import { useMutation } from "@apollo/client";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { AdminShippingAddressCreate } from "../mutations";
import { Routes } from "@bundles/UIAppBundle";
export const useShopShippingAddressCreate = () => {
    const t = useTranslate();
    const router = useRouter();
    const [addShopShippingAddress] = useMutation(AdminShippingAddressCreate);
    const onSubmit = (document) => {
        addShopShippingAddress({
            variables: {
                input: document,
            },
        })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_address.create_confirmation"),
            });
            return router.go(Routes.SHOP_SETTINGS_GENERAL_DETAILS_VIEW);
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    };
    return { onSubmit };
};

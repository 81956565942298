import { useMutation } from "@apollo/client";
import { ObjectId } from "@bluelibs/ejson";
import { useDataOne, useRouter, useTranslate, useUIComponents, } from "@bluelibs/x-ui";
import { use } from "@bluelibs/x-ui-react-bundle";
import { EndUserProfilesCollection } from "@bundles/UIAppBundle/collections";
import { useAvatarUpload } from "@bundles/UIAppBundle/components/Avatar/hooks";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useEffect, useState } from "react";
import { EndUserProfileEditForm } from "../../config/EndUserProfileEditForm";
import { END_USER_PROFILES_LIST } from "../../routes";
import { AdminEndUserProfileEdit } from "./mutations";
export const useEndUserProfileEdit = (id) => {
    const [UpdateAdminEndUserProfileEdit] = useMutation(AdminEndUserProfileEdit);
    const form = use(EndUserProfileEditForm, { transient: true });
    const UIComponents = useUIComponents();
    const [updateIsLoading, setUpdateIsLoading] = useState(false);
    const router = useRouter();
    const { uploadAvatar, setAvatarPreview, AvatarUpload } = useAvatarUpload();
    const t = useTranslate();
    const { data: document, isLoading, error, } = useDataOne(EndUserProfilesCollection, new ObjectId(id), EndUserProfileEditForm.getRequestBody());
    useEffect(() => {
        var _a, _b, _c;
        setAvatarPreview(generateProfileImageLink(document === null || document === void 0 ? void 0 : document.fullName, (_c = (_b = (_a = document === null || document === void 0 ? void 0 : document.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl));
    }, [document]);
    const updateClientDetails = async (formDocument) => {
        await form.onUpdateClient(id, formDocument, UpdateAdminEndUserProfileEdit);
    };
    const onUpdateClient = async (formDocument) => {
        setUpdateIsLoading(true);
        try {
            await uploadAvatar(document.userId);
            await updateClientDetails(formDocument);
            router.go(END_USER_PROFILES_LIST);
        }
        finally {
            setUpdateIsLoading(false);
        }
    };
    return {
        onUpdateClient,
        updateIsLoading,
        form,
        document,
        isLoading,
        error,
        UIComponents,
        t,
        initialValues: document && {
            ...document,
        },
        AvatarUpload,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslate } from "@bluelibs/x-ui";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Clients } from "@bundles/UIAppBundle/icons";
import { Card, CustomTag } from "@bundles/UIAppBundle/components/";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { useTeamMemberView } from "./hooks";
import styles from "./TeamMemberDetailsViewer.module.scss";
const TeamMemberDetailsViewer = ({ document }) => {
    var _a, _b;
    const { firstName, lastName, email, phoneNumber, user, fullName } = document;
    const t = useTranslate();
    const { roles } = useTeamMemberView(document._id);
    const profileImage = generateProfileImageLink(fullName, (_b = (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl);
    return (_jsx(Card, Object.assign({ className: styles["card"] }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["title"] }, { children: [_jsx("span", Object.assign({ className: styles["title__icon"] }, { children: _jsx(Clients, {}, void 0) }), void 0), _jsx("div", Object.assign({ className: styles["title__text"] }, { children: t("management.internal_user_profiles.view.header") }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["details"] }, { children: [_jsx("img", { src: profileImage }, void 0), _jsxs("div", Object.assign({ className: styles["details__grid"] }, { children: [_jsx("div", { children: t("management.internal_user_profiles.fields.firstName") }, void 0), _jsxs("div", { children: [": ", firstName, " "] }, void 0), _jsx("div", { children: t("management.internal_user_profiles.fields.lastName") }, void 0), _jsxs("div", { children: [": ", lastName, " "] }, void 0), _jsx("div", { children: t("management.internal_user_profiles.fields.email") }, void 0), _jsxs("div", { children: [": ", email, " "] }, void 0), _jsx("div", { children: t("management.internal_user_profiles.fields.phoneNumber") }, void 0), _jsxs("div", { children: [": ", phoneNumber, " "] }, void 0), _jsx("div", { children: "Roles" }, void 0), _jsx("div", { children: roles === null || roles === void 0 ? void 0 : roles.map((r, index) => (_jsx(CustomTag, { value: r, color: TagColorsEnum.GRAY, variant: "filled" }, r + index))) }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default TeamMemberDetailsViewer;

import { Routes } from "@bundles/UIAppBundle";
import { UserRole } from "@root/api.types";
export const dropdownLinks = [
    {
        label: "My Profile",
        url: Routes.PROFILE.path,
        roles: [],
    },
    {
        label: "Team Members",
        url: Routes.INTERNAL_USER_PROFILES_LIST.path,
        roles: [UserRole.SUPER_ADMIN, UserRole.TEAM_ADMIN],
    },
    {
        label: "Settings",
        url: Routes.SETTINGS_NOTIFICATIONS.path,
        roles: [
            UserRole.SUPER_ADMIN,
            UserRole.CLIENTS_ADMIN,
            UserRole.ORDERS_ADMIN,
            UserRole.PRODUCTS_ADMIN,
            UserRole.INVOICE_ADMIN,
        ],
    },
    {
        label: "Log out",
        url: Routes.LOGIN.path,
        roles: [],
        onclick: async (gardian) => {
            await gardian.logout();
        },
    },
];

import { Collection, } from "@bluelibs/x-ui";
import { InternalUserProfile } from "@root/api.types";
import { UsersCollection, OrdersCollection, EndUserProfilesCollection, NotificationSubscribersCollection, } from "@bundles/UIAppBundle/collections";
export class InternalUserProfilesCollection extends Collection {
    getName() {
        return "InternalUserProfiles";
    }
    getInputs() {
        return {
            insert: "InternalUserProfileInsertInput!",
            update: "InternalUserProfileUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "user",
                field: "userId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => OrdersCollection,
                name: "orders",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "clients",
            },
            {
                collection: () => NotificationSubscribersCollection,
                name: "notifications",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

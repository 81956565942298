import { useQuery } from "@apollo/client";
import { GET_ORDER_DETAILS, GET_INTERNAL_USER_PROFILES, GET_COMPANY_DETAILS, GET_PAYMENT_OPTIONS, } from "./query";
export const usePaymentDetails = () => {
    const onPaymentQuery = () => {
        const { data, loading, refetch } = useQuery(GET_PAYMENT_OPTIONS, {
            variables: {
                query: {
                    filters: {},
                },
            },
        });
        const paymentDetails = data === null || data === void 0 ? void 0 : data.PaymentOptionsFind;
        return { paymentDetails, loading, refetch };
    };
    return { onPaymentQuery };
};
export const useCompanyDetails = (id) => {
    let skip = true;
    if (id) {
        skip = false;
    }
    const { data, loading, refetch } = useQuery(GET_COMPANY_DETAILS, {
        skip,
        variables: {
            input: {
                endUserProfileId: id,
            },
        },
    });
    const companyDetails = data === null || data === void 0 ? void 0 : data.AdminEndUserProfileGet;
    return { companyDetails, loading, refetch };
};
export const useOrderDetail = (id) => {
    const { data, loading, refetch } = useQuery(GET_ORDER_DETAILS, {
        variables: {
            input: {
                orderId: id,
            },
        },
    });
    const orderDetail = data === null || data === void 0 ? void 0 : data.AdminOrderDetailsGet;
    const price = orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.price;
    const priceVat = +(orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.priceVAT);
    const vatCharge = +(priceVat - price);
    return {
        orderDetail: {
            ...orderDetail,
            price,
            priceVat,
            vatCharge,
        },
        loading,
        refetch,
    };
};
export const useTeamDetail = () => {
    const { data, loading, refetch } = useQuery(GET_INTERNAL_USER_PROFILES, {
        variables: {
            input: {
                isSuspended: false,
            },
        },
    });
    const teamDetail = data === null || data === void 0 ? void 0 : data.InternalUserProfileAdminListGet;
    return { teamDetail, loading, refetch };
};

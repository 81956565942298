import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Ant from "antd";
import { Button, ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
import { AddCircle, Trashcan } from "@bundles/UIAppBundle/icons";
import { attributeTypeOptions, suffixOptions, yesOrNoOptions, } from "@bundles/UIAppBundle/pages/ProductsManagement/components/Create/consts";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
import styles from "../ProductCategoriesCreate.module.scss";
export const newAttributesListRenderer = (t) => 
// eslint-disable-next-line react/display-name
(fields, { add, remove }) => {
    const onAdd = (e) => {
        e.preventDefault();
        add();
    };
    const onRemove = (name) => (e) => {
        e.preventDefault();
        remove(name);
    };
    return (_jsxs(Ant.Col, Object.assign({ span: 24 }, { children: [fields.map((field, index) => {
                return (_jsxs(Ant.Row, Object.assign({ gutter: 24, className: styles["attribute-item"] }, { children: [_jsx(Ant.Col, Object.assign({ span: 24, className: styles["name-column"] }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", name: [field.name, "name"], rules: [
                                    {
                                        required: true,
                                        message: t("management.products.required_field_errors.attributeOptions.name"),
                                    },
                                ] }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.name")), placeholder: t("management.products.placeholders.type_here"), description: t("management.products.descriptions.attributeOptions.name") }, void 0) }), void 0) }), void 0), _jsx(Ant.Col, Object.assign({ className: styles["type-column"] }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", name: [field.name, "type"], rules: [
                                    {
                                        required: true,
                                        message: t("management.products.required_field_errors.attributeOptions.type"),
                                    },
                                ] }, { children: _jsx(ControlledSelect, { options: attributeTypeOptions, label: addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.type")), placeholder: t("management.products.placeholders.attributeOptions.type") }, void 0) }), void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => {
                                var _a;
                                return ((_a = getFieldValue("attributeOptions")[field.name]) === null || _a === void 0 ? void 0 : _a.type) ===
                                    "number" ? (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", initialValue: null, name: [field.name, "defaultSuffix"], rules: [
                                            {
                                                required: true,
                                                message: t("management.products.required_field_errors.attributeOptions.defaultSuffix"),
                                            },
                                        ] }, { children: _jsx(ControlledSelect, { options: suffixOptions, label: addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.defaultSuffix")), placeholder: t("management.products.placeholders.attributeOptions.defaultSuffix") }, void 0) }), void 0) }), void 0)) : null;
                            } }), void 0), _jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, initialValue: false, label: "", name: [field.name, "isRequired"], rules: [
                                    {
                                        required: true,
                                        message: t("management.products.required_field_errors.attributeOptions.isRequired"),
                                    },
                                ] }, { children: _jsx(ControlledSelect, { options: yesOrNoOptions, label: addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.isRequired")), placeholder: t("management.products.placeholders.attributeOptions.isRequired") }, void 0) }), void 0) }), void 0), _jsx(Button, { btnStyle: "white", width: "fit-content", className: styles["remove-btn"], onClick: onRemove(field.name), icon: Trashcan }, void 0)] }), index));
            }), _jsx(Button, Object.assign({ btnStyle: "white", width: "fit-content", className: styles["add-btn"], onClick: onAdd }, { children: _jsxs(_Fragment, { children: [_jsx(AddCircle, {}, void 0), "Add New Attribute"] }, void 0) }), void 0)] }), void 0));
};

import { gql } from "@apollo/client";
export const GET_INTERNAL_USER_PROFILE = gql `
  query AdminGetOrderAdmins($input: AdminGetOrderAdminsInput) {
    AdminGetOrderAdmins(input: $input) {
      _id
      fullName
      userId
      user {
        avatar {
          file {
            downloadUrl
          }
        }
      }
    }
  }
`;

import { EndUserProfilesList } from "../components/List/EndUserProfilesList";
import { EndUserProfilesCreate } from "../components/Create/EndUserProfilesCreate";
import { EndUserProfilesEdit } from "../components/Edit/EndUserProfilesEdit";
import { EndUserProfilesView } from "../components/View/EndUserProfilesView";
import { SettingFilled } from "@ant-design/icons";
export const END_USER_PROFILES_LIST = {
    path: "/admin/end-user-profiles",
    component: EndUserProfilesList,
    menu: {
        key: "END_USER_PROFILES_LIST",
        label: "management.end_user_profiles.menu.title",
        icon: SettingFilled,
    },
};
export const END_USER_PROFILES_CREATE = {
    path: "/admin/end-user-profiles/create",
    component: EndUserProfilesCreate,
};
export const END_USER_PROFILES_EDIT = {
    path: "/admin/end-user-profiles/:id/edit",
    component: EndUserProfilesEdit,
};
export const END_USER_PROFILES_VIEW = {
    path: "/admin/end-user-profiles/:id/view",
    component: EndUserProfilesView,
};

import "./i18n";
import { PRODUCT_FAVORITES_LIST as BASE_PRODUCT_FAVORITES_LIST, PRODUCT_FAVORITES_CREATE as BASE_PRODUCT_FAVORITES_CREATE, PRODUCT_FAVORITES_EDIT as BASE_PRODUCT_FAVORITES_EDIT, PRODUCT_FAVORITES_VIEW as BASE_PRODUCT_FAVORITES_VIEW, } from "./config/routes";
export const PRODUCT_FAVORITES_LIST = {
    ...BASE_PRODUCT_FAVORITES_LIST,
};
export const PRODUCT_FAVORITES_CREATE = {
    ...BASE_PRODUCT_FAVORITES_CREATE,
};
export const PRODUCT_FAVORITES_EDIT = {
    ...BASE_PRODUCT_FAVORITES_EDIT,
};
export const PRODUCT_FAVORITES_VIEW = {
    ...BASE_PRODUCT_FAVORITES_VIEW,
};

import { AdminGetShippingAddresses, } from "./queries";
import { ShopShippingAddressCollection } from "./ShopShippingAddress.collection";
export class ShopShippingAddressCustomCollection extends ShopShippingAddressCollection {
    async find() {
        return (await this.apolloClient.query({
            query: AdminGetShippingAddresses,
            fetchPolicy: this.getFetchPolicy(),
        })).data.AdminGetShippingAddresses;
    }
}

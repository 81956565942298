import { gql } from "@apollo/client";
export const AdminInternalUserProfileUpdate = gql `
  mutation AdminInternalUserProfileUpdate(
    $input: AdminInternalUserProfileUpdateInput!
  ) {
    AdminInternalUserProfileUpdate(input: $input) {
      _id
      firstName
      middleName
      lastName
      email
      phoneNumber
      # TODO these lines should be uncommented when notifications are implemented on admin panel
      #activeNotifications {
      #  orderPlaced
      #  orderStatusChanged
      #  returnCreated
      #  returnStatusChanged
      #  clientCreated
      #  invoiceCreated
      #  productOutOfStock
      #  productPromotionExpire
      #}
    }
  }
`;

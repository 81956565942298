import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { InputNumber } from "antd";
import { generateImageLink, generateProfileImageLink, } from "@bundles/UIAppBundle/enums/Images";
import { Clipboard } from "@bundles/UIAppBundle/icons";
import { ordersTagColors, paymentMethodTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { orderStatusLabel, paymentOptionLabel, } from "@bundles/UIAppBundle/consts/labels";
import { CustomTag } from "@bundles/UIAppBundle/components/CustomTag";
import { Table } from "@bundles/UIAppBundle/components/Table";
import { useAddProductsFromOrder } from "./hooks";
import styles from "./AddProductsFromOrder.module.scss";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
const AddProductsFromOrder = ({ stepForm }) => {
    const { selectedOrder, productsData, updateQuantity, selectedRow, onSelectProduct, } = useAddProductsFromOrder(stepForm);
    const settings = useShopSettings();
    const columnsOrder = [
        {
            title: "Order Number",
            dataIndex: "orderNumber",
            render: (name, model) => {
                return (_jsxs("div", Object.assign({ className: styles["order-number"] }, { children: [_jsx(CustomTag, { isIcon: true, variant: "filled", color: ordersTagColors[model === null || model === void 0 ? void 0 : model.status], value: _jsx(Clipboard, {}, void 0) }, void 0), _jsxs("div", { children: ["#", name] }, void 0)] }), void 0));
            },
        },
        {
            title: "Buyer Name",
            dataIndex: ["buyerSnapshot", "name"],
            render: (name, model) => {
                var _a, _b, _c, _d;
                return (_jsxs("div", Object.assign({ className: styles["client-name-pic"] }, { children: [_jsx("img", { className: styles.image, src: generateProfileImageLink(name, (_d = (_c = (_b = (_a = model === null || model === void 0 ? void 0 : model.buyer) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.file) === null || _d === void 0 ? void 0 : _d.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            title: "Date placed",
            dataIndex: "placedAt",
            render: (value) => {
                const date = new Date(value);
                const dateString = date.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                });
                return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
            },
        },
        {
            title: "Payment Method",
            dataIndex: ["paymentOption", "type"],
            render: (val) => {
                return (_jsx("div", Object.assign({ className: styles["payment"] }, { children: _jsx(CustomTag, { color: val ? paymentMethodTagColors[val] : TagColorsEnum.GRAY, value: val ? paymentOptionLabel[val] : "dummy", variant: "filled" }, void 0) }), void 0));
            },
        },
        {
            title: "Status",
            dataIndex: ["status"],
            render: (value) => (_jsx(CustomTag, { value: orderStatusLabel[value], variant: "filled", color: ordersTagColors[value] }, void 0)),
        },
        {
            title: "Price",
            dataIndex: ["finalPrice"],
            render: (value) => _jsx("span", { children: settings.formatPriceWithCurrency(value) }, void 0),
        },
    ];
    const columnsProducts = [
        {
            title: "Product",
            dataIndex: ["productVariant", "product", "name"],
            render: (name, model) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: styles["product-variant-container"] }, { children: [_jsx("img", { src: generateImageLink((_a = model.productVariant.product.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            title: "Product Variant",
            dataIndex: ["productVariant"],
            render: ({ name, defaultImage }) => {
                return (_jsxs("div", Object.assign({ className: styles["product-variant-container"] }, { children: [_jsx("img", { src: generateImageLink(defaultImage === null || defaultImage === void 0 ? void 0 : defaultImage.downloadUrl) }, void 0), _jsx("div", Object.assign({ className: styles["product-variant"] }, { children: _jsx("div", Object.assign({ className: styles["product-variant__name"] }, { children: name }), void 0) }), void 0)] }), void 0));
            },
        },
        {
            title: "Quantity",
            dataIndex: ["count"],
            render: (val, row) => {
                return (_jsxs("span", Object.assign({ className: styles["quantity"] }, { children: [_jsx(InputNumber, { type: "number", defaultValue: row.count, min: 1, max: row.count, onChange: (v) => {
                                updateQuantity(row._id, +v);
                            } }, void 0), _jsxs("div", { children: ["/ ", row.count] }, void 0)] }), void 0));
            },
        },
        {
            title: "Price",
            dataIndex: ["price"],
            render: (value, model) => {
                var _a;
                return (_jsx("div", Object.assign({ className: styles["price"] }, { children: _jsx("span", { children: settings.formatPriceWithCurrency(((_a = model === null || model === void 0 ? void 0 : model.price) === null || _a === void 0 ? void 0 : _a.net) / (model === null || model === void 0 ? void 0 : model.count)) }, void 0) }), void 0));
            },
        },
    ];
    const rowSelectionProducts = {
        selectedRowKeys: selectedRow.map((i) => i._id),
        onChange: onSelectProduct,
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Table, { title: () => (_jsx("div", Object.assign({ className: styles["order-header"] }, { children: "SELECTED ORDER" }), void 0)), className: styles["table-order"], showHeader: false, columns: columnsOrder, dataSource: [selectedOrder], pagination: false }, void 0), _jsx(Table, { title: () => (_jsx("div", Object.assign({ className: styles["order-header"] }, { children: "SELECT PRODUCTS" }), void 0)), rowSelection: {
                    type: "checkbox",
                    ...rowSelectionProducts,
                }, rowKey: (record) => record._id, className: styles["table-product"], columns: columnsProducts, dataSource: productsData, pagination: false }, void 0)] }), void 0));
};
export default AddProductsFromOrder;

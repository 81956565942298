var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { useTranslate } from "@bluelibs/x-ui";
import { Service } from "@bluelibs/core";
import { ShopSettingEditForm as BaseShopSettingEditForm } from "../../../pages/ShopSettingsManagement/config/ShopSettingEditForm.base";
import { attributesListRenderer } from "./listRenderer";
let ShopAttributesConstructor = class ShopAttributesConstructor extends BaseShopSettingEditForm {
    build() {
        super.build();
        const t = useTranslate();
        // this.remove("attributeOptions");
        this.remove("vatRatesIds");
        this.remove("paymentOptionsIds");
        this.remove("analyticsURLs");
        this.remove("shopName");
        this.remove("companyName");
        this.remove("country");
        this.remove("companyIdentificationNumber");
        this.remove("shopAdministratorEmail");
        this.remove("billingAddress");
        this.remove("shippingAddress");
        this.remove("defaultCurrency");
        this.remove("invoiceGenerationPrefix");
        this.remove("firstInvoiceNumber");
        this.remove("freeShippingAfterAmountLimit");
        this.remove("reviewsEnabled");
        this.remove("faviconId");
        this.remove("logoId");
        this.update("attributeOptions", {
            isList: true,
            listRenderer: () => attributesListRenderer(t),
        });
        // Perthis additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ShopAttributesConstructor = __decorate([
    Service({ transient: true })
], ShopAttributesConstructor);
export { ShopAttributesConstructor };

import { jsx as _jsx } from "react/jsx-runtime";
import { Download, Edit, KebabMenu, View } from "@bundles/UIAppBundle/icons";
import { Routes } from "@bundles/UIAppBundle";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { CustomOrdersCollection } from "@bundles/UIAppBundle/collections/Orders/Orders.custom.collection";
import { features } from "../../config/features";
import { OrderList } from "../../config/OrderList";
export class OrdersAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomOrdersCollection;
    }
    getBody() {
        return OrderList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(OrderList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return OrderList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(Edit, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.ORDERS_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.ORDERS_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        //FIXME: Implement download functionality?
        actions.push({
            label: "Invoice",
            icon: _jsx(Download, {}, void 0),
            action: (model) => {
                this.router.go(Routes.INVOICES_VIEW, {
                    params: {
                        id: model.invoiceId,
                    },
                });
            },
        });
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["orderNumber"]) {
            modifiedFiltersObj["orderNumber"] =
                modifiedFiltersObj["orderNumber"].source;
        }
        if (modifiedFiltersObj["paymentOptionIds"]) {
            modifiedFiltersObj["paymentOptionIds"] =
                modifiedFiltersObj["paymentOptionIds"]["$in"];
        }
        if (modifiedFiltersObj["status"]) {
            modifiedFiltersObj["ordersStatus"] = modifiedFiltersObj["status"]["$in"];
            delete modifiedFiltersObj["status"];
        }
        if (modifiedFiltersObj["finalPrice"]) {
            modifiedFiltersObj["orderPrice"] = {
                gte: parseFloat(modifiedFiltersObj["finalPrice"]["$in"][0]),
                lte: parseFloat(modifiedFiltersObj["finalPrice"]["$in"][1]),
            };
            delete modifiedFiltersObj["finalPrice"];
        }
        if (modifiedFiltersObj["placedAt"]) {
            modifiedFiltersObj["orderDate"] = {
                gte: (modifiedFiltersObj["placedAt"]["$gte"] || "").toString() || null,
                lte: (modifiedFiltersObj["placedAt"]["$lte"] || "").toString() || null,
            };
            delete modifiedFiltersObj["placedAt"];
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
                // ...this.state.filters,
                // ...this.alwaysOnFilters,
            },
            options: {
                ...(this.getSortOptions() || { sort: { placedAt: -1 } }),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

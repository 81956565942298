import { Collection, } from "@bluelibs/x-ui";
import { OrderItem } from "@root/api.types";
import { ProductVariantsCollection, OrdersCollection, Product3DModelCollection, } from "@bundles/UIAppBundle/collections";
export class OrderItemsCollection extends Collection {
    getName() {
        return "OrderItems";
    }
    getInputs() {
        return {
            insert: "OrderItemInsertInput!",
            update: "OrderItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProductVariantsCollection,
                name: "productVariant",
                field: "productVariantId",
            },
            {
                collection: () => OrdersCollection,
                name: "order",
                field: "orderId",
            },
            {
                collection: () => Product3DModelCollection,
                name: "product3DModel",
                field: "product3DModelId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

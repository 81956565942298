import { gql } from "@apollo/client";
export const AdminShopSettingEdit = gql `
  mutation AdminShopSettingEdit($document: AdminShopSettingEditInput!) {
    AdminShopSettingEdit(document: $document) {
      _id
      billingAddress {
        city
        country
        email
        phoneNumber
        streetAddressLine1
        zipcode
      }
      attributeOptions {
        name
        type
      }
      defaultCurrency
      companyIdentificationNumber
      companyName
      firstInvoiceNumber
      freeShippingAfterAmountLimit
      invoiceGenerationPrefix
      shopName
      reviewsEnabled
      analyticsURLs {
        google
        facebookPixel
      }
    }
  }
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ProductVariantsCollection, OrdersCollection, Product3DModelCollection, } from "@bundles/UIAppBundle/collections";
let OrderItemListFiltersForm = class OrderItemListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "productSnapshot",
                label: t("management.order_items.fields.productSnapshot"),
                name: ["productSnapshot"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.order_items.fields.productSnapshot.name"),
                        name: ["productSnapshot", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "description",
                        label: t("management.order_items.fields.productSnapshot.description"),
                        name: ["productSnapshot", "description"],
                        component: Ant.Input,
                    },
                    {
                        id: "sku",
                        label: t("management.order_items.fields.productSnapshot.sku"),
                        name: ["productSnapshot", "sku"],
                        component: Ant.Input,
                    },
                    {
                        id: "imageUrl",
                        label: t("management.order_items.fields.productSnapshot.imageUrl"),
                        name: ["productSnapshot", "imageUrl"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "status",
                label: t("management.order_items.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.order_items.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "OPEN" }, { children: "Open" }), "OPEN"), _jsx(Ant.Select.Option, Object.assign({ value: "WAITING_EXTERNAL_STOCK" }, { children: "Waiting External Stock" }), "WAITING_EXTERNAL_STOCK"), _jsx(Ant.Select.Option, Object.assign({ value: "SHIPPED" }, { children: "Shipped" }), "SHIPPED"), _jsx(Ant.Select.Option, Object.assign({ value: "DELIVERED" }, { children: "Delivered" }), "DELIVERED"), _jsx(Ant.Select.Option, Object.assign({ value: "CANCELED" }, { children: "Canceled" }), "CANCELED")] }), void 0) }), void 0)),
            },
            {
                id: "appliedPrice",
                label: t("management.order_items.fields.appliedPrice"),
                name: ["appliedPrice"],
                columns: true,
                nest: [
                    {
                        id: "currency",
                        label: t("management.order_items.fields.appliedPrice.currency"),
                        name: ["appliedPrice", "currency"],
                        component: Ant.Input,
                    },
                    {
                        id: "gross",
                        label: t("management.order_items.fields.appliedPrice.gross"),
                        name: ["appliedPrice", "gross"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "net",
                        label: t("management.order_items.fields.appliedPrice.net"),
                        name: ["appliedPrice", "net"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "tax",
                        label: t("management.order_items.fields.appliedPrice.tax"),
                        name: ["appliedPrice", "tax"],
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "appliedDiscounts",
                label: t("management.order_items.fields.appliedDiscounts"),
                name: ["appliedDiscounts"],
                columns: true,
                nest: [
                    {
                        id: "discountSource",
                        label: t("management.order_items.fields.appliedDiscounts.discountSource"),
                        name: ["appliedDiscounts", "discountSource"],
                        required: true,
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "PRODUCT", label: "Product" },
                                { value: "CATEGORY", label: "Category" },
                                { value: "ORDER_ITEM_COUNT", label: "Order Item Count" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.order_items.fields.appliedDiscounts.discountSource") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.order_items.fields.appliedDiscounts.discountSource_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.order_items.fields.appliedDiscounts.discountSource_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "categoryId",
                        label: t("management.order_items.fields.appliedDiscounts.categoryId"),
                        name: ["appliedDiscounts", "categoryId"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "discountPercentage",
                        label: t("management.order_items.fields.appliedDiscounts.discountPercentage"),
                        name: ["appliedDiscounts", "discountPercentage"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "count",
                label: t("management.order_items.fields.count"),
                name: ["count"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "vatPercent",
                label: t("management.order_items.fields.vatPercent"),
                name: ["vatPercent"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "appliedVatRate",
                label: t("management.order_items.fields.appliedVatRate"),
                name: ["appliedVatRate"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.order_items.fields.appliedVatRate.name"),
                        name: ["appliedVatRate", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "percentage",
                        label: t("management.order_items.fields.appliedVatRate.percentage"),
                        name: ["appliedVatRate", "percentage"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "description",
                        label: t("management.order_items.fields.appliedVatRate.description"),
                        name: ["appliedVatRate", "description"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.order_items.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.order_items.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.order_items.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.order_items.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "productVariantId",
                label: t("management.order_items.fields.productVariant"),
                name: ["productVariantId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductVariantsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "orderId",
                label: t("management.order_items.fields.order"),
                name: ["orderId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrdersCollection, field: "orderNumber", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "product3DModelId",
                label: t("management.order_items.fields.product3DModel"),
                name: ["product3DModelId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: Product3DModelCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
OrderItemListFiltersForm = __decorate([
    Service({ transient: true })
], OrderItemListFiltersForm);
export { OrderItemListFiltersForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { use, useDataOne, useTranslate } from "@bluelibs/x-ui";
import { VatRatesCollection } from "@bundles/UIAppBundle/collections";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { VatRateEditForm } from "../../config/VatRateEditForm";
import styles from "../Create/VatRatesCreate.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function VatRatesEdit(props) {
    const t = useTranslate();
    const form = use(VatRateEditForm, { transient: true });
    const { data: document, isLoading, error, } = useDataOne(VatRatesCollection, new ObjectId(props.id), VatRateEditForm.getRequestBody());
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: {
                    ...seoData.vatRate.edit,
                    title: `${document === null || document === void 0 ? void 0 : document.name} | Edit Vat Rates`,
                    description: document === null || document === void 0 ? void 0 : document.description,
                } }, void 0), _jsxs(ShopConfigurationLayout, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsx(Card, Object.assign({ title: t("management.shop_settings.submenus.vat_details").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => form.submitForm(props.id, document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0) }), void 0))] }, void 0)] }), void 0));
}

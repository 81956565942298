var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ReturnViewer = class ReturnViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.returns.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "number",
                label: t("management.returns.fields.number"),
                dataIndex: ["number"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.nbh",
                label: t("management.returns.fields.billingAddress.nbh"),
                dataIndex: ["billingAddress", "nbh"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_floor",
                label: t("management.returns.fields.billingAddress.building_floor"),
                dataIndex: ["billingAddress", "building_floor"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip",
                label: t("management.returns.fields.billingAddress.zip"),
                dataIndex: ["billingAddress", "zip"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.address",
                label: t("management.returns.fields.billingAddress.address"),
                dataIndex: ["billingAddress", "address"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_entrance",
                label: t("management.returns.fields.billingAddress.building_entrance"),
                dataIndex: ["billingAddress", "building_entrance"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.street",
                label: t("management.returns.fields.billingAddress.street"),
                dataIndex: ["billingAddress", "street"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.district",
                label: t("management.returns.fields.billingAddress.district"),
                dataIndex: ["billingAddress", "district"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.tax",
                label: t("management.returns.fields.billingAddress.tax"),
                dataIndex: ["billingAddress", "tax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                label: t("management.returns.fields.billingAddress.country"),
                dataIndex: ["billingAddress", "country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.note",
                label: t("management.returns.fields.billingAddress.note"),
                dataIndex: ["billingAddress", "note"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                label: t("management.returns.fields.billingAddress.email"),
                dataIndex: ["billingAddress", "email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.num",
                label: t("management.returns.fields.billingAddress.num"),
                dataIndex: ["billingAddress", "num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.salesRayId",
                label: t("management.returns.fields.billingAddress.salesRayId"),
                dataIndex: ["billingAddress", "salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip_num",
                label: t("management.returns.fields.billingAddress.zip_num"),
                dataIndex: ["billingAddress", "zip_num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.fax",
                label: t("management.returns.fields.billingAddress.fax"),
                dataIndex: ["billingAddress", "fax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building",
                label: t("management.returns.fields.billingAddress.building"),
                dataIndex: ["billingAddress", "building"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.settlement",
                label: t("management.returns.fields.billingAddress.settlement"),
                dataIndex: ["billingAddress", "settlement"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phone",
                label: t("management.returns.fields.billingAddress.phone"),
                dataIndex: ["billingAddress", "phone"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_apartment",
                label: t("management.returns.fields.billingAddress.building_apartment"),
                dataIndex: ["billingAddress", "building_apartment"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.nbh",
                label: t("management.returns.fields.shippingAddress.nbh"),
                dataIndex: ["shippingAddress", "nbh"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_floor",
                label: t("management.returns.fields.shippingAddress.building_floor"),
                dataIndex: ["shippingAddress", "building_floor"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip",
                label: t("management.returns.fields.shippingAddress.zip"),
                dataIndex: ["shippingAddress", "zip"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.address",
                label: t("management.returns.fields.shippingAddress.address"),
                dataIndex: ["shippingAddress", "address"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_entrance",
                label: t("management.returns.fields.shippingAddress.building_entrance"),
                dataIndex: ["shippingAddress", "building_entrance"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.street",
                label: t("management.returns.fields.shippingAddress.street"),
                dataIndex: ["shippingAddress", "street"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.district",
                label: t("management.returns.fields.shippingAddress.district"),
                dataIndex: ["shippingAddress", "district"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.tax",
                label: t("management.returns.fields.shippingAddress.tax"),
                dataIndex: ["shippingAddress", "tax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.country",
                label: t("management.returns.fields.shippingAddress.country"),
                dataIndex: ["shippingAddress", "country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.note",
                label: t("management.returns.fields.shippingAddress.note"),
                dataIndex: ["shippingAddress", "note"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.email",
                label: t("management.returns.fields.shippingAddress.email"),
                dataIndex: ["shippingAddress", "email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.num",
                label: t("management.returns.fields.shippingAddress.num"),
                dataIndex: ["shippingAddress", "num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.salesRayId",
                label: t("management.returns.fields.shippingAddress.salesRayId"),
                dataIndex: ["shippingAddress", "salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip_num",
                label: t("management.returns.fields.shippingAddress.zip_num"),
                dataIndex: ["shippingAddress", "zip_num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.fax",
                label: t("management.returns.fields.shippingAddress.fax"),
                dataIndex: ["shippingAddress", "fax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building",
                label: t("management.returns.fields.shippingAddress.building"),
                dataIndex: ["shippingAddress", "building"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.settlement",
                label: t("management.returns.fields.shippingAddress.settlement"),
                dataIndex: ["shippingAddress", "settlement"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.phone",
                label: t("management.returns.fields.shippingAddress.phone"),
                dataIndex: ["shippingAddress", "phone"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_apartment",
                label: t("management.returns.fields.shippingAddress.building_apartment"),
                dataIndex: ["shippingAddress", "building_apartment"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "items",
                label: t("management.returns.fields.items"),
                dataIndex: ["items"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "price.currency",
                label: t("management.returns.fields.price.currency"),
                dataIndex: ["price", "currency"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.gross",
                label: t("management.returns.fields.price.gross"),
                dataIndex: ["price", "gross"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.net",
                label: t("management.returns.fields.price.net"),
                dataIndex: ["price", "net"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.tax",
                label: t("management.returns.fields.price.tax"),
                dataIndex: ["price", "tax"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                label: t("management.returns.fields.status"),
                dataIndex: ["status"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "notes",
                label: t("management.returns.fields.notes"),
                dataIndex: ["notes"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.returns.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.returns.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "endUser",
                label: t("management.returns.fields.endUser"),
                dataIndex: ["endUser"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "order",
                label: t("management.returns.fields.order"),
                dataIndex: ["order"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "orderNumber",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "admin",
                label: t("management.returns.fields.admin"),
                dataIndex: ["admin"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INTERNAL_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "invoice",
                label: t("management.returns.fields.invoice"),
                dataIndex: ["invoice"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INVOICES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "invoiceId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paymentOption",
                label: t("management.returns.fields.paymentOption"),
                dataIndex: ["paymentOption"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENT_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingOption",
                label: t("management.returns.fields.shippingOption"),
                dataIndex: ["shippingOption"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SHIPPING_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            number: 1,
            billingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            shippingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            items: {
                orderItemId: 1,
                quantity: 1,
                name: 1,
                sku: 1,
                price: {
                    currency: 1,
                    gross: 1,
                    net: 1,
                    tax: 1,
                },
                imageUrl: 1,
                attributesValues: {
                    key: 1,
                    value: 1,
                    suffix: 1,
                    isNumber: 1,
                },
            },
            price: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            status: 1,
            notes: 1,
            createdAt: 1,
            updatedAt: 1,
            endUser: {
                _id: 1,
                fullName: 1,
            },
            endUserId: 1,
            order: {
                _id: 1,
                orderNumber: 1,
            },
            orderId: 1,
            admin: {
                _id: 1,
                fullName: 1,
            },
            adminId: 1,
            invoice: {
                _id: 1,
                invoiceId: 1,
            },
            invoiceId: 1,
            paymentOption: {
                _id: 1,
                name: 1,
            },
            paymentOptionId: 1,
            shippingOption: {
                _id: 1,
                name: 1,
            },
            shippingOptionId: 1,
        };
    }
};
ReturnViewer = __decorate([
    Service({ transient: true })
], ReturnViewer);
export { ReturnViewer };

import { Collection, } from "@bluelibs/x-ui";
import { Product3DModel } from "@root/api.types";
import { Product3DImageCollection, Product3DTextCollection, ProductVariantsCollection, EndUserProfilesCollection, OrderItemsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class Product3DModelCollection extends Collection {
    getName() {
        return "Product3DModel";
    }
    getInputs() {
        return {
            insert: "Product3DModelInsertInput!",
            update: "Product3DModelUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProductVariantsCollection,
                name: "productVariant",
                field: "productVariantId",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "endUserProfile",
                field: "endUserProfileId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => Product3DImageCollection,
                name: "images",
            },
            {
                collection: () => Product3DTextCollection,
                name: "texts",
            },
            {
                collection: () => OrderItemsCollection,
                name: "orderItem",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

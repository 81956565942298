import { SUPEND_TEAM_MEMBER, UNSUSPEND_TEAM_MEMBER, } from "@bundles/UIAppBundle/pages/EndUserProfilesManagement/components/List/mutations";
import { GET_END_USER_PROFILES } from "@bundles/UIAppBundle/pages/EndUserProfilesManagement/components/List/queries";
import { EndUserProfilesCollection as Base } from "./EndUserProfiles.collection";
export class CustomEndUserProfilesCollection extends Base {
    async suspendUser(id) {
        return this.apolloClient
            .mutate({
            mutation: SUPEND_TEAM_MEMBER,
            variables: {
                input: {
                    _id: id,
                },
            },
        })
            .then(() => {
            return true;
        })
            .catch(() => {
            return false;
        });
    }
    async unSuspendUser(id) {
        return this.apolloClient
            .mutate({
            mutation: UNSUSPEND_TEAM_MEMBER,
            variables: {
                input: {
                    _id: id,
                },
            },
        })
            .then(() => {
            return true;
        })
            .catch(() => {
            return false;
        });
    }
    async find(query) {
        var _a, _b;
        return this.apolloClient
            .query({
            query: GET_END_USER_PROFILES,
            variables: {
                input: {
                    filters: (_a = query.filters) !== null && _a !== void 0 ? _a : {},
                    options: (_b = query.options) !== null && _b !== void 0 ? _b : {},
                },
            },
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => {
            return result.data.AdminEndUserProfileListGet;
        })
            .catch((err) => {
            console.error(err);
        });
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Card, ControlledSelect } from "@bundles/UIAppBundle/components";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useAssignTeamMember } from "./hooks";
import styles from "./AssignTeamMember.module.scss";
const AssignTeamMember = ({ salesAgentId, orderId, cardDefault = false, }) => {
    const { teamMember, teamMembersOption, changeTeamMember } = useAssignTeamMember(orderId, salesAgentId);
    return (_jsx(Card, Object.assign({ title: "Assign Team Member", className: cardDefault === true ? "" : styles["custom-card"] }, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsx(ControlledSelect, { placeholder: "Select Team Member", options: teamMembersOption, onChange: (v) => {
                        changeTeamMember(v);
                    } }, void 0), _jsxs("div", Object.assign({ className: styles["name-img"] }, { children: [_jsx("img", { src: generateProfileImageLink(teamMember.fullName, teamMember.avatar) }, void 0), _jsx("div", { children: teamMember.fullName || "Please select a team member" }, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default AssignTeamMember;

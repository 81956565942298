import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { BigCardLayout, Button, Progress, } from "@bundles/UIAppBundle/components";
import styles from "./ExportProducts.module.scss";
export const Exporting = ({ exported, total, onClickStopExporting, }) => {
    const t = useTranslate();
    return (_jsx(BigCardLayout, Object.assign({ title: t("management.export.title"), subtitle: t("management.export.headers.exporting") }, { children: _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles["label"] }, { children: t("management.export.descriptions.exporting.exportingInProgress") }), void 0), _jsx("div", { children: t("management.export.descriptions.exporting.message") }, void 0), _jsx(Progress, { className: styles["progress"], value: exported / total }, void 0), _jsx("div", Object.assign({ className: styles["progress-text"] }, { children: t("management.export.descriptions.exporting.progress", {
                        exported,
                        total,
                    }) }), void 0), _jsx(Button, Object.assign({ width: "fit-content", btnStyle: "primary-outlined", className: styles["submit"], type: "button", onClick: onClickStopExporting }, { children: t("management.export.buttons.stopExporting") }), void 0)] }, void 0) }), void 0));
};

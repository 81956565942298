import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { use, useDataOne, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { ShippingAddressEditForm } from "../../config/ShippingAddressEditForm";
import { Button, Card } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { ShippingAddressCollection, } from "@bundles/UIAppBundle/collections";
import { ObjectId } from "@bluelibs/ejson";
export function ShippingAddressEdit(props) {
    const t = useTranslate();
    const form = use(ShippingAddressEditForm, { transient: true });
    form.build();
    const { data: document, isLoading, error, } = useDataOne(ShippingAddressCollection, new ObjectId(props.id), ShippingAddressEditForm.getRequestBody());
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shippingAddress.edit }, void 0), _jsxs(_Fragment, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsx(ShopConfigurationLayout, { children: _jsx(Card, Object.assign({ title: t("management.shipping_address.edit").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => form.onEditSubmit(props.id, document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["absolute-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0) }), void 0) }, void 0))] }, void 0)] }), void 0));
}

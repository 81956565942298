import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { ControlledInput, Button } from "@bundles/UIAppBundle/components";
import { Group2996, HidePassword, ViewPassword, } from "@bundles/UIAppBundle/icons";
import { Routes } from "@bundles/UIAppBundle";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
import styles from "./Login.module.scss";
import { Input, PlaceHolder } from "./consts";
import { useLogin } from "./hooks";
const Login = () => {
    const router = useRouter();
    const tl = useTranslate("authentication.login");
    const { onSubmit, onChangeValue, fieldError, toggleShouldShowPassword, shouldShowPassword, isLoading, } = useLogin();
    return (_jsx(PublicRoute, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Group2996, { className: styles.group2996 }, void 0), _jsxs("div", Object.assign({ className: styles["form-container"] }, { children: [_jsx("div", { className: styles.flex1 }, void 0), _jsxs("form", Object.assign({ onSubmit: onSubmit }, { children: [_jsx("h1", Object.assign({ className: styles.title }, { children: tl("header") }), void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.EMAIL, v.target.value), error: fieldError(Input.EMAIL), placeholder: PlaceHolder[Input.EMAIL], name: Input.EMAIL, type: "email" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.PASSWORD, v.target.value), error: fieldError(Input.PASSWORD), placeholder: PlaceHolder[Input.PASSWORD], name: Input.PASSWORD, type: shouldShowPassword ? "text" : "password", EndComponent: _jsx("span", Object.assign({ onClick: toggleShouldShowPassword, className: styles["password-visibility-button"] }, { children: shouldShowPassword ? _jsx(ViewPassword, {}, void 0) : _jsx(HidePassword, {}, void 0) }), void 0) }, void 0), _jsx(Button, Object.assign({ type: "submit", btnStyle: "primary", loading: isLoading }, { children: _jsx("b", { children: tl("login") }, void 0) }), void 0), _jsx(Link, Object.assign({ className: styles["forgot-password"], to: router.path(Routes.FORGOT_PASSWORD) }, { children: tl("forgotPassword_btn") }), void 0)] }), void 0), _jsx("div", { className: styles.flex1 }, void 0)] }), void 0)] }), void 0) }, void 0));
};
export default Login;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import styles from "./Address.module.scss";
const Address = ({ address, showImage = false, title }) => {
    const { address: ad, zip_num, phone, country } = address;
    return (_jsx(Card, Object.assign({ title: `${title} information:`, className: styles["custom-card"] }, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsxs("div", Object.assign({ className: styles["name-img"] }, { children: [showImage && _jsx("img", { src: generateImageLink() }, void 0), _jsx("div", { children: country }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.address }, { children: [_jsx("div", { children: ad }, void 0), _jsx("div", { children: ad }, void 0), _jsx("div", { children: zip_num }, void 0), _jsx("div", { children: country }, void 0)] }), void 0), phone && (_jsxs("div", Object.assign({ className: styles["row-grid"] }, { children: [_jsx("div", { children: "Phone no" }, void 0), _jsxs("div", { children: [":", phone] }, void 0)] }), void 0))] }), void 0) }), void 0));
};
export default Address;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { EndUserProfilesCollection, ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
let ReviewListFiltersForm = class ReviewListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "comment",
                label: t("management.reviews.fields.comment"),
                name: ["comment"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "stars",
                label: t("management.reviews.fields.stars"),
                name: ["stars"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "status",
                label: t("management.reviews.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.reviews.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "PENDING" }, { children: "Pending" }), "PENDING"), _jsx(Ant.Select.Option, Object.assign({ value: "APPROVED" }, { children: "Approved" }), "APPROVED"), _jsx(Ant.Select.Option, Object.assign({ value: "UNAPPROVED" }, { children: "Unapproved" }), "UNAPPROVED")] }), void 0) }), void 0)),
            },
            {
                id: "isAnonymous",
                label: t("management.reviews.fields.isAnonymous"),
                name: ["isAnonymous"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "editHistory",
                label: t("management.reviews.fields.editHistory"),
                name: ["editHistory"],
                columns: true,
                nest: [
                    {
                        id: "comment",
                        label: t("management.reviews.fields.editHistory.comment"),
                        name: ["editHistory", "comment"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "stars",
                        label: t("management.reviews.fields.editHistory.stars"),
                        name: ["editHistory", "stars"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "createdAt",
                        label: t("management.reviews.fields.editHistory.createdAt"),
                        name: ["editHistory", "createdAt"],
                        required: true,
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.reviews.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.reviews.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.reviews.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.reviews.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "reviewerId",
                label: t("management.reviews.fields.reviewer"),
                name: ["reviewerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "productVariantId",
                label: t("management.reviews.fields.productVariant"),
                name: ["productVariantId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductVariantsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "likesIds",
                label: t("management.reviews.fields.likes"),
                name: ["likesIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "disLikesIds",
                label: t("management.reviews.fields.disLikes"),
                name: ["disLikesIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "reportsIds",
                label: t("management.reviews.fields.reports"),
                name: ["reportsIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ReviewListFiltersForm = __decorate([
    Service({ transient: true })
], ReviewListFiltersForm);
export { ReviewListFiltersForm };

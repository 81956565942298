import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Alert, Layout } from "antd";
import { newSmart, useUIComponents, useTranslate } from "@bluelibs/x-ui";
import { Table } from "@bundles/UIAppBundle/components/Table";
import { CustomPageHeader } from "@bundles/UIAppBundle/components/CustomPageHeader";
import { CreateOrder } from "@bundles/UIAppBundle/components/CreateOrder";
import { OrdersAntTableSmart } from "./OrdersTableSmart";
import { useOrdersList } from "./hooks";
import styles from "./OrdersList.module.scss";
import { OrdersListFilters } from "./OrdersListFilters";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ordersAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function OrdersList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const [api, Provider] = newSmart(OrdersAntTableSmart);
    const { filtersOpened, setFiltersOpened, createModalOpen, setCreateModalOpen, onFiltersUpdate, } = useOrdersList();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: [_jsx(Seo, { data: seoData.order.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.orders.list.header"), searchKey: "orderNumber", searchPlaceholder: t("management.orders.list.search"), onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened }, void 0), createModalOpen && (_jsx(CreateOrder, { modalOpen: createModalOpen, setModalOpen: setCreateModalOpen, onSuccess: () => api.init() }, void 0)), api.state.isError && (_jsx(Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Layout.Content, { children: _jsxs(Provider, { children: [filtersOpened && _jsx(OrdersListFilters, { onUpdate: onFiltersUpdate }, void 0), _jsx(Table, Object.assign({ className: styles["table"] }, api.getTableProps(), { 
                                    // NOTE: x: auto sets inline styles {table-layout: auto, min-width: auto}
                                    // -> this forces each col to have minimum possible width to accomodate content (not possible with css otherwise)
                                    scroll: { x: "auto" } }), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0));
}

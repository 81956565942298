import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { AdminUnitsOfMeasurementSync } from "./mutations";
import { UnitsOfMeasurementAntTableSmart } from "./UnitsOfMeasurementTableSmart";
export const useUnitOfMeasurementList = () => {
    const t = useTranslate();
    const [api, Provider] = newSmart(UnitsOfMeasurementAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [syncUnitsOfMeasurement, { loading: isLoadingSync }] = useMutation(AdminUnitsOfMeasurementSync);
    const onFiltersUpdate = useMemo(() => {
        return (filters) => {
            api.setFlexibleFilters(filters);
        };
    }, []);
    const onClickSync = () => {
        notification.info({
            key: "syncing_units_of_measurement",
            message: t("management.units_of_measurement.list.syncing"),
            duration: 3600,
        });
        syncUnitsOfMeasurement()
            .then(() => {
            api.load();
            notification.close("syncing_units_of_measurement");
            notification.success({
                message: t("management.units_of_measurement.list.sync_success"),
            });
        })
            .catch(() => {
            notification.close("syncing_units_of_measurement");
            notification.error({
                message: t("management.units_of_measurement.list.sync_error"),
            });
        });
    };
    return {
        api,
        Provider,
        filtersOpened,
        setFiltersOpened,
        isLoadingSync,
        onFiltersUpdate,
        onClickSync,
    };
};

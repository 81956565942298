var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Product3DModelListFiltersForm as BaseProduct3DModelListFiltersForm } from "./Product3DModelListFiltersForm.base";
import { useData } from "@bluelibs/x-ui";
import { ControlledSelect } from "@bundles/UIAppBundle/components";
import { EndUserProfilesCollection, ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
import * as Ant from "antd";
let Product3DModelListFiltersForm = class Product3DModelListFiltersForm extends BaseProduct3DModelListFiltersForm {
    build() {
        super.build();
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("createdById");
        this.remove("updatedById");
        this.remove("name");
        this.update("endUserProfileId", {
            order: 2,
            label: "",
            tooltip: null,
            name: ["endUserProfileId"],
            render: (props) => {
                const { data, error, isLoading } = useData(EndUserProfilesCollection, {}, { _id: 1, fullName: 1 });
                const EndUserProfiles = (data === null || data === void 0 ? void 0 : data.map(({ _id, fullName }) => ({
                    label: `${fullName} `,
                    value: _id,
                }))) || [];
                if (isLoading && !data) {
                    return _jsx(_Fragment, { children: "Loading..." }, void 0);
                }
                if (error && !data) {
                    return _jsx(_Fragment, { children: "Error occurred fetching the payment options" }, void 0);
                }
                return (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { initialValue: [] }, { children: _jsx(ControlledSelect, { placeholder: "Select End User", label: "Please select User profile", options: EndUserProfiles, multiple: true }, void 0) }), void 0) }), void 0));
            },
        });
        this.update("productVariantId", {
            label: "",
            render: (props) => {
                const { data, error, isLoading } = useData(ProductVariantsCollection, {}, {
                    _id: 1,
                    name: 1,
                    unitOfMeasurement: { name: 1, label: 1, code: 1 },
                });
                const ProductVariants = (data === null || data === void 0 ? void 0 : data.map(({ _id, name, unitOfMeasurement }) => ({
                    label: `${name} ${unitOfMeasurement.name} ${unitOfMeasurement.code}`,
                    value: _id,
                }))) || [];
                if (isLoading && !data) {
                    return _jsx(_Fragment, { children: "Loading..." }, void 0);
                }
                if (error && !data) {
                    return _jsx(_Fragment, { children: "Error occurred fetching the payment options" }, void 0);
                }
                return (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { initialValue: [] }, { children: _jsx(ControlledSelect, { placeholder: "Select Product Variant", label: "Please select option", options: ProductVariants.length > 0 && ProductVariants, multiple: true }, void 0) }), void 0) }), void 0));
            },
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
Product3DModelListFiltersForm = __decorate([
    Service({ transient: true })
], Product3DModelListFiltersForm);
export { Product3DModelListFiltersForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { Button, Card, ControlledInput } from "../../../components";
import { useProfileDetailsForm } from "../hooks";
import { Label, Input } from "../consts";
import styles from "./ProfileDetailsForm.module.scss";
const ProfileDetailsForm = () => {
    const { state: { user }, } = useAppGuardian();
    const { fieldProfileError, onChangeProfileValue, onProfileSubmit, isLoading, AvatarUpload, } = useProfileDetailsForm();
    return (_jsxs("div", Object.assign({ className: styles.details }, { children: [_jsxs("div", Object.assign({ className: styles["title-row"] }, { children: [_jsx("div", Object.assign({ className: styles["title"] }, { children: "MY PROFILE" }), void 0), _jsx(Button, Object.assign({ loading: isLoading, width: "fit-content", onClick: () => {
                            onProfileSubmit();
                        }, icon: Checkmark }, { children: "Save Changes" }), void 0)] }), void 0), _jsxs(Card, Object.assign({ title: "PROFILE DETAILS", className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.row }, { children: _jsx(AvatarUpload, {}, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles.row }, { children: [_jsx(ControlledInput, { onChange: (v) => onChangeProfileValue(Input.FIRST_NAME, v.target.value), label: Label[Input.FIRST_NAME], error: fieldProfileError(Input.FIRST_NAME), defaultValue: user === null || user === void 0 ? void 0 : user.profile.firstName, placeholder: "First name", required: false, autoComplete: "off" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeProfileValue(Input.LAST_NAME, v.target.value), label: Label[Input.LAST_NAME], error: fieldProfileError(Input.LAST_NAME), defaultValue: user === null || user === void 0 ? void 0 : user.profile.lastName, placeholder: "Last Name", required: false, autoComplete: "off" }, void 0)] }), void 0), _jsx(ControlledInput, { onChange: (v) => onChangeProfileValue(Input.EMAIL, v.target.value), label: Label[Input.EMAIL], error: fieldProfileError(Input.EMAIL), defaultValue: user === null || user === void 0 ? void 0 : user.email, placeholder: "Email", required: false, autoComplete: "off", type: "email" }, void 0)] }), void 0)] }), void 0));
};
export default ProfileDetailsForm;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import classNames from "classnames";
import { ShopLogo } from "@bundles/UIAppBundle/icons";
import { sideMenus } from "./consts";
import { useSidebar } from "./hooks";
import styles from "./Sidebar.module.scss";
import { useGuardian } from "@bluelibs/x-ui-guardian-bundle";
import { checkAdminRights } from "../utils/CheckAdminRights";
const Sidebar = () => {
    var _a;
    const { linkClasses, onOpenChange, openKeys } = useSidebar();
    const { state } = useGuardian();
    const userRoles = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.roles) || [];
    return (_jsxs("aside", Object.assign({ className: styles.container }, { children: [_jsx(Link, Object.assign({ to: "/dashboard", className: styles.logo }, { children: _jsx(ShopLogo, { className: styles.logo }, void 0) }), void 0), _jsx("ul", Object.assign({ className: classNames(styles.list, "cc-custom-list") }, { children: sideMenus.map(({ url, label, icon: Icon, subMenus, urlHead, roles }, idx) => {
                    if (!checkAdminRights(userRoles, roles))
                        return null;
                    return (_jsx("li", Object.assign({ className: styles["list-item"] }, { children: subMenus ? (_jsx(Menu, { openKeys: openKeys, mode: "inline", inlineIndent: 0, className: styles.menu, onOpenChange: () => onOpenChange(subMenus[0].url), items: [
                                {
                                    label: (_jsxs("div", Object.assign({ className: styles["menu-btn"] }, { children: [Icon && _jsx(Icon, { className: styles.icon }, void 0), _jsx("span", { children: label }, void 0)] }), void 0)),
                                    key: url,
                                    children: subMenus.map(({ url, label }, idx) => ({
                                        label: (_jsx(Link, Object.assign({ className: linkClasses(url, urlHead, true), to: url }, { children: _jsx("span", { children: label }, void 0) }), void 0)),
                                        key: idx,
                                    })),
                                },
                            ] }, void 0)) : (_jsxs(Link, Object.assign({ className: linkClasses(url, urlHead), to: url }, { children: [Icon && _jsx(Icon, { className: styles.icon }, void 0), _jsx("span", { children: label }, void 0)] }), void 0)) }), idx));
                }) }), void 0)] }), void 0));
};
export default Sidebar;

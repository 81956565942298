import { gql } from "@apollo/client";
export const GET_PAYMENT_OPTIONS = gql `
  query AdminGetPaymentOptions {
    AdminGetPaymentOptions {
      _id
      createdById
      description
      name
      type
      updatedById
      createdBy {
        _id
      }
      updatedBy {
        _id
      }
    }
  }
`;

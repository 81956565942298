import "./i18n";
import IntegrationList from "./components/Integrations/List/IntegrationList";
import IntegrationDetails from "./components/Integrations/View/IntegrationView";
import Logo from "./components/Logo/Logo";
export const MARKETING_INTEGRATIONS = {
    path: "/marketing/integrations",
    component: IntegrationList,
};
export const MARKETING_INTEGRATION_DETAILS = {
    path: "/marketing/integrations/:integrationOption",
    component: IntegrationDetails,
};
export const LOGO = {
    path: "/marketing/logo",
    component: Logo,
};

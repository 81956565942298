import { InternalUserProfilesList } from "../components/List/InternalUserProfilesList";
import { InternalUserProfilesCreate } from "../components/Create/InternalUserProfilesCreate";
import { InternalUserProfilesEdit } from "../components/Edit/InternalUserProfilesEdit";
import { InternalUserProfilesView } from "../components/View/InternalUserProfilesView";
import { SettingFilled } from "@ant-design/icons";
export const INTERNAL_USER_PROFILES_LIST = {
    path: "/admin/internal-user-profiles",
    component: InternalUserProfilesList,
    menu: {
        key: "INTERNAL_USER_PROFILES_LIST",
        label: "management.internal_user_profiles.menu.title",
        icon: SettingFilled,
    },
};
export const INTERNAL_USER_PROFILES_CREATE = {
    path: "/admin/internal-user-profiles/create",
    component: InternalUserProfilesCreate,
};
export const INTERNAL_USER_PROFILES_EDIT = {
    path: "/admin/internal-user-profiles/:id/edit",
    component: InternalUserProfilesEdit,
};
export const INTERNAL_USER_PROFILES_VIEW = {
    path: "/admin/internal-user-profiles/:id/view",
    component: InternalUserProfilesView,
};

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { Row, Col, Radio } from "antd";
import * as debounce from "lodash.debounce";
import styles from "./HeaderFilters.module.scss";
const HeaderFilters = ({ filterOptions, onFiltersUpdate, filterKey = "status", }) => {
    const [filterBy, setFilterBy] = useState(null);
    const debouncedFilterUpdates = useMemo(() => {
        return debounce(onFiltersUpdate, 500);
    }, [onFiltersUpdate]);
    const handleChange = (e) => {
        setFilterBy(e.target.value);
        debouncedFilterUpdates({ [filterKey]: e.target.value });
    };
    return (_jsx(Col, Object.assign({ className: styles["header-filters"] }, { children: _jsx(Row, { children: _jsx(Radio.Group, Object.assign({ className: styles["filters-wrapper"], onChange: handleChange, value: filterBy }, { children: filterOptions.map(({ value, label }, key) => (_jsx(Radio.Button, Object.assign({ value: value }, { children: label }), key))) }), void 0) }, void 0) }), void 0));
};
export default HeaderFilters;

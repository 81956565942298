import { gql } from "@apollo/client";
export const INTERNAL_USER_UPDATE = gql `
  mutation AdminInternalUserProfileUpdateAdmin(
    $input: AdminInternalUserProfileUpdateAdminInput!
    $id: ObjectId
  ) {
    AdminInternalUserProfileUpdateAdmin(input: $input, _id: $id) {
      _id
      firstName
      middleName
      lastName
      phoneNumber
      email
      fullName
    }
  }
`;

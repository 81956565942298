import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactModal from "react-modal";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./Modal.module.scss";
const Modal = ({ title, children, setIsOpen, ...rest }) => {
    return (_jsx(ReactModal, Object.assign({ overlayClassName: styles.body, shouldCloseOnOverlayClick: true, ariaHideApp: false, onRequestClose: () => {
            setIsOpen(false);
        }, style: {
            overlay: {
                cursor: "pointer",
                backgroundColor: "rgba(0,0,0,0.2)",
                zIndex: 101,
            },
            content: {
                margin: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "hidden",
                padding: 0,
                borderColor: "transparent",
                borderWidth: 0,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            },
        } }, rest, { closeTimeoutMS: 10, contentLabel: "modal" }, { children: _jsxs("div", Object.assign({ className: styles["content"] }, { children: [_jsxs("div", Object.assign({ className: styles["header"] }, { children: [_jsx("div", Object.assign({ className: styles["title"] }, { children: title }), void 0), _jsx("div", Object.assign({ onClick: () => {
                                setIsOpen(false);
                            } }, { children: _jsx(CloseOutlined, {}, void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: styles["children"] }, { children: children }), void 0)] }), void 0) }), void 0));
};
export default Modal;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ProductCategoriesCollection, VatRatesCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let ProductListFiltersForm = class ProductListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "updatedAt",
                label: t("management.products.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.products.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.products.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.products.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "discountActiveUntil",
                label: t("management.products.fields.discountActiveUntil"),
                name: ["discountActiveUntil"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "defaultDiscountPercentage",
                label: t("management.products.fields.defaultDiscountPercentage"),
                name: ["defaultDiscountPercentage"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "defaultNetPrice",
                label: t("management.products.fields.defaultNetPrice"),
                name: ["defaultNetPrice"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "hasVariants",
                label: t("management.products.fields.hasVariants"),
                name: ["hasVariants"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "country",
                label: t("management.products.fields.country"),
                name: ["country"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "height",
                label: t("management.products.fields.height"),
                name: ["height"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "width",
                label: t("management.products.fields.width"),
                name: ["width"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "length",
                label: t("management.products.fields.length"),
                name: ["length"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "weight",
                label: t("management.products.fields.weight"),
                name: ["weight"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.products.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.products.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "DIGITAL" }, { children: "Digital" }), "DIGITAL"), _jsx(Ant.Select.Option, Object.assign({ value: "PHYSICAL" }, { children: "Physical" }), "PHYSICAL"), _jsx(Ant.Select.Option, Object.assign({ value: "SERVICE" }, { children: "Service" }), "SERVICE")] }), void 0) }), void 0)),
            },
            {
                id: "brand",
                label: t("management.products.fields.brand"),
                name: ["brand"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                name: ["tags"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "availableStockCount",
                label: t("management.products.fields.availableStockCount"),
                name: ["availableStockCount"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "attributeOptions",
                label: t("management.products.fields.attributeOptions"),
                name: ["attributeOptions"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.products.fields.attributeOptions.name"),
                        name: ["attributeOptions", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "type",
                        label: t("management.products.fields.attributeOptions.type"),
                        name: ["attributeOptions", "type"],
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "string", label: "String" },
                                { value: "number", label: "Number" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.products.fields.attributeOptions.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.products.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.products.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "isRequired",
                        label: t("management.products.fields.attributeOptions.isRequired"),
                        name: ["attributeOptions", "isRequired"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "isEnum",
                        label: t("management.products.fields.attributeOptions.isEnum"),
                        name: ["attributeOptions", "isEnum"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "enumValues",
                        label: t("management.products.fields.attributeOptions.enumValues"),
                        name: ["attributeOptions", "enumValues"],
                        initialValue: [],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "isRange",
                        label: t("management.products.fields.attributeOptions.isRange"),
                        name: ["attributeOptions", "isRange"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "defaultValue",
                        label: t("management.products.fields.attributeOptions.defaultValue"),
                        name: ["attributeOptions", "defaultValue"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "defaultSuffix",
                        label: t("management.products.fields.attributeOptions.defaultSuffix"),
                        name: ["attributeOptions", "defaultSuffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "rangeMin",
                        label: t("management.products.fields.attributeOptions.rangeMin"),
                        name: ["attributeOptions", "rangeMin"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "rangeMax",
                        label: t("management.products.fields.attributeOptions.rangeMax"),
                        name: ["attributeOptions", "rangeMax"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "slug",
                label: t("management.products.fields.slug"),
                name: ["slug"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "seoFields",
                label: t("management.products.fields.seoFields"),
                name: ["seoFields"],
                columns: true,
                nest: [
                    {
                        id: "pageTitle",
                        label: t("management.products.fields.seoFields.pageTitle"),
                        name: ["seoFields", "pageTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "metaDescription",
                        label: t("management.products.fields.seoFields.metaDescription"),
                        name: ["seoFields", "metaDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "keywords",
                        label: t("management.products.fields.seoFields.keywords"),
                        name: ["seoFields", "keywords"],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "seoTitle",
                        label: t("management.products.fields.seoFields.seoTitle"),
                        name: ["seoFields", "seoTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "seoDescription",
                        label: t("management.products.fields.seoFields.seoDescription"),
                        name: ["seoFields", "seoDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "slug",
                        label: t("management.products.fields.seoFields.slug"),
                        name: ["seoFields", "slug"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "params",
                label: t("management.products.fields.params"),
                name: ["params"],
                columns: true,
                nest: [],
            },
            {
                id: "description",
                label: t("management.products.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "name",
                label: t("management.products.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "salesRayId",
                label: t("management.products.fields.salesRayId"),
                name: ["salesRayId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "categoryId",
                label: t("management.products.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "vatRateId",
                label: t("management.products.fields.vatRate"),
                name: ["vatRateId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: VatRatesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.products.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.products.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.products.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.products.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ProductListFiltersForm = __decorate([
    Service({ transient: true })
], ProductListFiltersForm);
export { ProductListFiltersForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Form, Col } from "antd";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import { InternalUserProfileCreateForm as BaseInternalUserProfileCreateForm } from "./InternalUserProfileCreateForm.base";
let InternalUserProfileForm = class InternalUserProfileForm extends BaseInternalUserProfileCreateForm {
    build() {
        const { t } = this.i18n;
        super.build();
        this.add({
            id: "image",
            name: ["image"],
            order: 0,
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx("label", Object.assign({ className: "upload" }, { children: _jsx("input", { type: "file" }, void 0) }), void 0) }), void 0)),
        });
        this.remove("middleName");
        this.remove("isSuspended");
        this.remove("suspendedAt");
        this.remove("userId");
        this.update("firstName", {
            render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.internal_user_profiles.fields.firstName"), placeholder: t("management.internal_user_profiles.placeholder.firstName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("lastName", {
            render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.internal_user_profiles.fields.lastName"), placeholder: t("management.internal_user_profiles.placeholder.lastName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("phoneNumber", {
            render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.internal_user_profiles.fields.phoneNumber"), placeholder: t("management.internal_user_profiles.placeholder.phoneNumber"), type: "tel" }, void 0) }), void 0) }), void 0)),
        });
        this.update("email", {
            render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { label: "", rules: [
                        {
                            type: "email",
                            message: "The input is not valid E-mail!",
                        },
                    ] }, { children: _jsx(ControlledInput, { label: t("management.internal_user_profiles.fields.email"), placeholder: t("management.internal_user_profiles.placeholder.email"), type: "email" }, void 0) }), void 0) }), void 0)),
        });
        this.add([
            {
                id: "roles",
                label: "",
                name: ["roles"],
                required: true,
                render: () => _jsx(_Fragment, {}, void 0),
            },
        ]);
    }
};
InternalUserProfileForm = __decorate([
    Service({ transient: true })
], InternalUserProfileForm);
export { InternalUserProfileForm };

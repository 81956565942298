import { useMemo, useState } from "react";
import { newSmart } from "@bluelibs/x-ui";
import { InternalUserProfilesAntTableSmart } from "./InternalUserProfilesTableSmart";
import { useMutation } from "@apollo/client";
import { SUPEND_TEAM_MEMBER } from "./mutation";
export const useInternalUserProfilesList = () => {
    const [api, Provider] = newSmart(InternalUserProfilesAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [suspendTeamMember] = useMutation(SUPEND_TEAM_MEMBER);
    const [filters, setFilters] = useState({});
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const nextFilters = { ...filters, ...newFilters };
            setFilters(nextFilters);
            api.setFlexibleFilters(nextFilters);
        };
    }, [filters]);
    return {
        api,
        filtersOpened,
        onFiltersUpdate,
        setFiltersOpened,
        Provider,
        suspendTeamMember,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { Service } from "@bluelibs/core";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import { ShopShippingAddressCreateForm as BaseShopShippingAddressCreateForm } from "./ShopShippingAddressCreateForm.base";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
let ShopShippingAddressForm = class ShopShippingAddressForm extends BaseShopShippingAddressCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating rendering functions, labels, description
        this.remove("streetAddressLine2");
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.update("streetAddressLine1", {
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.streetAddressLine1"), placeholder: t("management.shipping_address.placeholders.streetAddress") }, void 0) }), void 0) }), void 0)),
        });
        this.update("city", {
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.shipping_address.fields.city"), placeholder: t("management.shipping_address.placeholders.city") }, void 0) }), void 0) }), void 0)),
        });
        this.update("zipcode", {
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.shipping_address.fields.zipcode"), placeholder: t("management.shipping_address.placeholders.zipcode") }, void 0) }), void 0) }), void 0)),
        });
    }
};
ShopShippingAddressForm = __decorate([
    Service({ transient: true })
], ShopShippingAddressForm);
export { ShopShippingAddressForm };

import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GetTeamMembers, GetProductCategories, GetProducts, GetProductVariants, } from "./queries";
export const useFiltersData = () => {
    const { data: teamData, loading: teamDataLoading, refetch: teamDataRefetch, } = useQuery(GetTeamMembers);
    const { data: categoryData, loading: categoryDataLoading, refetch: categoryDataRefetch, } = useQuery(GetProductCategories);
    const { data: productData, loading: productDataLoading, refetch: productDataRefetch, } = useQuery(GetProducts);
    const { data: variantData, loading: variantDataLoading, refetch: variantDataRefetch, } = useQuery(GetProductVariants);
    useEffect(() => {
        if (!teamDataLoading && (!teamData || !teamData.InternalUserProfilesFind)) {
            teamDataRefetch();
        }
        if (!categoryDataLoading &&
            (!categoryData || !categoryData.ProductCategoriesFind)) {
            categoryDataRefetch();
        }
        if (!productDataLoading && (!productData || !productData.ProductsFind)) {
            productDataRefetch();
        }
        if (!variantDataLoading &&
            (!variantData || !variantData.ProductCategoriesFind)) {
            variantDataRefetch();
        }
    }, [teamData, categoryData, productData, variantData]);
    return {
        teamData,
        categoryData,
        productData,
        variantData,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Space, Spin } from "antd";
import { use, useTranslate } from "@bluelibs/x-ui";
import { useQuery } from "@apollo/client";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Card } from "@bundles/UIAppBundle/components/Card";
import ProfileDetails from "@bundles/UIAppBundle/icons/ProfileDetails";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { EndUserProfileViewer } from "../../config/EndUserProfileViewer";
import { clientsAccessRoles } from "../../consts";
import { ClientPageLayout } from "./Layout";
import { END_USER_VIEW } from "./query";
import styles from "./EndUserProfilesView.module.scss";
export function EndUserProfilesView(props) {
    const t = useTranslate();
    const { data, loading: isLoading, error, } = useQuery(END_USER_VIEW, {
        variables: {
            input: {
                endUserProfileId: props.id,
            },
        },
    });
    const document = data === null || data === void 0 ? void 0 : data.AdminEndUserProfileGet;
    let content;
    if (isLoading) {
        content = (_jsx(Space, Object.assign({ align: "center" }, { children: _jsx(Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(EndUserProfilesViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: clientsAccessRoles }, { children: [_jsx(Seo, { data: seoData.endUserProfile.view }, void 0), _jsx(ClientPageLayout, Object.assign({ id: props.id }, { children: _jsx("div", Object.assign({ className: styles.layout }, { children: _jsx("div", Object.assign({ className: styles.container }, { children: content }), void 0) }), void 0) }), void 0)] }), void 0));
}
export function EndUserProfilesViewComponent(props) {
    var _a, _b, _c;
    const document = props.document;
    const viewer = use(EndUserProfileViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Card, Object.assign({ title: "PROFILE DETAILS", className: styles.card, icon: ProfileDetails }, { children: _jsxs("div", Object.assign({ className: styles["card-content"] }, { children: [_jsx("div", Object.assign({ className: styles["profile-pic"] }, { children: _jsx("div", Object.assign({ className: styles["profile-pic__image"] }, { children: _jsx("img", { src: generateProfileImageLink(document.fullName, (_c = (_b = (_a = document.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl), width: "100%", height: "100%", alt: "profile-pic" }, void 0) }), void 0) }), void 0), viewer.render([
                            viewer.findElement("firstName"),
                            viewer.findElement("lastName"),
                            viewer.findElement("companyName"),
                            viewer.findElement("vatNumber"),
                            viewer.findElement("email"),
                            viewer.findElement("phoneNumber"),
                        ])] }), void 0) }), "1"), viewer.render([viewer.findElement("savedAddresses")])] }), void 0));
}

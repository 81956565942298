import { AdminVATRateCreate } from "@bundles/UIAppBundle/pages/VatRatesManagement/components/Create/mutations";
import { AdminVATRateUpdate } from "@bundles/UIAppBundle/pages/VatRatesManagement/components/Edit/mutations";
import { AdminGetVatRates } from "@bundles/UIAppBundle/pages/VatRatesManagement/components/List/queries";
import { VatRatesCollection } from "./VatRates.collection";
export class CustomVatRatesCollection extends VatRatesCollection {
    async find() {
        return this.apolloClient
            .query({
            query: AdminGetVatRates,
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => result.data.AdminGetVatRates)
            .catch(() => { });
    }
    async insertOne(document) {
        return this.apolloClient
            .mutate({
            mutation: AdminVATRateCreate,
            variables: { input: document },
        })
            .then((result) => result.data.AdminVatRateCreate)
            .catch((error) => {
            throw error;
        });
    }
    async updateOne(_id, update) {
        return this.apolloClient
            .mutate({
            mutation: AdminVATRateUpdate,
            variables: {
                vatRateId: _id,
                input: update,
            },
        })
            .then((result) => result.data.AdminVatRateUpdate)
            .catch((error) => {
            throw error;
        });
    }
}

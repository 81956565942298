import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { GET_PRODUCT_VARIANTS } from "./query";
export const useAddProductsOrder = (stepForm) => {
    const formData = stepForm.getFieldsValue(true);
    const [selectedRow, setSelectedRow] = useState(formData.items || []);
    const [itemsData, setItemsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableTotalData, setTableTotalData] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const pageSize = 4;
    const [getProducts, { loading: productsListLoading, data }] = useLazyQuery(GET_PRODUCT_VARIANTS, {
        variables: {
            input: {
                searchTerm: searchTerm,
                options: {
                    limit: pageSize,
                    skip: (currentPage - 1) * pageSize,
                    sort: null,
                },
            },
        },
        fetchPolicy: "network-only",
    });
    const updateQuantity = (id, updatedQuantity) => {
        const updatedSelectedItemsData = [...selectedRow];
        const updatedItemsData = [...itemsData];
        const itemSelectedDataIndex = updatedSelectedItemsData.findIndex((item) => item._id === id);
        if (itemSelectedDataIndex > -1) {
            updatedSelectedItemsData[itemSelectedDataIndex].quantity =
                updatedQuantity;
        }
        const itemsDataIndex = updatedItemsData.findIndex((item) => item._id === id);
        if (itemsDataIndex > -1) {
            updatedItemsData[itemsDataIndex].quantity = updatedQuantity;
        }
        // changing selected items accordingly
        if (itemSelectedDataIndex > -1 && updatedQuantity === 0) {
            // item is selected and the quantity changed to zero => deselect
            updatedSelectedItemsData.splice(itemSelectedDataIndex, 1);
        }
        if (itemSelectedDataIndex === -1 &&
            itemsDataIndex > -1 &&
            updatedQuantity !== 0) {
            // item is not selected and quantity changed to non-zero => select
            updatedSelectedItemsData.push(updatedItemsData[itemsDataIndex]);
        }
        setSelectedRow(updatedSelectedItemsData);
        setItemsData(updatedItemsData);
        stepForm.setFieldsValue({ items: updatedSelectedItemsData });
    };
    const onSelectProduct = (antdSelectedRowKeys, antdSelectedRows) => {
        setSelectedRow(antdSelectedRows);
        stepForm.setFieldsValue({ items: antdSelectedRows });
    };
    const getQuantity = (variant) => {
        var _a, _b;
        const formData = stepForm.getFieldsValue(true);
        const defaultQuantity = Math.min(variant.product.availableStockCount, 1);
        if (!formData.items)
            return defaultQuantity;
        return (((_b = (_a = formData.items) === null || _a === void 0 ? void 0 : _a.find((item) => item._id === variant._id)) === null || _b === void 0 ? void 0 : _b.quantity) ||
            defaultQuantity);
    };
    useEffect(() => {
        getProducts();
    }, [currentPage]);
    useEffect(() => {
        if (!productsListLoading && data) {
            setTableTotalData(data === null || data === void 0 ? void 0 : data.ProductVariantsListGet.pagination.total);
            setItemsData(data.ProductVariantsListGet.items.map((variant) => ({
                ...variant,
                quantity: getQuantity(variant),
            })));
        }
    }, [data, productsListLoading]);
    const debouncedSearch = debounce(async (searchTerm) => {
        setCurrentPage(1);
        setSearchTerm(searchTerm);
        getProducts();
    }, 500);
    const onSearch = (searchTerm) => {
        debouncedSearch(searchTerm);
    };
    return {
        updateQuantity,
        onSearch,
        itemsData,
        currentPage,
        setCurrentPage,
        tableTotalData,
        productsListLoading,
        pageSize,
        onSelectProduct,
        selectedRow,
    };
};

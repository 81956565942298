import { useGuardian, useTranslate } from "@bluelibs/x-ui";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { SETTINGS_NOTIFICATIONS, SETTINGS_REVIEWS, } from "@bundles/UIAppBundle/pages/Settings/routes";
import { checkAdminRights } from "../../Layout/utils/CheckAdminRights";
export const useSettingsLayout = () => {
    var _a;
    const t = useTranslate("management.settings.submenus");
    const { state } = useGuardian();
    const userRoles = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.roles) || [];
    const links = [
        {
            label: t("notifications"),
            route: SETTINGS_NOTIFICATIONS,
        },
    ];
    if (checkAdminRights(userRoles, ordersAccessRoles)) {
        links.push({
            label: t("reviews"),
            route: SETTINGS_REVIEWS,
        });
    }
    return {
        links,
    };
};

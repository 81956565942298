import { gql } from "@apollo/client";
export const GetReports = gql `
  query AdminDashboardReportsGet($input: AdminDashboardReportsInput!) {
    AdminDashboardReportsGet(input: $input) {
      totalIncome
      totalCompletedOrders
      totalCustomers
      dailyRevenues {
        timestamp
        totalOrders
      }
      monthlyRevenues {
        timestamp
        totalOrders
      }
      yearlyRevenues {
        timestamp
        totalOrders
      }
      bestProducts {
        _id
        name
        attributesValues {
          key
          value
        }
        netPrice
        defaultImage {
          downloadUrl
        }
        __typename
      }
      lastOrders {
        _id
        buyer {
          firstName
          lastName
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
          __typename
        }
        items {
          appliedPrice {
            currency
          }
        }
        orderNumber
        finalPrice
        paymentOption {
          _id
          name
        }
        placedAt
      }
      __typename
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents } from "@bluelibs/x-ui";
import { ResetPasswordForm } from "@bundles/UIAppBundle/components/MyProfileForm";
import { ProfileDetailsForm } from "@bundles/UIAppBundle/components/MyProfileForm";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import styles from "./Profile.module.scss";
export function Profile() {
    const UIComponents = useUIComponents();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [] }, { children: [_jsx(Seo, { data: seoData.profile }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(ProfileDetailsForm, {}, void 0), _jsx(ResetPasswordForm, {}, void 0)] }), void 0) }, void 0)] }), void 0));
}

import { useEffect, useState } from "react";
export const useAddProductsFromOrder = (stepForm) => {
    const formData = stepForm.getFieldsValue(true);
    const selectedOrder = formData.orderInfo;
    const [selectedRow, setSelectedRow] = useState(formData.items || []);
    const [productsData, setProductsData] = useState([]);
    useEffect(() => {
        setProductsData(formData.orderInfo.items);
    }, []);
    const updateQuantity = (id, v) => {
        const updatedSelectedItemsData = [...selectedRow];
        const updatedItemsData = [...productsData];
        const itemSelectedDataIndex = updatedSelectedItemsData.findIndex((item) => item._id === id);
        const itemsDataIndex = updatedItemsData.findIndex((item) => item._id === id);
        if (itemSelectedDataIndex > -1) {
            updatedSelectedItemsData[itemSelectedDataIndex].returnCount = v;
        }
        if (itemsDataIndex > -1) {
            updatedItemsData[itemsDataIndex].returnCount = v;
        }
        setSelectedRow(updatedSelectedItemsData);
        setProductsData(updatedItemsData);
        stepForm.setFieldsValue({ items: updatedSelectedItemsData });
    };
    const onSelectProduct = (antdSelectedRowKeys, antdSelectedRows) => {
        setSelectedRow(antdSelectedRows);
        stepForm.setFieldsValue({ items: antdSelectedRows });
    };
    return {
        formData,
        selectedOrder,
        productsData,
        updateQuantity,
        onSelectProduct,
        selectedRow,
    };
};

import { AvatarsList } from "../components/List/AvatarsList";
import { AvatarsCreate } from "../components/Create/AvatarsCreate";
import { AvatarsEdit } from "../components/Edit/AvatarsEdit";
import { AvatarsView } from "../components/View/AvatarsView";
import { SettingFilled } from "@ant-design/icons";
export const AVATARS_LIST = {
    path: "/admin/avatars",
    component: AvatarsList,
    menu: {
        key: "AVATARS_LIST",
        label: "management.avatars.menu.title",
        icon: SettingFilled,
    },
};
export const AVATARS_CREATE = {
    path: "/admin/avatars/create",
    component: AvatarsCreate,
};
export const AVATARS_EDIT = {
    path: "/admin/avatars/:id/edit",
    component: AvatarsEdit,
};
export const AVATARS_VIEW = {
    path: "/admin/avatars/:id/view",
    component: AvatarsView,
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useQuery } from "@apollo/client";
import * as Ant from "antd";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import { UserRole } from "@root/api.types";
import { Button } from "@bundles/UIAppBundle/components";
import { ProductCategoriesEditForm } from "./ProductCategoriesEditForm";
import { GetCategoryQuery } from "./queries";
import styles from "./ProductCategoriesEdit.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ProductCategoriesEdit(props) {
    const t = useTranslate();
    const UIComponents = useUIComponents();
    const router = useRouter();
    const { data: document, loading, error, } = useQuery(GetCategoryQuery, { variables: { id: props.id } });
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: {
                    ...seoData.productCategories.edit,
                    title: `${document === null || document === void 0 ? void 0 : document.AdminCategoryGet.name} | Edit Product Category`,
                    description: document === null || document === void 0 ? void 0 : document.AdminCategoryGet.description,
                } }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx(Button, { width: "fit-content", onClick: () => router.history.goBack(), className: styles["back-btn"], btnStyle: "white", icon: BackCircle }, void 0), !loading && !error && (_jsx("h2", Object.assign({ className: styles.title }, { children: t(`management.product_categories.edit.header.${document.AdminCategoryGet.isRoot ? "" : "sub"}category`) }), void 0))] }), void 0), loading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !loading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !loading && !error && (_jsx(ProductCategoriesEditForm, { document: document.AdminCategoryGet }, void 0))] }), void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button } from "..";
import styles from "./ProductVariantAttribute.module.scss";
import { useProductInfoVariants } from "./hooks";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { Routes } from "@bundles/UIAppBundle";
const ProductVariantAttribute = (props) => {
    const { productFoundId, isFromCurrentProductVariant } = useProductInfoVariants(props);
    const { value } = props;
    const router = useRouter();
    return (_jsx(Button, Object.assign({ className: `
        ${styles.buttonClick}
        ${!productFoundId ? styles.notFound : ""}
        ${isFromCurrentProductVariant(value) ? styles.currentValue : ""}
      `, disabled: !productFoundId, onClick: () => {
            router.go(Routes.PRODUCT_VARIANTS_VIEW, {
                params: {
                    id: productFoundId,
                },
            });
        }, btnStyle: "primary" }, { children: value }), void 0));
};
export default ProductVariantAttribute;

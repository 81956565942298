import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ORDERS_GET } from "./query";
export const useAddOrder = () => {
    const [ordersData, setOrdersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { data } = useQuery(ORDERS_GET, {
        variables: {
            input: {
                filters: {
                    isReturned: false,
                },
            },
        },
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        setOrdersData(data === null || data === void 0 ? void 0 : data.AdminOrdersGet.data);
        setFilteredData(data === null || data === void 0 ? void 0 : data.AdminOrdersGet.data);
    }, [data]);
    const onSearch = (searchTerm) => {
        setFilteredData(ordersData.filter((d) => {
            if (searchTerm === "")
                return d;
            else if (d.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
                d.buyer.fullName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return d;
            }
        }));
    };
    return {
        ordersData,
        onSearch,
        filteredData,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Button, ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
import { AddCircle, Trashcan } from "@bundles/UIAppBundle/icons";
import { attributeTypeOptions, suffixOptions, yesOrNoOptions } from "./consts";
import styles from "./ShopAttributesForm.module.scss";
export const attributesListRenderer = (t) => 
// eslint-disable-next-line react/display-name
(fields, { add, remove }) => {
    const onAdd = (e) => {
        e.preventDefault();
        add();
    };
    const onRemove = (name) => (e) => {
        e.preventDefault();
        remove(name);
    };
    return (_jsxs(Ant.Col, Object.assign({ span: 24 }, { children: [fields.map((field, index) => {
                return (_jsxs(Ant.Row, Object.assign({ gutter: 24, className: styles["attribute-item"] }, { children: [_jsx(Ant.Col, Object.assign({ span: 24, className: styles["name-column"] }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", name: [field.name, "name"] }, { children: _jsx(ControlledInput, { label: t("management.shop_settings.fields.attributeOptions.name"), placeholder: t("management.shop_settings.placeholders.type_here"), description: t("management.shop_settings.descriptions.attributeOptions.name") }, void 0) }), void 0) }), void 0), _jsx(Ant.Col, Object.assign({ className: styles["type-column"] }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", name: [field.name, "type"] }, { children: _jsx(ControlledSelect, { options: attributeTypeOptions, label: t("management.shop_settings.fields.attributeOptions.type"), placeholder: t("management.shop_settings.placeholders.attributeOptions.type") }, void 0) }), void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => {
                                var _a;
                                return ((_a = getFieldValue("attributeOptions")[field.name]) === null || _a === void 0 ? void 0 : _a.type) ===
                                    "number" ? (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, label: "", initialValue: null, name: [field.name, "defaultSuffix"] }, { children: _jsx(ControlledSelect, { options: suffixOptions, label: t("management.shop_settings.fields.attributeOptions.defaultSuffix"), placeholder: t("management.shop_settings.placeholders.attributeOptions.defaultSuffix") }, void 0) }), void 0) }), void 0)) : null;
                            } }), void 0), _jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, field, { required: true, initialValue: false, label: "", name: [field.name, "isRequired"] }, { children: _jsx(ControlledSelect, { options: yesOrNoOptions, label: t("management.shop_settings.fields.attributeOptions.isRequired"), placeholder: t("management.shop_settings.placeholders.attributeOptions.isRequired") }, void 0) }), void 0) }), void 0), _jsx(Button, { btnStyle: "white", width: "fit-content", className: styles["remove-btn"], onClick: onRemove(field.name), icon: Trashcan }, void 0)] }), index));
            }), _jsx(Button, Object.assign({ btnStyle: "white", width: "fit-content", className: styles["add-btn"], onClick: onAdd, icon: AddCircle }, { children: "Add New Attribute" }), void 0)] }), void 0));
};

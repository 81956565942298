import { Product3DTextList } from "../components/List/Product3DTextList";
import { Product3DTextCreate } from "../components/Create/Product3DTextCreate";
import { Product3DTextEdit } from "../components/Edit/Product3DTextEdit";
import { Product3DTextView } from "../components/View/Product3DTextView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_3_D_TEXT_LIST = {
    path: "/admin/product-3-d-text",
    component: Product3DTextList,
    menu: {
        key: "PRODUCT_3_D_TEXT_LIST",
        label: "management.product_3_d_text.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_3_D_TEXT_CREATE = {
    path: "/admin/product-3-d-text/create",
    component: Product3DTextCreate,
};
export const PRODUCT_3_D_TEXT_EDIT = {
    path: "/admin/product-3-d-text/:id/edit",
    component: Product3DTextEdit,
};
export const PRODUCT_3_D_TEXT_VIEW = {
    path: "/admin/product-3-d-text/:id/view",
    component: Product3DTextView,
};

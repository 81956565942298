import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Spin } from "antd";
import { Product } from "@bundles/UIAppBundle/components/Product";
import ProductVariantAttribute from "../ProductVariantAttribute/ProductVariantAttribute";
import { ControlledSelect } from "..";
import { Model3DViewer } from "../Model3DViewer";
import styles from "./ProductDetail.module.scss";
import { useProductPage } from "./hooks";
// eslint-disable-next-line react/prop-types
const ProductDetail = ({ id }) => {
    var _a, _b, _c;
    const { isLoading, oldPrice, price, additionalInfo, productVariant, attributeOptions, product, unitOfMeasurementOptions, onChangeUnitOfMeasurement, } = useProductPage(id);
    if (isLoading || !productVariant) {
        return _jsx("div", { children: "Loading..." }, void 0);
    }
    const { name, tags: categories, description } = productVariant || {};
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles["product"] }, { children: [_jsx(Product, { imageURL: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.salesRayImageUrl) ||
                        ((_a = productVariant === null || productVariant === void 0 ? void 0 : productVariant.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl), title: name, tags: categories, description: description, oldPrice: oldPrice, newPrice: price }, void 0), _jsxs("div", Object.assign({ className: styles["general-info"] }, { children: [_jsx("div", Object.assign({ className: styles["general-info__title"] }, { children: "General Info" }), void 0), _jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("div", Object.assign({ className: styles["general-info__table"] }, { children: additionalInfo &&
                                        additionalInfo.map((info, idx) => info.value && (_jsxs("div", Object.assign({ className: styles["general-info__table__row"] }, { children: [_jsxs("div", Object.assign({ className: styles["general-info__table__row__key"] }, { children: [info.label, " "] }), void 0), _jsx("div", Object.assign({ className: styles["general-info__table__row__value"] }, { children: info.value }), void 0)] }), idx))) }), void 0), _jsxs("div", Object.assign({ className: styles.attributes }, { children: [attributeOptions.map((attribute, key1) => {
                                            if ((attribute === null || attribute === void 0 ? void 0 : attribute.values) && attribute.values.length > 0)
                                                return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.attributeName }, { children: attribute === null || attribute === void 0 ? void 0 : attribute.name }), void 0), _jsx("div", Object.assign({ className: styles.attributeValues }, { children: attribute === null || attribute === void 0 ? void 0 : attribute.values.map((value, key2) => (_jsx(ProductVariantAttribute, { product: product, productVariant: productVariant, name: attribute.name, value: value }, key2))) }), void 0)] }, key1));
                                        }), _jsx(ControlledSelect, { label: "Unit of Measurement", options: unitOfMeasurementOptions, onChange: onChangeUnitOfMeasurement, value: productVariant === null || productVariant === void 0 ? void 0 : productVariant.unitOfMeasurementId }, void 0)] }), void 0)] }), void 0)] }), void 0), ((_b = productVariant === null || productVariant === void 0 ? void 0 : productVariant.model3D) === null || _b === void 0 ? void 0 : _b.downloadUrl) && (_jsx(Suspense, Object.assign({ fallback: _jsx("div", Object.assign({ className: styles["model3d-loading"] }, { children: _jsx(Spin, { size: "large" }, void 0) }), void 0) }, { children: _jsx(Model3DViewer, { model3DUrl: (_c = productVariant === null || productVariant === void 0 ? void 0 : productVariant.model3D) === null || _c === void 0 ? void 0 : _c.downloadUrl }, void 0) }), void 0))] }), void 0) }), void 0));
};
export default ProductDetail;

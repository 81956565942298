import { useEffect, useState } from "react";
// import { useLazyQuery } from "@apollo/client";
// import { EXPORT_PRODUCTS } from "./queries";
export const useExportProducts = () => {
    const [step, setStep] = useState(0); // TODO replace it with useLazyQuery Result
    const [exported, setExported] = useState(0);
    const [total, setTotal] = useState(156);
    useEffect(() => {
        setTotal(156);
    }, []);
    // // TODO replace EXPORT_PRODUCTS query
    // const [exportProducts, { called, data, loading, error }] = useLazyQuery(
    //   EXPORT_PRODUCTS,
    //   {},
    // );
    // TODO remove me
    useEffect(() => {
        let t1;
        if (step === 1) {
            setExported(0);
            t1 = setInterval(() => {
                setExported((exported) => exported + 1);
            }, 50);
        }
        return () => {
            if (t1)
                clearInterval(t1);
        };
    }, [step]);
    // TODO remove me
    useEffect(() => {
        if (exported === total) {
            setStep(2);
        }
    }, [exported]);
    const onClickStartExporting = () => {
        alert("// TODO start exporting");
        setStep(1);
    };
    const onClickStopExporting = () => {
        alert("// TODO stop exporting");
        setStep(0);
    };
    const onClickStartNewExport = () => {
        setStep(0);
    };
    const onClickDownload = () => {
        alert("// TODO download");
    };
    return {
        // called,
        // loading,
        // data,
        // error,
        exported,
        total,
        fileName: "Products_2022_V3",
        date: new Date(2022, 9, 21, 14, 33),
        step,
        onClickStartExporting,
        onClickStopExporting,
        onClickStartNewExport,
        onClickDownload,
    };
};

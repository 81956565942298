var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let NotificationList = class NotificationList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                title: t("management.notifications.fields.title"),
                key: "management.notifications.fields.title",
                dataIndex: ["title"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "type",
                title: t("management.notifications.fields.type"),
                key: "management.notifications.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "redirectLink",
                title: t("management.notifications.fields.redirectLink"),
                key: "management.notifications.fields.redirectLink",
                dataIndex: ["redirectLink"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "metadata.productVariantId",
                title: t("management.notifications.fields.metadata.productVariantId"),
                key: "management.notifications.fields.metadata.productVariantId",
                dataIndex: ["metadata", "productVariantId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "metadata.clientId",
                title: t("management.notifications.fields.metadata.clientId"),
                key: "management.notifications.fields.metadata.clientId",
                dataIndex: ["metadata", "clientId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "metadata.orderId",
                title: t("management.notifications.fields.metadata.orderId"),
                key: "management.notifications.fields.metadata.orderId",
                dataIndex: ["metadata", "orderId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.notifications.fields.createdAt"),
                key: "management.notifications.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.notifications.fields.updatedAt"),
                key: "management.notifications.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "users",
                title: t("management.notifications.fields.users"),
                key: "management.notifications.fields.users",
                dataIndex: ["users"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.NOTIFICATION_SUBSCRIBERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            users: "users._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            type: 1,
            redirectLink: 1,
            metadata: {
                productVariantId: 1,
                clientId: 1,
                orderId: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            users: {
                _id: 1,
            },
        };
    }
};
NotificationList = __decorate([
    Service({ transient: true })
], NotificationList);
export { NotificationList };

import { useMutation } from "@apollo/client";
import { useDataOne, useRouter, useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { UserRole } from "@root/api.types";
import { INTERNAL_USER_PROFILES_LIST } from "../../routes";
import { INTERNAL_USER_UPDATE } from "./mutation";
import { useEffect, useState } from "react";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { InternalUserProfilesCollection } from "@bundles/UIAppBundle/collections";
import { ObjectId } from "@bluelibs/ejson";
import { InternalUserProfileEditForm } from "../../config/InternalUserProfileEditForm";
import { useAvatarUpload } from "@bundles/UIAppBundle/components/Avatar/hooks";
export const useInternalUserProfilesEdit = (id) => {
    const { data: document, isLoading, error, } = useDataOne(InternalUserProfilesCollection, new ObjectId(id), InternalUserProfileEditForm.getRequestBody());
    const router = useRouter();
    const { uploadAvatar, setAvatarPreview, AvatarUpload } = useAvatarUpload();
    const [updateIsLoading, setUpdateIsLoading] = useState(false);
    const t = useTranslate("management.internal_user_profiles");
    useEffect(() => {
        var _a, _b, _c;
        setAvatarPreview(generateProfileImageLink(document === null || document === void 0 ? void 0 : document.fullName, (_c = (_b = (_a = document === null || document === void 0 ? void 0 : document.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl));
    }, [document]);
    const [updateTeamMemberMutation] = useMutation(INTERNAL_USER_UPDATE);
    const rolesOptionKeys = Object.keys(UserRole);
    const rolesOptions = rolesOptionKeys.map((r) => ({
        value: r,
        label: r.split("_").join(" "),
    }));
    const updateTeamMember = async (data) => {
        try {
            await updateTeamMemberMutation({
                variables: {
                    id: document.user._id,
                    input: data,
                },
            });
        }
        catch (error) {
            error.title = "Failed updating Team Member information";
            throw error;
        }
    };
    const handleUserUpdate = async (data) => {
        setUpdateIsLoading(true);
        try {
            await updateTeamMember(data);
            await uploadAvatar(document.userId);
            notification.success({ message: t("edit_confirmation") });
            router.go(INTERNAL_USER_PROFILES_LIST);
        }
        catch (error) {
            notification.error({
                message: error.title,
                description: error.message,
            });
        }
        finally {
            setUpdateIsLoading(false);
        }
    };
    return {
        document,
        isLoading,
        error,
        handleUserUpdate,
        rolesOptions,
        updateIsLoading,
        AvatarUpload,
    };
};

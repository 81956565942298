import { createElement as _createElement } from "react";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { Button, Card, FieldGroupWrapper, } from "@bundles/UIAppBundle/components";
import { AttributeListItem } from "@bundles/UIAppBundle/components/AttributeListItem";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { scrollToFirstFormFieldError } from "@bundles/UIAppBundle/utils/helpers";
import { useProductCategoryCreate } from "./hooks";
import styles from "./ProductCategoriesCreate.module.scss";
// eslint-disable-next-line react/prop-types
export function ProductCategoriesCreateForm({ id }) {
    var _a, _b;
    const t = useTranslate();
    const shopSettings = useShopSettings();
    const { form, formInstance, onSubmit, mutationLoading } = useProductCategoryCreate(id);
    return (_jsxs(Ant.Form, Object.assign({ requiredMark: "optional", form: formInstance, onFinish: (document) => onSubmit(document), scrollToFirstError: scrollToFirstFormFieldError }, { children: [_jsx(Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "General" }, { children: [form.render("name"), form.render("description"), form.render("image")] }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsx(FieldGroupWrapper, Object.assign({ title: "Vat Rate" }, { children: form.render("vatRateId") }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "Discount" }, { children: [form.render("discountPercentage"), form.render("discountActiveUntil")] }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "Attributes" }, { children: [_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Row, { children: (_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.attributeOptions) === null || _b === void 0 ? void 0 : _b.map(
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                ({ __typename, ...rest }, idx) => (_createElement(AttributeListItem, { ...rest, key: idx }))) }, void 0) }), void 0), form.render("attributeOptions")] }), void 0) }), void 0), _jsx(Button, Object.assign({ type: "submit", width: "auto", className: styles["submit-btn"], icon: Checkmark, loading: mutationLoading }, { children: t("generics.save_changes") }), void 0)] }), void 0));
}

import { newSmart } from "@bluelibs/smart";
import { useMemo, useState } from "react";
import { ReturnsAntTableSmart } from "./ReturnsTableSmart";
export const useReturnsList = () => {
    const [api, Provider] = newSmart(ReturnsAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const nextFilters = { ...filters, ...newFilters };
            setFilters(nextFilters);
            api.setFlexibleFilters(nextFilters);
        };
    }, [filters]);
    return {
        api,
        Provider,
        filtersOpened,
        setFiltersOpened,
        createModalOpen,
        setCreateModalOpen,
        onFiltersUpdate,
    };
};

import { i18n } from "@bundles/UIAppBundle/i18n";
import AuthenticationMessages from "./Authentication.i18n.json";
import { Login } from "./Login";
import { Register } from "./Register/Register";
import { VerifyEmail } from "./VerifyEmail/VerifyEmail";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { ChangePassword } from "./ChangePassword/ChangePassword";
i18n.push(AuthenticationMessages);
export const LOGIN = {
    path: "/login",
    component: Login,
};
export const REGISTER = {
    path: "/register",
    component: Register,
};
export const VERIFY_EMAIL = {
    path: "/verify-email/:token",
    component: VerifyEmail,
};
export const FORGOT_PASSWORD = {
    path: "/forgot-password",
    component: ForgotPassword,
};
export const RESET_PASSWORD = {
    path: "/reset-password/:token",
    component: ResetPassword,
};
export const CHANGE_PASSWORD = {
    path: "/change-password",
    component: ChangePassword,
};

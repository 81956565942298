import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { CustomPageHeader } from "@bundles/UIAppBundle/components/CustomPageHeader";
import { Table } from "@bundles/UIAppBundle/components/Table";
import styles from "./ProductVariantsList.module.scss";
import { ProductVariantsListFilters } from "./ProductVariantsListFilters";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { useProductVariant } from "./hooks";
const filterOptions = [
    { label: "All", value: null },
    {
        label: "Published",
        value: true,
    },
    { label: "Not Published", value: false },
];
export function ProductVariantsList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { api, Provider, filtersOpened, isLoadingSync, setFiltersOpened, onFiltersUpdate, onClickSync, } = useProductVariant();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.productVariant.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.product_variants.list.header"), searchKey: "name", searchPlaceholder: t("management.product_variants.list.search"), syncLabel: t("management.product_variants.list.sync"), isLoadingSync: isLoadingSync, onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened, onClickSync: onClickSync }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsxs(Provider, { children: [filtersOpened && (_jsx(ProductVariantsListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Table, Object.assign({ className: styles["table"] }, api.getTableProps()), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { ProductCategoryViewer as BaseProductCategoryViewer } from "./ProductCategoryViewer.base";
let ProductCategoryViewer = class ProductCategoryViewer extends BaseProductCategoryViewer {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            _id: 1,
            name: 1,
            description: 1,
            attributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            discountPercentage: 1,
            discountActiveUntil: 1,
            categoriesBreadcrumbs: {
                name: 1,
                _id: 1,
                discountPercentage: 1,
                discountActiveUntil: 1,
                attributeOptions: {
                    name: 1,
                    type: 1,
                    isRequired: 1,
                    isEnum: 1,
                    enumValues: 1,
                    isRange: 1,
                    defaultValue: 1,
                    defaultSuffix: 1,
                    rangeMin: 1,
                    rangeMax: 1,
                },
            },
            tags: 1,
            productsCount: 1,
            subCategoriesCount: 1,
            isRoot: 1,
            parent: {
                _id: 1,
                name: 1,
            },
            parentId: 1,
            subcategories: {
                _id: 1,
                name: 1,
            },
            products: {
                _id: 1,
                name: 1,
            },
            vatRate: {
                _id: 1,
                name: 1,
            },
            vatRateId: 1,
            image: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            imageId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
ProductCategoryViewer = __decorate([
    Service({ transient: true })
], ProductCategoryViewer);
export { ProductCategoryViewer };

import { ShopSettingsList } from "../components/List/ShopSettingsList";
import { ShopSettingsCreate } from "../components/Create/ShopSettingsCreate";
import { ShopSettingsEdit } from "../components/Edit/ShopSettingsEdit";
import { ShopSettingsView } from "../components/View/ShopSettingsView";
import { SettingFilled } from "@ant-design/icons";
export const SHOP_SETTINGS_LIST = {
    path: "/admin/shop-settings",
    component: ShopSettingsList,
    menu: {
        key: "SHOP_SETTINGS_LIST",
        label: "management.shop_settings.menu.title",
        icon: SettingFilled,
    },
};
export const SHOP_SETTINGS_CREATE = {
    path: "/admin/shop-settings/create",
    component: ShopSettingsCreate,
};
export const SHOP_SETTINGS_EDIT = {
    path: "/admin/shop-settings/:id/edit",
    component: ShopSettingsEdit,
};
export const SHOP_SETTINGS_VIEW = {
    path: "/admin/shop-settings/:id/view",
    component: ShopSettingsView,
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { AdminShippingOptionCreate } from "@bundles/UIAppBundle/pages/ShippingOptionsManagement/components/Create/mutations";
import { AdminGetShippingOptions } from "@bundles/UIAppBundle/pages/ShippingOptionsManagement/components/List/queries";
import { AdminShippingOptionUpdate } from "./../../pages/ShippingOptionsManagement/components/Edit/mutations";
import { ShippingOptionsCollection, } from "./ShippingOptions.collection";
let CustomShippingOptionsCollection = class CustomShippingOptionsCollection extends ShippingOptionsCollection {
    async find() {
        return this.apolloClient
            .query({
            query: AdminGetShippingOptions,
            variables: {},
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => {
            return result.data.AdminGetShippingOptions;
        })
            .catch(() => { });
    }
    async insertOne(document) {
        return this.apolloClient
            .mutate({
            mutation: AdminShippingOptionCreate,
            variables: { input: { ...document } },
        })
            .then((result) => result.data.AdminShippingOptionCreate)
            .catch((error) => {
            throw error;
        });
    }
    async updateOne(_id, update) {
        return this.apolloClient
            .mutate({
            mutation: AdminShippingOptionUpdate,
            variables: {
                input: {
                    _id,
                    document: update,
                },
            },
        })
            .then((result) => result.data.AdminShippingOptionUpdate)
            .catch((error) => {
            throw error;
        });
    }
};
CustomShippingOptionsCollection = __decorate([
    Service()
], CustomShippingOptionsCollection);
export { CustomShippingOptionsCollection };

import { jsx as _jsx } from "react/jsx-runtime";
import { AddOrder } from "./components/AddOrder";
import { AddProductsFromOrder } from "./components/AddProductsFromOrder";
export const getSteps = (formInstance) => [
    {
        title: "CREATE A RETURN FROM ORDERS",
        content: _jsx(AddOrder, { stepForm: formInstance }, void 0),
        validator: () => {
            if (formInstance.getFieldValue("orderInfo"))
                return true;
            else
                return false;
        },
        error: "Please select one of the above",
    },
    {
        title: "ADD PRODUCTS FROM ORDER",
        content: _jsx(AddProductsFromOrder, { stepForm: formInstance }, void 0),
        validator: () => {
            var _a;
            if ((_a = formInstance.getFieldValue("items")) === null || _a === void 0 ? void 0 : _a.length)
                return true;
            else
                return false;
        },
        error: "Please select atleast one of the above",
    },
];

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ShippingOptionsCollection, } from "@bundles/UIAppBundle/collections";
let ShippingOptionEditForm = class ShippingOptionEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.shipping_options.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.shipping_options.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "price",
                label: t("management.shipping_options.fields.price"),
                name: ["price"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "type",
                label: t("management.shipping_options.fields.type"),
                name: ["type"],
                initialValue: "NATIONAL",
                render: (props) => {
                    const options = [
                        { value: "NATIONAL", label: "National" },
                        { value: "INTERNATIONAL", label: "International" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.shipping_options.fields.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.shipping_options.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.shipping_options.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            description: 1,
            price: 1,
            type: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_options.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ShippingOptionsCollection),
    __metadata("design:type", typeof (_a = typeof ShippingOptionsCollection !== "undefined" && ShippingOptionsCollection) === "function" ? _a : Object)
], ShippingOptionEditForm.prototype, "collection", void 0);
ShippingOptionEditForm = __decorate([
    Service({ transient: true })
], ShippingOptionEditForm);
export { ShippingOptionEditForm };

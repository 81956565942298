import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { useUIComponents, useTranslate, useRouter } from "@bluelibs/x-ui";
import { CustomPageHeader } from "@bundles/UIAppBundle/components/CustomPageHeader";
import { Routes } from "@bundles/UIAppBundle";
import { Table } from "@bundles/UIAppBundle/components/Table";
import { useInternalUserProfilesList } from "./hooks";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const filterOptions = [
    { label: "All", value: null },
    { label: "Active", value: false },
    { label: "Suspended", value: true },
];
export function InternalUserProfilesList() {
    const router = useRouter();
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { Provider, api, onFiltersUpdate } = useInternalUserProfilesList();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.TEAM_ADMIN] }, { children: [_jsx(Seo, { data: seoData.internalUserProfile.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.internal_user_profiles.list.header"), searchKey: "fullName", handleCreateButtonClick: () => router.go(Routes.INTERNAL_USER_PROFILES_CREATE), createNewLabel: t("management.internal_user_profiles.list.create_btn"), searchPlaceholder: t("management.internal_user_profiles.list.search"), filterOptions: filterOptions, onFiltersUpdate: onFiltersUpdate, filterKey: "isSuspended" }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsx(Table, Object.assign({}, api.getTableProps()), void 0) }, void 0) }, void 0)] }, void 0)] }), void 0));
}

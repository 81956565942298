var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Product3DModelList as BaseProduct3DModelList } from "./Product3DModelList.base";
import { CustomTag } from "@bundles/UIAppBundle/components";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { Routes } from "@bundles/UIAppBundle";
let Product3DModelList = class Product3DModelList extends BaseProduct3DModelList {
    build() {
        super.build();
        this.remove("images");
        this.remove("texts");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("createdAt");
        this.remove("secret");
        this.update("name", { order: 1 });
        this.update("endUserProfile", {
            id: "endUserProfileId",
            order: 2,
            render: (value) => {
                if (!value)
                    return undefined;
                return (_jsx(CustomTag, { value: value.fullName, variant: "filled", onClick: () => this.router.go(Routes.END_USER_PROFILES_VIEW, {
                        params: { id: value._id.toString() },
                    }), color: TagColorsEnum.BLUE }, void 0));
            },
        });
        this.update("productVariant", {
            order: 3,
            render: (value) => (_jsx(CustomTag, { value: value.name, variant: "filled", onClick: () => this.router.go(Routes.PRODUCT_VARIANTS_VIEW, {
                    params: { id: value._id.toString() },
                }), color: TagColorsEnum.GREEN }, void 0)),
        });
        this.update("orderItem", {
            order: 4,
            render: (value) => {
                if (!value)
                    return undefined;
                return (_jsx(CustomTag, { value: value === null || value === void 0 ? void 0 : value.productSnapshot.name, variant: "filled", onClick: () => this.router.go(Routes.ORDER_ITEMS_VIEW, {
                        params: { id: value._id.toString() },
                    }), color: TagColorsEnum.GRAY }, void 0));
            },
        });
        this.update("companyName", { order: 5 });
        this.update("email", { order: 6 });
        this.update("phoneNumber", { order: 7 });
        this.update("note", { order: 8 });
        this.update("updatedAt", { order: 9 });
        // Perform additional modifications such as updating how a list item renders or add additional ones
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            companyName: 1,
            email: 1,
            phoneNumber: 1,
            note: 1,
            createdAt: 1,
            updatedAt: 1,
            images: {
                _id: 1,
            },
            texts: {
                _id: 1,
            },
            productVariant: {
                _id: 1,
                name: 1,
            },
            productVariantId: 1,
            endUserProfile: {
                _id: 1,
                fullName: 1,
            },
            endUserProfileId: 1,
            orderItem: {
                _id: 1,
                productSnapshot: {
                    name: 1,
                },
            },
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
Product3DModelList = __decorate([
    Service({ transient: true })
], Product3DModelList);
export { Product3DModelList };

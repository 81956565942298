import { gql } from "@apollo/client";
export const GET_PAYMENT_OPTIONS = gql `
  query AdminGetPaymentOptions {
    AdminGetPaymentOptions {
      _id
      name
    }
  }
`;
export const GET_INTERNAL_USER_PROFILES = gql `
  query InternalUserProfileAdminListGet(
    $input: InternalUserProfileAdminListInput
  ) {
    InternalUserProfileAdminListGet(input: $input) {
      data {
        _id
        fullName
        user {
          roles
        }
      }
    }
  }
`;

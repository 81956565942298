import { gql } from "@apollo/client";
export const AdminInvoiceGeneratePdf = gql `
  query AdminInvoiceGeneratePdf($id: ObjectId!) {
    AdminInvoiceGeneratePdf(_id: $id) {
      downloadUrl
    }
  }
`;
export const GET_INVOICE_DETAIL = gql `
  query AdminGetInvoice($_id: ObjectId) {
    AdminGetInvoice(_id: $_id) {
      _id
      billingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      items {
        name
        price {
          gross
          net
          tax
          currency
        }
        count
      }
      sendAt
      createdAt
      price {
        currency
        gross
        net
        tax
      }
      shippingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      invoiceId
      downloadUrl
    }
  }
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let PaymentList = class PaymentList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "externalPaymentIdentifier",
                title: t("management.payments.fields.externalPaymentIdentifier"),
                key: "management.payments.fields.externalPaymentIdentifier",
                dataIndex: ["externalPaymentIdentifier"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paymentProcessor",
                title: t("management.payments.fields.paymentProcessor"),
                key: "management.payments.fields.paymentProcessor",
                dataIndex: ["paymentProcessor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "notes",
                title: t("management.payments.fields.notes"),
                key: "management.payments.fields.notes",
                dataIndex: ["notes"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paidAt",
                title: t("management.payments.fields.paidAt"),
                key: "management.payments.fields.paidAt",
                dataIndex: ["paidAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.payments.fields.createdAt"),
                key: "management.payments.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.payments.fields.updatedAt"),
                key: "management.payments.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyer",
                title: t("management.payments.fields.buyer"),
                key: "management.payments.fields.buyer",
                dataIndex: ["buyer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "invoice",
                title: t("management.payments.fields.invoice"),
                key: "management.payments.fields.invoice",
                dataIndex: ["invoice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INVOICES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "invoiceId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            buyer: "buyer.fullName",
            invoice: "invoice.invoiceId",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            externalPaymentIdentifier: 1,
            paymentProcessor: 1,
            notes: 1,
            paidAt: 1,
            createdAt: 1,
            updatedAt: 1,
            buyer: {
                _id: 1,
                fullName: 1,
            },
            buyerId: 1,
            invoice: {
                _id: 1,
                invoiceId: 1,
            },
            invoiceId: 1,
        };
    }
};
PaymentList = __decorate([
    Service({ transient: true })
], PaymentList);
export { PaymentList };

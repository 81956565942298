import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/List/ProductVariantsList.module.scss";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ProductVariantReviewsTableSmart } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/View/ProductVariantReviewsTableSmart";
import { ObjectId } from "@bluelibs/ejson";
export function ProductVariantReviewsTable(props) {
    const t = useTranslate();
    const [api, Provider] = newSmart(ProductVariantReviewsTableSmart, {
        initialFilters: {
            productVariantId: new ObjectId(props.productVariantId),
        },
    });
    return (_jsxs("div", { children: [api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Provider, { children: _jsx(Table, Object.assign({ className: styles["table"] }, api.getTableProps()), void 0) }, void 0)] }, void 0));
}

import { useMutation, useQuery } from "@apollo/client";
import { use, useRouter, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { AdminShopSettingEdit } from "./mutations";
import { AdminGetShopSettings } from "./queries";
export const useShopConfigForm = (constructor) => {
    const form = use(constructor, { transient: true });
    const t = useTranslate();
    const router = useRouter();
    const [updateShopSettings] = useMutation(AdminShopSettingEdit);
    const { data, loading, error } = useQuery(AdminGetShopSettings);
    const document = data === null || data === void 0 ? void 0 : data.AdminGetShopSettings;
    const onSubmit = async (document) => {
        updateShopSettings({
            variables: { document },
        })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shop_settings.edit_confirmation"),
            });
            router.history.goBack();
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    };
    form.build();
    return { form, document, loading, error, onSubmit };
};

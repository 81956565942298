import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Form, notification } from "antd";
import { CREATE_RETURN } from "./mutation";
import { getSteps } from "./formSteps";
export const useCreateReturn = (setModalOpen) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [createReturn] = useMutation(CREATE_RETURN);
    const [stepForm] = Form.useForm();
    const steps = getSteps(stepForm);
    const next = () => {
        if (steps[currentStep].validator()) {
            setCurrentStep(currentStep + 1);
            setError("");
        }
        else {
            setError(steps[currentStep].error);
        }
    };
    const prev = () => {
        setCurrentStep(currentStep - 1);
        setError("");
    };
    const onConfirm = async () => {
        if (steps[currentStep].validator()) {
            await onFormSubmit(stepForm);
            setCurrentStep(0);
            setModalOpen(false);
        }
        else {
            setError(steps[currentStep].error);
        }
    };
    const onFormSubmit = (stepForm) => {
        setIsLoading(true);
        const formData = stepForm.getFieldsValue(true);
        const { items } = formData;
        return createReturn({
            variables: {
                input: {
                    items: items.map(({ _id, returnCount, count }) => ({
                        orderItemId: _id,
                        quantity: returnCount || count,
                    })),
                },
            },
        })
            .then(() => notification.success({
            message: "Return created",
        }))
            .catch((error) => notification.error({
            message: "Return creation failed",
            description: error.message,
        }))
            .finally(() => {
            stepForm.resetFields();
            setIsLoading(false);
        });
    };
    return {
        currentStep,
        setCurrentStep,
        error,
        isLoading,
        onFormSubmit,
        steps,
        stepForm,
        next,
        prev,
        onConfirm,
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { CustomVatRatesCollection, } from "@bundles/UIAppBundle/collections";
import { Edit, KebabMenu } from "@bundles/UIAppBundle/icons";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { VatRateList } from "../../config/VatRateList";
import { features } from "../../config/features";
import { Routes } from "@bundles/UIAppBundle";
export class VatRatesAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomVatRatesCollection;
    }
    getBody() {
        return VatRateList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(VatRateList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return VatRateList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(Edit, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.VAT_RATES_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        return actions;
    }
}

import { NotFound } from "@bundles/UIAppBundle/overrides";
import { PRODUCT_3_D_IMAGE_LIST as BASE_PRODUCT_3_D_IMAGE_LIST, PRODUCT_3_D_IMAGE_CREATE as BASE_PRODUCT_3_D_IMAGE_CREATE, PRODUCT_3_D_IMAGE_EDIT as BASE_PRODUCT_3_D_IMAGE_EDIT, PRODUCT_3_D_IMAGE_VIEW as BASE_PRODUCT_3_D_IMAGE_VIEW, } from "./config/routes";
import "./i18n";
export const PRODUCT_3_D_IMAGE_LIST = {
    ...BASE_PRODUCT_3_D_IMAGE_LIST,
    component: NotFound,
};
export const PRODUCT_3_D_IMAGE_CREATE = {
    ...BASE_PRODUCT_3_D_IMAGE_CREATE,
    component: NotFound,
};
export const PRODUCT_3_D_IMAGE_EDIT = {
    ...BASE_PRODUCT_3_D_IMAGE_EDIT,
    component: NotFound,
};
export const PRODUCT_3_D_IMAGE_VIEW = {
    ...BASE_PRODUCT_3_D_IMAGE_VIEW,
    component: NotFound,
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import classNames from "classnames";
import { Reset } from "@bundles/UIAppBundle/icons";
import { Button } from "../Button";
import { useCustomTableFilters } from "./hooks";
import styles from "./CustomTableFilters.module.scss";
const CustomTableFilters = ({ onUpdate, className, formInstance, }) => {
    const t = useTranslate();
    const { debouncedFilterUpdates, form, onReset } = useCustomTableFilters(onUpdate);
    const containerClasses = classNames(styles.container, className);
    return (_jsxs("div", Object.assign({ className: containerClasses }, { children: [_jsx("h2", { children: "Filters" }, void 0), _jsx(Ant.Form, Object.assign({ form: form, labelCol: { span: 24 }, onValuesChange: debouncedFilterUpdates }, { children: _jsxs(Ant.Row, Object.assign({ gutter: [16, 8], className: styles["filters-container"] }, { children: [formInstance.rest().map((item) => {
                            return useMemo(() => (_jsx(Ant.Col, Object.assign({ flex: "1 0 auto" }, { children: _jsx(Ant.Row, Object.assign({ gutter: 10 }, { children: formInstance.render(item) }), void 0) }), item.id)), []);
                        }), _jsx("div", Object.assign({ className: styles["btn-container"] }, { children: _jsx(Button, Object.assign({ onClick: onReset, width: "auto", btnStyle: "white", className: styles["reset-btn"], icon: Reset }, { children: t("generics.list_filters_reset") }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0));
};
export default React.memo(CustomTableFilters);

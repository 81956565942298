import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Card } from "@bundles/UIAppBundle/components";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import styles from "./ShippingMethod.module.scss";
const ShippingMethod = ({ shippingOption }) => {
    const shopSettings = useShopSettings();
    return (_jsx(Card, Object.assign({ title: "Shipping Method", className: styles["custom-card"] }, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsx("div", { children: shippingOption.name }, void 0), _jsx("div", Object.assign({ className: "" }, { children: shippingOption.description }), void 0), _jsx("div", Object.assign({ className: "" }, { children: shopSettings.formatPriceWithCurrency(shippingOption.price) }), void 0)] }), void 0) }), void 0));
};
export default ShippingMethod;

import { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { My3DProductModels } from "./queries";
import { defaultModel, DecalType } from "./consts";
export const use3DProduct = (id) => {
    const [model, setModel] = useState(defaultModel);
    const [selectedDecalIndex, setSelectedDecalIndex] = useState(0);
    const groupRef = useRef(null);
    const { data, loading: isLoading } = useQuery(My3DProductModels, {
        variables: {
            query: {
                filters: {
                    _id: { $objectId: id },
                },
            },
        },
    });
    useEffect(() => {
        var _a;
        if (data === null || data === void 0 ? void 0 : data.Product3DModelFindOne) {
            const { texts, images, productVariant } = data.Product3DModelFindOne;
            const textDecals = texts === null || texts === void 0 ? void 0 : texts.map(({ _id, text, size, position, rotation, fontColor, fontSize, backgroundColor, nodeName, }) => ({
                _id,
                text,
                scale: size,
                rotationX: rotation.x,
                rotationY: rotation.y,
                rotationZ: rotation.z,
                type: DecalType.TEXT,
                fontColor,
                fontSize,
                backgroundColor,
                ...position,
                nodeName,
            }));
            const imageDecals = images === null || images === void 0 ? void 0 : images.map(({ _id, size, position, rotation, image, nodeName, }) => ({
                _id,
                scale: size,
                x: position.x,
                y: position.y,
                z: position.z,
                href: image === null || image === void 0 ? void 0 : image.downloadUrl,
                fileName: image === null || image === void 0 ? void 0 : image.name,
                rotationX: rotation.x,
                rotationY: rotation.y,
                rotationZ: rotation.z,
                type: DecalType.IMAGE,
                nodeName,
            }));
            setModel({
                file: (_a = productVariant.model3D) === null || _a === void 0 ? void 0 : _a.downloadUrl,
                decals: [...textDecals, ...imageDecals],
            });
        }
    }, [data === null || data === void 0 ? void 0 : data.Product3DModelFindOne]);
    const onSelectDecal = (index) => {
        const newModel = { ...model };
        newModel.decals[selectedDecalIndex].debug = false;
        newModel.decals[index].debug = true;
        setModel(newModel);
        setSelectedDecalIndex(index);
    };
    const onClickDownload = () => {
        const exporter = new GLTFExporter();
        exporter.parse(groupRef.current, (gltf) => {
            const link = document.createElement("a");
            link.style.display = "none";
            document.body.appendChild(link);
            const blob = new Blob([gltf], {
                type: "application/octet-stream",
            });
            link.href = URL.createObjectURL(blob);
            link.download = "model.glb";
            link.click();
        }, (error) => {
            console.log({ error });
        }, { includeCustomExtensions: true, binary: true });
    };
    return {
        model,
        groupRef,
        isLoading,
        selectedDecalIndex,
        onSelectDecal,
        onClickDownload,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { findDefaultPage } from "./../Authentication/Login/hooks";
export function Home() {
    var _a;
    const { state } = useAppGuardian();
    const router = useRouter();
    if (state.initialised && state.isLoggedIn) {
        findDefaultPage(((_a = state.user) === null || _a === void 0 ? void 0 : _a.roles) || []).then((route) => {
            router.history.replace(route.path);
        });
    }
    if (state.initialised && !state.isLoggedIn)
        router.history.replace(Routes.LOGIN.path);
    return (_jsxs(PublicRoute, { children: [_jsx(Seo, { data: seoData.home }, void 0), _jsx("div", { children: "Loading...." }, void 0)] }, void 0));
}

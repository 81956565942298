import { jsx as _jsx } from "react/jsx-runtime";
import { Routes } from "@bundles/UIAppBundle";
import { Download, KebabMenu, View } from "@bundles/UIAppBundle/icons";
import { features } from "../../config/features";
import { ReturnList } from "../../config/ReturnList";
import { CustomReturnsCollection } from "@bundles/UIAppBundle/collections/Returns/Returns.custom.collection";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
export class ReturnsAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomReturnsCollection;
    }
    getBody() {
        return ReturnList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ReturnList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return ReturnList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.RETURNS_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        actions.push({
            label: "Invoice",
            icon: _jsx(Download, {}, void 0),
            action: (model) => {
                this.router.go(Routes.INVOICES_VIEW, {
                    params: {
                        id: model.invoiceId,
                    },
                });
            },
        });
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {
            number: undefined,
            status: undefined,
            price: undefined,
            createdAt: undefined,
            paymentMethod: undefined,
        };
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["number"]) {
            modifiedFiltersObj["number"] = modifiedFiltersObj["number"].source;
        }
        if (modifiedFiltersObj.status) {
            modifiedFiltersObj["status"] = modifiedFiltersObj["status"]["$in"];
        }
        if (modifiedFiltersObj["paymentOptionId"]) {
            modifiedFiltersObj["paymentMethod"] =
                modifiedFiltersObj["paymentOptionId"]["$in"];
            delete modifiedFiltersObj["paymentOptionId"];
        }
        if (modifiedFiltersObj.price) {
            modifiedFiltersObj["price"] = {
                gte: parseFloat(modifiedFiltersObj["price"]["$in"][0]),
                lte: parseFloat(modifiedFiltersObj["price"]["$in"][1]),
            };
        }
        if (modifiedFiltersObj.createdAt) {
            modifiedFiltersObj["createdAt"] = {
                gte: (modifiedFiltersObj["createdAt"]["$gte"] || "").valueOf() || null,
                lte: (modifiedFiltersObj["createdAt"]["$lte"] || "").valueOf() || null,
            };
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
                // ...this.state.filters,
                // ...this.alwaysOnFilters,
            },
            options: {
                ...(this.getSortOptions() || { sort: { createdAt: -1 } }),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

import { Collection, } from "@bluelibs/x-ui";
import { MailingList } from "@root/api.types";
export class MailingListsCollection extends Collection {
    getName() {
        return "MailingLists";
    }
    getInputs() {
        return {
            insert: "MailingListInsertInput!",
            update: "MailingListUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Row, Col, Input, PageHeader } from "antd";
import debounce from "lodash.debounce";
import { Button } from "@bundles/UIAppBundle/components/Button";
import { AddSimple as Add, DownArrow, Filters, Search, UpArrow, Sync, } from "@bundles/UIAppBundle/icons";
import { HeaderFilters } from "../HeaderFilters";
import styles from "./CustomPageHeader.module.scss";
const CustomPageHeader = ({ title, searchKey, handleCreateButtonClick, createNewLabel, syncLabel, isLoadingSync, searchPlaceholder, filterOptions, onFiltersUpdate, filtersOpened, setFiltersOpened, filterKey, onClickImport, onClickExport, onClickSync, }) => {
    const onSearch = (e) => {
        if (!searchKey)
            return;
        const value = e.target.value;
        onFiltersUpdate({ [searchKey]: value });
    };
    // debounced search
    const debouncedSearch = useMemo(() => {
        return debounce(onSearch, 500);
    }, [onFiltersUpdate]);
    return (_jsxs(PageHeader, Object.assign({ className: styles["header"] }, { children: [_jsxs(Row, Object.assign({ justify: "space-between", className: styles["first-row"], align: "middle" }, { children: [_jsx(Col, Object.assign({ className: styles.title }, { children: title }), void 0), _jsx(Col, Object.assign({ span: 10 }, { children: _jsx(Input.Search, { className: styles["ant-input-search"], placeholder: searchPlaceholder, onKeyUp: debouncedSearch, enterButton: _jsx(Search, {}, void 0) }, void 0) }), void 0), _jsxs(Row, Object.assign({ align: "middle", gutter: 8 }, { children: [createNewLabel && (
                            // && createNewUrl
                            _jsx(Col, { children: _jsx(Button, Object.assign({ onClick: handleCreateButtonClick, width: "auto", icon: Add }, { children: createNewLabel }), void 0) }, void 0)), onClickSync && (_jsx(Col, { children: _jsx(Button, Object.assign({ onClick: onClickSync, icon: Sync, loading: isLoadingSync }, { children: syncLabel }), void 0) }, void 0))] }), void 0)] }), void 0), _jsxs(Row, Object.assign({ className: styles["second-row"] }, { children: [_jsxs("div", Object.assign({ className: styles["second-row__right"] }, { children: [onClickImport && (_jsx(Button, Object.assign({ icon: DownArrow, btnStyle: "secondary", onClick: onClickImport }, { children: "Import" }), void 0)), onClickExport && (_jsx(Button, Object.assign({ icon: UpArrow, btnStyle: "secondary", onClick: onClickExport }, { children: "Export" }), void 0)), setFiltersOpened && (_jsx(Button, Object.assign({ btnStyle: "white", width: "auto", className: styles["filters-btn"], onClick: () => setFiltersOpened(!filtersOpened), icon: Filters }, { children: "Filter" }), void 0))] }), void 0), filterOptions && (_jsx(HeaderFilters, { filterOptions: filterOptions, onFiltersUpdate: onFiltersUpdate, filterKey: filterKey }, void 0))] }), void 0)] }), void 0));
};
export default CustomPageHeader;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Service } from "@bluelibs/core";
import classNames from "classnames";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Routes } from "@bundles/UIAppBundle";
import { Invoices } from "@bundles/UIAppBundle/icons";
import { CustomTag } from "@bundles/UIAppBundle/components/CustomTag";
import { invoiceTagColors, paymentMethodTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { paymentOptionLabel } from "@bundles/UIAppBundle/consts/labels";
import { InvoiceList as BaseInvoiceList } from "./InvoiceList.base";
import styles from "../components/List/InvoicesList.module.scss";
import { formatCurrencyAndPrice } from "@bundles/UIAppBundle/utils/helpers";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
let InvoiceList = class InvoiceList extends BaseInvoiceList {
    build() {
        super.build();
        const { router } = this;
        const { t } = this.i18n;
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.remove("sendAt");
        this.remove("updatedAt");
        this.remove("footer");
        this.remove("items.description");
        this.remove("items.count");
        this.remove("items.price");
        this.remove("paidAt");
        this.remove("dueDate");
        this.remove("status");
        this.remove("priceVAT");
        this.remove("price.currency");
        this.remove("price.net");
        this.remove("price.tax");
        this.remove("price.gross");
        this.remove("billingAddress.nbh");
        this.remove("billingAddress.building_floor");
        this.remove("billingAddress.zip");
        this.remove("billingAddress.address");
        this.remove("billingAddress.building_entrance");
        this.remove("billingAddress.street");
        this.remove("billingAddress.district");
        this.remove("billingAddress.tax");
        this.remove("billingAddress.note");
        this.remove("billingAddress.num");
        this.remove("billingAddress.salesRayId");
        this.remove("billingAddress.zip_num");
        this.remove("billingAddress.fax");
        this.remove("billingAddress.building");
        this.remove("billingAddress.settlement");
        this.remove("billingAddress.phone");
        this.remove("billingAddress.building_apartment");
        this.remove("billingAddress.firstName");
        this.remove("billingAddress.lastName");
        this.remove("billingAddress.streetAddressLine1");
        this.remove("billingAddress.streetAddressLine2");
        this.remove("billingAddress.city");
        this.remove("billingAddress.country");
        this.remove("billingAddress.county");
        this.remove("billingAddress.zipcode");
        this.remove("billingAddress.fullName");
        this.remove("billingAddress.phoneNumber");
        this.remove("billingAddress.email");
        this.remove("billingAddress.town");
        this.remove("invoicePrefix");
        this.remove("invoiceNumber");
        this.remove("order");
        this.remove("items");
        this.remove("payments");
        this.remove("shippingAddress.nbh");
        this.remove("shippingAddress.building_floor");
        this.remove("shippingAddress.zip");
        this.remove("shippingAddress.address");
        this.remove("shippingAddress.building_entrance");
        this.remove("shippingAddress.street");
        this.remove("shippingAddress.district");
        this.remove("shippingAddress.tax");
        this.remove("shippingAddress.note");
        this.remove("shippingAddress.num");
        this.remove("shippingAddress.salesRayId");
        this.remove("shippingAddress.zip_num");
        this.remove("shippingAddress.fax");
        this.remove("shippingAddress.building");
        this.remove("shippingAddress.settlement");
        this.remove("shippingAddress.phone");
        this.remove("shippingAddress.building_apartment");
        this.remove("shippingAddress.firstName");
        this.remove("shippingAddress.lastName");
        this.remove("shippingAddress.phoneNumber");
        this.remove("shippingAddress.streetAddressLine1");
        this.remove("shippingAddress.streetAddressLine2");
        this.remove("shippingAddress.email");
        this.remove("shippingAddress.city");
        this.remove("shippingAddress.town");
        this.remove("shippingAddress.country");
        this.remove("shippingAddress.zipcode");
        this.remove("shippingPrice.currency");
        this.remove("shippingPrice.gross");
        this.remove("shippingPrice.net");
        this.remove("shippingPrice.tax");
        this.remove("downloadUrl");
        this.add({
            id: "icon",
            width: 0,
            dataIndex: ["status"],
            order: 0,
            render: (value) => (_jsx(CustomTag, { isIcon: true, variant: "filled", value: _jsx(Invoices, {}, void 0), color: invoiceTagColors[value] }, void 0)),
        });
        this.update("invoiceId", {
            order: 1,
            dataIndex: ["invoiceId"],
            render: (value) => {
                return (_jsx("div", Object.assign({ className: classNames(styles["invoice-wrapper"]) }, { children: _jsx("span", { children: value }, void 0) }), void 0));
            },
        });
        this.add({
            id: "orderNumber",
            order: 2,
            title: t("management.invoices.tableHeader.orderId"),
            key: "management.invoices.fields.orderId",
            dataIndex: ["order"],
            sorter: false,
            render: (value) => {
                return (_jsx("span", Object.assign({ className: classNames(styles["order"], styles["clickable"]), onClick: () => router.go(Routes.ORDERS_VIEW, {
                        params: {
                            id: value === null || value === void 0 ? void 0 : value._id,
                        },
                    }) }, { children: value.orderNumber }), void 0));
            },
        });
        this.update("buyer", {
            title: t("management.invoices.tableHeader.buyer"),
            key: "management.invoices.fields.buyer",
            sorter: true,
            dataIndex: ["buyer"],
            order: 3,
            render: (value) => {
                var _a, _b, _c;
                return (_jsxs("div", Object.assign({ className: classNames(styles["buyer-wrapper"], styles["no-wrap"], styles["clickable"]), onClick: () => router.go(Routes.END_USER_PROFILES_VIEW, {
                        params: {
                            id: value === null || value === void 0 ? void 0 : value._id,
                        },
                    }) }, { children: [_jsx("img", { src: generateProfileImageLink(value.fullName, (_c = (_b = (_a = value.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("span", { children: value.fullName }, void 0)] }), void 0));
            },
        });
        this.update("createdAt", {
            order: 4,
            title: t("management.invoices.tableHeader.createdAt"),
            dataIndex: ["createdAt"],
            sorter: true,
            render: (value) => {
                const date = new Date(value);
                const props = {
                    type: "string",
                    value: date.toLocaleString(undefined, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                    }),
                };
                return (_jsx("div", Object.assign({ className: styles["no-wrap"] }, { children: _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: props.value }), void 0) }), void 0));
            },
        });
        this.update("paymentType", {
            order: 4,
            title: t("management.invoices.tableHeader.paymentMethod"),
            dataIndex: ["paymentType", "type"],
            render: (value) => {
                return (_jsx("div", Object.assign({ className: styles["payments"] }, { children: _jsx(CustomTag, { className: styles["tag"], value: value ? paymentOptionLabel[value] : "N/A", variant: "filled", color: value ? paymentMethodTagColors[value] : TagColorsEnum.GRAY }, void 0) }), void 0));
            },
        });
        this.add({
            id: "priceTotal",
            dataIndex: ["price"],
            title: t("management.invoices.tableHeader.price"),
            sorter: true,
            render: (value) => (_jsx("div", Object.assign({ className: styles["price-total"] }, { children: formatCurrencyAndPrice(value.currency, value.gross) }), void 0)),
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return { ...super.getRequestBody(), status: 1 };
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            invoiceId: "invoiceNumber",
            buyer: "clientName",
            "paymentType.type": "paymentMethod",
        };
    }
};
InvoiceList = __decorate([
    Service({ transient: true })
], InvoiceList);
export { InvoiceList };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, use, useDataOne, useTranslate, } from "@bluelibs/x-ui";
import { CouponEditForm } from "../../config/CouponEditForm";
import { features } from "../../config/features";
import { CouponsCollection } from "@bundles/UIAppBundle/collections";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function CouponsEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(CouponEditForm, { transient: true });
    const router = useRouter();
    const { data: document, isLoading, error, } = useDataOne(CouponsCollection, new ObjectId(props.id), CouponEditForm.getRequestBody());
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN] }, { children: [_jsx(Seo, { data: seoData.coupon.edit }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.coupons.edit.header"), onBack: () => window.history.back(), extra: features.view
                            ? [
                                _jsx(Link, Object.assign({ to: router.path(Routes.COUPONS_VIEW, {
                                        params: { id: props.id },
                                    }) }, { children: _jsx(Ant.Button, { children: t("generics.view") }, void 0) }), "view"),
                            ]
                            : [] }, void 0), _jsxs(Ant.Card, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsxs(Ant.Form, Object.assign({}, formLayout, { requiredMark: "optional", initialValues: document, onFinish: (document) => form.onSubmit(props.id, document) }, { children: [form.render(), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0))] }, void 0)] }, void 0)] }), void 0));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Form } from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { ControlledInput, ControlledSelect, ControlledTextArea, RichTextEditor, } from "@bundles/UIAppBundle/components";
import { PostCreateForm as BasePostCreateForm } from "./PostCreateForm.base";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let PostCreateForm = class PostCreateForm extends BasePostCreateForm {
    build() {
        super.build();
        const t = useTranslate();
        // Perform additional modifications such as updating rendering functions, labels, description
        this.remove("heroImageId");
        this.remove("authorId");
        this.update("title", {
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 24 } }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.posts.fields.title")), placeholder: t("management.posts.placeholders.title") }, void 0) }), void 0)),
        });
        this.update("subtitle", {
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 24 } }, { children: _jsx(ControlledTextArea, { label: t("management.posts.fields.subtitle"), placeholder: t("management.posts.placeholders.subtitle"), rows: 3 }, void 0) }), void 0)),
        });
        this.update("slug", {
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 24 } }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.posts.fields.slug")), placeholder: t("management.posts.placeholders.slug") }, void 0) }), void 0)),
        });
        this.update("body", {
            required: true,
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 24 } }, { children: _jsx(RichTextEditor, { placeholder: t("management.posts.placeholders.body") }, void 0) }), void 0)),
        });
        this.update("visibility", {
            render: (props) => {
                const options = [
                    {
                        value: "VISIBLE",
                        label: t("management.posts.fields.visibility_enums.visible"),
                    },
                    {
                        value: "HIDDEN",
                        label: t("management.posts.fields.visibility_enums.hidden"),
                    },
                ];
                return (_jsx(Form.Item, Object.assign({}, props, { label: "", noStyle: true }, { children: _jsx(ControlledSelect, { placeholder: t("management.posts.fields.visibility"), options: options, width: "max-content" }, void 0) }), void 0));
            },
        });
    }
};
PostCreateForm = __decorate([
    Service({ transient: true })
], PostCreateForm);
export { PostCreateForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { Button } from "../Button/index";
import styles from "./ProductBilling.module.scss";
import { useProductBilling } from "./hooks";
const ProductBilling = ({ name, variantInfo, price, quantity, image, product3DModelId, }) => {
    const settings = useShopSettings();
    const { onClickView3DModel } = useProductBilling({ product3DModelId });
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("img", { className: styles.image, src: generateImageLink(image) }, void 0), _jsx("div", Object.assign({ className: styles["name"] }, { children: name }), void 0), _jsx("div", { children: product3DModelId && (_jsx(Button, Object.assign({ onClick: onClickView3DModel }, { children: "3D Model" }), void 0)) }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(price / quantity) }, void 0), _jsxs("div", { children: [quantity, "x"] }, void 0), _jsx("div", Object.assign({ className: styles["total-price"] }, { children: settings.formatPriceWithCurrency(price) }), void 0)] }), void 0));
};
export default ProductBilling;

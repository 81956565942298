var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { a, Is, Schema } from "@bluelibs/validator-bundle";
export const Input = {
    EMAIL: "email",
    NEW_PASSWORD: "newPassword",
    CONFIRM_PASSWORD: "confirmPassword",
};
export const Label = {
    [Input.EMAIL]: "Email",
    [Input.NEW_PASSWORD]: "New Password",
    [Input.CONFIRM_PASSWORD]: "Confirm New Password",
};
export const DefaultValues = {
    email: "",
    newPassword: "",
    confirmPassword: "",
};
export const PlaceHolder = {
    [Input.EMAIL]: "Email",
    [Input.NEW_PASSWORD]: "New Password",
    [Input.CONFIRM_PASSWORD]: "Confirm New Password",
};
let ResetPasswordFormSchema = class ResetPasswordFormSchema {
};
__decorate([
    Is(a
        .string()
        .required("This field is required.")
        .typeError("Should be a string")
        .oneOf([a.ref("newPassword"), null], "Repeat your new password here")),
    __metadata("design:type", String)
], ResetPasswordFormSchema.prototype, "confirmPassword", void 0);
__decorate([
    Is(a
        .string()
        .required("This field is required.")
        .typeError("Should be a string")),
    __metadata("design:type", String)
], ResetPasswordFormSchema.prototype, "newPassword", void 0);
__decorate([
    Is(a
        .string()
        .required("This field is required.")
        .email("Invalid Email")
        .typeError("Should be a string")),
    __metadata("design:type", String)
], ResetPasswordFormSchema.prototype, "email", void 0);
ResetPasswordFormSchema = __decorate([
    Schema()
], ResetPasswordFormSchema);
export { ResetPasswordFormSchema };

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { AntTableSmart } from "@bluelibs/x-ui-admin";
import * as moment from "moment";
import { Button } from "../components/Button";
export class CustomTableSmart extends AntTableSmart {
    generateActions(model, actions) {
        const menu = (_jsx(Ant.Menu, Object.assign({ className: "cc-dropdown-menu" }, { children: actions.items.map((item) => {
                const props = {
                    key: item.label,
                    onClick: undefined,
                };
                if (item.icon) {
                    props.icon = item.icon;
                }
                let children = item.label;
                if (item.confirm) {
                    children = (_jsx(Ant.Popconfirm, Object.assign({ title: item.confirm, onConfirm: () => {
                            item.action(model);
                        } }, { children: item.label }), void 0));
                }
                else {
                    props.onClick = () => item.action(model);
                }
                return (_jsx(Ant.Menu.Item, Object.assign({}, props, { children: children }), props.key));
            }) }), void 0));
        return (_jsx(Ant.Dropdown, Object.assign({ align: { offset: [16, 0] }, trigger: ["click"], overlay: menu }, { children: _jsx(Ant.Button, Object.assign({ icon: actions.icon }, { children: actions.label }), void 0) }), void 0));
    }
    getUIPaginationConfig() {
        const config = super.getUIPaginationConfig();
        this.state.perPage = 8;
        return {
            ...config,
            itemRender: (page, type, originalElement) => {
                if (type === "prev") {
                    return null;
                }
                if (type === "next") {
                    return null;
                }
                if (type === "page") {
                    return (_jsx(Button, Object.assign({ className: config.defaultCurrent > 3 ? "transparent" : "" }, { children: page }), void 0));
                }
                if (type === "jump-next" || type === "jump-prev") {
                    return _jsx(Button, Object.assign({ className: "jump" }, { children: "..." }), void 0);
                }
                return originalElement;
            },
            showSizeChanger: false,
            showLessItems: true,
            showPrevNextJumpers: true,
            hideOnSinglePage: true,
            prefixCls: "cc-custom-pagination",
            pageSize: 8,
        };
    }
    extractFilters(filters, keyPrefix = "") {
        const $and = [];
        const filterMap = this.getFilterMap();
        const push = (key, value) => {
            $and.push({
                [keyPrefix + key]: value,
            });
        };
        for (const key in filters) {
            const value = filters[key];
            if (value === undefined ||
                value === null ||
                (Array.isArray(value) && value.length === 0)) {
                // These values we consider non-filters.
                continue;
            }
            if (filterMap[key]) {
                push(filterMap[key], filters[key]);
                continue;
            }
            if (Array.isArray(value)) {
                if (moment.isMoment(value[0])) {
                    // We detect a date-range
                    push(key, {
                        $gte: value[0].startOf("day").toDate(),
                        $lte: value[1].endOf("day").toDate(),
                    });
                }
                else if (typeof value[0] === "number") {
                    // Number range detected
                    push(key, {
                        $gte: value[0],
                        $lte: value[1],
                    });
                }
                // most likely array of objectIds
                else {
                    push(key, {
                        $in: filters[key],
                    });
                }
            }
            else {
                if (typeof filters[key] === "string") {
                    if (filters[key] !== "") {
                        push(key, new RegExp(filters[key], "i"));
                    }
                }
                else {
                    if (typeof filters[key] === "object" && !moment.isMoment(value)) {
                        const objectFilters = this.extractFilters(filters[key], key + ".");
                        if (objectFilters.length) {
                            $and.push(...objectFilters);
                        }
                    }
                    else if (typeof filters[key] === "object" &&
                        moment.isMoment(value)) {
                        push(key, {
                            $lte: value.endOf("day").toDate(),
                        });
                    }
                    else {
                        push(key, {
                            $in: Array.isArray(filters[key]) ? filters[key] : [filters[key]],
                        });
                    }
                }
            }
        }
        return $and;
    }
}

import { useTranslate } from "@bluelibs/x-ui";
import { IntegrationOption } from "@bundles/UIAppBundle/pages/Marketing/consts";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { TagColorsEnum } from "../../../CustomTag/types";
export const useIntegrations = () => {
    var _a, _b, _c, _d;
    const t = useTranslate("management.shop_settings.fields");
    const shopSettings = useShopSettings();
    const googleUrl = (_b = (_a = shopSettings === null || shopSettings === void 0 ? void 0 : shopSettings.state) === null || _a === void 0 ? void 0 : _a.analyticsURLs) === null || _b === void 0 ? void 0 : _b.google;
    const facebookUrl = (_d = (_c = shopSettings === null || shopSettings === void 0 ? void 0 : shopSettings.state) === null || _c === void 0 ? void 0 : _c.analyticsURLs) === null || _d === void 0 ? void 0 : _d.facebookPixel;
    const getStatus = (url) => (url ? "Added" : "Not Added");
    const getColor = (url) => url ? TagColorsEnum.GREEN : TagColorsEnum.RED;
    const integrations = [
        {
            label: t("analyticsURLs.google"),
            status: getStatus(googleUrl),
            statusColor: getColor(googleUrl),
            integrationOption: IntegrationOption.GOOGLE_ANALYTICS,
        },
        {
            label: t("analyticsURLs.facebookPixel"),
            status: getStatus(facebookUrl),
            statusColor: getColor(facebookUrl),
            integrationOption: IntegrationOption.FACEBOOK_PIXEL,
        },
    ];
    return { integrations };
};

import { ProductVariantPriceListsList } from "../components/List/ProductVariantPriceListsList";
import { ProductVariantPriceListsCreate } from "../components/Create/ProductVariantPriceListsCreate";
import { ProductVariantPriceListsEdit } from "../components/Edit/ProductVariantPriceListsEdit";
import { ProductVariantPriceListsView } from "../components/View/ProductVariantPriceListsView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_VARIANT_PRICE_LISTS_LIST = {
    path: "/admin/product-variant-price-lists",
    component: ProductVariantPriceListsList,
    menu: {
        key: "PRODUCT_VARIANT_PRICE_LISTS_LIST",
        label: "management.product_variant_price_lists.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_VARIANT_PRICE_LISTS_CREATE = {
    path: "/admin/product-variant-price-lists/create",
    component: ProductVariantPriceListsCreate,
};
export const PRODUCT_VARIANT_PRICE_LISTS_EDIT = {
    path: "/admin/product-variant-price-lists/:id/edit",
    component: ProductVariantPriceListsEdit,
};
export const PRODUCT_VARIANT_PRICE_LISTS_VIEW = {
    path: "/admin/product-variant-price-lists/:id/view",
    component: ProductVariantPriceListsView,
};

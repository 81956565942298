import { gql } from "@apollo/client";
export const ADMIN_POST_GET = gql `
  query AdminPostsGet($input: AdminPostsGetInput!) {
    AdminPostsGet(input: $input) {
      _id
      body
      createdAt
      heroImage {
        _id
        downloadUrl
        mimeType
        name
        path
        resourceId
        resourceType
        size
        thumbs {
          downloadUrl
          path
          type
        }
      }
      heroImageId
      slug
      subtitle
      title
      createdAt
      updatedAt
      visibility
    }
  }
`;
export const ADMIN_POSTS_GET = gql `
  query AdminPostsList($input: AdminPostsListInput) {
    AdminPostsList(input: $input) {
      data {
        _id
        body
        createdAt
        heroImage {
          _id
          downloadUrl
          mimeType
          name
          path
          resourceId
          resourceType
          size
          thumbs {
            downloadUrl
            path
            type
          }
        }
        heroImageId
        slug
        subtitle
        title
        createdAt
        updatedAt
        visibility
        author {
          firstName
          middleName
          lastName
        }
      }
      total
      skip
      limit
    }
  }
`;

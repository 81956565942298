import { jsx as _jsx } from "react/jsx-runtime";
import { ShippingAddressCollection, } from "@bundles/UIAppBundle/collections";
import { Edit, KebabMenu, Trashcan } from "@bundles/UIAppBundle/icons";
import { ShippingAddressList } from "../../config/ShippingAddressList";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { features } from "../../config/features";
import { Routes } from "@bundles/UIAppBundle";
export class ShippingAddressAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = ShippingAddressCollection;
    }
    getBody() {
        return ShippingAddressList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ShippingAddressList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return ShippingAddressList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(Edit, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.SHIPPING_ADDRESS_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.delete) {
            actions.push({
                label: this.i18n.t("generics.delete"),
                icon: _jsx(Trashcan, {}, void 0),
                action: (model) => {
                    this.collection.deleteOne(model._id).then(() => {
                        this.load();
                    });
                },
            });
        }
        return actions;
    }
}

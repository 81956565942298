import { gql } from "@apollo/client";
export const getInternalUserRoles = gql `
  query InternalUserProfileGetAdmin($input: InternalUserProfileGetAdminInput!) {
    InternalUserProfileGetAdmin(input: $input) {
      user {
        roles
      }
    }
  }
`;

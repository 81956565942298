import * as Ant from "antd";
import { useMutation } from "@apollo/client";
import { ObjectId } from "@bluelibs/ejson";
import { useTranslate, useRouter, useDataOne } from "@bluelibs/x-ui";
import { ShopShippingAddressCollection, } from "@bundles/UIAppBundle/collections";
import { ShopShippingAddressEditForm } from "../../config/ShopShippingAddressEditForm";
import { AdminShippingAddressEdit } from "../mutations";
import { Routes } from "@bundles/UIAppBundle";
export const useShopShippingAddressEdit = (id) => {
    const t = useTranslate();
    const router = useRouter();
    const [editShippingAddress] = useMutation(AdminShippingAddressEdit);
    const { data: document, isLoading, error, } = useDataOne(ShopShippingAddressCollection, new ObjectId(id), ShopShippingAddressEditForm.getRequestBody());
    const onSubmit = (document) => {
        editShippingAddress({
            variables: {
                id,
                input: document,
            },
        })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_address.create_confirmation"),
            });
            return router.go(Routes.SHOP_SETTINGS_GENERAL_DETAILS_VIEW);
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    };
    return { onSubmit, document, isLoading, error };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProductsManagementLayout } from "@bundles/UIAppBundle/components/ProductsManagementLayout";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { productsAccessRoles } from "../../consts";
import { ProductsCreateForm } from "./ProductsCreateForm";
export function ProductsCreate() {
    return (_jsxs(ProtectedRoute, Object.assign({ roles: productsAccessRoles }, { children: [_jsx(Seo, { data: seoData.product.create }, void 0), _jsx(ProductsManagementLayout, { children: _jsx(ProductsCreateForm, {}, void 0) }, void 0)] }), void 0));
}

import { useEffect, useState } from "react";
export const useLongImageUpload = (formInstance, fieldName) => {
    const [file, setFile] = useState(formInstance.getFieldValue([fieldName]));
    useEffect(() => {
        setFile(formInstance.getFieldValue([fieldName]));
    }, [formInstance.getFieldValue([fieldName])]);
    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const onChange = async ({ file }) => {
        if (!(file === null || file === void 0 ? void 0 : file._id)) {
            file._id = Math.random().toString(36).slice(2);
            file.downloadUrl = await getBase64(file);
        }
        // TODO: use randomstring() or something
        setFile(file);
        formInstance.setFieldValue(fieldName, file);
    };
    const customRequest = (options) => {
        options.onSuccess("ok", null);
    };
    const onRemoveImage = () => (e) => {
        e.stopPropagation();
        setFile(null);
        formInstance.setFieldValue(fieldName, null);
    };
    return {
        onChange,
        file,
        onRemoveImage,
        customRequest,
    };
};

import { useMutation } from "@apollo/client";
import { useRouter } from "@bluelibs/x-ui";
import { notification } from "antd";
import { UserRole } from "@root/api.types";
import { INTERNAL_USER_PROFILES_LIST } from "../../routes";
import { createInternalUser } from "./mutation";
import { useAvatarUpload } from "@bundles/UIAppBundle/components/Avatar/hooks";
import { useState } from "react";
export const useInternalUserProfilesCreate = () => {
    const router = useRouter();
    const [createTeamMember] = useMutation(createInternalUser);
    const { uploadAvatar, AvatarUpload } = useAvatarUpload();
    const [isLoading, setIsLoading] = useState(false);
    const rolesOptionKeys = Object.keys(UserRole);
    const rolesOptions = rolesOptionKeys.map((r) => ({
        value: r,
        label: r.split("_").join(" "),
    }));
    const handleUserCreate = (data) => {
        setIsLoading(true);
        createTeamMember({
            variables: {
                input: data,
            },
        })
            .then(async ({ data }) => {
            var _a;
            const userId = (_a = data === null || data === void 0 ? void 0 : data.AdminUserCreate) === null || _a === void 0 ? void 0 : _a.userId;
            if (userId)
                await uploadAvatar(userId);
            notification.success({ message: "New Team Member added" });
            router.go(INTERNAL_USER_PROFILES_LIST);
        })
            .catch(() => {
            notification.error({ message: "Failed adding Team Member" });
        })
            .finally(() => setIsLoading(false));
    };
    return { handleUserCreate, rolesOptions, AvatarUpload, isLoading };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { CustomTag, Card } from "../";
import { TagColorsEnum } from "../CustomTag/types";
import { useTranslate } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import styles from "./AttributeListItem.module.scss";
const AttributeListItem = ({ name, type, isRequired, defaultSuffix, inheritedFrom, children, noDescription = false, url = Routes.SHOP_SETTINGS_ATTRIBUTES_VIEW.path, }) => {
    const t = useTranslate();
    return (_jsx(Ant.Col, Object.assign({ span: 24, className: styles.col }, { children: _jsxs(Card, Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: name }), void 0), !noDescription && (_jsxs("div", Object.assign({ className: styles.description }, { children: ["This Product Attribute is inherited from the \u00A0", _jsx("strong", { children: inheritedFrom
                                ? `${inheritedFrom} Category`
                                : "Shop Configuration" }, void 0), "\u00A0 and can be changed \u00A0", _jsx(Link, Object.assign({ className: styles.link, to: url }, { children: t("generics.here") }), void 0), "."] }), void 0)), _jsxs("div", Object.assign({ className: styles["info-list"] }, { children: [_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: "Type" }), void 0), _jsx(CustomTag, { className: styles.tag, variant: "filled", 
                                    // TODO: return here after clarifications about types
                                    value: type, color: TagColorsEnum.BLUE }, void 0)] }), void 0), defaultSuffix && (_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: "Suffix" }), void 0), _jsx(CustomTag, { className: styles.tag, variant: "filled", value: defaultSuffix, color: TagColorsEnum.ORANGE }, void 0)] }), void 0)), _jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: "Attribute Required" }), void 0), _jsx(CustomTag, { className: styles.tag, variant: "filled", value: isRequired ? "Yes" : "No", color: TagColorsEnum.GREEN }, void 0)] }), void 0)] }), void 0), children] }), void 0) }), void 0));
};
export default AttributeListItem;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Upload } from "antd";
import classNames from "classnames";
import { Trashcan, UploadPhoto } from "@bundles/UIAppBundle/icons";
import { Button } from "../Button";
import { Card } from "../Card";
import { useProductImagesUpload } from "./hooks";
import styles from "./ProductImagesUpload.module.scss";
const ProductImagesUpload = ({ formInstance }) => {
    const { onChange, customRequest, fileList, defaultImageIndex, onSetDefaultImageIndex, onRemoveImage, onDescriptionChange, } = useProductImagesUpload(formInstance);
    const imageUploaded = fileList.length > 0 ? true : false;
    return (_jsxs(Card, Object.assign({ className: styles["upload-card"] }, { children: [_jsxs("div", Object.assign({ className: styles["upload-card-header"] }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Upload Media" }), void 0), _jsx(Upload, Object.assign({ fileList: fileList, customRequest: customRequest, onChange: onChange, multiple: true, showUploadList: false }, { children: _jsx(Button, Object.assign({ type: "button" }, { children: "Upload Images" }), void 0) }), void 0)] }), void 0), !imageUploaded ? (_jsx("div", { children: _jsxs(Upload, Object.assign({ fileList: fileList, customRequest: customRequest, onChange: onChange, multiple: true, listType: "picture-card", className: styles.dragger, showUploadList: false }, { children: [_jsx(UploadPhoto, { className: styles["upload-icon"] }, void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "Upload your Media Here" }), void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "(Click or Drag)" }), void 0)] }), void 0) }, void 0)) : null, imageUploaded ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles["hr"] }, void 0), _jsx("hr", {}, void 0), _jsxs("div", Object.assign({ className: styles["upload-table-header"] }, { children: [_jsx("div", { children: "Images" }, void 0), _jsx("div", { children: "Description(Image Alt Text)" }, void 0), _jsx("div", { children: "Thumbnail" }, void 0)] }), void 0), _jsx("ul", Object.assign({ className: classNames(styles["preview-list"], "cc-custom-list") }, { children: fileList.map((file, index) => (_jsxs("li", Object.assign({ className: styles["product-image-row"] }, { children: [_jsx("div", { children: _jsx("img", { src: file.downloadUrl, alt: file.name, className: styles.item, style: { objectFit: "contain" } }, void 0) }, void 0), _jsx("textarea", { placeholder: "Type Your Description Here", className: styles["description"], onChange: (e) => {
                                        onDescriptionChange(file._id, e.target.value);
                                    } }, void 0), _jsx("input", { type: "radio", name: "thumbnail", value: "HTML", className: styles["thumbnail"], onClick: () => onSetDefaultImageIndex(index), checked: defaultImageIndex === index }, void 0), _jsx(Trashcan, { onClick: onRemoveImage(file._id), className: styles["delete-icon"] }, void 0)] }), index))) }), void 0)] }, void 0)) : null] }), void 0));
};
export default ProductImagesUpload;

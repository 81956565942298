import { useEffect, useState } from "react";
export const useProductImagesUpload = (formInstance) => {
    const [fileList, setFileList] = useState(formInstance.getFieldValue(["images"]) || []);
    const [defaultImageIndex, setDefaultImageIndex] = useState(formInstance.getFieldValue(["defaultImageIndex"]));
    useEffect(() => {
        if (defaultImageIndex || !fileList[0])
            return;
        onSetDefaultImageIndex(0);
    }, [fileList]);
    useEffect(() => {
        setFileList(formInstance.getFieldValue(["images"]) || []);
    }, [formInstance.getFieldValue(["images"])]);
    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const onChange = async ({ fileList }) => {
        const newList = await Promise.all(fileList.map(async (file) => {
            if (file === null || file === void 0 ? void 0 : file._id)
                return file;
            // get photo src as base 64
            // to display it in page until uploading
            // TODO: use randomstring() or something
            file._id = Math.random().toString(36).slice(2);
            file.downloadUrl = await getBase64(file);
            return file;
        }));
        setFileList(newList);
        formInstance.setFieldValue("images", fileList);
    };
    const customRequest = (options) => {
        options.onSuccess("ok", null);
    };
    const onSetDefaultImageIndex = (index) => {
        setDefaultImageIndex(index);
        formInstance.setFieldValue("defaultImageIndex", index);
    };
    const onRemoveImage = (id) => (e) => {
        e.stopPropagation();
        const removedFileIndex = fileList.findIndex((item) => item._id === id);
        const newFileList = [...fileList];
        newFileList.splice(removedFileIndex, 1);
        setFileList(newFileList);
        if (defaultImageIndex === removedFileIndex) {
            onSetDefaultImageIndex(0);
        }
        formInstance.setFieldValue("images", newFileList);
    };
    const onDescriptionChange = (id, text) => {
        formInstance.setFieldValue("imagesDescription", {
            ...formInstance.getFieldValue("imagesDescription"),
            [id]: text,
        });
    };
    return {
        onChange,
        fileList,
        defaultImageIndex,
        onSetDefaultImageIndex,
        onRemoveImage,
        customRequest,
        onDescriptionChange,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Modal } from "../Modal";
import { CreateOrderLayout } from "../CreateOrder/components/CreateOrderLayout";
import { useCreateReturn } from "./hooks";
import styles from "./CreateReturn.module.scss";
const CreateReturn = ({ modalOpen, setModalOpen, onSuccess, }) => {
    const { currentStep, error, isLoading, steps, next, prev, onConfirm } = useCreateReturn(setModalOpen);
    return (_jsx("div", { children: _jsx(Modal, Object.assign({ isOpen: modalOpen, title: "Create Return", className: styles["modal"], setIsOpen: setModalOpen, handleClose: () => setModalOpen(false) }, { children: _jsxs(CreateOrderLayout, Object.assign({ title: steps[currentStep].title, currentStep: currentStep, totalSteps: steps.length, onConfirm: async () => {
                    await onConfirm();
                    await onSuccess();
                }, onNext: next, onBack: prev, isLoading: isLoading }, { children: [_jsx("div", Object.assign({ className: "steps-content" }, { children: steps[currentStep].content }), void 0), _jsx("div", Object.assign({ className: styles["error"] }, { children: error }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default CreateReturn;

import { NotificationSubscribersList } from "../components/List/NotificationSubscribersList";
import { NotificationSubscribersCreate } from "../components/Create/NotificationSubscribersCreate";
import { NotificationSubscribersEdit } from "../components/Edit/NotificationSubscribersEdit";
import { NotificationSubscribersView } from "../components/View/NotificationSubscribersView";
import { SettingFilled } from "@ant-design/icons";
export const NOTIFICATION_SUBSCRIBERS_LIST = {
    path: "/admin/notification-subscribers",
    component: NotificationSubscribersList,
    menu: {
        key: "NOTIFICATION_SUBSCRIBERS_LIST",
        label: "management.notification_subscribers.menu.title",
        icon: SettingFilled,
    },
};
export const NOTIFICATION_SUBSCRIBERS_CREATE = {
    path: "/admin/notification-subscribers/create",
    component: NotificationSubscribersCreate,
};
export const NOTIFICATION_SUBSCRIBERS_EDIT = {
    path: "/admin/notification-subscribers/:id/edit",
    component: NotificationSubscribersEdit,
};
export const NOTIFICATION_SUBSCRIBERS_VIEW = {
    path: "/admin/notification-subscribers/:id/view",
    component: NotificationSubscribersView,
};

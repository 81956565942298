var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { EndUserProfileList as BaseEndUserProfileList } from "./EndUserProfileList.base";
import styles from "../components/List/EndUserProfilesList.module.scss";
import { formatCurrencyAndPrice } from "@bundles/UIAppBundle/utils/helpers";
import { AdminListItemRenderer } from "@bundles/UIAppBundle/overrides/AdminListItemRenderer";
let EndUserProfileList = class EndUserProfileList extends BaseEndUserProfileList {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.remove("billingAddress.streetAddressLine1");
        this.remove("billingAddress.streetAddressLine2");
        this.remove("billingAddress.salesRayId");
        this.remove("billingAddress.city");
        this.remove("billingAddress.county");
        this.remove("billingAddress.country");
        this.remove("billingAddress.zipcode");
        this.remove("billingAddress.fullName");
        this.remove("billingAddress.phoneNumber");
        this.remove("billingAddress.email");
        this.remove("billingAddress.companyName");
        this.remove("billingAddress.vatNumber");
        this.remove("shipmentAddress.streetAddressLine1");
        this.remove("shipmentAddress.streetAddressLine2");
        this.remove("shipmentAddress.city");
        this.remove("shipmentAddress.county");
        this.remove("shipmentAddress.country");
        this.remove("shipmentAddress.zipcode");
        this.remove("shipmentAddress.fullName");
        this.remove("shipmentAddress.phoneNumber");
        this.remove("shipmentAddress.email");
        this.remove("savedAddresses");
        this.remove("firstName");
        this.remove("middleName");
        this.remove("birthDate");
        this.remove("lastName");
        this.remove("admin");
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("user");
        this.remove("birthDate");
        this.remove("productFavorites");
        this.remove("latestViewedProducts");
        this.remove("invoices");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("birthDate");
        this.remove("billingAddress.town");
        this.remove("billingAddress.email");
        this.remove("billingAddress.firstName");
        this.remove("billingAddress.lastName");
        this.remove("billingAddress.phoneNumber");
        this.remove("orders");
        this.remove("isSuspended");
        this.remove("suspendedAt");
        this.remove("admin");
        this.remove("payments");
        this.remove("salesRayId");
        this.remove("models");
        this.remove("language");
        this.remove("salesRayDefaultPricelistId");
        this.remove("isAnonymousCheckout");
        this.add({
            id: "image",
            title: "",
            order: -1,
            render: (value) => {
                var _a, _b, _c, _d;
                return (_jsx("img", { className: styles.img, src: generateProfileImageLink(value.profile.fullName, (_d = (_c = (_b = (_a = value.profile) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.file) === null || _d === void 0 ? void 0 : _d.downloadUrl) }, void 0));
            },
        });
        this.update("companyName", {
            id: "companyName",
            dataIndex: ["profile", "companyName"],
            order: 0,
            title: t("management.end_user_profiles.fields.companyName"),
            align: "center",
            render: (value) => {
                return _jsx(AdminListItemRenderer, { value: value, type: "string" }, void 0);
            },
        });
        this.update("fullName", {
            id: "fullName",
            dataIndex: ["profile", "fullName"],
            order: 1,
            title: t("management.end_user_profiles.fields.fullName"),
            align: "center",
            render: (value) => {
                return _jsx(AdminListItemRenderer, { value: value, type: "string" }, void 0);
            },
        });
        this.update("email", {
            dataIndex: ["profile", "email"],
            title: t("management.end_user_profiles.fields.email"),
            align: "center",
            order: 2,
        });
        this.update("phoneNumber", {
            dataIndex: ["profile", "phoneNumber"],
            order: 3,
            title: t("management.end_user_profiles.fields.phoneNumber"),
            align: "center",
        });
        this.update("vatNumber", {
            id: "vatNumber",
            dataIndex: ["profile", "vatNumber"],
            title: t("management.end_user_profiles.fields.vatNumber"),
            align: "center",
            order: 4,
            render: (value) => {
                return _jsx(AdminListItemRenderer, { value: value, type: "string" }, void 0);
            },
        });
        this.update("orderCount", {
            dataIndex: ["orderCount"],
            sorter: true,
            order: 6,
            title: t("management.end_user_profiles.fields.orderCount"),
            align: "center",
            render: (value) => {
                return _jsx(AdminListItemRenderer, { value: value, type: "number" }, void 0);
            },
        });
        this.update("orderTotal", {
            dataIndex: ["orderTotal"],
            sorter: true,
            order: 6,
            title: t("management.end_user_profiles.fields.orderTotal"),
            align: "center",
            render: (value) => {
                return (_jsx("span", Object.assign({ style: { fontWeight: "bold" } }, { children: formatCurrencyAndPrice("eur", value) }), void 0));
            },
        });
        // this.update("isAnonymousCheckout", {
        //   dataIndex: ["profile", "isAnonymousCheckout"],
        //   sorter: true,
        //   order: 7,
        //   title: t("management.end_user_profiles.fields.isAnonymousCheckout"),
        //   align: "center",
        //   render: (value) => {
        //     const props = value
        //       ? {
        //           value: "No",
        //           color: TagColorsEnum.RED,
        //         }
        //       : {
        //           value: "Yes",
        //           color: TagColorsEnum.GREEN,
        //         };
        //
        //     return (
        //       <div className={styles["has-account-wrapper"]}>
        //         <CustomTag {...props} variant="filled" />
        //       </div>
        //     );
        //   },
        // });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            user: {
                avatar: {
                    file: {
                        downloadUrl: 1,
                    },
                },
            },
        };
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            "profile.fullName": "name",
            "profile.companyName": "companyName",
            "profile.vatNumber": "vatNumber",
            "profile.email": "email",
            "profile.phoneNumber": "phone",
            "profile.isAnonymousCheckout": "hasAccount",
        };
    }
};
EndUserProfileList = __decorate([
    Service({ transient: true })
], EndUserProfileList);
export { EndUserProfileList };

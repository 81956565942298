import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_PAYMENT_OPTIONS, GET_SHIPPING_OPTIONS } from "./query";
export const usePaymentAndShipping = (stepForm) => {
    const formData = stepForm.getFieldsValue(true);
    const [notesHtml, setNotesHtml] = useState(formData.privateNotes || "");
    const [shippingOptionId, setShippingOptionId] = useState(formData.shippingOptionId || "");
    const [paymentOptionId, setPaymentOptionId] = useState(formData.paymentOptionId || "");
    useEffect(() => {
        stepForm.setFieldsValue({ privateNotes: notesHtml });
    }, [notesHtml]);
    const { data: shippingOptionsData } = useQuery(GET_SHIPPING_OPTIONS);
    const { data: paymentOptionsData } = useQuery(GET_PAYMENT_OPTIONS);
    const shippingOptions = shippingOptionsData === null || shippingOptionsData === void 0 ? void 0 : shippingOptionsData.AdminGetShippingOptions.map(({ name, _id }) => ({
        value: _id,
        label: name,
    }));
    const paymentOptions = paymentOptionsData === null || paymentOptionsData === void 0 ? void 0 : paymentOptionsData.AdminGetPaymentOptions.map(({ name, _id }) => ({
        value: _id,
        label: name,
    }));
    const onShippingOptionChange = (value) => {
        var _a;
        setShippingOptionId(value);
        stepForm.setFieldsValue({ shippingOptionId: value });
        stepForm.setFieldsValue({
            shippingOption: (_a = shippingOptionsData === null || shippingOptionsData === void 0 ? void 0 : shippingOptionsData.AdminGetShippingOptions) === null || _a === void 0 ? void 0 : _a.find((item) => item._id === value),
        });
    };
    const onPaymentOptionChange = (value) => {
        setPaymentOptionId(value);
        stepForm.setFieldsValue({ paymentOptionId: value });
    };
    return {
        notesHtml,
        setNotesHtml,
        shippingOptions,
        paymentOptions,
        onShippingOptionChange,
        onPaymentOptionChange,
        shippingOptionId,
        paymentOptionId,
    };
};

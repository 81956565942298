import { InvoicesList } from "../components/List/InvoicesList";
import { InvoicesCreate } from "../components/Create/InvoicesCreate";
import { InvoicesEdit } from "../components/Edit/InvoicesEdit";
import { InvoicesView } from "../components/View/InvoicesView";
import { SettingFilled } from "@ant-design/icons";
export const INVOICES_LIST = {
    path: "/admin/invoices",
    component: InvoicesList,
    menu: {
        key: "INVOICES_LIST",
        label: "management.invoices.menu.title",
        icon: SettingFilled,
    },
};
export const INVOICES_CREATE = {
    path: "/admin/invoices/create",
    component: InvoicesCreate,
};
export const INVOICES_EDIT = {
    path: "/admin/invoices/:id/edit",
    component: InvoicesEdit,
};
export const INVOICES_VIEW = {
    path: "/admin/invoices/:id/view",
    component: InvoicesView,
};

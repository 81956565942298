import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useTranslate } from "@bluelibs/x-ui";
import { clientsAccessRoles } from "@bundles/UIAppBundle/pages/EndUserProfilesManagement/consts";
import { invoiceAccessRoles } from "@bundles/UIAppBundle/pages/InvoicesManagement/consts";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { productsAccessRoles } from "@bundles/UIAppBundle/pages/ProductsManagement/consts";
import { checkAdminRights } from "../../Layout/utils/CheckAdminRights";
import ClientsNotif from "./components/ClientsNotif";
import InvoicesNotif from "./components/InvoicesNotif";
import styles from "../Settings.module.scss";
import OrdersNotif from "./components/OrdersNotif";
import ProductsNotif from "./components/ProductsNotif";
import ReturnsNotif from "./components/ReturnsNotif";
import { useNotifications } from "@bundles/UIAppBundle/components/Settings/Notifications/hooks";
import SettingsLoader from "@bundles/UIAppBundle/components/Settings/Layout/SettingsLoader";
const Notifications = () => {
    var _a;
    const { loading, handleChangeNotificationStatus, groupedNotifications } = useNotifications();
    const t = useTranslate("management.settings.notifications");
    const { state } = useGuardian();
    const userRoles = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.roles) || [];
    const { orderNotifications, productNotifications, returnNotifications, invoiceNotifications, clientNotifications, } = groupedNotifications;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("h2", Object.assign({ className: styles.title }, { children: t("title") }), void 0), loading ? (_jsx(SettingsLoader, {}, void 0)) : (_jsxs("div", Object.assign({ className: styles["settings-sections"] }, { children: [checkAdminRights(userRoles, ordersAccessRoles) && (_jsx(OrdersNotif, { orderNotifications: orderNotifications, handleChangeNotificationStatus: handleChangeNotificationStatus }, void 0)), checkAdminRights(userRoles, ordersAccessRoles) && (_jsx(ReturnsNotif, { returnNotifications: returnNotifications, handleChangeNotificationStatus: handleChangeNotificationStatus }, void 0)), checkAdminRights(userRoles, clientsAccessRoles) && (_jsx(ClientsNotif, { clientNotifications: clientNotifications, handleChangeNotificationStatus: handleChangeNotificationStatus }, void 0)), checkAdminRights(userRoles, invoiceAccessRoles) && (_jsx(InvoicesNotif, { invoiceNotifications: invoiceNotifications, handleChangeNotificationStatus: handleChangeNotificationStatus }, void 0)), checkAdminRights(userRoles, productsAccessRoles) && (_jsx(ProductsNotif, { productNotifications: productNotifications, handleChangeNotificationStatus: handleChangeNotificationStatus }, void 0))] }), void 0))] }), void 0));
};
export default Notifications;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Card } from "..";
import styles from "./ClientInfo.module.scss";
const ClientInfo = ({ clientData, avatarUrl, }) => {
    const { email, phoneNumber } = clientData;
    return (_jsx(Card, Object.assign({ title: "Client Information:", className: styles["custom-card"] }, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsxs("div", Object.assign({ className: styles["name-img"] }, { children: [_jsx("img", { src: generateProfileImageLink(clientData.name || clientData.fullName, avatarUrl) }, void 0), _jsx("div", { children: clientData.name || clientData.fullName }, void 0)] }), void 0), _jsx("div", { children: email }, void 0), phoneNumber && _jsxs("div", { children: ["Phone: ", phoneNumber] }, void 0)] }), void 0) }), void 0));
};
export default ClientInfo;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProductsManagementLayout } from "@bundles/UIAppBundle/components/ProductsManagementLayout";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { ProductVariantsCreateForm } from "./ProductVariantsCreateForm";
// eslint-disable-next-line react/prop-types
export function ProductVariantsCreate({ id }) {
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.productVariant.create }, void 0), _jsx(ProductsManagementLayout, Object.assign({ productId: id }, { children: _jsx(ProductVariantsCreateForm, { id: id }, void 0) }), void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart } from "@bluelibs/x-ui";
import { ShopShippingAddressAntTableSmart } from "./ShopShippingAddressTableSmart";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { Table } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
export function ShopShippingAddressList() {
    const [api, Provider] = newSmart(ShopShippingAddressAntTableSmart);
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shippingAddress.list }, void 0), _jsx(Provider, { children: _jsx(Table, Object.assign({ className: styles.table }, api.getTableProps()), void 0) }, void 0)] }), void 0));
}

import { gql } from "@apollo/client";
export const GET_END_USER_PROFILES = gql `
  query AdminEndUserProfileListGet($input: AdminEndUserProfileListGetInput) {
    AdminEndUserProfileListGet(input: $input) {
      data {
        profile {
          _id
          fullName
          companyName
          vatNumber
          email
          phoneNumber
          isAnonymousCheckout
          user {
            isSuspended
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
        orderCount
        orderTotal
      }
      skip
      limit
      total
    }
  }
`;

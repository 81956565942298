import { ShippingAddressList } from "../components/List/ShippingAddressList";
import { ShippingAddressCreate } from "../components/Create/ShippingAddressCreate";
import { ShippingAddressEdit } from "../components/Edit/ShippingAddressEdit";
import { ShippingAddressView } from "../components/View/ShippingAddressView";
import { SettingFilled } from "@ant-design/icons";
export const SHIPPING_ADDRESS_LIST = {
    path: "/admin/shipping-address",
    component: ShippingAddressList,
    menu: {
        key: "SHIPPING_ADDRESS_LIST",
        label: "management.shipping_address.menu.title",
        icon: SettingFilled,
    },
};
export const SHIPPING_ADDRESS_CREATE = {
    path: "/admin/shipping-address/create",
    component: ShippingAddressCreate,
};
export const SHIPPING_ADDRESS_EDIT = {
    path: "/admin/shipping-address/:id/edit",
    component: ShippingAddressEdit,
};
export const SHIPPING_ADDRESS_VIEW = {
    path: "/admin/shipping-address/:id/view",
    component: ShippingAddressView,
};

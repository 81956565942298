import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { UPDATE_CUSTOM_PAGE } from "./mutation";
export const useArticlesEdit = () => {
    const [updateArticle] = useMutation(UPDATE_CUSTOM_PAGE);
    const router = useRouter();
    const onUpdate = (id, document) => {
        updateArticle({
            variables: {
                input: {
                    articleId: id,
                    body: document.body,
                },
            },
        })
            .then(() => {
            notification.success({
                message: "Update successful",
            });
            router.go(Routes.ARTICLES_LIST);
        })
            .catch(() => notification.error({
            message: "Update unsuccessful",
        }));
    };
    return { onUpdate };
};

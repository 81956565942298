import { gql } from "@apollo/client";
export const UpdateProductCategoryMutation = gql `
  mutation AdminCategoryUpdate(
    $id: ObjectId
    $document: ProductCategoryUpdateInput!
  ) {
    AdminCategoryUpdate(_id: $id, document: $document) {
      _id
      attributeOptions {
        name
        type
        isRequired
        defaultSuffix
      }
      vatRateId
      discountPercentage
      discountActiveUntil
      name
      isRoot
      description
      image {
        _id
        downloadUrl
      }
    }
  }
`;

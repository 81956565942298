import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useRouter, useTranslate } from "@bluelibs/x-ui";
import { ShippingOptionsAntTableSmart } from "./ShippingOptionsTableSmart";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { UserRole } from "@root/api.types";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Table, Button } from "@bundles/UIAppBundle/components";
import styles from "./ShippingOptionsList.module.scss";
import { AddSimple } from "@bundles/UIAppBundle/icons";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ShippingOptionsList() {
    const router = useRouter();
    const t = useTranslate();
    const [api, Provider] = newSmart(ShippingOptionsAntTableSmart);
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN] }, { children: [_jsx(Seo, { data: seoData.shippingOption.list }, void 0), _jsxs(ShopConfigurationLayout, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.shop_settings.submenus.shipping_options") }), void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsx(Table, Object.assign({ className: styles.table }, api.getTableProps(), { pagination: false }), void 0) }, void 0) }, void 0), _jsx(Button, Object.assign({ width: "auto", onClick: () => router.go(Routes.SHIPPING_OPTIONS_CREATE), className: styles["create-btn"], icon: AddSimple }, { children: t("management.shipping_options.create.header") }), void 0)] }, void 0)] }), void 0));
}

import { gql } from "@apollo/client";
export const SUPEND_TEAM_MEMBER = gql `
  mutation AdminInternalUserProfileSuspendAdmin(
    $document: AdminInternalUserProfileSuspendAdminInput!
  ) {
    AdminInternalUserProfileSuspendAdmin(document: $document) {
      _id
    }
  }
`;
export const UNSUSPEND_TEAM_MEMBER = gql `
  mutation AdminInternalUserProfileRestoreAdmin(
    $document: AdminInternalUserProfileRestoreAdminInput!
  ) {
    AdminInternalUserProfileRestoreAdmin(document: $document) {
      _id
    }
  }
`;

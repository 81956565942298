import { gql } from "@apollo/client";
export const AdminProductVariantListGet = gql `
  query AdminProductVariantListGet($input: AdminProductVariantListGetInput) {
    AdminProductVariantListGet(input: $input) {
      data {
        _id
        salesRayId
        attributesValues {
          key
          value
        }
        unitOfMeasurement {
          _id
          salesRayId
          name
          code
          hasWeight
          label
        }
        availability
        product {
          _id
          name
        }
        category {
          _id
          name
        }
        name
        description
        isPublished
        netPrice
        sku
        discountPercentage
        defaultImage {
          downloadUrl
        }
      }
      skip
      limit
      total
    }
  }
`;

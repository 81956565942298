var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UsersCollection, InternalUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
let InternalUserProfileCreateForm = class InternalUserProfileCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.internal_user_profiles.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "middleName",
                label: t("management.internal_user_profiles.fields.middleName"),
                name: ["middleName"],
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.internal_user_profiles.fields.lastName"),
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.internal_user_profiles.fields.phoneNumber"),
                name: ["phoneNumber"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.internal_user_profiles.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "activeNotifications",
                label: t("management.internal_user_profiles.fields.activeNotifications"),
                name: ["activeNotifications"],
                required: true,
                nest: [
                    {
                        id: "orderPlaced",
                        label: t("management.internal_user_profiles.fields.activeNotifications.orderPlaced"),
                        name: ["activeNotifications", "orderPlaced"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "orderStatusChanged",
                        label: t("management.internal_user_profiles.fields.activeNotifications.orderStatusChanged"),
                        name: ["activeNotifications", "orderStatusChanged"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "returnCreated",
                        label: t("management.internal_user_profiles.fields.activeNotifications.returnCreated"),
                        name: ["activeNotifications", "returnCreated"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "returnStatusChanged",
                        label: t("management.internal_user_profiles.fields.activeNotifications.returnStatusChanged"),
                        name: ["activeNotifications", "returnStatusChanged"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "clientCreated",
                        label: t("management.internal_user_profiles.fields.activeNotifications.clientCreated"),
                        name: ["activeNotifications", "clientCreated"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "invoiceCreated",
                        label: t("management.internal_user_profiles.fields.activeNotifications.invoiceCreated"),
                        name: ["activeNotifications", "invoiceCreated"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "productOutOfStock",
                        label: t("management.internal_user_profiles.fields.activeNotifications.productOutOfStock"),
                        name: ["activeNotifications", "productOutOfStock"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "productPromotionExpire",
                        label: t("management.internal_user_profiles.fields.activeNotifications.productPromotionExpire"),
                        name: ["activeNotifications", "productPromotionExpire"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                ],
            },
            {
                id: "userId",
                label: t("management.internal_user_profiles.fields.user"),
                name: ["userId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.internal_user_profiles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.INTERNAL_USER_PROFILES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.INTERNAL_USER_PROFILES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.INTERNAL_USER_PROFILES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => InternalUserProfilesCollection),
    __metadata("design:type", typeof (_a = typeof InternalUserProfilesCollection !== "undefined" && InternalUserProfilesCollection) === "function" ? _a : Object)
], InternalUserProfileCreateForm.prototype, "collection", void 0);
InternalUserProfileCreateForm = __decorate([
    Service({ transient: true })
], InternalUserProfileCreateForm);
export { InternalUserProfileCreateForm };

import { ReturnsList } from "../components/List/ReturnsList";
import { ReturnsCreate } from "../components/Create/ReturnsCreate";
import { ReturnsEdit } from "../components/Edit/ReturnsEdit";
import { ReturnsView } from "../components/View/ReturnsView";
import { SettingFilled } from "@ant-design/icons";
export const RETURNS_LIST = {
    path: "/admin/returns",
    component: ReturnsList,
    menu: {
        key: "RETURNS_LIST",
        label: "management.returns.menu.title",
        icon: SettingFilled,
    },
};
export const RETURNS_CREATE = {
    path: "/admin/returns/create",
    component: ReturnsCreate,
};
export const RETURNS_EDIT = {
    path: "/admin/returns/:id/edit",
    component: ReturnsEdit,
};
export const RETURNS_VIEW = {
    path: "/admin/returns/:id/view",
    component: ReturnsView,
};

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AdminShopSettingEdit } from "@bundles/UIAppBundle/components/ShopConfiguration/mutations";
import { notification } from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { GetShopSettingsReviewsNotificationStatus } from "@bundles/UIAppBundle/components/Settings/Reviews/queries";
export const useOrdersReview = () => {
    const { data: shopSettings, loading } = useQuery(GetShopSettingsReviewsNotificationStatus, { fetchPolicy: "network-only" });
    const [activateReviewsStorefront, setActivateReviewsStorefront] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [updateShopSettings] = useMutation(AdminShopSettingEdit);
    const tl = useTranslate("management.settings.edit_notifications");
    const t = useTranslate();
    useEffect(() => {
        setActivateReviewsStorefront(shopSettings === null || shopSettings === void 0 ? void 0 : shopSettings.AdminGetShopSettings.reviewsEnabled);
    }, [shopSettings]);
    const handleActivateReviews = () => {
        setIsConfirmationOpen(false);
        updateShopSettings({
            variables: {
                document: { reviewsEnabled: !activateReviewsStorefront },
            },
        })
            .then((response) => {
            notification.success({
                message: tl(`orderReviews.${!activateReviewsStorefront ? "enabled" : "disabled"}`),
            });
            setActivateReviewsStorefront(response.data.AdminShopSettingEdit.reviewsEnabled);
        })
            .catch((error) => {
            notification.error({
                message: t("generics.error"),
                description: error.message,
            });
        });
    };
    return {
        loading,
        activateReviewsStorefront,
        setActivateReviewsStorefront,
        isConfirmationOpen,
        setIsConfirmationOpen,
        handleActivateReviews,
    };
};

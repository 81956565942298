import { gql } from "@apollo/client";
export const UPDATE_CUSTOM_PAGE = gql `
  mutation AdminArticleUpdate($input: AdminArticleUpdateInput!) {
    AdminArticleUpdate(input: $input) {
      _id
      title
      slug
      body
      createdAt
      updatedAt
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserRole } from "@root/api.types";
import { useRouter, useTranslate, useUIComponents } from "@bluelibs/x-ui";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ProductCategoriesCreateForm } from "./ProductCategoriesCreateForm";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import { Button } from "@bundles/UIAppBundle/components";
import styles from "./ProductCategoriesCreate.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ProductCategoriesCreate(props) {
    const t = useTranslate();
    const UIComponents = useUIComponents();
    const router = useRouter();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.productCategories.create }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx(Button, { width: "fit-content", onClick: () => router.history.goBack(), className: styles["back-btn"], btnStyle: "white", icon: BackCircle }, void 0), _jsx("h2", Object.assign({ className: styles.title }, { children: t(`management.product_categories.create.header.${props.id ? "sub" : ""}category`) }), void 0)] }), void 0), _jsx(ProductCategoriesCreateForm, { id: props.id }, void 0)] }), void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { UnitsOfMeasurementCollection, } from "@bundles/UIAppBundle/collections";
import { UnitsOfMeasurementViewer } from "../../config/UnitsOfMeasurementViewer";
import { Card, Topbar } from "@bundles/UIAppBundle/components";
import styles from "./UnitsOfMeasurementView.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function UnitsOfMeasurementView(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(UnitsOfMeasurementCollection, new ObjectId(props.id), UnitsOfMeasurementViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(UnitsOfMeasurementViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.unitsOfMeasurements.view }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx("div", Object.assign({ className: styles["container"] }, { children: _jsx(Topbar, { title: "UNITS OF MEASUREMENT " }, void 0) }), void 0), content] }, void 0)] }), void 0));
}
export function UnitsOfMeasurementViewComponent(props) {
    const document = props.document;
    const viewer = use(UnitsOfMeasurementViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return (_jsx(Card, Object.assign({ className: styles["card"] }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles["title"] }, { children: _jsx("div", Object.assign({ className: styles["title__text"] }, { children: "Units Of Measurement Details" }), void 0) }), void 0), _jsx("div", Object.assign({ className: styles["details"] }, { children: _jsx("div", Object.assign({ className: styles["details__grid"] }, { children: viewer.rest().map((item) => {
                            return (_jsx(Ant.Descriptions.Item, Object.assign({ label: item.label }, { children: viewer.render(item) }), item.id));
                        }) }), void 0) }), void 0)] }), void 0) }), void 0));
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    const viewRoutePath = router.path(Routes.UNITS_OF_MEASUREMENT_VIEW, {
        params: { id },
    });
    return actions;
}

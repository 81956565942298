import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { useRouter } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "../Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { Edit } from "@bundles/UIAppBundle/icons";
import { useShopAttributesView } from "./hooks";
import styles from "../GeneralDetailsView/GeneralDetailsView.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "../consts";
const ShopAttributesView = () => {
    const router = useRouter();
    const { data, loading, error, t } = useShopAttributesView();
    return (_jsx(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: _jsxs(ShopConfigurationLayout, { children: [loading && _jsx(Ant.Spin, { size: "large" }, void 0), !loading && (error || !data) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !loading && !error && (_jsxs(Fragment, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.shop_settings.submenus.shop_attributes") }), void 0), data.map((attribute, idx) => (_jsx(Card, Object.assign({ className: styles["info-card"] }, { children: _jsx("ul", Object.assign({ className: "cc-custom-list" }, { children: attribute.map(({ label, value }, idx) => (_jsxs("li", Object.assign({ className: styles["list-item"] }, { children: [_jsx("div", Object.assign({ className: styles.key }, { children: label }), void 0), _jsx("div", Object.assign({ className: styles.value }, { children: value }), void 0)] }), idx))) }), void 0) }), idx))), _jsx(Button, Object.assign({ width: "auto", onClick: () => router.go(Routes.SHOP_SETTINGS_ATTRIBUTES_EDIT), className: styles["edit-btn"], icon: Edit }, { children: t("generics.edit") }), void 0)] }, void 0))] }, void 0) }), void 0));
};
export default ShopAttributesView;

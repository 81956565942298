import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import styles from "./BigCardLayout.module.scss";
import { Card } from "../Card";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
const BigCardLayout = ({ title, subtitle, SubtitleRight, children, onClickBack, }) => {
    const router = useRouter();
    if (!onClickBack) {
        onClickBack = () => {
            router.history.goBack();
        };
    }
    return (_jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsxs("div", Object.assign({ className: styles["title-container"] }, { children: [_jsx(BackCircle, { onClick: onClickBack, className: styles["title-container__back-button"] }, void 0), title] }), void 0), _jsx(Card, { children: _jsxs("div", Object.assign({ className: styles["card-content"] }, { children: [_jsxs("div", Object.assign({ className: styles["card-content__header"] }, { children: [_jsx("span", Object.assign({ className: styles["card-content__header__title"] }, { children: subtitle }), void 0), SubtitleRight] }), void 0), children] }), void 0) }, void 0)] }), void 0));
};
export default BigCardLayout;

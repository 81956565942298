import { useEffect, useState } from "react";
import { useRouter } from "@bluelibs/x-ui";
import classNames from "classnames";
import { getProduct } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/queries";
import { useQuery } from "@apollo/client";
import { Routes } from "@bundles/UIAppBundle";
import { MainButtonsEnum } from "./types";
import styles from "./ProductsManagementLayout.module.scss";
export const useProductsManagementLayout = (id) => {
    const router = useRouter();
    const [openKeys, setOpenKeys] = useState([]);
    const { data, loading, error } = useQuery(getProduct, {
        skip: !id,
        fetchPolicy: "network-only",
        variables: { input: { _id: id } },
    });
    const mainBtnClasses = (type) => {
        const { pathname } = router.history.location;
        return classNames(styles["control-btn"], {
            [styles.active]: (pathname.includes("variants") &&
                type === MainButtonsEnum.PRODUCT_VARIANTS) ||
                (!pathname.includes("variants") &&
                    type === MainButtonsEnum.MAIN_PRODUCT),
        });
    };
    const variantBtnClasses = (id) => {
        const { pathname } = router.history.location;
        return classNames(styles["variant-btn"], {
            [styles.active]: pathname.includes(id),
        });
    };
    useEffect(() => {
        const { pathname } = router.history.location;
        if (pathname.includes("variants"))
            setOpenKeys(["variants"]);
    }, [router]);
    const navigateTo = (route, params = {}) => router.go(route, { params });
    const onOpenChange = () => {
        const { variants } = data.AdminProductsGet;
        if (variants.length)
            navigateTo(Routes.PRODUCT_VARIANTS_EDIT, {
                productId: id,
                variantId: variants[0]._id,
            });
        else
            navigateTo(Routes.PRODUCT_VARIANTS_CREATE, { id });
    };
    return {
        onOpenChange,
        navigateTo,
        openKeys,
        loading,
        data,
        error,
        mainBtnClasses,
        variantBtnClasses,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { NotificationsCollection, InternalUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
let NotificationSubscriberListFiltersForm = class NotificationSubscriberListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "status",
                label: t("management.notification_subscribers.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.notification_subscribers.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "SENT" }, { children: "Sent" }), "SENT"), _jsx(Ant.Select.Option, Object.assign({ value: "ERROR" }, { children: "Error" }), "ERROR")] }), void 0) }), void 0)),
            },
            {
                id: "isRead",
                label: t("management.notification_subscribers.fields.isRead"),
                name: ["isRead"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "readAt",
                label: t("management.notification_subscribers.fields.readAt"),
                name: ["readAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "notificationId",
                label: t("management.notification_subscribers.fields.notification"),
                name: ["notificationId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: NotificationsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "userId",
                label: t("management.notification_subscribers.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
NotificationSubscriberListFiltersForm = __decorate([
    Service({ transient: true })
], NotificationSubscriberListFiltersForm);
export { NotificationSubscriberListFiltersForm };

import { gql } from "@apollo/client";
export const AdminGetShippingAddresses = gql `
  query AdminGetShippingAddresses {
    AdminGetShippingAddresses {
      _id
      city
      zipcode
      streetAddressLine1
      createdAt
    }
  }
`;

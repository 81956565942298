import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import classNames from "classnames";
import { useRichTextEditor } from "./hooks";
import styles from "./RichTextEditor.module.scss";
const RichTextEditor = ({ value, onChange, description, placeholder, className, label, id, }) => {
    const { editorState, onEditorStateChange, uploadImageCallBack } = useRichTextEditor(value, onChange);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className), id: id }, { children: [_jsx("label", { children: label }, void 0), description && _jsx("p", Object.assign({ className: styles.description }, { children: description }), void 0), _jsx(Editor, { editorState: editorState, onEditorStateChange: onEditorStateChange, placeholder: placeholder, toolbar: {
                    options: [
                        "blockType",
                        "fontSize",
                        "inline",
                        "link",
                        "list",
                        "image",
                        "textAlign",
                    ],
                    image: {
                        uploadCallback: uploadImageCallBack,
                        defaultSize: {
                            width: 500,
                        },
                        previewImage: true,
                        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    },
                    inline: {
                        inDropdown: false,
                        options: ["bold", "italic"],
                    },
                } }, void 0)] }), void 0));
};
export default RichTextEditor;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ProductsCollection, ProductCategoriesCollection, InternalUserProfilesCollection, VatRatesCollection, UnitsOfMeasurementCollection, } from "@bundles/UIAppBundle/collections";
let ProductVariantListFiltersForm = class ProductVariantListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "salesRayId",
                label: t("management.product_variants.fields.salesRayId"),
                name: ["salesRayId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "name",
                label: t("management.product_variants.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.product_variants.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "params",
                label: t("management.product_variants.fields.params"),
                name: ["params"],
                columns: true,
                nest: [],
            },
            {
                id: "sku",
                label: t("management.product_variants.fields.sku"),
                name: ["sku"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "seoFields",
                label: t("management.product_variants.fields.seoFields"),
                name: ["seoFields"],
                columns: true,
                nest: [
                    {
                        id: "pageTitle",
                        label: t("management.product_variants.fields.seoFields.pageTitle"),
                        name: ["seoFields", "pageTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "metaDescription",
                        label: t("management.product_variants.fields.seoFields.metaDescription"),
                        name: ["seoFields", "metaDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "keywords",
                        label: t("management.product_variants.fields.seoFields.keywords"),
                        name: ["seoFields", "keywords"],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "seoTitle",
                        label: t("management.product_variants.fields.seoFields.seoTitle"),
                        name: ["seoFields", "seoTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "seoDescription",
                        label: t("management.product_variants.fields.seoFields.seoDescription"),
                        name: ["seoFields", "seoDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "slug",
                        label: t("management.product_variants.fields.seoFields.slug"),
                        name: ["seoFields", "slug"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "availability",
                label: t("management.product_variants.fields.availability"),
                name: ["availability"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.product_variants.fields.availability") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "OUT_OF_STOCK" }, { children: "Out Of Stock" }), "OUT_OF_STOCK"), _jsx(Ant.Select.Option, Object.assign({ value: "IN_STOCK" }, { children: "In Stock" }), "IN_STOCK"), _jsx(Ant.Select.Option, Object.assign({ value: "IN_STOCK_EXTERNAL" }, { children: "In Stock External" }), "IN_STOCK_EXTERNAL")] }), void 0) }), void 0)),
            },
            {
                id: "isPublished",
                label: t("management.product_variants.fields.isPublished"),
                name: ["isPublished"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "publishedAt",
                label: t("management.product_variants.fields.publishedAt"),
                name: ["publishedAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "discountPercentage",
                label: t("management.product_variants.fields.discountPercentage"),
                name: ["discountPercentage"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "discountActiveUntil",
                label: t("management.product_variants.fields.discountActiveUntil"),
                name: ["discountActiveUntil"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "tags",
                label: t("management.product_variants.fields.tags"),
                name: ["tags"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "attributesValues",
                label: t("management.product_variants.fields.attributesValues"),
                name: ["attributesValues"],
                columns: true,
                nest: [
                    {
                        id: "key",
                        label: t("management.product_variants.fields.attributesValues.key"),
                        name: ["attributesValues", "key"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "value",
                        label: t("management.product_variants.fields.attributesValues.value"),
                        name: ["attributesValues", "value"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "suffix",
                        label: t("management.product_variants.fields.attributesValues.suffix"),
                        name: ["attributesValues", "suffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "isNumber",
                        label: t("management.product_variants.fields.attributesValues.isNumber"),
                        name: ["attributesValues", "isNumber"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                ],
            },
            {
                id: "daysBeforeAllowingReview",
                label: t("management.product_variants.fields.daysBeforeAllowingReview"),
                name: ["daysBeforeAllowingReview"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "brand",
                label: t("management.product_variants.fields.brand"),
                name: ["brand"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.product_variants.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.product_variants.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "DIGITAL" }, { children: "Digital" }), "DIGITAL"), _jsx(Ant.Select.Option, Object.assign({ value: "PHYSICAL" }, { children: "Physical" }), "PHYSICAL"), _jsx(Ant.Select.Option, Object.assign({ value: "SERVICE" }, { children: "Service" }), "SERVICE")] }), void 0) }), void 0)),
            },
            {
                id: "rating",
                label: t("management.product_variants.fields.rating"),
                name: ["rating"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "country",
                label: t("management.product_variants.fields.country"),
                name: ["country"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "salesRayImageUrl",
                label: t("management.product_variants.fields.salesRayImageUrl"),
                name: ["salesRayImageUrl"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "height",
                label: t("management.product_variants.fields.height"),
                name: ["height"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "width",
                label: t("management.product_variants.fields.width"),
                name: ["width"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "length_",
                label: t("management.product_variants.fields.length_"),
                name: ["length_"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "netWeight",
                label: t("management.product_variants.fields.netWeight"),
                name: ["netWeight"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "grossWeight",
                label: t("management.product_variants.fields.grossWeight"),
                name: ["grossWeight"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "coefficient",
                label: t("management.product_variants.fields.coefficient"),
                name: ["coefficient"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "productId",
                label: t("management.product_variants.fields.product"),
                name: ["productId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "categoryId",
                label: t("management.product_variants.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "publishedById",
                label: t("management.product_variants.fields.publishedBy"),
                name: ["publishedById"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "vatRateId",
                label: t("management.product_variants.fields.vatRate"),
                name: ["vatRateId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: VatRatesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "unitOfMeasurementId",
                label: t("management.product_variants.fields.unitOfMeasurement"),
                name: ["unitOfMeasurementId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UnitsOfMeasurementCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ProductVariantListFiltersForm = __decorate([
    Service({ transient: true })
], ProductVariantListFiltersForm);
export { ProductVariantListFiltersForm };

import { InvoiceStatus, OrderStatus, PaymentOptionType, ReturnStatus, ReviewStatus, } from "@root/api.types";
import { TagColorsEnum } from "../components/CustomTag/types";
export const invoiceTagColors = {
    [InvoiceStatus.CANCELED]: TagColorsEnum.RED,
    [InvoiceStatus.DRAFT]: TagColorsEnum.GRAY,
    [InvoiceStatus.OPEN]: TagColorsEnum.ORANGE,
    [InvoiceStatus.PAID]: TagColorsEnum.GREEN,
    [InvoiceStatus.AWAITING_PAYMENT]: TagColorsEnum.BLUE,
};
export const ordersTagColors = {
    [OrderStatus.CANCELED]: TagColorsEnum.RED,
    [OrderStatus.DRAFT]: TagColorsEnum.GRAY,
    [OrderStatus.PLACED]: TagColorsEnum.GREEN,
    [OrderStatus.DELIVERED]: TagColorsEnum.PURPLE,
    [OrderStatus.SHIPPING]: TagColorsEnum.BLUE,
};
export const returnsTagColors = {
    [ReturnStatus.CANCELED]: TagColorsEnum.RED,
    [ReturnStatus.COMPLETED]: TagColorsEnum.GREEN,
    [ReturnStatus.PENDING]: TagColorsEnum.ORANGE,
};
export const paymentMethodTagColors = {
    [PaymentOptionType.CARD_POS]: TagColorsEnum.GREEN,
    [PaymentOptionType.CASH]: TagColorsEnum.RED,
    [PaymentOptionType.BANK_TRANSFER]: TagColorsEnum.ORANGE,
    [PaymentOptionType.STRIPE]: TagColorsEnum.PURPLE,
    [PaymentOptionType.PAYU]: TagColorsEnum.BLUE,
    [PaymentOptionType.LATER]: TagColorsEnum.BLUE,
};
export const reviewStatusTagColors = {
    [ReviewStatus.APPROVED]: TagColorsEnum.GREEN,
    [ReviewStatus.UNAPPROVED]: TagColorsEnum.RED,
    [ReviewStatus.PENDING]: TagColorsEnum.GRAY,
};

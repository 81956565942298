var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let OrderItemViewer = class OrderItemViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.order_items.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productSnapshot.name",
                label: t("management.order_items.fields.productSnapshot.name"),
                dataIndex: ["productSnapshot", "name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productSnapshot.description",
                label: t("management.order_items.fields.productSnapshot.description"),
                dataIndex: ["productSnapshot", "description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productSnapshot.sku",
                label: t("management.order_items.fields.productSnapshot.sku"),
                dataIndex: ["productSnapshot", "sku"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productSnapshot.imageUrl",
                label: t("management.order_items.fields.productSnapshot.imageUrl"),
                dataIndex: ["productSnapshot", "imageUrl"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                label: t("management.order_items.fields.status"),
                dataIndex: ["status"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedPrice.currency",
                label: t("management.order_items.fields.appliedPrice.currency"),
                dataIndex: ["appliedPrice", "currency"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedPrice.gross",
                label: t("management.order_items.fields.appliedPrice.gross"),
                dataIndex: ["appliedPrice", "gross"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedPrice.net",
                label: t("management.order_items.fields.appliedPrice.net"),
                dataIndex: ["appliedPrice", "net"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedPrice.tax",
                label: t("management.order_items.fields.appliedPrice.tax"),
                dataIndex: ["appliedPrice", "tax"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.currency",
                label: t("management.order_items.fields.price.currency"),
                dataIndex: ["price", "currency"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.gross",
                label: t("management.order_items.fields.price.gross"),
                dataIndex: ["price", "gross"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.net",
                label: t("management.order_items.fields.price.net"),
                dataIndex: ["price", "net"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.tax",
                label: t("management.order_items.fields.price.tax"),
                dataIndex: ["price", "tax"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedDiscounts",
                label: t("management.order_items.fields.appliedDiscounts"),
                dataIndex: ["appliedDiscounts"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "discounts",
                label: t("management.order_items.fields.discounts"),
                dataIndex: ["discounts"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "count",
                label: t("management.order_items.fields.count"),
                dataIndex: ["count"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatPercent",
                label: t("management.order_items.fields.vatPercent"),
                dataIndex: ["vatPercent"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedVatRate.name",
                label: t("management.order_items.fields.appliedVatRate.name"),
                dataIndex: ["appliedVatRate", "name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedVatRate.percentage",
                label: t("management.order_items.fields.appliedVatRate.percentage"),
                dataIndex: ["appliedVatRate", "percentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "appliedVatRate.description",
                label: t("management.order_items.fields.appliedVatRate.description"),
                dataIndex: ["appliedVatRate", "description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatRate.name",
                label: t("management.order_items.fields.vatRate.name"),
                dataIndex: ["vatRate", "name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatRate.percentage",
                label: t("management.order_items.fields.vatRate.percentage"),
                dataIndex: ["vatRate", "percentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatRate.description",
                label: t("management.order_items.fields.vatRate.description"),
                dataIndex: ["vatRate", "description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isRevieawable",
                label: t("management.order_items.fields.isRevieawable"),
                dataIndex: ["isRevieawable"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.order_items.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.order_items.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productVariant",
                label: t("management.order_items.fields.productVariant"),
                dataIndex: ["productVariant"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_VARIANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "order",
                label: t("management.order_items.fields.order"),
                dataIndex: ["order"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "orderNumber",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "product3DModel",
                label: t("management.order_items.fields.product3DModel"),
                dataIndex: ["product3DModel"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_3_D_MODEL_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            productSnapshot: {
                name: 1,
                description: 1,
                sku: 1,
                imageUrl: 1,
            },
            status: 1,
            appliedPrice: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            price: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            appliedDiscounts: {
                discountSource: 1,
                categoryId: 1,
                discountPercentage: 1,
            },
            discounts: {
                discountSource: 1,
                categoryId: 1,
                discountPercentage: 1,
            },
            count: 1,
            vatPercent: 1,
            appliedVatRate: {
                name: 1,
                percentage: 1,
                description: 1,
            },
            vatRate: {
                name: 1,
                percentage: 1,
                description: 1,
            },
            isRevieawable: 1,
            createdAt: 1,
            updatedAt: 1,
            productVariant: {
                _id: 1,
                name: 1,
            },
            productVariantId: 1,
            order: {
                _id: 1,
                orderNumber: 1,
            },
            orderId: 1,
            product3DModel: {
                _id: 1,
                name: 1,
            },
            product3DModelId: 1,
        };
    }
};
OrderItemViewer = __decorate([
    Service({ transient: true })
], OrderItemViewer);
export { OrderItemViewer };

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ObjectId } from "@bluelibs/ejson";
import { notification } from "antd";
import { OrderStatus } from "@root/api.types";
import { orderStatusLabel } from "@bundles/UIAppBundle/consts/labels";
import { useRouter } from "@bluelibs/x-ui";
import { ORDERS_LIST } from "../../routes";
import { useCompanyDetails, useOrderDetail, } from "@bundles/UIAppBundle/components/OrderDetail/hooks";
import { GET_INTERNAL_USER_PROFILES, GET_PAYMENT_OPTIONS } from "./query";
import { UPDATE_ORDER } from "./mutation";
export const useUpdateOrder = (orderId) => {
    var _a;
    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const { orderDetail, loading: orderDetailLoading } = useOrderDetail(orderId);
    useEffect(() => {
        if (orderDetail) {
            setNewOrderDetail(orderDetail);
        }
    }, [orderDetailLoading]);
    const { companyDetails, loading: companyDetailsLoading } = useCompanyDetails((_a = orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.buyerId) === null || _a === void 0 ? void 0 : _a.toString());
    const [newOrderDetail, setNewOrderDetail] = useState(orderDetail);
    const router = useRouter();
    const onPaymentOptionSelectHandler = (value) => {
        setNewOrderDetail({
            ...newOrderDetail,
            paymentOptionId: value,
        });
    };
    const onOrderStatusSelectHandler = (value) => {
        setNewOrderDetail({
            ...newOrderDetail,
            status: value,
        });
    };
    const onNoteEditHandler = (value) => {
        setNewOrderDetail({
            ...newOrderDetail,
            privateNotes: value,
        });
    };
    const { data: paymentOptionsData, loading: paymentOptionLoading } = useQuery(GET_PAYMENT_OPTIONS);
    const { data: teamMemberProfilesData, loading: teamMemberProfilesLoading } = useQuery(GET_INTERNAL_USER_PROFILES, {
        variables: {
            input: {
                filters: {
                    isSuspended: false,
                },
            },
        },
    });
    useEffect(() => {
        var _a;
        setOrderStatusOptions(Object.keys(OrderStatus)
            .map((status) => ({
            value: status,
            label: orderStatusLabel[status],
        }))
            .filter((status) => status.value !== OrderStatus.DRAFT));
        setPaymentOptions((_a = paymentOptionsData === null || paymentOptionsData === void 0 ? void 0 : paymentOptionsData.AdminGetPaymentOptions) === null || _a === void 0 ? void 0 : _a.map(({ name, _id }) => ({
            value: _id,
            label: name,
        })));
        setTeamList(teamMemberProfilesData === null || teamMemberProfilesData === void 0 ? void 0 : teamMemberProfilesData.InternalUserProfileAdminListGet.data);
    }, [paymentOptionsData, teamMemberProfilesData]);
    const [updateOrder] = useMutation(UPDATE_ORDER);
    const onSubmit = async (orderId, document) => {
        updateOrder({
            variables: {
                id: new ObjectId(orderId),
                document: {
                    privateNotes: document.privateNotes,
                    shippingAddress: {
                        ...document.shippingAddress,
                        __typename: undefined,
                    },
                    billingAddress: {
                        ...document.billingAddress,
                        __typename: undefined,
                    },
                    buyerSnapshot: {
                        name: document.buyerSnapshot.name,
                        phoneNumber: document.buyerSnapshot.phoneNumber,
                        email: document.buyerSnapshot.email,
                        ...document.buyerSnapshot,
                        __typename: undefined,
                    },
                    status: document.status,
                    paymentOptionId: document.paymentOptionId,
                    salesAgentId: document.salesAgentId,
                    __typename: undefined,
                },
            },
        })
            .then(() => {
            router.go(ORDERS_LIST);
            notification.success({
                message: "Order updated successfully",
            });
        })
            .catch(() => {
            notification.error({
                message: "Order update failed",
            });
        });
    };
    return {
        onSubmit,
        orderStatusOptions,
        paymentOptions,
        paymentOptionLoading,
        teamList,
        teamMemberProfilesLoading,
        companyDetailsLoading,
        orderDetailLoading,
        newOrderDetail,
        companyDetails,
        orderDetail,
        onOrderStatusSelectHandler,
        onPaymentOptionSelectHandler,
        onNoteEditHandler,
    };
};

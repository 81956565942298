import { gql } from "@apollo/client";
export const GET_INVOICE_LIST = gql `
  query Query($input: AdminGetInvoiceListInput) {
    AdminGetInvoiceList(input: $input) {
      data {
        _id
        status
        downloadUrl
        price {
          currency
          gross
        }
        order {
          _id
          placedAt
          orderNumber
        }
        invoiceId
        orderId
        buyer {
          _id
          fullName
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
        paymentType {
          type
        }
        createdAt
      }
      skip
      limit
      total
    }
  }
`;

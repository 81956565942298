import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useRouter, useTranslate } from "@bluelibs/x-ui";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Table } from "@bundles/UIAppBundle/components";
import { AddSimple } from "@bundles/UIAppBundle/icons";
import { Routes } from "@bundles/UIAppBundle";
import { PaymentOptionsAntTableSmart } from "./PaymentOptionsTableSmart";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function PaymentOptionsList() {
    const router = useRouter();
    const t = useTranslate();
    const [api, Provider] = newSmart(PaymentOptionsAntTableSmart);
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.paymentOption.list }, void 0), _jsx(ShopConfigurationLayout, { children: _jsxs(Provider, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.shop_settings.submenus.payment_details") }), void 0), _jsx(Table, Object.assign({ className: styles.table }, api.getTableProps()), void 0), _jsx(Button, Object.assign({ width: "auto", onClick: () => router.go(Routes.PAYMENT_OPTIONS_CREATE), className: styles["absolute-btn"], icon: AddSimple }, { children: t("management.payment_options.create.header") }), void 0)] }, void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { useFiltersData } from "./hooks";
import * as Ant from "antd";
import { DatePicker } from "@bundles/UIAppBundle/components";
import styles from "./filters.module.scss";
import "./ant-select.scss";
export function Filters({ teamMemberHandler, categoryHandler, productHandler, variantHandler, dateRangeHandler, }) {
    var _a, _b, _c, _d;
    const { teamData, categoryData, productData, variantData } = useFiltersData();
    return (_jsx("div", Object.assign({ className: `${styles.card}` }, { children: _jsxs("div", Object.assign({ className: styles.indexrow }, { children: [teamData && teamData.InternalUserProfilesFind ? (_jsx(Ant.Select, Object.assign({ className: styles.personslct, bordered: false, allowClear: true, placeholder: "Select Team Member", onChange: (value) => {
                        teamMemberHandler(value);
                    } }, { children: (_a = teamData === null || teamData === void 0 ? void 0 : teamData.InternalUserProfilesFind) === null || _a === void 0 ? void 0 : _a.map((member) => (_jsx(Ant.Select.Option, Object.assign({ value: member._id }, { children: member.fullName }), member._id))) }), void 0)) : (_jsx(_Fragment, {}, void 0)), _jsxs("div", Object.assign({ className: styles.slcts }, { children: [categoryData && categoryData.ProductCategoriesFind ? (_jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: "Select Category", className: styles.slct, bordered: false, onChange: (value) => {
                                categoryHandler(value);
                            } }, { children: (_b = categoryData === null || categoryData === void 0 ? void 0 : categoryData.ProductCategoriesFind) === null || _b === void 0 ? void 0 : _b.map((category) => (_jsx(Ant.Select.Option, Object.assign({ value: category._id }, { children: category.name }), category._id))) }), void 0)) : (_jsx(_Fragment, {}, void 0)), productData && productData.ProductsFind ? (_jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: "Select Product", className: styles.slct, bordered: false, onChange: (value) => {
                                productHandler(value);
                            } }, { children: (_c = productData === null || productData === void 0 ? void 0 : productData.ProductsFind) === null || _c === void 0 ? void 0 : _c.map((product) => (_jsx(Ant.Select.Option, Object.assign({ value: product._id }, { children: product.name }), product._id))) }), void 0)) : (_jsx(_Fragment, {}, void 0)), variantData && variantData.ProductVariantsFind ? (_jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: "Select Product Variant", className: styles.slct, bordered: false, onChange: (value) => {
                                variantHandler(value);
                            } }, { children: (_d = variantData === null || variantData === void 0 ? void 0 : variantData.ProductVariantsFind) === null || _d === void 0 ? void 0 : _d.map((variant) => (_jsx(Ant.Select.Option, Object.assign({ value: variant._id }, { children: variant.name }), variant._id))) }), void 0)) : (_jsx(_Fragment, {}, void 0))] }), void 0), _jsx("div", Object.assign({ className: styles.daypicker }, { children: _jsx(DatePicker, { disabledDate: (currentDate) => currentDate.isSameOrAfter(moment()), range: true, transient: true, allowClear: true, onChange: (date) => {
                            if (date === null) {
                                dateRangeHandler([null, null]);
                            }
                            else {
                                dateRangeHandler(date);
                            }
                        }, bordered: false }, void 0) }), void 0)] }), void 0) }), void 0));
}

import { gql } from "@apollo/client";
export const GET_ARTICLE = gql `
  query AdminArticleGet($input: AdminArticleGetInput!) {
    AdminArticleGet(input: $input) {
      _id
      body
      createdAt
      slug
      title
      updatedAt
    }
  }
`;

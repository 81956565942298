var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ShippingAddressList = class ShippingAddressList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "nbh",
                title: t("management.shipping_address.fields.nbh"),
                key: "management.shipping_address.fields.nbh",
                dataIndex: ["nbh"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "building_floor",
                title: t("management.shipping_address.fields.building_floor"),
                key: "management.shipping_address.fields.building_floor",
                dataIndex: ["building_floor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "zip",
                title: t("management.shipping_address.fields.zip"),
                key: "management.shipping_address.fields.zip",
                dataIndex: ["zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address",
                title: t("management.shipping_address.fields.address"),
                key: "management.shipping_address.fields.address",
                dataIndex: ["address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "building_entrance",
                title: t("management.shipping_address.fields.building_entrance"),
                key: "management.shipping_address.fields.building_entrance",
                dataIndex: ["building_entrance"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "street",
                title: t("management.shipping_address.fields.street"),
                key: "management.shipping_address.fields.street",
                dataIndex: ["street"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "district",
                title: t("management.shipping_address.fields.district"),
                key: "management.shipping_address.fields.district",
                dataIndex: ["district"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "tax",
                title: t("management.shipping_address.fields.tax"),
                key: "management.shipping_address.fields.tax",
                dataIndex: ["tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "country",
                title: t("management.shipping_address.fields.country"),
                key: "management.shipping_address.fields.country",
                dataIndex: ["country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "note",
                title: t("management.shipping_address.fields.note"),
                key: "management.shipping_address.fields.note",
                dataIndex: ["note"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                title: t("management.shipping_address.fields.email"),
                key: "management.shipping_address.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "num",
                title: t("management.shipping_address.fields.num"),
                key: "management.shipping_address.fields.num",
                dataIndex: ["num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                title: t("management.shipping_address.fields.salesRayId"),
                key: "management.shipping_address.fields.salesRayId",
                dataIndex: ["salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "zip_num",
                title: t("management.shipping_address.fields.zip_num"),
                key: "management.shipping_address.fields.zip_num",
                dataIndex: ["zip_num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fax",
                title: t("management.shipping_address.fields.fax"),
                key: "management.shipping_address.fields.fax",
                dataIndex: ["fax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "building",
                title: t("management.shipping_address.fields.building"),
                key: "management.shipping_address.fields.building",
                dataIndex: ["building"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "settlement",
                title: t("management.shipping_address.fields.settlement"),
                key: "management.shipping_address.fields.settlement",
                dataIndex: ["settlement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phone",
                title: t("management.shipping_address.fields.phone"),
                key: "management.shipping_address.fields.phone",
                dataIndex: ["phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "building_apartment",
                title: t("management.shipping_address.fields.building_apartment"),
                key: "management.shipping_address.fields.building_apartment",
                dataIndex: ["building_apartment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.shipping_address.fields.createdAt"),
                key: "management.shipping_address.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.shipping_address.fields.updatedAt"),
                key: "management.shipping_address.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "endUserProfile",
                title: t("management.shipping_address.fields.endUserProfile"),
                key: "management.shipping_address.fields.endUserProfile",
                dataIndex: ["endUserProfile"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.shipping_address.fields.createdBy"),
                key: "management.shipping_address.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.shipping_address.fields.updatedBy"),
                key: "management.shipping_address.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            endUserProfile: "endUserProfile.fullName",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            nbh: 1,
            building_floor: 1,
            zip: 1,
            address: 1,
            building_entrance: 1,
            street: 1,
            district: 1,
            tax: 1,
            country: 1,
            note: 1,
            email: 1,
            num: 1,
            salesRayId: 1,
            zip_num: 1,
            fax: 1,
            building: 1,
            settlement: 1,
            phone: 1,
            building_apartment: 1,
            createdAt: 1,
            updatedAt: 1,
            endUserProfile: {
                _id: 1,
                fullName: 1,
            },
            endUserProfileId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
ShippingAddressList = __decorate([
    Service({ transient: true })
], ShippingAddressList);
export { ShippingAddressList };

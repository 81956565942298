import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { UnitsOfMeasurementListFilters } from "./UnitsOfMeasurementListFilters";
import { CustomPageHeader, Table } from "@bundles/UIAppBundle/components";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { useUnitOfMeasurementList } from "./hooks";
export function UnitsOfMeasurementList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { api, Provider, isLoadingSync, filtersOpened, setFiltersOpened, onFiltersUpdate, onClickSync, } = useUnitOfMeasurementList();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.unitsOfMeasurements.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.units_of_measurement.list.header"), searchKey: "name", searchPlaceholder: t("generics.list_search"), syncLabel: t("management.units_of_measurement.list.sync"), isLoadingSync: isLoadingSync, onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened, onClickSync: onClickSync }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsxs("div", Object.assign({ className: "page-units-of-measurement-list" }, { children: [filtersOpened && (_jsx(UnitsOfMeasurementListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Table, Object.assign({}, api.getTableProps()), void 0)] }), void 0) }, void 0) }, void 0)] }, void 0)] }), void 0));
}

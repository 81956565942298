import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useEffect } from "react";
import classNames from "classnames";
import { Listbox, Transition } from "@headlessui/react";
import { CustomTag } from "../CustomTag";
import { TagColorsEnum } from "../CustomTag/types";
import { Button } from "../Button";
import { DownChevron } from "@bundles/UIAppBundle/icons";
import { useControlledSelect } from "./hooks";
import styles from "./ControlledSelect.module.scss";
const ControlledSelect = ({ label = "", description, placeholder, containerClassName, error, options, onChange, multiple, value: defaultValue, id, }) => {
    const { value, _onChange } = useControlledSelect({
        defaultValue,
        onChange,
    });
    useEffect(() => {
        if (!defaultValue)
            return;
        _onChange(defaultValue);
    }, [defaultValue]);
    const itemClasses = (active, selected, disabled) => classNames({
        [styles.active]: active,
        [styles.selected]: selected,
        [styles.disabled]: disabled,
    });
    const wrapperClasses = classNames(styles["options-container"], multiple ? styles.multiple : styles.single);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, containerClassName), id: id }, { children: [_jsx("label", { children: label }, void 0), description && _jsx("p", Object.assign({ className: styles.description }, { children: description }), void 0), _jsx(Listbox, Object.assign({ as: "div", value: value, onChange: _onChange, multiple: multiple }, { children: ({ open }) => {
                    var _a;
                    return (_jsxs(Fragment, { children: [_jsxs(Listbox.Button, Object.assign({ as: Button }, { children: [_jsx("span", Object.assign({ className: value === undefined ? styles.placeholder : "" }, { children: ((_a = options.find((option) => option.value === value)) === null || _a === void 0 ? void 0 : _a.label) ||
                                            placeholder }), void 0), _jsx(DownChevron, { className: styles[`${open ? "up" : "down"}-chevron`] }, void 0)] }), void 0), _jsx(Transition, Object.assign({ as: Fragment, leave: styles["leave-transition"], leaveFrom: styles["leave-from"], leaveTo: styles["leave-to"], enter: styles["enter-transition"], enterFrom: styles["enter-from"], enterTo: styles["enter-to"] }, { children: _jsx("div", Object.assign({ className: wrapperClasses }, { children: _jsx(Listbox.Options, { children: options.map((option) => (_jsx(Listbox.Option, Object.assign({ value: option.value, disabled: option.disabled, as: Fragment }, { children: ({ active, selected, disabled }) => (_jsx("li", Object.assign({ className: itemClasses(active, selected, disabled) }, { children: option.label }), void 0)) }), option.value))) }, void 0) }), void 0) }), void 0)] }, void 0));
                } }), void 0), _jsx("div", Object.assign({ className: classNames(styles.error, {
                    [styles["error--visible"]]: error,
                }) }, { children: error }), void 0), multiple && (_jsx("div", Object.assign({ className: styles["selected-items"] }, { children: Array.isArray(value) &&
                    value.map((option, key) => (_jsx(CustomTag, { color: options.find((s) => s.value === option).tagColor ||
                            TagColorsEnum.GRAY, variant: "filled", value: options.find((s) => s.value === option).label }, key))) }), void 0))] }), void 0));
};
export default ControlledSelect;

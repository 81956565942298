import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ProductOrder } from "../ProductOrder";
import { Address } from "./components/Address";
import { AssignTeamMember } from "./components/AssignTeamMember";
import { ShippingMethod } from "./components/ShippingMethod";
import { PaymentInformation } from "./components/PaymentInformation";
import { Notes } from "./components/Notes";
import { Returns } from "./components/Returns";
import { ClientInfo } from "../ClientInfo";
import { useOrderDetail } from "./hooks";
import styles from "./OrderDetail.module.scss";
const OrderDetail = ({ id, returnStatus, returnId }) => {
    var _a, _b, _c;
    const { orderDetail, loading } = useOrderDetail(id);
    if (loading) {
        return _jsx(_Fragment, { children: "Loading..." }, void 0);
    }
    const { _id, items: orderItems, status, shippingAddress, billingAddress, salesAgentId, paymentOption, privateNotes, buyerSnapshot, buyer, shippingOption, priceVat, } = orderDetail;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(ProductOrder, { subTotal: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.price, shippingCharge: shippingOption === null || shippingOption === void 0 ? void 0 : shippingOption.price, vatCharge: priceVat, total: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.priceTotal, items: orderItems, status: status }, void 0), _jsxs("div", Object.assign({ className: styles["shipping-info-container"] }, { children: [_jsx(ClientInfo, { clientData: buyerSnapshot || {}, avatarUrl: (_c = (_b = (_a = buyer === null || buyer === void 0 ? void 0 : buyer.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl }, void 0), shippingAddress && (_jsx(Address, { title: "Shipping", address: shippingAddress, showImage: false }, void 0)), returnId && _jsx(Returns, { id: returnId, status: returnStatus }, void 0), _jsx(AssignTeamMember, { orderId: _id, salesAgentId: salesAgentId }, void 0), shippingOption && _jsx(ShippingMethod, { shippingOption: shippingOption }, void 0), billingAddress && (_jsx(Address, { title: "Billing", showImage: false, address: billingAddress }, void 0)), paymentOption && _jsx(PaymentInformation, { paymentInfo: paymentOption }, void 0), _jsx(Notes, { notes: privateNotes }, void 0)] }), void 0)] }), void 0));
};
export default OrderDetail;

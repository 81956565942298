import { GetInTouchsList } from "../components/List/GetInTouchsList";
import { GetInTouchsCreate } from "../components/Create/GetInTouchsCreate";
import { GetInTouchsEdit } from "../components/Edit/GetInTouchsEdit";
import { GetInTouchsView } from "../components/View/GetInTouchsView";
import { SettingFilled } from "@ant-design/icons";
export const GET_IN_TOUCHS_LIST = {
    path: "/admin/get-in-touchs",
    component: GetInTouchsList,
    menu: {
        key: "GET_IN_TOUCHS_LIST",
        label: "management.get_in_touchs.menu.title",
        icon: SettingFilled,
    },
};
export const GET_IN_TOUCHS_CREATE = {
    path: "/admin/get-in-touchs/create",
    component: GetInTouchsCreate,
};
export const GET_IN_TOUCHS_EDIT = {
    path: "/admin/get-in-touchs/:id/edit",
    component: GetInTouchsEdit,
};
export const GET_IN_TOUCHS_VIEW = {
    path: "/admin/get-in-touchs/:id/view",
    component: GetInTouchsView,
};

import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
export var STATUS;
(function (STATUS) {
    STATUS["MODIFIED"] = "Modified";
    STATUS["UNCHANGED"] = "Unchanged";
})(STATUS || (STATUS = {}));
export const statusColors = {
    [STATUS.MODIFIED]: TagColorsEnum.GREEN,
    [STATUS.UNCHANGED]: TagColorsEnum.RED,
};

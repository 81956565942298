import "./i18n";
import { UNITS_OF_MEASUREMENT_LIST as BASE_UNITS_OF_MEASUREMENT_LIST, UNITS_OF_MEASUREMENT_CREATE as BASE_UNITS_OF_MEASUREMENT_CREATE, UNITS_OF_MEASUREMENT_EDIT as BASE_UNITS_OF_MEASUREMENT_EDIT, UNITS_OF_MEASUREMENT_VIEW as BASE_UNITS_OF_MEASUREMENT_VIEW, } from "./config/routes";
export const UNITS_OF_MEASUREMENT_LIST = {
    ...BASE_UNITS_OF_MEASUREMENT_LIST,
    path: "/units-of-measurement",
};
export const UNITS_OF_MEASUREMENT_CREATE = {
    ...BASE_UNITS_OF_MEASUREMENT_CREATE,
};
export const UNITS_OF_MEASUREMENT_EDIT = {
    ...BASE_UNITS_OF_MEASUREMENT_EDIT,
};
export const UNITS_OF_MEASUREMENT_VIEW = {
    ...BASE_UNITS_OF_MEASUREMENT_VIEW,
    path: "/units-of-measurement/:id/view",
};

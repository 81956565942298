var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ControlledInput, ControlledSelect, ControlledTextArea, DatePicker, InputNumber, RichTextEditor, } from "@bundles/UIAppBundle/components";
import { availabilityOptions, productTypeOptions, yesOrNoOptions, } from "../../ProductsManagement/components/Create/consts";
import { ProductVariantEditForm as BaseProductVariantEditForm } from "./ProductVariantEditForm.base";
import { ProductTypes } from "@root/api.types";
import { inheritedAttributesListRenderer } from "../../ProductsManagement/components/Create/helpers/inheritedAttritubesListRenderer";
import styles from "../../ProductsManagement/components/Create/ProductsCreate.module.scss";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let ProductVariantEditForm = class ProductVariantEditForm extends BaseProductVariantEditForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("publishedAt");
        this.remove("tags");
        this.remove("daysBeforeAllowingReview");
        this.update("name", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.name")), placeholder: t("management.products.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(RichTextEditor, { label: t("management.products.fields.description"), placeholder: t("management.products.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("brand", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.brand")), placeholder: t("management.products.placeholders.brand") }, void 0) }), void 0) }), void 0)),
        });
        this.update("type", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: productTypeOptions, label: t("management.products.fields.type"), placeholder: t("management.products.placeholders.type") }, void 0) }), void 0) }), void 0)),
        });
        this.update("isPublished", {
            render: (props) => (_jsxs("div", Object.assign({ className: styles["published-column"] }, { children: [_jsx("div", Object.assign({ className: styles["side-label"] }, { children: t("management.products.fields.isPublished") }), void 0), _jsx(Ant.Form.Item, Object.assign({}, props, { noStyle: true, label: "", initialValue: true }, { children: _jsx(ControlledSelect, { containerClassName: styles.select, options: yesOrNoOptions }, void 0) }), void 0)] }), void 0)),
        });
        this.update("weight", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 9 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.weight")), placeholder: t("management.products.placeholders.weight") }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("length", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.descriptions.length")), placeholder: t("management.products.placeholders.length") }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("width", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: "\u00A0", placeholder: t("management.products.placeholders.width") }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("height", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: "\u00A0", placeholder: t("management.products.placeholders.height") }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("country", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.country"), placeholder: t("management.products.placeholders.type_here") }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("sku", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.sku")), placeholder: t("management.products.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("availableStockCount", {
            rules: [
                {
                    required: true,
                    message: t("generics.forms.field_required", {
                        field: t("management.products.fields.availableStockCount"),
                    }),
                },
                {
                    type: "number",
                    min: 0,
                    message: t("generics.forms.rangeMin", {
                        field: t("management.products.fields.availableStockCount"),
                        min: 0,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: addRequiredFieldSpecialMark(t("management.products.fields.availableStockCount")), placeholder: t("management.products.placeholders.type_here"), type: "number" }, void 0) }), void 0) }), void 0)),
        });
        this.update("availability", {
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: availabilityOptions, label: t("management.products.fields.availability"), placeholder: t("management.products.placeholders.availability") }, void 0) }), void 0) }), void 0)),
        });
        this.update("netPrice", {
            required: true,
            rules: [
                {
                    required: true,
                    message: t("generics.forms.field_required", {
                        field: t("management.product_variants.fields.netPrice"),
                    }),
                },
                {
                    type: "number",
                    min: 0,
                    message: t("generics.forms.rangeMin", {
                        field: t("management.product_variants.fields.netPrice"),
                        min: 0,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: addRequiredFieldSpecialMark(t("management.product_variants.fields.netPrice")), placeholder: t("management.product_variants.placeholders.netPrice"), type: "number" }, void 0) }), void 0) }), void 0)),
        });
        this.update("discountPercentage", {
            required: false,
            rules: [
                {
                    type: "number",
                    min: 1,
                    max: 99,
                    message: t("generics.forms.range", {
                        field: t("management.products.fields.discountPercentage"),
                        min: 1,
                        max: 99,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: _jsxs(_Fragment, { children: [t("management.products.fields.discountPercentage"), "\u00A0", _jsx("span", Object.assign({ className: styles.comment }, { children: t("management.products.fields.discountPercentage_comment") }), void 0)] }, void 0), placeholder: t("management.products.placeholders.discountPercentage"), type: "number" }, void 0) }), void 0) }), void 0)),
        });
        this.update("discountActiveUntil", {
            required: false,
            name: ["discountActiveUntil"],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(DatePicker, { label: t("management.product_variants.fields.discountActiveUntil"), placeholder: t("management.product_variants.placeholders.discountActiveUntil"), disabledDate: (date) => date.valueOf() < Date.now().valueOf() }, void 0) }), void 0) }), void 0)),
        });
        this.update("attributesValues", {
            required: true,
            render: (props) => _jsx(Ant.Form.Item, Object.assign({}, props, { label: "", noStyle: true, hidden: true }), void 0),
        });
        this.update("seoFields", {
            nest: [
                {
                    id: "pageTitle",
                    name: ["seoFields"]["pageTitle"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.pageTitle"), placeholder: t("management.products.placeholders.pageTitle") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "metaDescription",
                    name: ["metaDescription"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledTextArea, { label: t("management.products.fields.metaDescription"), placeholder: t("management.products.placeholders.metaDescription"), rows: 7 }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "keywords",
                    name: ["keywords"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.keywords"), placeholder: t("management.products.placeholders.keywords"), description: t("management.products.descriptions.keywords") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "slug",
                    name: ["slug"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.slug"), placeholder: t("management.products.placeholders.slug"), StartComponent: _jsx("span", Object.assign({ className: styles["base-url"] }, { children: `${process.env.SHOP_URL}/` }), void 0) }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        this.add([
            {
                id: "defaultImageIndex",
                name: ["defaultImageIndex"],
                required: false,
                render: (props) => _jsx(Ant.Form.Item, Object.assign({}, props, { label: "", noStyle: true, hidden: true }), void 0),
            },
            {
                id: "attributeOptions",
                name: ["attributeOptions"],
                required: true,
                isList: true,
                listRenderer: () => inheritedAttributesListRenderer({
                    collection: "attributeOptions",
                    noDescription: true,
                    isVariant: true,
                    isEdit: true,
                }),
            },
        ]);
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ProductVariantEditForm = __decorate([
    Service({ transient: true })
], ProductVariantEditForm);
export { ProductVariantEditForm };

import { useEffect, useState } from "react";
import * as Ant from "antd";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { useTranslate } from "@bluelibs/x-ui";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ObjectId } from "@bluelibs/ejson";
import { CustomProductVariantsCollection } from "@bundles/UIAppBundle/collections/ProductVariants/ProductVariants.custom.collection";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { Routes } from "@bundles/UIAppBundle";
export const useProductPage = (id) => {
    var _a;
    const router = useRouter();
    const t = useTranslate();
    const [data, setData] = useState({
        product: null,
        productVariant: null,
    });
    const [isLoading, setIsLoading] = useState(true);
    const shopSettings = useShopSettings();
    const customProductVariantCollection = use(CustomProductVariantsCollection);
    const getData = async () => {
        await shopSettings.init();
        customProductVariantCollection
            .findOne({
            productVariantId: new ObjectId(id),
        })
            .then((productVariant) => {
            if (productVariant) {
                const product = productVariant.product;
                const productAttributeOptions = (product === null || product === void 0 ? void 0 : product.attributeOptions) || [];
                const categoryAttributeOptions = (product === null || product === void 0 ? void 0 : product.categoriesBreadcrumbs.flatMap((item) => item === null || item === void 0 ? void 0 : item.attributeOptions)) || [];
                const shopAttributeOptions = (shopSettings === null || shopSettings === void 0 ? void 0 : shopSettings.state.attributeOptions) || [];
                const allAttributes = [
                    ...productAttributeOptions,
                    ...categoryAttributeOptions,
                    ...shopAttributeOptions,
                ];
                const allAtributesWithValues = allAttributes.map((attribute) => {
                    const values = [];
                    product === null || product === void 0 ? void 0 : product.variants.forEach((variant) => {
                        variant === null || variant === void 0 ? void 0 : variant.attributesValues.forEach((variantAttribute) => {
                            if ((variantAttribute === null || variantAttribute === void 0 ? void 0 : variantAttribute.key) === (attribute === null || attribute === void 0 ? void 0 : attribute.name)) {
                                values.push((variantAttribute === null || variantAttribute === void 0 ? void 0 : variantAttribute.value) || "");
                            }
                        });
                    });
                    return {
                        ...attribute,
                        values: Array.from(new Set(values)),
                    };
                });
                setData({
                    productVariant,
                    product,
                    attributeOptions: allAtributesWithValues,
                });
            }
            else {
                alert("not found");
            }
        })
            .catch((err) => {
            console.log(err);
            Ant.notification.error({
                message: "Failed to fetch product variant details",
            });
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);
    const getAdditionalInfo = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return [
            {
                value: (_a = data === null || data === void 0 ? void 0 : data.productVariant) === null || _a === void 0 ? void 0 : _a.brand,
                label: t("management.product_variants.fields.brand"),
            },
            {
                value: ((_b = data === null || data === void 0 ? void 0 : data.productVariant) === null || _b === void 0 ? void 0 : _b.type[0].toUpperCase()) +
                    ((_c = data === null || data === void 0 ? void 0 : data.productVariant) === null || _c === void 0 ? void 0 : _c.type.substring(1).toLowerCase()),
                label: t("management.product_variants.fields.type"),
            },
            {
                value: ((_e = (_d = data.product) === null || _d === void 0 ? void 0 : _d.availableStockCount) === null || _e === void 0 ? void 0 : _e.toString()) || "0",
                label: t("management.product_variants.fields.availableStockCount"),
            },
            {
                value: (_f = data.productVariant) === null || _f === void 0 ? void 0 : _f.netWeight,
                label: t("management.product_variants.fields.weight"),
            },
            {
                value: (_g = data.productVariant) === null || _g === void 0 ? void 0 : _g.width,
                label: t("management.product_variants.fields.width"),
            },
            {
                value: (_h = data.productVariant) === null || _h === void 0 ? void 0 : _h.height,
                label: t("management.product_variants.fields.height"),
            },
            {
                value: (_j = data.productVariant) === null || _j === void 0 ? void 0 : _j.length_,
                label: t("management.product_variants.fields.length"),
            },
            ...Object.keys(((_k = data.product) === null || _k === void 0 ? void 0 : _k.params) || {})
                .sort((a, b) => a.length - b.length)
                .map((key) => ({ value: data.product.params[key], label: key })),
        ];
    };
    const getOldPrice = () => {
        var _a, _b;
        return ((_a = data === null || data === void 0 ? void 0 : data.productVariant) === null || _a === void 0 ? void 0 : _a.totalDiscountPercentage) > 0
            ? (_b = data === null || data === void 0 ? void 0 : data.productVariant) === null || _b === void 0 ? void 0 : _b.netPrice
            : undefined;
    };
    const getFinalPrice = () => {
        var _a, _b;
        return (((_a = data === null || data === void 0 ? void 0 : data.productVariant) === null || _a === void 0 ? void 0 : _a.netPrice) *
            (100 - ((_b = data === null || data === void 0 ? void 0 : data.productVariant) === null || _b === void 0 ? void 0 : _b.totalDiscountPercentage))) /
            100 || 0;
    };
    const unitOfMeasurementOptions = (_a = data.product) === null || _a === void 0 ? void 0 : _a.variants.map((variant) => {
        const unit = variant === null || variant === void 0 ? void 0 : variant.unitOfMeasurement;
        if (!unit)
            return { label: "", value: "" };
        let label = "";
        if (unit.hasWeight) {
            label = `${unit.name}: ${parseFloat(variant.grossWeight || "?")} (gross) ${parseFloat(variant.netWeight || "?")} (net) ${unit.label}`;
        }
        else {
            label = `${unit.name}: ${parseFloat(variant.width || "0")} x ${parseFloat(variant.length_ || "0")} x ${parseFloat(variant.height || "0")} ${unit.label}`;
        }
        return { label, value: unit._id };
    });
    const onChangeUnitOfMeasurement = (value) => {
        const targetProductVariant = data.product.variants.find((variant) => (variant === null || variant === void 0 ? void 0 : variant.unitOfMeasurement._id) === value);
        if (!targetProductVariant || targetProductVariant._id === id)
            return;
        router.go(Routes.PRODUCT_VARIANTS_VIEW, {
            params: { id: targetProductVariant._id },
        });
    };
    return {
        ...data,
        getData,
        oldPrice: getOldPrice(),
        price: getFinalPrice(),
        additionalInfo: getAdditionalInfo(),
        isLoading,
        unitOfMeasurementOptions,
        onChangeUnitOfMeasurement,
    };
};

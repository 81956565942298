var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { CustomTag } from "@bundles/UIAppBundle/components/CustomTag";
import { InternalUserProfileList as BaseInternalUserProfileList } from "./InternalUserProfileList.base";
import styles from "../components/List/InternalUserProfilesList.module.scss";
let InternalUserProfileList = class InternalUserProfileList extends BaseInternalUserProfileList {
    build() {
        super.build();
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("firstName");
        this.remove("middleName");
        this.remove("lastName");
        this.remove("orders");
        this.remove("user");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("suspendedAt");
        this.remove("isSuspended");
        this.remove("clients");
        this.remove("activeNotifications.orderPlaced");
        this.remove("activeNotifications.orderStatusChanged");
        this.remove("activeNotifications.returnCreated");
        this.remove("activeNotifications.returnStatusChanged");
        this.remove("activeNotifications.clientCreated");
        this.remove("activeNotifications.invoiceCreated");
        this.remove("activeNotifications.productOutOfStock");
        this.remove("activeNotifications.productPromotionExpire");
        this.remove("notifications");
        this.update("fullName", {
            title: _jsx("div", Object.assign({ className: styles["column-header-name"] }, { children: "Name" }), void 0),
            order: 0,
            render: (value, model) => {
                var _a, _b;
                return (_jsxs("span", { children: [_jsx("img", { className: styles.avatar, src: generateProfileImageLink(value, (_b = (_a = model.user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl) }, void 0), value] }, void 0));
            },
        });
        this.update("email", { order: 1 });
        this.update("phoneNumber", {
            title: "Phone",
            order: 2,
        });
        this.add({
            order: 3,
            id: "roles",
            title: "Permissions",
            key: "management.users.fields.roles",
            dataIndex: "user",
            render: (user) => {
                return (_jsx("div", Object.assign({ className: styles["roles-wrapper"] }, { children: user &&
                        user.roles.map((role, idx) => (_jsx(CustomTag, { color: TagColorsEnum.GREEN, value: role.split("_")[0] }, idx))) }), void 0));
            },
        });
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            phoneNumber: "phoneNumber",
            email: "email",
            "user.fullName": "fullName",
        };
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            user: {
                _id: 1,
                fullName: 1,
                roles: 1,
                isSuspended: 1,
                suspendedAt: 1,
            },
        };
    }
};
InternalUserProfileList = __decorate([
    Service({ transient: true })
], InternalUserProfileList);
export { InternalUserProfileList };

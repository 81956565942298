import { Collection, } from "@bluelibs/x-ui";
import { NotificationSubscriber } from "@root/api.types";
import { NotificationsCollection, InternalUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
export class NotificationSubscribersCollection extends Collection {
    getName() {
        return "NotificationSubscribers";
    }
    getInputs() {
        return {
            insert: "NotificationSubscriberInsertInput!",
            update: "NotificationSubscriberUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => NotificationsCollection,
                name: "notification",
                field: "notificationId",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "user",
                field: "userId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            readAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from "classnames";
import { Service } from "@bluelibs/core";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { CustomTag } from "@bundles/UIAppBundle/components";
import { Clipboard } from "@bundles/UIAppBundle/icons";
import { Routes } from "@bundles/UIAppBundle";
import { paymentMethodTagColors, returnsTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { formatCurrencyAndPrice } from "@bundles/UIAppBundle/utils/helpers";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { returnStatusLabel } from "@bundles/UIAppBundle/consts/labels";
import { ReturnList as BaseReturnList } from "./ReturnList.base";
import styles from "../components/List/ReturnsList.module.scss";
let ReturnList = class ReturnList extends BaseReturnList {
    build() {
        const { router } = this;
        const { t } = this.i18n;
        super.build();
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.remove("billingAddress");
        this.remove("shippingAddress");
        this.remove("items");
        this.remove("notes");
        this.remove("updatedAt");
        this.remove("invoice");
        this.remove("admin");
        this.remove("order");
        this.remove("price.gross");
        this.remove("price.net");
        this.remove("price.tax");
        this.remove("price.currency");
        this.remove("shippingOption");
        this.remove("shippingAddress.nbh");
        this.remove("shippingAddress.building_floor");
        this.remove("shippingAddress.zip");
        this.remove("shippingAddress.address");
        this.remove("shippingAddress.building_entrance");
        this.remove("shippingAddress.street");
        this.remove("shippingAddress.district");
        this.remove("shippingAddress.tax");
        this.remove("shippingAddress.note");
        this.remove("shippingAddress.num");
        this.remove("shippingAddress.salesRayId");
        this.remove("shippingAddress.zip_num");
        this.remove("shippingAddress.fax");
        this.remove("shippingAddress.building");
        this.remove("shippingAddress.settlement");
        this.remove("shippingAddress.phone");
        this.remove("shippingAddress.building_apartment");
        this.remove("shippingAddress.streetAddressLine1");
        this.remove("shippingAddress.streetAddressLine2");
        this.remove("shippingAddress.city");
        this.remove("shippingAddress.country");
        this.remove("shippingAddress.email");
        this.remove("billingAddress.nbh");
        this.remove("billingAddress.building_floor");
        this.remove("billingAddress.zip");
        this.remove("billingAddress.address");
        this.remove("billingAddress.building_entrance");
        this.remove("billingAddress.street");
        this.remove("billingAddress.district");
        this.remove("billingAddress.tax");
        this.remove("billingAddress.note");
        this.remove("billingAddress.num");
        this.remove("billingAddress.salesRayId");
        this.remove("billingAddress.zip_num");
        this.remove("billingAddress.fax");
        this.remove("billingAddress.building");
        this.remove("billingAddress.settlement");
        this.remove("billingAddress.phone");
        this.remove("billingAddress.building_apartment");
        this.remove("billingAddress.email");
        this.remove("billingAddress.country");
        this.add({
            title: "",
            sorter: false,
            order: 0,
            width: 0,
            id: "invoiceImg",
            dataIndex: ["status"],
            render: (status) => (_jsx("div", { children: _jsx(CustomTag, { isIcon: true, variant: "filled", color: returnsTagColors[status], value: _jsx(Clipboard, {}, void 0) }, void 0) }, void 0)),
        });
        this.update("number", {
            order: 1,
            title: t("management.returns.table.number"),
            dataIndex: ["number"],
            render: (value, model) => {
                return (_jsx("div", Object.assign({ className: classNames(styles["order-wrapper"], styles["no-wrap"]) }, { children: _jsx("span", { children: value }, void 0) }), void 0));
            },
        });
        this.update("endUser", {
            order: 2,
            dataIndex: ["endUser"],
            title: t("management.returns.table.endUser"),
            render: ({ _id, fullName, user }, model) => {
                var _a, _b;
                return (_jsxs("div", Object.assign({ className: classNames(styles["buyer-wrapper"], styles["no-wrap"]) }, { children: [_jsx("img", { src: generateProfileImageLink(fullName, (_b = (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl) }, void 0), _jsx("span", Object.assign({ onClick: () => {
                                var _a;
                                return router.go(Routes.END_USER_PROFILES_VIEW, {
                                    params: {
                                        id: (_a = model === null || model === void 0 ? void 0 : model.order) === null || _a === void 0 ? void 0 : _a.buyerId,
                                    },
                                });
                            } }, { children: fullName }), void 0)] }), void 0));
            },
        });
        this.update("createdAt", {
            order: 3,
            id: "createdAt",
            title: t("management.returns.table.createdAt"),
            dataIndex: ["createdAt"],
            sorter: true,
            render: (value, model) => {
                const date = new Date(value);
                const dateString = date.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                });
                return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
            },
        });
        this.update("paymentOption", {
            order: 4,
            title: t("management.returns.table.payment"),
            dataIndex: ["order", "paymentOption"],
            sorter: true,
            render: (value, model) => {
                return (_jsx("div", Object.assign({ className: styles["payment"] }, { children: _jsx(CustomTag, { color: value ? paymentMethodTagColors[value === null || value === void 0 ? void 0 : value.type] : TagColorsEnum.GRAY, value: value ? value.name : "dummy", variant: "filled" }, void 0) }), void 0));
            },
        });
        this.update("status", {
            order: 5,
            title: t("management.returns.table.status"),
            sorter: true,
            render: (value, model) => {
                return (_jsx("div", Object.assign({ className: classNames(styles["status"]) }, { children: _jsx(CustomTag, { value: returnStatusLabel[value], variant: "filled", color: returnsTagColors[model.status] }, void 0) }), void 0));
            },
        });
        this.add({
            id: "price",
            dataIndex: ["price"],
            order: 6,
            title: t("management.returns.table.price"),
            sorter: true,
            render: (price, model) => (_jsx("span", Object.assign({ className: styles["bold"] }, { children: formatCurrencyAndPrice(price.currency, price.gross) }), void 0)),
        });
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            number: "number",
            endUser: "clientName",
            price: "price",
            createdAt: "createdAt",
            status: "status",
            "order.paymentOption": "paymentMethod",
        };
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            billingAddress: 0,
            shippingAddress: 0,
            items: 0,
        };
    }
};
ReturnList = __decorate([
    Service({ transient: true })
], ReturnList);
export { ReturnList };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate, useUIComponents } from "@bluelibs/x-ui";
import classNames from "classnames";
import { Alert, Layout } from "antd";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { CustomPageHeader, Table } from "@bundles/UIAppBundle/components";
import { useInvoice } from "./hooks";
import { InvoicesListFilters } from "./InvoicesListFilters";
import styles from "./InvoicesList.module.scss";
import { invoiceAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const filterOptions = [
    { label: "All", value: null },
    { label: "Paid", value: "PAID" },
    { label: "Pending", value: "AWAITING_PAYMENT" },
    { label: "Refund", value: "CANCELED" },
];
export function InvoicesList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { Provider, api, filtersOpened, setFiltersOpened, onFiltersUpdate } = useInvoice();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: invoiceAccessRoles }, { children: [_jsx(Seo, { data: seoData.invoice.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.invoices.list.header"), searchKey: "invoiceId", searchPlaceholder: t("management.invoices.list.search"), filterOptions: filterOptions, onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened, filterKey: "status" }, void 0), api.state.isError && (_jsx(Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Layout.Content, { children: _jsxs(Provider, { children: [filtersOpened && (_jsx(InvoicesListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Table, Object.assign({ className: classNames(styles["table"]) }, api.getTableProps(), { scroll: { x: "auto" } }), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0));
}

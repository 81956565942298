import { Collection, } from "@bluelibs/x-ui";
import { EndUserProfile } from "@root/api.types";
import { UsersCollection, OrdersCollection, ProductFavoritesCollection, ProductsCollection, InvoicesCollection, PaymentsCollection, InternalUserProfilesCollection, ShippingAddressCollection, Product3DModelCollection, } from "@bundles/UIAppBundle/collections";
export class EndUserProfilesCollection extends Collection {
    getName() {
        return "EndUserProfiles";
    }
    getInputs() {
        return {
            insert: "EndUserProfileInsertInput!",
            update: "EndUserProfileUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "user",
                field: "userId",
            },
            {
                collection: () => ProductsCollection,
                name: "latestViewedProducts",
                many: true,
                field: "latestViewedProductsIds",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "admin",
                field: "adminId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => OrdersCollection,
                name: "orders",
            },
            {
                collection: () => ProductFavoritesCollection,
                name: "productFavorites",
            },
            {
                collection: () => InvoicesCollection,
                name: "invoices",
            },
            {
                collection: () => PaymentsCollection,
                name: "payments",
            },
            {
                collection: () => ShippingAddressCollection,
                name: "savedAddresses",
                many: true,
            },
            {
                collection: () => Product3DModelCollection,
                name: "models",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            birthDate: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

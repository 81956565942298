import { gql } from "@apollo/client";
export const GET_PAYMENT_OPTIONS = gql `
  query AdminGetPaymentOptions {
    AdminGetPaymentOptions {
      _id
      name
    }
  }
`;
export const GET_SHIPPING_OPTIONS = gql `
  query AdminGetShippingOptions {
    AdminGetShippingOptions {
      name
      _id
      price
    }
  }
`;

import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { useGLTF } from "@react-three/drei";
import ImageDecal from "../components/ImageDecal";
import TextDecal from "../components/TextDecal";
import { DecalType } from "../consts";
const ModelMesh = forwardRef(({ model }, ref) => {
    const { nodes } = useGLTF(model.file);
    return (_jsx("group", Object.assign({ ref: ref }, { children: Object.keys(nodes).map((nodeName, index) => {
            var _a;
            return (_jsx("mesh", Object.assign({ castShadow: true }, nodes[nodeName], { children: (_a = model.decals) === null || _a === void 0 ? void 0 : _a.filter((decal) => decal.nodeName === nodeName).map((d, key) => {
                    if (d.type === DecalType.TEXT) {
                        return _jsx(TextDecal, { d: d }, key);
                    }
                    return _jsx(ImageDecal, { d: d }, key);
                }) }), index));
        }) }), void 0));
});
ModelMesh.displayName = "ModelMesh";
export default ModelMesh;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Smart } from "@bluelibs/smart";
import { useSmart } from "@bluelibs/smart";
import { Inject } from "@bluelibs/core";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { getShopSettings } from "./shop-settings.queries";
import { uploadFaviconMutation, uploadLogoMutation, } from "./shop-settings.mutations";
import CartContext from "./ShopSettingsContext";
import { formatCurrencyAndPrice } from "@bundles/UIAppBundle/utils/helpers";
export class ShopSettingsService extends Smart {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    async init() {
        this.apolloClient
            .query({
            query: getShopSettings,
            fetchPolicy: "network-only",
        })
            .then((response) => {
            this.setState(response.data.AdminGetShopSettings);
            this.syncFavicon(response.data.AdminGetShopSettings);
        })
            .catch((error) => {
            console.log(error.message);
        });
    }
    async destroy() { }
    formatPriceWithCurrency(price) {
        const currency = this.state.defaultCurrency;
        return formatCurrencyAndPrice(currency, price);
    }
    uploadLogo(logoFile) {
        return this.apolloClient.mutate({
            mutation: uploadLogoMutation,
            variables: {
                input: {
                    logo: logoFile,
                },
            },
        });
    }
    uploadFavicon(faviconFile) {
        return this.apolloClient.mutate({
            mutation: uploadFaviconMutation,
            variables: {
                input: {
                    favicon: faviconFile,
                },
            },
        });
    }
    syncFavicon(shopSettings) {
        var _a;
        const faviconURL = (_a = shopSettings.favicon) === null || _a === void 0 ? void 0 : _a.downloadUrl;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement("link");
            link.setAttribute("rel", "icon");
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.setAttribute("href", faviconURL);
    }
}
ShopSettingsService.getContext = () => CartContext;
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _a : Object)
], ShopSettingsService.prototype, "apolloClient", void 0);
export function useShopSettings() {
    return useSmart(ShopSettingsService);
}

import { gql } from "@apollo/client";
export const USER_UPLOAD_AVATAR = gql `
  mutation AdminUploadAvatar($input: AdminAvatarUploadInput!) {
    AdminUploadAvatar(input: $input) {
      _id
      name
      path
      downloadUrl
      size
      mimeType
      thumbs {
        type
        path
        downloadUrl
      }
      resourceType
      resourceId
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import styles from "./ControlledInput.module.scss";
// eslint-disable-next-line react/display-name
const ControlledInput = React.forwardRef(({ containerClassName, label = "", description, error, StartComponent, EndComponent, id, ...rest }, ref) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, containerClassName), id: id }, { children: [_jsx("label", { children: label }, void 0), description && _jsx("p", Object.assign({ className: styles.description }, { children: description }), void 0), _jsxs("div", Object.assign({ className: styles["input-container"] }, { children: [StartComponent, _jsx("input", Object.assign({ ref: ref }, rest), void 0), EndComponent] }), void 0), error && (_jsx("div", Object.assign({ className: classNames(styles.error, {
                    [styles["error--visible"]]: error,
                }) }, { children: error }), void 0))] }), void 0));
});
export default ControlledInput;

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
const ShopLogo = () => {
    var _a, _b, _c, _d, _e, _f;
    const shopSettings = useShopSettings();
    function Logo(props) {
        var _a;
        return (_jsx("div", { children: _jsx("img", { style: { margin: "auto" }, src: props.logoURL, alt: (_a = props.shopName) !== null && _a !== void 0 ? _a : "Shop Logo" }, void 0) }, void 0));
    }
    function Name() {
        var _a, _b;
        return _jsx("h2", { children: (_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.shopName) !== null && _b !== void 0 ? _b : "" }, void 0);
    }
    if ((_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.logo) === null || _b === void 0 ? void 0 : _b.downloadUrl) {
        return (_jsx(Logo, { logoURL: (_d = (_c = shopSettings.state) === null || _c === void 0 ? void 0 : _c.logo) === null || _d === void 0 ? void 0 : _d.downloadUrl, shopName: (_e = shopSettings.state) === null || _e === void 0 ? void 0 : _e.shopName }, void 0));
    }
    else if ((_f = shopSettings.state) === null || _f === void 0 ? void 0 : _f.shopName) {
        return _jsx(Name, {}, void 0);
    }
    else {
        return _jsx(Logo, { logoURL: generateImageLink() }, void 0);
    }
};
export default ShopLogo;

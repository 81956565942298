import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import style from "./ColorSelect.module.scss";
import { SketchPicker } from "react-color";
const SketchExample = ({ label = "", onChange, value, icon }) => {
    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [color, setColor] = React.useState(value);
    const Icon = icon;
    useEffect(() => {
        setColor(value);
    }, [value]);
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };
    const handleClose = () => {
        setDisplayColorPicker(false);
    };
    const handleChange = (color) => {
        setColor(color.hex);
        onChange(color.hex);
    };
    return (_jsxs("div", Object.assign({ className: style.container }, { children: [icon && _jsx(Icon, { onClick: handleClick }, void 0), displayColorPicker && (_jsxs("div", Object.assign({ className: style.popover }, { children: [_jsx("div", { className: style.cover, onClick: handleClose }, void 0), _jsx(SketchPicker, { color: color, onChange: handleChange, className: style["color-picker"] }, void 0)] }), void 0))] }), void 0));
};
export default SketchExample;

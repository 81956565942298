import { useTranslate } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
export const useShopConfgurationLayout = () => {
    const t = useTranslate();
    const links = [
        {
            route: Routes.SHOP_SETTINGS_GENERAL_DETAILS_VIEW,
            label: t("management.shop_settings.submenus.general_details"),
        },
        {
            route: Routes.SHOP_SETTINGS_INVOICING_DETAILS_VIEW,
            label: t("management.shop_settings.submenus.invoicing_details"),
        },
        {
            route: Routes.VAT_RATES_LIST,
            label: t("management.shop_settings.submenus.vat_details"),
        },
        {
            route: Routes.PAYMENT_OPTIONS_LIST,
            label: t("management.shop_settings.submenus.payment_details"),
        },
        {
            route: Routes.ARTICLES_LIST,
            label: t("management.shop_settings.submenus.custom_pages"),
        },
    ];
    return { links };
};

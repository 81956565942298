import { useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useMutation } from "@apollo/client";
import { UPLOAD_IMAGE } from "@bundles/UIAppBundle/components/RichTextEditor/mutations";
import htmlToDraft from "html-to-draftjs";
export const useRichTextEditor = (defaultValue, onChange) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [uploadImage] = useMutation(UPLOAD_IMAGE);
    useEffect(() => {
        if (!defaultValue)
            return;
        const contentBlock = htmlToDraft(defaultValue);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, []);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        onChange && onChange(html);
    };
    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            uploadImage({
                variables: {
                    upload: file,
                },
            })
                .then((response) => {
                resolve({ data: { link: response.data.AppFileUpload.downloadUrl } });
            })
                .catch((error) => {
                reject(error.message);
            });
        });
    };
    return {
        editorState,
        onEditorStateChange,
        uploadImageCallBack,
    };
};

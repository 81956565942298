import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter, useUIComponents } from "@bluelibs/x-ui";
import classNames from "classnames";
import { Card, Button } from "../..";
import { useSideBarSecondary } from "./hooks";
import styles from "./SideBarSecondary.module.scss";
const SideBarSecondary = ({ children, links, title, }) => {
    const UIComponents = useUIComponents();
    const { btnClasses } = useSideBarSecondary();
    const router = useRouter();
    return (_jsx(UIComponents.AdminLayout, { children: _jsx("div", Object.assign({ className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles["cards-wrapper"] }, { children: [_jsxs("div", Object.assign({ className: styles["left-section"] }, { children: [_jsx("div", Object.assign({ className: styles["left-section__title"] }, { children: _jsx("h2", Object.assign({ className: styles.title }, { children: title }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles["left-section__list"] }, { children: _jsx("ul", Object.assign({ className: classNames("cc-custom-list") }, { children: links.map(({ route, label }, idx) => (_jsx("li", Object.assign({ className: styles["list-item"] }, { children: _jsx(Button, Object.assign({ onClick: () => router.go(route), className: btnClasses(route.path), btnStyle: "white" }, { children: label }), idx) }), idx))) }), void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: styles["component-wrapper"] }, { children: children }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default SideBarSecondary;

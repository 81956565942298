import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { ReturnsCollection } from "@bundles/UIAppBundle/collections";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ReturnViewer } from "../../config/ReturnViewer";
import { features } from "../../config/features";
import { Topbar } from "@bundles/UIAppBundle/components";
import styles from "./ReturnsView.module.scss";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { ReturnDetail } from "@bundles/UIAppBundle/components/OrderDetail/ReturnDetail";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ReturnsView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(ReturnsCollection, new ObjectId(props.id), ReturnViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(ReturnsViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: [_jsx(Seo, { data: seoData.return.view }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsx(Topbar, { title: "Order Return Detail", btnText: "Invoice", btnOnClick: () => {
                                if (document.invoiceId) {
                                    router.go(Routes.INVOICES_VIEW, {
                                        params: { id: document.invoiceId },
                                    });
                                }
                            } }, void 0), content] }), void 0) }, void 0)] }), void 0));
}
export function ReturnsViewComponent(props) {
    const document = props.document;
    const viewer = use(ReturnViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return _jsx(ReturnDetail, { id: document._id }, void 0);
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    if (features.edit) {
        actions.push(_jsx(Link, Object.assign({ to: router.path(Routes.RETURNS_EDIT, {
                params: { id },
            }) }, { children: _jsx(Ant.Button, { children: t("generics.edit") }, void 0) }), "edit"));
    }
    if (features.delete) {
        actions.push(_jsx(Ant.Popconfirm, Object.assign({ title: "Are you sure you want to delete this Return?", onConfirm: () => {
                collection.deleteOne(id).then(() => {
                    router.go(Routes.RETURNS_LIST);
                    Ant.notification.success({
                        message: "Success",
                        description: "You have deleted the Return",
                    });
                });
            } }, { children: _jsx(Ant.Button, Object.assign({ danger: true }, { children: t("generics.delete") }), void 0) }), "delete"));
    }
    return actions;
}

import "./i18n";
import { ProductVariantsList } from "../ProductVariantsManagement/components/List/ProductVariantsList";
import { PRODUCTS_LIST as BASE_PRODUCTS_LIST, PRODUCTS_CREATE as BASE_PRODUCTS_CREATE, PRODUCTS_EDIT as BASE_PRODUCTS_EDIT, PRODUCTS_VIEW as BASE_PRODUCTS_VIEW, } from "./config/routes";
export const PRODUCTS_LIST = {
    ...BASE_PRODUCTS_LIST,
    component: ProductVariantsList,
    path: "/products",
};
export const PRODUCTS_CREATE = {
    ...BASE_PRODUCTS_CREATE,
    path: "/products/create",
};
export const PRODUCTS_EDIT = {
    ...BASE_PRODUCTS_EDIT,
    path: "/products/:id/edit",
};
export const PRODUCTS_VIEW = {
    ...BASE_PRODUCTS_VIEW,
};

import { useMutation } from "@apollo/client";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { CREATE_AVATAR } from "./mutation";
export const useAvatarCreate = () => {
    const [uploadAvatar] = useMutation(CREATE_AVATAR);
    const guardian = useAppGuardian();
    const { state: { user }, } = guardian;
    const onUpload = async (document) => {
        await uploadAvatar({
            variables: {
                input: {
                    avatar: document === null || document === void 0 ? void 0 : document.fileId,
                    forUserId: user === null || user === void 0 ? void 0 : user._id,
                },
            },
        });
    };
    return { onUpload };
};

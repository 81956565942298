import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Table as AntTable } from "antd";
import classNames from "classnames";
import styles from "./Table.module.scss";
const Table = ({ className, ...rest }) => {
    const tableClasses = classNames(styles.table, {
        [className]: className,
    });
    return _jsx(AntTable, Object.assign({ className: tableClasses }, rest), void 0);
};
export default Table;

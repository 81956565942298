import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents } from "@bluelibs/x-ui";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
export function ShopSettingsEdit() {
    const UIComponents = useUIComponents();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shopSettings.edit }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsx(ShopConfigurationLayout, {}, void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
import { ProductVariantViewer } from "../../config/ProductVariantViewer";
import { ProductDetail } from "@bundles/UIAppBundle/components/ProductDetail";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { ProductVariantReviewsTable } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/View/ProductVariantReviewsTable";
import { Topbar } from "@bundles/UIAppBundle/components";
export function ProductVariantsView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const collection = use(ProductVariantsCollection);
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(ProductVariantsCollection, new ObjectId(props.id), ProductVariantViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if ((error === null || error === void 0 ? void 0 : error.message) || document === null) {
            content = (_jsx(Ant.Alert, { message: error.message || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(ProductVariantsViewComponent, { document: document }, void 0);
        }
    }
    const productId = document === null || document === void 0 ? void 0 : document.productId;
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: {
                    ...seoData.productVariant.view,
                    title: `${document === null || document === void 0 ? void 0 : document.name} | View Product Variant`,
                    description: document === null || document === void 0 ? void 0 : document.description,
                } }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Topbar, { title: t("management.product_variants.view.header") }, void 0), _jsx(Ant.Card, { children: content }, void 0), (document === null || document === void 0 ? void 0 : document._id) && (_jsx("div", Object.assign({ className: "mt-4" }, { children: _jsx(ProductVariantReviewsTable, { productVariantId: document === null || document === void 0 ? void 0 : document._id }, void 0) }), void 0))] }, void 0)] }), void 0));
}
export function ProductVariantsViewComponent(props) {
    const document = props.document;
    /*
    const viewer = use(ProductVariantViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    */
    return _jsx(ProductDetail, { id: props.document._id }, void 0);
}
export function getHeaderActions(collection, router, variantId, productId) {
    const actions = [];
    const t = useTranslate();
    // const viewRoutePath = router.path(Routes.PRODUCT_VARIANTS_VIEW, {
    //   params: { id },
    // });
    return actions;
}

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Card } from "@bundles/UIAppBundle/components";
import styles from "./statcard.module.scss";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import StatCardLoader from "@bundles/UIAppBundle/pages/Dashboard/StatCards/StatCardsLoader";
export const StatCard = ({ className, value, title, svg, background, withCurrency = false, loading, }) => {
    const shopSettings = useShopSettings();
    return (_jsx(Card, Object.assign({ className: `${styles.card} ${className}` }, { children: loading ? (_jsx(StatCardLoader, {}, void 0)) : (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.title }, { children: title }), void 0), _jsx("div", Object.assign({ className: styles.value }, { children: withCurrency
                                ? shopSettings.formatPriceWithCurrency(value)
                                : value === null || value === void 0 ? void 0 : value.toLocaleString() }), void 0)] }, void 0), _jsx("div", Object.assign({ className: styles[`svgWrapper-${background}`] }, { children: _jsx("div", Object.assign({ className: styles.svgChild }, { children: svg }), void 0) }), void 0)] }, void 0)) }), void 0));
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { Link } from "react-router-dom";
import { Button, BigCardLayout } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import styles from "./ExportProducts.module.scss";
export const Start = ({ onClickStartExporting }) => {
    const t = useTranslate();
    return (_jsx(BigCardLayout, Object.assign({ title: t("management.export.title"), subtitle: t("management.export.headers.start") }, { children: _jsxs(_Fragment, { children: [_jsxs("div", { children: [t("management.export.descriptions.start.message"), " ", _jsx(Link, Object.assign({ to: Routes.PRODUCTS_LIST.path, className: styles["link"] }, { children: t("management.export.descriptions.start.messageProductsList") }), void 0)] }, void 0), _jsx(Button, Object.assign({ width: "fit-content", className: styles["submit"], onClick: onClickStartExporting }, { children: t("management.export.buttons.startExporting") }), void 0)] }, void 0) }), void 0));
};

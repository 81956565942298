var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { CustomTag } from "@bundles/UIAppBundle/components";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { UnitsOfMeasurementList as BaseUnitsOfMeasurementList } from "./UnitsOfMeasurementList.base";
let UnitsOfMeasurementList = class UnitsOfMeasurementList extends BaseUnitsOfMeasurementList {
    build() {
        super.build();
        this.update("hasWeight", {
            render: (value) => {
                return (_jsx(CustomTag, { value: value.toString(), color: value ? TagColorsEnum.GREEN : TagColorsEnum.RED, variant: "filled" }, void 0));
            },
        });
        // Perform additional modifications such as updating how a list item renders or add additional ones
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
UnitsOfMeasurementList = __decorate([
    Service({ transient: true })
], UnitsOfMeasurementList);
export { UnitsOfMeasurementList };

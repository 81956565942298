import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ShopLogo } from "@bundles/UIAppBundle/icons";
import { Card } from "../Card";
import styles from "./ViewInvoice.module.scss";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
const ViewInvoice = ({ invoice }) => {
    const { invoiceId, billingAddress, createdAt, sendAt, shippingAddress, price, shippingPrice, items, } = invoice;
    const settings = useShopSettings();
    const merchantAddress = settings.state.billingAddress;
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsxs(Card, Object.assign({ className: styles["card"] }, { children: [_jsxs("div", Object.assign({ className: styles["header-section"] }, { children: [_jsxs("div", Object.assign({ className: `${styles["row"]} ${styles["icon-row"]}` }, { children: [_jsx("div", Object.assign({ className: styles["icon"] }, { children: settings.state.shopName ? (_jsx(ShopLogo, {}, void 0)) : (_jsx(Spin, { indicator: _jsx(LoadingOutlined, { spin: true }, void 0), className: styles["loading"] }, void 0)) }), void 0), _jsxs("div", Object.assign({ className: styles["invoice"] }, { children: ["Invoice #", invoiceId, " "] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["row"] }, { children: [_jsxs("div", Object.assign({ className: styles["own-address"] }, { children: [_jsx("div", { children: merchantAddress === null || merchantAddress === void 0 ? void 0 : merchantAddress.streetAddressLine1 }, void 0), _jsxs("div", { children: [merchantAddress === null || merchantAddress === void 0 ? void 0 : merchantAddress.city, ", ", merchantAddress === null || merchantAddress === void 0 ? void 0 : merchantAddress.country] }, void 0), _jsx("div", { children: merchantAddress === null || merchantAddress === void 0 ? void 0 : merchantAddress.phoneNumber }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["date"] }, { children: [_jsxs("div", { children: [_jsx("div", { children: "Date issued" }, void 0), _jsx("div", { children: new Date(createdAt)
                                                        .toDateString()
                                                        .split(" ")
                                                        .slice(1)
                                                        .join(" ") }, void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", { children: "Due Date" }, void 0), _jsx("div", { children: new Date(sendAt)
                                                        .toDateString()
                                                        .split(" ")
                                                        .slice(1)
                                                        .join(" ") }, void 0)] }, void 0)] }), void 0)] }), void 0)] }), void 0), _jsx("div", { className: styles["hr"] }, void 0), _jsxs("div", Object.assign({ className: styles["address-section"] }, { children: [_jsxs("div", { children: [_jsx("div", { children: "Invoice To:" }, void 0), _jsxs("div", Object.assign({ className: styles["address"] }, { children: [_jsxs("div", { children: [billingAddress.address, " ", billingAddress.nbh] }, void 0), _jsx("div", { children: billingAddress.building }, void 0), _jsx("div", { children: billingAddress.fax }, void 0), _jsx("div", { children: billingAddress.email }, void 0), _jsxs("div", { children: [billingAddress.country, " ", billingAddress.zip_num] }, void 0), _jsxs("div", Object.assign({ className: styles["address__phone"] }, { children: [_jsx("span", { children: "Phone" }, void 0), _jsxs("span", { children: [":", billingAddress.phone] }, void 0)] }), void 0)] }), void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", { children: "Shipped at" }, void 0), _jsxs("div", { children: [_jsx("div", { children: shippingAddress.address }, void 0), _jsx("div", { children: shippingAddress.email }, void 0), _jsx("div", { children: shippingAddress.fax }, void 0), _jsxs("div", { children: [shippingAddress.country, " ", shippingAddress.zip_num] }, void 0)] }, void 0)] }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["items-container"] }, { children: [_jsxs("div", Object.assign({ className: styles["items-grid"] }, { children: [_jsx("div", { children: "PRODUCT" }, void 0), _jsx("div", { children: "PRICE" }, void 0), _jsx("div", { children: "Pcs" }, void 0), _jsx("div", Object.assign({ className: styles["items-grid__last"] }, { children: "Total" }), void 0)] }), void 0), _jsx("div", { className: styles["hr"] }, void 0), items.map(({ name, price, count }, index) => {
                            return (_jsxs("div", Object.assign({ className: styles["items-grid"] }, { children: [_jsx("div", { children: name }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(price.net / count) }, void 0), _jsx("div", { children: count }, void 0), _jsx("div", Object.assign({ className: styles["items-grid__last"] }, { children: settings.formatPriceWithCurrency(price.net) }), void 0)] }), index));
                        }), _jsxs("div", Object.assign({ className: styles["total-section"] }, { children: [_jsxs("div", Object.assign({ className: styles["total-grid"] }, { children: [_jsx("div", { children: "Subtotal:" }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(price.net) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["total-grid"] }, { children: [_jsx("div", { children: "Shipping Charge:" }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(shippingPrice === null || shippingPrice === void 0 ? void 0 : shippingPrice.gross) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["total-grid"] }, { children: [_jsx("div", { children: "VAT Tax:" }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(price.tax) }, void 0)] }), void 0), _jsx("div", { className: styles["hr-small"] }, void 0), _jsxs("div", Object.assign({ className: styles["total-grid"] }, { children: [_jsx("div", { children: "Total:" }, void 0), _jsx("div", { children: settings.formatPriceWithCurrency(price.gross) }, void 0)] }), void 0)] }), void 0)] }), void 0), _jsx("div", { className: styles["hr"] }, void 0), _jsx("div", Object.assign({ className: styles["note"] }, { children: "Note: It was a pleasure working with you and your team. We hope you will keep us in mind for your future freelance projects. Thank You!" }), void 0)] }), void 0) }), void 0));
};
export default ViewInvoice;

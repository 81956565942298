import "./i18n";
import { ARTICLES_LIST as BASE_ARTICLES_LIST, ARTICLES_CREATE as BASE_ARTICLES_CREATE, ARTICLES_EDIT as BASE_ARTICLES_EDIT, ARTICLES_VIEW as BASE_ARTICLES_VIEW, } from "./config/routes";
export const ARTICLES_LIST = {
    ...BASE_ARTICLES_LIST,
    path: "/shop-settings/articles",
};
export const ARTICLES_CREATE = {
    ...BASE_ARTICLES_CREATE,
    path: "/shop-settings/articles/create",
};
export const ARTICLES_EDIT = {
    ...BASE_ARTICLES_EDIT,
    path: "/shop-settings/articles/:id/edit",
};
export const ARTICLES_VIEW = {
    ...BASE_ARTICLES_VIEW,
    path: "/shop-settings/articles/:id/view",
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProductFavoriteList = class ProductFavoriteList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "createdAt",
                title: t("management.product_favorites.fields.createdAt"),
                key: "management.product_favorites.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.product_favorites.fields.updatedAt"),
                key: "management.product_favorites.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "quantity",
                title: t("management.product_favorites.fields.quantity"),
                key: "management.product_favorites.fields.quantity",
                dataIndex: ["quantity"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "endUserProfile",
                title: t("management.product_favorites.fields.endUserProfile"),
                key: "management.product_favorites.fields.endUserProfile",
                dataIndex: ["endUserProfile"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productVariant",
                title: t("management.product_favorites.fields.productVariant"),
                key: "management.product_favorites.fields.productVariant",
                dataIndex: ["productVariant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_VARIANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            endUserProfile: "endUserProfile.fullName",
            productVariant: "productVariant.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            createdAt: 1,
            updatedAt: 1,
            quantity: 1,
            endUserProfile: {
                _id: 1,
                fullName: 1,
            },
            endUserProfileId: 1,
            productVariant: {
                _id: 1,
                name: 1,
            },
            productVariantId: 1,
        };
    }
};
ProductFavoriteList = __decorate([
    Service({ transient: true })
], ProductFavoriteList);
export { ProductFavoriteList };

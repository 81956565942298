import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AttributeOptionsType } from "@root/api.types";
// TODO:`these are repeating in AddProduct PR, after merging both, use in all places from one place `
export const yesOrNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];
export const attributeTypeOptions = [
    { label: "Text", value: AttributeOptionsType.string },
    { label: "Number", value: AttributeOptionsType.number },
];
export const suffixOptions = [
    { label: "No Sufix Required", value: null },
    { label: "m (meters)", value: "meters" },
    {
        label: (_jsxs(_Fragment, { children: ["m", _jsx("sup", { children: "2" }, void 0), "\u00A0(square meters)"] }, void 0)),
        value: "square meters",
    },
    {
        label: (_jsxs(_Fragment, { children: ["m", _jsx("sup", { children: "3" }, void 0), "\u00A0(cube meters)"] }, void 0)),
        value: "cubic meters",
    },
    { label: "l (liters)", value: "liters" },
];

import { useRouter, useTranslate } from "@bluelibs/x-ui";
import classNames from "classnames";
import { Routes } from "@bundles/UIAppBundle";
import { matchPath } from "react-router-dom";
import ProfileDetails from "@bundles/UIAppBundle/icons/ProfileDetails";
import SvgOrders from "@bundles/UIAppBundle/icons/Orders";
import styles from "./ClientPageLayout.module.scss";
export const useShopConfgurationLayout = () => {
    const t = useTranslate();
    const router = useRouter();
    const links = [
        {
            route: Routes.END_USER_PROFILES_VIEW,
            label: t("management.end_user_profiles.submenus.profile"),
            icon: ProfileDetails,
        },
        {
            route: Routes.END_USER_PROFILES_ORDER_LIST,
            label: t("management.end_user_profiles.submenus.orders"),
            icon: SvgOrders,
        },
    ];
    const btnClasses = (url) => classNames(styles["control-btn"], matchPath(router.history.location.pathname, {
        path: url,
        exact: true,
        strict: false,
    })
        ? styles.active
        : "");
    return { btnClasses, links };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { Table } from "@bundles/UIAppBundle/components";
import { ArticlesAntTableSmart } from "./ArticlesTableSmart";
import styles from "./ArticlesList.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ArticlesList() {
    const t = useTranslate();
    const [api, Provider] = newSmart(ArticlesAntTableSmart);
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.article.list }, void 0), _jsx(ShopConfigurationLayout, { children: _jsxs(Provider, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.shop_settings.submenus.custom_pages") }), void 0), _jsx(Table, Object.assign({ className: styles.table }, api.getTableProps()), void 0)] }, void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import * as React from "react";
import { useTranslate } from "@bluelibs/x-ui";
import { useIntegrationDetails } from "./hooks";
import { Button, Card, ControlledInput } from "@bundles/UIAppBundle/components";
import styles from "./IntegrationDetails.module.scss";
import layoutStyles from "@bundles/UIAppBundle/pages/Marketing/config/MarketingLayout.module.scss";
const IntegrationDetails = ({ integrationOption, }) => {
    const { title, label, description, placeholder, integrationLink, loading, onChange, onSubmit, } = useIntegrationDetails(integrationOption);
    const t = useTranslate();
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: layoutStyles.title }, { children: t("management.marketing.submenus.integrations") }), void 0), _jsx(Card, Object.assign({ title: title.toUpperCase() }, { children: _jsxs(Ant.Form, { children: [_jsx(ControlledInput, { label: label, description: description, placeholder: placeholder, onChange: (e) => onChange(e.target.value), value: integrationLink }, void 0), _jsx("div", Object.assign({ className: styles["btn-container"] }, { children: _jsx(Button, Object.assign({ width: "fit-content", onClick: onSubmit, loading: loading }, { children: t("generics.submit") }), void 0) }), void 0)] }, void 0) }), void 0)] }, void 0));
};
export default IntegrationDetails;

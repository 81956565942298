import { gql } from "@apollo/client";
export const GET_RETURNS = gql `
  query AdminReturnsGet($input: AdminReturnsGetInput!) {
    AdminReturnsGet(input: $input) {
      data {
        _id
        createdAt
        endUser {
          fullName
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
        number
        status
        order {
          buyerId
          finalPrice
          paymentOption {
            _id
            name
            type
          }
        }
        price {
          gross
          net
          tax
          currency
        }
        invoiceId
      }
      skip
      limit
      total
    }
  }
`;
export const GET_PAYMENT_OPTOINS = gql `
  query AdminGetPaymentOptions {
    AdminGetPaymentOptions {
      _id
      type
      name
    }
  }
`;

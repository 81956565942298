import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import React, { useState } from "react";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card, notification } from "antd";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
export function Register() {
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.register");
    const [submitError, setSubmitError] = useState(null);
    const onSubmit = (data) => {
        const { email, password, firstName, lastName } = data;
        guardian
            .register({
            email,
            firstName,
            lastName,
            password,
        })
            .then(() => {
            notification.success({
                message: tl("success.header"),
                description: tl("success.description"),
            });
            setSubmitError(null);
            router.go(Routes.HOME);
        })
            .catch((err) => {
            setSubmitError(err.message || err.toString());
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(PublicRoute, { children: _jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "register-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsx(Card, Object.assign({ title: tl("header") }, { children: _jsxs(Form, Object.assign({ onFinish: (data) => onSubmit(data), className: "authentication-form" }, { children: [_jsx(Form.Item, Object.assign({ name: "firstName", rules: [{ required: true }] }, { children: _jsx(Input, { placeholder: tl("fields.firstName") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "lastName", rules: [{ required: true }] }, { children: _jsx(Input, { placeholder: tl("fields.lastName") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "email", rules: [{ required: true }] }, { children: _jsx(Input, { placeholder: tl("fields.email") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "password", rules: [{ required: true }] }, { children: _jsx(Input.Password, { placeholder: tl("fields.password") }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", className: "authentication-form-button" }, { children: tl("submit") }), void 0) }, void 0), submitError && _jsx(Alert, { message: submitError, type: "error" }, void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }, void 0));
}

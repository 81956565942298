import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable prettier/prettier */
import { DownChevron } from "@bundles/UIAppBundle/icons";
import { Button } from "@bundles/UIAppBundle/components";
import styles from "./ProductCategoriesList.module.scss";
// eslint-disable-next-line react/prop-types
const ExpandRowIcon = ({ expanded, onExpand, record }) => (_jsx("span", Object.assign({ className: styles["button-container"] }, { children: 
    // eslint-disable-next-line react/prop-types
    !!record.subCategoriesCount && (_jsx(Button, Object.assign({ btnStyle: "white", width: 30, className: styles["expand-btn"], onClick: (e) => onExpand(record, e) }, { children: _jsx(DownChevron, { className: expanded ? styles.inverted : styles.icon }, void 0) }), void 0)) }), void 0));
export default ExpandRowIcon;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Sync = (props) => (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 512 512" }, props, { children: [_jsx("path", { d: "M434.67,285.59v-29.8C434.67,157.06,354.43,77,255.47,77a179,179,0,0,0-140.14,67.36m-38.53,82v29.8C76.8,355,157,435,256,435a180.45,180.45,0,0,0,140-66.92", style: {
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "32px",
            } }, void 0), _jsx("polyline", { points: "32 256 76 212 122 256", style: {
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "32px",
            } }, void 0), _jsx("polyline", { points: "480 256 436 300 390 256", style: {
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "32px",
            } }, void 0)] }), void 0));
export default Sync;

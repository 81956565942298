import "./i18n";
import { PAYMENTS_LIST as BASE_PAYMENTS_LIST, PAYMENTS_CREATE as BASE_PAYMENTS_CREATE, PAYMENTS_EDIT as BASE_PAYMENTS_EDIT, PAYMENTS_VIEW as BASE_PAYMENTS_VIEW, } from "./config/routes";
export const PAYMENTS_LIST = {
    ...BASE_PAYMENTS_LIST,
};
export const PAYMENTS_CREATE = {
    ...BASE_PAYMENTS_CREATE,
};
export const PAYMENTS_EDIT = {
    ...BASE_PAYMENTS_EDIT,
};
export const PAYMENTS_VIEW = {
    ...BASE_PAYMENTS_VIEW,
};

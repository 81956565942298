import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { GET_ENDUSER_PROFILES_LIST } from "./query";
export const useAddClient = () => {
    const [clientsData, setClientsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableTotalData, setTableTotalData] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const pageSize = 4;
    const [getClients, { loading: clientsListLoading, data }] = useLazyQuery(GET_ENDUSER_PROFILES_LIST, {
        variables: {
            input: {
                filters: {
                    name: searchTerm,
                },
                options: {
                    skip: (currentPage - 1) * pageSize,
                    limit: pageSize,
                    sort: null,
                },
            },
        },
    });
    useEffect(() => {
        getClients();
        if (data) {
            setTableTotalData(data === null || data === void 0 ? void 0 : data.AdminEndUserProfileListGet.total);
            const modifiedData = data === null || data === void 0 ? void 0 : data.AdminEndUserProfileListGet.data.map(({ profile }) => {
                return profile;
            });
            if (modifiedData) {
                setClientsData(modifiedData);
            }
        }
    }, [currentPage, data]);
    const debouncedSearch = debounce(async (searchTerm) => {
        setCurrentPage(1);
        setSearchTerm(searchTerm);
        getClients();
    }, 500);
    const onSearch = (searchTerm) => {
        debouncedSearch(searchTerm);
    };
    return {
        clientsData,
        onSearch,
        currentPage,
        setCurrentPage,
        tableTotalData,
        clientsListLoading,
        pageSize,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Card } from "@bundles/UIAppBundle/components";
import ShippingDetails from "@bundles/UIAppBundle/icons/ShippingDetails";
import styles from "./../components/View/EndUserProfilesView.module.scss";
import { EndUserProfileViewer as BaseEndUserProfileViewer } from "./EndUserProfileViewer.base";
import { ShippingAddressFields } from "./consts";
let EndUserProfileViewer = class EndUserProfileViewer extends BaseEndUserProfileViewer {
    build() {
        const { i18n } = this;
        super.build();
        this.remove("billingAddress");
        this.remove("shipmentAddress.firstName");
        this.remove("shipmentAddress.phoneNumber");
        this.remove("shipmentAddress.streetAddressLine1");
        this.remove("shipmentAddress.streetAddressLine2");
        this.remove("shipmentAddress.phoneNumber");
        this.remove("shipmentAddress.country");
        this.remove("shipmentAddress.city");
        this.remove("shipmentAddress.email");
        this.remove("shipmentAddress.town");
        this.remove("shipmentAddress.lastName");
        this.remove("shipmentAddress.zipcode");
        this.remove("isAnonymousCheckout");
        this.remove("updatedAt");
        this.remove("createdAt");
        this.remove("orders");
        this.remove("invoices");
        this.remove("payments");
        this.remove("productFavorites");
        this.remove("latestViewedProducts");
        this.remove("admin");
        this.remove("createdBy");
        this.remove("user");
        this.remove("fullName");
        this.update("firstName", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("firstName").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles.description }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("lastName", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("lastName").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles.description }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("companyName", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("companyName").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles.description }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("vatNumber", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("vatNumber").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles.description }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("email", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("email").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles["email-text"] }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("phoneNumber", {
            render: (value) => {
                return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: this.findElement("phoneNumber").label }), void 0), _jsxs("div", Object.assign({ title: value, className: styles.description }, { children: [": ", " ", value ? value : "N/A"] }), void 0)] }), void 0));
            },
        });
        this.update("savedAddresses", {
            render: (value) => {
                return (_jsx(_Fragment, { children: value &&
                        value.map((value, idx) => {
                            return (_jsx(Card, Object.assign({ title: `SHIPPING ADDRESS ${idx + 1}`, className: styles.card, icon: ShippingDetails }, { children: _jsx("div", Object.assign({ className: styles["card-content"] }, { children: ShippingAddressFields.map((v) => {
                                        if (!value[v])
                                            return null;
                                        return (_jsxs("div", Object.assign({ className: styles["info-row"] }, { children: [_jsx("span", Object.assign({ className: styles["shipping-info"] }, { children: i18n.t(`management.end_user_profiles.fields.${v}`) }), void 0), _jsxs("div", Object.assign({ title: value[v], className: styles.description }, { children: [": ", " ", v === "country"
                                                            ? i18n.t(`management.end_user_profiles.fields.countries.${value[v]}`)
                                                            : value[v]] }), void 0)] }), v));
                                    }) }), void 0) }), value._id));
                        }) }, void 0));
            },
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            admin: 0,
            adminId: 0,
            birthDate: 0,
            userId: 0,
            orders: 0,
            productFavorites: 0,
            latestViewedProducts: 0,
            latestViewedProductsIds: 0,
            invoices: 0,
            createdBy: 0,
            createdById: 0,
            updatedBy: 0,
            updatedById: 0,
            user: { avatar: { file: { path: 1, downloadUrl: 1 } } },
            savedAddresses: {
                _id: 1,
                salesRayId: 1,
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
        };
    }
};
EndUserProfileViewer = __decorate([
    Service({ transient: true })
], EndUserProfileViewer);
export { EndUserProfileViewer };

import { MailingListsList } from "../components/List/MailingListsList";
import { MailingListsCreate } from "../components/Create/MailingListsCreate";
import { MailingListsEdit } from "../components/Edit/MailingListsEdit";
import { MailingListsView } from "../components/View/MailingListsView";
import { SettingFilled } from "@ant-design/icons";
export const MAILING_LISTS_LIST = {
    path: "/admin/mailing-lists",
    component: MailingListsList,
    menu: {
        key: "MAILING_LISTS_LIST",
        label: "management.mailing_lists.menu.title",
        icon: SettingFilled,
    },
};
export const MAILING_LISTS_CREATE = {
    path: "/admin/mailing-lists/create",
    component: MailingListsCreate,
};
export const MAILING_LISTS_EDIT = {
    path: "/admin/mailing-lists/:id/edit",
    component: MailingListsEdit,
};
export const MAILING_LISTS_VIEW = {
    path: "/admin/mailing-lists/:id/view",
    component: MailingListsView,
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ControlledInput } from "@bundles/UIAppBundle/components/ControlledInput";
import styles from "./../components/Create/EndUserProfilesCreate.module.scss";
import { AddCircle, Trashcan } from "@bundles/UIAppBundle/icons";
import { Button } from "@bundles/UIAppBundle/components/Button";
import { EndUserProfileCreateForm as BaseEndUserProfileCreateForm } from "./EndUserProfileCreateForm.base";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let EndUserProfileCreateForm = class EndUserProfileCreateForm extends BaseEndUserProfileCreateForm {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("latestViewedProductsIds");
        this.remove("userId");
        this.remove("adminId");
        this.remove("middleName");
        this.update("firstName", {
            order: 0,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.end_user_profiles.fields.firstName")), placeholder: t("management.end_user_profiles.fields.firstName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("lastName", {
            order: 3,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.end_user_profiles.fields.lastName")), placeholder: t("management.end_user_profiles.fields.lastName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("email", {
            order: 3,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.end_user_profiles.fields.email")), placeholder: t("management.end_user_profiles.fields.email"), type: "email" }, void 0) }), void 0) }), void 0)),
        });
        this.update("phoneNumber", {
            order: 3,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.phoneNumber"), placeholder: t("management.end_user_profiles.fields.phoneNumber"), type: "tel" }, void 0) }), void 0) }), void 0)),
        });
        this.update("birthDate", {
            order: 3,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { type: "date", containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.birthDate"), placeholder: t("management.end_user_profiles.fields.birthDate") }, void 0) }), void 0) }), void 0)),
        });
        this.update("billingAddress", {
            nest: [
                {
                    id: "firstName",
                    label: t("management.end_user_profiles.fields.billingAddress.firstName"),
                    name: ["billingAddress", "firstName"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.firstName"), placeholder: t("management.end_user_profiles.fields.firstName") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "lastName",
                    label: t("management.end_user_profiles.fields.billingAddress.lastName"),
                    name: ["billingAddress", "lastName"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.lastName"), placeholder: t("management.end_user_profiles.fields.lastName") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "email",
                    label: t("management.end_user_profiles.fields.billingAddress.email"),
                    name: ["billingAddress", "email"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.email"), placeholder: t("management.end_user_profiles.fields.billingAddress.email") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "phoneNumber",
                    label: t("management.end_user_profiles.fields.billingAddress.phoneNumber"),
                    name: ["billingAddress", "phoneNumber"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.phoneNumber"), placeholder: t("management.end_user_profiles.fields.billingAddress.phoneNumber") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "streetAddressLine1",
                    label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1"),
                    name: ["billingAddress", "streetAddressLine1"],
                    required: true,
                    //   component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1"), placeholder: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "streetAddressLine2",
                    label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2"),
                    name: ["billingAddress", "streetAddressLine2"],
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2"), placeholder: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "country",
                    label: t("management.end_user_profiles.fields.billingAddress.country"),
                    name: ["billingAddress", "country"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.country"), placeholder: t("management.end_user_profiles.fields.billingAddress.country") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "city",
                    label: t("management.end_user_profiles.fields.billingAddress.city"),
                    name: ["billingAddress", "city"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.city"), placeholder: t("management.end_user_profiles.fields.billingAddress.city") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "town",
                    label: t("management.end_user_profiles.fields.billingAddress.town"),
                    name: ["billingAddress", "town"],
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.town"), placeholder: t("management.end_user_profiles.fields.billingAddress.town") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "zipcode",
                    label: t("management.end_user_profiles.fields.billingAddress.zipcode"),
                    name: ["billingAddress", "zipcode"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.billingAddress.zipcode"), placeholder: t("management.end_user_profiles.fields.billingAddress.zipcode") }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        this.remove("shipmentAddress");
        this.update("isAnonymousCheckout", {
            order: 6,
        });
        this.add({
            id: "savedAddresses",
            order: 7,
            columns: [24, 12, 12, 12, 12, 12, 12],
            isList: true,
            listRenderer: () => 
            // eslint-disable-next-line react/display-name
            (fields, { add, remove }) => {
                const onAdd = (e) => {
                    e.preventDefault();
                    add();
                };
                const onRemove = (name) => (e) => {
                    e.preventDefault();
                    remove(name);
                };
                return (_jsxs(Ant.Col, Object.assign({ span: 24 }, { children: [fields.map((field, index) => {
                            return (_jsxs("div", Object.assign({ className: styles["shipment-wrapper"] }, { children: [this.renderFormItem({
                                        ...this.findElement("savedAddresses"),
                                        // Manipulate nesting to contain proper fieldKey and name
                                        nest: this.findElement("savedAddresses").nest.map((subitem) => {
                                            return {
                                                ...subitem,
                                                fieldKey: [field.fieldKey, subitem.id],
                                                name: [field.name, subitem.id],
                                            };
                                        }),
                                    }), _jsx(Button, Object.assign({ width: "fit-content", className: styles["remove-btn"], onClick: onRemove(field.name) }, { children: _jsxs(_Fragment, { children: [_jsx(Trashcan, {}, void 0), "Remove Shipping Address"] }, void 0) }), void 0)] }), index));
                        }), _jsx(Button, Object.assign({ width: "fit-content", className: styles["add-btn"], onClick: onAdd }, { children: _jsxs(_Fragment, { children: [_jsx(AddCircle, {}, void 0), "Add New Shipping Address"] }, void 0) }), void 0)] }), void 0));
            },
            name: ["savedAddresses"],
            nest: [
                {
                    id: "name",
                    label: t("management.end_user_profiles.fields.savedAddresses.name"),
                    name: ["savedAddresses", "name"],
                    required: true,
                    initialValue: [],
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.savedAddresses.name"), placeholder: t("management.end_user_profiles.fields.savedAddresses.name") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "streetAddressLine1",
                    label: t("management.shipping_address.fields.streetAddressLine1"),
                    name: ["savedAddresses", "address", "streetAddressLine1"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.end_user_profiles.fields.savedAddresses.address.streetAddressLine1"), placeholder: t("management.end_user_profiles.fields.savedAddresses.address.streetAddressLine1") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "streetAddressLine2",
                    label: t("management.shipping_address.fields.streetAddressLine2"),
                    name: ["savedAddresses", "address", "streetAddressLine2"],
                    required: false,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.streetAddressLine2"), placeholder: t("management.shipping_address.fields.streetAddressLine2") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "city",
                    label: t("management.shipping_address.fields.city"),
                    name: ["savedAddresses", "address", "city"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.city"), placeholder: t("management.shipping_address.fields.city") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "country",
                    label: t("management.shipping_address.fields.country"),
                    name: ["savedAddresses", "address", "country"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.country"), placeholder: t("management.shipping_address.fields.country") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "town",
                    label: t("management.shipping_address.fields.town"),
                    name: ["savedAddresses", "address", "town"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.town"), placeholder: t("management.shipping_address.fields.town") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "zipcode",
                    label: t("management.shipping_address.fields.zipcode"),
                    name: ["savedAddresses", "address", "zipcode"],
                    required: true,
                    component: Ant.Input,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shipping_address.fields.zipcode"), placeholder: t("management.shipping_address.fields.zipcode") }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    onCreateEndUserProfile(document, EndUserProfileCreateMutation) {
        return EndUserProfileCreateMutation({
            variables: {
                document: {
                    ...document,
                    billingAddress: document.billingAddress,
                },
            },
        })
            .then(({ data }) => {
            return data.AdminEndUserProfileCreate;
        })
            .catch((error) => {
            Ant.notification.error({
                message: "Error: Failed creating Client",
                description: error.message,
            });
            throw error;
        });
    }
};
EndUserProfileCreateForm = __decorate([
    Service({ transient: true })
], EndUserProfileCreateForm);
export { EndUserProfileCreateForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ProductVariantViewer = class ProductVariantViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.product_variants.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                label: t("management.product_variants.fields.salesRayId"),
                dataIndex: ["salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "name",
                label: t("management.product_variants.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "description",
                label: t("management.product_variants.fields.description"),
                dataIndex: ["description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "params",
                label: t("management.product_variants.fields.params"),
                dataIndex: ["params"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "sku",
                label: t("management.product_variants.fields.sku"),
                dataIndex: ["sku"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.pageTitle",
                label: t("management.product_variants.fields.seoFields.pageTitle"),
                dataIndex: ["seoFields", "pageTitle"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.metaDescription",
                label: t("management.product_variants.fields.seoFields.metaDescription"),
                dataIndex: ["seoFields", "metaDescription"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.keywords",
                label: t("management.product_variants.fields.seoFields.keywords"),
                dataIndex: ["seoFields", "keywords"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "seoFields.seoTitle",
                label: t("management.product_variants.fields.seoFields.seoTitle"),
                dataIndex: ["seoFields", "seoTitle"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.seoDescription",
                label: t("management.product_variants.fields.seoFields.seoDescription"),
                dataIndex: ["seoFields", "seoDescription"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.slug",
                label: t("management.product_variants.fields.seoFields.slug"),
                dataIndex: ["seoFields", "slug"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "availability",
                label: t("management.product_variants.fields.availability"),
                dataIndex: ["availability"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isPublished",
                label: t("management.product_variants.fields.isPublished"),
                dataIndex: ["isPublished"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "publishedAt",
                label: t("management.product_variants.fields.publishedAt"),
                dataIndex: ["publishedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "netPrice",
                label: t("management.product_variants.fields.netPrice"),
                dataIndex: ["netPrice"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isPriceCustomized",
                label: t("management.product_variants.fields.isPriceCustomized"),
                dataIndex: ["isPriceCustomized"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "discountPercentage",
                label: t("management.product_variants.fields.discountPercentage"),
                dataIndex: ["discountPercentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "discountActiveUntil",
                label: t("management.product_variants.fields.discountActiveUntil"),
                dataIndex: ["discountActiveUntil"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "totalDiscountPercentage",
                label: t("management.product_variants.fields.totalDiscountPercentage"),
                dataIndex: ["totalDiscountPercentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "taxAmount",
                label: t("management.product_variants.fields.taxAmount"),
                dataIndex: ["taxAmount"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "tags",
                label: t("management.product_variants.fields.tags"),
                dataIndex: ["tags"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "attributesValues",
                label: t("management.product_variants.fields.attributesValues"),
                dataIndex: ["attributesValues"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "daysBeforeAllowingReview",
                label: t("management.product_variants.fields.daysBeforeAllowingReview"),
                dataIndex: ["daysBeforeAllowingReview"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "brand",
                label: t("management.product_variants.fields.brand"),
                dataIndex: ["brand"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "type",
                label: t("management.product_variants.fields.type"),
                dataIndex: ["type"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "rating",
                label: t("management.product_variants.fields.rating"),
                dataIndex: ["rating"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "country",
                label: t("management.product_variants.fields.country"),
                dataIndex: ["country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayImageUrl",
                label: t("management.product_variants.fields.salesRayImageUrl"),
                dataIndex: ["salesRayImageUrl"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "height",
                label: t("management.product_variants.fields.height"),
                dataIndex: ["height"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "width",
                label: t("management.product_variants.fields.width"),
                dataIndex: ["width"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "length_",
                label: t("management.product_variants.fields.length_"),
                dataIndex: ["length_"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "netWeight",
                label: t("management.product_variants.fields.netWeight"),
                dataIndex: ["netWeight"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "grossWeight",
                label: t("management.product_variants.fields.grossWeight"),
                dataIndex: ["grossWeight"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "coefficient",
                label: t("management.product_variants.fields.coefficient"),
                dataIndex: ["coefficient"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "model3D",
                label: t("management.product_variants.fields.model3D"),
                dataIndex: ["model3D"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "defaultImage",
                label: t("management.product_variants.fields.defaultImage"),
                dataIndex: ["defaultImage"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "images",
                label: t("management.product_variants.fields.images"),
                dataIndex: ["images"],
                render: (value) => {
                    const props = {
                        type: "fileGroup",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "product",
                label: t("management.product_variants.fields.product"),
                dataIndex: ["product"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "category",
                label: t("management.product_variants.fields.category"),
                dataIndex: ["category"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "publishedBy",
                label: t("management.product_variants.fields.publishedBy"),
                dataIndex: ["publishedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INTERNAL_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "reviews",
                label: t("management.product_variants.fields.reviews"),
                dataIndex: ["reviews"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.REVIEWS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "stars",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatRate",
                label: t("management.product_variants.fields.vatRate"),
                dataIndex: ["vatRate"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.VAT_RATES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "models",
                label: t("management.product_variants.fields.models"),
                dataIndex: ["models"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_3_D_MODEL_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "unitOfMeasurement",
                label: t("management.product_variants.fields.unitOfMeasurement"),
                dataIndex: ["unitOfMeasurement"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.UNITS_OF_MEASUREMENT_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            salesRayId: 1,
            name: 1,
            description: 1,
            params: 1,
            sku: 1,
            seoFields: {
                pageTitle: 1,
                metaDescription: 1,
                keywords: 1,
                seoTitle: 1,
                seoDescription: 1,
                slug: 1,
            },
            availability: 1,
            isPublished: 1,
            publishedAt: 1,
            netPrice: 1,
            isPriceCustomized: 1,
            discountPercentage: 1,
            discountActiveUntil: 1,
            totalDiscountPercentage: 1,
            taxAmount: 1,
            tags: 1,
            attributesValues: {
                key: 1,
                value: 1,
                suffix: 1,
                isNumber: 1,
            },
            daysBeforeAllowingReview: 1,
            brand: 1,
            type: 1,
            rating: 1,
            country: 1,
            salesRayImageUrl: 1,
            height: 1,
            width: 1,
            length_: 1,
            netWeight: 1,
            grossWeight: 1,
            coefficient: 1,
            model3D: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            model3DId: 1,
            defaultImage: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            defaultImageId: 1,
            images: {
                _id: 1,
                name: 1,
                files: {
                    downloadUrl: 1,
                    name: 1,
                },
            },
            imagesId: 1,
            product: {
                _id: 1,
                name: 1,
            },
            productId: 1,
            category: {
                _id: 1,
                name: 1,
            },
            categoryId: 1,
            publishedBy: {
                _id: 1,
                fullName: 1,
            },
            publishedById: 1,
            reviews: {
                _id: 1,
                stars: 1,
            },
            vatRate: {
                _id: 1,
                name: 1,
            },
            vatRateId: 1,
            models: {
                _id: 1,
                name: 1,
            },
            unitOfMeasurement: {
                _id: 1,
            },
            unitOfMeasurementId: 1,
        };
    }
};
ProductVariantViewer = __decorate([
    Service({ transient: true })
], ProductVariantViewer);
export { ProductVariantViewer };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Col } from "antd";
import { Card } from "@bundles/UIAppBundle/components";
import styles from "./bestproductscard.module.scss";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import BestProductsCardLoader from "@bundles/UIAppBundle/pages/Dashboard/BestProductsCard/BestProductsCardLoader";
export const BestProductsCard = ({ className, data, loading, }) => {
    const shopSettings = useShopSettings();
    return (_jsx(Card, Object.assign({ className: `${styles.card} ${className !== null && className !== void 0 ? className : ""}` }, { children: loading ? (_jsx(BestProductsCardLoader, {}, void 0)) : (_jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Best Products" }), void 0), data.map((product, index) => {
                    var _a;
                    let attributeString = "";
                    product.attributesValues.forEach((attribute, index) => {
                        var _a, _b, _c, _d;
                        if (attribute.value && attribute.value !== "-") {
                            attributeString +=
                                ((_a = attribute.key) === null || _a === void 0 ? void 0 : _a.slice(0, 1).toUpperCase()) +
                                    ((_b = attribute.key) === null || _b === void 0 ? void 0 : _b.slice(1).toLowerCase()) +
                                    " " +
                                    ((_c = attribute.value) === null || _c === void 0 ? void 0 : _c.slice(0, 1).toUpperCase()) +
                                    ((_d = attribute.value) === null || _d === void 0 ? void 0 : _d.slice(1).toLowerCase()) +
                                    (index < product.attributesValues.length - 1 ? ", " : " ");
                        }
                    });
                    return (_jsxs("div", Object.assign({ className: styles.productrow }, { children: [_jsx("div", Object.assign({ className: styles["avatar-container"] }, { children: _jsx("img", { className: styles.img, src: generateImageLink((_a = product === null || product === void 0 ? void 0 : product.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl), alt: `product-no ${index}` }, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["nameattr"] }, { children: [_jsx("p", Object.assign({ className: styles["prdname"] }, { children: product.name }), void 0), _jsx("p", Object.assign({ className: styles["atrrow"] }, { children: attributeString.length > 150
                                            ? `${attributeString.slice(0, 150)}...`
                                            : attributeString }), void 0)] }), void 0), _jsx("p", Object.assign({ className: styles["price"] }, { children: shopSettings.formatPriceWithCurrency(product.netPrice) }), void 0)] }), index));
                })] }), void 0)) }), void 0));
};

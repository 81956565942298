import { gql } from "@apollo/client";
export const END_USER_VIEW = gql `
  query AdminEndUserProfileGet($input: AdminEndUserProfileGetInput!) {
    AdminEndUserProfileGet(input: $input) {
      _id
      createdAt
      email
      firstName
      fullName
      companyName
      vatNumber
      invoices {
        _id
        invoiceId
      }
      isAnonymousCheckout
      lastName
      middleName
      orderCount
      orderTotal
      payments {
        _id
        paymentProcessor
      }
      phoneNumber
      user {
        avatar {
          file {
            downloadUrl
          }
        }
      }
      savedAddresses {
        _id
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Col } from "antd";
import { Card } from "@bundles/UIAppBundle/components";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import LastOrdersCardLoader from "@bundles/UIAppBundle/pages/Dashboard/LastOrdersCard/LastOrdersCardLoader";
import styles from "./lastorderscard.module.scss";
export const LastOrdersCard = ({ className, data, loading, }) => {
    const settings = useShopSettings();
    return (_jsx(Card, Object.assign({ className: `${styles.card} ${className}` }, { children: loading ? (_jsx(LastOrdersCardLoader, {}, void 0)) : (_jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Last Orders" }), void 0), _jsxs("div", Object.assign({ className: styles.indexrow }, { children: [_jsx("p", Object.assign({ className: styles.indexname }, { children: "Customer Name" }), void 0), _jsx("p", Object.assign({ className: styles.indexno }, { children: "Order No." }), void 0), _jsx("p", Object.assign({ className: styles.indexamount }, { children: "Amount" }), void 0), _jsx("p", Object.assign({ className: styles.indexpayment }, { children: "Payment Method" }), void 0), _jsx("p", Object.assign({ className: styles.indexdate }, { children: "Date" }), void 0)] }), void 0), data.map((order, index) => {
                    var _a, _b, _c, _d;
                    const paymentDate = new Date(order.placedAt);
                    return (_jsxs("div", Object.assign({ className: styles.custrow }, { children: [_jsx("div", Object.assign({ className: styles["avatar-container"] }, { children: _jsx("img", { className: styles.img, src: generateProfileImageLink(order.buyer.firstName + " " + order.buyer.lastName, (_c = (_b = (_a = order.buyer.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl), alt: `order-no ${index}` }, void 0) }), void 0), _jsx("p", Object.assign({ className: styles["custname"] }, { children: order.buyer.firstName + " " + order.buyer.lastName }), void 0), _jsxs("p", Object.assign({ className: styles["custno"] }, { children: ["#", order.orderNumber] }), void 0), _jsx("p", Object.assign({ className: styles["custprice"] }, { children: settings.formatPriceWithCurrency(order.finalPrice) }), void 0), _jsx("p", Object.assign({ className: styles["custpayment"] }, { children: ((_d = order.paymentOption) === null || _d === void 0 ? void 0 : _d.name) || "N/A" }), void 0), _jsx("p", Object.assign({ className: styles["custdate"], title: paymentDate.toLocaleTimeString() }, { children: paymentDate.toLocaleString(undefined, {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                }) }), void 0)] }), index));
                })] }), void 0)) }), void 0));
};

import { ProductVariantsCollection as Base } from "./ProductVariants.collection";
import { AdminProductVariantListGet } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/List/queries";
import { getVariant } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/queries";
export class CustomProductVariantsCollection extends Base {
    async find(query) {
        var _a, _b;
        return this.apolloClient
            .query({
            query: AdminProductVariantListGet,
            variables: {
                input: {
                    filters: (_a = query.filters) !== null && _a !== void 0 ? _a : {},
                    options: (_b = query.options) !== null && _b !== void 0 ? _b : {},
                },
            },
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => {
            return result.data.AdminProductVariantListGet;
        })
            .catch((error) => {
            throw error;
        });
    }
    async findOne(query) {
        return this.apolloClient
            .query({
            query: getVariant,
            variables: { input: query },
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => result.data.AdminProductVariantGetOne)
            .catch((error) => {
            throw error;
        });
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { EndUserProfilesCollection, ShippingAddressCollection, } from "@bundles/UIAppBundle/collections";
let ShippingAddressCreateForm = class ShippingAddressCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "nbh",
                label: t("management.shipping_address.fields.nbh"),
                name: ["nbh"],
                component: Ant.Input,
            },
            {
                id: "building_floor",
                label: t("management.shipping_address.fields.building_floor"),
                name: ["building_floor"],
                component: Ant.Input,
            },
            {
                id: "zip",
                label: t("management.shipping_address.fields.zip"),
                name: ["zip"],
                component: Ant.Input,
            },
            {
                id: "address",
                label: t("management.shipping_address.fields.address"),
                name: ["address"],
                component: Ant.Input,
            },
            {
                id: "building_entrance",
                label: t("management.shipping_address.fields.building_entrance"),
                name: ["building_entrance"],
                component: Ant.Input,
            },
            {
                id: "street",
                label: t("management.shipping_address.fields.street"),
                name: ["street"],
                component: Ant.Input,
            },
            {
                id: "district",
                label: t("management.shipping_address.fields.district"),
                name: ["district"],
                component: Ant.Input,
            },
            {
                id: "tax",
                label: t("management.shipping_address.fields.tax"),
                name: ["tax"],
                component: Ant.Input,
            },
            {
                id: "country",
                label: t("management.shipping_address.fields.country"),
                name: ["country"],
                component: Ant.Input,
            },
            {
                id: "note",
                label: t("management.shipping_address.fields.note"),
                name: ["note"],
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.shipping_address.fields.email"),
                name: ["email"],
                component: Ant.Input,
            },
            {
                id: "num",
                label: t("management.shipping_address.fields.num"),
                name: ["num"],
                component: Ant.Input,
            },
            {
                id: "salesRayId",
                label: t("management.shipping_address.fields.salesRayId"),
                name: ["salesRayId"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "zip_num",
                label: t("management.shipping_address.fields.zip_num"),
                name: ["zip_num"],
                component: Ant.Input,
            },
            {
                id: "fax",
                label: t("management.shipping_address.fields.fax"),
                name: ["fax"],
                component: Ant.Input,
            },
            {
                id: "building",
                label: t("management.shipping_address.fields.building"),
                name: ["building"],
                component: Ant.Input,
            },
            {
                id: "settlement",
                label: t("management.shipping_address.fields.settlement"),
                name: ["settlement"],
                component: Ant.Input,
            },
            {
                id: "phone",
                label: t("management.shipping_address.fields.phone"),
                name: ["phone"],
                component: Ant.Input,
            },
            {
                id: "building_apartment",
                label: t("management.shipping_address.fields.building_apartment"),
                name: ["building_apartment"],
                component: Ant.Input,
            },
            {
                id: "endUserProfileId",
                label: t("management.shipping_address.fields.endUserProfile"),
                name: ["endUserProfileId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_address.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.SHIPPING_ADDRESS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.SHIPPING_ADDRESS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.SHIPPING_ADDRESS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ShippingAddressCollection),
    __metadata("design:type", typeof (_a = typeof ShippingAddressCollection !== "undefined" && ShippingAddressCollection) === "function" ? _a : Object)
], ShippingAddressCreateForm.prototype, "collection", void 0);
ShippingAddressCreateForm = __decorate([
    Service({ transient: true })
], ShippingAddressCreateForm);
export { ShippingAddressCreateForm };

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { BigCardLayout, Button } from "@bundles/UIAppBundle/components";
import { File } from "@bundles/UIAppBundle/icons";
import styles from "./ExportProducts.module.scss";
export const Download = ({ exported, date, fileName, onClickStartNewExport, onClickDownload, }) => {
    const t = useTranslate();
    return (_jsx(BigCardLayout, Object.assign({ title: t("management.export.title"), subtitle: t("management.export.headers.download") }, { children: _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles["label"] }, { children: t("management.export.descriptions.download.message") }), void 0), _jsx("div", Object.assign({ className: styles["exported-statistics"] }, { children: _jsxs("div", { children: [t("management.export.descriptions.download.statistics", {
                                exported,
                            }), _jsx("i", Object.assign({ title: date.toLocaleTimeString() }, { children: date.toLocaleString(undefined, {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                }) }), void 0)] }, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["download-box"] }, { children: [_jsx("div", Object.assign({ className: styles["download-box__label"], onClick: onClickDownload }, { children: t("management.export.descriptions.download.downloadLabel") }), void 0), _jsxs("div", Object.assign({ className: styles["download-box__file"], onClick: onClickDownload }, { children: [_jsx(File, { className: styles["download-box__file__icon"] }, void 0), _jsx("div", Object.assign({ className: styles["download-box__file__name"] }, { children: fileName }), void 0)] }), void 0)] }), void 0), _jsx(Button, Object.assign({ width: "fit-content", btnStyle: "primary-outlined", className: styles["submit"], type: "button", onClick: onClickStartNewExport }, { children: t("management.export.buttons.startNewExport") }), void 0)] }, void 0) }), void 0));
};

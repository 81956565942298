import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Button } from "@bundles/UIAppBundle/components/Button";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { useEndUserProfileCreate } from "./hooks";
import styles from "./EndUserProfilesCreate.module.scss";
import { FieldGroupWrapper } from "@bundles/UIAppBundle/components";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { clientsAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { scrollToFirstFormFieldError } from "@bundles/UIAppBundle/utils/helpers";
export function EndUserProfilesCreate() {
    const { createEndUserProfile, updateIsLoading, form, t, UIComponents, AvatarUpload, } = useEndUserProfileCreate();
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: clientsAccessRoles }, { children: [_jsx(Seo, { data: seoData.endUserProfile.create }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsx(Ant.PageHeader, { title: t("management.end_user_profiles.create.header"), onBack: () => window.history.back(), backIcon: _jsx(BackCircle, {}, void 0), extra: _jsx(Button, Object.assign({ btnStyle: "primary", form: "EndUserProfileCreateForm", loading: updateIsLoading }, { children: "Save changes" }), void 0), className: styles["page-header"] }, void 0), _jsx("div", Object.assign({ className: styles.container }, { children: _jsx(Ant.Form, Object.assign({ name: "EndUserProfileCreateForm", labelCol: { span: 0 }, requiredMark: "optional", onFinish: createEndUserProfile, scrollToFirstError: scrollToFirstFormFieldError }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(Card, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "PROFILE" }, { children: [_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Row, Object.assign({ justify: "start" }, { children: _jsx(AvatarUpload, {}, void 0) }), void 0) }), void 0), form.render([
                                                        form.findElement("firstName"),
                                                        form.findElement("lastName"),
                                                    ]), form.render([
                                                        form.findElement("email"),
                                                        form.findElement("phoneNumber"),
                                                    ])] }), void 0) }, void 0), _jsx(Card, { children: _jsx(FieldGroupWrapper, Object.assign({ title: "BILLING DETAILS" }, { children: form.render([form.findElement("billingAddress")]) }), void 0) }, void 0), _jsx(Card, { children: _jsx(FieldGroupWrapper, Object.assign({ title: "SHIPPING" }, { children: form.render([form.findElement("savedAddresses")]) }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0)] }), void 0));
}

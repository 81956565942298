import { gql } from "@apollo/client";
export const AddProductMutation = gql `
  mutation AdminProductCreate($input: AdminProductCreateInput!) {
    AdminProductCreate(input: $input) {
      _id
    }
  }
`;
export const UpdateProductMutation = gql `
  mutation ($input: AdminProductUpdateInput!) {
    AdminProductUpdate(input: $input) {
      _id
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { Button, Card, ControlledInput } from "../..";
import { useProfileDetailsForm } from "../hooks";
import { Label, Input } from "../consts";
import styles from "./ResetPasswordForm.module.scss";
const ResetPasswordForm = () => {
    const { fieldResetPasswordError, onChangeResetPasswordValue, onResetPasswordSubmit, resetPasswordInput, } = useProfileDetailsForm();
    return (_jsx("div", Object.assign({ className: styles.details }, { children: _jsxs(Card, Object.assign({ title: "RESET PASSWORD", className: styles.container }, { children: [_jsx(ControlledInput, { onChange: (v) => onChangeResetPasswordValue(Input.OLD_PASS, v.target.value), type: "password", label: Label[Input.OLD_PASS], error: fieldResetPasswordError(Input.OLD_PASS), value: (resetPasswordInput === null || resetPasswordInput === void 0 ? void 0 : resetPasswordInput.oldPassword) || "", autoComplete: "new-password" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeResetPasswordValue(Input.NEW_PASS, v.target.value), type: "password", label: Label[Input.NEW_PASS], error: fieldResetPasswordError(Input.NEW_PASS), value: (resetPasswordInput === null || resetPasswordInput === void 0 ? void 0 : resetPasswordInput.newPassword) || "", autoComplete: "new-password" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeResetPasswordValue(Input.CONFIRM_PASS, v.target.value), label: Label[Input.CONFIRM_PASS], type: "password", error: fieldResetPasswordError(Input.CONFIRM_PASS), value: (resetPasswordInput === null || resetPasswordInput === void 0 ? void 0 : resetPasswordInput.confirmPassword) || "", autoComplete: "new-password" }, void 0), _jsx(Button, Object.assign({ className: styles["btn-container"], width: "fit-content", onClick: () => {
                        onResetPasswordSubmit();
                    }, icon: Checkmark }, { children: "Save Password" }), void 0)] }), void 0) }), void 0));
};
export default ResetPasswordForm;

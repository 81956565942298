import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { use } from "@bluelibs/x-ui";
import { Product3DModelListFiltersForm } from "../../config/Product3DModelListFiltersForm";
import { CustomTableFilters } from "@bundles/UIAppBundle/components/CustomTableFilters";
export const Product3DModelListFilters = React.memo(Product3DModelListFiltersBase);
export function Product3DModelListFiltersBase(props) {
    const filterForm = use(Product3DModelListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsx(CustomTableFilters, { onUpdate: props.onUpdate, formInstance: filterForm }, void 0));
}

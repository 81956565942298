import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { useUIComponents, useTranslate } from "@bluelibs/x-ui";
import { CustomPageHeader } from "@bundles/UIAppBundle/components/CustomPageHeader";
import { Table } from "@bundles/UIAppBundle/components/Table";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { useClientsList } from "./hooks";
import { EndUserProfilesListFilters } from "./EndUserProfilesListFilters";
import { clientsAccessRoles } from "../../consts";
import styles from "./EndUserProfilesList.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
//just for the moment, as we don't know what filter will be here
const filterOptions = [
    { label: "All", value: null },
    { label: "Active", value: false },
    { label: "Suspended", value: true },
];
export function EndUserProfilesList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { api, Provider, isLoadingSync, filtersOpened, onFiltersUpdate, setFiltersOpened, onClickSync, } = useClientsList();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: clientsAccessRoles }, { children: [_jsx(Seo, { data: seoData.endUserProfile.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.end_user_profiles.list.header"), searchKey: "name", searchPlaceholder: t("management.end_user_profiles.list.search"), syncLabel: t("management.end_user_profiles.list.sync"), isLoadingSync: isLoadingSync, onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened, onClickSync: onClickSync }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsxs(Provider, { children: [filtersOpened && (_jsx(EndUserProfilesListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Table, Object.assign({ className: styles.table }, api.getTableProps()), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0));
}

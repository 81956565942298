import "./i18n";
import { VAT_RATES_LIST as BASE_VAT_RATES_LIST, VAT_RATES_CREATE as BASE_VAT_RATES_CREATE, VAT_RATES_EDIT as BASE_VAT_RATES_EDIT, VAT_RATES_VIEW as BASE_VAT_RATES_VIEW, } from "./config/routes";
export const VAT_RATES_LIST = {
    ...BASE_VAT_RATES_LIST,
    path: "/shop-settings/vat-rates",
};
export const VAT_RATES_CREATE = {
    ...BASE_VAT_RATES_CREATE,
    path: "/shop-settings/vat-rates/create",
};
export const VAT_RATES_EDIT = {
    ...BASE_VAT_RATES_EDIT,
    path: "/shop-settings/vat-rates/:id/edit",
};
export const VAT_RATES_VIEW = {
    ...BASE_VAT_RATES_VIEW,
};
// export const SHOP_SETTINGS_VAT_DETAILS_VIEW: IRoute = {
//   path: "/shop-settings/vat-rates",
//   component: VatRatesList,
// };
// export const SHOP_SETTINGS_VAT_DETAILS_CREATE: IRoute = {
//   path: "/shop-settings/vat-rates/create",
//   component: VatRatesList,
// };

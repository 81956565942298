import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { use } from "@bluelibs/x-ui";
import { UnitsOfMeasurementListFiltersForm } from "../../config/UnitsOfMeasurementListFiltersForm";
import { CustomTableFilters } from "@bundles/UIAppBundle/components/CustomTableFilters";
export const UnitsOfMeasurementListFilters = React.memo(UnitsOfMeasurementListFiltersBase);
export function UnitsOfMeasurementListFiltersBase(props) {
    const filterForm = use(UnitsOfMeasurementListFiltersForm, {
        transient: true,
    });
    filterForm.build();
    return (_jsx(CustomTableFilters, { onUpdate: props.onUpdate, formInstance: filterForm }, void 0));
}

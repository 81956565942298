import { gql } from "@apollo/client";
export const INTERNAL_USER_PROFILE_GET = gql `
  query AdminMe {
    AdminMe {
      internalUserProfile {
        activeNotifications {
          clientCreated
          invoiceCreated
          orderStatusChanged
          orderPlaced
          productOutOfStock
          productPromotionExpire
          returnCreated
          returnStatusChanged
        }
      }
    }
  }
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ShopSettingEditForm as BaseShopSettingEditForm } from "../../../pages/ShopSettingsManagement/config/ShopSettingEditForm.base";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
import styles from "./InvoicingDetailsForm.module.scss";
import { currencyOptions } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
let InvoicingDetailsConstructor = class InvoicingDetailsConstructor extends BaseShopSettingEditForm {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("attributeOptions");
        this.remove("vatRatesIds");
        this.remove("analyticsURLs");
        this.remove("paymentOptionsIds");
        this.remove("shopName");
        this.remove("companyName");
        this.remove("country");
        this.remove("companyIdentificationNumber");
        this.remove("shopAdministratorEmail");
        this.remove("billingAddress");
        this.remove("shippingAddress");
        this.remove("reviewsEnabled");
        this.remove("faviconId");
        this.remove("logoId");
        this.update("defaultCurrency", {
            order: 1,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "sdf" }, { children: _jsx(ControlledSelect, { label: t("management.shop_settings.fields.defaultCurrency"), options: currencyOptions, placeholder: "$" }, void 0) }), void 0) }), void 0)),
        });
        this.update("invoiceGenerationPrefix", {
            order: 2,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.invoiceGenerationPrefix"), placeholder: t("management.shop_settings.placeholders.invoiceGenerationPrefix") }, void 0) }), void 0) }), void 0)),
        });
        this.update("firstInvoiceNumber", {
            order: 3,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { type: "number", containerClassName: styles["input-container"], label: t("management.shop_settings.fields.firstInvoiceNumber"), placeholder: t("management.shop_settings.placeholders.typeNumber") }, void 0) }), void 0) }), void 0)),
        });
        this.update("freeShippingAfterAmountLimit", {
            order: 4,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { type: "number", containerClassName: styles["input-container"], label: t("management.shop_settings.fields.freeShippingAfterAmountLimit"), placeholder: t("management.shop_settings.placeholders.typeNumber"), description: t("management.shop_settings.descriptions.freeShippingAfterAmountLimit") }, void 0) }), void 0) }), void 0)),
        });
        // Perthis additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
InvoicingDetailsConstructor = __decorate([
    Service({ transient: true })
], InvoicingDetailsConstructor);
export { InvoicingDetailsConstructor };

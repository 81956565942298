var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { useTranslate } from "@bluelibs/x-ui";
import { CustomTag } from "@bundles/UIAppBundle/components";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { UnitsOfMeasurementViewer as BaseUnitsOfMeasurementViewer } from "./UnitsOfMeasurementViewer.base";
let UnitsOfMeasurementViewer = class UnitsOfMeasurementViewer extends BaseUnitsOfMeasurementViewer {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("_id");
        this.update("salesRayId", {
            render: (value) => {
                const props = {
                    type: "number",
                    value,
                };
                return (_jsxs(_Fragment, { children: [_jsx("div", { children: this.findElement("salesRayId").label }, void 0), " ", _jsxs("div", { children: [": ", value, " "] }, void 0)] }, void 0));
            },
        });
        this.update("name", {
            render: (value) => {
                const props = {
                    type: "number",
                    value,
                };
                return (_jsxs(_Fragment, { children: [_jsx("div", { children: this.findElement("name").label }, void 0), " ", _jsxs("div", { children: [": ", value, " "] }, void 0)] }, void 0));
            },
        });
        this.update("label", {
            render: (value) => {
                const props = {
                    type: "number",
                    value,
                };
                return (_jsxs(_Fragment, { children: [_jsx("div", { children: this.findElement("label").label }, void 0), " ", _jsxs("div", { children: [": ", value, " "] }, void 0)] }, void 0));
            },
        });
        this.update("code", {
            render: (value) => {
                const props = {
                    type: "number",
                    value,
                };
                return (_jsxs(_Fragment, { children: [_jsx("div", { children: this.findElement("code").label }, void 0), " ", _jsxs("div", { children: [": ", value, " "] }, void 0)] }, void 0));
            },
        });
        this.update("hasWeight", {
            render: (value) => {
                const props = {
                    type: "number",
                    value,
                };
                return (_jsxs(_Fragment, { children: [_jsx("div", { children: this.findElement("hasWeight").label }, void 0), " ", _jsxs("div", { children: [":", " ", _jsx(CustomTag, { value: value.toString(), color: value ? TagColorsEnum.GREEN : TagColorsEnum.RED }, void 0), " "] }, void 0)] }, void 0));
            },
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
UnitsOfMeasurementViewer = __decorate([
    Service({ transient: true })
], UnitsOfMeasurementViewer);
export { UnitsOfMeasurementViewer };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Clipboard } from "@bundles/UIAppBundle/icons";
import { Table } from "@bundles/UIAppBundle/components/Table";
import { Searchbar } from "@bundles/UIAppBundle/components/Searchbar";
import { CustomTag } from "@bundles/UIAppBundle/components";
import { ordersTagColors, paymentMethodTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { orderStatusLabel, paymentOptionLabel, } from "@bundles/UIAppBundle/consts/labels";
import { useAddOrder } from "./hooks";
import styles from "./AddOrder.module.scss";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
const AddOrder = ({ stepForm }) => {
    const { onSearch, filteredData } = useAddOrder();
    const settings = useShopSettings();
    const columns = [
        {
            title: "Order Number",
            dataIndex: "orderNumber",
            render: (name, model) => {
                return (_jsxs("div", Object.assign({ className: styles["order-number"] }, { children: [_jsx(CustomTag, { isIcon: true, variant: "filled", color: ordersTagColors[model.status], value: _jsx(Clipboard, {}, void 0) }, void 0), _jsxs("div", { children: ["#", name] }, void 0)] }), void 0));
            },
        },
        {
            title: "Buyer Name",
            dataIndex: ["buyerSnapshot", "name"],
            render: (name, model) => {
                var _a, _b, _c;
                return (_jsxs("div", Object.assign({ className: styles["client-name-pic"] }, { children: [_jsx("img", { src: generateProfileImageLink(name, (_c = (_b = (_a = model.buyer.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            title: "Date placed",
            dataIndex: "placedAt",
            render: (value) => {
                const date = new Date(value);
                const dateString = date.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                });
                return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
            },
        },
        {
            title: "payment method",
            dataIndex: ["paymentOption", "type"],
            render: (val) => {
                return (_jsx("div", Object.assign({ className: styles["payment"] }, { children: _jsx(CustomTag, { color: val ? paymentMethodTagColors[val] : TagColorsEnum.GRAY, value: val ? paymentOptionLabel[val] : "dummy", variant: "filled" }, void 0) }), void 0));
            },
        },
        {
            title: "Status",
            dataIndex: ["status"],
            render: (value) => (_jsx(CustomTag, { value: orderStatusLabel[value], variant: "filled", color: ordersTagColors[value] }, void 0)),
        },
        {
            title: "Price",
            dataIndex: ["finalPrice"],
            render: (value) => _jsx("span", { children: settings.formatPriceWithCurrency(value) }, void 0),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([
    // formData.buyerInfo?.key,
    ]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            stepForm.setFieldsValue({ orderInfo: selectedRows[0] });
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Searchbar, { placeholder: "Search For Order Id or Client name", onChange: (searchTerm) => {
                    onSearch(searchTerm);
                } }, void 0), _jsx(Table, { className: styles["table"], rowSelection: {
                    type: "radio",
                    ...rowSelection,
                }, rowKey: (record) => record._id, showHeader: false, columns: columns, dataSource: filteredData, pagination: false }, void 0)] }), void 0));
};
export default AddOrder;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import classNames from "classnames";
import { AdminLoading } from "@bluelibs/x-ui-admin/dist/react/components";
import { RangeSlider } from "@bundles/UIAppBundle/components/RangeSlider";
import { ColorSelect } from "@bundles/UIAppBundle/components/ColorSelect";
import TextSvg from "@bundles/UIAppBundle/icons/Text";
import ImageSvg from "@bundles/UIAppBundle/icons/Image";
import DropDownArrow from "@bundles/UIAppBundle/icons/DropDownArrow";
import TextController from "@bundles/UIAppBundle/icons/TextController";
import { Button, Disclosure } from "@bundles/UIAppBundle/components";
import styles from "../Product3DPreview.module.scss";
import { DecalType, sliderValues } from "../consts";
const sideMenuBackgroundClasses = (isText) => classNames(styles["menu-item-button"], {
    [styles["menu-item-background"]]: isText,
});
const sideMenuSvgClasses = (isText) => classNames({
    [styles["menu-item-svg"]]: isText,
});
const sideMenuTextClasses = (isText) => classNames({
    [styles["menu-item-text"]]: isText,
});
const EditProduct3DMenu = ({ model, isLoading }) => {
    const [isTexts, setIsTexts] = useState(true);
    return (_jsxs("div", Object.assign({ className: styles.editContainer }, { children: [_jsxs("div", Object.assign({ className: styles.menu }, { children: [_jsx("div", Object.assign({ className: styles["menu-item"] }, { children: _jsx(Button, Object.assign({ onClick: () => setIsTexts(true), className: sideMenuBackgroundClasses(isTexts), btnStyle: "primary" }, { children: _jsxs("div", Object.assign({ className: styles["button-container"] }, { children: [_jsx(TextSvg, { className: sideMenuSvgClasses(isTexts) }, void 0), _jsx("span", Object.assign({ className: sideMenuTextClasses(isTexts) }, { children: "Text" }), void 0)] }), void 0) }), void 0) }), void 0), _jsx("div", Object.assign({ className: styles["menu-item"] }, { children: _jsx(Button, Object.assign({ onClick: () => setIsTexts(false), className: sideMenuBackgroundClasses(!isTexts), btnStyle: "primary" }, { children: _jsxs("div", Object.assign({ className: styles["button-container"] }, { children: [_jsx(ImageSvg, { className: sideMenuSvgClasses(!isTexts) }, void 0), _jsx("span", Object.assign({ className: sideMenuTextClasses(!isTexts) }, { children: "Images" }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["middle-bar"] }, { children: [isLoading && _jsx(AdminLoading, {}, void 0), _jsx("div", Object.assign({ className: styles["decals-container"] }, { children: model.decals.map((decal, index) => {
                            return decal.type === DecalType.TEXT && isTexts ? (_jsx(Fragment, { children: _jsx(Disclosure, Object.assign({ className: styles["dropdown"], classes: {
                                        container: styles["dropdown-container"],
                                        wrapper: styles["dropdown-wrapper"],
                                    }, dropdownButton: _jsxs("div", Object.assign({ className: styles["dropdown-input"] }, { children: [_jsx("input", { value: decal.text, className: styles["dropdown-button"], disabled: true, onKeyDownCapture: (event) => {
                                                    event.stopPropagation();
                                                } }, void 0), _jsx(DropDownArrow, {}, void 0)] }), void 0) }, { children: _jsxs("div", Object.assign({ className: index + "aa1" }, { children: [_jsx("div", Object.assign({ className: styles["dropdown-switch-box"] }, { children: _jsx(ColorSelect, { value: decal.fontColor, label: "", icon: TextController, onChange: () => { } }, void 0) }), void 0), sliderValues.map((e, index2) => e.renderOn.includes(decal.type) && (_jsx(RangeSlider, { value: decal[e.key], onChange: () => { }, label: e.key, min: e.min, max: e.max, disabled: true }, index2))), _jsx("div", Object.assign({ className: styles["node-name"] }, { children: decal.nodeName }), void 0)] }), index + "aa1") }), void 0) }, index)) : (_jsx(Fragment, { children: decal.type === DecalType.IMAGE && !isTexts && (_jsx(Disclosure, Object.assign({ className: styles["dropdown"], classes: {
                                        container: styles["dropdown-container"],
                                        wrapper: styles["dropdown-wrapper"],
                                    }, dropdownButton: _jsxs("div", Object.assign({ className: styles["dropdown-input"] }, { children: [_jsx("label", Object.assign({ htmlFor: `decal-${index}`, className: styles["upload-label"] }, { children: _jsx("img", { src: decal.href, className: styles.upload }, void 0) }), void 0), _jsx("input", { type: "file", id: `decal-${index}`, hidden: true, disabled: true }, void 0), _jsx("span", Object.assign({ className: styles["dropdown__file-name"] }, { children: decal.fileName }), void 0), _jsx(DropDownArrow, {}, void 0)] }), void 0) }, { children: _jsxs("div", { children: [sliderValues.map((e, index2) => e.renderOn.includes(decal.type) && (_jsx(RangeSlider, { value: decal[e.key], onChange: () => { }, label: e.key, min: e.min, max: e.max, disabled: true }, index2))), _jsx("div", Object.assign({ className: styles["node-name"] }, { children: decal.nodeName }), void 0)] }, void 0) }), void 0)) }, index));
                        }) }), void 0)] }), void 0)] }), void 0));
};
export default EditProduct3DMenu;

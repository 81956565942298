/* Example metadata object for reference
article: {
  path: {
    name: "article",
  },
  schema: "OnlineStore",
  title: "Article",
  description: "Article Page Description",
  imageUrl: "",
  contentType: "",
  published: "",
  updated: "",
  category: "",
  tags: [],
},
*/
const seoData = {
    article: {
        create: {
            path: {
                name: "article",
            },
            schema: "OnlineStore",
            title: "Create Article",
            description: "Article Page Description",
        },
        view: {
            path: {
                name: "article",
            },
            schema: "OnlineStore",
            title: "View Article",
            description: "Article Page Description",
        },
        edit: {
            path: {
                name: "article",
            },
            schema: "OnlineStore",
            title: "Edit Article",
            description: "Article Page Description",
        },
        list: {
            path: {
                name: "article",
            },
            schema: "OnlineStore",
            title: "Articles",
            description: "Article Page Description",
        },
    },
    unitsOfMeasurements: {
        view: {
            path: {
                name: "units Of measurements",
            },
            schema: "OnlineStore",
            title: "View units Of measurements",
            description: "units Of measurements Page Description",
        },
        list: {
            path: {
                name: "units Of measurements",
            },
            schema: "OnlineStore",
            title: "Units Of measurements list",
            description: "Units Of measurements Page Description",
        },
    },
    avatar: {
        create: {
            path: {
                name: "avatar",
            },
            schema: "OnlineStore",
            title: "Create Avatar",
            description: "create avatar Page Description",
        },
        view: {
            path: {
                name: "avatar",
            },
            schema: "OnlineStore",
            title: "View Avatar",
            description: "view avatar Page Description",
        },
        edit: {
            path: {
                name: "avatar",
            },
            schema: "OnlineStore",
            title: "Edit Avatar",
            description: "edit avatar Page Description",
        },
        list: {
            path: {
                name: "avatar",
            },
            schema: "OnlineStore",
            title: "Avatars",
            description: "avatar Page Description",
        },
    },
    coupon: {
        create: {
            path: {
                name: "coupon",
            },
            schema: "OnlineStore",
            title: "Create Coupons",
            description: "Coupon Page Description",
        },
        view: {
            path: {
                name: "coupon",
            },
            schema: "OnlineStore",
            title: "View Coupons",
            description: "Coupon Page Description",
        },
        edit: {
            path: {
                name: "coupon",
            },
            schema: "OnlineStore",
            title: "Edit Coupons",
            description: "Coupon Page Description",
        },
        list: {
            path: {
                name: "coupon",
            },
            schema: "OnlineStore",
            title: "Coupons List",
            description: "Coupon Page Description",
        },
    },
    dashboard: {
        path: {
            name: "dashboard",
        },
        schema: "OnlineStore",
        title: "Dashboard",
        description: "Dashboard Page Description",
    },
    endUserProfile: {
        create: {
            path: {
                name: "endUserProfile",
            },
            schema: "OnlineStore",
            title: "Create End User Profile",
            description: "enduserprofile Page Description",
        },
        view: {
            path: {
                name: "endUserProfile",
            },
            schema: "OnlineStore",
            title: "View End User Profile",
            description: "enduserprofile Page Description",
        },
        edit: {
            path: {
                name: "endUserProfile",
            },
            schema: "OnlineStore",
            title: "Edit End User Profile",
            description: "enduserprofile Page Description",
        },
        list: {
            path: {
                name: "endUserProfile",
            },
            schema: "OnlineStore",
            title: "End User Profiles List",
            description: "enduserprofile Page Description",
        },
    },
    exportProduct: {
        path: {
            name: "exportProduct",
        },
        schema: "OnlineStore",
        title: "Export Product",
        description: "Export Product Description",
    },
    home: {
        path: {
            name: "home",
        },
        schema: "OnlineStore",
        title: "Home",
        description: "Home Page Description",
    },
    importProduct: {
        path: {
            name: "importProduct",
        },
        schema: "OnlineStore",
        title: "Import Product",
        description: "importProduct Page Description",
    },
    internalUserProfile: {
        create: {
            path: {
                name: "internalUserProfile",
            },
            schema: "OnlineStore",
            title: "Create Internal Profile",
            description: "internalUserProfile Page Description",
        },
        view: {
            path: {
                name: "internalUserProfile",
            },
            schema: "OnlineStore",
            title: "View Internal Profile",
            description: "internalUserProfile Page Description",
        },
        edit: {
            path: {
                name: "internalUserProfile",
            },
            schema: "OnlineStore",
            title: "Edit Internal Profile",
            description: "internalUserProfile Page Description",
        },
        list: {
            path: {
                name: "internalUserProfile",
            },
            schema: "OnlineStore",
            title: "Internal Profiles List",
            description: "internalUserProfile Page Description",
        },
    },
    invoice: {
        create: {
            path: {
                name: "invoice",
            },
            schema: "OnlineStore",
            title: "Create Invoice",
            description: "create invoice Page Description",
        },
        view: {
            path: {
                name: "invoice",
            },
            schema: "OnlineStore",
            title: "View Invoice",
            description: "view invoice Page Description",
        },
        edit: {
            path: {
                name: "invoice",
            },
            schema: "OnlineStore",
            title: "Edit Invoice",
            description: "edit invoice Page Description",
        },
        list: {
            path: {
                name: "invoice",
            },
            schema: "OnlineStore",
            title: "Invoices List",
            description: "invoice Page Description",
        },
    },
    mailingList: {
        create: {
            path: {
                name: "mailingList",
            },
            schema: "OnlineStore",
            title: "Create MailingList",
            description: "mailingPage Description",
        },
        view: {
            path: {
                name: "mailingList",
            },
            schema: "OnlineStore",
            title: "View MailingList",
            description: "mailingPage Description",
        },
        edit: {
            path: {
                name: "mailingList",
            },
            schema: "OnlineStore",
            title: "Edit MailingList",
            description: "mailingPage Description",
        },
        list: {
            path: {
                name: "mailingList",
            },
            schema: "OnlineStore",
            title: "MailingList List",
            description: "mailingPage Description",
        },
    },
    marketing: {
        banners: {
            path: {
                name: "marketing",
            },
            schema: "OnlineStore",
            title: "Banners: Marketing",
            description: "marketing Page Description",
        },
        integration: {
            view: {
                path: {
                    name: "marketing",
                },
                schema: "OnlineStore",
                title: "View Integration",
                description: "marketing Page Description",
            },
            list: {
                path: {
                    name: "marketing",
                },
                schema: "OnlineStore",
                title: "Integrations List",
                description: "marketing Page Description",
            },
        },
    },
    orderItem: {
        create: {
            path: {
                name: "orderItem",
            },
            schema: "OnlineStore",
            title: "Create Order Item",
            description: "orderItem Page Description",
        },
        view: {
            path: {
                name: "orderItem",
            },
            schema: "OnlineStore",
            title: "View Order Item",
            description: "orderItem Page Description",
        },
        edit: {
            path: {
                name: "orderItem",
            },
            schema: "OnlineStore",
            title: "Edit Order Item",
            description: "orderItem Page Description",
        },
        list: {
            path: {
                name: "orderItem",
            },
            schema: "OnlineStore",
            title: "Order Items List",
            description: "orderItem Page Description",
        },
    },
    order: {
        create: {
            path: {
                name: "order",
            },
            schema: "OnlineStore",
            title: "Create Order",
            description: "Order Page Description",
        },
        view: {
            path: {
                name: "order",
            },
            schema: "OnlineStore",
            title: "View Order",
            description: "Order Page Description",
        },
        edit: {
            path: {
                name: "order",
            },
            schema: "OnlineStore",
            title: "Edit Order",
            description: "Order Page Description",
        },
        list: {
            path: {
                name: "order",
            },
            schema: "OnlineStore",
            title: "Orders List",
            description: "Order Page Description",
        },
    },
    paymentOption: {
        create: {
            path: {
                name: "paymentOption",
            },
            schema: "OnlineStore",
            title: "Create Payment Option",
            description: "paymentOption Page Description",
        },
        view: {
            path: {
                name: "paymentOption",
            },
            schema: "OnlineStore",
            title: "View Payment Option",
            description: "paymentOption Page Description",
        },
        edit: {
            path: {
                name: "paymentOption",
            },
            schema: "OnlineStore",
            title: "Edit Payment Option",
            description: "paymentOption Page Description",
        },
        list: {
            path: {
                name: "paymentOption",
            },
            schema: "OnlineStore",
            title: "Payment Options List",
            description: "paymentOption Page Description",
        },
    },
    payment: {
        create: {
            path: {
                name: "payment",
            },
            schema: "OnlineStore",
            title: "Create Payment",
            description: "payment Page Description",
        },
        view: {
            path: {
                name: "payment",
            },
            schema: "OnlineStore",
            title: "View Payment",
            description: "payment Page Description",
        },
        edit: {
            path: {
                name: "payment",
            },
            schema: "OnlineStore",
            title: "Edit Payment",
            description: "payment Page Description",
        },
        list: {
            path: {
                name: "payment",
            },
            schema: "OnlineStore",
            title: "Payments List",
            description: "payment Page Description",
        },
    },
    posts: {
        create: {
            path: {
                name: "posts",
            },
            schema: "OnlineStore",
            title: "Create post",
            description: "posts Page Description",
        },
        view: {
            path: {
                name: "posts",
            },
            schema: "OnlineStore",
            title: "View post",
            description: "posts Page Description",
        },
        edit: {
            path: {
                name: "posts",
            },
            schema: "OnlineStore",
            title: "Edit post",
            description: "posts Page Description",
        },
        list: {
            path: {
                name: "posts",
            },
            schema: "OnlineStore",
            title: "Posts List",
            description: "posts Page Description",
        },
    },
    productCategories: {
        create: {
            path: {
                name: "productCategories",
            },
            schema: "OnlineStore",
            title: "Create Product Categories",
            description: "productCategories Page Description",
        },
        view: {
            path: {
                name: "productCategories",
            },
            schema: "OnlineStore",
            title: "View Product Categories",
            description: "productCategories Page Description",
        },
        edit: {
            path: {
                name: "productCategories",
            },
            schema: "OnlineStore",
            title: "Edit Product Categories",
            description: "productCategories Page Description",
        },
        list: {
            path: {
                name: "productCategories",
            },
            schema: "OnlineStore",
            title: "Product Categories List",
            description: "productCategories Page Description",
        },
    },
    productFavourites: {
        create: {
            path: {
                name: "productFavourites",
            },
            schema: "OnlineStore",
            title: "Create Product Favourite",
            description: "productFavourites Page Description",
        },
        view: {
            path: {
                name: "productFavourites",
            },
            schema: "OnlineStore",
            title: "View Product Favourite",
            description: "productFavourites Page Description",
        },
        edit: {
            path: {
                name: "productFavourites",
            },
            schema: "OnlineStore",
            title: "Edit Product Favourite",
            description: "productFavourites Page Description",
        },
        list: {
            path: {
                name: "productFavourites",
            },
            schema: "OnlineStore",
            title: "Product Favourites List",
            description: "productFavourites Page Description",
        },
    },
    product: {
        create: {
            path: {
                name: "product",
            },
            schema: "OnlineStore",
            title: "Create Product",
            description: "product Page Description",
        },
        view: {
            path: {
                name: "product",
            },
            schema: "OnlineStore",
            title: "View Product",
            description: "product Page Description",
        },
        edit: {
            path: {
                name: "product",
            },
            schema: "OnlineStore",
            title: "Edit Product",
            description: "product Page Description",
        },
        list: {
            path: {
                name: "product",
            },
            schema: "OnlineStore",
            title: "Products List",
            description: "product Page Description",
        },
    },
    productVariant: {
        create: {
            path: {
                name: "productVariant",
            },
            schema: "OnlineStore",
            title: "Create Product Variant",
            description: "productVariant Page Description",
        },
        view: {
            path: {
                name: "productVariant",
            },
            schema: "OnlineStore",
            title: "View Product Variant",
            description: "productVariant Page Description",
        },
        edit: {
            path: {
                name: "productVariant",
            },
            schema: "OnlineStore",
            title: "Edit Product Variant",
            description: "productVariant Page Description",
        },
        list: {
            path: {
                name: "productVariant",
            },
            schema: "OnlineStore",
            title: "Product Variants List",
            description: "productVariant Page Description",
        },
    },
    profile: {
        path: {
            name: "profile",
        },
        schema: "OnlineStore",
        title: "Create Profile",
        description: "profile Page Description",
    },
    return: {
        create: {
            path: {
                name: "return",
            },
            schema: "OnlineStore",
            title: "Create Return",
            description: "return Page Description",
        },
        view: {
            path: {
                name: "return",
            },
            schema: "OnlineStore",
            title: "View Return",
            description: "return Page Description",
        },
        edit: {
            path: {
                name: "return",
            },
            schema: "OnlineStore",
            title: "Edit Return",
            description: "return Page Description",
        },
        list: {
            path: {
                name: "return",
            },
            schema: "OnlineStore",
            title: "Returns List",
            description: "return Page Description",
        },
    },
    review: {
        create: {
            path: {
                name: "review",
            },
            schema: "OnlineStore",
            title: "Create Review",
            description: "review Page Description",
        },
        view: {
            path: {
                name: "review",
            },
            schema: "OnlineStore",
            title: "View Review",
            description: "review Page Description",
        },
        edit: {
            path: {
                name: "review",
            },
            schema: "OnlineStore",
            title: "Edit Review",
            description: "review Page Description",
        },
        list: {
            path: {
                name: "review",
            },
            schema: "OnlineStore",
            title: "Reviews List",
            description: "review Page Description",
        },
    },
    setting: {
        path: {
            name: "setting",
        },
        schema: "OnlineStore",
        title: "Settings",
        description: "setting Page Description",
    },
    shippingAddress: {
        create: {
            path: {
                name: "shippingAddress",
            },
            schema: "OnlineStore",
            title: "Create ShippingAddress",
            description: "ShippingAddress Page Description",
        },
        view: {
            path: {
                name: "shippingAddress",
            },
            schema: "OnlineStore",
            title: "View ShippingAddress",
            description: "ShippingAddress Page Description",
        },
        edit: {
            path: {
                name: "shippingAddress",
            },
            schema: "OnlineStore",
            title: "Edit ShippingAddress",
            description: "ShippingAddress Page Description",
        },
        list: {
            path: {
                name: "shippingAddress",
            },
            schema: "OnlineStore",
            title: "ShippingAddress",
            description: "ShippingAddress Page Description",
        },
    },
    shippingOption: {
        create: {
            path: {
                name: "shippingOption",
            },
            schema: "OnlineStore",
            title: "Create ShippingOption",
            description: "ShippingOption Page Description",
        },
        view: {
            path: {
                name: "shippingOption",
            },
            schema: "OnlineStore",
            title: "View ShippingOption",
            description: "ShippingOption Page Description",
        },
        edit: {
            path: {
                name: "shippingOption",
            },
            schema: "OnlineStore",
            title: "Edit ShippingOption",
            description: "ShippingOption Page Description",
        },
        list: {
            path: {
                name: "shippingOption",
            },
            schema: "OnlineStore",
            title: "ShippingOptions List",
            description: "ShippingOption Page Description",
        },
    },
    shopSettings: {
        create: {
            path: {
                name: "shopSettings",
            },
            schema: "OnlineStore",
            title: "Create Shop Settings",
            description: "shopSettings Page Description",
        },
        view: {
            path: {
                name: "shopSettings",
            },
            schema: "OnlineStore",
            title: "View Shop Settings",
            description: "shopSettings Page Description",
        },
        edit: {
            path: {
                name: "shopSettings",
            },
            schema: "OnlineStore",
            title: "Edit Shop Settings",
            description: "shopSettings Page Description",
        },
        list: {
            path: {
                name: "shopSettings",
            },
            schema: "OnlineStore",
            title: "Shop Settings List",
            description: "shopSettings Page Description",
        },
    },
    user: {
        create: {
            path: {
                name: "user",
            },
            schema: "OnlineStore",
            title: "Create User",
            description: "user Page Description",
        },
        view: {
            path: {
                name: "user",
            },
            schema: "OnlineStore",
            title: "View User",
            description: "user Page Description",
        },
        edit: {
            path: {
                name: "user",
            },
            schema: "OnlineStore",
            title: "Edit User",
            description: "user Page Description",
        },
        list: {
            path: {
                name: "user",
            },
            schema: "OnlineStore",
            title: "User",
            description: "user Page Description",
        },
    },
    vatRate: {
        create: {
            path: {
                name: "vatRate",
            },
            schema: "OnlineStore",
            title: "Create Vat Rate",
            description: "vatRate Page Description",
        },
        view: {
            path: {
                name: "vatRate",
            },
            schema: "OnlineStore",
            title: "View Vat Rate",
            description: "vatRate Page Description",
        },
        edit: {
            path: {
                name: "vatRate",
            },
            schema: "OnlineStore",
            title: "Edit Vat Rate",
            description: "vatRate Page Description",
        },
        list: {
            path: {
                name: "vatRate",
            },
            schema: "OnlineStore",
            title: "Vat Rate List",
            description: "vatRate Page Description",
        },
    },
};
export default seoData;

import { Routes } from "@bundles/UIAppBundle";
import { Clients, Dashboard, Invoices, Marketing, Orders, Products, ShopConfiguration, } from "@bundles/UIAppBundle/icons";
import { dashboardAccessRoles } from "@bundles/UIAppBundle/pages/Dashboard";
import { clientsAccessRoles } from "@bundles/UIAppBundle/pages/EndUserProfilesManagement/consts";
import { invoiceAccessRoles } from "@bundles/UIAppBundle/pages/InvoicesManagement/consts";
import { marketingAccessRoles } from "@bundles/UIAppBundle/pages/Marketing/consts";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { productsAccessRoles } from "@bundles/UIAppBundle/pages/ProductsManagement/consts";
import { shopConfigurationAccessRoles } from "../../ShopConfiguration";
export const sideMenus = [
    {
        label: "Dashboard",
        url: "/dashboard",
        icon: Dashboard,
        roles: dashboardAccessRoles,
    },
    {
        label: "Shop Configuration",
        url: "/shop-settings/general",
        icon: ShopConfiguration,
        urlHead: "/shop-settings",
        roles: shopConfigurationAccessRoles,
    },
    {
        label: "Products",
        url: "products",
        icon: Products,
        roles: productsAccessRoles,
        subMenus: [
            {
                label: "Products dashboard",
                url: Routes.PRODUCTS_LIST.path,
            },
            {
                label: "Products categories",
                url: Routes.PRODUCT_CATEGORIES_LIST.path,
            },
            {
                label: "Units of measurements",
                url: Routes.UNITS_OF_MEASUREMENT_LIST.path,
            },
            {
                label: "Products 3D models",
                url: Routes.PRODUCT_3_D_MODEL_LIST.path,
            },
        ],
    },
    {
        label: "Orders",
        url: "orders",
        icon: Orders,
        roles: ordersAccessRoles,
        subMenus: [
            {
                label: "Orders",
                url: Routes.ORDERS_LIST.path,
            },
            {
                label: "Returns",
                url: "/returns",
            },
        ],
    },
    {
        label: "Clients",
        url: Routes.END_USER_PROFILES_LIST.path,
        icon: Clients,
        roles: clientsAccessRoles,
    },
    {
        label: "Invoices",
        url: "/invoices",
        icon: Invoices,
        roles: invoiceAccessRoles,
    },
    {
        label: "Marketing",
        url: Routes.MARKETING_INTEGRATIONS.path,
        icon: Marketing,
        roles: marketingAccessRoles,
        urlHead: "/marketing",
    },
];

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import classNames from "classnames";
import { Button, Card, FieldGroupWrapper, } from "@bundles/UIAppBundle/components";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { useProductCreate } from "./hooks";
import { inheritedAttributesListRenderer } from "./helpers/inheritedAttritubesListRenderer";
import { scrollToFirstFormFieldError } from "@bundles/UIAppBundle/utils/helpers";
import styles from "./ProductsCreate.module.scss";
export function ProductsCreateForm() {
    const { t, form, formInstance, onSubmit, onFormValuesChange, mutationLoading, } = useProductCreate();
    return (_jsxs(Ant.Form, Object.assign({ requiredMark: "optional", form: formInstance, onFinish: (document) => onSubmit(document), onValuesChange: onFormValuesChange, scrollToFirstError: scrollToFirstFormFieldError }, { children: [form.render("isAvailableForPurchase"), _jsxs(Card, Object.assign({ className: styles.section }, { children: [_jsxs(FieldGroupWrapper, Object.assign({ title: "General" }, { children: [form.render("name"), form.render("description")] }), void 0), _jsxs(FieldGroupWrapper, Object.assign({ title: "Details" }, { children: [form.render("brand"), form.render("type"), form.render("weight"), form.render("length"), form.render("width"), form.render("height"), form.render("country")] }), void 0), _jsx(FieldGroupWrapper, Object.assign({ title: "Category" }, { children: form.render("categoryId") }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.section }, { children: [_jsx("div", Object.assign({ className: classNames(styles["gray-bold"], styles["outside-title"]) }, { children: "Product Images" }), void 0), form.render("images")] }), void 0), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsx(FieldGroupWrapper, Object.assign({ title: "Variants" }, { children: form.render("hasVariants") }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "Price" }, { children: [form.render("defaultNetPrice"), form.render("defaultDiscountPercentage"), form.render("discountActiveUntil"), form.render("vatRateId")] }), void 0) }), void 0), form.render("inventory"), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "Attributes" }, { children: [_jsx(Ant.Form.List, Object.assign({ name: "shopAttributes", initialValue: [] }, { children: inheritedAttributesListRenderer({
                                collection: "shopAttributes",
                                isEdit: false,
                            }) }), void 0), _jsx(Ant.Form.List, Object.assign({ name: "categoryAttributes", initialValue: [] }, { children: inheritedAttributesListRenderer({
                                collection: "categoryAttributes",
                                isEdit: false,
                            }) }), void 0), form.render("attributeOptions")] }), void 0) }), void 0), form.render("defaultImageIndex"), _jsx(Card, Object.assign({ className: styles.section }, { children: _jsx(FieldGroupWrapper, Object.assign({ title: "Search Engine" }, { children: form.render("seoFields") }), void 0) }), void 0), _jsx(Button, Object.assign({ type: "submit", width: "auto", className: styles["submit-btn"], icon: Checkmark, loading: mutationLoading }, { children: t("generics.save_changes") }), void 0)] }), void 0));
}

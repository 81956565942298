import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Helmet } from "react-helmet";
const seoURL = (path) => {
    return path;
};
const getMetaTags = ({ title, description, url, 
// imageUrl,
contentType, published, updated, category, tags, }) => {
    const metaTags = [
        { itemprop: "name", content: `${title} | Cult of Coders` },
        { itemprop: "description", content: description },
        // { itemprop: 'image', content: imageUrl || Meteor.absoluteUrl("assets/img/placeholder-cult-of-coders.jpg") },
        { name: "description", content: description },
        // { name: "twitter:card", content: "summary" },
        // { name: "twitter:creator", content: "@cultofcoders" },
        // { name: "twitter:site", content: "@cultofcoders" },
        // { name: "twitter:title", content: `${title} | Cult of Coders` },
        // { name: "twitter:description", content: description },
        // { name: 'twitter:image', content: imageUrl || Meteor.absoluteUrl("assets/img/placeholder-cult-of-coders.jpg") },
        { property: "og:type", content: `${contentType}` || "website" },
        { property: "og:title", content: `${title} | Cult of Coders` },
        { property: "og:description", content: description },
        { property: "og:url", content: url },
        // { property: "og:site_name", content: "Cult of coders" },
        // { property: 'og:image', content: imageUrl || Meteor.absoluteUrl("assets/img/placeholder-cult-of-coders.jpg") },
        // { property: 'og:image:secure_url', content: imageUrl || Meteor.absoluteUrl("assets/img/placeholder-cult-of-coders.jpg") },
        // { property: "fb:app_id", content: "" },
    ];
    if (contentType === "article")
        metaTags.push({
            property: "article:author",
            content: "https://www.facebook.com/cultofcoders/",
        });
    if (contentType === "article")
        metaTags.push({
            property: "article:publisher",
            content: "https://www.facebook.com/cultofcoders/",
        });
    if (published)
        metaTags.push({ property: "article:published_time", content: published });
    if (updated)
        metaTags.push({ property: "article:modified_time", content: updated });
    if (category)
        metaTags.push({ property: "article:section", content: category });
    if (tags === null || tags === void 0 ? void 0 : tags.length)
        metaTags.push({ property: "article:tag", content: tags });
    return metaTags;
};
const Seo = ({ data }) => {
    const { path, schema, title, description, 
    // imageUrl,
    contentType, published, updated, category, tags, } = data;
    return (_jsx(Helmet, { htmlAttributes: {
            lang: "en",
            itemscope: undefined,
            itemtype: `http://schema.org/${schema}`,
        }, title: `${title} | Freedom Pack Admin Panel`, link: [{ rel: "canonical", href: seoURL(path) }], meta: getMetaTags({
            title,
            description,
            contentType,
            url: seoURL(path),
            // imageUrl,
            published,
            updated,
            category,
            tags,
        }) }, void 0));
};
export default Seo;

import { VatRatesList } from "../components/List/VatRatesList";
import { VatRatesCreate } from "../components/Create/VatRatesCreate";
import { VatRatesEdit } from "../components/Edit/VatRatesEdit";
import { VatRatesView } from "../components/View/VatRatesView";
import { SettingFilled } from "@ant-design/icons";
export const VAT_RATES_LIST = {
    path: "/admin/vat-rates",
    component: VatRatesList,
    menu: {
        key: "VAT_RATES_LIST",
        label: "management.vat_rates.menu.title",
        icon: SettingFilled,
    },
};
export const VAT_RATES_CREATE = {
    path: "/admin/vat-rates/create",
    component: VatRatesCreate,
};
export const VAT_RATES_EDIT = {
    path: "/admin/vat-rates/:id/edit",
    component: VatRatesEdit,
};
export const VAT_RATES_VIEW = {
    path: "/admin/vat-rates/:id/view",
    component: VatRatesView,
};

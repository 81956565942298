import { gql } from "@apollo/client";
export const GetGeneralDetails = gql `
  query AdminGetShopSettings {
    AdminGetShopSettings {
      _id
      billingAddress {
        city
        country
        email
        phoneNumber
        streetAddressLine1
        zipcode
      }
      companyIdentificationNumber
      companyName
      shopName
    }
  }
`;
export const GetInvoicingDetails = gql `
  query AdminGetShopSettings {
    AdminGetShopSettings {
      _id
      defaultCurrency
      firstInvoiceNumber
      freeShippingAfterAmountLimit
      invoiceGenerationPrefix
    }
  }
`;
export const GetShopAttributes = gql `
  query AdminGetShopSettings {
    AdminGetShopSettings {
      _id
      attributeOptions {
        name
        type
        defaultSuffix
      }
    }
  }
`;
export const AdminGetShopSettings = gql `
  query AdminGetShopSettings {
    AdminGetShopSettings {
      _id
      billingAddress {
        city
        country
        email
        phoneNumber
        streetAddressLine1
        zipcode
      }
      attributeOptions {
        name
        type
      }
      defaultCurrency
      companyIdentificationNumber
      companyName
      firstInvoiceNumber
      freeShippingAfterAmountLimit
      invoiceGenerationPrefix
      shopName
    }
  }
`;

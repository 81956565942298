import { gql } from "@apollo/client";
export const GET_PRODUCT_VARIANTS = gql `
  query ProductVariantsListGet($input: ProductVariantsListGetInput) {
    ProductVariantsListGet(input: $input) {
      pagination {
        total
      }
      items {
        _id
        name
        product {
          _id
          name
          availableStockCount
        }
        attributesValues {
          key
          value
        }
        isPublished
        netPrice
        defaultImage {
          downloadUrl
        }
        totalDiscountPercentage
        taxAmount
        vatRate {
          percentage
        }
      }
    }
  }
`;

import { OrderStatus, PaymentOptionType, ReturnStatus } from "@root/api.types";
export const orderStatusLabel = {
    [OrderStatus.CANCELED]: "Canceled",
    [OrderStatus.DRAFT]: "Pending",
    [OrderStatus.PLACED]: "Received",
    [OrderStatus.DELIVERED]: "Shipped",
    [OrderStatus.SHIPPING]: "In Progress",
};
export const paymentOptionLabel = {
    [PaymentOptionType.CARD_POS]: "Credit Card",
    [PaymentOptionType.CASH]: "Cash",
    [PaymentOptionType.BANK_TRANSFER]: "Bank Transfer",
    [PaymentOptionType.STRIPE]: "Stripe",
    [PaymentOptionType.PAYU]: "PayU",
    [PaymentOptionType.LATER]: "Later",
};
export const returnStatusLabel = {
    [ReturnStatus.CANCELED]: "Cancelled",
    [ReturnStatus.COMPLETED]: "Completed",
    [ReturnStatus.PENDING]: "Pending",
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { Service } from "@bluelibs/core";
import { ShippingAddressForm } from "./ShippingAddressForm";
import { SmileOutlined } from "@ant-design/icons";
let ShippingAddressEditForm = class ShippingAddressEditForm extends ShippingAddressForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            _id: 1,
        };
    }
    onEditSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_address.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
ShippingAddressEditForm = __decorate([
    Service({ transient: true })
], ShippingAddressEditForm);
export { ShippingAddressEditForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ProductEditForm as BaseProductEditForm } from "./ProductEditForm.base";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ControlledInput, RichTextEditor, ControlledSelect, FieldGroupWrapper, InputNumber, DatePicker, ControlledTextArea, } from "@bundles/UIAppBundle/components";
import { ProductTypes } from "@root/api.types";
import { yesOrNoOptions, availabilityOptions, } from "../components/Create/consts";
import { newAttributesListRenderer } from "../components/Create/helpers/newAttritubesListRenderer";
import styles from "../components/Create/ProductsCreate.module.scss";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let ProductEditForm = class ProductEditForm extends BaseProductEditForm {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("tags");
        this.remove("defaultImageId");
        this.remove("imagesId");
        this.update("name", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.name")), placeholder: t("management.products.placeholders.type_here"), id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(RichTextEditor, { label: t("management.products.fields.description"), placeholder: t("management.products.placeholders.type_here"), id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("brand", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.brand")), placeholder: t("management.products.placeholders.brand"), id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("weight", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 9 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.weight")), placeholder: t("management.products.placeholders.weight"), id: props.id }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("country", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.fields.country")), placeholder: t("management.products.placeholders.type_here"), id: props.id }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("length", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.products.descriptions.length")), placeholder: t("management.products.placeholders.length"), id: props.id }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("width", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: "\u00A0", placeholder: t("management.products.placeholders.width"), id: props.id }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("height", {
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => getFieldValue("type") === ProductTypes.PHYSICAL ? (_jsx(Ant.Col, Object.assign({ span: 5 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: "\u00A0", placeholder: t("management.products.placeholders.height"), id: props.id }, void 0) }), void 0) }), void 0)) : null }), void 0)),
        });
        this.update("attributeOptions", {
            name: ["attributeOptions"],
            required: false,
            isList: true,
            initialValue: [],
            listRenderer: () => newAttributesListRenderer(t),
        });
        this.update("defaultNetPrice", {
            required: true,
            name: ["defaultNetPrice"],
            rules: [
                {
                    required: true,
                    message: t("generics.forms.field_required", {
                        field: t("management.products.fields.defaultNetPrice"),
                    }),
                },
                {
                    type: "number",
                    min: 0,
                    message: t("generics.forms.rangeMin", {
                        field: t("management.products.fields.defaultNetPrice"),
                        min: 0,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: addRequiredFieldSpecialMark(t("management.products.fields.defaultNetPrice")), placeholder: t("management.products.placeholders.defaultNetPrice"), type: "number", id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("hasVariants", {
            required: true,
            initialValue: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: yesOrNoOptions, label: t("management.products.fields.hasVariants"), placeholder: t("management.products.placeholders.hasVariants"), description: t("management.products.descriptions.hasVariants"), id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("defaultDiscountPercentage", {
            name: ["defaultDiscountPercentage"],
            required: false,
            rules: [
                {
                    type: "number",
                    min: 1,
                    max: 99,
                    message: t("generics.forms.range", {
                        field: t("management.products.fields.discountPercentage"),
                        min: 1,
                        max: 99,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: _jsxs(_Fragment, { children: [t("management.products.fields.discountPercentage"), "\u00A0", _jsx("span", Object.assign({ className: styles.comment }, { children: t("management.products.fields.discountPercentage_comment") }), void 0)] }, void 0), placeholder: t("management.products.placeholders.discountPercentage"), type: "number", id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("discountActiveUntil", {
            required: false,
            name: ["discountActiveUntil"],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(DatePicker, { label: t("management.products.fields.discountActiveUntil"), placeholder: t("management.products.placeholders.discountActiveUntil"), disabledDate: (date) => date.valueOf() < Date.now().valueOf(), id: props.id }, void 0) }), void 0) }), void 0)),
        });
        this.update("seoFields", {
            name: ["seoFields"],
            nest: [
                {
                    id: "pageTitle",
                    name: ["seoFields"]["pageTitle"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.pageTitle"), placeholder: t("management.products.placeholders.pageTitle"), id: props.id }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "metaDescription",
                    name: ["metaDescription"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledTextArea, { label: t("management.products.fields.metaDescription"), placeholder: t("management.products.placeholders.metaDescription"), rows: 7, id: props.id }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "keywords",
                    name: ["keywords"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.keywords"), placeholder: t("management.products.placeholders.keywords"), description: t("management.products.descriptions.keywords"), id: props.id }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "slug",
                    name: ["slug"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.slug"), placeholder: t("management.products.placeholders.slug"), StartComponent: _jsx("span", Object.assign({ className: styles["base-url"] }, { children: `${process.env.SHOP_URL}/` }), void 0), id: props.id }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        this.add([
            {
                id: "availableStockCount",
                name: ["availableStockCount"],
                required: true,
                rules: [
                    {
                        required: true,
                        message: t("generics.forms.field_required", {
                            field: t("management.products.fields.availableStockCount"),
                        }),
                    },
                    {
                        type: "number",
                        min: 0,
                        message: t("generics.forms.rangeMin", {
                            field: t("management.products.fields.availableStockCount"),
                            min: 0,
                        }),
                    },
                ],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: _jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: t("management.products.fields.availableStockCount"), placeholder: t("management.products.placeholders.type_here"), type: "number", id: props.id }, void 0) }), void 0) }), void 0) }), void 0)),
            },
            {
                id: "sku",
                name: ["sku"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: _jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.products.fields.sku"), placeholder: t("management.products.placeholders.type_here"), id: props.id }, void 0) }), void 0) }), void 0) }), void 0)),
            },
            {
                id: "availability",
                name: ["availability"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: _jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: availabilityOptions, label: t("management.products.fields.availability"), placeholder: t("management.products.placeholders.availability"), id: props.id }, void 0) }), void 0) }), void 0) }), void 0)),
            },
            {
                id: "inventory",
                name: ["inventory"],
                render: () => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: _jsx(Ant.Card, Object.assign({ className: styles.section }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: "Inventory" }, { children: [this.renderFormItem(this.findElement("availableStockCount")), this.renderFormItem(this.findElement("sku")), this.renderFormItem(this.findElement("availability"))] }), void 0) }), void 0) }), void 0)),
            },
            {
                id: "isAvailableForPurchase",
                required: true,
                name: ["isAvailableForPurchase"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: _jsxs("div", Object.assign({ className: styles["published-column"] }, { children: [_jsx("div", Object.assign({ className: styles["side-label"] }, { children: t("management.products.fields.isPublished") }), void 0), _jsx(Ant.Form.Item, Object.assign({}, props, { noStyle: true, label: "", initialValue: true }, { children: _jsx(ControlledSelect, { containerClassName: styles.select, options: yesOrNoOptions, id: props.id }, void 0) }), void 0)] }), void 0) }), void 0)),
            },
            {
                id: "defaultImageIndex",
                name: ["defaultImageIndex"],
                required: false,
                render: (props) => _jsx(Ant.Form.Item, Object.assign({}, props, { label: "", noStyle: true }), void 0),
            },
        ]);
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ProductEditForm = __decorate([
    Service({ transient: true })
], ProductEditForm);
export { ProductEditForm };

import { Product3DImageList } from "../components/List/Product3DImageList";
import { Product3DImageCreate } from "../components/Create/Product3DImageCreate";
import { Product3DImageEdit } from "../components/Edit/Product3DImageEdit";
import { Product3DImageView } from "../components/View/Product3DImageView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_3_D_IMAGE_LIST = {
    path: "/admin/product-3-d-image",
    component: Product3DImageList,
    menu: {
        key: "PRODUCT_3_D_IMAGE_LIST",
        label: "management.product_3_d_image.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_3_D_IMAGE_CREATE = {
    path: "/admin/product-3-d-image/create",
    component: Product3DImageCreate,
};
export const PRODUCT_3_D_IMAGE_EDIT = {
    path: "/admin/product-3-d-image/:id/edit",
    component: Product3DImageEdit,
};
export const PRODUCT_3_D_IMAGE_VIEW = {
    path: "/admin/product-3-d-image/:id/view",
    component: Product3DImageView,
};

import { gql } from "@apollo/client";
export const AdminGetVatRates = gql `
  query AdminGetVatRates {
    AdminGetVatRates {
      _id
      description
      name
      percentage
    }
  }
`;

import { Collection, } from "@bluelibs/x-ui";
import { Product } from "@root/api.types";
import { AppFilesCollection, AppFileGroupsCollection, } from "@bluelibs/x-ui-admin";
import { ProductCategoriesCollection, ProductVariantsCollection, VatRatesCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class ProductsCollection extends Collection {
    getName() {
        return "Products";
    }
    getInputs() {
        return {
            insert: "ProductInsertInput!",
            update: "ProductUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "defaultImage",
                field: "defaultImageId",
            },
            {
                collection: () => AppFileGroupsCollection,
                name: "images",
                field: "imagesId",
            },
            {
                collection: () => ProductCategoriesCollection,
                name: "category",
                field: "categoryId",
            },
            {
                collection: () => VatRatesCollection,
                name: "vatRate",
                field: "vatRateId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => ProductVariantsCollection,
                name: "variants",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            discountActiveUntil: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

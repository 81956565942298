import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { useRouter } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "../Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { Edit } from "@bundles/UIAppBundle/icons";
import { useInvoicingDetailsView } from "./hooks";
import styles from "../GeneralDetailsView/GeneralDetailsView.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "../consts";
const InvoicingDetailsView = () => {
    const router = useRouter();
    const { data, loading, error, t } = useInvoicingDetailsView();
    return (_jsx(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: _jsxs(ShopConfigurationLayout, { children: [loading && _jsx(Ant.Spin, { size: "large" }, void 0), !loading && (error || !data) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !loading && !error && (_jsxs(Fragment, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.shop_settings.submenus.invoicing_details") }), void 0), data.map(({ label, value }, idx) => (_jsxs(Card, Object.assign({ className: styles["info-card"] }, { children: [_jsx("div", Object.assign({ className: styles.key }, { children: label }), void 0), _jsx("div", Object.assign({ className: styles.value }, { children: value }), void 0)] }), idx))), _jsx(Button, Object.assign({ width: "auto", onClick: () => router.go(Routes.SHOP_SETTINGS_INVOICING_DETAILS_EDIT), className: styles["edit-btn"], icon: Edit }, { children: t("generics.edit") }), void 0)] }, void 0))] }, void 0) }), void 0));
};
export default InvoicingDetailsView;

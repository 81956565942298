var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { VatRateList as BaseVatRateList } from "./VatRateList.base";
import styles from "../components/List/VatRatesList.module.scss";
import classNames from "classnames";
let VatRateList = class VatRateList extends BaseVatRateList {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("stripeId");
        this.update("name", {
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.vat_rates.fields.name") }), void 0)),
            render: (value) => {
                return _jsx("span", Object.assign({ className: styles.row }, { children: value }), void 0);
            },
        });
        this.update("percentage", {
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.vat_rates.fields.percentage") }), void 0)),
            render: (value) => {
                return _jsx("span", Object.assign({ className: styles.row }, { children: value }), void 0);
            },
        });
        this.update("description", {
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.vat_rates.fields.description") }), void 0)),
            sorter: false,
            render: (value) => {
                return value ? (_jsx("span", Object.assign({ className: classNames(styles.row, "cc--clamped cc--lines-2") }, { children: value }), void 0)) : (_jsx("span", { children: "No description" }, void 0));
            },
        });
        // Perform additional modifications such as updating how a list item renders or add additional ones
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
VatRateList = __decorate([
    Service({ transient: true })
], VatRateList);
export { VatRateList };

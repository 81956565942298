import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import React from "react";
import { Button } from "../Button";
import styles from "./Topbar.module.scss";
const Topbar = ({ title, btnText, btnIcon, btnOnClick, btnLoading = false, productBreadCrumb, }) => {
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["header"] }, { children: [_jsx(BackCircle, { onClick: () => window.history.back() }, void 0), _jsx("h1", Object.assign({ className: styles["title"] }, { children: title }), void 0), btnText && (_jsx(Button, Object.assign({ width: "fit-content", onClick: btnOnClick, icon: btnIcon, loading: btnLoading }, { children: btnText }), void 0))] }), void 0), _jsx("div", Object.assign({ className: styles["breadcrumb"] }, { children: productBreadCrumb === null || productBreadCrumb === void 0 ? void 0 : productBreadCrumb.map((p, index) => {
                    {
                        index === 0 ? (_jsx("span", { children: p }, p + index)) : (_jsxs("span", { children: [" > ", p] }, p + index));
                    }
                }) }), void 0)] }), void 0));
};
export default Topbar;

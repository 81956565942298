import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useRouter, useUIComponents, useTranslate, } from "@bluelibs/x-ui";
import { useState, useMemo } from "react";
import { Product3DModelAntTableSmart } from "./Product3DModelTableSmart";
import * as Ant from "antd";
import { Product3DModelListFilters } from "./Product3DModelListFilters";
import { CustomPageHeader, Table } from "@bundles/UIAppBundle/components";
export function Product3DModelList() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const [api, Provider] = newSmart(Product3DModelAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const onFiltersUpdate = useMemo(() => {
        return (filters) => {
            api.setFlexibleFilters(filters);
        };
    }, []);
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.product_3_d_model.list.header"), searchKey: "name", searchPlaceholder: "Search Product Model", 
                // createNewLabel={t("management.product_3_d_model.list.create_btn")}
                onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsxs("div", Object.assign({ className: "page-product-3-d-model-list" }, { children: [filtersOpened && (_jsx(Product3DModelListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Table, Object.assign({}, api.getTableProps()), void 0)] }), void 0) }, void 0) }, void 0)] }, void 0));
}

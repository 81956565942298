import { useQuery } from "@apollo/client";
import * as Ant from "antd";
import { GET_INVOICE_DETAIL } from "./queries";
export const useInvoiceView = (id) => {
    const { data: AdminGetInvoiceData, loading: isLoading, error, } = useQuery(GET_INVOICE_DETAIL, {
        variables: { _id: id },
    });
    const onInvoiceDownload = (downloadUrl) => {
        if (downloadUrl)
            window.open(downloadUrl);
        else {
            Ant.notification.error({
                message: "Failed to download invoice",
            });
        }
    };
    return {
        onInvoiceDownload,
        invoice: AdminGetInvoiceData === null || AdminGetInvoiceData === void 0 ? void 0 : AdminGetInvoiceData.AdminGetInvoice,
        isLoading,
        error,
    };
};

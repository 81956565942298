var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let OrderList = class OrderList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "orderNumber",
                title: t("management.orders.fields.orderNumber"),
                key: "management.orders.fields.orderNumber",
                dataIndex: ["orderNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "placedAt",
                title: t("management.orders.fields.placedAt"),
                key: "management.orders.fields.placedAt",
                dataIndex: ["placedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                title: t("management.orders.fields.status"),
                key: "management.orders.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.nbh",
                title: t("management.orders.fields.billingAddress.nbh"),
                key: "management.orders.fields.billingAddress.nbh",
                dataIndex: ["billingAddress", "nbh"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_floor",
                title: t("management.orders.fields.billingAddress.building_floor"),
                key: "management.orders.fields.billingAddress.building_floor",
                dataIndex: ["billingAddress", "building_floor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip",
                title: t("management.orders.fields.billingAddress.zip"),
                key: "management.orders.fields.billingAddress.zip",
                dataIndex: ["billingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.address",
                title: t("management.orders.fields.billingAddress.address"),
                key: "management.orders.fields.billingAddress.address",
                dataIndex: ["billingAddress", "address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_entrance",
                title: t("management.orders.fields.billingAddress.building_entrance"),
                key: "management.orders.fields.billingAddress.building_entrance",
                dataIndex: ["billingAddress", "building_entrance"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.street",
                title: t("management.orders.fields.billingAddress.street"),
                key: "management.orders.fields.billingAddress.street",
                dataIndex: ["billingAddress", "street"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.district",
                title: t("management.orders.fields.billingAddress.district"),
                key: "management.orders.fields.billingAddress.district",
                dataIndex: ["billingAddress", "district"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.tax",
                title: t("management.orders.fields.billingAddress.tax"),
                key: "management.orders.fields.billingAddress.tax",
                dataIndex: ["billingAddress", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.orders.fields.billingAddress.country"),
                key: "management.orders.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.note",
                title: t("management.orders.fields.billingAddress.note"),
                key: "management.orders.fields.billingAddress.note",
                dataIndex: ["billingAddress", "note"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                title: t("management.orders.fields.billingAddress.email"),
                key: "management.orders.fields.billingAddress.email",
                dataIndex: ["billingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.num",
                title: t("management.orders.fields.billingAddress.num"),
                key: "management.orders.fields.billingAddress.num",
                dataIndex: ["billingAddress", "num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.salesRayId",
                title: t("management.orders.fields.billingAddress.salesRayId"),
                key: "management.orders.fields.billingAddress.salesRayId",
                dataIndex: ["billingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip_num",
                title: t("management.orders.fields.billingAddress.zip_num"),
                key: "management.orders.fields.billingAddress.zip_num",
                dataIndex: ["billingAddress", "zip_num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.fax",
                title: t("management.orders.fields.billingAddress.fax"),
                key: "management.orders.fields.billingAddress.fax",
                dataIndex: ["billingAddress", "fax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building",
                title: t("management.orders.fields.billingAddress.building"),
                key: "management.orders.fields.billingAddress.building",
                dataIndex: ["billingAddress", "building"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.settlement",
                title: t("management.orders.fields.billingAddress.settlement"),
                key: "management.orders.fields.billingAddress.settlement",
                dataIndex: ["billingAddress", "settlement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phone",
                title: t("management.orders.fields.billingAddress.phone"),
                key: "management.orders.fields.billingAddress.phone",
                dataIndex: ["billingAddress", "phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_apartment",
                title: t("management.orders.fields.billingAddress.building_apartment"),
                key: "management.orders.fields.billingAddress.building_apartment",
                dataIndex: ["billingAddress", "building_apartment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                title: t("management.orders.fields.salesRayId"),
                key: "management.orders.fields.salesRayId",
                dataIndex: ["salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.nbh",
                title: t("management.orders.fields.shippingAddress.nbh"),
                key: "management.orders.fields.shippingAddress.nbh",
                dataIndex: ["shippingAddress", "nbh"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_floor",
                title: t("management.orders.fields.shippingAddress.building_floor"),
                key: "management.orders.fields.shippingAddress.building_floor",
                dataIndex: ["shippingAddress", "building_floor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip",
                title: t("management.orders.fields.shippingAddress.zip"),
                key: "management.orders.fields.shippingAddress.zip",
                dataIndex: ["shippingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.address",
                title: t("management.orders.fields.shippingAddress.address"),
                key: "management.orders.fields.shippingAddress.address",
                dataIndex: ["shippingAddress", "address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_entrance",
                title: t("management.orders.fields.shippingAddress.building_entrance"),
                key: "management.orders.fields.shippingAddress.building_entrance",
                dataIndex: ["shippingAddress", "building_entrance"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.street",
                title: t("management.orders.fields.shippingAddress.street"),
                key: "management.orders.fields.shippingAddress.street",
                dataIndex: ["shippingAddress", "street"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.district",
                title: t("management.orders.fields.shippingAddress.district"),
                key: "management.orders.fields.shippingAddress.district",
                dataIndex: ["shippingAddress", "district"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.tax",
                title: t("management.orders.fields.shippingAddress.tax"),
                key: "management.orders.fields.shippingAddress.tax",
                dataIndex: ["shippingAddress", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.country",
                title: t("management.orders.fields.shippingAddress.country"),
                key: "management.orders.fields.shippingAddress.country",
                dataIndex: ["shippingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.note",
                title: t("management.orders.fields.shippingAddress.note"),
                key: "management.orders.fields.shippingAddress.note",
                dataIndex: ["shippingAddress", "note"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.email",
                title: t("management.orders.fields.shippingAddress.email"),
                key: "management.orders.fields.shippingAddress.email",
                dataIndex: ["shippingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.num",
                title: t("management.orders.fields.shippingAddress.num"),
                key: "management.orders.fields.shippingAddress.num",
                dataIndex: ["shippingAddress", "num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.salesRayId",
                title: t("management.orders.fields.shippingAddress.salesRayId"),
                key: "management.orders.fields.shippingAddress.salesRayId",
                dataIndex: ["shippingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip_num",
                title: t("management.orders.fields.shippingAddress.zip_num"),
                key: "management.orders.fields.shippingAddress.zip_num",
                dataIndex: ["shippingAddress", "zip_num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.fax",
                title: t("management.orders.fields.shippingAddress.fax"),
                key: "management.orders.fields.shippingAddress.fax",
                dataIndex: ["shippingAddress", "fax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building",
                title: t("management.orders.fields.shippingAddress.building"),
                key: "management.orders.fields.shippingAddress.building",
                dataIndex: ["shippingAddress", "building"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.settlement",
                title: t("management.orders.fields.shippingAddress.settlement"),
                key: "management.orders.fields.shippingAddress.settlement",
                dataIndex: ["shippingAddress", "settlement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.phone",
                title: t("management.orders.fields.shippingAddress.phone"),
                key: "management.orders.fields.shippingAddress.phone",
                dataIndex: ["shippingAddress", "phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_apartment",
                title: t("management.orders.fields.shippingAddress.building_apartment"),
                key: "management.orders.fields.shippingAddress.building_apartment",
                dataIndex: ["shippingAddress", "building_apartment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.name",
                title: t("management.orders.fields.buyerSnapshot.name"),
                key: "management.orders.fields.buyerSnapshot.name",
                dataIndex: ["buyerSnapshot", "name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.phoneNumber",
                title: t("management.orders.fields.buyerSnapshot.phoneNumber"),
                key: "management.orders.fields.buyerSnapshot.phoneNumber",
                dataIndex: ["buyerSnapshot", "phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.email",
                title: t("management.orders.fields.buyerSnapshot.email"),
                key: "management.orders.fields.buyerSnapshot.email",
                dataIndex: ["buyerSnapshot", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "privateNotes",
                title: t("management.orders.fields.privateNotes"),
                key: "management.orders.fields.privateNotes",
                dataIndex: ["privateNotes"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price",
                title: t("management.orders.fields.price"),
                key: "management.orders.fields.price",
                dataIndex: ["price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceVAT",
                title: t("management.orders.fields.priceVAT"),
                key: "management.orders.fields.priceVAT",
                dataIndex: ["priceVAT"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceTotal",
                title: t("management.orders.fields.priceTotal"),
                key: "management.orders.fields.priceTotal",
                dataIndex: ["priceTotal"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "finalPrice",
                title: t("management.orders.fields.finalPrice"),
                key: "management.orders.fields.finalPrice",
                dataIndex: ["finalPrice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "couponsOnHold",
                title: t("management.orders.fields.couponsOnHold"),
                key: "management.orders.fields.couponsOnHold",
                dataIndex: ["couponsOnHold"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "coupons",
                title: t("management.orders.fields.coupons"),
                key: "management.orders.fields.coupons",
                dataIndex: ["coupons"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "appliedCoupons",
                title: t("management.orders.fields.appliedCoupons"),
                key: "management.orders.fields.appliedCoupons",
                dataIndex: ["appliedCoupons"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "createdAt",
                title: t("management.orders.fields.createdAt"),
                key: "management.orders.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.orders.fields.updatedAt"),
                key: "management.orders.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "items",
                title: t("management.orders.fields.items"),
                key: "management.orders.fields.items",
                dataIndex: ["items"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDER_ITEMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "productSnapshot.name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyer",
                title: t("management.orders.fields.buyer"),
                key: "management.orders.fields.buyer",
                dataIndex: ["buyer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "invoice",
                title: t("management.orders.fields.invoice"),
                key: "management.orders.fields.invoice",
                dataIndex: ["invoice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INVOICES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "invoiceId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "payment",
                title: t("management.orders.fields.payment"),
                key: "management.orders.fields.payment",
                dataIndex: ["payment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "paymentProcessor",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesAgent",
                title: t("management.orders.fields.salesAgent"),
                key: "management.orders.fields.salesAgent",
                dataIndex: ["salesAgent"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INTERNAL_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paymentOption",
                title: t("management.orders.fields.paymentOption"),
                key: "management.orders.fields.paymentOption",
                dataIndex: ["paymentOption"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENT_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingOption",
                title: t("management.orders.fields.shippingOption"),
                key: "management.orders.fields.shippingOption",
                dataIndex: ["shippingOption"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SHIPPING_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.orders.fields.createdBy"),
                key: "management.orders.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.orders.fields.updatedBy"),
                key: "management.orders.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            items: "items.productSnapshot.name",
            buyer: "buyer.fullName",
            invoice: "invoice.invoiceId",
            payment: "payment.paymentProcessor",
            salesAgent: "salesAgent.fullName",
            paymentOption: "paymentOption.name",
            shippingOption: "shippingOption.name",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            orderNumber: 1,
            placedAt: 1,
            status: 1,
            billingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            salesRayId: 1,
            shippingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            buyerSnapshot: {
                name: 1,
                phoneNumber: 1,
                email: 1,
            },
            privateNotes: 1,
            price: 1,
            priceVAT: 1,
            priceTotal: 1,
            finalPrice: 1,
            couponsOnHold: 1,
            coupons: {
                couponCode: 1,
                couponId: 1,
                amount: 1,
                isPercentage: 1,
            },
            appliedCoupons: {
                couponCode: 1,
                couponId: 1,
                amount: 1,
                isPercentage: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            items: {
                _id: 1,
                productSnapshot: {
                    name: 1,
                },
            },
            buyer: {
                _id: 1,
                fullName: 1,
            },
            buyerId: 1,
            invoice: {
                _id: 1,
                invoiceId: 1,
            },
            invoiceId: 1,
            payment: {
                _id: 1,
                paymentProcessor: 1,
            },
            paymentId: 1,
            salesAgent: {
                _id: 1,
                fullName: 1,
            },
            salesAgentId: 1,
            paymentOption: {
                _id: 1,
                name: 1,
            },
            paymentOptionId: 1,
            shippingOption: {
                _id: 1,
                name: 1,
            },
            shippingOptionId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
OrderList = __decorate([
    Service({ transient: true })
], OrderList);
export { OrderList };

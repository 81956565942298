import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { SettingsLayout, Notifications as NotificationsComponent, } from "@bundles/UIAppBundle/components/Settings";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { notificationsAccessRoles } from "../../consts";
const Notifications = () => {
    return (_jsxs(ProtectedRoute, Object.assign({ roles: notificationsAccessRoles }, { children: [_jsx(Seo, { data: seoData.setting }, void 0), _jsx(SettingsLayout, { children: _jsx(NotificationsComponent, {}, void 0) }, void 0)] }), void 0));
};
export default Notifications;

import { gql } from "@apollo/client";
export const GET_ORDERS = gql `
  query AdminOrdersGet($input: AdminOrdersGetInput) {
    AdminOrdersGet(input: $input) {
      data {
        _id
        status
        orderNumber
        paymentOption {
          _id
          name
          type
        }
        finalPrice
        placedAt
        buyerId
        priceTotal
        buyer {
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
        buyerSnapshot {
          name
        }
        invoiceId
        items {
          price {
            currency
          }
        }
      }
      skip
      limit
      total
    }
  }
`;
export const GET_PAYMENT_OPTOINS = gql `
  query AdminGetPaymentOptions {
    AdminGetPaymentOptions {
      _id
      type
      name
    }
  }
`;

import { jsx as _jsx } from "react/jsx-runtime";
import { ObjectId } from "@bluelibs/ejson";
import { Product3DModelCollection, } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { KebabMenu, View } from "@bundles/UIAppBundle/icons";
import { features } from "../../config/features";
import { Product3DModelList } from "../../config/Product3DModelList";
export class Product3DModelAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = Product3DModelCollection;
    }
    getBody() {
        return Product3DModelList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(Product3DModelList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return Product3DModelList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.PRODUCT_3_D_MODEL_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        this.count(true);
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["endUserProfileId"]) {
            modifiedFiltersObj["endUserProfileId"]["$in"] = modifiedFiltersObj["endUserProfileId"]["$in"].map((val) => {
                return new ObjectId(val);
            });
        }
        if (modifiedFiltersObj["productVariantId"]) {
            modifiedFiltersObj["productVariantId"]["$in"] = modifiedFiltersObj["productVariantId"]["$in"].map((val) => {
                return new ObjectId(val);
            });
        }
        console.log(modifiedFiltersObj);
        this.collection
            .find({
            filters: {
                ...this.state.filters,
                ...modifiedFiltersObj,
                ...this.alwaysOnFilters,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((documents) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

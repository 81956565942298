import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { Button } from "@bundles/UIAppBundle/components";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import styles from "./EndUserProfilesEdit.module.scss";
import { useEndUserProfileEdit } from "./hooks";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { clientsAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { scrollToFirstFormFieldError } from "@bundles/UIAppBundle/utils/helpers";
export function EndUserProfilesEdit(props) {
    const { onUpdateClient, updateIsLoading, form, document, isLoading, error, UIComponents, t, initialValues, AvatarUpload, } = useEndUserProfileEdit(props.id);
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: clientsAccessRoles }, { children: [_jsx(Seo, { data: seoData.endUserProfile.edit }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsx(Ant.PageHeader, { title: t("management.end_user_profiles.edit.header"), onBack: () => window.history.back(), backIcon: _jsx(BackCircle, {}, void 0), extra: _jsx(Button, Object.assign({ btnStyle: "primary", form: "EndUserProfileEditForm", loading: updateIsLoading }, { children: "Save changes" }), void 0), className: styles["page-header"] }, void 0), _jsxs("div", Object.assign({ className: styles.container }, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsx(Ant.Form, Object.assign({ name: "EndUserProfileEditForm", labelCol: { span: 0 }, requiredMark: "optional", initialValues: initialValues, onFinish: onUpdateClient, scrollToFirstError: scrollToFirstFormFieldError }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(Card, Object.assign({ title: "PROFILE", className: styles["info-card"] }, { children: _jsxs(Ant.Col, Object.assign({ span: 16 }, { children: [_jsx(Ant.Row, Object.assign({ justify: "start" }, { children: _jsx(AvatarUpload, {}, void 0) }), void 0), _jsx(Ant.Row, Object.assign({ justify: "space-between", gutter: 18 }, { children: form.render([
                                                                form.findElement("firstName"),
                                                                form.findElement("lastName"),
                                                            ]) }), void 0), _jsx(Ant.Row, Object.assign({ justify: "space-between", gutter: 18 }, { children: form.render([
                                                                form.findElement("email"),
                                                                form.findElement("phoneNumber"),
                                                            ]) }), void 0)] }), void 0) }), void 0), _jsx(Card, Object.assign({ title: "BILLING DETAILS", className: styles["info-card"] }, { children: _jsx(Ant.Col, Object.assign({ span: 16 }, { children: _jsx(Ant.Row, Object.assign({ justify: "space-between", gutter: 18 }, { children: form.render([form.findElement("billingAddress")]) }), void 0) }), void 0) }), void 0), _jsx(Card, Object.assign({ title: "SHIPPING", className: styles["info-card"] }, { children: _jsx(Ant.Col, Object.assign({ span: 16 }, { children: _jsx(Ant.Row, Object.assign({ justify: "space-between", gutter: 18 }, { children: form.render([form.findElement("savedAddresses")]) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0))] }), void 0)] }), void 0) }, void 0)] }), void 0));
}

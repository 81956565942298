import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { CheckOutlined } from "@ant-design/icons";
import { use, useDataOne, useTranslate } from "@bluelibs/x-ui";
import { ShippingOptionEditForm } from "../../config/ShippingOptionEditForm";
import { ShippingOptionsCollection, } from "@bundles/UIAppBundle/collections";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import styles from "../Create/ShippingOptionsCreate.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ShippingOptionsEdit(props) {
    const t = useTranslate();
    const form = use(ShippingOptionEditForm, { transient: true });
    const { data: document, isLoading, error, } = useDataOne(ShippingOptionsCollection, new ObjectId(props.id), ShippingOptionEditForm.getRequestBody());
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN] }, { children: [_jsx(Seo, { data: {
                    ...seoData.shippingOption.edit,
                    title: `${document === null || document === void 0 ? void 0 : document.name} | Edit Shipping provider`,
                    description: document === null || document === void 0 ? void 0 : document.description,
                } }, void 0), _jsxs(ShopConfigurationLayout, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsx(Card, Object.assign({ title: t("management.shop_settings.submenus.shipping_options").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => form.onSubmit(props.id, document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"] }, { children: _jsxs("span", { children: [_jsx(CheckOutlined, { style: { marginRight: "8px" } }, void 0), t("generics.save_changes")] }, void 0) }), void 0)] }), void 0) }), void 0))] }, void 0)] }), void 0));
}

import { ShopShippingAddressList } from "../components/List/ShopShippingAddressList";
import { ShopShippingAddressCreate } from "../components/Create/ShopShippingAddressCreate";
import { ShopShippingAddressEdit } from "../components/Edit/ShopShippingAddressEdit";
import { ShopShippingAddressView } from "../components/View/ShopShippingAddressView";
import { SettingFilled } from "@ant-design/icons";
export const SHOP_SHIPPING_ADDRESS_LIST = {
    path: "/admin/shop-shipping-address",
    component: ShopShippingAddressList,
    menu: {
        key: "SHOP_SHIPPING_ADDRESS_LIST",
        label: "management.shop_shipping_address.menu.title",
        icon: SettingFilled,
    },
};
export const SHOP_SHIPPING_ADDRESS_CREATE = {
    path: "/admin/shop-shipping-address/create",
    component: ShopShippingAddressCreate,
};
export const SHOP_SHIPPING_ADDRESS_EDIT = {
    path: "/admin/shop-shipping-address/:id/edit",
    component: ShopShippingAddressEdit,
};
export const SHOP_SHIPPING_ADDRESS_VIEW = {
    path: "/admin/shop-shipping-address/:id/view",
    component: ShopShippingAddressView,
};

import "./i18n";
import { SHOP_SHIPPING_ADDRESS_LIST as BASE_SHOP_SHIPPING_ADDRESS_LIST, SHOP_SHIPPING_ADDRESS_CREATE as BASE_SHOP_SHIPPING_ADDRESS_CREATE, SHOP_SHIPPING_ADDRESS_EDIT as BASE_SHOP_SHIPPING_ADDRESS_EDIT, SHOP_SHIPPING_ADDRESS_VIEW as BASE_SHOP_SHIPPING_ADDRESS_VIEW, } from "./config/routes";
export const SHOP_SHIPPING_ADDRESS_LIST = {
    ...BASE_SHOP_SHIPPING_ADDRESS_LIST,
};
export const SHOP_SHIPPING_ADDRESS_CREATE = {
    ...BASE_SHOP_SHIPPING_ADDRESS_CREATE,
    path: "/shop-settings/general/shipping-address/create",
};
export const SHOP_SHIPPING_ADDRESS_EDIT = {
    ...BASE_SHOP_SHIPPING_ADDRESS_EDIT,
    path: "/shop-settings/general/shipping-address/:id/edit",
};
export const SHOP_SHIPPING_ADDRESS_VIEW = {
    ...BASE_SHOP_SHIPPING_ADDRESS_VIEW,
};

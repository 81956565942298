var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { Product3DModelCollection, Product3DImageCollection, } from "@bundles/UIAppBundle/collections";
let Product3DImageCreateForm = class Product3DImageCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "size",
                label: t("management.product_3_d_image.fields.size"),
                name: ["size"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "position",
                label: t("management.product_3_d_image.fields.position"),
                name: ["position"],
                required: true,
                nest: [
                    {
                        id: "x",
                        label: t("management.product_3_d_image.fields.position.x"),
                        name: ["position", "x"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "y",
                        label: t("management.product_3_d_image.fields.position.y"),
                        name: ["position", "y"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "z",
                        label: t("management.product_3_d_image.fields.position.z"),
                        name: ["position", "z"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "rotation",
                label: t("management.product_3_d_image.fields.rotation"),
                name: ["rotation"],
                required: true,
                nest: [
                    {
                        id: "x",
                        label: t("management.product_3_d_image.fields.rotation.x"),
                        name: ["rotation", "x"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "y",
                        label: t("management.product_3_d_image.fields.rotation.y"),
                        name: ["rotation", "y"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "z",
                        label: t("management.product_3_d_image.fields.rotation.z"),
                        name: ["rotation", "z"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "nodeName",
                label: t("management.product_3_d_image.fields.nodeName"),
                name: ["nodeName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "imageId",
                label: t("management.product_3_d_image.fields.image"),
                name: ["imageId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "productModelId",
                label: t("management.product_3_d_image.fields.productModel"),
                name: ["productModelId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: Product3DModelCollection, field: "name", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_3_d_image.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.PRODUCT_3_D_IMAGE_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PRODUCT_3_D_IMAGE_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PRODUCT_3_D_IMAGE_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => Product3DImageCollection),
    __metadata("design:type", typeof (_a = typeof Product3DImageCollection !== "undefined" && Product3DImageCollection) === "function" ? _a : Object)
], Product3DImageCreateForm.prototype, "collection", void 0);
Product3DImageCreateForm = __decorate([
    Service({ transient: true })
], Product3DImageCreateForm);
export { Product3DImageCreateForm };

import { use, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation } from "@apollo/client";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { AddProductCategoryMutation } from "./mutations";
import { updateFields } from "./helpers/extraFieldsUpdates";
import { ProductCategoryCreateForm } from "../../config/ProductCategoryCreateForm";
export const useProductCategoryCreate = (id) => {
    const t = useTranslate();
    const router = useRouter();
    const form = use(ProductCategoryCreateForm, { transient: true });
    const [formInstance] = Ant.Form.useForm();
    const [addProductCategory, { loading: mutationLoading }] = useMutation(AddProductCategoryMutation);
    form.build();
    updateFields(formInstance, form);
    const onSubmit = async (input) => {
        var _a;
        const attributeOptions = input.attributeOptions || [];
        input.isRoot = !id;
        if (id) {
            input.parentId = id;
        }
        try {
            await addProductCategory({
                variables: {
                    input: {
                        ...input,
                        image: (_a = input.image) === null || _a === void 0 ? void 0 : _a.originFileObj,
                        attributeOptions,
                    },
                },
            });
            Ant.notification.success({
                message: t(`management.product_categories.create_${id ? "sub" : ""}category_confirmation`),
            });
            router.go(Routes.PRODUCT_CATEGORIES_LIST);
        }
        catch (err) {
            Ant.notification.error({
                message: t("generics.error"),
                description: err.message || err.toString(),
            });
        }
    };
    return {
        form,
        formInstance,
        onSubmit,
        mutationLoading,
    };
};

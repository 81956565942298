var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProductTypes } from "@root/api.types";
import { ProductCategoriesCollection, VatRatesCollection, ProductsCollection, } from "@bundles/UIAppBundle/collections";
let ProductCreateForm = class ProductCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "discountActiveUntil",
                label: t("management.products.fields.discountActiveUntil"),
                name: ["discountActiveUntil"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: false }, void 0) }), void 0)),
            },
            {
                id: "defaultDiscountPercentage",
                label: t("management.products.fields.defaultDiscountPercentage"),
                name: ["defaultDiscountPercentage"],
                component: Ant.InputNumber,
            },
            {
                id: "defaultNetPrice",
                label: t("management.products.fields.defaultNetPrice"),
                name: ["defaultNetPrice"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "hasVariants",
                label: t("management.products.fields.hasVariants"),
                name: ["hasVariants"],
                initialValue: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "country",
                label: t("management.products.fields.country"),
                name: ["country"],
                component: Ant.Input,
            },
            {
                id: "height",
                label: t("management.products.fields.height"),
                name: ["height"],
                component: Ant.Input,
            },
            {
                id: "width",
                label: t("management.products.fields.width"),
                name: ["width"],
                component: Ant.Input,
            },
            {
                id: "length",
                label: t("management.products.fields.length"),
                name: ["length"],
                component: Ant.Input,
            },
            {
                id: "weight",
                label: t("management.products.fields.weight"),
                name: ["weight"],
                component: Ant.Input,
            },
            {
                id: "type",
                label: t("management.products.fields.type"),
                name: ["type"],
                initialValue: ProductTypes.PHYSICAL,
                render: (props) => {
                    const options = [
                        { value: "DIGITAL", label: "Digital" },
                        { value: "PHYSICAL", label: "Physical" },
                        { value: "SERVICE", label: "Service" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.products.fields.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.products.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.products.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "brand",
                label: t("management.products.fields.brand"),
                name: ["brand"],
                component: Ant.Input,
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                name: ["tags"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "availableStockCount",
                label: t("management.products.fields.availableStockCount"),
                name: ["availableStockCount"],
                component: Ant.InputNumber,
            },
            {
                id: "attributeOptions",
                label: t("management.products.fields.attributeOptions"),
                name: ["attributeOptions"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.products.fields.attributeOptions.name"),
                        name: ["attributeOptions", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "type",
                        label: t("management.products.fields.attributeOptions.type"),
                        name: ["attributeOptions", "type"],
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "string", label: "String" },
                                { value: "number", label: "Number" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.products.fields.attributeOptions.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.products.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.products.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "isRequired",
                        label: t("management.products.fields.attributeOptions.isRequired"),
                        name: ["attributeOptions", "isRequired"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "isEnum",
                        label: t("management.products.fields.attributeOptions.isEnum"),
                        name: ["attributeOptions", "isEnum"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "enumValues",
                        label: t("management.products.fields.attributeOptions.enumValues"),
                        name: ["attributeOptions", "enumValues"],
                        initialValue: [],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "isRange",
                        label: t("management.products.fields.attributeOptions.isRange"),
                        name: ["attributeOptions", "isRange"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "defaultValue",
                        label: t("management.products.fields.attributeOptions.defaultValue"),
                        name: ["attributeOptions", "defaultValue"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "defaultSuffix",
                        label: t("management.products.fields.attributeOptions.defaultSuffix"),
                        name: ["attributeOptions", "defaultSuffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "rangeMin",
                        label: t("management.products.fields.attributeOptions.rangeMin"),
                        name: ["attributeOptions", "rangeMin"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "rangeMax",
                        label: t("management.products.fields.attributeOptions.rangeMax"),
                        name: ["attributeOptions", "rangeMax"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
                initialValue: [],
            },
            {
                id: "slug",
                label: t("management.products.fields.slug"),
                name: ["slug"],
                component: Ant.Input,
            },
            {
                id: "seoFields",
                label: t("management.products.fields.seoFields"),
                name: ["seoFields"],
                nest: [
                    {
                        id: "pageTitle",
                        label: t("management.products.fields.seoFields.pageTitle"),
                        name: ["seoFields", "pageTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "metaDescription",
                        label: t("management.products.fields.seoFields.metaDescription"),
                        name: ["seoFields", "metaDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "keywords",
                        label: t("management.products.fields.seoFields.keywords"),
                        name: ["seoFields", "keywords"],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "seoTitle",
                        label: t("management.products.fields.seoFields.seoTitle"),
                        name: ["seoFields", "seoTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "seoDescription",
                        label: t("management.products.fields.seoFields.seoDescription"),
                        name: ["seoFields", "seoDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "slug",
                        label: t("management.products.fields.seoFields.slug"),
                        name: ["seoFields", "slug"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "params",
                label: t("management.products.fields.params"),
                name: ["params"],
                nest: [],
            },
            {
                id: "description",
                label: t("management.products.fields.description"),
                name: ["description"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "name",
                label: t("management.products.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "salesRayId",
                label: t("management.products.fields.salesRayId"),
                name: ["salesRayId"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "defaultImageId",
                label: t("management.products.fields.defaultImage"),
                name: ["defaultImageId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "imagesId",
                label: t("management.products.fields.images"),
                name: ["imagesId"],
                component: UIComponents.AdminFileGroupUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "categoryId",
                label: t("management.products.fields.category"),
                name: ["categoryId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "vatRateId",
                label: t("management.products.fields.vatRate"),
                name: ["vatRateId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: VatRatesCollection, field: "name", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.products.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.PRODUCTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PRODUCTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PRODUCTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProductsCollection),
    __metadata("design:type", typeof (_a = typeof ProductsCollection !== "undefined" && ProductsCollection) === "function" ? _a : Object)
], ProductCreateForm.prototype, "collection", void 0);
ProductCreateForm = __decorate([
    Service({ transient: true })
], ProductCreateForm);
export { ProductCreateForm };

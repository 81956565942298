var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let InternalUserProfileList = class InternalUserProfileList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "createdAt",
                title: t("management.internal_user_profiles.fields.createdAt"),
                key: "management.internal_user_profiles.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.internal_user_profiles.fields.updatedAt"),
                key: "management.internal_user_profiles.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "firstName",
                title: t("management.internal_user_profiles.fields.firstName"),
                key: "management.internal_user_profiles.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "middleName",
                title: t("management.internal_user_profiles.fields.middleName"),
                key: "management.internal_user_profiles.fields.middleName",
                dataIndex: ["middleName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "lastName",
                title: t("management.internal_user_profiles.fields.lastName"),
                key: "management.internal_user_profiles.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                title: t("management.internal_user_profiles.fields.phoneNumber"),
                key: "management.internal_user_profiles.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                title: t("management.internal_user_profiles.fields.email"),
                key: "management.internal_user_profiles.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fullName",
                title: t("management.internal_user_profiles.fields.fullName"),
                key: "management.internal_user_profiles.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.orderPlaced",
                title: t("management.internal_user_profiles.fields.activeNotifications.orderPlaced"),
                key: "management.internal_user_profiles.fields.activeNotifications.orderPlaced",
                dataIndex: ["activeNotifications", "orderPlaced"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.orderStatusChanged",
                title: t("management.internal_user_profiles.fields.activeNotifications.orderStatusChanged"),
                key: "management.internal_user_profiles.fields.activeNotifications.orderStatusChanged",
                dataIndex: ["activeNotifications", "orderStatusChanged"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.returnCreated",
                title: t("management.internal_user_profiles.fields.activeNotifications.returnCreated"),
                key: "management.internal_user_profiles.fields.activeNotifications.returnCreated",
                dataIndex: ["activeNotifications", "returnCreated"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.returnStatusChanged",
                title: t("management.internal_user_profiles.fields.activeNotifications.returnStatusChanged"),
                key: "management.internal_user_profiles.fields.activeNotifications.returnStatusChanged",
                dataIndex: ["activeNotifications", "returnStatusChanged"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.clientCreated",
                title: t("management.internal_user_profiles.fields.activeNotifications.clientCreated"),
                key: "management.internal_user_profiles.fields.activeNotifications.clientCreated",
                dataIndex: ["activeNotifications", "clientCreated"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.invoiceCreated",
                title: t("management.internal_user_profiles.fields.activeNotifications.invoiceCreated"),
                key: "management.internal_user_profiles.fields.activeNotifications.invoiceCreated",
                dataIndex: ["activeNotifications", "invoiceCreated"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.productOutOfStock",
                title: t("management.internal_user_profiles.fields.activeNotifications.productOutOfStock"),
                key: "management.internal_user_profiles.fields.activeNotifications.productOutOfStock",
                dataIndex: ["activeNotifications", "productOutOfStock"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "activeNotifications.productPromotionExpire",
                title: t("management.internal_user_profiles.fields.activeNotifications.productPromotionExpire"),
                key: "management.internal_user_profiles.fields.activeNotifications.productPromotionExpire",
                dataIndex: ["activeNotifications", "productPromotionExpire"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "user",
                title: t("management.internal_user_profiles.fields.user"),
                key: "management.internal_user_profiles.fields.user",
                dataIndex: ["user"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orders",
                title: t("management.internal_user_profiles.fields.orders"),
                key: "management.internal_user_profiles.fields.orders",
                dataIndex: ["orders"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "orderNumber",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "clients",
                title: t("management.internal_user_profiles.fields.clients"),
                key: "management.internal_user_profiles.fields.clients",
                dataIndex: ["clients"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "notifications",
                title: t("management.internal_user_profiles.fields.notifications"),
                key: "management.internal_user_profiles.fields.notifications",
                dataIndex: ["notifications"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.NOTIFICATION_SUBSCRIBERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.internal_user_profiles.fields.createdBy"),
                key: "management.internal_user_profiles.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.internal_user_profiles.fields.updatedBy"),
                key: "management.internal_user_profiles.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            user: "user.fullName",
            orders: "orders.orderNumber",
            clients: "clients.fullName",
            notifications: "notifications._id",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            createdAt: 1,
            updatedAt: 1,
            firstName: 1,
            middleName: 1,
            lastName: 1,
            phoneNumber: 1,
            email: 1,
            fullName: 1,
            activeNotifications: {
                orderPlaced: 1,
                orderStatusChanged: 1,
                returnCreated: 1,
                returnStatusChanged: 1,
                clientCreated: 1,
                invoiceCreated: 1,
                productOutOfStock: 1,
                productPromotionExpire: 1,
            },
            user: {
                _id: 1,
                fullName: 1,
            },
            userId: 1,
            orders: {
                _id: 1,
                orderNumber: 1,
            },
            clients: {
                _id: 1,
                fullName: 1,
            },
            notifications: {
                _id: 1,
            },
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
InternalUserProfileList = __decorate([
    Service({ transient: true })
], InternalUserProfileList);
export { InternalUserProfileList };

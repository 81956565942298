import { gql } from "@apollo/client";
export const ADMIN_POST_CREATE = gql `
  mutation AdminPostCreate($input: AdminPostCreateInput!) {
    AdminPostCreate(input: $input) {
      _id
      body
      createdAt
      heroImage {
        _id
        downloadUrl
        mimeType
        name
        path
        resourceId
        resourceType
        size
        thumbs {
          downloadUrl
          path
          type
        }
      }
      heroImageId
      slug
      subtitle
      title
      createdAt
      updatedAt
      visibility
    }
  }
`;
export const ADMIN_POST_UPDATE = gql `
  mutation AdminPostUpdate($input: AdminPostUpdateInput!) {
    AdminPostUpdate(input: $input) {
      _id
      body
      createdAt
      heroImage {
        _id
        downloadUrl
        mimeType
        name
        path
        resourceId
        resourceType
        size
        thumbs {
          downloadUrl
          path
          type
        }
      }
      heroImageId
      slug
      subtitle
      title
      createdAt
      updatedAt
      visibility
    }
  }
`;

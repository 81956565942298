import { gql } from "@apollo/client";
export const AdminGetShippingOptions = gql `
  query AdminGetShippingOptions {
    AdminGetShippingOptions {
      _id
      description
      name
      price
      type
    }
  }
`;

import { Collection, } from "@bluelibs/x-ui";
import { ProductVariant } from "@root/api.types";
import { AppFilesCollection, AppFileGroupsCollection, } from "@bluelibs/x-ui-admin";
import { ProductsCollection, ProductCategoriesCollection, InternalUserProfilesCollection, ReviewsCollection, VatRatesCollection, Product3DModelCollection, UnitsOfMeasurementCollection, } from "@bundles/UIAppBundle/collections";
export class ProductVariantsCollection extends Collection {
    getName() {
        return "ProductVariants";
    }
    getInputs() {
        return {
            insert: "ProductVariantInsertInput!",
            update: "ProductVariantUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "model3D",
                field: "model3DId",
            },
            {
                collection: () => AppFilesCollection,
                name: "defaultImage",
                field: "defaultImageId",
            },
            {
                collection: () => AppFileGroupsCollection,
                name: "images",
                field: "imagesId",
            },
            {
                collection: () => ProductsCollection,
                name: "product",
                field: "productId",
            },
            {
                collection: () => ProductCategoriesCollection,
                name: "category",
                field: "categoryId",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "publishedBy",
                field: "publishedById",
            },
            {
                collection: () => VatRatesCollection,
                name: "vatRate",
                field: "vatRateId",
            },
            {
                collection: () => UnitsOfMeasurementCollection,
                name: "unitOfMeasurement",
                field: "unitOfMeasurementId",
            },
            {
                collection: () => ReviewsCollection,
                name: "reviews",
            },
            {
                collection: () => Product3DModelCollection,
                name: "models",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            publishedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            discountActiveUntil: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import "./i18n";
import { PRODUCT_VARIANT_PRICE_LISTS_LIST as BASE_PRODUCT_VARIANT_PRICE_LISTS_LIST, PRODUCT_VARIANT_PRICE_LISTS_CREATE as BASE_PRODUCT_VARIANT_PRICE_LISTS_CREATE, PRODUCT_VARIANT_PRICE_LISTS_EDIT as BASE_PRODUCT_VARIANT_PRICE_LISTS_EDIT, PRODUCT_VARIANT_PRICE_LISTS_VIEW as BASE_PRODUCT_VARIANT_PRICE_LISTS_VIEW, } from "./config/routes";
export const PRODUCT_VARIANT_PRICE_LISTS_LIST = {
    ...BASE_PRODUCT_VARIANT_PRICE_LISTS_LIST,
};
export const PRODUCT_VARIANT_PRICE_LISTS_CREATE = {
    ...BASE_PRODUCT_VARIANT_PRICE_LISTS_CREATE,
};
export const PRODUCT_VARIANT_PRICE_LISTS_EDIT = {
    ...BASE_PRODUCT_VARIANT_PRICE_LISTS_EDIT,
};
export const PRODUCT_VARIANT_PRICE_LISTS_VIEW = {
    ...BASE_PRODUCT_VARIANT_PRICE_LISTS_VIEW,
};

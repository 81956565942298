import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Components, I18NService, use } from "@bluelibs/x-ui";
import { Button, Tag } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { ObjectId } from "@bluelibs/ejson";
import _, { startCase } from "lodash";
const emptyValue = _jsx("span", { children: "N/A" }, void 0);
// eslint-disable-next-line react/prop-types
export function DownloadButton({ name, downloadUrl }) {
    return (_jsx(Button, Object.assign({ href: downloadUrl, target: "_blank", icon: _jsx(DownloadOutlined, {}, void 0) }, { children: name }), void 0));
}
/**
 * @param props
 */
export function AdminListItemRenderer(props) {
    let value;
    const i18n = use(I18NService);
    if (props.type === "string" || props.type === "number") {
        value = _jsx("span", Object.assign({ className: props.className }, { children: props.value }), void 0);
    }
    if (props.type === "objectId") {
        if (props.value instanceof ObjectId) {
            value = _jsx("span", { children: props.value.toString() }, void 0);
        }
        else {
            value = _jsx("span", { children: props.value }, void 0);
        }
    }
    if (props.type === "date") {
        const local = i18n === null || i18n === void 0 ? void 0 : i18n.getCurrentPolyglot();
        const date = props.value instanceof Date
            ? props.value
            : new Date(props.value);
        value = (_jsx("time", Object.assign({ dateTime: date.toISOString() }, { children: date.toLocaleDateString(local) }), void 0));
    }
    if (props.type === "tag") {
        value = _jsx(Tag, Object.assign({ color: "cyan" }, { children: props.value }), void 0);
    }
    if (props.type === "enum") {
        value = (_jsx(Tag, Object.assign({ color: "cyan" }, { children: props.labelify ? startCase(props.value) : props.value }), void 0));
    }
    if (props.type === "boolean") {
        value = _jsx(Tag, Object.assign({ color: "blue" }, { children: props.value ? "Yes" : "No" }), void 0);
    }
    if (props.type === "file") {
        if (Array.isArray(props.value)) {
            value = (_jsx(_Fragment, { children: props.value.map((file, idx) => (_createElement(DownloadButton, { ...file, key: idx }))) }, void 0));
        }
        else {
            if (props.value) {
                value = _jsx(DownloadButton, Object.assign({}, props.value, { name: null }), void 0);
            }
        }
    }
    if (props.type === "fileGroup") {
        if (!props.value) {
            value = _jsx("span", { children: "N/A" }, void 0);
        }
        else {
            value = (_jsx(_Fragment, { children: props.value.files.map((file, idx) => (_createElement(DownloadButton, { ...file, key: idx }))) }, void 0));
        }
    }
    if (props.type === "relation") {
        if (!props.value) {
            return _jsx(Tag, { children: emptyValue }, void 0);
        }
        value = (_jsx(Link, Object.assign({ to: props.relation.path }, { children: _jsx(Tag, { children: _.get(props.value, props.relation.dataIndex.split("."), emptyValue) }, void 0) }), void 0));
    }
    if (props.type === "object") {
        value = _jsx("pre", { children: JSON.stringify(props.value, null, 4) }, void 0);
    }
    if (value === undefined || value === null) {
        return emptyValue;
    }
    return _jsx(Components.ErrorBoundary, { children: value }, void 0);
}

import "./i18n";
import { PRODUCT_3_D_MODEL_LIST as BASE_PRODUCT_3_D_MODEL_LIST, PRODUCT_3_D_MODEL_CREATE as BASE_PRODUCT_3_D_MODEL_CREATE, PRODUCT_3_D_MODEL_EDIT as BASE_PRODUCT_3_D_MODEL_EDIT, PRODUCT_3_D_MODEL_VIEW as BASE_PRODUCT_3_D_MODEL_VIEW, } from "./config/routes";
export const PRODUCT_3_D_MODEL_LIST = {
    ...BASE_PRODUCT_3_D_MODEL_LIST,
    path: "/product-3-d-models",
};
export const PRODUCT_3_D_MODEL_CREATE = {
    ...BASE_PRODUCT_3_D_MODEL_CREATE,
};
export const PRODUCT_3_D_MODEL_EDIT = {
    ...BASE_PRODUCT_3_D_MODEL_EDIT,
};
export const PRODUCT_3_D_MODEL_VIEW = {
    ...BASE_PRODUCT_3_D_MODEL_VIEW,
    path: "/product-3-d-models/:id/view",
};

import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ENDUSER_ADDRESS } from "./query";
export const useOrderAddressDetails = (stepForm, editable) => {
    var _a, _b, _c, _d, _e, _f;
    const formData = stepForm.getFieldsValue(true);
    const [shippingAddress, setShippingAddress] = useState(formData.shippingAddress || {});
    const [billingAddress, setBillingAddress] = useState({
        firstName: "",
        lastName: "",
        streetAddressLine1: "",
        streetAddressLine2: "",
        city: "",
        country: "",
        zipcode: "",
    });
    const { buyerInfo } = formData;
    if (!editable) {
        return {
            avatar: (_c = (_b = (_a = buyerInfo.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.path,
            shippingAddress: formData.shippingAddress,
            billingAddress: formData.billingAddress,
        };
    }
    const { loading, data } = useQuery(GET_ENDUSER_ADDRESS, {
        variables: {
            input: {
                endUserProfileId: buyerInfo._id,
            },
        },
    });
    const [shippingAddressOption, setShippingAddressOption] = useState([]);
    useEffect(() => {
        if (!loading && data) {
            //Setting billing address to form and state
            setBillingAddress(data.AdminEndUserProfileGet.billingAddress);
            stepForm.setFieldsValue({
                billingAddress: data.AdminEndUserProfileGet.billingAddress,
            });
            setShippingAddressOption(data.AdminEndUserProfileGet.savedAddresses.map((option) => {
                return {
                    label: option.address,
                    value: option._id,
                };
            }));
        }
    }, [data]);
    const onDropdownChange = (value) => {
        let selectedShippingAddress = data === null || data === void 0 ? void 0 : data.AdminEndUserProfileGet.savedAddresses.filter((shippingAddress) => shippingAddress._id === value)[0];
        if (selectedShippingAddress) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, _id, ...rest } = selectedShippingAddress;
            selectedShippingAddress = rest;
            setShippingAddress(selectedShippingAddress);
            stepForm.setFieldsValue({
                shippingAddress: selectedShippingAddress,
            });
        }
    };
    return {
        avatar: (_f = (_e = (_d = buyerInfo.user) === null || _d === void 0 ? void 0 : _d.avatar) === null || _e === void 0 ? void 0 : _e.file) === null || _f === void 0 ? void 0 : _f.downloadUrl,
        billingAddress,
        shippingAddress,
        setBillingAddress,
        setShippingAddress,
        shippingAddressOption,
        onDropdownChange,
    };
};

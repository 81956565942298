import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { Toggle } from "@bundles/UIAppBundle/components/Toggle";
import { ConfirmationPopup } from "../../ConfirmationPopup";
import styles from "../SettingsSection.module.scss";
import { useOrdersReview } from "./hooks";
import SettingsLoader from "@bundles/UIAppBundle/components/Settings/Layout/SettingsLoader";
const OrdersReview = () => {
    const t = useTranslate("management.settings.reviews.orders");
    const { loading, activateReviewsStorefront, isConfirmationOpen, setIsConfirmationOpen, handleActivateReviews, } = useOrdersReview();
    return (_jsx("div", Object.assign({ className: styles["section-container"] }, { children: loading ? (_jsx(SettingsLoader, {}, void 0)) : (_jsxs(_Fragment, { children: [_jsx("h2", Object.assign({ className: styles.title }, { children: t("title") }), void 0), _jsxs("div", Object.assign({ className: styles["settings-container"] }, { children: [_jsxs(Card, Object.assign({ className: styles["setting-container"] }, { children: [_jsx("p", Object.assign({ className: styles.content }, { children: t("settings.activateReviewsStorefront") }), void 0), _jsx(Toggle, { id: "activateReviewsStorefront", checked: activateReviewsStorefront, onChange: () => setIsConfirmationOpen(true) }, void 0)] }), void 0), _jsx(ConfirmationPopup, { title: t("popup.title"), confirm: activateReviewsStorefront
                                ? t("popup.deactivate")
                                : t("popup.activate"), cancel: t("popup.cancel"), isOpen: isConfirmationOpen, body: activateReviewsStorefront
                                ? t("popup.deactivateBody")
                                : t("popup.activateBody"), onCancel: () => setIsConfirmationOpen(false), onConfirm: handleActivateReviews }, void 0)] }), void 0)] }, void 0)) }), void 0));
};
export default OrdersReview;

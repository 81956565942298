var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import { ShippingOptionCreateForm as BaseShippingOptionCreateForm } from "./ShippingOptionCreateForm.base";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { ControlledInput, InputNumber } from "@bundles/UIAppBundle/components";
import { CustomShippingOptionsCollection, ShippingOptionsCollection, } from "@bundles/UIAppBundle/collections";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let ShippingOptionCreateForm = class ShippingOptionCreateForm extends BaseShippingOptionCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("type");
        this.update("name", {
            order: 1,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.name")) }, void 0) }), void 0) }), void 0)),
        });
        this.update("price", {
            order: 2,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { min: 0, label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.price")) }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            order: 3,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.description")) }, void 0) }), void 0) }), void 0)),
        });
    }
    async onSubmit(document) {
        const { t } = this.i18n;
        try {
            await this.collection.insertOne(document);
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_options.create_confirmation"),
            });
            return this.router.go(Routes.SHIPPING_OPTIONS_LIST);
        }
        catch (err) {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        }
    }
};
__decorate([
    Inject(() => CustomShippingOptionsCollection),
    __metadata("design:type", typeof (_a = typeof ShippingOptionsCollection !== "undefined" && ShippingOptionsCollection) === "function" ? _a : Object)
], ShippingOptionCreateForm.prototype, "collection", void 0);
ShippingOptionCreateForm = __decorate([
    Service({ transient: true })
], ShippingOptionCreateForm);
export { ShippingOptionCreateForm };

import { useTranslate } from "@bluelibs/x-ui";
import { 
// MARKETING_BANNERS,
MARKETING_INTEGRATIONS, POSTS_LIST, LOGO, } from "@bundles/UIAppBundle/routes";
export const useMarketingLayout = () => {
    const t = useTranslate("management.marketing.submenus");
    const links = [
        {
            label: t("integrations"),
            route: MARKETING_INTEGRATIONS,
        },
        // {
        //   label: t("banners"),
        //   route: MARKETING_BANNERS,
        // },
        {
            label: t("blog"),
            route: POSTS_LIST,
        },
        {
            label: t("logo"),
            route: LOGO,
        },
    ];
    return {
        links,
    };
};

import { useEffect } from "react";
import { use, useData, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation } from "@apollo/client";
import * as Ant from "antd";
import { ProductCategoriesCollection } from "@bundles/UIAppBundle/collections";
import { ProductCreateForm } from "../../config/ProductCreateForm";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { Routes } from "@bundles/UIAppBundle";
import { categoriesQueryBody } from "./consts";
import { AddProductMutation } from "./mutations";
import { updateFields } from "./helpers/extraFieldsUpdates";
import { removeWhiteSpacesAndSplitWordsByComma } from "@bundles/UIAppBundle/utils/helpers";
export const useProductCreate = () => {
    const t = useTranslate();
    const router = useRouter();
    const form = use(ProductCreateForm, { transient: true });
    const shopSettings = useShopSettings();
    const [formInstance] = Ant.Form.useForm();
    const [addProduct, { loading: mutationLoading }] = useMutation(AddProductMutation);
    form.build();
    useEffect(() => {
        var _a, _b;
        formInstance.setFieldValue("shopAttributes", (_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.attributeOptions) === null || _b === void 0 ? void 0 : _b.map((att) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, ...rest } = att;
            return rest;
        }));
    }, [shopSettings.state]);
    const { data: categories } = useData(ProductCategoriesCollection, {}, categoriesQueryBody);
    updateFields(formInstance, form, categories);
    const getSelectedCategoryInfo = (id) => {
        const category = categories === null || categories === void 0 ? void 0 : categories.find(({ _id }) => _id === id);
        formInstance.setFieldValue("categoryAttributes", category.attributeOptions);
        formInstance.setFieldValue("categoryName", category.name);
        if (category.vatRateId) {
            formInstance.setFieldValue("vatRateId", category.vatRateId.toHexString());
        }
    };
    const onSubmit = async (input) => {
        var _a;
        const attributeOptions = input.attributeOptions || [];
        const attributesValues = [];
        const shopAttributes = input.shopAttributes || [];
        const categoriesAttributes = input.categoryAttributes || [];
        const seoFields = packSeoFieldsAndRemoveThem(input);
        const allAttributes = shopAttributes
            .concat(categoriesAttributes)
            .concat(attributeOptions);
        for (const attribute of allAttributes) {
            if (attribute.isRequired && !attribute.value) {
                return Ant.notification.warn({
                    message: t("generics.error"),
                    description: "Please add all the values on the Attributes",
                });
            }
            attributesValues.push({
                key: attribute.name,
                value: (_a = attribute.value) === null || _a === void 0 ? void 0 : _a.toString(),
                // TODO: suffix, others?
            });
        }
        for (const key in input.attributeOptions) {
            delete input.attributeOptions[key].value;
        }
        delete input.shopAttributes;
        delete input.categoryAttributes;
        const images = input.images || [];
        //TODO: use imagesDescription[id] to access the images description
        delete input.images;
        try {
            const response = await addProduct({
                variables: {
                    input: {
                        ...input,
                        // TODO: any way to do it from the ImagesUpload component while still allowing for the base64 img?
                        images: images.map((image) => image.originFileObj),
                        attributesValues,
                        seoFields,
                    },
                },
            });
            if (input.hasVariants) {
                Ant.notification.success({
                    message: t("generics.success"),
                    description: t("management.products.create_confirmation"),
                });
                return router.go(Routes.PRODUCT_VARIANTS_CREATE, {
                    params: {
                        id: response.data.AdminProductCreate._id,
                    },
                });
            }
            else {
                return router.go(Routes.PRODUCTS_LIST);
            }
        }
        catch (err) {
            Ant.notification.error({
                message: t("generics.error"),
                description: err.message || err.toString(),
            });
        }
        //   addProductVariant({
        //     variables: {
        //       document: {
        //         ...variantDocument,
        //         productId: response.data.AdminProductCreate._id,
        //       },
        //     },
        //   })
        //     .then((resp) => {
        //       Ant.notification.success({
        //         message: t("generics.success"),
        //         description: t("management.product_variants.create_confirmation"),
        //       });
        //       router.go(Routes.PRODUCT_VARIANTS_EDIT, {
        //         params: {
        //           productId: response.data.AdminProductCreate._id,
        //           variantId: resp.data.AdminProductVariantCreate._id,
        //         },
        //       });
        //     })
        //     .catch((error) => {
        //       Ant.notification.warn({
        //         message: t("generics.error"),
        //         description: t("generics.error_message"),
        //       });
        //       console.log(error);
        //     });
        // })
        // .catch((error) => {
        //   Ant.notification.warn({
        //     message: t("generics.error"),
        //     description: t("generics.error_message"),
        //   });
        //   console.log(error);
    };
    const onFormValuesChange = (value, values) => {
        if (value.categoryId)
            getSelectedCategoryInfo(value.categoryId);
        if (value.pageTitle) {
            formInstance.setFieldValue("slug", encodeURIComponent(values.pageTitle.toLowerCase().replaceAll(" ", "-")));
        }
    };
    const packSeoFieldsAndRemoveThem = (input) => {
        const { pageTitle, metaDescription, keywords, slug } = input;
        const seoFields = {
            pageTitle,
            metaDescription,
            keywords: keywords ? removeWhiteSpacesAndSplitWordsByComma(keywords) : [],
            slug,
        };
        delete input.pageTitle;
        delete input.metaDescription;
        delete input.keywords;
        delete input.slug;
        return seoFields;
    };
    return {
        t,
        form,
        formInstance,
        onSubmit,
        onFormValuesChange,
        mutationLoading,
    };
};

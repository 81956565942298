import { useEffect, useState } from "react";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
// import { useMutation } from "@apollo/client";
import { Routes } from "@bundles/UIAppBundle";
// import { IMPORT_PRODUCTS } from "./mutations";
export const useImportProducts = () => {
    const [file, setFile] = useState();
    const [step, setStep] = useState(0); // TODO replace it with useMutation Result
    const [imported, setImported] = useState(0);
    const [total, setTotal] = useState(156);
    useEffect(() => {
        setTotal(156);
    }, []);
    const router = useRouter();
    // // TODO replace IMPORT_PRODUCTS mutation
    // /*
    //   TODO it would be nice if we had `reset` here in order to back to the first step
    //   but Apollo Client couldn't be upgraded directly due to bluelibs dependencies
    // */
    // const [importProducts, { called, data, loading, error }] = useMutation(
    //   IMPORT_PRODUCTS,
    //   {},
    // );
    const onChangeFile = (event) => {
        const file = event.currentTarget.files.item(0) || undefined;
        setFile(file);
    };
    const onDropFile = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files.item(0) || undefined;
        setFile(file);
    };
    const onReset = (event) => {
        event.stopPropagation();
        setFile(undefined);
    };
    const onSubmit = (event) => {
        event.preventDefault();
        alert("// TODO submit");
        setStep(1);
    };
    // TODO remove me
    useEffect(() => {
        let t1, t2;
        if (step === 1) {
            setImported(0);
            t1 = setInterval(() => {
                setImported((imported) => imported + 1);
            }, 50);
        }
        else if (step === 2) {
            t2 = setTimeout(() => {
                setStep(3);
            }, 2000);
        }
        return () => {
            if (t1)
                clearInterval(t1);
            if (t2)
                clearTimeout(t2);
        };
    }, [step]);
    // TODO remove me
    useEffect(() => {
        if (imported === total) {
            setStep(2);
        }
    }, [imported]);
    const onClickStopImporting = () => {
        alert("// TODO stop importing");
        setStep(0);
    };
    const onClickViewProducts = () => {
        router.go(Routes.PRODUCTS_LIST);
    };
    const onClickStartANewImport = () => {
        alert("// TODO back to first page");
        setStep(0);
    };
    const onClickTryAgain = () => {
        alert("// TODO try again");
        setStep(0);
    };
    return {
        fileName: file === null || file === void 0 ? void 0 : file.name,
        // called,
        // data,
        // loading,
        // error,
        imported,
        total,
        created: 140,
        updated: 16,
        step,
        onChangeFile,
        onDropFile,
        onSubmit,
        onReset,
        onClickStopImporting,
        onClickStartANewImport,
        onClickViewProducts,
        onClickTryAgain,
    };
};

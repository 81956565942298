import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { Routes } from "@bundles/UIAppBundle";
export const useProductBilling = ({ product3DModelId }) => {
    const router = useRouter();
    const onClickView3DModel = (event) => {
        router.go(Routes.PRODUCT_3_D_MODEL_VIEW, {
            params: { id: product3DModelId },
        });
    };
    return { onClickView3DModel };
};

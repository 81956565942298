var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Service } from "@bluelibs/core";
import { ControlledInput, DatePicker, InputNumber, RichTextEditor, } from "@bundles/UIAppBundle/components";
import { newAttributesListRenderer } from "../components/Create/helpers/newAttritubesListRenderer";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
import styles from "../components/Create/ProductCategoriesCreate.module.scss";
import { ProductCategoryCreateForm as BaseProductCategoryCreateForm } from "./ProductCategoryCreateForm.base";
let ProductCategoryCreateForm = class ProductCategoryCreateForm extends BaseProductCategoryCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.update("name", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.product_categories.labels.name")), placeholder: t("management.product_categories.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(RichTextEditor, { placeholder: t("management.product_categories.placeholders.type_here"), label: addRequiredFieldSpecialMark(t("management.product_categories.fields.description")) }, void 0) }), void 0) }), void 0)),
        });
        this.update("discountPercentage", {
            required: false,
            rules: [
                {
                    type: "number",
                    min: 1,
                    max: 99,
                    message: t("generics.forms.range", {
                        field: t("management.product_categories.fields.discountPercentage"),
                        min: 1,
                        max: 99,
                    }),
                },
            ],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: _jsxs(_Fragment, { children: [t("management.product_categories.fields.discountPercentage"), "\u00A0", _jsx("span", Object.assign({ className: styles["field-label-description"] }, { children: t("management.product_categories.descriptions.discountPercentage_comment") }), void 0)] }, void 0), placeholder: t("management.product_categories.placeholders.discountPercentage"), type: "number" }, void 0) }), void 0) }), void 0)),
        });
        this.update("discountActiveUntil", {
            required: false,
            name: ["discountActiveUntil"],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(DatePicker, { label: t("management.product_categories.fields.discountActiveUntil"), placeholder: t("management.product_categories.placeholders.discountActiveUntil"), disabledDate: (date) => (date === null || date === void 0 ? void 0 : date.valueOf()) < Date.now().valueOf() }, void 0) }), void 0) }), void 0)),
        });
        this.update("attributeOptions", {
            name: ["attributeOptions"],
            required: false,
            isList: true,
            initialValue: [],
            listRenderer: () => newAttributesListRenderer(t),
        });
    }
};
ProductCategoryCreateForm = __decorate([
    Service({ transient: true })
], ProductCategoryCreateForm);
export { ProductCategoryCreateForm };

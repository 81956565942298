var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { EndUserProfilesCollection, OrdersCollection, PaymentOptionsCollection, } from "@bundles/UIAppBundle/collections";
let InvoiceListFiltersForm = class InvoiceListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "invoiceId",
                label: t("management.invoices.fields.invoiceId"),
                name: ["invoiceId"],
                tooltip: t("management.invoices.fields.invoiceId_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "billingAddress",
                label: t("management.invoices.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "nbh",
                        label: t("management.invoices.fields.billingAddress.nbh"),
                        name: ["billingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.invoices.fields.billingAddress.building_floor"),
                        name: ["billingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.invoices.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.invoices.fields.billingAddress.address"),
                        name: ["billingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.invoices.fields.billingAddress.building_entrance"),
                        name: ["billingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.invoices.fields.billingAddress.street"),
                        name: ["billingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.invoices.fields.billingAddress.district"),
                        name: ["billingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.invoices.fields.billingAddress.tax"),
                        name: ["billingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.invoices.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.invoices.fields.billingAddress.note"),
                        name: ["billingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.invoices.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.invoices.fields.billingAddress.num"),
                        name: ["billingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.invoices.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.invoices.fields.billingAddress.zip_num"),
                        name: ["billingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.invoices.fields.billingAddress.fax"),
                        name: ["billingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.invoices.fields.billingAddress.building"),
                        name: ["billingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.invoices.fields.billingAddress.settlement"),
                        name: ["billingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.invoices.fields.billingAddress.phone"),
                        name: ["billingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.invoices.fields.billingAddress.building_apartment"),
                        name: ["billingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "status",
                label: t("management.invoices.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.invoices.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "AWAITING_PAYMENT" }, { children: "Awaiting Payment" }), "AWAITING_PAYMENT"), _jsx(Ant.Select.Option, Object.assign({ value: "PAID" }, { children: "Paid" }), "PAID"), _jsx(Ant.Select.Option, Object.assign({ value: "CANCELED" }, { children: "Canceled" }), "CANCELED")] }), void 0) }), void 0)),
            },
            {
                id: "sendAt",
                label: t("management.invoices.fields.sendAt"),
                name: ["sendAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "paidAt",
                label: t("management.invoices.fields.paidAt"),
                name: ["paidAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "items",
                label: t("management.invoices.fields.items"),
                name: ["items"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.invoices.fields.items.name"),
                        name: ["items", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "price",
                        label: t("management.invoices.fields.items.price"),
                        name: ["items", "price"],
                        required: true,
                        nest: [],
                        initialValue: [],
                    },
                    {
                        id: "count",
                        label: t("management.invoices.fields.items.count"),
                        name: ["items", "count"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "imageUrl",
                        label: t("management.invoices.fields.items.imageUrl"),
                        name: ["items", "imageUrl"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "shippingAddress",
                label: t("management.invoices.fields.shippingAddress"),
                name: ["shippingAddress"],
                columns: true,
                nest: [
                    {
                        id: "nbh",
                        label: t("management.invoices.fields.shippingAddress.nbh"),
                        name: ["shippingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.invoices.fields.shippingAddress.building_floor"),
                        name: ["shippingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.invoices.fields.shippingAddress.zip"),
                        name: ["shippingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.invoices.fields.shippingAddress.address"),
                        name: ["shippingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.invoices.fields.shippingAddress.building_entrance"),
                        name: ["shippingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.invoices.fields.shippingAddress.street"),
                        name: ["shippingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.invoices.fields.shippingAddress.district"),
                        name: ["shippingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.invoices.fields.shippingAddress.tax"),
                        name: ["shippingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.invoices.fields.shippingAddress.country"),
                        name: ["shippingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.invoices.fields.shippingAddress.note"),
                        name: ["shippingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.invoices.fields.shippingAddress.email"),
                        name: ["shippingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.invoices.fields.shippingAddress.num"),
                        name: ["shippingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.invoices.fields.shippingAddress.salesRayId"),
                        name: ["shippingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.invoices.fields.shippingAddress.zip_num"),
                        name: ["shippingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.invoices.fields.shippingAddress.fax"),
                        name: ["shippingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.invoices.fields.shippingAddress.building"),
                        name: ["shippingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.invoices.fields.shippingAddress.settlement"),
                        name: ["shippingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.invoices.fields.shippingAddress.phone"),
                        name: ["shippingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.invoices.fields.shippingAddress.building_apartment"),
                        name: ["shippingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "shippingPrice",
                label: t("management.invoices.fields.shippingPrice"),
                name: ["shippingPrice"],
                columns: true,
                nest: [
                    {
                        id: "currency",
                        label: t("management.invoices.fields.shippingPrice.currency"),
                        name: ["shippingPrice", "currency"],
                        initialValue: { net: 0 },
                        component: Ant.Input,
                    },
                    {
                        id: "gross",
                        label: t("management.invoices.fields.shippingPrice.gross"),
                        name: ["shippingPrice", "gross"],
                        initialValue: { net: 0 },
                        component: Ant.InputNumber,
                    },
                    {
                        id: "net",
                        label: t("management.invoices.fields.shippingPrice.net"),
                        name: ["shippingPrice", "net"],
                        required: true,
                        initialValue: { net: 0 },
                        component: Ant.InputNumber,
                    },
                    {
                        id: "tax",
                        label: t("management.invoices.fields.shippingPrice.tax"),
                        name: ["shippingPrice", "tax"],
                        initialValue: { net: 0 },
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "downloadUrl",
                label: t("management.invoices.fields.downloadUrl"),
                name: ["downloadUrl"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.invoices.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.invoices.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.invoices.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.invoices.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "buyerId",
                label: t("management.invoices.fields.buyer"),
                name: ["buyerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "orderId",
                label: t("management.invoices.fields.order"),
                name: ["orderId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrdersCollection, field: "orderNumber", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "paymentTypeId",
                label: t("management.invoices.fields.paymentType"),
                name: ["paymentTypeId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: PaymentOptionsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
InvoiceListFiltersForm = __decorate([
    Service({ transient: true })
], InvoiceListFiltersForm);
export { InvoiceListFiltersForm };

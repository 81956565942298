import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Table } from "@bundles/UIAppBundle/components";
import { newSmart, useRouter, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import styles from "./PostsListTable.module.scss";
import React from "react";
import { PostsAntTableSmart } from "@bundles/UIAppBundle/pages/PostsManagement/components/List/PostsTableSmart";
import { Routes } from "@bundles/UIAppBundle";
import { AddSimple } from "@bundles/UIAppBundle/icons";
export function PostsListTable() {
    const t = useTranslate();
    const router = useRouter();
    const [api, Provider] = newSmart(PostsAntTableSmart);
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t("management.marketing.submenus.blog") }), void 0), _jsx(Button, Object.assign({ width: "auto", onClick: () => router.go(Routes.POSTS_CREATE), icon: AddSimple }, { children: t("management.marketing.blog.list.create_btn") }), void 0)] }), void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Provider, { children: _jsx(Table, Object.assign({ className: styles["table"] }, api.getTableProps()), void 0) }, void 0)] }, void 0));
}

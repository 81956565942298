var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
import { PaymentOptionType } from "@root/api.types";
import * as Ant from "antd";
import { PaymentOptionCreateForm as BasePaymentOptionCreateForm } from "./PaymentOptionCreateForm.base";
let PaymentOptionForm = class PaymentOptionForm extends BasePaymentOptionCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.update("description", {
            order: 3,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.payment_options.fields.description"), placeholder: t("management.payment_options.placeholders.type_here"), description: t("management.payment_options.descriptions.description") }, void 0) }), void 0) }), void 0)),
        });
        this.update("type", {
            order: 1,
            render: (props) => {
                const options = Object.keys(PaymentOptionType).map((option) => ({
                    value: PaymentOptionType[option],
                    label: t(`management.payment_options.fields.type_enums.${option}`),
                }));
                return (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: options, label: addRequiredFieldSpecialMark(t("management.payment_options.fields.type")), placeholder: t("management.payment_options.placeholders.type"), description: t("management.payment_options.descriptions.type") }, void 0) }), void 0) }), void 0));
            },
        });
        this.update("name", {
            order: 2,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.payment_options.fields.name")), placeholder: t("management.payment_options.placeholders.type_here"), description: t("management.payment_options.descriptions.name") }, void 0) }), void 0) }), void 0)),
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
PaymentOptionForm = __decorate([
    Service({ transient: true })
], PaymentOptionForm);
export { PaymentOptionForm };

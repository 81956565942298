import { jsx as _jsx } from "react/jsx-runtime";
import { Routes } from "@bundles/UIAppBundle";
import { KebabMenu, View } from "@bundles/UIAppBundle/icons";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { CustomProductVariantsCollection } from "@bundles/UIAppBundle/collections/ProductVariants/ProductVariants.custom.collection";
import { features } from "../../config/features";
import { ProductVariantList } from "../../config/ProductVariantList";
export class ProductVariantsAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomProductVariantsCollection;
    }
    getBody() {
        return ProductVariantList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ProductVariantList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return ProductVariantList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.PRODUCT_VARIANTS_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            var _a;
            if (filter["availability"]) {
                modifiedFiltersObj["availability"] = [];
                (_a = filter["availability"]["$in"]) === null || _a === void 0 ? void 0 : _a.forEach((value) => {
                    modifiedFiltersObj["availability"].push(value);
                });
            }
            else {
                modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            }
        });
        if (modifiedFiltersObj["name"]) {
            modifiedFiltersObj["name"] = modifiedFiltersObj["name"].source;
        }
        if (modifiedFiltersObj["categoryId"]) {
            modifiedFiltersObj["categoryId"] = modifiedFiltersObj["categoryId"]
                .toString()
                .split("/")[1];
        }
        if (modifiedFiltersObj["netPrice"]) {
            modifiedFiltersObj["netPrice"] = {
                gte: parseFloat(modifiedFiltersObj["netPrice"]["$in"][0]),
                lte: parseFloat(modifiedFiltersObj["netPrice"]["$in"][1]),
            };
        }
        if (modifiedFiltersObj["discountPercentage"]) {
            modifiedFiltersObj["discountPercentage"] = {
                gte: parseFloat(modifiedFiltersObj["discountPercentage"]["$in"][0]) ||
                    null,
                lte: parseFloat(modifiedFiltersObj["discountPercentage"]["$in"][1]) ||
                    null,
            };
        }
        if (modifiedFiltersObj["createdAt"]) {
            modifiedFiltersObj["createdAt"] = {
                gte: (modifiedFiltersObj["createdAt"]["$gte"] || "").toString() ||
                    new Date(-8640000000000000).getTime(),
                lte: (modifiedFiltersObj["createdAt"]["$lte"] || "").toString() ||
                    new Date(8640000000000000).getTime(),
            };
        }
        if (modifiedFiltersObj["discountActiveUntil"]) {
            modifiedFiltersObj["discountEndDate"] = {
                lte: (modifiedFiltersObj["discountActiveUntil"]["$lte"] || "").toString() || null,
            };
            delete modifiedFiltersObj["discountActiveUntil"];
        }
        if (modifiedFiltersObj["attributesValues"]) {
            (modifiedFiltersObj["attributes"] = modifiedFiltersObj["attributesValues"]
                .toString()
                .split("/")[1]),
                delete modifiedFiltersObj["attributesValues"];
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

import { Collection, } from "@bluelibs/x-ui";
import { Post } from "@root/api.types";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { InternalUserProfilesCollection } from "@bundles/UIAppBundle/collections";
export class PostsCollection extends Collection {
    getName() {
        return "Posts";
    }
    getInputs() {
        return {
            insert: "PostInsertInput!",
            update: "PostUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "heroImage",
                field: "heroImageId",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "author",
                field: "authorId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

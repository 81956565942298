import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styles from "./RangeSlider.module.scss";
const RangeSlider = ({ min, max, step = 0.01, value, label, ...rest }) => {
    const percentage = ((value > 0 ? +value + Math.abs(min) : Math.abs(min - value)) /
        (Math.abs(+min) + Math.abs(+max))) *
        100;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("p", Object.assign({ className: styles.label }, { children: [label, " : ", value] }), void 0), _jsx("input", Object.assign({ className: `${styles.slider}  `, min: min, max: max, step: step, value: value }, rest, { type: "range", style: {
                    background: `linear-gradient(to right, #002554 0%, #002554 ${percentage}%, #b5b5b5 ${percentage}%, #b5b5b5 100%)`,
                    borderRadius: "30px",
                    width: "95%",
                    border: "0.3px solid #002554",
                } }), void 0)] }), void 0));
};
export default RangeSlider;

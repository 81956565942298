import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery } from "@apollo/client";
import { useSmart } from "@bluelibs/smart";
import { useEffect } from "react";
import { ProductCategoriesAntTableSmart } from "../ProductCategoriesTableSmart";
import { AdminGetCategories } from "../queries";
export const useExpandedInfoTable = (record) => {
    const api = useSmart(ProductCategoriesAntTableSmart);
    const [getSubCategories, { data, loading, error }] = useLazyQuery(AdminGetCategories, {
        variables: {
            input: {
                filters: { parentId: record._id },
            },
        },
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        getSubCategories();
    }, [record]);
    const getNestedTableColumns = (level) => {
        const [a, b, c, d, e, f] = api.getTableProps().columns;
        return [
            { ...a, title: "Subcategory" },
            b,
            {
                ...c,
                title: (_jsxs("span", { children: ["Subcategories", _jsx("sup", { children: level }, void 0)] }, void 0)),
            },
            d,
            e,
            f,
        ].map((column) => ({ ...column, sorter: false }));
    };
    return { data, loading, error, getNestedTableColumns };
};

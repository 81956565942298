import { gql } from "@apollo/client";
export const GET_ENDUSER_ADDRESS = gql `
  query AdminEndUserProfileGet($input: AdminEndUserProfileGetInput!) {
    AdminEndUserProfileGet(input: $input) {
      savedAddresses {
        _id
        name
        firstName
        lastName
        phoneNumber
        streetAddressLine1
        streetAddressLine2
        email
        city
        town
        country
        zipcode
      }
      billingAddress {
        firstName
        lastName
        phoneNumber
        streetAddressLine2
        streetAddressLine1
        email
        city
        town
        country
        zipcode
      }
    }
  }
`;

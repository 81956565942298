import { gql } from "@apollo/client";
export const ORDERS_GET = gql `
  query AdminOrdersGet($input: AdminOrdersGetInput) {
    AdminOrdersGet(input: $input) {
      data {
        _id
        buyer {
          fullName
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
        buyerSnapshot {
          name
        }
        placedAt
        paymentOption {
          type
        }
        status
        finalPrice
        orderNumber
        items {
          count
          _id
          price {
            currency
            gross
            net
          }
          productSnapshot {
            name
          }
          productVariant {
            name
            attributesValues {
              key
              value
            }
            defaultImage {
              downloadUrl
            }
            product {
              _id
              name
              availableStockCount
              defaultImage {
                downloadUrl
              }
            }
          }
        }
      }
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { Download, Invoices } from "@bundles/UIAppBundle/icons";
import { ordersTagColors, paymentMethodTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { orderStatusLabel, paymentOptionLabel, } from "@bundles/UIAppBundle/consts/labels";
import { CustomTag, Table } from "@bundles/UIAppBundle/components";
import { ClientPageLayout } from "../Layout";
import { useEndUserProfileOrdersList } from "./hooks";
import styles from "./EndUserProfileOrdersList.module.scss";
import { clientsAccessRoles } from "../../../consts";
import * as Ant from "antd";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
const EndUserProfileOrdersList = ({ id }) => {
    return (_jsx(ProtectedRoute, Object.assign({ roles: clientsAccessRoles }, { children: _jsx(ClientPageLayout, Object.assign({ id: id }, { children: _jsx(EndUsersOrders, { id: id }, void 0) }), void 0) }), void 0));
};
const EndUsersOrders = ({ id }) => {
    const { orderList } = useEndUserProfileOrdersList(id);
    const settings = useShopSettings();
    const onDownloadInvoice = (downloadUrl) => {
        if (downloadUrl)
            window.open(downloadUrl);
        else {
            Ant.notification.error({
                message: "Failed to download invoice",
            });
        }
    };
    const columns = [
        {
            title: "Order ID",
            dataIndex: "orderNumber",
            sorter: true,
            render: (v, model) => {
                return (_jsxs("div", Object.assign({ className: styles["order-number"] }, { children: [_jsx(CustomTag, { value: _jsx(Invoices, {}, void 0), variant: "filled", color: ordersTagColors[model.status] }, void 0), _jsxs("span", { children: ["#", v] }, void 0)] }), void 0));
            },
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: true,
            render: (v) => {
                return new Date(v).toDateString().split(" ").slice(1).join(" ");
            },
        },
        {
            title: "Payment Method",
            dataIndex: ["paymentOption", "type"],
            sorter: true,
            render: (val) => {
                return (_jsx("div", Object.assign({ className: styles["payment-method"] }, { children: _jsx(CustomTag, { color: val ? paymentMethodTagColors[val] : TagColorsEnum.GRAY, value: val ? paymentOptionLabel[val] : "dummy", variant: "filled" }, void 0) }), void 0));
            },
        },
        {
            title: "Order Status",
            dataIndex: "status",
            sorter: true,
            render: (v) => {
                return (_jsx(CustomTag, { value: orderStatusLabel[v], color: ordersTagColors[v], variant: "filled" }, void 0));
            },
        },
        {
            title: "Total",
            dataIndex: "priceTotal",
            sorter: true,
            render: (v) => {
                return (_jsx("div", Object.assign({ className: styles["price-total"] }, { children: settings.formatPriceWithCurrency(v) }), void 0));
            },
        },
        {
            title: "",
            dataIndex: ["invoice", "downloadUrl"],
            render: (v) => {
                return (_jsxs("button", Object.assign({ className: styles["download-invoice"], onClick: () => onDownloadInvoice(v), style: { cursor: "pointer" } }, { children: [_jsx(Download, {}, void 0), "Invoice"] }), void 0));
            },
        },
    ];
    return (_jsx(Table, { rowKey: (record) => record === null || record === void 0 ? void 0 : record._id, className: styles["table"], dataSource: orderList, columns: columns, pagination: false, showSorterTooltip: true }, void 0));
};
export default EndUserProfileOrdersList;

import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { ReturnStatus } from "@root/api.types";
import { returnStatusLabel } from "@bundles/UIAppBundle/consts/labels";
import { UPDATE_RETURN_STATUS } from "./mutation";
export const useReturns = (id) => {
    const [returnOptions, setReturnOptions] = useState([]);
    useEffect(() => {
        setReturnOptions(Object.keys(ReturnStatus).map((status) => ({
            value: status,
            label: returnStatusLabel[status],
        })));
    }, []);
    const [updateStatus] = useMutation(UPDATE_RETURN_STATUS);
    const changeReturnStatus = (v) => {
        updateStatus({
            variables: {
                input: {
                    document: {
                        status: v,
                    },
                    _id: id,
                },
            },
        })
            .then(() => notification.success({ message: "Return status updated successful" }))
            .catch(() => notification.error({ message: "Return status update failed" }));
    };
    return {
        returnOptions,
        changeReturnStatus,
    };
};

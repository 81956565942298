var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import { ShippingOptionEditForm as BaseShippingOptionEditForm } from "./ShippingOptionEditForm.base";
import { CustomShippingOptionsCollection, ShippingOptionsCollection, } from "@bundles/UIAppBundle/collections";
import { ControlledInput, InputNumber } from "@bundles/UIAppBundle/components";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let ShippingOptionEditForm = class ShippingOptionEditForm extends BaseShippingOptionEditForm {
    build() {
        super.build();
        this.remove("type");
        const { t } = this.i18n;
        this.update("name", {
            order: 1,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.name")), placeholder: t("management.shipping_options.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("price", {
            order: 2,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { type: "number", min: 0, label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.price")), placeholder: t("management.shipping_options.placeholders.type_here") }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            order: 3,
            required: true,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.shipping_options.fields.description")), placeholder: t("management.shipping_options.placeholders.type_description") }, void 0) }), void 0) }), void 0)),
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, values)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shipping_options.edit_confirmation"),
            });
            return this.router.go(Routes.SHIPPING_OPTIONS_LIST);
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CustomShippingOptionsCollection),
    __metadata("design:type", typeof (_a = typeof ShippingOptionsCollection !== "undefined" && ShippingOptionsCollection) === "function" ? _a : Object)
], ShippingOptionEditForm.prototype, "collection", void 0);
ShippingOptionEditForm = __decorate([
    Service({ transient: true })
], ShippingOptionEditForm);
export { ShippingOptionEditForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProductVariantPriceListList = class ProductVariantPriceListList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "priceListId",
                title: t("management.product_variant_price_lists.fields.priceListId"),
                key: "management.product_variant_price_lists.fields.priceListId",
                dataIndex: ["priceListId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListCode",
                title: t("management.product_variant_price_lists.fields.priceListCode"),
                key: "management.product_variant_price_lists.fields.priceListCode",
                dataIndex: ["priceListCode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "sku",
                title: t("management.product_variant_price_lists.fields.sku"),
                key: "management.product_variant_price_lists.fields.sku",
                dataIndex: ["sku"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price",
                title: t("management.product_variant_price_lists.fields.price"),
                key: "management.product_variant_price_lists.fields.price",
                dataIndex: ["price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "currency",
                title: t("management.product_variant_price_lists.fields.currency"),
                key: "management.product_variant_price_lists.fields.currency",
                dataIndex: ["currency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.id",
                title: t("management.product_variant_price_lists.fields.priceListInfo.id"),
                key: "management.product_variant_price_lists.fields.priceListInfo.id",
                dataIndex: ["priceListInfo", "id"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.code",
                title: t("management.product_variant_price_lists.fields.priceListInfo.code"),
                key: "management.product_variant_price_lists.fields.priceListInfo.code",
                dataIndex: ["priceListInfo", "code"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.status",
                title: t("management.product_variant_price_lists.fields.priceListInfo.status"),
                key: "management.product_variant_price_lists.fields.priceListInfo.status",
                dataIndex: ["priceListInfo", "status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.formula",
                title: t("management.product_variant_price_lists.fields.priceListInfo.formula"),
                key: "management.product_variant_price_lists.fields.priceListInfo.formula",
                dataIndex: ["priceListInfo", "formula"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.name",
                title: t("management.product_variant_price_lists.fields.priceListInfo.name"),
                key: "management.product_variant_price_lists.fields.priceListInfo.name",
                dataIndex: ["priceListInfo", "name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceListInfo.tp",
                title: t("management.product_variant_price_lists.fields.priceListInfo.tp"),
                key: "management.product_variant_price_lists.fields.priceListInfo.tp",
                dataIndex: ["priceListInfo", "tp"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.product_variant_price_lists.fields.createdAt"),
                key: "management.product_variant_price_lists.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.product_variant_price_lists.fields.updatedAt"),
                key: "management.product_variant_price_lists.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "unitOfMeasurement",
                title: t("management.product_variant_price_lists.fields.unitOfMeasurement"),
                key: "management.product_variant_price_lists.fields.unitOfMeasurement",
                dataIndex: ["unitOfMeasurement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.UNITS_OF_MEASUREMENT_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            unitOfMeasurement: "unitOfMeasurement._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            priceListId: 1,
            priceListCode: 1,
            sku: 1,
            price: 1,
            currency: 1,
            priceListInfo: {
                id: 1,
                code: 1,
                status: 1,
                formula: 1,
                name: 1,
                tp: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            unitOfMeasurement: {
                _id: 1,
            },
            unitOfMeasurementId: 1,
        };
    }
};
ProductVariantPriceListList = __decorate([
    Service({ transient: true })
], ProductVariantPriceListList);
export { ProductVariantPriceListList };

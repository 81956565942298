var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ShopSettingList = class ShopSettingList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "shopName",
                title: t("management.shop_settings.fields.shopName"),
                key: "management.shop_settings.fields.shopName",
                dataIndex: ["shopName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "companyName",
                title: t("management.shop_settings.fields.companyName"),
                key: "management.shop_settings.fields.companyName",
                dataIndex: ["companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "companyIdentificationNumber",
                title: t("management.shop_settings.fields.companyIdentificationNumber"),
                key: "management.shop_settings.fields.companyIdentificationNumber",
                dataIndex: ["companyIdentificationNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.salesRayId",
                title: t("management.shop_settings.fields.billingAddress.salesRayId"),
                key: "management.shop_settings.fields.billingAddress.salesRayId",
                dataIndex: ["billingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.firstName",
                title: t("management.shop_settings.fields.billingAddress.firstName"),
                key: "management.shop_settings.fields.billingAddress.firstName",
                dataIndex: ["billingAddress", "firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.lastName",
                title: t("management.shop_settings.fields.billingAddress.lastName"),
                key: "management.shop_settings.fields.billingAddress.lastName",
                dataIndex: ["billingAddress", "lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phoneNumber",
                title: t("management.shop_settings.fields.billingAddress.phoneNumber"),
                key: "management.shop_settings.fields.billingAddress.phoneNumber",
                dataIndex: ["billingAddress", "phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.streetAddressLine1",
                title: t("management.shop_settings.fields.billingAddress.streetAddressLine1"),
                key: "management.shop_settings.fields.billingAddress.streetAddressLine1",
                dataIndex: ["billingAddress", "streetAddressLine1"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.streetAddressLine2",
                title: t("management.shop_settings.fields.billingAddress.streetAddressLine2"),
                key: "management.shop_settings.fields.billingAddress.streetAddressLine2",
                dataIndex: ["billingAddress", "streetAddressLine2"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                title: t("management.shop_settings.fields.billingAddress.email"),
                key: "management.shop_settings.fields.billingAddress.email",
                dataIndex: ["billingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.city",
                title: t("management.shop_settings.fields.billingAddress.city"),
                key: "management.shop_settings.fields.billingAddress.city",
                dataIndex: ["billingAddress", "city"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.town",
                title: t("management.shop_settings.fields.billingAddress.town"),
                key: "management.shop_settings.fields.billingAddress.town",
                dataIndex: ["billingAddress", "town"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.shop_settings.fields.billingAddress.country"),
                key: "management.shop_settings.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zipcode",
                title: t("management.shop_settings.fields.billingAddress.zipcode"),
                key: "management.shop_settings.fields.billingAddress.zipcode",
                dataIndex: ["billingAddress", "zipcode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.companyName",
                title: t("management.shop_settings.fields.billingAddress.companyName"),
                key: "management.shop_settings.fields.billingAddress.companyName",
                dataIndex: ["billingAddress", "companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.vatNumber",
                title: t("management.shop_settings.fields.billingAddress.vatNumber"),
                key: "management.shop_settings.fields.billingAddress.vatNumber",
                dataIndex: ["billingAddress", "vatNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "defaultCurrency",
                title: t("management.shop_settings.fields.defaultCurrency"),
                key: "management.shop_settings.fields.defaultCurrency",
                dataIndex: ["defaultCurrency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "firstInvoiceNumber",
                title: t("management.shop_settings.fields.firstInvoiceNumber"),
                key: "management.shop_settings.fields.firstInvoiceNumber",
                dataIndex: ["firstInvoiceNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "freeShippingAfterAmountLimit",
                title: t("management.shop_settings.fields.freeShippingAfterAmountLimit"),
                key: "management.shop_settings.fields.freeShippingAfterAmountLimit",
                dataIndex: ["freeShippingAfterAmountLimit"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "invoiceGenerationPrefix",
                title: t("management.shop_settings.fields.invoiceGenerationPrefix"),
                key: "management.shop_settings.fields.invoiceGenerationPrefix",
                dataIndex: ["invoiceGenerationPrefix"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "attributeOptions",
                title: t("management.shop_settings.fields.attributeOptions"),
                key: "management.shop_settings.fields.attributeOptions",
                dataIndex: ["attributeOptions"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "analyticsURLs.google",
                title: t("management.shop_settings.fields.analyticsURLs.google"),
                key: "management.shop_settings.fields.analyticsURLs.google",
                dataIndex: ["analyticsURLs", "google"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "analyticsURLs.facebookPixel",
                title: t("management.shop_settings.fields.analyticsURLs.facebookPixel"),
                key: "management.shop_settings.fields.analyticsURLs.facebookPixel",
                dataIndex: ["analyticsURLs", "facebookPixel"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "reviewsEnabled",
                title: t("management.shop_settings.fields.reviewsEnabled"),
                key: "management.shop_settings.fields.reviewsEnabled",
                dataIndex: ["reviewsEnabled"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.shop_settings.fields.createdAt"),
                key: "management.shop_settings.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.shop_settings.fields.updatedAt"),
                key: "management.shop_settings.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.shop_settings.fields.createdBy"),
                key: "management.shop_settings.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.shop_settings.fields.updatedBy"),
                key: "management.shop_settings.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "logo",
                title: t("management.shop_settings.fields.logo"),
                key: "management.shop_settings.fields.logo",
                dataIndex: ["logo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "favicon",
                title: t("management.shop_settings.fields.favicon"),
                key: "management.shop_settings.fields.favicon",
                dataIndex: ["favicon"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
            logo: "logo._id",
            favicon: "favicon._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            shopName: 1,
            companyName: 1,
            companyIdentificationNumber: 1,
            billingAddress: {
                salesRayId: 1,
                firstName: 1,
                lastName: 1,
                phoneNumber: 1,
                streetAddressLine1: 1,
                streetAddressLine2: 1,
                email: 1,
                city: 1,
                town: 1,
                country: 1,
                zipcode: 1,
                companyName: 1,
                vatNumber: 1,
            },
            defaultCurrency: 1,
            firstInvoiceNumber: 1,
            freeShippingAfterAmountLimit: 1,
            invoiceGenerationPrefix: 1,
            attributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            analyticsURLs: {
                google: 1,
                facebookPixel: 1,
            },
            reviewsEnabled: 1,
            createdAt: 1,
            updatedAt: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
            logo: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            logoId: 1,
            favicon: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            faviconId: 1,
        };
    }
};
ShopSettingList = __decorate([
    Service({ transient: true })
], ShopSettingList);
export { ShopSettingList };

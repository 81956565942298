import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import { UPDATE_ORDER_ASSIGNEE } from "./mutation";
import { GET_INTERNAL_USER_PROFILE } from "./query";
export const useAssignTeamMember = (orderId, salesAgentId) => {
    //Current team member
    const [teamMember, setTeamMember] = useState({
        fullName: "",
    });
    // List of team members modified for ControlledSelect
    const [teamMembersOption, setTeamMembersOption] = useState([]);
    // List of all team members with additional values
    const [allTeamMemberInfo, setAllTeamMemberInfo] = useState([]);
    const { loading, error, data } = useQuery(GET_INTERNAL_USER_PROFILE, {
        variables: {
            input: {},
        },
    });
    const [updateTeamMember] = useMutation(UPDATE_ORDER_ASSIGNEE);
    useEffect(() => {
        setTeamMember({ fullName: "" });
        allTeamMemberInfo.forEach(({ _id: teamMemeberId, fullName, user }) => {
            var _a, _b;
            if (teamMemeberId === salesAgentId)
                setTeamMember({ fullName, avatar: (_b = (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl });
        });
    }, [allTeamMemberInfo]);
    useEffect(() => {
        if (!loading && !error) {
            setAllTeamMemberInfo(data.AdminGetOrderAdmins);
            setTeamMembersOption(data.AdminGetOrderAdmins.map(({ _id: salesAgentId, fullName }) => ({
                value: salesAgentId,
                label: fullName,
            })));
        }
    }, [data]);
    const changeTeamMember = (assignTo) => {
        updateTeamMember({
            variables: {
                input: {
                    orderId,
                    assignTo,
                },
            },
        })
            .then((res) => {
            allTeamMemberInfo.forEach(({ _id: teamMemeberId, fullName, user }) => {
                var _a, _b;
                if (teamMemeberId === res.data.AdminOrderUpdateAssignee.salesAgentId)
                    setTeamMember({
                        fullName: fullName || "New Team Member",
                        avatar: (_b = (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl,
                    });
            });
            notification.success({
                message: "Changed team member",
            });
        })
            .catch(() => notification.error({
            message: "Team member update failed",
        }));
    };
    return {
        teamMember,
        setTeamMember,
        teamMembersOption,
        changeTeamMember,
    };
};

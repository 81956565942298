import { gql } from "@apollo/client";
export const SUPEND_TEAM_MEMBER = gql `
  mutation AdminEndUserProfileSuspend(
    $input: AdminEndUserProfileSuspendInput!
  ) {
    AdminEndUserProfileSuspend(input: $input) {
      _id
    }
  }
`;
export const UNSUSPEND_TEAM_MEMBER = gql `
  mutation AdminEndUserProfileRestore(
    $input: AdminEndUserProfileRestoreInput!
  ) {
    AdminEndUserProfileRestore(input: $input) {
      _id
    }
  }
`;
export const AdminClientsSync = gql `
  mutation AdminClientsSync {
    AdminClientsSync
  }
`;
export const AdminClientsAddressesSync = gql `
  mutation AdminClientsAddressesSync {
    AdminClientsAddressesSync
  }
`;

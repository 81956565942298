var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let EndUserProfileList = class EndUserProfileList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "billingAddress.salesRayId",
                title: t("management.end_user_profiles.fields.billingAddress.salesRayId"),
                key: "management.end_user_profiles.fields.billingAddress.salesRayId",
                dataIndex: ["billingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.firstName",
                title: t("management.end_user_profiles.fields.billingAddress.firstName"),
                key: "management.end_user_profiles.fields.billingAddress.firstName",
                dataIndex: ["billingAddress", "firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.lastName",
                title: t("management.end_user_profiles.fields.billingAddress.lastName"),
                key: "management.end_user_profiles.fields.billingAddress.lastName",
                dataIndex: ["billingAddress", "lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phoneNumber",
                title: t("management.end_user_profiles.fields.billingAddress.phoneNumber"),
                key: "management.end_user_profiles.fields.billingAddress.phoneNumber",
                dataIndex: ["billingAddress", "phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.streetAddressLine1",
                title: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1"),
                key: "management.end_user_profiles.fields.billingAddress.streetAddressLine1",
                dataIndex: ["billingAddress", "streetAddressLine1"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.streetAddressLine2",
                title: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2"),
                key: "management.end_user_profiles.fields.billingAddress.streetAddressLine2",
                dataIndex: ["billingAddress", "streetAddressLine2"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                title: t("management.end_user_profiles.fields.billingAddress.email"),
                key: "management.end_user_profiles.fields.billingAddress.email",
                dataIndex: ["billingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.city",
                title: t("management.end_user_profiles.fields.billingAddress.city"),
                key: "management.end_user_profiles.fields.billingAddress.city",
                dataIndex: ["billingAddress", "city"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.town",
                title: t("management.end_user_profiles.fields.billingAddress.town"),
                key: "management.end_user_profiles.fields.billingAddress.town",
                dataIndex: ["billingAddress", "town"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.end_user_profiles.fields.billingAddress.country"),
                key: "management.end_user_profiles.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zipcode",
                title: t("management.end_user_profiles.fields.billingAddress.zipcode"),
                key: "management.end_user_profiles.fields.billingAddress.zipcode",
                dataIndex: ["billingAddress", "zipcode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.companyName",
                title: t("management.end_user_profiles.fields.billingAddress.companyName"),
                key: "management.end_user_profiles.fields.billingAddress.companyName",
                dataIndex: ["billingAddress", "companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.vatNumber",
                title: t("management.end_user_profiles.fields.billingAddress.vatNumber"),
                key: "management.end_user_profiles.fields.billingAddress.vatNumber",
                dataIndex: ["billingAddress", "vatNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "firstName",
                title: t("management.end_user_profiles.fields.firstName"),
                key: "management.end_user_profiles.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "middleName",
                title: t("management.end_user_profiles.fields.middleName"),
                key: "management.end_user_profiles.fields.middleName",
                dataIndex: ["middleName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "lastName",
                title: t("management.end_user_profiles.fields.lastName"),
                key: "management.end_user_profiles.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "companyName",
                title: t("management.end_user_profiles.fields.companyName"),
                key: "management.end_user_profiles.fields.companyName",
                dataIndex: ["companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatNumber",
                title: t("management.end_user_profiles.fields.vatNumber"),
                key: "management.end_user_profiles.fields.vatNumber",
                dataIndex: ["vatNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                title: t("management.end_user_profiles.fields.salesRayId"),
                key: "management.end_user_profiles.fields.salesRayId",
                dataIndex: ["salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "birthDate",
                title: t("management.end_user_profiles.fields.birthDate"),
                key: "management.end_user_profiles.fields.birthDate",
                dataIndex: ["birthDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                title: t("management.end_user_profiles.fields.email"),
                key: "management.end_user_profiles.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                title: t("management.end_user_profiles.fields.phoneNumber"),
                key: "management.end_user_profiles.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fullName",
                title: t("management.end_user_profiles.fields.fullName"),
                key: "management.end_user_profiles.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isAnonymousCheckout",
                title: t("management.end_user_profiles.fields.isAnonymousCheckout"),
                key: "management.end_user_profiles.fields.isAnonymousCheckout",
                dataIndex: ["isAnonymousCheckout"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orderTotal",
                title: t("management.end_user_profiles.fields.orderTotal"),
                key: "management.end_user_profiles.fields.orderTotal",
                dataIndex: ["orderTotal"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orderCount",
                title: t("management.end_user_profiles.fields.orderCount"),
                key: "management.end_user_profiles.fields.orderCount",
                dataIndex: ["orderCount"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "language",
                title: t("management.end_user_profiles.fields.language"),
                key: "management.end_user_profiles.fields.language",
                dataIndex: ["language"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayDefaultPricelistId",
                title: t("management.end_user_profiles.fields.salesRayDefaultPricelistId"),
                key: "management.end_user_profiles.fields.salesRayDefaultPricelistId",
                dataIndex: ["salesRayDefaultPricelistId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.end_user_profiles.fields.createdAt"),
                key: "management.end_user_profiles.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.end_user_profiles.fields.updatedAt"),
                key: "management.end_user_profiles.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "user",
                title: t("management.end_user_profiles.fields.user"),
                key: "management.end_user_profiles.fields.user",
                dataIndex: ["user"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orders",
                title: t("management.end_user_profiles.fields.orders"),
                key: "management.end_user_profiles.fields.orders",
                dataIndex: ["orders"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "orderNumber",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productFavorites",
                title: t("management.end_user_profiles.fields.productFavorites"),
                key: "management.end_user_profiles.fields.productFavorites",
                dataIndex: ["productFavorites"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_FAVORITES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "latestViewedProducts",
                title: t("management.end_user_profiles.fields.latestViewedProducts"),
                key: "management.end_user_profiles.fields.latestViewedProducts",
                dataIndex: ["latestViewedProducts"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.PRODUCTS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "name",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "invoices",
                title: t("management.end_user_profiles.fields.invoices"),
                key: "management.end_user_profiles.fields.invoices",
                dataIndex: ["invoices"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INVOICES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "invoiceId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "payments",
                title: t("management.end_user_profiles.fields.payments"),
                key: "management.end_user_profiles.fields.payments",
                dataIndex: ["payments"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "paymentProcessor",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "admin",
                title: t("management.end_user_profiles.fields.admin"),
                key: "management.end_user_profiles.fields.admin",
                dataIndex: ["admin"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INTERNAL_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "savedAddresses",
                title: t("management.end_user_profiles.fields.savedAddresses"),
                key: "management.end_user_profiles.fields.savedAddresses",
                dataIndex: ["savedAddresses"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.SHIPPING_ADDRESS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "_id",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "models",
                title: t("management.end_user_profiles.fields.models"),
                key: "management.end_user_profiles.fields.models",
                dataIndex: ["models"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_3_D_MODEL_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.end_user_profiles.fields.createdBy"),
                key: "management.end_user_profiles.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.end_user_profiles.fields.updatedBy"),
                key: "management.end_user_profiles.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            user: "user.fullName",
            orders: "orders.orderNumber",
            productFavorites: "productFavorites._id",
            latestViewedProducts: "latestViewedProducts.name",
            invoices: "invoices.invoiceId",
            payments: "payments.paymentProcessor",
            admin: "admin.fullName",
            savedAddresses: "savedAddresses._id",
            models: "models.name",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            billingAddress: {
                salesRayId: 1,
                firstName: 1,
                lastName: 1,
                phoneNumber: 1,
                streetAddressLine1: 1,
                streetAddressLine2: 1,
                email: 1,
                city: 1,
                town: 1,
                country: 1,
                zipcode: 1,
                companyName: 1,
                vatNumber: 1,
            },
            firstName: 1,
            middleName: 1,
            lastName: 1,
            companyName: 1,
            vatNumber: 1,
            salesRayId: 1,
            birthDate: 1,
            email: 1,
            phoneNumber: 1,
            fullName: 1,
            isAnonymousCheckout: 1,
            orderTotal: 1,
            orderCount: 1,
            language: 1,
            salesRayDefaultPricelistId: 1,
            createdAt: 1,
            updatedAt: 1,
            user: {
                _id: 1,
                fullName: 1,
            },
            userId: 1,
            orders: {
                _id: 1,
                orderNumber: 1,
            },
            productFavorites: {
                _id: 1,
            },
            latestViewedProducts: {
                _id: 1,
                name: 1,
            },
            latestViewedProductsIds: 1,
            invoices: {
                _id: 1,
                invoiceId: 1,
            },
            payments: {
                _id: 1,
                paymentProcessor: 1,
            },
            admin: {
                _id: 1,
                fullName: 1,
            },
            adminId: 1,
            savedAddresses: {
                _id: 1,
            },
            models: {
                _id: 1,
                name: 1,
            },
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
EndUserProfileList = __decorate([
    Service({ transient: true })
], EndUserProfileList);
export { EndUserProfileList };

import { gql } from "@apollo/client";
export const createInternalUser = gql `
  mutation AdminUserCreate($input: AdminInternalUserInsertInput!) {
    AdminUserCreate(input: $input) {
      _id
      fullName
      email
      userId
    }
  }
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let ShopSettingListFiltersForm = class ShopSettingListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "shopName",
                label: t("management.shop_settings.fields.shopName"),
                name: ["shopName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "companyName",
                label: t("management.shop_settings.fields.companyName"),
                name: ["companyName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "companyIdentificationNumber",
                label: t("management.shop_settings.fields.companyIdentificationNumber"),
                name: ["companyIdentificationNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "billingAddress",
                label: t("management.shop_settings.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.shop_settings.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        component: Ant.Input,
                    },
                    {
                        id: "firstName",
                        label: t("management.shop_settings.fields.billingAddress.firstName"),
                        name: ["billingAddress", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.shop_settings.fields.billingAddress.lastName"),
                        name: ["billingAddress", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.shop_settings.fields.billingAddress.phoneNumber"),
                        name: ["billingAddress", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.shop_settings.fields.billingAddress.streetAddressLine1"),
                        name: ["billingAddress", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.shop_settings.fields.billingAddress.streetAddressLine2"),
                        name: ["billingAddress", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.shop_settings.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.shop_settings.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.shop_settings.fields.billingAddress.town"),
                        name: ["billingAddress", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.shop_settings.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.shop_settings.fields.billingAddress.zipcode"),
                        name: ["billingAddress", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.shop_settings.fields.billingAddress.companyName"),
                        name: ["billingAddress", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.shop_settings.fields.billingAddress.vatNumber"),
                        name: ["billingAddress", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "defaultCurrency",
                label: t("management.shop_settings.fields.defaultCurrency"),
                name: ["defaultCurrency"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "firstInvoiceNumber",
                label: t("management.shop_settings.fields.firstInvoiceNumber"),
                name: ["firstInvoiceNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "freeShippingAfterAmountLimit",
                label: t("management.shop_settings.fields.freeShippingAfterAmountLimit"),
                name: ["freeShippingAfterAmountLimit"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "invoiceGenerationPrefix",
                label: t("management.shop_settings.fields.invoiceGenerationPrefix"),
                name: ["invoiceGenerationPrefix"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "attributeOptions",
                label: t("management.shop_settings.fields.attributeOptions"),
                name: ["attributeOptions"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.shop_settings.fields.attributeOptions.name"),
                        name: ["attributeOptions", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "type",
                        label: t("management.shop_settings.fields.attributeOptions.type"),
                        name: ["attributeOptions", "type"],
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "string", label: "String" },
                                { value: "number", label: "Number" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.shop_settings.fields.attributeOptions.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.shop_settings.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.shop_settings.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "isRequired",
                        label: t("management.shop_settings.fields.attributeOptions.isRequired"),
                        name: ["attributeOptions", "isRequired"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "isEnum",
                        label: t("management.shop_settings.fields.attributeOptions.isEnum"),
                        name: ["attributeOptions", "isEnum"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "enumValues",
                        label: t("management.shop_settings.fields.attributeOptions.enumValues"),
                        name: ["attributeOptions", "enumValues"],
                        initialValue: [],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "isRange",
                        label: t("management.shop_settings.fields.attributeOptions.isRange"),
                        name: ["attributeOptions", "isRange"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "defaultValue",
                        label: t("management.shop_settings.fields.attributeOptions.defaultValue"),
                        name: ["attributeOptions", "defaultValue"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "defaultSuffix",
                        label: t("management.shop_settings.fields.attributeOptions.defaultSuffix"),
                        name: ["attributeOptions", "defaultSuffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "rangeMin",
                        label: t("management.shop_settings.fields.attributeOptions.rangeMin"),
                        name: ["attributeOptions", "rangeMin"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "rangeMax",
                        label: t("management.shop_settings.fields.attributeOptions.rangeMax"),
                        name: ["attributeOptions", "rangeMax"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "analyticsURLs",
                label: t("management.shop_settings.fields.analyticsURLs"),
                name: ["analyticsURLs"],
                columns: true,
                nest: [
                    {
                        id: "google",
                        label: t("management.shop_settings.fields.analyticsURLs.google"),
                        name: ["analyticsURLs", "google"],
                        component: Ant.Input,
                    },
                    {
                        id: "facebookPixel",
                        label: t("management.shop_settings.fields.analyticsURLs.facebookPixel"),
                        name: ["analyticsURLs", "facebookPixel"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "reviewsEnabled",
                label: t("management.shop_settings.fields.reviewsEnabled"),
                name: ["reviewsEnabled"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.shop_settings.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.shop_settings.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.shop_settings.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.shop_settings.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.shop_settings.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.shop_settings.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.shop_settings.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.shop_settings.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ShopSettingListFiltersForm = __decorate([
    Service({ transient: true })
], ShopSettingListFiltersForm);
export { ShopSettingListFiltersForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Spin } from "antd";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { CustomTag, InputNumber, Table, Button, } from "@bundles/UIAppBundle/components";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { Searchbar } from "@bundles/UIAppBundle/components/Searchbar";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { useAddProductsOrder } from "./hooks";
import styles from "./AddProductsToOrder.module.scss";
const AddProductsToOrder = ({ stepForm }) => {
    const { updateQuantity, onSearch, itemsData, currentPage, setCurrentPage, tableTotalData, productsListLoading, pageSize, onSelectProduct, selectedRow, } = useAddProductsOrder(stepForm);
    const shopSettings = useShopSettings();
    const columns = [
        {
            dataIndex: ["product", "name"],
            render: (name, model) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: styles["product"] }, { children: [_jsx("img", { src: generateImageLink((_a = model.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            dataIndex: "name",
            render: (name, model) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: styles["product"] }, { children: [_jsx("img", { src: generateImageLink((_a = model.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            dataIndex: "isPublished",
            render: (value) => {
                return (_jsx(CustomTag, { value: value ? "Yes" : "No", variant: "filled", color: value ? TagColorsEnum.GREEN : TagColorsEnum.RED }, void 0));
            },
        },
        {
            dataIndex: "quantity",
            render: (value, row) => {
                return (_jsxs("span", Object.assign({ className: styles["quantity-wrapper"] }, { children: [_jsx(InputNumber, { type: "number", defaultValue: value, className: styles["quantity"], min: 1, max: row.product.availableStockCount, onChange: (v) => {
                                updateQuantity(row._id, +v);
                            } }, void 0), "/", row.product.availableStockCount] }), void 0));
            },
        },
        {
            dataIndex: "netPrice",
            render: (value, model) => {
                const price = value - (value * model.totalDiscountPercentage) / 100;
                return (_jsx("div", Object.assign({ className: styles["price"] }, { children: _jsx("span", Object.assign({ className: styles["price__value"] }, { children: shopSettings.formatPriceWithCurrency(price) }), void 0) }), void 0));
            },
        },
    ];
    const rowSelection = {
        selectedRowKeys: selectedRow.map((i) => i._id),
        onChange: onSelectProduct,
        getCheckboxProps: (record) => ({
            disabled: record.product.availableStockCount === 0 ||
                record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Searchbar, { onChange: (searchTerm) => {
                    onSearch(searchTerm);
                } }, void 0), productsListLoading ? (_jsx(Spin, { size: "large" }, void 0)) : (_jsx(Table, { className: styles["table"], rowSelection: {
                    type: "checkbox",
                    ...rowSelection,
                }, rowKey: (record) => record._id, showHeader: false, columns: columns, dataSource: itemsData, pagination: {
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: (page) => {
                        setCurrentPage(page);
                    },
                    total: tableTotalData,
                    showSizeChanger: false,
                    showLessItems: true,
                    showPrevNextJumpers: true,
                    hideOnSinglePage: true,
                    prefixCls: "cc-custom-pagination",
                    position: ["bottomCenter"],
                    itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                            return null;
                        }
                        if (type === "next") {
                            return null;
                        }
                        if (type === "page") {
                            return _jsx(Button, { children: page }, void 0);
                        }
                        if (type === "jump-next" || type === "jump-prev") {
                            return _jsx(Button, Object.assign({ className: "jump" }, { children: "..." }), void 0);
                        }
                        return originalElement;
                    },
                } }, void 0))] }), void 0));
};
export default AddProductsToOrder;

import "./i18n";
import { SHIPPING_OPTIONS_LIST as BASE_SHIPPING_OPTIONS_LIST, SHIPPING_OPTIONS_CREATE as BASE_SHIPPING_OPTIONS_CREATE, SHIPPING_OPTIONS_EDIT as BASE_SHIPPING_OPTIONS_EDIT, SHIPPING_OPTIONS_VIEW as BASE_SHIPPING_OPTIONS_VIEW, } from "./config/routes";
export const SHIPPING_OPTIONS_LIST = {
    ...BASE_SHIPPING_OPTIONS_LIST,
    path: "/shop-settings/shipping-providers",
};
export const SHIPPING_OPTIONS_CREATE = {
    ...BASE_SHIPPING_OPTIONS_CREATE,
    path: "/shop-settings/shipping-providers/create",
};
export const SHIPPING_OPTIONS_EDIT = {
    ...BASE_SHIPPING_OPTIONS_EDIT,
    path: "/shop-settings/shipping-providers/:id/edit",
};
export const SHIPPING_OPTIONS_VIEW = {
    ...BASE_SHIPPING_OPTIONS_VIEW,
};

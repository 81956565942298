import { gql } from "@apollo/client";
export const GET_PAYMENT_OPTIONS = gql `
  query PaymentOptionsFind($query: QueryInput) {
    PaymentOptionsFind(query: $query) {
      _id
      type
    }
  }
`;
export const GET_INTERNAL_USER_PROFILES = gql `
  query InternalUserProfileAdminListGet(
    $input: InternalUserProfileAdminListInput!
  ) {
    InternalUserProfileAdminListGet(input: $input) {
      data {
        _id
        fullName
        user {
          roles
        }
      }
    }
  }
`;
export const GET_COMPANY_DETAILS = gql `
  query AdminEndUserProfileGet($input: AdminEndUserProfileGetInput!) {
    AdminEndUserProfileGet(input: $input) {
      companyName
      vatNumber
    }
  }
`;
// TODO: add image when backend doesn't fail
export const GET_ORDER_DETAILS = gql `
  query AdminOrderDetailsGet($input: AdminOrderDetailsGetInput!) {
    AdminOrderDetailsGet(input: $input) {
      _id
      buyerId
      buyerSnapshot {
        email
        name
        phoneNumber
      }
      buyer {
        user {
          avatar {
            file {
              downloadUrl
            }
          }
        }
      }
      items {
        _id
        product3DModelId
        productSnapshot {
          name
          imageUrl
        }
        price {
          currency
        }
        count
        productVariant {
          _id
          attributesValues {
            key
            value
          }
          name
          defaultImage {
            downloadUrl
          }
        }
        price {
          gross
          tax
          net
        }
      }
      price
      priceTotal
      paymentOption {
        type
        name
        description
      }
      paymentOptionId
      billingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      privateNotes
      salesAgentId
      shippingOption {
        name
        price
        type
        description
      }
      shippingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      status
      priceTotal
      priceVAT
      price
    }
  }
`;
export const GET_RETURN_DETAIL = gql `
  query AdminReturnGet($input: AdminReturnGetInput) {
    AdminReturnGet(input: $input) {
      _id
      items {
        price {
          gross
          tax
          net
          currency
        }
        quantity
        name
        imageUrl
        attributesValues {
          key
          value
        }
      }
      billingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      shippingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      status
      price {
        net
        gross
        tax
      }
      notes
      endUser {
        email
        fullName
        user {
          avatar {
            file {
              downloadUrl
            }
          }
        }
      }
      orderId
      shippingOption {
        name
        price
        description
      }
      paymentOption {
        name
        description
      }
      adminId
    }
  }
`;

import "./i18n";
import { NOTIFICATION_SUBSCRIBERS_LIST as BASE_NOTIFICATION_SUBSCRIBERS_LIST, NOTIFICATION_SUBSCRIBERS_CREATE as BASE_NOTIFICATION_SUBSCRIBERS_CREATE, NOTIFICATION_SUBSCRIBERS_EDIT as BASE_NOTIFICATION_SUBSCRIBERS_EDIT, NOTIFICATION_SUBSCRIBERS_VIEW as BASE_NOTIFICATION_SUBSCRIBERS_VIEW, } from "./config/routes";
export const NOTIFICATION_SUBSCRIBERS_LIST = {
    ...BASE_NOTIFICATION_SUBSCRIBERS_LIST,
};
export const NOTIFICATION_SUBSCRIBERS_CREATE = {
    ...BASE_NOTIFICATION_SUBSCRIBERS_CREATE,
};
export const NOTIFICATION_SUBSCRIBERS_EDIT = {
    ...BASE_NOTIFICATION_SUBSCRIBERS_EDIT,
};
export const NOTIFICATION_SUBSCRIBERS_VIEW = {
    ...BASE_NOTIFICATION_SUBSCRIBERS_VIEW,
};

import { gql } from "@apollo/client";
export const AdminShippingOptionCreate = gql `
  mutation AdminShippingOptionCreate($input: AdminShippingOptionCreateInput!) {
    AdminShippingOptionCreate(input: $input) {
      _id
      description
      name
      price
      type
    }
  }
`;

import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslate } from "@bluelibs/x-ui";
import { GetShopAttributes } from "../queries";
export const useShopAttributesView = () => {
    const t = useTranslate();
    const [sortedData, setSortedData] = useState([]);
    const { data, loading, error } = useQuery(GetShopAttributes, {
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (!data)
            return;
        const sortedKeys = ["name", "type", "defaultSuffix"];
        setSortedData(data.AdminGetShopSettings.attributeOptions.map((attribute) => sortedKeys
            .map((key) => ({
            label: t(`management.shop_settings.fields.attributeOptions.${key}`),
            value: attribute[key],
        }))
            .filter(({ value }) => value)));
    }, [data]);
    return {
        data: sortedData,
        loading,
        error,
        t,
    };
};

import { gql } from "@apollo/client";
export const UPDATE_ORDER = gql `
  mutation Mutation($id: ObjectId!, $document: OrderUpdateInput!) {
    OrdersUpdateOne(_id: $id, document: $document) {
      _id
      privateNotes
      shippingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      billingAddress {
        nbh
        building_floor
        zip
        address
        building_entrance
        street
        district
        tax
        country
        note
        email
        num
        salesRayId
        zip_num
        fax
        building
        settlement
        phone
        building_apartment
      }
      buyerSnapshot {
        name
        phoneNumber
        email
      }
      status
      paymentOptionId
      salesAgentId
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { AddSimple, UploadPhoto } from "@bundles/UIAppBundle/icons";
import { Button } from "../Button";
import { useLongImageUpload } from "./hooks";
import styles from "./LongImageUpload.module.scss";
const LongImageUpload = ({ formInstance, fieldName }) => {
    const { onChange, customRequest, file, onRemoveImage } = useLongImageUpload(formInstance, fieldName);
    return (_jsxs(Ant.Upload, Object.assign({ fileList: file ? [file] : [], customRequest: customRequest, onChange: onChange, listType: "picture-card", className: styles.dragger, showUploadList: false, name: fieldName }, { children: [_jsx(UploadPhoto, { className: styles["upload-icon"], height: 96, width: 114 }, void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "Drag & Drop image here" }), void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "or Select an image from your computer" }), void 0), file && (_jsxs("div", Object.assign({ className: styles["preview"] }, { children: [_jsx(Button, { className: styles["remove-btn"], btnStyle: "outlined", icon: AddSimple, width: "normal", onClick: onRemoveImage() }, void 0), _jsx("img", { src: file.downloadUrl, alt: file.name }, void 0)] }), void 0))] }), void 0));
};
export default LongImageUpload;

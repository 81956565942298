import { PaymentsList } from "../components/List/PaymentsList";
import { PaymentsCreate } from "../components/Create/PaymentsCreate";
import { PaymentsEdit } from "../components/Edit/PaymentsEdit";
import { PaymentsView } from "../components/View/PaymentsView";
import { SettingFilled } from "@ant-design/icons";
export const PAYMENTS_LIST = {
    path: "/admin/payments",
    component: PaymentsList,
    menu: {
        key: "PAYMENTS_LIST",
        label: "management.payments.menu.title",
        icon: SettingFilled,
    },
};
export const PAYMENTS_CREATE = {
    path: "/admin/payments/create",
    component: PaymentsCreate,
};
export const PAYMENTS_EDIT = {
    path: "/admin/payments/:id/edit",
    component: PaymentsEdit,
};
export const PAYMENTS_VIEW = {
    path: "/admin/payments/:id/view",
    component: PaymentsView,
};

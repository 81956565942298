import { useEffect, useState } from "react";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ValidatorService } from "@bluelibs/validator-bundle";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { ProfileDetailsSchema, ResetPasswordSchema, ResetPasswordDefaultValues, } from "./consts";
import { AdminInternalUserProfileUpdate } from "./ProfileDetailsForm/mutations";
import { AdminInternalUserProfileResetPassword } from "./ResetPasswordForm/mutations";
import { useAvatarUpload } from "../Avatar/hooks";
export const useProfileDetailsForm = () => {
    var _a, _b, _c, _d;
    const [profileInput, setProfileInput] = useState(null);
    const [resetPasswordInput, setResetPasswordInput] = useState(ResetPasswordDefaultValues);
    const [profileError, setProfileError] = useState();
    const [resetPasswordError, setResetPasswordError] = useState();
    const [updateProfile] = useMutation(AdminInternalUserProfileUpdate);
    const [resetPassword] = useMutation(AdminInternalUserProfileResetPassword);
    const guardian = useAppGuardian();
    const [isLoading, setIsLoading] = useState(false);
    const { uploadAvatar, setAvatarPreview, AvatarUpload } = useAvatarUpload();
    const tl = useTranslate("profile");
    const t = useTranslate();
    useEffect(() => {
        var _a, _b, _c, _d;
        setAvatarPreview(generateProfileImageLink((_a = guardian.state.user) === null || _a === void 0 ? void 0 : _a.fullName, (_d = (_c = (_b = guardian.state.user) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.file) === null || _d === void 0 ? void 0 : _d.downloadUrl));
    }, [
        (_a = guardian.state.user) === null || _a === void 0 ? void 0 : _a.fullName,
        (_d = (_c = (_b = guardian.state.user) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.file) === null || _d === void 0 ? void 0 : _d.downloadUrl,
    ]);
    const validatorService = use(ValidatorService);
    const onProfileSubmit = async () => {
        var _a, _b;
        try {
            setIsLoading(true);
            await uploadAvatar();
            if (profileInput) {
                await validatorService.validate(profileInput, {
                    model: ProfileDetailsSchema,
                });
                for (const key in profileInput) {
                    if (profileInput[key] === "") {
                        delete profileInput[key];
                    }
                }
                setProfileInput(null);
                await updateProfile({
                    variables: { input: profileInput },
                });
            }
            await guardian.init();
            setAvatarPreview(generateProfileImageLink(guardian.state.user.fullName, (_b = (_a = guardian.state.user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl));
            notification.success({
                message: t("generics.success"),
                description: tl("profile_details.edit_confirmation"),
            });
        }
        catch (err) {
            setProfileError(err);
        }
        finally {
            setIsLoading(false);
        }
    };
    const onResetPasswordSubmit = async () => {
        try {
            await validatorService.validate(resetPasswordInput, {
                model: ResetPasswordSchema,
            });
            setResetPasswordInput(null);
            setResetPasswordError(null);
            await resetPasswordMutation();
        }
        catch (err) {
            setResetPasswordError(err);
        }
    };
    const resetPasswordMutation = async () => {
        try {
            await resetPassword({
                variables: {
                    input: {
                        oldPassword: resetPasswordInput.oldPassword,
                        newPassword: resetPasswordInput.newPassword,
                    },
                },
            });
            notification.success({
                message: t("generics.success"),
                description: tl("reset_password.edit_confirmation"),
            });
        }
        catch (error) {
            notification.error({ message: error.message });
        }
    };
    const fieldProfileError = (inputKey) => {
        if (inputKey === (profileError === null || profileError === void 0 ? void 0 : profileError.path))
            return profileError === null || profileError === void 0 ? void 0 : profileError.errors[0];
        return null;
    };
    const fieldResetPasswordError = (inputKey) => {
        if (inputKey === (resetPasswordError === null || resetPasswordError === void 0 ? void 0 : resetPasswordError.path))
            return resetPasswordError === null || resetPasswordError === void 0 ? void 0 : resetPasswordError.errors[0];
        return null;
    };
    const onChangeProfileValue = (key, value) => {
        setProfileInput({
            ...profileInput,
            [key]: value,
        });
    };
    const onChangeResetPasswordValue = (key, value) => {
        setResetPasswordInput({
            ...resetPasswordInput,
            [key]: value,
        });
    };
    return {
        fieldProfileError,
        profileInput,
        fieldResetPasswordError,
        resetPasswordInput,
        onChangeProfileValue,
        onChangeResetPasswordValue,
        onProfileSubmit,
        onResetPasswordSubmit,
        isLoading,
        AvatarUpload,
    };
};

import { useMemo, useState } from "react";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { useMutation } from "@apollo/client";
import { AdminCategoriesSync } from "./mutations";
import { ProductCategoriesAntTableSmart } from "./ProductCategoriesTableSmart";
export const useProductCategories = () => {
    const t = useTranslate();
    const [api, Provider] = newSmart(ProductCategoriesAntTableSmart);
    const [filters, setFilters] = useState({});
    const [syncCategories, { loading: isLoadingSync }] = useMutation(AdminCategoriesSync);
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const nextFilters = { ...filters, ...newFilters };
            setFilters(nextFilters);
            api.setFlexibleFilters(nextFilters);
        };
    }, [filters]);
    const onClickSync = () => {
        notification.info({
            key: "syncing_product_categories",
            message: t("management.product_categories.list.syncing"),
            duration: 3600,
        });
        syncCategories()
            .then(() => {
            api.load();
            notification.close("syncing_product_categories");
            notification.success({
                message: t("management.product_categories.list.sync_success"),
            });
        })
            .catch(() => {
            notification.close("syncing_product_categories");
            notification.error({
                message: t("management.product_categories.list.sync_error"),
            });
        });
    };
    return {
        api,
        Provider,
        isLoadingSync,
        onFiltersUpdate,
        onClickSync,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ProductTypes } from "@root/api.types";
import { ProductsCollection, ProductCategoriesCollection, InternalUserProfilesCollection, VatRatesCollection, UnitsOfMeasurementCollection, ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
let ProductVariantEditForm = class ProductVariantEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "salesRayId",
                label: t("management.product_variants.fields.salesRayId"),
                name: ["salesRayId"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "name",
                label: t("management.product_variants.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.product_variants.fields.description"),
                name: ["description"],
                required: true,
                initialValue: "",
                component: Ant.Input,
            },
            {
                id: "params",
                label: t("management.product_variants.fields.params"),
                name: ["params"],
                nest: [],
            },
            {
                id: "sku",
                label: t("management.product_variants.fields.sku"),
                name: ["sku"],
                component: Ant.Input,
            },
            {
                id: "seoFields",
                label: t("management.product_variants.fields.seoFields"),
                name: ["seoFields"],
                nest: [
                    {
                        id: "pageTitle",
                        label: t("management.product_variants.fields.seoFields.pageTitle"),
                        name: ["seoFields", "pageTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "metaDescription",
                        label: t("management.product_variants.fields.seoFields.metaDescription"),
                        name: ["seoFields", "metaDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "keywords",
                        label: t("management.product_variants.fields.seoFields.keywords"),
                        name: ["seoFields", "keywords"],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "seoTitle",
                        label: t("management.product_variants.fields.seoFields.seoTitle"),
                        name: ["seoFields", "seoTitle"],
                        component: Ant.Input,
                    },
                    {
                        id: "seoDescription",
                        label: t("management.product_variants.fields.seoFields.seoDescription"),
                        name: ["seoFields", "seoDescription"],
                        component: Ant.Input,
                    },
                    {
                        id: "slug",
                        label: t("management.product_variants.fields.seoFields.slug"),
                        name: ["seoFields", "slug"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "availability",
                label: t("management.product_variants.fields.availability"),
                name: ["availability"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "OUT_OF_STOCK", label: "Out Of Stock" },
                        { value: "IN_STOCK", label: "In Stock" },
                        { value: "IN_STOCK_EXTERNAL", label: "In Stock External" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.product_variants.fields.availability") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.product_variants.fields.availability_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.product_variants.fields.availability_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "isPublished",
                label: t("management.product_variants.fields.isPublished"),
                name: ["isPublished"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "publishedAt",
                label: t("management.product_variants.fields.publishedAt"),
                name: ["publishedAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: false }, void 0) }), void 0)),
            },
            {
                id: "discountPercentage",
                label: t("management.product_variants.fields.discountPercentage"),
                name: ["discountPercentage"],
                component: Ant.InputNumber,
            },
            {
                id: "discountActiveUntil",
                label: t("management.product_variants.fields.discountActiveUntil"),
                name: ["discountActiveUntil"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: false }, void 0) }), void 0)),
            },
            {
                id: "tags",
                label: t("management.product_variants.fields.tags"),
                name: ["tags"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "attributesValues",
                label: t("management.product_variants.fields.attributesValues"),
                name: ["attributesValues"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "key",
                        label: t("management.product_variants.fields.attributesValues.key"),
                        name: ["attributesValues", "key"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "value",
                        label: t("management.product_variants.fields.attributesValues.value"),
                        name: ["attributesValues", "value"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "suffix",
                        label: t("management.product_variants.fields.attributesValues.suffix"),
                        name: ["attributesValues", "suffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "isNumber",
                        label: t("management.product_variants.fields.attributesValues.isNumber"),
                        name: ["attributesValues", "isNumber"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                ],
                initialValue: [],
            },
            {
                id: "daysBeforeAllowingReview",
                label: t("management.product_variants.fields.daysBeforeAllowingReview"),
                name: ["daysBeforeAllowingReview"],
                initialValue: 0,
                component: Ant.InputNumber,
            },
            {
                id: "brand",
                label: t("management.product_variants.fields.brand"),
                name: ["brand"],
                component: Ant.Input,
            },
            {
                id: "type",
                label: t("management.product_variants.fields.type"),
                name: ["type"],
                initialValue: ProductTypes.PHYSICAL,
                render: (props) => {
                    const options = [
                        { value: "DIGITAL", label: "Digital" },
                        { value: "PHYSICAL", label: "Physical" },
                        { value: "SERVICE", label: "Service" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.product_variants.fields.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.product_variants.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.product_variants.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "rating",
                label: t("management.product_variants.fields.rating"),
                name: ["rating"],
                initialValue: 0,
                component: Ant.InputNumber,
            },
            {
                id: "country",
                label: t("management.product_variants.fields.country"),
                name: ["country"],
                component: Ant.Input,
            },
            {
                id: "salesRayImageUrl",
                label: t("management.product_variants.fields.salesRayImageUrl"),
                name: ["salesRayImageUrl"],
                component: Ant.Input,
            },
            {
                id: "height",
                label: t("management.product_variants.fields.height"),
                name: ["height"],
                component: Ant.Input,
            },
            {
                id: "width",
                label: t("management.product_variants.fields.width"),
                name: ["width"],
                component: Ant.Input,
            },
            {
                id: "length_",
                label: t("management.product_variants.fields.length_"),
                name: ["length_"],
                component: Ant.Input,
            },
            {
                id: "netWeight",
                label: t("management.product_variants.fields.netWeight"),
                name: ["netWeight"],
                component: Ant.Input,
            },
            {
                id: "grossWeight",
                label: t("management.product_variants.fields.grossWeight"),
                name: ["grossWeight"],
                component: Ant.Input,
            },
            {
                id: "coefficient",
                label: t("management.product_variants.fields.coefficient"),
                name: ["coefficient"],
                component: Ant.InputNumber,
            },
            {
                id: "model3DId",
                label: t("management.product_variants.fields.model3D"),
                name: ["model3DId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "defaultImageId",
                label: t("management.product_variants.fields.defaultImage"),
                name: ["defaultImageId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "imagesId",
                label: t("management.product_variants.fields.images"),
                name: ["imagesId"],
                component: UIComponents.AdminFileGroupUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "productId",
                label: t("management.product_variants.fields.product"),
                name: ["productId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "categoryId",
                label: t("management.product_variants.fields.category"),
                name: ["categoryId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "publishedById",
                label: t("management.product_variants.fields.publishedBy"),
                name: ["publishedById"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", required: false }, void 0) }), void 0)),
            },
            {
                id: "vatRateId",
                label: t("management.product_variants.fields.vatRate"),
                name: ["vatRateId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: VatRatesCollection, field: "name", required: false }, void 0) }), void 0)),
            },
            {
                id: "unitOfMeasurementId",
                label: t("management.product_variants.fields.unitOfMeasurement"),
                name: ["unitOfMeasurementId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UnitsOfMeasurementCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            salesRayId: 1,
            name: 1,
            description: 1,
            params: 1,
            sku: 1,
            seoFields: {
                pageTitle: 1,
                metaDescription: 1,
                keywords: 1,
                seoTitle: 1,
                seoDescription: 1,
                slug: 1,
            },
            availability: 1,
            isPublished: 1,
            publishedAt: 1,
            discountPercentage: 1,
            discountActiveUntil: 1,
            tags: 1,
            attributesValues: {
                key: 1,
                value: 1,
                suffix: 1,
                isNumber: 1,
            },
            daysBeforeAllowingReview: 1,
            brand: 1,
            type: 1,
            rating: 1,
            country: 1,
            salesRayImageUrl: 1,
            height: 1,
            width: 1,
            length_: 1,
            netWeight: 1,
            grossWeight: 1,
            coefficient: 1,
            model3D: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            model3DId: 1,
            defaultImage: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            defaultImageId: 1,
            images: {
                _id: 1,
                name: 1,
                files: {
                    downloadUrl: 1,
                    name: 1,
                },
            },
            imagesId: 1,
            product: {
                _id: 1,
                name: 1,
            },
            productId: 1,
            category: {
                _id: 1,
                name: 1,
            },
            categoryId: 1,
            publishedBy: {
                _id: 1,
                fullName: 1,
            },
            publishedById: 1,
            vatRate: {
                _id: 1,
                name: 1,
            },
            vatRateId: 1,
            unitOfMeasurement: {
                _id: 1,
            },
            unitOfMeasurementId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_variants.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProductVariantsCollection),
    __metadata("design:type", typeof (_a = typeof ProductVariantsCollection !== "undefined" && ProductVariantsCollection) === "function" ? _a : Object)
], ProductVariantEditForm.prototype, "collection", void 0);
ProductVariantEditForm = __decorate([
    Service({ transient: true })
], ProductVariantEditForm);
export { ProductVariantEditForm };

import { PaymentOptionsList } from "../components/List/PaymentOptionsList";
import { PaymentOptionsCreate } from "../components/Create/PaymentOptionsCreate";
import { PaymentOptionsEdit } from "../components/Edit/PaymentOptionsEdit";
import { PaymentOptionsView } from "../components/View/PaymentOptionsView";
import { SettingFilled } from "@ant-design/icons";
export const PAYMENT_OPTIONS_LIST = {
    path: "/admin/payment-options",
    component: PaymentOptionsList,
    menu: {
        key: "PAYMENT_OPTIONS_LIST",
        label: "management.payment_options.menu.title",
        icon: SettingFilled,
    },
};
export const PAYMENT_OPTIONS_CREATE = {
    path: "/admin/payment-options/create",
    component: PaymentOptionsCreate,
};
export const PAYMENT_OPTIONS_EDIT = {
    path: "/admin/payment-options/:id/edit",
    component: PaymentOptionsEdit,
};
export const PAYMENT_OPTIONS_VIEW = {
    path: "/admin/payment-options/:id/view",
    component: PaymentOptionsView,
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProductVariantsCollection, OrdersCollection, Product3DModelCollection, OrderItemsCollection, } from "@bundles/UIAppBundle/collections";
let OrderItemCreateForm = class OrderItemCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "productSnapshot",
                label: t("management.order_items.fields.productSnapshot"),
                name: ["productSnapshot"],
                required: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.order_items.fields.productSnapshot.name"),
                        name: ["productSnapshot", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "description",
                        label: t("management.order_items.fields.productSnapshot.description"),
                        name: ["productSnapshot", "description"],
                        component: Ant.Input,
                    },
                    {
                        id: "sku",
                        label: t("management.order_items.fields.productSnapshot.sku"),
                        name: ["productSnapshot", "sku"],
                        component: Ant.Input,
                    },
                    {
                        id: "imageUrl",
                        label: t("management.order_items.fields.productSnapshot.imageUrl"),
                        name: ["productSnapshot", "imageUrl"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "status",
                label: t("management.order_items.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "OPEN", label: "Open" },
                        {
                            value: "WAITING_EXTERNAL_STOCK",
                            label: "Waiting External Stock",
                        },
                        { value: "SHIPPED", label: "Shipped" },
                        { value: "DELIVERED", label: "Delivered" },
                        { value: "CANCELED", label: "Canceled" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.order_items.fields.status") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.order_items.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.order_items.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "appliedPrice",
                label: t("management.order_items.fields.appliedPrice"),
                name: ["appliedPrice"],
                nest: [
                    {
                        id: "currency",
                        label: t("management.order_items.fields.appliedPrice.currency"),
                        name: ["appliedPrice", "currency"],
                        component: Ant.Input,
                    },
                    {
                        id: "gross",
                        label: t("management.order_items.fields.appliedPrice.gross"),
                        name: ["appliedPrice", "gross"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "net",
                        label: t("management.order_items.fields.appliedPrice.net"),
                        name: ["appliedPrice", "net"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "tax",
                        label: t("management.order_items.fields.appliedPrice.tax"),
                        name: ["appliedPrice", "tax"],
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "appliedDiscounts",
                label: t("management.order_items.fields.appliedDiscounts"),
                name: ["appliedDiscounts"],
                isList: true,
                nest: [
                    {
                        id: "discountSource",
                        label: t("management.order_items.fields.appliedDiscounts.discountSource"),
                        name: ["appliedDiscounts", "discountSource"],
                        required: true,
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "PRODUCT", label: "Product" },
                                { value: "CATEGORY", label: "Category" },
                                { value: "ORDER_ITEM_COUNT", label: "Order Item Count" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.order_items.fields.appliedDiscounts.discountSource") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.order_items.fields.appliedDiscounts.discountSource_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.order_items.fields.appliedDiscounts.discountSource_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "categoryId",
                        label: t("management.order_items.fields.appliedDiscounts.categoryId"),
                        name: ["appliedDiscounts", "categoryId"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "discountPercentage",
                        label: t("management.order_items.fields.appliedDiscounts.discountPercentage"),
                        name: ["appliedDiscounts", "discountPercentage"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
                initialValue: [],
            },
            {
                id: "count",
                label: t("management.order_items.fields.count"),
                name: ["count"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "vatPercent",
                label: t("management.order_items.fields.vatPercent"),
                name: ["vatPercent"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "appliedVatRate",
                label: t("management.order_items.fields.appliedVatRate"),
                name: ["appliedVatRate"],
                nest: [
                    {
                        id: "name",
                        label: t("management.order_items.fields.appliedVatRate.name"),
                        name: ["appliedVatRate", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "percentage",
                        label: t("management.order_items.fields.appliedVatRate.percentage"),
                        name: ["appliedVatRate", "percentage"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "description",
                        label: t("management.order_items.fields.appliedVatRate.description"),
                        name: ["appliedVatRate", "description"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "productVariantId",
                label: t("management.order_items.fields.productVariant"),
                name: ["productVariantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductVariantsCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "orderId",
                label: t("management.order_items.fields.order"),
                name: ["orderId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrdersCollection, field: "orderNumber", required: true }, void 0) }), void 0)),
            },
            {
                id: "product3DModelId",
                label: t("management.order_items.fields.product3DModel"),
                name: ["product3DModelId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: Product3DModelCollection, field: "name", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.order_items.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.ORDER_ITEMS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.ORDER_ITEMS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.ORDER_ITEMS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => OrderItemsCollection),
    __metadata("design:type", typeof (_a = typeof OrderItemsCollection !== "undefined" && OrderItemsCollection) === "function" ? _a : Object)
], OrderItemCreateForm.prototype, "collection", void 0);
OrderItemCreateForm = __decorate([
    Service({ transient: true })
], OrderItemCreateForm);
export { OrderItemCreateForm };

import { gql } from "@apollo/client";
export const GetProductCategories = gql `
  query ProductCategoriesFind($query: QueryInput) {
    ProductCategoriesFind(query: $query) {
      _id
      name
    }
  }
`;
export const GetProducts = gql `
  query ProductsFind($query: QueryInput) {
    ProductsFind(query: $query) {
      _id
      name
    }
  }
`;
export const GetProductVariants = gql `
  query ProductVariantsFind($query: QueryInput) {
    ProductVariantsFind(query: $query) {
      _id
      name
    }
  }
`;
export const GetTeamMembers = gql `
  query InternalUserProfilesFind($query: QueryInput) {
    InternalUserProfilesFind(query: $query) {
      _id
      fullName
    }
  }
`;

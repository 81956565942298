import { Collection, } from "@bluelibs/x-ui";
import { Return } from "@root/api.types";
import { EndUserProfilesCollection, OrdersCollection, InternalUserProfilesCollection, InvoicesCollection, PaymentOptionsCollection, ShippingOptionsCollection, } from "@bundles/UIAppBundle/collections";
export class ReturnsCollection extends Collection {
    getName() {
        return "Returns";
    }
    getInputs() {
        return {
            insert: "ReturnInsertInput!",
            update: "ReturnUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "endUser",
                field: "endUserId",
            },
            {
                collection: () => OrdersCollection,
                name: "order",
                field: "orderId",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "admin",
                field: "adminId",
            },
            {
                collection: () => InvoicesCollection,
                name: "invoice",
                field: "invoiceId",
            },
            {
                collection: () => PaymentOptionsCollection,
                name: "paymentOption",
                field: "paymentOptionId",
            },
            {
                collection: () => ShippingOptionsCollection,
                name: "shippingOption",
                field: "shippingOptionId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { DownChevron } from "@bundles/UIAppBundle/icons";
import styles from "./DatePicker.module.scss";
const DatePicker = ({ label, range = false, id, ...rest }) => {
    const props = {
        superNextIcon: null,
        superPrevIcon: null,
        format: "DD/MM/YYYY",
        placement: "bottomRight",
    };
    return (_jsxs("div", Object.assign({ className: styles.container, id: id }, { children: [_jsx("label", { children: label }, void 0), range ? (_jsx(Ant.DatePicker.RangePicker, Object.assign({}, rest, props, { separator: "-" }), void 0)) : (_jsx(Ant.DatePicker, Object.assign({}, rest, props, { showToday: false }), void 0)), _jsx(DownChevron, { className: styles["dropdown-arrow"] }, void 0)] }), void 0));
};
export default DatePicker;

import "./i18n";
import { REVIEWS_LIST as BASE_REVIEWS_LIST, REVIEWS_CREATE as BASE_REVIEWS_CREATE, REVIEWS_EDIT as BASE_REVIEWS_EDIT, REVIEWS_VIEW as BASE_REVIEWS_VIEW, } from "./config/routes";
export const REVIEWS_LIST = {
    ...BASE_REVIEWS_LIST,
};
export const REVIEWS_CREATE = {
    ...BASE_REVIEWS_CREATE,
};
export const REVIEWS_EDIT = {
    ...BASE_REVIEWS_EDIT,
};
export const REVIEWS_VIEW = {
    ...BASE_REVIEWS_VIEW,
};

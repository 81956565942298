import { jsx as _jsx } from "react/jsx-runtime";
import { AddProductsToOrder } from "./components/AddProductsToOrder";
import { AddClient } from "./components/AddClient";
import { Confirmation } from "./components/Confirmation";
import { PaymentAndShipping } from "./components/PaymentAndShipping";
import { OrderAddressDetails } from "./components/OrderAddressDetails";
import { AddressSchema } from "./components/AddClient/consts";
export const getSteps = (formInstance, validatorService) => [
    {
        title: "Select Client for the Order",
        content: _jsx(AddClient, { stepForm: formInstance }, void 0),
        validator: async () => {
            var _a;
            const buyerInfo = formInstance.getFieldValue("buyerInfo");
            if (!buyerInfo)
                return { success: false };
            if (!((_a = buyerInfo.savedAddresses) === null || _a === void 0 ? void 0 : _a.length))
                return {
                    success: false,
                    message: "Client does not have any shipping addresses",
                };
            try {
                await validatorService.validate(buyerInfo.billingAddress, {
                    model: AddressSchema,
                });
                return { success: true };
            }
            catch (error) {
                return {
                    success: false,
                    message: "Client's billing info has missing fields",
                };
            }
        },
        error: "Please select one of the above",
    },
    {
        title: "Add Products To the Order",
        content: _jsx(AddProductsToOrder, { stepForm: formInstance }, void 0),
        validator: () => {
            var _a;
            if ((_a = formInstance.getFieldValue("items")) === null || _a === void 0 ? void 0 : _a.length)
                return { success: true };
            else
                return { success: false };
        },
        error: "Please select atleast one of the above",
    },
    {
        title: "ORDER DETAILS",
        content: _jsx(OrderAddressDetails, { editable: true, stepForm: formInstance }, void 0),
        validator: () => {
            if (formInstance.getFieldValue("shippingAddress")) {
                return { success: true };
            }
            else
                return { success: false };
        },
        error: "Please select shipping address one of the above",
    },
    {
        title: "PAYMENT AND SHIPPING",
        content: _jsx(PaymentAndShipping, { stepForm: formInstance }, void 0),
        validator: () => {
            if (formInstance.getFieldValue("shippingOptionId") &&
                formInstance.getFieldValue("paymentOptionId"))
                return { success: true };
            else
                return {
                    success: false,
                };
        },
        error: "Please select the payment and shipping provider",
    },
    {
        title: "CONFIRMATION",
        content: _jsx(Confirmation, { stepForm: formInstance }, void 0),
    },
];

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { InvoiceViewer } from "../../config/InvoiceViewer";
import { features } from "../../config/features";
import { Topbar } from "@bundles/UIAppBundle/components/Topbar";
import { Download } from "@bundles/UIAppBundle/icons";
import { ViewInvoice } from "@bundles/UIAppBundle/components/ViewInvoice";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { useInvoiceView } from "./hooks";
import { invoiceAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function InvoicesView(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { invoice, isLoading, error, onInvoiceDownload } = useInvoiceView(props.id);
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || !invoice) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(InvoicesViewComponent, { invoice: invoice }, void 0);
        }
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: invoiceAccessRoles }, { children: [_jsx(Seo, { data: seoData.invoice.view }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Topbar, { title: isLoading ? "" : `Invoice ${invoice === null || invoice === void 0 ? void 0 : invoice.invoiceId}`, btnText: "Download", btnIcon: Download, btnOnClick: () => {
                            onInvoiceDownload(invoice === null || invoice === void 0 ? void 0 : invoice.downloadUrl);
                        } }, void 0), content] }, void 0)] }), void 0));
}
export function InvoicesViewComponent(props) {
    const viewer = use(InvoiceViewer, { transient: true });
    viewer.setDocument(props.invoice);
    viewer.build();
    return _jsx(ViewInvoice, { invoice: props.invoice }, void 0);
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    if (features.edit) {
        actions.push(_jsx(Link, Object.assign({ to: router.path(Routes.INVOICES_EDIT, {
                params: { id },
            }) }, { children: _jsx(Ant.Button, { children: t("generics.edit") }, void 0) }), "edit"));
    }
    if (features.delete) {
        actions.push(_jsx(Ant.Popconfirm, Object.assign({ title: "Are you sure you want to delete this Invoice?", onConfirm: () => {
                collection.deleteOne(id).then(() => {
                    router.go(Routes.INVOICES_LIST);
                    Ant.notification.success({
                        message: "Success",
                        description: "You have deleted the Invoice",
                    });
                });
            } }, { children: _jsx(Ant.Button, Object.assign({ danger: true }, { children: t("generics.delete") }), void 0) }), "delete"));
    }
    return actions;
}

import { gql } from "@apollo/client";
export const uploadLogoMutation = gql `
  mutation AdminShopSettingsUploadLogo(
    $input: AdminShopSettingsUploadLogoInput!
  ) {
    AdminShopSettingsUploadLogo(input: $input) {
      _id
    }
  }
`;
export const uploadFaviconMutation = gql `
  mutation AdminShopSettingsUploadFavicon(
    $input: AdminShopSettingsUploadFaviconInput!
  ) {
    AdminShopSettingsUploadFavicon(input: $input) {
      _id
    }
  }
`;

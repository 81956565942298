import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Form, notification } from "antd";
import { ORDER_CREATE } from "@bundles/UIAppBundle/mutations/order.mutation";
import { getSteps } from "./formSteps";
import { ValidatorService } from "@bluelibs/validator-bundle";
import { use } from "@bluelibs/x-ui";
export const useCreateOrder = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [error, setError] = useState("");
    const [createOrder] = useMutation(ORDER_CREATE);
    const [stepForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const validatorService = use(ValidatorService);
    const steps = getSteps(stepForm, validatorService);
    const next = async () => {
        const { success, message } = await steps[currentStep].validator();
        if (success) {
            setCurrentStep(currentStep + 1);
            setError("");
        }
        else {
            setError(message || steps[currentStep].error);
        }
    };
    const prev = () => {
        setCurrentStep(currentStep - 1);
        setError("");
    };
    const onConfirm = () => {
        return onFormSubmit(stepForm);
    };
    const onFormSubmit = (stepForm) => {
        setIsLoading(true);
        const formData = stepForm.getFieldsValue(true);
        const { billingAddress, shippingAddress, items, buyerInfo, privateNotes, paymentOptionId, shippingOptionId, } = formData;
        return createOrder({
            variables: {
                input: {
                    billingAddress: {
                        ...billingAddress,
                        __typename: undefined,
                    },
                    shippingAddress: {
                        ...shippingAddress,
                        __typename: undefined,
                    },
                    buyerId: buyerInfo._id,
                    buyerSnapshot: {
                        name: buyerInfo.fullName,
                        phoneNumber: buyerInfo.phoneNumber,
                        email: buyerInfo.email,
                    },
                    items: items.map(({ _id, quantity }) => ({
                        productVariantId: _id,
                        count: quantity,
                    })),
                    privateNotes,
                    paymentOptionId,
                    shippingOptionId,
                },
            },
        })
            .then(() => {
            notification.success({
                message: "Order created",
            });
            stepForm.resetFields();
            setCurrentStep(0);
        })
            .catch((error) => notification.error({
            message: "Order creation failed",
            description: error.message,
        }))
            .finally(() => setIsLoading(false));
    };
    return {
        isLoading,
        currentStep,
        setCurrentStep,
        error,
        onFormSubmit,
        steps,
        stepForm,
        next,
        prev,
        onConfirm,
    };
};

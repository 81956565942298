import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import styles from "./Disclosure.module.scss";
const MyDisclosure = ({ className, dropdownButton, position = "right", classes: { container = "", wrapper = "" } = {}, children, paddingNone = false, }) => {
    const dropdownContainerClasses = classNames(styles["dropdown-container"], wrapper, {
        [styles["padding--none"]]: paddingNone,
    });
    return (_jsxs(Disclosure, Object.assign({ as: "div", className: classNames(styles["dropdown-section"], className) }, { children: [_jsx(Disclosure.Button, Object.assign({ as: "div" }, { children: dropdownButton }), void 0), _jsx(Disclosure.Panel, Object.assign({ className: classNames(styles.dropdown, styles[position], container) }, { children: _jsx("div", Object.assign({ className: dropdownContainerClasses }, { children: children }), void 0) }), void 0)] }), "dropdown-key"));
};
export default MyDisclosure;

import { useQuery } from "@apollo/client";
import { END_USER_GET_PROFILE } from "./queries";
export const useEndUserProfileOrdersList = (id) => {
    const { data } = useQuery(END_USER_GET_PROFILE, {
        variables: {
            input: {
                endUserProfileId: id,
            },
        },
    });
    const orderList = data === null || data === void 0 ? void 0 : data.AdminEndUserProfileGet.orders;
    return { orderList };
};

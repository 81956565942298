import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { useRouter, useUIComponents } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { BackCircle } from "@bundles/UIAppBundle/icons";
import { useShopConfgurationLayout } from "./hooks";
import styles from "./ClientPageLayout.module.scss";
const ClientPageLayout = ({ id, children }) => {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const { btnClasses, links } = useShopConfgurationLayout();
    return (_jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles["header"] }, { children: _jsx(Ant.PageHeader, { title: "CLIENT DETAILS", onBack: () => window.history.back(), backIcon: _jsx(BackCircle, {}, void 0), className: styles["header-content"] }, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["cards-wrapper"] }, { children: [_jsx(Card, Object.assign({ className: styles["control-panel"] }, { children: _jsx("ul", Object.assign({ className: classNames(styles.list, "cc-custom-list") }, { children: links.map(({ route, label, icon }) => {
                                    const Icon = icon;
                                    return (_jsx("li", Object.assign({ className: styles["list-item"] }, { children: _jsx(Button, Object.assign({ onClick: () => router.go(route, {
                                                params: { id },
                                            }), className: btnClasses(route.path), btnStyle: "white" }, { children: _jsxs(_Fragment, { children: [_jsx(Icon, { width: 24 }, void 0), label] }, void 0) }), void 0) }), route.name));
                                }) }), void 0) }), "card-key"), _jsx("div", Object.assign({ className: styles["component-wrapper"] }, { children: children }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default ClientPageLayout;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { Edit } from "@bundles/UIAppBundle/icons";
import { MARKETING_INTEGRATION_DETAILS } from "@bundles/UIAppBundle/routes";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { Button, Card, CustomTag } from "../../..";
import { useIntegrations } from "./hooks";
import styles from "./IntegrationList.module.scss";
const IntegrationList = () => {
    const { integrations } = useIntegrations();
    const t = useTranslate();
    const router = useRouter();
    useShopSettings();
    return (_jsx("div", { children: integrations.map(({ label, status, statusColor, integrationOption }, idx) => (_jsxs(Card, Object.assign({ className: styles["info-card"] }, { children: [_jsx("div", Object.assign({ className: styles["card-item"] }, { children: label }), void 0), _jsx("div", Object.assign({ className: styles["card-item"] }, { children: _jsx(CustomTag, { value: status, color: statusColor, variant: "filled" }, void 0) }), void 0), _jsx("div", { children: _jsx(Button, Object.assign({ className: styles["edit-button"], icon: Edit, onClick: () => router.go(MARKETING_INTEGRATION_DETAILS, {
                            params: { integrationOption },
                        }) }, { children: t("generics.edit") }), void 0) }, void 0)] }), idx))) }, void 0));
};
export default IntegrationList;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { GetInTouchsCollection, } from "@bundles/UIAppBundle/collections";
let GetInTouchEditForm = class GetInTouchEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.get_in_touchs.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "address",
                label: t("management.get_in_touchs.fields.address"),
                name: ["address"],
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.get_in_touchs.fields.address.salesRayId"),
                        name: ["address", "salesRayId"],
                        component: Ant.Input,
                    },
                    {
                        id: "firstName",
                        label: t("management.get_in_touchs.fields.address.firstName"),
                        name: ["address", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.get_in_touchs.fields.address.lastName"),
                        name: ["address", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.get_in_touchs.fields.address.phoneNumber"),
                        name: ["address", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.get_in_touchs.fields.address.streetAddressLine1"),
                        name: ["address", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.get_in_touchs.fields.address.streetAddressLine2"),
                        name: ["address", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.get_in_touchs.fields.address.email"),
                        name: ["address", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.get_in_touchs.fields.address.city"),
                        name: ["address", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.get_in_touchs.fields.address.town"),
                        name: ["address", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.get_in_touchs.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.get_in_touchs.fields.address.zipcode"),
                        name: ["address", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.get_in_touchs.fields.address.companyName"),
                        name: ["address", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.get_in_touchs.fields.address.vatNumber"),
                        name: ["address", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "phone",
                label: t("management.get_in_touchs.fields.phone"),
                name: ["phone"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.get_in_touchs.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.get_in_touchs.fields.description"),
                name: ["description"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "protectData",
                label: t("management.get_in_touchs.fields.protectData"),
                name: ["protectData"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            address: {
                salesRayId: 1,
                firstName: 1,
                lastName: 1,
                phoneNumber: 1,
                streetAddressLine1: 1,
                streetAddressLine2: 1,
                email: 1,
                city: 1,
                town: 1,
                country: 1,
                zipcode: 1,
                companyName: 1,
                vatNumber: 1,
            },
            phone: 1,
            email: 1,
            description: 1,
            protectData: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.get_in_touchs.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => GetInTouchsCollection),
    __metadata("design:type", typeof (_a = typeof GetInTouchsCollection !== "undefined" && GetInTouchsCollection) === "function" ? _a : Object)
], GetInTouchEditForm.prototype, "collection", void 0);
GetInTouchEditForm = __decorate([
    Service({ transient: true })
], GetInTouchEditForm);
export { GetInTouchEditForm };

import { NotFound } from "@bundles/UIAppBundle/overrides";
import { PRODUCT_3_D_TEXT_LIST as BASE_PRODUCT_3_D_TEXT_LIST, PRODUCT_3_D_TEXT_CREATE as BASE_PRODUCT_3_D_TEXT_CREATE, PRODUCT_3_D_TEXT_EDIT as BASE_PRODUCT_3_D_TEXT_EDIT, PRODUCT_3_D_TEXT_VIEW as BASE_PRODUCT_3_D_TEXT_VIEW, } from "./config/routes";
import "./i18n";
export const PRODUCT_3_D_TEXT_LIST = {
    ...BASE_PRODUCT_3_D_TEXT_LIST,
    component: NotFound,
};
export const PRODUCT_3_D_TEXT_CREATE = {
    ...BASE_PRODUCT_3_D_TEXT_CREATE,
    component: NotFound,
};
export const PRODUCT_3_D_TEXT_EDIT = {
    ...BASE_PRODUCT_3_D_TEXT_EDIT,
    component: NotFound,
};
export const PRODUCT_3_D_TEXT_VIEW = {
    ...BASE_PRODUCT_3_D_TEXT_VIEW,
    component: NotFound,
};

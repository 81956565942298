export const ShippingAddressFields = [
    "country",
    "district",
    "settlement",
    "street",
    "num",
    "zip",
    "address",
    "nbh",
    "building",
    "building_entrance",
    "building_floor",
    "building_apartment",
    "zip_num",
    "email",
    "phone",
    "fax",
    "note",
];

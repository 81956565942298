import { UnitsOfMeasurementList } from "../components/List/UnitsOfMeasurementList";
import { UnitsOfMeasurementCreate } from "../components/Create/UnitsOfMeasurementCreate";
import { UnitsOfMeasurementEdit } from "../components/Edit/UnitsOfMeasurementEdit";
import { UnitsOfMeasurementView } from "../components/View/UnitsOfMeasurementView";
import { SettingFilled } from "@ant-design/icons";
export const UNITS_OF_MEASUREMENT_LIST = {
    path: "/admin/units-of-measurement",
    component: UnitsOfMeasurementList,
    menu: {
        key: "UNITS_OF_MEASUREMENT_LIST",
        label: "management.units_of_measurement.menu.title",
        icon: SettingFilled,
    },
};
export const UNITS_OF_MEASUREMENT_CREATE = {
    path: "/admin/units-of-measurement/create",
    component: UnitsOfMeasurementCreate,
};
export const UNITS_OF_MEASUREMENT_EDIT = {
    path: "/admin/units-of-measurement/:id/edit",
    component: UnitsOfMeasurementEdit,
};
export const UNITS_OF_MEASUREMENT_VIEW = {
    path: "/admin/units-of-measurement/:id/view",
    component: UnitsOfMeasurementView,
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ShopSettingsCollection, } from "@bundles/UIAppBundle/collections";
let ShopSettingEditForm = class ShopSettingEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "shopName",
                label: t("management.shop_settings.fields.shopName"),
                name: ["shopName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "companyName",
                label: t("management.shop_settings.fields.companyName"),
                name: ["companyName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "companyIdentificationNumber",
                label: t("management.shop_settings.fields.companyIdentificationNumber"),
                name: ["companyIdentificationNumber"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "billingAddress",
                label: t("management.shop_settings.fields.billingAddress"),
                name: ["billingAddress"],
                required: true,
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.shop_settings.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        component: Ant.Input,
                    },
                    {
                        id: "firstName",
                        label: t("management.shop_settings.fields.billingAddress.firstName"),
                        name: ["billingAddress", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.shop_settings.fields.billingAddress.lastName"),
                        name: ["billingAddress", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.shop_settings.fields.billingAddress.phoneNumber"),
                        name: ["billingAddress", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.shop_settings.fields.billingAddress.streetAddressLine1"),
                        name: ["billingAddress", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.shop_settings.fields.billingAddress.streetAddressLine2"),
                        name: ["billingAddress", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.shop_settings.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.shop_settings.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.shop_settings.fields.billingAddress.town"),
                        name: ["billingAddress", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.shop_settings.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.shop_settings.fields.billingAddress.zipcode"),
                        name: ["billingAddress", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.shop_settings.fields.billingAddress.companyName"),
                        name: ["billingAddress", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.shop_settings.fields.billingAddress.vatNumber"),
                        name: ["billingAddress", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "defaultCurrency",
                label: t("management.shop_settings.fields.defaultCurrency"),
                name: ["defaultCurrency"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "firstInvoiceNumber",
                label: t("management.shop_settings.fields.firstInvoiceNumber"),
                name: ["firstInvoiceNumber"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "freeShippingAfterAmountLimit",
                label: t("management.shop_settings.fields.freeShippingAfterAmountLimit"),
                name: ["freeShippingAfterAmountLimit"],
                component: Ant.InputNumber,
            },
            {
                id: "invoiceGenerationPrefix",
                label: t("management.shop_settings.fields.invoiceGenerationPrefix"),
                name: ["invoiceGenerationPrefix"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "attributeOptions",
                label: t("management.shop_settings.fields.attributeOptions"),
                name: ["attributeOptions"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.shop_settings.fields.attributeOptions.name"),
                        name: ["attributeOptions", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "type",
                        label: t("management.shop_settings.fields.attributeOptions.type"),
                        name: ["attributeOptions", "type"],
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "string", label: "String" },
                                { value: "number", label: "Number" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.shop_settings.fields.attributeOptions.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.shop_settings.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.shop_settings.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "isRequired",
                        label: t("management.shop_settings.fields.attributeOptions.isRequired"),
                        name: ["attributeOptions", "isRequired"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "isEnum",
                        label: t("management.shop_settings.fields.attributeOptions.isEnum"),
                        name: ["attributeOptions", "isEnum"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "enumValues",
                        label: t("management.shop_settings.fields.attributeOptions.enumValues"),
                        name: ["attributeOptions", "enumValues"],
                        initialValue: [],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "isRange",
                        label: t("management.shop_settings.fields.attributeOptions.isRange"),
                        name: ["attributeOptions", "isRange"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "defaultValue",
                        label: t("management.shop_settings.fields.attributeOptions.defaultValue"),
                        name: ["attributeOptions", "defaultValue"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "defaultSuffix",
                        label: t("management.shop_settings.fields.attributeOptions.defaultSuffix"),
                        name: ["attributeOptions", "defaultSuffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "rangeMin",
                        label: t("management.shop_settings.fields.attributeOptions.rangeMin"),
                        name: ["attributeOptions", "rangeMin"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "rangeMax",
                        label: t("management.shop_settings.fields.attributeOptions.rangeMax"),
                        name: ["attributeOptions", "rangeMax"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
                initialValue: [],
            },
            {
                id: "analyticsURLs",
                label: t("management.shop_settings.fields.analyticsURLs"),
                name: ["analyticsURLs"],
                nest: [
                    {
                        id: "google",
                        label: t("management.shop_settings.fields.analyticsURLs.google"),
                        name: ["analyticsURLs", "google"],
                        component: Ant.Input,
                    },
                    {
                        id: "facebookPixel",
                        label: t("management.shop_settings.fields.analyticsURLs.facebookPixel"),
                        name: ["analyticsURLs", "facebookPixel"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "reviewsEnabled",
                label: t("management.shop_settings.fields.reviewsEnabled"),
                name: ["reviewsEnabled"],
                initialValue: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.shop_settings.fields.logo"),
                name: ["logoId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "faviconId",
                label: t("management.shop_settings.fields.favicon"),
                name: ["faviconId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            shopName: 1,
            companyName: 1,
            companyIdentificationNumber: 1,
            billingAddress: {
                salesRayId: 1,
                firstName: 1,
                lastName: 1,
                phoneNumber: 1,
                streetAddressLine1: 1,
                streetAddressLine2: 1,
                email: 1,
                city: 1,
                town: 1,
                country: 1,
                zipcode: 1,
                companyName: 1,
                vatNumber: 1,
            },
            defaultCurrency: 1,
            firstInvoiceNumber: 1,
            freeShippingAfterAmountLimit: 1,
            invoiceGenerationPrefix: 1,
            attributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            analyticsURLs: {
                google: 1,
                facebookPixel: 1,
            },
            reviewsEnabled: 1,
            logo: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            logoId: 1,
            favicon: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            faviconId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.shop_settings.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ShopSettingsCollection),
    __metadata("design:type", typeof (_a = typeof ShopSettingsCollection !== "undefined" && ShopSettingsCollection) === "function" ? _a : Object)
], ShopSettingEditForm.prototype, "collection", void 0);
ShopSettingEditForm = __decorate([
    Service({ transient: true })
], ShopSettingEditForm);
export { ShopSettingEditForm };

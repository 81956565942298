import { useMutation } from "@apollo/client";
import { useRouter, useTranslate, useUIComponents } from "@bluelibs/x-ui";
import { use } from "@bluelibs/x-ui-react-bundle";
import { notification } from "antd";
import { useState } from "react";
import { EndUserProfileCreateForm } from "../../config/EndUserProfileCreateForm";
import { AdminEndUserProfileCreate } from "./mutations";
import { Routes } from "@bundles/UIAppBundle";
import { useAvatarUpload } from "@bundles/UIAppBundle/components/Avatar/hooks";
export const useEndUserProfileCreate = () => {
    const form = use(EndUserProfileCreateForm, { transient: true });
    const t = useTranslate();
    const UIComponents = useUIComponents();
    const [updateIsLoading, setUpdateIsLoading] = useState(false);
    const router = useRouter();
    const { uploadAvatar, AvatarUpload } = useAvatarUpload();
    const [AdminEndUserProfileCreateMutation] = useMutation(AdminEndUserProfileCreate);
    const createEndUserProfile = async (data) => {
        setUpdateIsLoading(true);
        try {
            const { userId } = await form.onCreateEndUserProfile(data, AdminEndUserProfileCreateMutation);
            await uploadAvatar(userId);
            notification.success({
                message: t("generics.success"),
                description: t("management.end_user_profiles.create_confirmation"),
            });
            router.go(Routes.END_USER_PROFILES_LIST);
        }
        finally {
            setUpdateIsLoading(false);
        }
    };
    return {
        createEndUserProfile,
        updateIsLoading,
        form,
        t,
        UIComponents,
        AvatarUpload,
    };
};

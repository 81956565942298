import { useState, useMemo } from "react";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { ProductVariantsAntTableSmart } from "./ProductVariantsTableSmart";
import { AdminProductsSync } from "./mutations";
export const useProductVariant = () => {
    const t = useTranslate();
    const [api, Provider] = newSmart(ProductVariantsAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [filters, setFilters] = useState({});
    const [syncCategories, { loading: isLoadingSync }] = useMutation(AdminProductsSync);
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const nextFilters = { ...filters, ...newFilters };
            setFilters(nextFilters);
            api.setFlexibleFilters(nextFilters);
        };
    }, [filters]);
    const onClickSync = () => {
        notification.info({
            key: "syncing_products",
            message: t("management.product_variants.list.syncing"),
            duration: 3600,
        });
        syncCategories()
            .then(() => {
            api.load();
            notification.close("syncing_products");
            notification.success({
                message: t("management.product_variants.list.sync_success"),
            });
        })
            .catch(() => {
            notification.close("syncing_products");
            notification.error({
                message: t("management.product_variants.list.sync_error"),
            });
        });
    };
    return {
        api,
        Provider,
        filtersOpened,
        isLoadingSync,
        setFiltersOpened,
        onFiltersUpdate,
        onClickSync,
    };
};

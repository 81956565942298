import { useMutation, useQuery } from "@apollo/client";
import { INTERNAL_USER_PROFILE_GET } from "@bundles/UIAppBundle/components/Settings/Notifications/queries";
import { useEffect, useState } from "react";
import { useTranslate } from "@bluelibs/x-ui";
import { UPDATE_ACTIVE_NOTIFICATIONS } from "@bundles/UIAppBundle/components/Settings/Notifications/mutations";
import { notification } from "antd";
export const useNotifications = () => {
    const { data: internalUserProfile, loading } = useQuery(INTERNAL_USER_PROFILE_GET, {
        fetchPolicy: "network-only",
    });
    const [activeNotifications, setActiveNotifications] = useState({});
    const [updateActiveNotifications] = useMutation(UPDATE_ACTIVE_NOTIFICATIONS);
    const tl = useTranslate("management.settings.edit_notifications");
    const t = useTranslate();
    useEffect(() => {
        setActiveNotifications(internalUserProfile === null || internalUserProfile === void 0 ? void 0 : internalUserProfile.AdminMe.internalUserProfile.activeNotifications);
    }, [internalUserProfile]);
    const handleChangeNotificationStatus = (notificationKey) => {
        updateActiveNotifications({
            variables: {
                input: fromNotificationTypeToNotificationKey(notificationKey),
            },
        })
            .then((response) => {
            notification.success({
                message: tl(`${notificationKey}.${!activeNotifications[notificationKey] ? "enabled" : "disabled"}`),
            });
            setActiveNotifications(response.data.AdminUpdateActiveNotifications.activeNotifications);
        })
            .catch((error) => {
            notification.error({
                message: t("generics.error"),
                description: error.message,
            });
        });
    };
    const fromNotificationTypeToNotificationKey = (notificationType) => notificationType.replace(/([a-z])|([A-Z])/g, (letter) => letter === letter.toUpperCase() ? `_${letter}` : letter.toUpperCase());
    const getNotificationsSortedByGroup = (group) => {
        const notificationGroup = {};
        for (const key in activeNotifications) {
            if (key.startsWith(group)) {
                notificationGroup[key] = activeNotifications[key];
            }
        }
        return Object.keys(notificationGroup)
            .sort()
            .reduce((accumulator, key) => {
            accumulator[key] = notificationGroup[key];
            return accumulator;
        }, {});
    };
    const getGroupedNotifications = () => {
        return {
            orderNotifications: getNotificationsSortedByGroup("order"),
            returnNotifications: getNotificationsSortedByGroup("return"),
            productNotifications: getNotificationsSortedByGroup("product"),
            invoiceNotifications: getNotificationsSortedByGroup("invoice"),
            clientNotifications: getNotificationsSortedByGroup("client"),
        };
    };
    return {
        loading,
        groupedNotifications: getGroupedNotifications(),
        handleChangeNotificationStatus,
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { TagColorsEnum } from "./types";
import styles from "./CustomTag.module.scss";
const CustomTag = ({ value, variant = "outlined", color = TagColorsEnum.GRAY, isIcon = false, onClick, ...rest }) => {
    const tagClassNames = classNames(styles.tag, styles[variant], styles[color], { [styles.link]: onClick, [styles.isIcon]: isIcon }, rest.className);
    return (_jsx("span", Object.assign({ onClick: onClick, className: tagClassNames }, { children: value }), void 0));
};
export default CustomTag;

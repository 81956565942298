import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "@bundles/UIAppBundle/components/Button";
import { LeftArrow, RightArrow } from "@bundles/UIAppBundle/icons";
import styles from "./CreateOrderLayout.module.scss";
const CreateOrderLayout = ({ title, currentStep, totalSteps, isLoading = false, onNext, onBack, onConfirm, children, }) => {
    const progress = Math.floor((currentStep / totalSteps) * 100);
    return (_jsxs("div", { children: [_jsx("div", { className: styles["progress-bar"], style: {
                    width: `${progress}%`,
                } }, void 0), _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["header"] }, { children: [_jsx("div", Object.assign({ className: styles["title"] }, { children: title }), void 0), currentStep === totalSteps - 1 && (_jsxs("div", Object.assign({ className: styles["header__buttons"] }, { children: [_jsx(Button, Object.assign({ icon: LeftArrow, width: "fit-content", iconPosition: "before", btnStyle: "outlined", onClick: onBack, type: "button" }, { children: "Back" }), void 0), _jsx(Button, Object.assign({ loading: isLoading, width: "fit-content", iconPosition: "after", onClick: onConfirm }, { children: "Confirm" }), void 0)] }), void 0))] }), void 0), _jsx("div", Object.assign({ className: styles["children"] }, { children: children }), void 0), currentStep < totalSteps - 1 && (_jsxs("div", Object.assign({ className: styles["footer"] }, { children: [currentStep > 0 && (_jsx(Button, Object.assign({ icon: LeftArrow, width: "fit-content", iconPosition: "before", btnStyle: "outlined", onClick: onBack, type: "button" }, { children: "Back" }), void 0)), currentStep < totalSteps && (_jsx(Button, Object.assign({ icon: RightArrow, width: "fit-content", iconPosition: "after", onClick: onNext, type: "button" }, { children: "Next" }), void 0))] }), void 0))] }), void 0)] }, void 0));
};
export default CreateOrderLayout;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { ControlledInput, Button } from "@bundles/UIAppBundle/components";
import { Group2996, RecoverPassword } from "@bundles/UIAppBundle/icons";
import { Routes } from "@bundles/UIAppBundle";
import styles from "./ForgotPassword.module.scss";
import { Input, PlaceHolder } from "./consts";
import { useForgotPassword } from "./hooks";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
const ForgotPassword = () => {
    const router = useRouter();
    const tl = useTranslate("authentication.forgotPassword");
    const { onSubmit, onChangeValue, fieldError } = useForgotPassword();
    return (_jsx(PublicRoute, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Group2996, { className: styles.group2996 }, void 0), _jsxs("div", Object.assign({ className: styles["form-container"] }, { children: [_jsx("div", { className: styles.flex1 }, void 0), _jsxs("form", Object.assign({ onSubmit: onSubmit }, { children: [_jsx(RecoverPassword, { className: styles["lock-image"] }, void 0), _jsx("h1", Object.assign({ className: styles.title }, { children: tl("header") }), void 0), _jsx("p", Object.assign({ className: styles.description }, { children: tl("description") }), void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.EMAIL, v.target.value), error: fieldError(Input.EMAIL), placeholder: PlaceHolder[Input.EMAIL], name: Input.EMAIL, type: "email" }, void 0), _jsx(Button, Object.assign({ type: "submit", btnStyle: "primary" }, { children: _jsx("b", { children: tl("submit") }, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["back-to-login"] }, { children: [tl("goBackTo") + " ", _jsx(Link, Object.assign({ className: styles["login"], to: router.path(Routes.LOGIN) }, { children: tl("login") }), void 0)] }), void 0)] }), void 0), _jsx("div", { className: styles.flex1 }, void 0)] }), void 0)] }), void 0) }, void 0));
};
export default ForgotPassword;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "../Layout";
import { useShopConfigForm } from "../hooks";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { InvoicingDetailsConstructor } from "./InvoicingDetailsConstructor";
import styles from "./InvoicingDetailsForm.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "../consts";
const InvoicingDetailsForm = () => {
    const t = useTranslate();
    const { form, document, loading, error, onSubmit } = useShopConfigForm(InvoicingDetailsConstructor);
    return (_jsx(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: _jsxs(ShopConfigurationLayout, { children: [loading && _jsx(Ant.Spin, { size: "large" }, void 0), !loading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !loading && !error && (_jsx(Card, Object.assign({ title: t("management.shop_settings.submenus.invoicing_details").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ labelCol: { span: 0 }, requiredMark: "optional", initialValues: document, onFinish: (document) => onSubmit({
                            ...document,
                            freeShippingAfterAmountLimit: 
                            // TODO: go back here after merging InputNumber``
                            +document.freeShippingAfterAmountLimit,
                            firstInvoiceNumber: +document.firstInvoiceNumber,
                        }) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"], icon: Checkmark }, { children: t("generics.save_changes") }), void 0)] }), void 0) }), void 0))] }, void 0) }), void 0));
};
export default InvoicingDetailsForm;

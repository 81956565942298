import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslate } from "@bluelibs/x-ui";
import { ControlledInput, Button } from "@bundles/UIAppBundle/components";
import { Group2996, RecoverPassword } from "@bundles/UIAppBundle/icons";
import styles from "./ResetPassword.module.scss";
import { Input, PlaceHolder } from "./consts";
import { useResetPassword } from "./hooks";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
const ResetPassword = ({ token = "" }) => {
    const tl = useTranslate("authentication.resetPassword");
    const { onSubmit, onChangeValue, fieldError } = useResetPassword({ token });
    return (_jsx(PublicRoute, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Group2996, { className: styles.group2996 }, void 0), _jsxs("div", Object.assign({ className: styles["form-container"] }, { children: [_jsx("div", { className: styles.flex1 }, void 0), _jsxs("form", Object.assign({ onSubmit: onSubmit }, { children: [_jsx(RecoverPassword, { className: styles["lock-image"] }, void 0), _jsx("h1", Object.assign({ className: styles.title }, { children: tl("header") }), void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.EMAIL, v.target.value), error: fieldError(Input.EMAIL), placeholder: PlaceHolder[Input.EMAIL], name: Input.EMAIL, autoComplete: "email" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.NEW_PASSWORD, v.target.value), error: fieldError(Input.NEW_PASSWORD), placeholder: PlaceHolder[Input.NEW_PASSWORD], name: Input.NEW_PASSWORD, type: "password", autoComplete: "new-password" }, void 0), _jsx(ControlledInput, { onChange: (v) => onChangeValue(Input.CONFIRM_PASSWORD, v.target.value), error: fieldError(Input.CONFIRM_PASSWORD), placeholder: PlaceHolder[Input.CONFIRM_PASSWORD], name: Input.CONFIRM_PASSWORD, type: "password", autoComplete: "new-password" }, void 0), _jsx(Button, Object.assign({ type: "submit", btnStyle: "primary" }, { children: _jsx("b", { children: tl("submit") }, void 0) }), void 0)] }), void 0), _jsx("div", { className: styles.flex1 }, void 0)] }), void 0)] }), void 0) }, void 0));
};
export default ResetPassword;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { marketingAccessRoles } from "../../../consts";
import { MarketingLayout, IntegrationList as Integrations, } from "@bundles/UIAppBundle/components";
import layoutStyles from "@bundles/UIAppBundle/pages/Marketing/config/MarketingLayout.module.scss";
import { useTranslate } from "@bluelibs/x-ui";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const IntegrationList = () => {
    const t = useTranslate();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: marketingAccessRoles }, { children: [_jsx(Seo, { data: seoData.marketing.integration.list }, void 0), _jsxs(MarketingLayout, { children: [_jsx("div", Object.assign({ className: layoutStyles.title }, { children: t("management.marketing.submenus.integrations") }), void 0), _jsx(Integrations, {}, void 0)] }, void 0)] }), void 0));
};
export default IntegrationList;

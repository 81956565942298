var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Col, Form } from "antd";
import moment from "moment";
import { ControlledInput, DatePicker, } from "@bundles/UIAppBundle/components";
import { EndUserProfileListFiltersForm as BaseEndUserProfileListFiltersForm } from "./EndUserProfileListFiltersForm.base";
let EndUserProfileListFiltersForm = class EndUserProfileListFiltersForm extends BaseEndUserProfileListFiltersForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("billingAddress");
        this.remove("shipmentAddress");
        this.remove("savedAddresses");
        this.remove("firstName");
        this.remove("middleName");
        this.remove("birthDate");
        this.remove("lastName");
        this.remove("email");
        this.remove("phoneNumber");
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("userId");
        this.remove("latestViewedProductsIds");
        this.remove("createdById");
        this.remove("updatedById");
        this.remove("adminId");
        this.remove("salesRayId");
        this.remove("language");
        this.remove("isAnonymousCheckout");
        this.remove("salesRayDefaultPricelistId");
        // this.update("salesRayDefaultPricelistId", {
        //   label: "",
        //   render: (props) => {
        //     return (
        //       <Col span={24}>
        //         <Form.Item {...props}>
        //           <ControlledInput
        //             label={t(
        //               "management.end_user_profiles.fields.salesRayDefaultPricelistId",
        //             )}
        //             placeholder="SalesRay Default Pricelist ID"
        //             type="number"
        //           />
        //         </Form.Item>
        //       </Col>
        //     );
        //   },
        // });
        this.update("companyName", {
            label: "",
            render: (props) => {
                return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { label: t("management.end_user_profiles.fields.companyName"), placeholder: "Company Name" }, void 0) }), void 0) }), void 0));
            },
        });
        this.update("vatNumber", {
            label: "",
            render: (props) => {
                return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { label: t("management.end_user_profiles.fields.vatNumber"), placeholder: "VAT Number" }, void 0) }), void 0) }), void 0));
            },
        });
        // this.update("isAnonymousCheckout", {
        //   label: "",
        //   render: (props) => {
        //     return (
        //       <Col span={24}>
        //         <Form.Item {...props} initialValue={[]}>
        //           <ControlledSelect
        //             multiple
        //             label={t(
        //               "management.end_user_profiles.fields.isAnonymousCheckout",
        //             )}
        //             placeholder="Select Status"
        //             options={[
        //               { value: true, label: "Yes", tagColor: TagColorsEnum.ORANGE },
        //               { value: false, label: "No", tagColor: TagColorsEnum.RED },
        //             ]}
        //           />
        //         </Form.Item>
        //       </Col>
        //     );
        //   },
        // });
        this.add({
            id: "orderTotal",
            name: ["orderTotal"],
            label: "",
            nest: [
                {
                    id: "min",
                    name: ["orderTotal", 0],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Starting At", label: t("management.end_user_profiles.fields.orderTotal") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "max",
                    name: ["orderTotal", 1],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Ending At", label: "\u00A0" }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        this.add({
            id: "orderDate",
            name: ["profile", "orders", "placedAt"],
            order: 4,
            label: "",
            render: (props) => (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(DatePicker, { disabledDate: (currentDate) => currentDate.isSameOrAfter(moment()), label: t("management.end_user_profiles.fields.orderDate"), range: true }, void 0) }), void 0) }), void 0)),
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
EndUserProfileListFiltersForm = __decorate([
    Service({ transient: true })
], EndUserProfileListFiltersForm);
export { EndUserProfileListFiltersForm };

import { useEffect, useState } from "react";
import { useRouter } from "@bluelibs/x-ui";
import classNames from "classnames";
import styles from "./Sidebar.module.scss";
export const useSidebar = () => {
    const router = useRouter();
    const [openKeys, setOpenKeys] = useState([]);
    const linkClasses = (url, urlHead, subMenuLink = false) => classNames(subMenuLink ? styles["submenu-link"] : styles.link, router.history.location.pathname.startsWith(url) ||
        router.history.location.pathname.startsWith(urlHead)
        ? styles.active
        : "");
    useEffect(() => {
        const { pathname } = router.history.location;
        if (pathname.startsWith("/returns") || pathname.startsWith("/orders")) {
            setOpenKeys(["orders"]);
        }
        if (pathname.startsWith("/products") ||
            pathname.startsWith("/product-categories") ||
            pathname.startsWith("/units-of-measurement") ||
            pathname.startsWith("/product-3-d-model")) {
            setOpenKeys(["products"]);
        }
    }, [router]);
    const onOpenChange = (url) => {
        router.history.push(url);
    };
    return { linkClasses, onOpenChange, openKeys };
};

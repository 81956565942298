import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Link } from "react-router-dom";
import { ControlledSelect } from "@bundles/UIAppBundle/components";
import { useData, useRouter, useTranslate } from "@bluelibs/x-ui";
import { VatRatesCollection } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import styles from "../ProductCategoriesCreate.module.scss";
import ImageUpload from "@bundles/UIAppBundle/components/ImageUpload/ImageUpload";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
export const updateFields = (formInstance, form) => {
    const t = useTranslate();
    const router = useRouter();
    const { data: vatRates } = useData(VatRatesCollection, {}, { _id: 1, name: 1 });
    const getSelectOptions = (data) => {
        const options = data === null || data === void 0 ? void 0 : data.map(({ name, _id }) => ({
            label: name,
            value: _id.toString(),
        }));
        return options;
    };
    form.update("vatRateId", {
        required: true,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledSelect, { options: getSelectOptions(vatRates) || [], label: addRequiredFieldSpecialMark(t("management.products.fields.vatRate")), placeholder: t("management.products.placeholders.vatRate"), description: _jsxs(_Fragment, { children: [t("management.products.descriptions.vatRate"), "\u00A0", _jsx(Link, Object.assign({ className: styles.link, to: router.path(Routes.VAT_RATES_LIST), target: "_blank" }, { children: t("generics.here") }), void 0), "."] }, void 0) }, void 0) }), void 0) }), void 0)),
    });
    form.add([
        {
            id: "image",
            name: ["image"],
            required: false,
            render: (props) => (_jsxs(Ant.Col, Object.assign({ span: 24 }, { children: [_jsx(Ant.Form.Item, Object.assign({}, props, { label: "", noStyle: true }), void 0), _jsx(ImageUpload, { formInstance: formInstance }, void 0)] }), void 0)),
        },
    ]);
};

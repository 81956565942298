import { gql } from "@apollo/client";
export const AddProductVariantMutation = gql `
  mutation AdminProductVariantCreate($input: AdminProductVariantCreateInput!) {
    AdminProductVariantCreate(input: $input) {
      _id
    }
  }
`;
export const UpdateProductVariantMutation = gql `
  mutation ($input: AdminProductVariantUpdateInput!) {
    AdminProductVariantUpdate(input: $input) {
      _id
    }
  }
`;

import { CustomProductCategoriesCollection, } from "@bundles/UIAppBundle/collections";
import { ProductCategoryList } from "../../config/ProductCategoryList";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
export class ProductCategoriesAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomProductCategoriesCollection;
    }
    getBody() {
        return ProductCategoryList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ProductCategoryList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {};
    }
    getSortMap() {
        return ProductCategoryList.getSortMap();
    }
    getActionItems() {
        return [];
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["name"]) {
            modifiedFiltersObj["name"] = modifiedFiltersObj["name"].source;
        }
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
                //...this.state.filters,
                //...this.alwaysOnFilters,
                isRoot: true,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

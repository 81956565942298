import "./i18n";
import { INVOICES_LIST as BASE_INVOICES_LIST, INVOICES_CREATE as BASE_INVOICES_CREATE, INVOICES_EDIT as BASE_INVOICES_EDIT, INVOICES_VIEW as BASE_INVOICES_VIEW, } from "./config/routes";
export const INVOICES_LIST = {
    ...BASE_INVOICES_LIST,
    path: "/invoices",
};
export const INVOICES_CREATE = {
    ...BASE_INVOICES_CREATE,
    path: "/invoices/create",
};
export const INVOICES_EDIT = {
    ...BASE_INVOICES_EDIT,
    path: "/invoices/:id/edit",
};
export const INVOICES_VIEW = {
    ...BASE_INVOICES_VIEW,
    path: "/invoices/:id/view",
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ControlledSelect } from "@bundles/UIAppBundle/components/ControlledSelect";
import { RichTextEditor } from "@bundles/UIAppBundle/components/RichTextEditor";
import { usePaymentAndShipping } from "./hooks";
import styles from "./PaymentAndShipping.module.scss";
const PaymentAndShipping = ({ stepForm }) => {
    const { notesHtml, setNotesHtml, shippingOptions = [], paymentOptions = [], onShippingOptionChange, onPaymentOptionChange, shippingOptionId, paymentOptionId, } = usePaymentAndShipping(stepForm);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["row"] }, { children: [_jsx(ControlledSelect, { value: shippingOptionId, options: shippingOptions, placeholder: "Select Shipping Provider", label: "Shipping Provider", onChange: (v) => {
                            onShippingOptionChange(v);
                        } }, void 0), _jsx(ControlledSelect, { value: paymentOptionId, options: paymentOptions, label: "Payment Option", placeholder: "Select Payment Option", onChange: (v) => {
                            onPaymentOptionChange(v);
                        } }, void 0)] }), void 0), _jsx(RichTextEditor, { label: "ADD NOTES", value: notesHtml, onChange: (e) => {
                    setNotesHtml(e);
                } }, void 0)] }), void 0));
};
export default PaymentAndShipping;

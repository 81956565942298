import "./i18n";
import { INTERNAL_USER_PROFILES_LIST as BASE_INTERNAL_USER_PROFILES_LIST, INTERNAL_USER_PROFILES_CREATE as BASE_INTERNAL_USER_PROFILES_CREATE, INTERNAL_USER_PROFILES_EDIT as BASE_INTERNAL_USER_PROFILES_EDIT, INTERNAL_USER_PROFILES_VIEW as BASE_INTERNAL_USER_PROFILES_VIEW, } from "./config/routes";
export const INTERNAL_USER_PROFILES_LIST = {
    ...BASE_INTERNAL_USER_PROFILES_LIST,
    path: "/team-members",
};
export const INTERNAL_USER_PROFILES_CREATE = {
    ...BASE_INTERNAL_USER_PROFILES_CREATE,
    path: "/team-members/create",
};
export const INTERNAL_USER_PROFILES_EDIT = {
    ...BASE_INTERNAL_USER_PROFILES_EDIT,
    path: "/team-members/:id/edit",
};
export const INTERNAL_USER_PROFILES_VIEW = {
    ...BASE_INTERNAL_USER_PROFILES_VIEW,
    path: "/team-members/:id/view",
};

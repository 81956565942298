import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import DOMPurify from "dompurify";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { CustomTag } from "../CustomTag";
import styles from "./Product.module.scss";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
const Product = ({ imageURL, title, tags, description, oldPrice, newPrice, }) => {
    //FIXME: Temporary color for tags
    const getTagColor = () => {
        const colors = ["red", "green", "orange", "purple", "gray"];
        const index = Math.floor(Math.random() * colors.length);
        return colors[index];
    };
    const shopSettings = useShopSettings();
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("img", { className: styles["product-image"], src: generateImageLink(imageURL), alt: "products" }, void 0), _jsxs("div", Object.assign({ className: styles["content"] }, { children: [_jsx("div", Object.assign({ className: styles["title"] }, { children: title }), void 0), _jsx("div", Object.assign({ className: "" }, { children: tags === null || tags === void 0 ? void 0 : tags.map((t, index) => (_jsx(CustomTag, { color: getTagColor(), variant: "filled", value: t }, index))) }), void 0), description && (_jsx("div", { className: styles["description"], dangerouslySetInnerHTML: {
                            __html: DOMPurify.sanitize(description),
                        } }, void 0)), _jsxs("div", Object.assign({ className: styles["price"] }, { children: [oldPrice && (_jsx("span", Object.assign({ className: styles["price__old"] }, { children: shopSettings.formatPriceWithCurrency(oldPrice) }), void 0)), _jsx("span", Object.assign({ className: styles["price__new"] }, { children: shopSettings.formatPriceWithCurrency(newPrice) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default Product;

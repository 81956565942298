var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { PaymentOptionForm } from "./PaymentOptionForm";
let PaymentOptionCreateForm = class PaymentOptionCreateForm extends PaymentOptionForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    submitForm(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.payment_options.create_confirmation"),
            });
            return this.router.go(Routes.PAYMENT_OPTIONS_LIST);
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
PaymentOptionCreateForm = __decorate([
    Service({ transient: true })
], PaymentOptionCreateForm);
export { PaymentOptionCreateForm };

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Card, ControlledSelect } from "@bundles/UIAppBundle/components";
import { useReturns } from "./hooks";
import styles from "./Returns.module.scss";
const Returns = ({ status, id }) => {
    const { returnOptions, changeReturnStatus } = useReturns(id);
    return (_jsx(Card, Object.assign({ title: "Return Status", className: styles["custom-card"] }, { children: _jsx("div", Object.assign({ className: styles["container"] }, { children: _jsx(ControlledSelect, { options: returnOptions, value: status, onChange: (v) => {
                    if (v !== status)
                        changeReturnStatus(v);
                } }, void 0) }), void 0) }), void 0));
};
export default Returns;

import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { SideBarSecondary } from "../..";
import { useSettingsLayout } from "./hooks";
const SettingsLayout = ({ children, }) => {
    const { links } = useSettingsLayout();
    const t = useTranslate("management.settings.menu");
    return (_jsx(SideBarSecondary, Object.assign({ title: t("title"), links: links }, { children: children }), void 0));
};
export default SettingsLayout;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UnitsOfMeasurementCollection, ProductVariantPriceListsCollection, } from "@bundles/UIAppBundle/collections";
let ProductVariantPriceListCreateForm = class ProductVariantPriceListCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "priceListId",
                label: t("management.product_variant_price_lists.fields.priceListId"),
                name: ["priceListId"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "priceListCode",
                label: t("management.product_variant_price_lists.fields.priceListCode"),
                name: ["priceListCode"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "sku",
                label: t("management.product_variant_price_lists.fields.sku"),
                name: ["sku"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "price",
                label: t("management.product_variant_price_lists.fields.price"),
                name: ["price"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "currency",
                label: t("management.product_variant_price_lists.fields.currency"),
                name: ["currency"],
                component: Ant.Input,
            },
            {
                id: "priceListInfo",
                label: t("management.product_variant_price_lists.fields.priceListInfo"),
                name: ["priceListInfo"],
                required: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.id"),
                        name: ["priceListInfo", "id"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "code",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.code"),
                        name: ["priceListInfo", "code"],
                        component: Ant.Input,
                    },
                    {
                        id: "status",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.status"),
                        name: ["priceListInfo", "status"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "formula",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.formula"),
                        name: ["priceListInfo", "formula"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "name",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.name"),
                        name: ["priceListInfo", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "tp",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.tp"),
                        name: ["priceListInfo", "tp"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "unitOfMeasurementId",
                label: t("management.product_variant_price_lists.fields.unitOfMeasurement"),
                name: ["unitOfMeasurementId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UnitsOfMeasurementCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_variant_price_lists.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.PRODUCT_VARIANT_PRICE_LISTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PRODUCT_VARIANT_PRICE_LISTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PRODUCT_VARIANT_PRICE_LISTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProductVariantPriceListsCollection),
    __metadata("design:type", typeof (_a = typeof ProductVariantPriceListsCollection !== "undefined" && ProductVariantPriceListsCollection) === "function" ? _a : Object)
], ProductVariantPriceListCreateForm.prototype, "collection", void 0);
ProductVariantPriceListCreateForm = __decorate([
    Service({ transient: true })
], ProductVariantPriceListCreateForm);
export { ProductVariantPriceListCreateForm };

import "./i18n";
import { POSTS_LIST as BASE_POSTS_LIST, POSTS_CREATE as BASE_POSTS_CREATE, POSTS_EDIT as BASE_POSTS_EDIT, POSTS_VIEW as BASE_POSTS_VIEW, } from "./config/routes";
export const POSTS_LIST = {
    ...BASE_POSTS_LIST,
    path: "/marketing/posts",
};
export const POSTS_CREATE = {
    ...BASE_POSTS_CREATE,
    path: "/marketing/posts/create",
};
export const POSTS_EDIT = {
    ...BASE_POSTS_EDIT,
    path: "/marketing/posts/:id/edit",
};
export const POSTS_VIEW = {
    ...BASE_POSTS_VIEW,
    path: "/marketing/posts/:id/view",
};

import { gql } from "@apollo/client";
export const InternalUserProfileAdminListGet = gql `
  query InternalUserProfileAdminListGet(
    $input: InternalUserProfileAdminListInput
  ) {
    InternalUserProfileAdminListGet(input: $input) {
      data {
        _id
        fullName
        email
        phoneNumber
        user {
          roles
          avatar {
            file {
              downloadUrl
            }
          }
          isSuspended
        }
      }
      skip
      limit
      total
    }
  }
`;

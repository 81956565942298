var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Service } from "@bluelibs/core";
import { ShippingOptionList as BaseShippingOptionList } from "./ShippingOptionList.base";
import styles from "../components/List/ShippingOptionsList.module.scss";
let ShippingOptionList = class ShippingOptionList extends BaseShippingOptionList {
    build() {
        super.build();
        const { t } = this.i18n;
        this.update("name", {
            order: 1,
            sorter: false,
            render: (value, model) => {
                return _jsx("span", Object.assign({ className: styles.name }, { children: value }), void 0);
            },
            title: (_jsx("div", Object.assign({ className: styles["table-header"] }, { children: t("management.shipping_options.fields.name") }), void 0)),
        });
        this.update("price", {
            order: 2,
            sorter: false,
            render: (value, model) => {
                return _jsx("span", Object.assign({ className: styles.price }, { children: value }), void 0);
            },
            title: (_jsx("span", Object.assign({ className: styles["table-header"] }, { children: t("management.shipping_options.fields.price") }), void 0)),
        });
        this.update("description", {
            order: 3,
            sorter: false,
            render: (value, model) => {
                return _jsx("span", Object.assign({ className: styles.description }, { children: value }), void 0);
            },
            title: (_jsx("span", Object.assign({ className: styles["table-header"] }, { children: t("management.shipping_options.fields.description") }), void 0)),
        });
        this.remove("type");
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ShippingOptionList = __decorate([
    Service({ transient: true })
], ShippingOptionList);
export { ShippingOptionList };

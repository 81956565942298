var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Col, Form } from "antd";
import { Service } from "@bluelibs/core";
import moment from "moment";
import { ControlledInput, ControlledSelect, DatePicker, } from "@bundles/UIAppBundle/components";
import { returnsTagColors } from "@bundles/UIAppBundle/consts/colors";
import { returnStatusLabel } from "@bundles/UIAppBundle/consts/labels";
import { ReturnStatus } from "@root/api.types";
import { ReturnListFiltersForm as BaseReturnListFiltersForm } from "./ReturnListFiltersForm.base";
import { GET_PAYMENT_OPTOINS } from "../components/List/queries";
let ReturnListFiltersForm = class ReturnListFiltersForm extends BaseReturnListFiltersForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("number");
        this.remove("shippingAddress");
        this.remove("billingAddress");
        this.remove("endUserId");
        this.remove("adminId");
        this.remove("orderId");
        this.remove("updatedAt");
        this.remove("invoiceId");
        this.remove("items");
        this.remove("notes");
        this.remove("shippingOptionId");
        // Perform additional modifications such as updating rendering functions, labels, description
        this.update("status", {
            order: 1,
            label: "",
            tooltip: null,
            render: (props) => (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { initialValue: [] }, { children: _jsx(ControlledSelect, { label: t("management.returns.filters.status"), placeholder: "Select Status", multiple: true, options: Object.keys(ReturnStatus).map((status) => ({
                            value: status,
                            label: returnStatusLabel[status],
                            tagColor: returnsTagColors[status],
                        })) }, void 0) }), void 0) }), void 0)),
        });
        this.update("paymentOptionId", {
            order: 2,
            label: "",
            tooltip: null,
            render: (props) => {
                const { data, loading, error } = useQuery(GET_PAYMENT_OPTOINS);
                const paymentOptions = (data === null || data === void 0 ? void 0 : data.AdminGetPaymentOptions.map(({ _id, type, name }) => ({
                    label: `${type} (${name})`,
                    value: _id,
                }))) || [];
                if (loading && !data) {
                    return _jsx(_Fragment, { children: "Loading..." }, void 0);
                }
                if (error && !data) {
                    return _jsx(_Fragment, { children: "Error occurred fetching the payment options" }, void 0);
                }
                return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { initialValue: [] }, { children: _jsx(ControlledSelect, { placeholder: "Select Payment Method", label: t("management.returns.filters.payment"), options: paymentOptions, multiple: true }, void 0) }), void 0) }), void 0));
            },
        });
        this.add({
            id: "price",
            name: ["price"],
            order: 3,
            nest: [
                {
                    id: "min",
                    name: ["price", 0],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Starting At", label: t("management.returns.filters.price") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "max",
                    name: ["price", 1],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Ending At", label: "\u00A0" }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        this.update("createdAt", {
            order: 4,
            label: "",
            render: (props) => (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { children: _jsx(DatePicker, { disabledDate: (currentDate) => currentDate.isSameOrAfter(moment()), label: t("management.returns.filters.createdAt"), range: true }, void 0) }), void 0) }), void 0)),
        });
    }
};
ReturnListFiltersForm = __decorate([
    Service({ transient: true })
], ReturnListFiltersForm);
export { ReturnListFiltersForm };

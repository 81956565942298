import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Modal } from "../Modal";
import { CreateOrderLayout } from "./components/CreateOrderLayout";
import { useCreateOrder } from "./hooks";
import styles from "./CreateOrder.module.scss";
const CreateOrder = ({ modalOpen, setModalOpen, onSuccess, }) => {
    const { isLoading, currentStep, error, steps, next, prev, onConfirm } = useCreateOrder();
    return (_jsx("div", { children: _jsx(Modal, Object.assign({ isOpen: modalOpen, title: "Create Order", className: styles["modal"], setIsOpen: setModalOpen, handleClose: () => setModalOpen(false) }, { children: _jsxs(CreateOrderLayout, Object.assign({ isLoading: isLoading, title: steps[currentStep].title, currentStep: currentStep, totalSteps: steps.length, onConfirm: async () => {
                    await onConfirm();
                    setModalOpen(false);
                    onSuccess();
                }, onNext: next, onBack: prev }, { children: [_jsx("div", Object.assign({ className: "steps-content" }, { children: steps[currentStep].content }), void 0), _jsx("div", Object.assign({ className: styles["error"] }, { children: error }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default CreateOrder;

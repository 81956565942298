export var DecalType;
(function (DecalType) {
    DecalType[DecalType["TEXT"] = 0] = "TEXT";
    DecalType[DecalType["IMAGE"] = 1] = "IMAGE";
})(DecalType || (DecalType = {}));
export const sliderValues = [
    {
        key: "x",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: -5,
        max: 5,
    },
    {
        key: "y",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: -5,
        max: 5,
    },
    {
        key: "z",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: -5,
        max: 5,
    },
    {
        key: "rotationX",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: 0,
        max: 360,
    },
    {
        key: "rotationY",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: 0,
        max: 360,
    },
    {
        key: "rotationZ",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: 0,
        max: 360,
    },
    {
        key: "scale",
        renderOn: [DecalType.IMAGE, DecalType.TEXT],
        min: -10,
        max: 10,
    },
];
export const defaultValues = {
    [DecalType.IMAGE]: {
        href: "/public/no-image.png",
        file: undefined,
        fileName: "",
        scale: 1,
        x: 0,
        y: 0,
        z: -0.3,
        rotationX: 0,
        rotationY: 0,
        rotationZ: 0,
        type: DecalType.IMAGE,
        debug: true,
    },
    [DecalType.TEXT]: {
        text: "Type here",
        scale: 1,
        x: 0,
        y: 0,
        z: -0.3,
        rotationX: 0,
        rotationY: 0,
        rotationZ: 0,
        type: DecalType.TEXT,
        fontColor: "#444423",
        fontSize: 1.2,
        backgroundColor: "#BB4423",
        debug: false,
        fileName: "",
    },
};
export const defaultModel = {
    file: "",
    decals: [],
};

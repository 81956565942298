import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { BackCircle, Products, ProductVariant, } from "@bundles/UIAppBundle/icons";
import { useUIComponents } from "@bluelibs/x-ui";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { useProductsManagementLayout } from "./hooks";
import { MainButtonsEnum } from "./types";
import VariantsDropdown from "./VariantsDropdown";
import styles from "./ProductsManagementLayout.module.scss";
const ProductsManagementLayout = ({ productId, children }) => {
    const UIComponents = useUIComponents();
    const { onOpenChange, openKeys, loading, data, error, navigateTo, mainBtnClasses, variantBtnClasses, } = useProductsManagementLayout(productId);
    return (_jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["back-and-submit"] }, { children: [_jsx(Button, { width: "fit-content", onClick: () => navigateTo(Routes.PRODUCTS_LIST), className: styles["back-btn"], btnStyle: "white", icon: BackCircle }, void 0), _jsxs("h2", Object.assign({ className: styles.title }, { children: [productId ? "Edit" : "Add", " Product"] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["cards-wrapper"] }, { children: [_jsxs(Card, Object.assign({ className: styles["control-panel"] }, { children: [_jsx(Button, Object.assign({ onClick: productId
                                        ? () => navigateTo(Routes.PRODUCTS_EDIT, { id: productId })
                                        : null, className: mainBtnClasses(MainButtonsEnum.MAIN_PRODUCT), btnStyle: "white", icon: Products }, { children: "Main Product" }), void 0), (!productId || (productId && error)) && (_jsx(Button, Object.assign({ className: mainBtnClasses(MainButtonsEnum.PRODUCT_VARIANTS), btnStyle: "white", icon: ProductVariant, disabled: true }, { children: "Product Variants" }), void 0)), productId && loading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), productId && data && (_jsx(VariantsDropdown, Object.assign({}, {
                                    openKeys,
                                    onOpenChange,
                                    mainBtnClasses,
                                    variantBtnClasses,
                                    navigateTo,
                                    data,
                                    productId,
                                }), void 0))] }), void 0), _jsx("div", Object.assign({ className: styles["form-wrapper"] }, { children: children }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default ProductsManagementLayout;

import "./i18n";
import { RETURNS_LIST as BASE_RETURNS_LIST, RETURNS_CREATE as BASE_RETURNS_CREATE, RETURNS_EDIT as BASE_RETURNS_EDIT, RETURNS_VIEW as BASE_RETURNS_VIEW, } from "./config/routes";
export const RETURNS_LIST = {
    ...BASE_RETURNS_LIST,
    path: "/returns",
};
export const RETURNS_CREATE = {
    ...BASE_RETURNS_CREATE,
    path: "/returns/create",
};
export const RETURNS_EDIT = {
    ...BASE_RETURNS_EDIT,
    // path: "/returns/:id/edit",
};
export const RETURNS_VIEW = {
    ...BASE_RETURNS_VIEW,
    path: "/returns/:id/view",
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Card } from "@bundles/UIAppBundle/components";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import styles from "./AddProductsCard.module.scss";
export function AddProductsCard({ items, subTotal, shippingCharge, vatCharge, total, }) {
    const shopSettings = useShopSettings();
    return (_jsxs(Card, Object.assign({ className: styles["card"] }, { children: [_jsx("div", Object.assign({ className: styles.indexrow }, { children: _jsx("p", { children: "Product Order: " }, void 0) }), void 0), _jsx("div", { children: items.map((item, index) => {
                    let attributeString = "";
                    item.attributes.forEach((attribute, index) => {
                        if (attribute.value) {
                            attributeString +=
                                attribute.key +
                                    " " +
                                    attribute.value +
                                    (index < item.attributes.length - 1 ? ", " : " ");
                        }
                    });
                    return (_jsxs("div", Object.assign({ className: styles.productCol }, { children: [_jsx("img", { src: generateImageLink(item.picture), alt: item.name }, void 0), _jsxs("div", Object.assign({ className: styles.nameatr }, { children: [_jsx("p", Object.assign({ className: attributeString !== "" ? styles.name : styles.namenoatr }, { children: item.name }), void 0), " ", _jsx("br", {}, void 0), _jsx("p", Object.assign({ className: styles.attr }, { children: attributeString }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["price-row-bold"] }, { children: [item.quantity, " X", " ", shopSettings.formatPriceWithCurrency(item.piecePrice)] }), void 0)] }), index));
                }) }, void 0), _jsxs("div", Object.assign({ className: styles.bottomCol }, { children: [_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.bottomRow }, { children: [_jsx("p", { children: "Subtotal" }, void 0), _jsx("p", { children: shopSettings.formatPriceWithCurrency(subTotal) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.bottomRow }, { children: [_jsx("p", { children: "Shipping Charge:" }, void 0), _jsx("p", { children: shopSettings.formatPriceWithCurrency(shippingCharge) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.bottomRow }, { children: [_jsx("p", { children: "VAT Tax:" }, void 0), _jsx("p", { children: shopSettings.formatPriceWithCurrency(vatCharge) }, void 0)] }), void 0)] }, void 0), _jsxs("div", Object.assign({ className: styles.total }, { children: [_jsx("p", { children: "Total:" }, void 0), _jsx("p", { children: shopSettings.formatPriceWithCurrency(total) }, void 0)] }), void 0)] }), void 0)] }), void 0));
}

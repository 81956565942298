import { useState } from "react";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ValidatorService } from "@bluelibs/validator-bundle";
import { notification } from "antd";
import { useRouter } from "@bluelibs/x-ui";
import { LoginFormSchema, DefaultValues } from "./consts";
import { Routes } from "@bundles/UIAppBundle";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { dashboardAccessRoles } from "../../Dashboard";
import { checkAdminRights } from "@bundles/UIAppBundle/components/Layout/utils/CheckAdminRights";
import { productsAccessRoles } from "../../ProductsManagement/consts";
import { clientsAccessRoles } from "../../EndUserProfilesManagement/consts";
import { ordersAccessRoles } from "../../OrdersManagement/consts";
import { invoiceAccessRoles } from "../../InvoicesManagement/consts";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
export const findDefaultPage = async (adminRoles) => {
    let route;
    const setRoute = (accessRoles, accessRoute) => {
        const hasAccess = checkAdminRights(adminRoles, accessRoles);
        if (hasAccess) {
            if (!route)
                route = accessRoute;
        }
    };
    setRoute(dashboardAccessRoles, Routes.DASHBOARD);
    setRoute(shopConfigurationAccessRoles, Routes.SHOP_SETTINGS_GENERAL_DETAILS_VIEW);
    setRoute(productsAccessRoles, Routes.PRODUCTS_LIST);
    setRoute(ordersAccessRoles, Routes.ORDERS_LIST);
    setRoute(clientsAccessRoles, Routes.END_USER_PROFILES_LIST);
    setRoute(invoiceAccessRoles, Routes.INVOICES_LIST);
    // go to profile if no route available
    return route || Routes.PROFILE;
};
export const useLogin = () => {
    const [input, setInput] = useState(DefaultValues);
    const [error, setError] = useState();
    const [shouldShowPassword, setShouldShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const validatorService = use(ValidatorService);
    const guardian = useAppGuardian();
    const router = useRouter();
    const onSubmit = async (event) => {
        var _a, _b;
        event.preventDefault();
        const { email, password } = input;
        setIsLoading(true);
        try {
            await validatorService.validate(input, {
                model: LoginFormSchema,
            });
            try {
                await guardian.login(email, password);
                setError(null);
                notification.success({
                    message: "Welcome!",
                });
                const adminRoles = ((_b = (_a = guardian.state) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.roles) || [];
                const route = await findDefaultPage(adminRoles);
                router.go(route);
            }
            catch (err) {
                notification.error({
                    message: err.message || err.toString(),
                });
                setError(err.message || err.toString());
            }
        }
        catch (err) {
            setError(err);
            notification.error({
                message: err.message || err.toString(),
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const fieldError = (inputKey) => {
        if (inputKey === (error === null || error === void 0 ? void 0 : error.path))
            return error === null || error === void 0 ? void 0 : error.errors[0];
        return null;
    };
    const onChangeValue = (key, value) => {
        setInput({
            ...input,
            [key]: value,
        });
    };
    const toggleShouldShowPassword = () => {
        setShouldShowPassword((_shouldShowPassword) => !_shouldShowPassword);
    };
    return {
        fieldError,
        shouldShowPassword,
        toggleShouldShowPassword,
        onChangeValue,
        onSubmit,
        isLoading,
    };
};

import { gql } from "@apollo/client";
export const AdminEndUserProfileEdit = gql `
  mutation AdminEndUserProfileEdit($document: AdminEndUserProfileEditInput!) {
    AdminEndUserProfileEdit(document: $document) {
      _id
      firstName
      middleName
      lastName
      isAnonymousCheckout
      phoneNumber
      companyName
      vatNumber
      savedAddresses {
        name
        firstName
        lastName
        phoneNumber
        streetAddressLine1
        streetAddressLine2
        email
        city
        town
        country
        zipcode
      }
      billingAddress {
        firstName
        lastName
        phoneNumber
        streetAddressLine1
        streetAddressLine2
        email
        city
        town
        country
        zipcode
      }
      email
    }
  }
`;

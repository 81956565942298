import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslate } from "@bluelibs/x-ui";
import { GetInvoicingDetails } from "../queries";
export const useInvoicingDetailsView = () => {
    const t = useTranslate();
    const [sortedData, setSortedData] = useState([]);
    const { data, loading, error } = useQuery(GetInvoicingDetails, {
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (!data)
            return;
        const sortedKeys = [
            "defaultCurrency",
            "invoiceGenerationPrefix",
            "firstInvoiceNumber",
            "freeShippingAfterAmountLimit",
        ];
        setSortedData(sortedKeys.map((key) => ({
            label: t(`management.shop_settings.fields.${key}`),
            value: data.AdminGetShopSettings[key],
        })));
    }, [data]);
    return {
        data: sortedData,
        loading,
        error,
        t,
    };
};

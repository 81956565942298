var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let InvoiceList = class InvoiceList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "invoiceId",
                title: t("management.invoices.fields.invoiceId"),
                key: "management.invoices.fields.invoiceId",
                dataIndex: ["invoiceId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.nbh",
                title: t("management.invoices.fields.billingAddress.nbh"),
                key: "management.invoices.fields.billingAddress.nbh",
                dataIndex: ["billingAddress", "nbh"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_floor",
                title: t("management.invoices.fields.billingAddress.building_floor"),
                key: "management.invoices.fields.billingAddress.building_floor",
                dataIndex: ["billingAddress", "building_floor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip",
                title: t("management.invoices.fields.billingAddress.zip"),
                key: "management.invoices.fields.billingAddress.zip",
                dataIndex: ["billingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.address",
                title: t("management.invoices.fields.billingAddress.address"),
                key: "management.invoices.fields.billingAddress.address",
                dataIndex: ["billingAddress", "address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_entrance",
                title: t("management.invoices.fields.billingAddress.building_entrance"),
                key: "management.invoices.fields.billingAddress.building_entrance",
                dataIndex: ["billingAddress", "building_entrance"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.street",
                title: t("management.invoices.fields.billingAddress.street"),
                key: "management.invoices.fields.billingAddress.street",
                dataIndex: ["billingAddress", "street"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.district",
                title: t("management.invoices.fields.billingAddress.district"),
                key: "management.invoices.fields.billingAddress.district",
                dataIndex: ["billingAddress", "district"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.tax",
                title: t("management.invoices.fields.billingAddress.tax"),
                key: "management.invoices.fields.billingAddress.tax",
                dataIndex: ["billingAddress", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.invoices.fields.billingAddress.country"),
                key: "management.invoices.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.note",
                title: t("management.invoices.fields.billingAddress.note"),
                key: "management.invoices.fields.billingAddress.note",
                dataIndex: ["billingAddress", "note"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                title: t("management.invoices.fields.billingAddress.email"),
                key: "management.invoices.fields.billingAddress.email",
                dataIndex: ["billingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.num",
                title: t("management.invoices.fields.billingAddress.num"),
                key: "management.invoices.fields.billingAddress.num",
                dataIndex: ["billingAddress", "num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.salesRayId",
                title: t("management.invoices.fields.billingAddress.salesRayId"),
                key: "management.invoices.fields.billingAddress.salesRayId",
                dataIndex: ["billingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip_num",
                title: t("management.invoices.fields.billingAddress.zip_num"),
                key: "management.invoices.fields.billingAddress.zip_num",
                dataIndex: ["billingAddress", "zip_num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.fax",
                title: t("management.invoices.fields.billingAddress.fax"),
                key: "management.invoices.fields.billingAddress.fax",
                dataIndex: ["billingAddress", "fax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building",
                title: t("management.invoices.fields.billingAddress.building"),
                key: "management.invoices.fields.billingAddress.building",
                dataIndex: ["billingAddress", "building"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.settlement",
                title: t("management.invoices.fields.billingAddress.settlement"),
                key: "management.invoices.fields.billingAddress.settlement",
                dataIndex: ["billingAddress", "settlement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phone",
                title: t("management.invoices.fields.billingAddress.phone"),
                key: "management.invoices.fields.billingAddress.phone",
                dataIndex: ["billingAddress", "phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_apartment",
                title: t("management.invoices.fields.billingAddress.building_apartment"),
                key: "management.invoices.fields.billingAddress.building_apartment",
                dataIndex: ["billingAddress", "building_apartment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.currency",
                title: t("management.invoices.fields.price.currency"),
                key: "management.invoices.fields.price.currency",
                dataIndex: ["price", "currency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.gross",
                title: t("management.invoices.fields.price.gross"),
                key: "management.invoices.fields.price.gross",
                dataIndex: ["price", "gross"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.net",
                title: t("management.invoices.fields.price.net"),
                key: "management.invoices.fields.price.net",
                dataIndex: ["price", "net"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price.tax",
                title: t("management.invoices.fields.price.tax"),
                key: "management.invoices.fields.price.tax",
                dataIndex: ["price", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                title: t("management.invoices.fields.status"),
                key: "management.invoices.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "sendAt",
                title: t("management.invoices.fields.sendAt"),
                key: "management.invoices.fields.sendAt",
                dataIndex: ["sendAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paidAt",
                title: t("management.invoices.fields.paidAt"),
                key: "management.invoices.fields.paidAt",
                dataIndex: ["paidAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "items",
                title: t("management.invoices.fields.items"),
                key: "management.invoices.fields.items",
                dataIndex: ["items"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "shippingAddress.nbh",
                title: t("management.invoices.fields.shippingAddress.nbh"),
                key: "management.invoices.fields.shippingAddress.nbh",
                dataIndex: ["shippingAddress", "nbh"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_floor",
                title: t("management.invoices.fields.shippingAddress.building_floor"),
                key: "management.invoices.fields.shippingAddress.building_floor",
                dataIndex: ["shippingAddress", "building_floor"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip",
                title: t("management.invoices.fields.shippingAddress.zip"),
                key: "management.invoices.fields.shippingAddress.zip",
                dataIndex: ["shippingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.address",
                title: t("management.invoices.fields.shippingAddress.address"),
                key: "management.invoices.fields.shippingAddress.address",
                dataIndex: ["shippingAddress", "address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_entrance",
                title: t("management.invoices.fields.shippingAddress.building_entrance"),
                key: "management.invoices.fields.shippingAddress.building_entrance",
                dataIndex: ["shippingAddress", "building_entrance"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.street",
                title: t("management.invoices.fields.shippingAddress.street"),
                key: "management.invoices.fields.shippingAddress.street",
                dataIndex: ["shippingAddress", "street"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.district",
                title: t("management.invoices.fields.shippingAddress.district"),
                key: "management.invoices.fields.shippingAddress.district",
                dataIndex: ["shippingAddress", "district"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.tax",
                title: t("management.invoices.fields.shippingAddress.tax"),
                key: "management.invoices.fields.shippingAddress.tax",
                dataIndex: ["shippingAddress", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.country",
                title: t("management.invoices.fields.shippingAddress.country"),
                key: "management.invoices.fields.shippingAddress.country",
                dataIndex: ["shippingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.note",
                title: t("management.invoices.fields.shippingAddress.note"),
                key: "management.invoices.fields.shippingAddress.note",
                dataIndex: ["shippingAddress", "note"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.email",
                title: t("management.invoices.fields.shippingAddress.email"),
                key: "management.invoices.fields.shippingAddress.email",
                dataIndex: ["shippingAddress", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.num",
                title: t("management.invoices.fields.shippingAddress.num"),
                key: "management.invoices.fields.shippingAddress.num",
                dataIndex: ["shippingAddress", "num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.salesRayId",
                title: t("management.invoices.fields.shippingAddress.salesRayId"),
                key: "management.invoices.fields.shippingAddress.salesRayId",
                dataIndex: ["shippingAddress", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip_num",
                title: t("management.invoices.fields.shippingAddress.zip_num"),
                key: "management.invoices.fields.shippingAddress.zip_num",
                dataIndex: ["shippingAddress", "zip_num"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.fax",
                title: t("management.invoices.fields.shippingAddress.fax"),
                key: "management.invoices.fields.shippingAddress.fax",
                dataIndex: ["shippingAddress", "fax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building",
                title: t("management.invoices.fields.shippingAddress.building"),
                key: "management.invoices.fields.shippingAddress.building",
                dataIndex: ["shippingAddress", "building"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.settlement",
                title: t("management.invoices.fields.shippingAddress.settlement"),
                key: "management.invoices.fields.shippingAddress.settlement",
                dataIndex: ["shippingAddress", "settlement"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.phone",
                title: t("management.invoices.fields.shippingAddress.phone"),
                key: "management.invoices.fields.shippingAddress.phone",
                dataIndex: ["shippingAddress", "phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_apartment",
                title: t("management.invoices.fields.shippingAddress.building_apartment"),
                key: "management.invoices.fields.shippingAddress.building_apartment",
                dataIndex: ["shippingAddress", "building_apartment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingPrice.currency",
                title: t("management.invoices.fields.shippingPrice.currency"),
                key: "management.invoices.fields.shippingPrice.currency",
                dataIndex: ["shippingPrice", "currency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingPrice.gross",
                title: t("management.invoices.fields.shippingPrice.gross"),
                key: "management.invoices.fields.shippingPrice.gross",
                dataIndex: ["shippingPrice", "gross"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingPrice.net",
                title: t("management.invoices.fields.shippingPrice.net"),
                key: "management.invoices.fields.shippingPrice.net",
                dataIndex: ["shippingPrice", "net"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingPrice.tax",
                title: t("management.invoices.fields.shippingPrice.tax"),
                key: "management.invoices.fields.shippingPrice.tax",
                dataIndex: ["shippingPrice", "tax"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "downloadUrl",
                title: t("management.invoices.fields.downloadUrl"),
                key: "management.invoices.fields.downloadUrl",
                dataIndex: ["downloadUrl"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.invoices.fields.createdAt"),
                key: "management.invoices.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.invoices.fields.updatedAt"),
                key: "management.invoices.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyer",
                title: t("management.invoices.fields.buyer"),
                key: "management.invoices.fields.buyer",
                dataIndex: ["buyer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "order",
                title: t("management.invoices.fields.order"),
                key: "management.invoices.fields.order",
                dataIndex: ["order"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "orderNumber",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "payments",
                title: t("management.invoices.fields.payments"),
                key: "management.invoices.fields.payments",
                dataIndex: ["payments"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "paymentProcessor",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paymentType",
                title: t("management.invoices.fields.paymentType"),
                key: "management.invoices.fields.paymentType",
                dataIndex: ["paymentType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENT_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            buyer: "buyer.fullName",
            order: "order.orderNumber",
            payments: "payments.paymentProcessor",
            paymentType: "paymentType.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            invoiceId: 1,
            billingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            price: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            status: 1,
            sendAt: 1,
            paidAt: 1,
            items: {
                name: 1,
                price: {
                    currency: 1,
                    gross: 1,
                    net: 1,
                    tax: 1,
                },
                count: 1,
                imageUrl: 1,
            },
            shippingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            shippingPrice: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            downloadUrl: 1,
            createdAt: 1,
            updatedAt: 1,
            buyer: {
                _id: 1,
                fullName: 1,
            },
            buyerId: 1,
            order: {
                _id: 1,
                orderNumber: 1,
            },
            orderId: 1,
            payments: {
                _id: 1,
                paymentProcessor: 1,
            },
            paymentType: {
                _id: 1,
                name: 1,
            },
            paymentTypeId: 1,
        };
    }
};
InvoiceList = __decorate([
    Service({ transient: true })
], InvoiceList);
export { InvoiceList };

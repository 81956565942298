import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { ControlledSelect } from "@bundles/UIAppBundle/components/ControlledSelect";
import { useOrderAddressDetails } from "./hooks";
import styles from "./OrderAddressDetails.module.scss";
const OrderAddressDetails = ({ editable = true, stepForm, }) => {
    const { avatar, billingAddress, shippingAddress, shippingAddressOption, onDropdownChange, } = useOrderAddressDetails(stepForm, editable);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Card, Object.assign({ className: styles["card"] }, { children: _jsxs("div", Object.assign({ className: styles["address-container"] }, { children: [_jsx("div", Object.assign({ className: styles["header"] }, { children: _jsx("div", Object.assign({ className: styles["title"] }, { children: "Billing Information" }), void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["name"] }, { children: [_jsx("img", { src: generateProfileImageLink(billingAddress.firstName + " " + billingAddress.lastName, avatar) }, void 0), billingAddress.firstName, " ", billingAddress.lastName] }), void 0), _jsxs("div", Object.assign({ className: styles["address"] }, { children: [_jsx("div", { children: billingAddress.streetAddressLine1 }, void 0), _jsx("div", { children: billingAddress.streetAddressLine2 }, void 0), _jsxs("div", { children: [billingAddress.city, " ", billingAddress.zipcode] }, void 0), _jsx("div", { children: billingAddress.country }, void 0), billingAddress.phoneNumber ? (_jsxs("div", Object.assign({ className: styles["phone"] }, { children: [_jsx("span", { children: "Phone" }, void 0), _jsxs("span", { children: [":", billingAddress.phoneNumber] }, void 0)] }), void 0)) : null] }), void 0)] }), void 0) }), void 0), _jsx(Card, Object.assign({ className: styles["card"] }, { children: _jsxs("div", Object.assign({ className: styles["address-container"] }, { children: [_jsx("div", Object.assign({ className: styles["header"] }, { children: _jsx("div", Object.assign({ className: styles["title"] }, { children: "Shipping Information" }), void 0) }), void 0), editable && (_jsx(ControlledSelect, { value: shippingAddress._id, options: shippingAddressOption, placeholder: "Select shipping address", onChange: (v) => {
                                onDropdownChange(v);
                            } }, void 0)), _jsxs("div", Object.assign({ className: styles["name"] }, { children: [shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.email, " ", shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.phone] }), void 0), _jsxs("div", Object.assign({ className: styles["address"] }, { children: [_jsx("div", { children: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.address }, void 0), _jsx("div", { children: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.district }, void 0), _jsxs("div", { children: [shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.zip, " ", shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.zip_num] }, void 0), _jsx("div", { children: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.country }, void 0), (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.fax) && (_jsxs("div", Object.assign({ className: styles["phone"] }, { children: [_jsx("span", { children: "Phone" }, void 0), _jsxs("span", { children: [":", shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.fax] }, void 0)] }), void 0))] }), void 0)] }), void 0) }), void 0)] }), void 0));
};
export default OrderAddressDetails;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Service } from "@bluelibs/core";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { CustomTag } from "@bundles/UIAppBundle/components/CustomTag";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { ProductVariantList as BaseProductVariantList } from "./ProductVariantList.base";
import styles from "../components/List/ProductVariantsList.module.scss";
// eslint-disable-next-line react/prop-types
const Price = ({ value }) => {
    const shopSettings = useShopSettings();
    return _jsx(_Fragment, { children: shopSettings.formatPriceWithCurrency(value) }, void 0);
};
let ProductVariantList = class ProductVariantList extends BaseProductVariantList {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.remove("description");
        this.remove("availability");
        this.remove("publishedAt");
        this.remove("discountPercentage");
        this.remove("discountActiveUntil");
        this.remove("discountAmount");
        this.remove("tags");
        this.remove("attributesValues");
        this.remove("attributeOptions");
        this.remove("daysBeforeAllowingReview");
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("images");
        this.remove("publishedBy");
        this.remove("reviews");
        this.remove("orderItems");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("reviews");
        this.remove("reviews");
        this.remove("name");
        this.remove("totalDiscountPercentage");
        this.remove("brand");
        this.remove("type");
        this.remove("weight");
        this.remove("length");
        this.remove("width");
        this.remove("height");
        this.remove("shippingRate.currency");
        this.remove("shippingRate.gross");
        this.remove("shippingRate.net");
        this.remove("shippingRate.tax");
        this.remove("country");
        this.remove("taxAmount");
        this.remove("vatRate");
        this.remove("product");
        this.remove("rating");
        this.remove("seoFields.pageTitle");
        this.remove("seoFields.metaDescription");
        this.remove("seoFields.keywords");
        this.remove("seoFields.seoTitle");
        this.remove("seoFields.seoDescription");
        this.remove("seoFields.slug");
        this.remove("isPublished");
        this.remove("models");
        this.remove("model3D");
        this.remove("salesRayImageUrl");
        this.remove("length_");
        this.remove("netWeight");
        this.remove("grossWeight");
        this.remove("defaultImage");
        this.remove("netWeight");
        this.remove("grossWeight");
        this.remove("coefficient");
        this.remove("isPriceCustomized");
        this.remove("params");
        this.add({
            id: "productVariant",
            order: 1,
            title: t("management.product_variants.table.productVariant"),
            dataIndex: ["name"],
            sorter: true,
            render: (value, model) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: styles["name-column"] }, { children: [_jsx("img", { className: styles.image, src: generateImageLink((model === null || model === void 0 ? void 0 : model.salesRayImageUrl) || ((_a = model === null || model === void 0 ? void 0 : model.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl)) }, void 0), _jsx("div", Object.assign({ className: styles["name-and-desc"] }, { children: _jsx("h6", Object.assign({ className: styles["name"] }, { children: value }), void 0) }), void 0)] }), void 0));
            },
        });
        this.update("category", {
            render: (value) => {
                return (_jsx(CustomTag, { value: value.name, color: TagColorsEnum.GREEN, variant: "filled" }, void 0));
            },
        });
        this.update("salesRayId", {
            sorter: false,
        });
        this.update("category", {
            sorter: false,
        });
        this.update("netPrice", {
            order: 5,
            title: t("management.product_variants.table.netPrice"),
            sorter: true,
            dataIndex: ["netPrice"],
            render: (value, model) => (_jsx("b", { children: _jsx(Price, { value: value }, void 0) }, void 0)),
        });
        this.update("sku", {
            title: t("management.product_variants.table.sku"),
            sorter: true,
            order: 6,
            dataIndex: ["sku"],
            render: (value, model) => _jsx("b", { children: value }, void 0),
        });
        this.update("unitOfMeasurement", {
            render: (value, model) => {
                return _jsx("b", { children: value === null || value === void 0 ? void 0 : value.name }, void 0);
            },
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            "product.name": "productName",
            unitOfMeasurement: "unitOfMeasurement",
        };
    }
};
ProductVariantList = __decorate([
    Service({ transient: true })
], ProductVariantList);
export { ProductVariantList };

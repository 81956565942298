import { use, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation, useQuery } from "@apollo/client";
import * as Ant from "antd";
import { UpdateProductVariantMutation } from "../mutations";
import { ProductVariantEditForm } from "../../config/ProductVariantEditForm";
import { updateFields } from "./extraFieldsUpdates";
import { getVariant } from "../queries";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { useEffect } from "react";
import moment from "moment";
import { removeWhiteSpacesAndSplitWordsByComma, transformArrayToStringSeperatedByComma, } from "@bundles/UIAppBundle/utils/helpers";
import { Routes } from "@bundles/UIAppBundle";
export const mapAttributesWithVaules = (attributeOptions, attributeValues) => {
    return attributeOptions === null || attributeOptions === void 0 ? void 0 : attributeOptions.map((attributeOption) => {
        var _a;
        const attribute = {
            ...attributeOption,
            value: (_a = attributeValues === null || attributeValues === void 0 ? void 0 : attributeValues.find((attributeValue) => attributeValue.key === attributeOption.name)) === null || _a === void 0 ? void 0 : _a.value,
        };
        const parseToNumber = attributeOption.type === "number" &&
            !(attribute.isEnum && attribute.enumValues) &&
            attribute.value;
        if (parseToNumber)
            attribute.value = parseFloat(attribute.value);
        return attribute;
    });
};
export const useProductVariantEdit = (id) => {
    const t = useTranslate();
    const form = use(ProductVariantEditForm, { transient: true });
    const shopSettings = useShopSettings();
    const [formInstance] = Ant.Form.useForm();
    const router = useRouter();
    const [updateProductVariant, { loading: mutationLoading }] = useMutation(UpdateProductVariantMutation);
    form.build();
    const { data: document, loading: queryLoading, error: queryError, } = useQuery(getVariant, {
        fetchPolicy: "network-only",
        variables: { input: { productVariantId: id } },
    });
    const productVariant = document === null || document === void 0 ? void 0 : document.AdminProductVariantGetOne;
    useEffect(() => {
        var _a;
        if (!document)
            return;
        formInstance.setFieldValue("shopAttributes", mapAttributesWithVaules((_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.attributeOptions, productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributesValues));
    }, [shopSettings.state, document]);
    useEffect(() => {
        if (!document)
            return;
        formInstance.setFieldValue("categoryAttributes", mapAttributesWithVaules(productVariant.product.category.fullAttributeOptions, productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributesValues));
        formInstance.setFieldValue("categoryUrl", router.path(Routes.PRODUCT_CATEGORIES_EDIT, {
            params: { id: productVariant.product.category._id },
        }));
        formInstance.setFieldValue("categoryName", productVariant.product.category.name);
    }, [document]);
    useEffect(() => {
        if (!document)
            return;
        formInstance.setFieldValue("attributeOptions", mapAttributesWithVaules(productVariant.product.attributeOptions, productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributesValues));
    }, [document]);
    updateFields(formInstance, form);
    useEffect(() => {
        if (!document)
            return;
        formInstance.setFieldsValue(getInitialValues());
    }, [document]);
    const onSubmit = async (input) => {
        const allImages = input.images || [];
        const newImagesIdsMap = allImages.reduce((prev, current) => ({ ...prev, [current._id]: true }), {});
        const deletedImagesIds = productVariant.images.files
            .map((file) => file._id)
            .filter((id) => !newImagesIdsMap[id]);
        const images = allImages
            .map((image) => image.originFileObj)
            .filter(Boolean);
        const attributeOptions = input.attributeOptions || [];
        const attributesValues = productVariant.attributesValues.map((attr) => ({
            value: attr.value,
            key: attr.key,
            isNumber: attr.isNumber,
            suffix: attr.suffix,
        }));
        const shopAttributes = input.shopAttributes || [];
        const categoriesAttributes = input.categoryAttributes || [];
        const allAttributes = shopAttributes
            .concat(categoriesAttributes)
            .concat(attributeOptions);
        for (const attribute of allAttributes) {
            if (!attribute.value && attribute.isRequired) {
                Ant.notification.warn({
                    message: "Invalid Input",
                    description: "Please fill all required attributes",
                });
                return;
            }
            const existingAttributeValue = attributesValues.find((a) => a.key === attribute.name);
            if (existingAttributeValue) {
                if (attribute.value) {
                    existingAttributeValue.value = attribute.value;
                }
            }
            else {
                attributesValues.push({
                    key: attribute.name,
                    value: attribute.value || "",
                    // TODO: suffix, others?
                });
            }
        }
        delete input.shopAttributes;
        delete input.categoryAttributes;
        delete input.attributeOptions;
        delete input.attributesValues;
        const filteredAttributesValues = attributesValues
            .filter((attributesValue) => (attributesValue.value ? true : false))
            .map((attributesValue) => ({
            ...attributesValue,
            value: attributesValue.value.toString(),
        }));
        const seoFields = packSeoFieldsAndRemoveThem(input);
        try {
            await updateProductVariant({
                variables: {
                    input: {
                        productVariantId: id,
                        ...input,
                        images,
                        deletedImagesIds,
                        attributesValues: filteredAttributesValues,
                        seoFields,
                    },
                },
            });
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_variants.edit_confirmation"),
            });
            router.go({ path: "/products" });
        }
        catch (err) {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
            console.error(err.message || err.toString());
        }
    };
    const getInitialValues = () => {
        var _a, _b, _c;
        return {
            ...productVariant,
            ...unPackSeoFields(productVariant),
            vatRateId: productVariant === null || productVariant === void 0 ? void 0 : productVariant.vatRateId,
            netPrice: productVariant === null || productVariant === void 0 ? void 0 : productVariant.netPrice,
            images: ((_a = productVariant === null || productVariant === void 0 ? void 0 : productVariant.images) === null || _a === void 0 ? void 0 : _a.files) || [],
            discountPercentage: productVariant === null || productVariant === void 0 ? void 0 : productVariant.discountPercentage,
            defaultImageIndex: (_c = (_b = productVariant === null || productVariant === void 0 ? void 0 : productVariant.images) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c.findIndex((image) => { var _a; return image._id === ((_a = productVariant === null || productVariant === void 0 ? void 0 : productVariant.defaultImage) === null || _a === void 0 ? void 0 : _a._id); }),
            discountActiveUntil: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.discountActiveUntil)
                ? moment(productVariant === null || productVariant === void 0 ? void 0 : productVariant.discountActiveUntil)
                : null,
        };
    };
    const onFormValuesChange = (value, values) => {
        if (value.pageTitle) {
            formInstance.setFieldValue("slug", encodeURIComponent(values.pageTitle.toLowerCase().replaceAll(" ", "-")));
        }
    };
    // TODO: this method can be deleted if there's a way to get object input field from the form
    const packSeoFieldsAndRemoveThem = (input) => {
        const { pageTitle, metaDescription, keywords, slug } = input;
        const seoFields = {
            pageTitle,
            metaDescription,
            keywords: keywords ? removeWhiteSpacesAndSplitWordsByComma(keywords) : [],
            slug,
        };
        delete input.pageTitle;
        delete input.metaDescription;
        delete input.keywords;
        delete input.slug;
        return seoFields;
    };
    // TODO: this method can be deleted if there's a way to access keys inside the object in the form
    const unPackSeoFields = (productVariant) => {
        if (!productVariant)
            return {};
        const { seoFields } = productVariant;
        const { pageTitle, metaDescription, keywords, slug } = seoFields || {};
        return {
            pageTitle,
            metaDescription,
            keywords: keywords
                ? transformArrayToStringSeperatedByComma(keywords)
                : keywords,
            slug,
        };
    };
    return {
        form,
        formInstance,
        onSubmit,
        document: getInitialValues(),
        queryLoading,
        queryError,
        mutationLoading,
        onFormValuesChange,
    };
};

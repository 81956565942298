import { gql } from "@apollo/client";
export const GetCategoryQuery = gql `
  query AdminCategoryGet($id: ObjectId!) {
    AdminCategoryGet(_id: $id) {
      _id
      attributeOptions {
        name
        type
        isRequired
        defaultSuffix
      }
      fullAttributeOptions {
        name
        type
        isRequired
        defaultSuffix
      }
      vatRateId
      discountPercentage
      discountActiveUntil
      name
      isRoot
      description
      image {
        _id
        downloadUrl
      }
      parent {
        _id
        name
      }
    }
  }
`;

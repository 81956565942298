import { GET_INVOICE_LIST } from "@bundles/UIAppBundle/pages/InvoicesManagement/components/List/queries";
import { InvoicesCollection as Base } from "./Invoices.collection";
export class CustomInvoicesCollection extends Base {
    async find(query) {
        var _a, _b;
        return this.apolloClient
            .query({
            query: GET_INVOICE_LIST,
            variables: {
                input: {
                    filters: (_a = query.filters) !== null && _a !== void 0 ? _a : {},
                    options: (_b = query.options) !== null && _b !== void 0 ? _b : {},
                },
            },
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => {
            return result.data.AdminGetInvoiceList;
        })
            .catch((err) => {
            console.error(err);
        });
    }
}

import { jsx as _jsx } from "react/jsx-runtime";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { USER_UPLOAD_AVATAR } from "./mutations";
import { notification } from "antd";
import AvatarUpload from "./AvatarUpload";
export const useAvatarUpload = () => {
    const [avatar, setAvatar] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(generateProfileImageLink());
    const [isLoading, setIsLoading] = useState(false);
    const [uploadAvatarMutation] = useMutation(USER_UPLOAD_AVATAR);
    const onAvatarChange = ({ file }) => {
        if (file) {
            setAvatarPreview(URL.createObjectURL(file.originFileObj));
            setAvatar(file.originFileObj);
        }
    };
    const uploadAvatar = (userId) => {
        setIsLoading(true);
        if (!avatar)
            return;
        return uploadAvatarMutation({
            variables: {
                input: {
                    avatar,
                    forUserId: userId,
                },
            },
        })
            .catch((error) => {
            notification.warn({
                message: "Error: Failed uploading avatar",
                description: error.message,
            });
            throw error;
        })
            .finally(() => setIsLoading(false));
    };
    return {
        AvatarUpload: () => (_jsx(AvatarUpload, { avatarPreview: avatarPreview, onAvatarChange: onAvatarChange }, void 0)),
        uploadAvatar,
        setAvatarPreview,
        isLoading,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { OrderViewer as BaseOrderViewer } from "./OrderViewer.base";
let OrderViewer = class OrderViewer extends BaseOrderViewer {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            orderNumber: 0,
            placedAt: 0,
            buyerSnapshot: 0,
            price: 0,
            priceVAT: 0,
            priceTotal: 0,
            couponsOnHold: 0,
            coupons: 0,
            appliedCoupons: 0,
            updatedAt: 0,
            createdAt: 0,
            createdBy: 0,
            createdById: 0,
            updatedBy: 0,
            updatedById: 0,
            // items: {
            //   totalPrice: 1,
            // },
        };
    }
};
OrderViewer = __decorate([
    Service({ transient: true })
], OrderViewer);
export { OrderViewer };

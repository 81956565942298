import { gql } from "@apollo/client";
export const AddProductCategoryMutation = gql `
  mutation AdminProductCategoryCreate(
    $input: AdminProductCategoryCreateInput!
  ) {
    AdminProductCategoryCreate(input: $input) {
      _id
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "../Layout";
import { useShopConfigForm } from "../hooks";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { ShopAttributesConstructor } from "./ShopAttributesConstructor";
import styles from "./ShopAttributesForm.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "../consts";
const ShopAttributesForm = () => {
    const t = useTranslate();
    const { form, document, loading, error, onSubmit } = useShopConfigForm(ShopAttributesConstructor);
    const cleanDocument = {
        attributeOptions: document === null || document === void 0 ? void 0 : document.attributeOptions.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ __typename, ...rest }) => rest),
    };
    return (_jsx(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: _jsxs(ShopConfigurationLayout, { children: [loading && _jsx(Ant.Spin, { size: "large" }, void 0), !loading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !loading && !error && (_jsx(Card, Object.assign({ title: t("management.shop_settings.submenus.shop_attributes").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ labelCol: { span: 0 }, requiredMark: "optional", initialValues: cleanDocument, onFinish: (document) => onSubmit(document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"], icon: Checkmark }, { children: t("generics.save_changes") }), void 0)] }), void 0) }), void 0))] }, void 0) }), void 0));
};
export default ShopAttributesForm;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let UnitsOfMeasurementListFiltersForm = class UnitsOfMeasurementListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "salesRayId",
                label: t("management.units_of_measurement.fields.salesRayId"),
                name: ["salesRayId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "name",
                label: t("management.units_of_measurement.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "label",
                label: t("management.units_of_measurement.fields.label"),
                name: ["label"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "code",
                label: t("management.units_of_measurement.fields.code"),
                name: ["code"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "hasWeight",
                label: t("management.units_of_measurement.fields.hasWeight"),
                name: ["hasWeight"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
        ]);
    }
};
UnitsOfMeasurementListFiltersForm = __decorate([
    Service({ transient: true })
], UnitsOfMeasurementListFiltersForm);
export { UnitsOfMeasurementListFiltersForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useUIComponents } from "@bluelibs/x-ui";
import { newSmart } from "@bluelibs/smart";
import { ShopSettingsService } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { AppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import Sidebar from "./sidebar/SideBar";
import Header from "./header/Header";
import styles from "./Layout.module.scss";
const Layout = ({ children, protect }) => {
    const Components = useUIComponents();
    const [_, GuardianProvider] = newSmart(AppGuardian);
    const [__, ShopSettingsProvider] = newSmart(ShopSettingsService);
    const content = (_jsx(GuardianProvider, { children: _jsx(ShopSettingsProvider, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Sidebar, {}, void 0), _jsxs("section", Object.assign({ className: styles["header-and-content"] }, { children: [_jsx(Header, {}, void 0), _jsx("div", Object.assign({ className: styles.content }, { children: children }), void 0)] }), void 0)] }), void 0) }, void 0) }, void 0));
    if (protect) {
        return _jsx(Components.Protect, { children: content }, void 0);
    }
    else {
        return content;
    }
};
export default Layout;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { AddSimple, UploadPhoto } from "@bundles/UIAppBundle/icons";
import { Button } from "../Button";
import { Card } from "../Card";
import { useImageUpload } from "./hooks";
import styles from "./ImageUpload.module.scss";
const ImageUpload = ({ formInstance }) => {
    const { onChange, customRequest, file, onRemoveImage } = useImageUpload(formInstance);
    return (_jsxs("div", Object.assign({ className: styles["upload-container"] }, { children: [_jsxs(Card, Object.assign({ className: styles["upload-card"] }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Upload Media" }), void 0), _jsx("div", { children: _jsxs(Ant.Upload, Object.assign({ fileList: file ? [file] : [], customRequest: customRequest, onChange: onChange, listType: "picture-card", className: styles.dragger, showUploadList: false }, { children: [_jsx(UploadPhoto, { className: styles["upload-icon"] }, void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "Upload your Media Here" }), void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "(Click or Drag)" }), void 0)] }), void 0) }, void 0)] }), void 0), _jsxs(Card, Object.assign({ className: styles["gallery-card"] }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Category Image:" }), void 0), file ? (_jsxs("div", Object.assign({ className: styles["img-container"] }, { children: [_jsx(Button, { className: styles["remove-btn"], btnStyle: "outlined", icon: AddSimple, width: "normal", onClick: onRemoveImage() }, void 0), _jsx("img", { src: file.downloadUrl, alt: file.name }, void 0)] }), void 0)) : (_jsx("div", Object.assign({ className: styles["card-description"] }, { children: "No image to display yet, please upload one!" }), void 0))] }), void 0)] }), void 0));
};
export default ImageUpload;

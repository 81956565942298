var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { a, Is, Schema } from "@bluelibs/validator-bundle";
let AddressSchema = class AddressSchema {
};
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "firstName", void 0);
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "lastName", void 0);
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "phoneNumber", void 0);
__decorate([
    Is(a.string().required()),
    __metadata("design:type", String)
], AddressSchema.prototype, "streetAddressLine1", void 0);
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "streetAddressLine2", void 0);
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "email", void 0);
__decorate([
    Is(a.string().required()),
    __metadata("design:type", String)
], AddressSchema.prototype, "city", void 0);
__decorate([
    Is(a.string().nullable()),
    __metadata("design:type", String)
], AddressSchema.prototype, "town", void 0);
__decorate([
    Is(a.string().required()),
    __metadata("design:type", String)
], AddressSchema.prototype, "country", void 0);
__decorate([
    Is(a.string().required()),
    __metadata("design:type", String)
], AddressSchema.prototype, "zipcode", void 0);
AddressSchema = __decorate([
    Schema()
], AddressSchema);
export { AddressSchema };

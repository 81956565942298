import { jsx as _jsx } from "react/jsx-runtime";
import { use } from "@bluelibs/x-ui-react-bundle";
import { useMutation } from "@apollo/client";
import * as Ant from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import slugify from "slugify";
import { LongImageUpload } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { PostCreateForm } from "../../config/PostCreateForm";
import { ADMIN_POST_CREATE } from "../../config/mutations";
import { features } from "../../config/features";
export const usePostsCreate = () => {
    const form = use(PostCreateForm, { transient: true });
    form.build();
    const [formInstance] = Ant.Form.useForm();
    const [createPost, { loading: isSubmitting }] = useMutation(ADMIN_POST_CREATE);
    const t = useTranslate();
    const router = useRouter();
    form.add({
        id: "heroImage",
        label: t("management.posts.fields.heroImage"),
        name: ["heroImage"],
        required: true,
        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 32 } }, { children: _jsx(LongImageUpload, { formInstance: formInstance, fieldName: "heroImage" }, void 0) }), void 0)),
    });
    const onFormValuesChange = (value, values) => {
        if (typeof value.title === "string") {
            formInstance.setFieldValue("slug", slugify(values.title));
        }
        if (value.slug) {
            formInstance.setFieldValue("slug", slugify(value.slug));
        }
    };
    const onFinish = (values) => {
        var _a;
        createPost({
            variables: {
                input: {
                    ...values,
                    heroImage: (_a = values.heroImage) === null || _a === void 0 ? void 0 : _a.originFileObj,
                },
            },
        })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.posts.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.list) {
                return router.go(Routes.POSTS_LIST);
            }
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    };
    return { form, formInstance, isSubmitting, onFormValuesChange, onFinish };
};

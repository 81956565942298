import Notifications from "./components/Notifications/Notifications";
import Reviews from "./components/Reviews/Reviews";
import "./i18n";
export const SETTINGS_NOTIFICATIONS = {
    path: "/settings/notifications",
    component: Notifications,
};
export const SETTINGS_REVIEWS = {
    path: "/settings/reviews",
    component: Reviews,
};

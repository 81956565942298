var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
let GetInTouchList = class GetInTouchList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                title: t("management.get_in_touchs.fields.name"),
                key: "management.get_in_touchs.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.salesRayId",
                title: t("management.get_in_touchs.fields.address.salesRayId"),
                key: "management.get_in_touchs.fields.address.salesRayId",
                dataIndex: ["address", "salesRayId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.firstName",
                title: t("management.get_in_touchs.fields.address.firstName"),
                key: "management.get_in_touchs.fields.address.firstName",
                dataIndex: ["address", "firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.lastName",
                title: t("management.get_in_touchs.fields.address.lastName"),
                key: "management.get_in_touchs.fields.address.lastName",
                dataIndex: ["address", "lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.phoneNumber",
                title: t("management.get_in_touchs.fields.address.phoneNumber"),
                key: "management.get_in_touchs.fields.address.phoneNumber",
                dataIndex: ["address", "phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.streetAddressLine1",
                title: t("management.get_in_touchs.fields.address.streetAddressLine1"),
                key: "management.get_in_touchs.fields.address.streetAddressLine1",
                dataIndex: ["address", "streetAddressLine1"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.streetAddressLine2",
                title: t("management.get_in_touchs.fields.address.streetAddressLine2"),
                key: "management.get_in_touchs.fields.address.streetAddressLine2",
                dataIndex: ["address", "streetAddressLine2"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.email",
                title: t("management.get_in_touchs.fields.address.email"),
                key: "management.get_in_touchs.fields.address.email",
                dataIndex: ["address", "email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.city",
                title: t("management.get_in_touchs.fields.address.city"),
                key: "management.get_in_touchs.fields.address.city",
                dataIndex: ["address", "city"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.town",
                title: t("management.get_in_touchs.fields.address.town"),
                key: "management.get_in_touchs.fields.address.town",
                dataIndex: ["address", "town"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.country",
                title: t("management.get_in_touchs.fields.address.country"),
                key: "management.get_in_touchs.fields.address.country",
                dataIndex: ["address", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.zipcode",
                title: t("management.get_in_touchs.fields.address.zipcode"),
                key: "management.get_in_touchs.fields.address.zipcode",
                dataIndex: ["address", "zipcode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.companyName",
                title: t("management.get_in_touchs.fields.address.companyName"),
                key: "management.get_in_touchs.fields.address.companyName",
                dataIndex: ["address", "companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address.vatNumber",
                title: t("management.get_in_touchs.fields.address.vatNumber"),
                key: "management.get_in_touchs.fields.address.vatNumber",
                dataIndex: ["address", "vatNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phone",
                title: t("management.get_in_touchs.fields.phone"),
                key: "management.get_in_touchs.fields.phone",
                dataIndex: ["phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                title: t("management.get_in_touchs.fields.email"),
                key: "management.get_in_touchs.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "description",
                title: t("management.get_in_touchs.fields.description"),
                key: "management.get_in_touchs.fields.description",
                dataIndex: ["description"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "protectData",
                title: t("management.get_in_touchs.fields.protectData"),
                key: "management.get_in_touchs.fields.protectData",
                dataIndex: ["protectData"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.get_in_touchs.fields.createdAt"),
                key: "management.get_in_touchs.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.get_in_touchs.fields.updatedAt"),
                key: "management.get_in_touchs.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {};
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            address: {
                salesRayId: 1,
                firstName: 1,
                lastName: 1,
                phoneNumber: 1,
                streetAddressLine1: 1,
                streetAddressLine2: 1,
                email: 1,
                city: 1,
                town: 1,
                country: 1,
                zipcode: 1,
                companyName: 1,
                vatNumber: 1,
            },
            phone: 1,
            email: 1,
            description: 1,
            protectData: 1,
            createdAt: 1,
            updatedAt: 1,
        };
    }
};
GetInTouchList = __decorate([
    Service({ transient: true })
], GetInTouchList);
export { GetInTouchList };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "@bundles/UIAppBundle/components";
import { useExportProducts } from "./hooks";
import { Start } from "./Start";
import { Exporting } from "./Exporting";
import { Download } from "./Download";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export const ExportProducts = () => {
    const { step, 
    // data,
    // loading,
    // error,
    exported, total, fileName, date, onClickStopExporting, onClickStartExporting, onClickStartNewExport, onClickDownload, } = useExportProducts();
    return (_jsxs(Layout, { children: [_jsx(Seo, { data: seoData.exportProduct }, void 0), step === 0 && (
            // TODO (!called && !loading) &&
            _jsx(Start, Object.assign({}, {
                onClickStartExporting,
            }), void 0)), step === 1 && (
            // TODO loading &&
            _jsx(Exporting, Object.assign({}, { exported, total, onClickStopExporting }), void 0)), step === 2 && (
            // TODO data &&
            _jsx(Download, Object.assign({}, {
                exported,
                fileName,
                date,
                onClickStartNewExport,
                onClickDownload,
            }), void 0))] }, void 0));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { Toggle } from "@bundles/UIAppBundle/components/Toggle";
import styles from "../../SettingsSection.module.scss";
const InvoicesNotif = ({ invoiceNotifications, handleChangeNotificationStatus, }) => {
    const t = useTranslate("management.settings.notifications.invoices");
    return (_jsxs("div", Object.assign({ className: styles["section-container"] }, { children: [_jsx("h2", Object.assign({ className: styles.title }, { children: t("title") }), void 0), _jsx("div", Object.assign({ className: styles["settings-container"] }, { children: Object.keys(invoiceNotifications).map((id) => {
                    return (_jsxs(Card, Object.assign({ className: styles["setting-container"] }, { children: [_jsx("p", Object.assign({ className: styles.content }, { children: t(`settings.${id}`) }), void 0), _jsx(Toggle, { id: id, checked: invoiceNotifications[id], onChange: (event) => handleChangeNotificationStatus(event.target.id) }, void 0)] }), id));
                }) }), void 0)] }), void 0));
};
export default InvoicesNotif;

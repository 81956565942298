import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { AdminShopSettingEdit } from "@bundles/UIAppBundle/components/ShopConfiguration/mutations";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { IntegrationOption } from "@bundles/UIAppBundle/pages/Marketing/consts";
import { Routes } from "@bundles/UIAppBundle";
import { useState } from "react";
export const useIntegrationDetails = (integrationOption) => {
    var _a, _b;
    const shopSettings = useShopSettings();
    const [updateShopSettings] = useMutation(AdminShopSettingEdit);
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const tl = useTranslate("management.marketing");
    const t = useTranslate();
    const integrationKey = integrationOption === IntegrationOption.GOOGLE_ANALYTICS
        ? "google"
        : "facebookPixel";
    const onChange = (data) => {
        shopSettings.updateState({
            analyticsURLs: {
                ...shopSettings.state.analyticsURLs,
                [integrationKey]: data,
            },
        });
    };
    const onSubmit = () => {
        const analyticsURLs = shopSettings.state.analyticsURLs;
        delete analyticsURLs.__typename;
        setLoading(true);
        updateShopSettings({
            variables: { document: { analyticsURLs } },
        })
            .then(() => {
            notification.success({
                message: tl(`edit_confirmation.${integrationKey}`),
            });
            router.go(Routes.MARKETING_INTEGRATIONS);
        })
            .catch((error) => {
            notification.error({
                message: t("generics.error"),
                description: error.message,
            });
        })
            .finally(() => setLoading(false));
    };
    return {
        integrationLink: (_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.analyticsURLs) === null || _b === void 0 ? void 0 : _b[integrationKey],
        loading,
        title: tl(`fields.${integrationKey}`),
        label: tl(`labels.${integrationKey}`),
        description: tl(`descriptions.${integrationKey}`),
        placeholder: tl(`placeholders.${integrationKey}`),
        onChange,
        onSubmit,
    };
};

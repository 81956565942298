import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import React, { useEffect, useState } from "react";
import { Routes } from "@bundles/UIAppBundle";
import { Row, Col, Alert, Card } from "antd";
import PublicRoute from "@bundles/UIAppBundle/hoc/PublicRoute/PublicRoute";
export function VerifyEmail(props) {
    const { token } = props;
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.verifyEmail");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isVerifyingEmail, setIsVerifyingEmail] = useState(true);
    const [emailVerificationError, setEmailVerificationError] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            guardian
                .verifyEmail(token)
                .then(() => {
                setIsVerifyingEmail(false);
                setIsEmailVerified(true);
                setTimeout(() => {
                    router.go(Routes.DASHBOARD);
                }, 3500);
            })
                .catch((err) => {
                setIsVerifyingEmail(false);
                setEmailVerificationError(err.message || err.toString());
            });
        }, 1000);
    }, []);
    const style = { minHeight: "100vh" };
    return (_jsx(PublicRoute, { children: _jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "verify-email-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsxs(Card, Object.assign({ title: tl("header") }, { children: [isVerifyingEmail && _jsx(Alert, { message: tl("verifying") }, void 0), isEmailVerified && (_jsx(Alert, { type: "success", message: tl("success") }, void 0)), emailVerificationError && (_jsx(Alert, { type: "error", message: tl("errored") }, void 0))] }), void 0) }), void 0) }), void 0) }, void 0));
}

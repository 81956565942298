import { ProductVariantsList } from "../components/List/ProductVariantsList";
import { ProductVariantsCreate } from "../components/Create/ProductVariantsCreate";
import { ProductVariantsEdit } from "../components/Edit/ProductVariantsEdit";
import { ProductVariantsView } from "../components/View/ProductVariantsView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_VARIANTS_LIST = {
    path: "/admin/product-variants",
    component: ProductVariantsList,
    menu: {
        key: "PRODUCT_VARIANTS_LIST",
        label: "management.product_variants.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_VARIANTS_CREATE = {
    path: "/admin/product-variants/create",
    component: ProductVariantsCreate,
};
export const PRODUCT_VARIANTS_EDIT = {
    path: "/admin/product-variants/:id/edit",
    component: ProductVariantsEdit,
};
export const PRODUCT_VARIANTS_VIEW = {
    path: "/admin/product-variants/:id/view",
    component: ProductVariantsView,
};

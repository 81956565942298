import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { use, useTranslate, useUIComponents } from "@bluelibs/x-ui";
import { Col, Form, Spin, Alert } from "antd";
import { InternalUserProfileEditForm } from "../../config/InternalUserProfileEditForm";
import { Card, ControlledSelect, FieldGroupWrapper, } from "@bundles/UIAppBundle/components";
import { Topbar } from "@bundles/UIAppBundle/components/Topbar";
import { useInternalUserProfilesEdit } from "./hooks";
import styles from "./InternalUserProfilesEdit.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function InternalUserProfilesEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(InternalUserProfileEditForm, { transient: true });
    const { document, isLoading, error, handleUserUpdate, rolesOptions, updateIsLoading, AvatarUpload, } = useInternalUserProfilesEdit(props.id);
    form.build();
    form.update("roles", {
        render: (props) => (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { initialValue: document === null || document === void 0 ? void 0 : document.user.roles }, { children: _jsx(ControlledSelect, { label: t("management.internal_user_profiles.fields.roles"), placeholder: t("management.internal_user_profiles.placeholder.roles"), options: rolesOptions, multiple: true }, void 0) }), void 0) }), void 0)),
    });
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.TEAM_ADMIN] }, { children: [_jsx(Seo, { data: seoData.internalUserProfile.edit }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [isLoading && _jsx(Spin, { size: "large" }, void 0), !isLoading && (error || !document) && (_jsx(Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsxs(Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => handleUserUpdate(document) }, { children: [_jsx(Topbar, { title: t("management.internal_user_profiles.edit.header"), btnText: t("management.internal_user_profiles.create.submit"), btnOnClick: () => null, btnLoading: updateIsLoading }, void 0), _jsx(Card, Object.assign({ className: styles.card }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: t("management.internal_user_profiles.create.cardHeader") }, { children: [_jsx("div", Object.assign({ className: styles["row"] }, { children: _jsx(AvatarUpload, {}, void 0) }), void 0), form.render("firstName"), form.render("lastName"), form.render("email"), form.render("phoneNumber"), form.render("roles")] }), void 0) }), void 0)] }), void 0))] }), void 0) }, void 0)] }), void 0));
}

import "./i18n";
import { PRODUCT_CATEGORIES_LIST as BASE_PRODUCT_CATEGORIES_LIST, PRODUCT_CATEGORIES_CREATE as BASE_PRODUCT_CATEGORIES_CREATE, PRODUCT_CATEGORIES_EDIT as BASE_PRODUCT_CATEGORIES_EDIT, PRODUCT_CATEGORIES_VIEW as BASE_PRODUCT_CATEGORIES_VIEW, } from "./config/routes";
import { ProductCategoriesCreate } from "./components/Create/ProductCategoriesCreate";
export const PRODUCT_CATEGORIES_LIST = {
    ...BASE_PRODUCT_CATEGORIES_LIST,
    path: "/product-categories",
};
export const PRODUCT_CATEGORIES_CREATE = {
    ...BASE_PRODUCT_CATEGORIES_CREATE,
    path: "/product-categories/create",
};
export const PRODUCT_CATEGORIES_EDIT = {
    ...BASE_PRODUCT_CATEGORIES_EDIT,
    path: "/product-categories/:id/edit",
};
export const PRODUCT_CATEGORIES_VIEW = {
    ...BASE_PRODUCT_CATEGORIES_VIEW,
    path: "/product-categories/:id/view",
};
export const PRODUCT_CATEGORIES_CREATE_SUBCATEGORY = {
    path: "/product-categories/:id/create",
    // TODO: change here to the component for subcateg
    component: ProductCategoriesCreate,
};

import { useQuery } from "@apollo/client";
import { getInternalUserRoles } from "@bundles/UIAppBundle/queries/internalUser.query";
export const useTeamMemberView = (id) => {
    var _a, _b;
    const { data } = useQuery(getInternalUserRoles, {
        variables: {
            input: {
                adminId: id,
            },
        },
    });
    const roles = (_b = (_a = data === null || data === void 0 ? void 0 : data.InternalUserProfileGetAdmin) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.roles.map((r) => r.split("_").join(" "));
    return {
        roles,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import { Col, Form, Row } from "antd";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { InternalUserProfileCreateForm } from "../../config/InternalUserProfileCreateForm";
import { Card, FieldGroupWrapper, ControlledSelect, Topbar, } from "@bundles/UIAppBundle/components";
import { useInternalUserProfilesCreate } from "./hooks";
import styles from "./InternalUserProfilesCreate.module.scss";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function InternalUserProfilesCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(InternalUserProfileCreateForm, { transient: true });
    const { handleUserCreate, rolesOptions, AvatarUpload, isLoading } = useInternalUserProfilesCreate();
    form.build();
    form.update("roles", {
        render: (props) => (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Form.Item, Object.assign({}, props, { initialValue: [] }, { children: _jsx(ControlledSelect, { label: t("management.internal_user_profiles.fields.roles"), placeholder: t("management.internal_user_profiles.placeholder.roles"), options: rolesOptions, multiple: true }, void 0) }), void 0) }), void 0)),
    });
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.TEAM_ADMIN] }, { children: [_jsx(Seo, { data: seoData.internalUserProfile.create }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsx("div", Object.assign({ className: styles["container"] }, { children: _jsxs(Form, Object.assign({ requiredMark: "optional", onFinish: (document) => {
                            handleUserCreate(document);
                        } }, { children: [_jsx(Topbar, { title: t("management.internal_user_profiles.create.header"), btnText: t("management.internal_user_profiles.create.submit"), btnOnClick: () => null, btnLoading: isLoading }, void 0), _jsx(Card, Object.assign({ className: styles.card }, { children: _jsxs(FieldGroupWrapper, Object.assign({ title: t("management.internal_user_profiles.create.cardHeader") }, { children: [_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Row, { children: _jsx(AvatarUpload, {}, void 0) }, void 0) }), void 0), form.render("firstName"), form.render("lastName"), form.render("email"), form.render("phoneNumber"), form.render("roles")] }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0));
}

import "./i18n";
import { GET_IN_TOUCHS_LIST as BASE_GET_IN_TOUCHS_LIST, GET_IN_TOUCHS_CREATE as BASE_GET_IN_TOUCHS_CREATE, GET_IN_TOUCHS_EDIT as BASE_GET_IN_TOUCHS_EDIT, GET_IN_TOUCHS_VIEW as BASE_GET_IN_TOUCHS_VIEW, } from "./config/routes";
export const GET_IN_TOUCHS_LIST = {
    ...BASE_GET_IN_TOUCHS_LIST,
};
export const GET_IN_TOUCHS_CREATE = {
    ...BASE_GET_IN_TOUCHS_CREATE,
};
export const GET_IN_TOUCHS_EDIT = {
    ...BASE_GET_IN_TOUCHS_EDIT,
};
export const GET_IN_TOUCHS_VIEW = {
    ...BASE_GET_IN_TOUCHS_VIEW,
};

import { ProductFavoritesList } from "../components/List/ProductFavoritesList";
import { ProductFavoritesCreate } from "../components/Create/ProductFavoritesCreate";
import { ProductFavoritesEdit } from "../components/Edit/ProductFavoritesEdit";
import { ProductFavoritesView } from "../components/View/ProductFavoritesView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_FAVORITES_LIST = {
    path: "/admin/product-favorites",
    component: ProductFavoritesList,
    menu: {
        key: "PRODUCT_FAVORITES_LIST",
        label: "management.product_favorites.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_FAVORITES_CREATE = {
    path: "/admin/product-favorites/create",
    component: ProductFavoritesCreate,
};
export const PRODUCT_FAVORITES_EDIT = {
    path: "/admin/product-favorites/:id/edit",
    component: ProductFavoritesEdit,
};
export const PRODUCT_FAVORITES_VIEW = {
    path: "/admin/product-favorites/:id/view",
    component: ProductFavoritesView,
};

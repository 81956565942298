import { Collection, } from "@bluelibs/x-ui";
import { Invoice } from "@root/api.types";
import { EndUserProfilesCollection, OrdersCollection, PaymentsCollection, PaymentOptionsCollection, } from "@bundles/UIAppBundle/collections";
export class InvoicesCollection extends Collection {
    getName() {
        return "Invoices";
    }
    getInputs() {
        return {
            insert: "InvoiceInsertInput!",
            update: "InvoiceUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "buyer",
                field: "buyerId",
            },
            {
                collection: () => OrdersCollection,
                name: "order",
                field: "orderId",
            },
            {
                collection: () => PaymentOptionsCollection,
                name: "paymentType",
                field: "paymentTypeId",
            },
            {
                collection: () => PaymentsCollection,
                name: "payments",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            sendAt: (v) => (v && new Date(v) ? new Date(v) : v),
            paidAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

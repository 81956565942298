import { Collection, } from "@bluelibs/x-ui";
import { Order } from "@root/api.types";
import { OrderItemsCollection, EndUserProfilesCollection, InvoicesCollection, PaymentsCollection, InternalUserProfilesCollection, PaymentOptionsCollection, ShippingOptionsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class OrdersCollection extends Collection {
    getName() {
        return "Orders";
    }
    getInputs() {
        return {
            insert: "OrderInsertInput!",
            update: "OrderUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "buyer",
                field: "buyerId",
            },
            {
                collection: () => InvoicesCollection,
                name: "invoice",
                field: "invoiceId",
            },
            {
                collection: () => PaymentsCollection,
                name: "payment",
                field: "paymentId",
            },
            {
                collection: () => InternalUserProfilesCollection,
                name: "salesAgent",
                field: "salesAgentId",
            },
            {
                collection: () => PaymentOptionsCollection,
                name: "paymentOption",
                field: "paymentOptionId",
            },
            {
                collection: () => ShippingOptionsCollection,
                name: "shippingOption",
                field: "shippingOptionId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => OrderItemsCollection,
                name: "items",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            placedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

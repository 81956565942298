var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Service } from "@bluelibs/core";
import classNames from "classnames";
import { Routes } from "@bundles/UIAppBundle";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Clipboard } from "@bundles/UIAppBundle/icons";
import { ordersTagColors, paymentMethodTagColors, } from "@bundles/UIAppBundle/consts/colors";
import { orderStatusLabel } from "@bundles/UIAppBundle/consts/labels";
import { formatCurrencyAndPrice } from "@bundles/UIAppBundle/utils/helpers";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
import { CustomTag } from "@bundles/UIAppBundle/components/CustomTag";
import { OrderList as BaseOrderList } from "./OrderList.base";
import styles from "../components/List/OrdersList.module.scss";
let OrderList = class OrderList extends BaseOrderList {
    build() {
        const { router } = this;
        const { t } = this.i18n;
        super.build();
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.remove("updatedAt");
        this.remove("createdAt");
        this.remove("appliedCoupons");
        this.remove("couponsOnHold");
        this.remove("priceVAT");
        this.remove("price");
        this.remove("privateNotes");
        // this.remove("buyerSnapshot.name");
        this.remove("buyer");
        this.remove("buyerSnapshot.phoneNumber");
        this.remove("buyerSnapshot.email");
        this.remove("shippingAddress.nbh");
        this.remove("shippingAddress.building_floor");
        this.remove("shippingAddress.zip");
        this.remove("shippingAddress.address");
        this.remove("shippingAddress.building_entrance");
        this.remove("shippingAddress.street");
        this.remove("shippingAddress.district");
        this.remove("shippingAddress.tax");
        this.remove("shippingAddress.note");
        this.remove("shippingAddress.num");
        this.remove("shippingAddress.salesRayId");
        this.remove("shippingAddress.zip_num");
        this.remove("shippingAddress.fax");
        this.remove("shippingAddress.building");
        this.remove("shippingAddress.settlement");
        this.remove("shippingAddress.phone");
        this.remove("shippingAddress.building_apartment");
        this.remove("shippingAddress.streetAddressLine1");
        this.remove("shippingAddress.streetAddressLine2");
        this.remove("shippingAddress.city");
        this.remove("shippingAddress.country");
        this.remove("shippingAddress.county");
        this.remove("shippingAddress.zipcode");
        this.remove("shippingAddress.firstName");
        this.remove("shippingAddress.lastName");
        this.remove("shippingAddress.phoneNumber");
        this.remove("shippingAddress.email");
        this.remove("shippingAddress.town");
        this.remove("billingAddress.nbh");
        this.remove("billingAddress.building_floor");
        this.remove("billingAddress.zip");
        this.remove("billingAddress.address");
        this.remove("billingAddress.building_entrance");
        this.remove("billingAddress.street");
        this.remove("billingAddress.district");
        this.remove("billingAddress.tax");
        this.remove("billingAddress.note");
        this.remove("billingAddress.num");
        this.remove("billingAddress.salesRayId");
        this.remove("billingAddress.zip_num");
        this.remove("billingAddress.fax");
        this.remove("billingAddress.building");
        this.remove("billingAddress.settlement");
        this.remove("billingAddress.phone");
        this.remove("billingAddress.building_apartment");
        this.remove("billingAddress.firstName");
        this.remove("billingAddress.lastName");
        this.remove("billingAddress.phoneNumber");
        this.remove("billingAddress.email");
        this.remove("billingAddress.zipcode");
        this.remove("billingAddress.city");
        this.remove("billingAddress.country");
        this.remove("billingAddress.town");
        this.remove("billingAddress.streetAddressLine1");
        this.remove("billingAddress.streetAddressLine2");
        this.remove("items");
        this.remove("invoice");
        this.remove("salesAgent");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("payment");
        this.remove("coupons");
        this.remove("priceTotal");
        this.remove("shippingOption");
        this.remove("salesRayId");
        this.add({
            title: "",
            sorter: false,
            order: 0,
            width: 0,
            id: "invoiceImg",
            dataIndex: ["status"],
            render: (status) => (_jsx("div", { children: _jsx(CustomTag, { isIcon: true, variant: "filled", color: ordersTagColors[status], value: _jsx(Clipboard, {}, void 0) }, void 0) }, void 0)),
        });
        this.update("orderNumber", {
            order: 0,
            title: t("management.orders.table.orderNumber"),
            dataIndex: ["orderNumber"],
            render: (value) => {
                return (_jsx("div", Object.assign({ className: classNames(styles["order-wrapper"], styles["no-wrap"]) }, { children: _jsx("span", { children: value }, void 0) }), void 0));
            },
        });
        this.update("buyerSnapshot.name", {
            order: 1,
            dataIndex: ["buyerSnapshot", "name"],
            title: t("management.orders.table.buyer"),
            sorter: true,
            render: (value, model) => {
                var _a, _b, _c;
                return (_jsxs("div", Object.assign({ className: classNames(styles["buyer-wrapper"], styles["no-wrap"]) }, { children: [_jsx("img", { className: styles.img, src: generateProfileImageLink(model.buyerSnapshot.name, (_c = (_b = (_a = model.buyer.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("span", Object.assign({ onClick: () => router.go(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: model.buyerId,
                                },
                            }) }, { children: value }), void 0)] }), void 0));
            },
        });
        this.update("placedAt", {
            order: 2,
            id: "placedAt",
            title: t("management.orders.table.placedAt"),
            dataIndex: ["placedAt"],
            sorter: true,
            render: (value) => {
                const date = new Date(value);
                const dateString = date.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                });
                return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
            },
        });
        this.update("paymentOption", {
            order: 3,
            dataIndex: ["paymentOption"],
            title: t("management.orders.table.payment"),
            sorter: true,
            render: (paymentOption) => {
                return (_jsx("div", Object.assign({ className: styles["payment"] }, { children: paymentOption ? (_jsx(CustomTag, { color: paymentMethodTagColors[paymentOption.type], value: paymentOption.name, variant: "filled" }, void 0)) : (_jsx(CustomTag, { color: TagColorsEnum.GRAY, value: "dummy", variant: "filled" }, void 0)) }), void 0));
            },
        });
        this.update("status", {
            order: 4,
            title: t("management.orders.table.status"),
            render: (value) => (_jsx("div", Object.assign({ className: classNames(styles["status"]) }, { children: _jsx(CustomTag, { value: orderStatusLabel[value], variant: "filled", color: ordersTagColors[value] }, void 0) }), void 0)),
        });
        this.update("finalPrice", {
            order: 5,
            title: t("management.orders.table.finalPrice"),
            render: (value, model) => {
                var _a;
                // FIXME find a better way to determine currency
                return (_jsx("span", Object.assign({ className: styles["bold"] }, { children: formatCurrencyAndPrice((_a = model.items[0]) === null || _a === void 0 ? void 0 : _a.price.currency, value) }), void 0));
            },
        });
        this.update("invoiceImg", { order: -1 });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            shippingOption: { price: 1 },
            priceTotal: 0,
        };
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            status: "orderStatus",
            "buyerSnapshot.name": "clientName",
            paymentOption: "paymentOption",
        };
    }
};
OrderList = __decorate([
    Service({ transient: true })
], OrderList);
export { OrderList };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { InternalUserProfilesCollection, PostsCollection, } from "@bundles/UIAppBundle/collections";
let PostCreateForm = class PostCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.posts.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "subtitle",
                label: t("management.posts.fields.subtitle"),
                name: ["subtitle"],
                component: Ant.Input,
            },
            {
                id: "slug",
                label: t("management.posts.fields.slug"),
                name: ["slug"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "body",
                label: t("management.posts.fields.body"),
                name: ["body"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "visibility",
                label: t("management.posts.fields.visibility"),
                name: ["visibility"],
                required: true,
                initialValue: "VISIBLE",
                render: (props) => {
                    const options = [
                        { value: "VISIBLE", label: "Visible" },
                        { value: "HIDDEN", label: "Hidden" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.posts.fields.visibility") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.posts.fields.visibility_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.posts.fields.visibility_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "heroImageId",
                label: t("management.posts.fields.heroImage"),
                name: ["heroImageId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "authorId",
                label: t("management.posts.fields.author"),
                name: ["authorId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.posts.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.POSTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.POSTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.POSTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => PostsCollection),
    __metadata("design:type", typeof (_a = typeof PostsCollection !== "undefined" && PostsCollection) === "function" ? _a : Object)
], PostCreateForm.prototype, "collection", void 0);
PostCreateForm = __decorate([
    Service({ transient: true })
], PostCreateForm);
export { PostCreateForm };

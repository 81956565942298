import { gql } from "@apollo/client";
export const getSimulatedOrder = gql `
  query SimulatedOrderGet($input: SimulatedOrderGetInput) {
    SimulatedOrderGet(input: $input) {
      price
      priceTotal
      items {
        count
        productVariant {
          name
          netPrice
          totalDiscountPercentage
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";
export const My3DProductModels = gql `
  query Product3DModelFindOne($query: QueryInput) {
    Product3DModelFindOne(query: $query) {
      productVariant {
        model3D {
          downloadUrl
        }
        model3DId
      }
      _id
      texts {
        _id
        fontColor
        backgroundColor
        fontSize
        position {
          x
          y
          z
        }
        rotation {
          x
          y
          z
        }
        text
        size
        nodeName
      }
      name
      images {
        _id
        position {
          x
          y
          z
        }
        rotation {
          x
          y
          z
        }
        image {
          _id
          name
          downloadUrl
        }
        size
        nodeName
      }
    }
  }
`;

import "./i18n";
import { COUPONS_LIST as BASE_COUPONS_LIST, COUPONS_CREATE as BASE_COUPONS_CREATE, COUPONS_EDIT as BASE_COUPONS_EDIT, COUPONS_VIEW as BASE_COUPONS_VIEW, } from "./config/routes";
export const COUPONS_LIST = {
    ...BASE_COUPONS_LIST,
    path: "/coupons",
};
export const COUPONS_CREATE = {
    ...BASE_COUPONS_CREATE,
    path: "/coupons/create",
};
export const COUPONS_EDIT = {
    ...BASE_COUPONS_EDIT,
    path: "/coupons/:id/edit",
};
export const COUPONS_VIEW = {
    ...BASE_COUPONS_VIEW,
    path: "/coupons/:id/view",
};

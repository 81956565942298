import { gql } from "@apollo/client";
export const END_USER_GET_PROFILE = gql `
  query AdminEndUserProfileGet($input: AdminEndUserProfileGetInput!) {
    AdminEndUserProfileGet(input: $input) {
      _id
      orders {
        _id
        status
        priceTotal
        paymentOption {
          name
          type
        }
        orderNumber
        createdAt
        invoiceId
        invoice {
          downloadUrl
        }
        items {
          price {
            currency
          }
        }
      }
    }
  }
`;

import { Collection, } from "@bluelibs/x-ui";
import { Review } from "@root/api.types";
import { EndUserProfilesCollection, ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
export class ReviewsCollection extends Collection {
    getName() {
        return "Reviews";
    }
    getInputs() {
        return {
            insert: "ReviewInsertInput!",
            update: "ReviewUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "reviewer",
                field: "reviewerId",
            },
            {
                collection: () => ProductVariantsCollection,
                name: "productVariant",
                field: "productVariantId",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "likes",
                many: true,
                field: "likesIds",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "disLikes",
                many: true,
                field: "disLikesIds",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "reports",
                many: true,
                field: "reportsIds",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

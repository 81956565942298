import { useQuery } from "@apollo/client";
import { GET_RETURN_DETAIL } from "../query";
export const useReturnDetail = (id) => {
    if (!id) {
        return {
            returnDetail: null,
            loading: false,
        };
    }
    const { data, loading, refetch } = useQuery(GET_RETURN_DETAIL, {
        variables: {
            input: {
                returnId: id,
            },
        },
    });
    const returnDetail = data === null || data === void 0 ? void 0 : data.AdminReturnGet;
    const price = +(returnDetail === null || returnDetail === void 0 ? void 0 : returnDetail.price.net);
    const priceVat = +(returnDetail === null || returnDetail === void 0 ? void 0 : returnDetail.price.gross);
    const vatCharge = +(returnDetail === null || returnDetail === void 0 ? void 0 : returnDetail.price.tax);
    return {
        returnDetail: {
            ...returnDetail,
            price,
            priceVat,
            vatCharge,
        },
        loading,
        refetch,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { CustomPageHeader } from "@bundles/UIAppBundle/components/CustomPageHeader";
import { Table } from "@bundles/UIAppBundle/components/Table";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import styles from "./ProductCategoriesList.module.scss";
import { useProductCategories } from "./hooks";
import { ExpandedInfoTable } from "./ExpandedInfoTable";
import ExpandRowIcon from "./ExpandRowIcon";
export function ProductCategoriesList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { Provider, api, isLoadingSync, onFiltersUpdate, onClickSync } = useProductCategories();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.productCategories.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.product_categories.list.header"), searchKey: "name", syncLabel: t("management.product_categories.list.sync"), isLoadingSync: isLoadingSync, onFiltersUpdate: onFiltersUpdate, searchPlaceholder: t("management.product_categories.list.search"), onClickSync: onClickSync }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsx(Table, Object.assign({ rowClassName: () => styles.row, className: styles.table }, api.getTableProps(), { expandable: {
                                    expandIcon: ExpandRowIcon,
                                    expandedRowClassName: () => styles["expanded-row"],
                                    expandedRowRender: (record) => (_jsx(ExpandedInfoTable, { record: record }, void 0)),
                                } }), void 0) }, void 0) }, void 0)] }, void 0)] }), void 0));
}

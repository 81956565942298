import React from "react";
import * as Ant from "antd";
import * as debounce from "lodash.debounce";
export const useCustomTableFilters = (onUpdate) => {
    const [form] = Ant.Form.useForm();
    const debouncedFilterUpdates = React.useMemo(() => {
        const setFilters = (_, filters) => {
            onUpdate(filters);
        };
        return debounce(setFilters, 500);
    }, [onUpdate]);
    const onReset = () => {
        form.resetFields();
        onUpdate(form.getFieldsValue());
    };
    return { debouncedFilterUpdates, onReset, form };
};

import { ReviewsList } from "../components/List/ReviewsList";
import { ReviewsCreate } from "../components/Create/ReviewsCreate";
import { ReviewsEdit } from "../components/Edit/ReviewsEdit";
import { ReviewsView } from "../components/View/ReviewsView";
import { SettingFilled } from "@ant-design/icons";
export const REVIEWS_LIST = {
    path: "/admin/reviews",
    component: ReviewsList,
    menu: {
        key: "REVIEWS_LIST",
        label: "management.reviews.menu.title",
        icon: SettingFilled,
    },
};
export const REVIEWS_CREATE = {
    path: "/admin/reviews/create",
    component: ReviewsCreate,
};
export const REVIEWS_EDIT = {
    path: "/admin/reviews/:id/edit",
    component: ReviewsEdit,
};
export const REVIEWS_VIEW = {
    path: "/admin/reviews/:id/view",
    component: ReviewsView,
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { DownChevron } from "@bundles/UIAppBundle/icons";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { checkAdminRights } from "./../utils/CheckAdminRights";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { dropdownLinks } from "./consts";
import styles from "./Header.module.scss";
const Header = () => {
    var _a, _b;
    const appGuardian = useAppGuardian();
    const { state: { user }, } = appGuardian;
    const userRoles = (user === null || user === void 0 ? void 0 : user.roles) || [];
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsx("div", Object.assign({ className: styles.content }, { children: _jsx("div", Object.assign({ className: styles["admin-info"] }, { children: _jsx(Dropdown, Object.assign({ placement: "bottomRight", overlayClassName: styles.overlay, arrow: true, overlay: _jsx("div", Object.assign({ className: styles["dropdown-content"] }, { children: _jsx("ul", Object.assign({ className: "cc-custom-list" }, { children: dropdownLinks.map(({ label, url, roles, onclick }, idx) => {
                                if (!checkAdminRights(userRoles, roles))
                                    return null;
                                return (_jsx("li", { children: _jsx(Link, Object.assign({ className: styles.link, to: url, onClick: onclick ? () => onclick(appGuardian) : undefined }, { children: label }), void 0) }, idx));
                            }) }), void 0) }), void 0), trigger: ["click"] }, { children: _jsxs("div", Object.assign({ className: styles["dropdown-trigger"] }, { children: [_jsxs("div", Object.assign({ className: styles["name-and-role"] }, { children: [_jsx("div", Object.assign({ className: styles.name }, { children: user === null || user === void 0 ? void 0 : user.fullName }), void 0), _jsx("div", Object.assign({ className: styles.role }, { children: "Admin" }), void 0)] }), void 0), _jsx("div", Object.assign({ className: styles["avatar-container"] }, { children: _jsx("img", { className: "cc--of-cover", src: generateProfileImageLink(user === null || user === void 0 ? void 0 : user.fullName, (_b = (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl), alt: "user avatar" }, void 0) }), void 0), _jsx(DownChevron, { className: styles.icon }, void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0));
};
export default Header;

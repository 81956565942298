import { useState } from "react";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ValidatorService } from "@bluelibs/validator-bundle";
import { useGuardian } from "@bluelibs/x-ui-guardian-bundle";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { ResetPasswordFormSchema, DefaultValues } from "./consts";
export const useResetPassword = ({ token }) => {
    const [input, setInput] = useState(DefaultValues);
    const [error, setError] = useState(null);
    const validatorService = use(ValidatorService);
    const tl = useTranslate("authentication.resetPassword");
    const guardian = useGuardian();
    const router = useRouter();
    const onSubmit = async (event) => {
        event.preventDefault();
        const { email, newPassword } = input;
        try {
            await validatorService.validate(input, {
                model: ResetPasswordFormSchema,
            });
            setError(null);
        }
        catch (error) {
            setError(error);
            return;
        }
        guardian
            .resetPassword(email, token, newPassword)
            .then(() => {
            notification.success({
                message: tl("success"),
            });
            setTimeout(() => {
                router.go(Routes.DASHBOARD);
            }, 4500);
        })
            .catch((error) => {
            notification.error({
                message: error.message,
            });
        });
    };
    const fieldError = (inputKey) => {
        if (inputKey === (error === null || error === void 0 ? void 0 : error.path))
            return error === null || error === void 0 ? void 0 : error.errors[0];
        return null;
    };
    const onChangeValue = (key, value) => {
        setInput({
            ...input,
            [key]: value,
        });
    };
    return {
        fieldError,
        onChangeValue,
        onSubmit,
    };
};

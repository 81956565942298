var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { EndUserProfilesCollection, ProductVariantsCollection, ProductCategoriesCollection, CouponsCollection, } from "@bundles/UIAppBundle/collections";
let CouponCreateForm = class CouponCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "code",
                label: t("management.coupons.fields.code"),
                name: ["code"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "isActive",
                label: t("management.coupons.fields.isActive"),
                name: ["isActive"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "applicableOnAll",
                label: t("management.coupons.fields.applicableOnAll"),
                name: ["applicableOnAll"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "isActiveUntil",
                label: t("management.coupons.fields.isActiveUntil"),
                name: ["isActiveUntil"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "isUsageLimited",
                label: t("management.coupons.fields.isUsageLimited"),
                name: ["isUsageLimited"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "isUsageByUserLimited",
                label: t("management.coupons.fields.isUsageByUserLimited"),
                name: ["isUsageByUserLimited"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "limitUsage",
                label: t("management.coupons.fields.limitUsage"),
                name: ["limitUsage"],
                component: Ant.InputNumber,
            },
            {
                id: "limitUsagePerUser",
                label: t("management.coupons.fields.limitUsagePerUser"),
                name: ["limitUsagePerUser"],
                component: Ant.InputNumber,
            },
            {
                id: "amount",
                label: t("management.coupons.fields.amount"),
                name: ["amount"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "isPercentage",
                label: t("management.coupons.fields.isPercentage"),
                name: ["isPercentage"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "allowedUsersIds",
                label: t("management.coupons.fields.allowedUsers"),
                name: ["allowedUsersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "usedByIds",
                label: t("management.coupons.fields.usedBy"),
                name: ["usedByIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "productVariantsIds",
                label: t("management.coupons.fields.productVariants"),
                name: ["productVariantsIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductVariantsCollection, field: "name", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "productCategoryIds",
                label: t("management.coupons.fields.productCategory"),
                name: ["productCategoryIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.coupons.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.COUPONS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.COUPONS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.COUPONS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CouponsCollection),
    __metadata("design:type", typeof (_a = typeof CouponsCollection !== "undefined" && CouponsCollection) === "function" ? _a : Object)
], CouponCreateForm.prototype, "collection", void 0);
CouponCreateForm = __decorate([
    Service({ transient: true })
], CouponCreateForm);
export { CouponCreateForm };

import "./i18n";
import { PAYMENT_OPTIONS_LIST as BASE_PAYMENT_OPTIONS_LIST, PAYMENT_OPTIONS_CREATE as BASE_PAYMENT_OPTIONS_CREATE, PAYMENT_OPTIONS_EDIT as BASE_PAYMENT_OPTIONS_EDIT, PAYMENT_OPTIONS_VIEW as BASE_PAYMENT_OPTIONS_VIEW, } from "./config/routes";
export const PAYMENT_OPTIONS_LIST = {
    ...BASE_PAYMENT_OPTIONS_LIST,
    path: "/shop-settings/payment-options",
};
export const PAYMENT_OPTIONS_CREATE = {
    ...BASE_PAYMENT_OPTIONS_CREATE,
    path: "/shop-settings/payment-options/create",
};
export const PAYMENT_OPTIONS_EDIT = {
    ...BASE_PAYMENT_OPTIONS_EDIT,
    path: "/shop-settings/payment-options/:id/edit",
};
export const PAYMENT_OPTIONS_VIEW = {
    ...BASE_PAYMENT_OPTIONS_VIEW,
};

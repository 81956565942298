import { jsx as _jsx } from "react/jsx-runtime";
import { Routes } from "@bundles/UIAppBundle";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { Clipboard, Download, KebabMenu, View, } from "@bundles/UIAppBundle/icons";
import { features } from "../../config/features";
import { InvoiceList } from "../../config/InvoiceList";
import { CustomInvoicesCollection } from "@bundles/UIAppBundle/collections/Invoices/Invoices.custom.collection";
export class InvoicesAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomInvoicesCollection;
    }
    getBody() {
        return InvoiceList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(InvoiceList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            title: "",
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return InvoiceList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.INVOICES_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        actions.push({
            label: "Go to Order",
            icon: _jsx(Clipboard, { height: "15px", width: "12px" }, void 0),
            action: (model) => {
                this.router.go(Routes.ORDERS_VIEW, {
                    params: {
                        id: model.order._id,
                    },
                });
            },
        });
        actions.push({
            label: "Invoice" || this.i18n.t("generics.delete"),
            icon: _jsx(Download, {}, void 0),
            action: (model) => {
                window.open(model.downloadUrl);
            },
        });
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["invoiceId"]) {
            modifiedFiltersObj["invoiceId"] = modifiedFiltersObj["invoiceId"].source;
        }
        if (modifiedFiltersObj["paymentTypeId"]) {
            modifiedFiltersObj["paymentMethodIds"] =
                modifiedFiltersObj["paymentTypeId"]["$in"];
            delete modifiedFiltersObj["paymentTypeId"];
        }
        if (modifiedFiltersObj["orderTotal"]) {
            modifiedFiltersObj["orderPrice"] = {
                gte: parseFloat(modifiedFiltersObj["orderTotal"]["$in"][0]),
                lte: parseFloat(modifiedFiltersObj["orderTotal"]["$in"][1]),
            };
            delete modifiedFiltersObj["orderTotal"];
        }
        if (modifiedFiltersObj["order.placedAt"]) {
            modifiedFiltersObj["orderDate"] = {
                gte: (modifiedFiltersObj["order.placedAt"]["$gte"] || "").toString() ||
                    null,
                lte: (modifiedFiltersObj["order.placedAt"]["$lte"] || "").toString() ||
                    null,
            };
            delete modifiedFiltersObj["order.placedAt"];
        }
        if (modifiedFiltersObj["status"]) {
            modifiedFiltersObj["status"] = (modifiedFiltersObj["status"] || "")
                .toString()
                .split("/")[1];
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
                // ...this.state.filters,
                // ...this.alwaysOnFilters,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        }, this.getBody())
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}

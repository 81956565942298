import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { newSmart, useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { EndUserProfilesAntTableSmart } from "./EndUserProfilesTableSmart";
import { AdminClientsAddressesSync, AdminClientsSync } from "./mutations";
export const useClientsList = () => {
    const t = useTranslate();
    const [api, Provider] = newSmart(EndUserProfilesAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [filters, setFilters] = useState({});
    const [_syncClients, { loading: isLoadingClientsSync }] = useMutation(AdminClientsSync);
    const [_syncClientsAddresses, { loading: isLoadingClientsAddressesSync }] = useMutation(AdminClientsAddressesSync);
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const nextFilters = { ...filters, ...newFilters };
            setFilters(nextFilters);
            api.setFlexibleFilters(nextFilters);
        };
    }, [filters]);
    const syncClients = () => {
        notification.info({
            key: "syncing_clients",
            message: t("management.end_user_profiles.list.syncing"),
            duration: 3600,
        });
        return _syncClients()
            .then(() => {
            api.load();
            notification.close("syncing_clients");
            notification.success({
                message: t("management.end_user_profiles.list.sync_success"),
            });
        })
            .catch(() => {
            notification.close("syncing_clients");
            notification.error({
                message: t("management.end_user_profiles.list.sync_error"),
            });
        });
    };
    const syncClientsAddresses = () => {
        notification.info({
            key: "syncing_clients_addresses",
            message: t("management.end_user_profiles.list.syncing_addresses"),
            duration: 3600,
        });
        return _syncClientsAddresses()
            .then(() => {
            api.load();
            notification.close("syncing_clients_addresses");
            notification.success({
                message: t("management.end_user_profiles.list.sync_addresses_success"),
            });
        })
            .catch(() => {
            notification.close("syncing_clients_addresses");
            notification.error({
                message: t("management.end_user_profiles.list.sync_addresses_error"),
            });
        });
    };
    const onClickSync = () => {
        syncClients();
        syncClientsAddresses();
    };
    return {
        api,
        Provider,
        isLoadingSync: isLoadingClientsSync || isLoadingClientsAddressesSync,
        filtersOpened,
        onFiltersUpdate,
        setFiltersOpened,
        onClickSync,
    };
};

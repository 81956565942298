import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useRouter, useUIComponents, useTranslate, } from "@bluelibs/x-ui";
import { useState, useMemo } from "react";
import { OrderItemsAntTableSmart } from "./OrderItemsTableSmart";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { features } from "../../config/features";
import { OrderItemsListFilters } from "./OrderItemsListFilters";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function OrderItemsList() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const [api, Provider] = newSmart(OrderItemsAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const onFiltersUpdate = useMemo(() => {
        return (filters) => {
            api.setFlexibleFilters(filters);
        };
    }, []);
    return (_jsxs(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: [_jsx(Seo, { data: seoData.orderItem.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.order_items.list.header"), extra: [
                            features.create ? (_jsx(Ant.Button, Object.assign({ onClick: () => router.go(Routes.ORDER_ITEMS_CREATE), icon: _jsx(PlusOutlined, {}, void 0) }, { children: t("management.order_items.list.create_btn") }), "1")) : null,
                            _jsx(Ant.Button, Object.assign({ onClick: () => setFiltersOpened(!filtersOpened), icon: _jsx(FilterOutlined, {}, void 0) }, { children: t("generics.list_filters") }), "2"),
                        ] }, void 0), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsxs("div", Object.assign({ className: "page-order-items-list" }, { children: [filtersOpened && (_jsx(OrderItemsListFilters, { onUpdate: onFiltersUpdate }, void 0)), _jsx(Ant.Input.Search, { name: "Search", placeholder: t("generics.list_search"), className: "search", onKeyUp: (e) => {
                                            const value = e.target.value;
                                            api.setFilters({
                                                // Customise your search filters!
                                                title: new RegExp(`${value}`, "i"),
                                            });
                                        } }, void 0), _jsx(Ant.Table, Object.assign({}, api.getTableProps()), void 0)] }), void 0) }, void 0) }, void 0)] }, void 0)] }), void 0));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import { VatRateCreateForm as BaseVatRateCreateForm } from "./VatRateCreateForm.base";
import { InputNumber } from "@bundles/UIAppBundle/components/InputNumber";
import { CustomVatRatesCollection, VatRatesCollection, } from "@bundles/UIAppBundle/collections";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let VatRateForm = class VatRateForm extends BaseVatRateCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.update("name", {
            order: 2,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: addRequiredFieldSpecialMark(t("management.vat_rates.labels.name")), placeholder: t("management.vat_rates.placeholders.name") }, void 0) }), void 0) }), void 0)),
        });
        this.update("percentage", {
            order: 1,
            rules: [{ min: 1, max: 99, type: "number", required: true }],
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(InputNumber, { label: addRequiredFieldSpecialMark(t("management.vat_rates.labels.percentage")), placeholder: t("management.vat_rates.placeholders.percentage") }, void 0) }), void 0) }), void 0)),
        });
        this.update("description", {
            order: 3,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.vat_rates.labels.description"), placeholder: t("management.vat_rates.placeholders.description") }, void 0) }), void 0) }), void 0)),
        });
    }
};
__decorate([
    Inject(() => CustomVatRatesCollection),
    __metadata("design:type", typeof (_a = typeof VatRatesCollection !== "undefined" && VatRatesCollection) === "function" ? _a : Object)
], VatRateForm.prototype, "collection", void 0);
VatRateForm = __decorate([
    Service({ transient: true })
], VatRateForm);
export { VatRateForm };

import { Kernel } from "@bluelibs/core";
import { XUIBundle } from "@bluelibs/x-ui";
import { XUIAdminBundle } from "@bluelibs/x-ui-admin";
import { Layout } from "@bundles/UIAppBundle/components/Layout";
import { AdminListItemRenderer } from "@bundles/UIAppBundle/overrides/AdminListItemRenderer";
import { AppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { UIAppBundle } from "@bundles/UIAppBundle/UIAppBundle";
// All UI bundles need to be prefixed with UI
// All X-Framework bundles have the first prefix X
export const kernel = new Kernel({
    bundles: [
        new XUIBundle({
            graphql: {
                // ApolloClient Options
                // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClientOptions
                uri: process.env.API_URL,
            },
            guardian: { guardianClass: AppGuardian },
        }),
        new UIAppBundle(),
        new XUIAdminBundle({
            components: {
                //This is how you override the XUiAdmin components, you can find them here: https://github.com/bluelibs/bluelibs/tree/main/packages/x-ui-admin/src/react/components
                AdminLayout: Layout,
                AdminListItemRenderer: AdminListItemRenderer,
            },
        }),
    ],
});

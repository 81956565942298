import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import { ControlledInput, ControlledSelect, InputNumber, } from "@bundles/UIAppBundle/components";
import { AttributeListItem } from "@bundles/UIAppBundle/components/AttributeListItem";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
import styles from "../ProductsCreate.module.scss";
export const inheritedAttributesListRenderer = ({ collection, noDescription = false, isVariant = false, isEdit = false, }) => 
// eslint-disable-next-line react/display-name
(fields) => {
    return (_jsx(_Fragment, { children: fields.map((field, index) => {
            return (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => {
                    return (_jsx(AttributeListItem, Object.assign({ noDescription: noDescription, inheritedFrom: collection === "categoryAttributes" &&
                            getFieldValue("categoryName"), url: (collection === "categoryAttributes" &&
                            getFieldValue("categoryUrl")) ||
                            undefined }, getFieldValue(collection)[field.name], { children: _jsx(ListItemChildren, { field: field, collection: collection, isVariant: isVariant, isEdit: isEdit }, void 0) }), void 0));
                } }), index));
        }) }, void 0));
};
// eslint-disable-next-line react/prop-types
function ListItemChildren({ field, collection, isVariant, isEdit }) {
    const t = useTranslate();
    const getInputComponent = (type) => {
        if (type == "string")
            return ControlledInput;
        return InputNumber;
    };
    const getRules = (isRequired, isNumber, isRange, rangeMin, rangeMax) => {
        const rules = [];
        if (isRequired)
            rules.push({
                required: true,
                message: t("generics.forms.field_required", {
                    field: t("management.product_variants.fields.attributesValues.value"),
                }),
            });
        if (isNumber)
            rules.push({
                type: "number",
                message: t("generics.forms.field_number", {
                    field: t("management.product_variants.fields.attributesValues.value"),
                }),
            });
        if (isRange) {
            rules.push({
                type: "number",
                min: rangeMin,
                max: rangeMax,
                message: t("generics.forms.range", {
                    field: t("management.product_variants.fields.attributesValues.value"),
                    min: rangeMin,
                    max: rangeMax,
                }),
            });
        }
        return rules;
    };
    return (_jsx(Ant.Form.Item, Object.assign({ shouldUpdate: true, noStyle: true }, { children: ({ getFieldValue }) => {
            var _a, _b;
            const { name, isRequired = false, isEnum, enumValues, type, isRange, rangeMin, rangeMax,
            // eslint-disable-next-line react/prop-types
             } = getFieldValue(collection)[field.name];
            const Input = getInputComponent(type);
            return (_jsx(Ant.Form.Item, Object.assign({}, field, { 
                // eslint-disable-next-line react/prop-types
                name: [field.name, "value"], required: isRequired, className: styles["list-item-input-wrapper"], rules: getRules(isRequired, !(isEnum && enumValues) && type === "number", isRange, rangeMin, rangeMax) }, { children: isEnum && enumValues ? (_jsx(ControlledSelect, { defaultValue: isVariant && isEdit
                        ? (_a = getFieldValue("attributesValues").find((attributeValue) => attributeValue.key === name)) === null || _a === void 0 ? void 0 : _a.value
                        : undefined, label: isRequired
                        ? addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.value"))
                        : t("management.products.fields.attributeOptions.value"), placeholder: t("management.products.placeholders.attributeOptions.value.type"), options: enumValues.map((value) => ({
                        label: value,
                        value: value,
                    })) }, void 0)) : (_jsx(Input, { defaultValue: isVariant && isEdit
                        ? (_b = getFieldValue("attributesValues").find((v) => v.key === name)) === null || _b === void 0 ? void 0 : _b.value
                        : undefined, label: isRequired
                        ? addRequiredFieldSpecialMark(t("management.products.fields.attributeOptions.value"))
                        : t("management.products.fields.attributeOptions.value"), placeholder: t("management.products.placeholders.attributeOptions.value.type"), type: type === "number" ? "number" : null }, void 0)) }), void 0));
        } }), void 0));
}

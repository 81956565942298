var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { a, Is, Schema } from "@bluelibs/validator-bundle";
export const Input = {
    EMAIL: "email",
    PASSWORD: "password",
};
export const Label = {
    [Input.EMAIL]: "Email",
    [Input.PASSWORD]: "Password",
};
export const DefaultValues = {
    email: "",
    password: "",
};
export const PlaceHolder = {
    [Input.EMAIL]: "Email",
    [Input.PASSWORD]: "Password",
};
let LoginFormSchema = class LoginFormSchema {
};
__decorate([
    Is(a
        .string()
        .required("Password is required.")
        .typeError("Should be a string")),
    __metadata("design:type", String)
], LoginFormSchema.prototype, "password", void 0);
__decorate([
    Is(a
        .string()
        .required("Email field is required.")
        .email("Email must be valid")
        .typeError("Should be a string")),
    __metadata("design:type", String)
], LoginFormSchema.prototype, "email", void 0);
LoginFormSchema = __decorate([
    Schema()
], LoginFormSchema);
export { LoginFormSchema };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopShippingAddressCreateForm } from "../../config/ShopShippingAddressCreateForm";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { useShopShippingAddressCreate } from "./hooks";
export function ShopShippingAddressCreate() {
    const t = useTranslate();
    const form = use(ShopShippingAddressCreateForm, { transient: true });
    form.build();
    const { onSubmit } = useShopShippingAddressCreate();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shippingAddress.create }, void 0), _jsx(ShopConfigurationLayout, { children: _jsx(Card, Object.assign({ title: t("management.shipping_address.create").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", onFinish: (document) => {
                            onSubmit(document);
                        } }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["absolute-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0));
}

import { Collection, } from "@bluelibs/x-ui";
import { ProductVariantPriceList } from "@root/api.types";
import { UnitsOfMeasurementCollection } from "@bundles/UIAppBundle/collections";
export class ProductVariantPriceListsCollection extends Collection {
    getName() {
        return "ProductVariantPriceLists";
    }
    getInputs() {
        return {
            insert: "ProductVariantPriceListInsertInput!",
            update: "ProductVariantPriceListUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UnitsOfMeasurementCollection,
                name: "unitOfMeasurement",
                field: "unitOfMeasurementId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import { use, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation, useQuery } from "@apollo/client";
import * as Ant from "antd";
import { AddProductVariantMutation } from "../mutations";
import { Routes } from "@bundles/UIAppBundle";
import { updateFields } from "./extraFieldsUpdates";
import { getProduct } from "../queries";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { useEffect } from "react";
import { ProductVariantCreateForm } from "../../config/ProductVariantCreateForm";
import moment from "moment";
import { removeWhiteSpacesAndSplitWordsByComma } from "@bundles/UIAppBundle/utils/helpers";
export const useProductVariantCreate = (id) => {
    const t = useTranslate();
    const form = use(ProductVariantCreateForm, { transient: true });
    const router = useRouter();
    const shopSettings = useShopSettings();
    const [formInstance] = Ant.Form.useForm();
    const [addProductVariant, { loading: mutationLoading }] = useMutation(AddProductVariantMutation);
    form.build();
    useEffect(() => {
        var _a, _b;
        formInstance.setFieldValue("shopAttributes", (_b = (_a = shopSettings.state) === null || _a === void 0 ? void 0 : _a.attributeOptions) === null || _b === void 0 ? void 0 : _b.map((att) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, ...rest } = att;
            return rest;
        }));
    }, [shopSettings.state]);
    const { data: document, loading: queryLoading, error: queryError, } = useQuery(getProduct, {
        fetchPolicy: "network-only",
        variables: { input: { _id: id } },
    });
    const product = document === null || document === void 0 ? void 0 : document.AdminProductsGet;
    useEffect(() => {
        if (!document)
            return;
        formInstance.setFieldValue("categoryAttributes", product.category.attributeOptions);
        formInstance.setFieldValue("categoryName", product.category.name);
    }, [document]);
    updateFields(formInstance, form);
    const onSubmit = async (input) => {
        var _a;
        const allImages = input.images || [];
        const adminProductsGet = document === null || document === void 0 ? void 0 : document.AdminProductsGet;
        const newImagesIdsMap = allImages.reduce((prev, current) => ({ ...prev, [current._id]: true }), {});
        const deletedImagesIds = adminProductsGet.images.files
            .map((file) => file._id)
            .filter((id) => !newImagesIdsMap[id]);
        const images = allImages
            .map((image) => image.originFileObj)
            .filter(Boolean);
        const attributeOptions = input.attributeOptions || [];
        const attributesValues = [];
        const shopAttributes = input.shopAttributes || [];
        const categoriesAttributes = input.categoryAttributes || [];
        const seoFields = packSeoFieldsAndRemoveThem(input);
        const allAttributes = shopAttributes
            .concat(categoriesAttributes)
            .concat(attributeOptions);
        for (const attribute of allAttributes) {
            attributesValues.push({
                key: attribute.name,
                value: ((_a = attribute.value) === null || _a === void 0 ? void 0 : _a.toString()) || "",
                // TODO: suffix, others?
            });
        }
        for (const key in input.attributeOptions) {
            delete input.attributeOptions[key].value;
        }
        delete input.shopAttributes;
        delete input.categoryAttributes;
        delete input.attributeOptions;
        try {
            const { data } = await addProductVariant({
                variables: {
                    input: {
                        ...input,
                        images,
                        deletedImagesIds,
                        productId: id,
                        attributesValues,
                        seoFields,
                    },
                },
            });
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_variants.create_confirmation"),
            });
            router.go(Routes.PRODUCT_VARIANTS_EDIT, {
                params: {
                    productId: id,
                    variantId: data.AdminProductVariantCreate._id,
                },
            });
        }
        catch (err) {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
            console.error(err.message || err.toString());
        }
    };
    const getInitialValues = () => {
        var _a, _b, _c, _d;
        const adminProductsGet = document === null || document === void 0 ? void 0 : document.AdminProductsGet;
        return {
            ...adminProductsGet,
            vatRateId: (_a = adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.vatRate) === null || _a === void 0 ? void 0 : _a._id,
            netPrice: adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.defaultNetPrice,
            images: ((_b = adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.images) === null || _b === void 0 ? void 0 : _b.files) || [],
            discountPercentage: adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.defaultDiscountPercentage,
            defaultImageIndex: (_d = (_c = adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.images) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d.findIndex((image) => { var _a; return image._id === ((_a = adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.defaultImage) === null || _a === void 0 ? void 0 : _a._id); }),
            attributeOptions: adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.attributeOptions.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ __typename, ...rest }) => rest),
            discountActiveUntil: (adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.discountActiveUntil)
                ? moment(adminProductsGet === null || adminProductsGet === void 0 ? void 0 : adminProductsGet.discountActiveUntil)
                : null,
        };
    };
    const onFormValuesChange = (value, values) => {
        if (value.pageTitle) {
            formInstance.setFieldValue("slug", encodeURIComponent(values.pageTitle.toLowerCase().replaceAll(" ", "-")));
        }
    };
    const packSeoFieldsAndRemoveThem = (input) => {
        const { pageTitle, metaDescription, keywords, slug } = input;
        const seoFields = {
            pageTitle,
            metaDescription,
            keywords: keywords ? removeWhiteSpacesAndSplitWordsByComma(keywords) : [],
            slug,
        };
        delete input.pageTitle;
        delete input.metaDescription;
        delete input.keywords;
        delete input.slug;
        return seoFields;
    };
    return {
        form,
        formInstance,
        onSubmit,
        document: getInitialValues(),
        queryLoading,
        queryError,
        mutationLoading,
        onFormValuesChange,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { EndUserProfilesCollection, OrdersCollection, InternalUserProfilesCollection, InvoicesCollection, PaymentOptionsCollection, ShippingOptionsCollection, ReturnsCollection, } from "@bundles/UIAppBundle/collections";
let ReturnEditForm = class ReturnEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "number",
                label: t("management.returns.fields.number"),
                name: ["number"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "billingAddress",
                label: t("management.returns.fields.billingAddress"),
                name: ["billingAddress"],
                nest: [
                    {
                        id: "nbh",
                        label: t("management.returns.fields.billingAddress.nbh"),
                        name: ["billingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.returns.fields.billingAddress.building_floor"),
                        name: ["billingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.returns.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.returns.fields.billingAddress.address"),
                        name: ["billingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.returns.fields.billingAddress.building_entrance"),
                        name: ["billingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.returns.fields.billingAddress.street"),
                        name: ["billingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.returns.fields.billingAddress.district"),
                        name: ["billingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.returns.fields.billingAddress.tax"),
                        name: ["billingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.returns.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.returns.fields.billingAddress.note"),
                        name: ["billingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.returns.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.returns.fields.billingAddress.num"),
                        name: ["billingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.returns.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.returns.fields.billingAddress.zip_num"),
                        name: ["billingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.returns.fields.billingAddress.fax"),
                        name: ["billingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.returns.fields.billingAddress.building"),
                        name: ["billingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.returns.fields.billingAddress.settlement"),
                        name: ["billingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.returns.fields.billingAddress.phone"),
                        name: ["billingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.returns.fields.billingAddress.building_apartment"),
                        name: ["billingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "shippingAddress",
                label: t("management.returns.fields.shippingAddress"),
                name: ["shippingAddress"],
                nest: [
                    {
                        id: "nbh",
                        label: t("management.returns.fields.shippingAddress.nbh"),
                        name: ["shippingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.returns.fields.shippingAddress.building_floor"),
                        name: ["shippingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.returns.fields.shippingAddress.zip"),
                        name: ["shippingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.returns.fields.shippingAddress.address"),
                        name: ["shippingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.returns.fields.shippingAddress.building_entrance"),
                        name: ["shippingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.returns.fields.shippingAddress.street"),
                        name: ["shippingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.returns.fields.shippingAddress.district"),
                        name: ["shippingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.returns.fields.shippingAddress.tax"),
                        name: ["shippingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.returns.fields.shippingAddress.country"),
                        name: ["shippingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.returns.fields.shippingAddress.note"),
                        name: ["shippingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.returns.fields.shippingAddress.email"),
                        name: ["shippingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.returns.fields.shippingAddress.num"),
                        name: ["shippingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.returns.fields.shippingAddress.salesRayId"),
                        name: ["shippingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.returns.fields.shippingAddress.zip_num"),
                        name: ["shippingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.returns.fields.shippingAddress.fax"),
                        name: ["shippingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.returns.fields.shippingAddress.building"),
                        name: ["shippingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.returns.fields.shippingAddress.settlement"),
                        name: ["shippingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.returns.fields.shippingAddress.phone"),
                        name: ["shippingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.returns.fields.shippingAddress.building_apartment"),
                        name: ["shippingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "items",
                label: t("management.returns.fields.items"),
                name: ["items"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "orderItemId",
                        label: t("management.returns.fields.items.orderItemId"),
                        name: ["items", "orderItemId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "quantity",
                        label: t("management.returns.fields.items.quantity"),
                        name: ["items", "quantity"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "name",
                        label: t("management.returns.fields.items.name"),
                        name: ["items", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "sku",
                        label: t("management.returns.fields.items.sku"),
                        name: ["items", "sku"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "price",
                        label: t("management.returns.fields.items.price"),
                        name: ["items", "price"],
                        required: true,
                        nest: [],
                        initialValue: [],
                    },
                    {
                        id: "imageUrl",
                        label: t("management.returns.fields.items.imageUrl"),
                        name: ["items", "imageUrl"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "attributesValues",
                        label: t("management.returns.fields.items.attributesValues"),
                        name: ["items", "attributesValues"],
                        isList: true,
                        nest: [],
                        initialValue: [],
                    },
                ],
                initialValue: [],
            },
            {
                id: "status",
                label: t("management.returns.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "PENDING", label: "Pending" },
                        { value: "COMPLETED", label: "Completed" },
                        { value: "CANCELED", label: "Canceled" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.returns.fields.status") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.returns.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.returns.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "notes",
                label: t("management.returns.fields.notes"),
                name: ["notes"],
                component: Ant.Input,
            },
            {
                id: "endUserId",
                label: t("management.returns.fields.endUser"),
                name: ["endUserId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
            {
                id: "orderId",
                label: t("management.returns.fields.order"),
                name: ["orderId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrdersCollection, field: "orderNumber", required: true }, void 0) }), void 0)),
            },
            {
                id: "adminId",
                label: t("management.returns.fields.admin"),
                name: ["adminId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", required: false }, void 0) }), void 0)),
            },
            {
                id: "invoiceId",
                label: t("management.returns.fields.invoice"),
                name: ["invoiceId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InvoicesCollection, field: "invoiceId", required: true }, void 0) }), void 0)),
            },
            {
                id: "paymentOptionId",
                label: t("management.returns.fields.paymentOption"),
                name: ["paymentOptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: PaymentOptionsCollection, field: "name", required: false }, void 0) }), void 0)),
            },
            {
                id: "shippingOptionId",
                label: t("management.returns.fields.shippingOption"),
                name: ["shippingOptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ShippingOptionsCollection, field: "name", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            number: 1,
            billingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            shippingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            items: {
                orderItemId: 1,
                quantity: 1,
                name: 1,
                sku: 1,
                price: {
                    currency: 1,
                    gross: 1,
                    net: 1,
                    tax: 1,
                },
                imageUrl: 1,
                attributesValues: {
                    key: 1,
                    value: 1,
                    suffix: 1,
                    isNumber: 1,
                },
            },
            price: {
                currency: 1,
                gross: 1,
                net: 1,
                tax: 1,
            },
            status: 1,
            notes: 1,
            endUser: {
                _id: 1,
                fullName: 1,
            },
            endUserId: 1,
            order: {
                _id: 1,
                orderNumber: 1,
            },
            orderId: 1,
            admin: {
                _id: 1,
                fullName: 1,
            },
            adminId: 1,
            invoice: {
                _id: 1,
                invoiceId: 1,
            },
            invoiceId: 1,
            paymentOption: {
                _id: 1,
                name: 1,
            },
            paymentOptionId: 1,
            shippingOption: {
                _id: 1,
                name: 1,
            },
            shippingOptionId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.returns.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ReturnsCollection),
    __metadata("design:type", typeof (_a = typeof ReturnsCollection !== "undefined" && ReturnsCollection) === "function" ? _a : Object)
], ReturnEditForm.prototype, "collection", void 0);
ReturnEditForm = __decorate([
    Service({ transient: true })
], ReturnEditForm);
export { ReturnEditForm };

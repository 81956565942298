var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ProductViewer = class ProductViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "updatedAt",
                label: t("management.products.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.products.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "discountActiveUntil",
                label: t("management.products.fields.discountActiveUntil"),
                dataIndex: ["discountActiveUntil"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "defaultDiscountPercentage",
                label: t("management.products.fields.defaultDiscountPercentage"),
                dataIndex: ["defaultDiscountPercentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "defaultNetPrice",
                label: t("management.products.fields.defaultNetPrice"),
                dataIndex: ["defaultNetPrice"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "hasVariants",
                label: t("management.products.fields.hasVariants"),
                dataIndex: ["hasVariants"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "country",
                label: t("management.products.fields.country"),
                dataIndex: ["country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "height",
                label: t("management.products.fields.height"),
                dataIndex: ["height"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "width",
                label: t("management.products.fields.width"),
                dataIndex: ["width"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "length",
                label: t("management.products.fields.length"),
                dataIndex: ["length"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "weight",
                label: t("management.products.fields.weight"),
                dataIndex: ["weight"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "type",
                label: t("management.products.fields.type"),
                dataIndex: ["type"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "brand",
                label: t("management.products.fields.brand"),
                dataIndex: ["brand"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                dataIndex: ["tags"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "discountPercentage",
                label: t("management.products.fields.discountPercentage"),
                dataIndex: ["discountPercentage"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "purchasedCount",
                label: t("management.products.fields.purchasedCount"),
                dataIndex: ["purchasedCount"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "availableStockCount",
                label: t("management.products.fields.availableStockCount"),
                dataIndex: ["availableStockCount"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "reviews.stars",
                label: t("management.products.fields.reviews.stars"),
                dataIndex: ["reviews", "stars"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "reviews.reviewsCount",
                label: t("management.products.fields.reviews.reviewsCount"),
                dataIndex: ["reviews", "reviewsCount"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "stockAvailability",
                label: t("management.products.fields.stockAvailability"),
                dataIndex: ["stockAvailability"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "priceRange",
                label: t("management.products.fields.priceRange"),
                dataIndex: ["priceRange"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "number",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "attributeOptions",
                label: t("management.products.fields.attributeOptions"),
                dataIndex: ["attributeOptions"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "categoriesBreadcrumbs",
                label: t("management.products.fields.categoriesBreadcrumbs"),
                dataIndex: ["categoriesBreadcrumbs"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "slug",
                label: t("management.products.fields.slug"),
                dataIndex: ["slug"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.pageTitle",
                label: t("management.products.fields.seoFields.pageTitle"),
                dataIndex: ["seoFields", "pageTitle"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.metaDescription",
                label: t("management.products.fields.seoFields.metaDescription"),
                dataIndex: ["seoFields", "metaDescription"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.keywords",
                label: t("management.products.fields.seoFields.keywords"),
                dataIndex: ["seoFields", "keywords"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "seoFields.seoTitle",
                label: t("management.products.fields.seoFields.seoTitle"),
                dataIndex: ["seoFields", "seoTitle"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.seoDescription",
                label: t("management.products.fields.seoFields.seoDescription"),
                dataIndex: ["seoFields", "seoDescription"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seoFields.slug",
                label: t("management.products.fields.seoFields.slug"),
                dataIndex: ["seoFields", "slug"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "params",
                label: t("management.products.fields.params"),
                dataIndex: ["params"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "description",
                label: t("management.products.fields.description"),
                dataIndex: ["description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "name",
                label: t("management.products.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                label: t("management.products.fields.salesRayId"),
                dataIndex: ["salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "_id",
                label: t("management.products.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "defaultImage",
                label: t("management.products.fields.defaultImage"),
                dataIndex: ["defaultImage"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "images",
                label: t("management.products.fields.images"),
                dataIndex: ["images"],
                render: (value) => {
                    const props = {
                        type: "fileGroup",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "category",
                label: t("management.products.fields.category"),
                dataIndex: ["category"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "variants",
                label: t("management.products.fields.variants"),
                dataIndex: ["variants"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_VARIANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "vatRate",
                label: t("management.products.fields.vatRate"),
                dataIndex: ["vatRate"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.VAT_RATES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                label: t("management.products.fields.createdBy"),
                dataIndex: ["createdBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                label: t("management.products.fields.updatedBy"),
                dataIndex: ["updatedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            updatedAt: 1,
            createdAt: 1,
            discountActiveUntil: 1,
            defaultDiscountPercentage: 1,
            defaultNetPrice: 1,
            hasVariants: 1,
            country: 1,
            height: 1,
            width: 1,
            length: 1,
            weight: 1,
            type: 1,
            brand: 1,
            tags: 1,
            discountPercentage: 1,
            purchasedCount: 1,
            availableStockCount: 1,
            reviews: {
                stars: 1,
                reviewsCount: 1,
            },
            stockAvailability: 1,
            priceRange: 1,
            attributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            categoriesBreadcrumbs: {
                name: 1,
                _id: 1,
                discountPercentage: 1,
                discountActiveUntil: 1,
                attributeOptions: {
                    name: 1,
                    type: 1,
                    isRequired: 1,
                    isEnum: 1,
                    enumValues: 1,
                    isRange: 1,
                    defaultValue: 1,
                    defaultSuffix: 1,
                    rangeMin: 1,
                    rangeMax: 1,
                },
            },
            slug: 1,
            seoFields: {
                pageTitle: 1,
                metaDescription: 1,
                keywords: 1,
                seoTitle: 1,
                seoDescription: 1,
                slug: 1,
            },
            params: 1,
            description: 1,
            name: 1,
            salesRayId: 1,
            defaultImage: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            defaultImageId: 1,
            images: {
                _id: 1,
                name: 1,
                files: {
                    downloadUrl: 1,
                    name: 1,
                },
            },
            imagesId: 1,
            category: {
                _id: 1,
                name: 1,
            },
            categoryId: 1,
            variants: {
                _id: 1,
                name: 1,
            },
            vatRate: {
                _id: 1,
                name: 1,
            },
            vatRateId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
ProductViewer = __decorate([
    Service({ transient: true })
], ProductViewer);
export { ProductViewer };

import { use, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation } from "@apollo/client";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { UpdateProductCategoryMutation } from "./mutations";
import { updateFields } from "./helpers/extraFieldsUpdates";
import { ProductCategoryEditForm } from "../../config/ProductCategoryEditForm";
import moment from "moment";
export const useProductCategoryEdit = ({ _id, isRoot, attributeOptions, fullAttributeOptions, discountActiveUntil, }) => {
    const t = useTranslate();
    const router = useRouter();
    const form = use(ProductCategoryEditForm, { transient: true });
    const [formInstance] = Ant.Form.useForm();
    const [updateProductCategory, { loading: mutationLoading }] = useMutation(UpdateProductCategoryMutation);
    form.build();
    updateFields(formInstance, form);
    attributeOptions = attributeOptions === null || attributeOptions === void 0 ? void 0 : attributeOptions.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ __typename, ...rest }) => rest);
    const attributeOptionNames = attributeOptions.map((attributeOption) => attributeOption.name);
    const parentsAttributeOptions = fullAttributeOptions === null || fullAttributeOptions === void 0 ? void 0 : fullAttributeOptions.filter((parentAttributeOption) => !attributeOptionNames.includes(parentAttributeOption.name));
    discountActiveUntil = discountActiveUntil
        ? moment(discountActiveUntil)
        : null;
    const onSubmit = async (input) => {
        var _a;
        const attributeOptions = input.attributeOptions || [];
        let imageId = null;
        if (input["image"]) {
            if ((_a = input["image"]) === null || _a === void 0 ? void 0 : _a.originFileObj) {
                input["image"] = input["image"].originFileObj;
            }
            else {
                imageId = input["image"]._id;
                delete input["image"];
            }
        }
        try {
            await updateProductCategory({
                variables: {
                    id: _id,
                    document: {
                        ...input,
                        attributeOptions,
                        imageId,
                        isRoot,
                    },
                },
            });
            Ant.notification.success({
                message: t(`management.product_categories.edit_${isRoot ? "" : "sub"}category_confirmation`),
            });
            router.go(Routes.PRODUCT_CATEGORIES_LIST);
        }
        catch (err) {
            Ant.notification.error({
                message: t("generics.error"),
                description: err.message || err.toString(),
            });
        }
    };
    return {
        form,
        formInstance,
        onSubmit,
        mutationLoading,
        attributeOptions,
        parentsAttributeOptions,
        discountActiveUntil,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { PostList as BasePostList } from "./PostList.base";
import { CustomTag } from "@bundles/UIAppBundle/components";
import classNames from "classnames";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import styles from "../components/List/PostsListTable.module.scss";
import { PostVisibility } from "@root/api.types";
import { TagColorsEnum } from "@bundles/UIAppBundle/components/CustomTag/types";
export const postStatusTagColors = {
    [PostVisibility.VISIBLE]: TagColorsEnum.GREEN,
    [PostVisibility.HIDDEN]: TagColorsEnum.RED,
};
let PostList = class PostList extends BasePostList {
    build() {
        super.build();
        this.remove("title");
        this.remove("subtitle");
        this.remove("slug");
        this.remove("body");
        this.remove("updatedAt");
        this.remove("heroImage");
        this.remove("visibility");
        this.remove("createdAt");
        this.remove("updatedAt");
        this.remove("heroImage");
        this.remove("author");
        const { t } = this.i18n;
        this.add({
            id: "title",
            title: t("management.marketing.blog.fields.blogArticle"),
            dataIndex: ["title"],
            sorter: true,
            render: (value) => {
                return _jsx("b", { children: value }, void 0);
            },
        });
        this.add({
            id: "createdAt",
            title: t("management.marketing.blog.fields.date"),
            dataIndex: ["createdAt"],
            sorter: true,
            render: (value) => {
                const date = new Date(value);
                const dateString = date.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                });
                return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
            },
        });
        this.add({
            id: "author",
            dataIndex: ["author"],
            title: t("management.marketing.blog.fields.createdBy"),
            sorter: true,
            render: (value, model) => {
                var _a, _b, _c;
                const fullName = [value.firstName, value.middleName, value.lastName]
                    .join(" ")
                    .replace(/\s+/g, " ")
                    .trim();
                return (_jsxs("div", Object.assign({ className: styles["name-column"] }, { children: [_jsx("img", { alt: fullName, src: generateProfileImageLink(fullName, (_c = (_b = (_a = model.author.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("div", Object.assign({ className: styles["name-and-desc"] }, { children: _jsx("h6", Object.assign({ className: styles["name"] }, { children: fullName }), void 0) }), void 0)] }), void 0));
            },
        });
        this.add({
            id: "status",
            title: t("management.marketing.blog.fields.status"),
            dataIndex: ["visibility"],
            sorter: true,
            render: (value) => {
                return (_jsx("div", Object.assign({ className: classNames(styles["status"]) }, { children: _jsx(CustomTag, { value: value, variant: "filled", color: postStatusTagColors[value] }, void 0) }), void 0));
            },
        });
        // Perform additional modifications such as updating how a list item renders or add additional ones
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    static getSortMap() {
        return {
            ...super.getSortMap(),
            author: "author",
        };
    }
};
PostList = __decorate([
    Service({ transient: true })
], PostList);
export { PostList };

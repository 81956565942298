var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { UnitsOfMeasurementListFiltersForm as BaseUnitsOfMeasurementListFiltersForm } from "./UnitsOfMeasurementListFiltersForm.base";
import * as Ant from "antd";
import { ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
let UnitsOfMeasurementListFiltersForm = class UnitsOfMeasurementListFiltersForm extends BaseUnitsOfMeasurementListFiltersForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("salesRayId");
        this.update("name", {
            order: 1,
            label: "",
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: t("management.units_of_measurement.fields.name"), label: t("management.units_of_measurement.fields.name") }, void 0) }), void 0) }), void 0)),
        });
        this.update("label", {
            order: 2,
            label: "",
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: t("management.units_of_measurement.fields.label"), label: t("management.units_of_measurement.fields.label") }, void 0) }), void 0) }), void 0)),
        });
        this.update("code", {
            order: 3,
            label: "",
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: t("management.units_of_measurement.fields.code"), label: t("management.units_of_measurement.fields.code") }, void 0) }), void 0) }), void 0)),
        });
        this.update("hasWeight", {
            order: 4,
            label: "",
            render: (props) => {
                return (_jsx(Ant.Form.Item, Object.assign({}, props, { name: props.name }, { children: _jsx(ControlledSelect, { defaultValue: [], label: "Has Weight?", placeholder: "Has Weight", onChange: () => undefined, options: [
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                        ] }, void 0) }), void 0));
            },
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
UnitsOfMeasurementListFiltersForm = __decorate([
    Service({ transient: true })
], UnitsOfMeasurementListFiltersForm);
export { UnitsOfMeasurementListFiltersForm };

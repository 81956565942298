import "./i18n";
import { PRODUCT_VARIANTS_LIST as BASE_PRODUCT_VARIANTS_LIST, PRODUCT_VARIANTS_CREATE as BASE_PRODUCT_VARIANTS_CREATE, PRODUCT_VARIANTS_EDIT as BASE_PRODUCT_VARIANTS_EDIT, PRODUCT_VARIANTS_VIEW as BASE_PRODUCT_VARIANTS_VIEW, } from "./config/routes";
export const PRODUCT_VARIANTS_LIST = {
    ...BASE_PRODUCT_VARIANTS_LIST,
};
export const PRODUCT_VARIANTS_CREATE = {
    ...BASE_PRODUCT_VARIANTS_CREATE,
    path: "/products/:id/variants/create",
};
export const PRODUCT_VARIANTS_EDIT = {
    ...BASE_PRODUCT_VARIANTS_EDIT,
    path: "/products/:productId/variants/:variantId/edit",
};
export const PRODUCT_VARIANTS_VIEW = {
    ...BASE_PRODUCT_VARIANTS_VIEW,
    path: "/products/:id/view",
};

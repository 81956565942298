var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { EndUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
import { CustomEndUserProfilesCollection } from "@bundles/UIAppBundle/collections/EndUserProfiles/EndUserProfiles.custom.collection";
import { Inject } from "@bluelibs/core";
import { StopOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { KebabMenu, View } from "@bundles/UIAppBundle/icons";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { features } from "../../config/features";
import { EndUserProfileList } from "../../config/EndUserProfileList";
export class EndUserProfilesAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomEndUserProfilesCollection;
    }
    getBody() {
        return EndUserProfileList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(EndUserProfileList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            title: "",
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return EndUserProfileList.getSortMap();
    }
    getActionItems() {
        var _a, _b, _c, _d;
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.END_USER_PROFILES_VIEW, {
                        params: { id: model.profile._id },
                    });
                },
            });
        }
        const isSuspended = (_d = (_c = (_b = (_a = this.state.filters) === null || _a === void 0 ? void 0 : _a["$and"]) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c["isSuspended"]) === null || _d === void 0 ? void 0 : _d["$in"][0];
        if (isSuspended !== undefined) {
            actions.push({
                label: isSuspended ? "Unuspend" : "Suspend",
                icon: _jsx(StopOutlined, {}, void 0),
                confirm: this.i18n.t("generics.ask_confirmation"),
                action: (model) => {
                    var _a, _b, _c, _d;
                    if (((_b = (_a = model.profile) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.isSuspended) === false) {
                        this.collection
                            .suspendUser((_c = model === null || model === void 0 ? void 0 : model.profile) === null || _c === void 0 ? void 0 : _c._id)
                            .then(() => {
                            this.load();
                            notification.success({ message: "User Suspended" });
                        })
                            .catch(() => notification.error({ message: "User Suspend Failed" }));
                    }
                    else {
                        this.collection
                            .unSuspendUser((_d = model === null || model === void 0 ? void 0 : model.profile) === null || _d === void 0 ? void 0 : _d._id)
                            .then(() => {
                            this.load();
                            notification.success({ message: "User Unsuspended" });
                        })
                            .catch(() => notification.error({ message: "User Unsuspend Failed" }));
                    }
                },
            });
        }
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["name"]) {
            modifiedFiltersObj["name"] = modifiedFiltersObj["name"].source;
        }
        if (modifiedFiltersObj["companyName"]) {
            modifiedFiltersObj["companyName"] =
                modifiedFiltersObj["companyName"].source;
        }
        if (modifiedFiltersObj["vatNumber"]) {
            modifiedFiltersObj["vatNumber"] = modifiedFiltersObj["vatNumber"].source;
        }
        if (modifiedFiltersObj["salesRayDefaultPricelistId"]) {
            modifiedFiltersObj["salesRayDefaultPricelistId"] =
                +modifiedFiltersObj["salesRayDefaultPricelistId"].source;
        }
        if (modifiedFiltersObj["isAnonymousCheckout"]) {
            const isAnonymousCheckoutArr = modifiedFiltersObj["isAnonymousCheckout"]["$in"];
            let hasAccountValue;
            if (isAnonymousCheckoutArr.length > 1)
                hasAccountValue = null;
            else if (isAnonymousCheckoutArr.find((element) => element === true))
                hasAccountValue = true;
            else
                hasAccountValue = false;
            modifiedFiltersObj["hasAccount"] = hasAccountValue;
            delete modifiedFiltersObj["isAnonymousCheckout"];
        }
        if (modifiedFiltersObj["orderTotal"]) {
            modifiedFiltersObj["ordersPriceRange"] = {
                gte: parseFloat(modifiedFiltersObj["orderTotal"]["$in"][0]),
                lte: parseFloat(modifiedFiltersObj["orderTotal"]["$in"][1]),
            };
            delete modifiedFiltersObj["orderTotal"];
        }
        if (modifiedFiltersObj["orders.placedAt"]) {
            modifiedFiltersObj["ordersDateRange"] = {
                gte: (modifiedFiltersObj["orders.placedAt"]["$gte"] || "").toString() ||
                    null,
                lte: (modifiedFiltersObj["orders.placedAt"]["$lte"] || "").toString() ||
                    null,
            };
            delete modifiedFiltersObj["orders.placedAt"];
        }
        if (modifiedFiltersObj["isSuspended"]) {
            modifiedFiltersObj["isSuspended"] =
                modifiedFiltersObj["isSuspended"]["$in"][0];
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
                // ...this.state.filters,
                // ...this.alwaysOnFilters,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        })
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: false,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}
__decorate([
    Inject(() => EndUserProfilesCollection),
    __metadata("design:type", typeof (_a = typeof CustomEndUserProfilesCollection !== "undefined" && CustomEndUserProfilesCollection) === "function" ? _a : Object)
], EndUserProfilesAntTableSmart.prototype, "collection", void 0);

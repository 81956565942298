const NO_IMAGE = "../../../../public/no-product-image-available.png";
export const generateImageLink = (url) => {
    if (!url) {
        return NO_IMAGE;
    }
    return url;
};
export const generateProfileImageLink = (fullName, url) => {
    if (!fullName) {
        return NO_IMAGE;
    }
    if (!url) {
        return `https://ui-avatars.com/api/?name=${fullName}&background=random`;
    }
    return generateImageLink(url);
};

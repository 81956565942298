/**
 * We customize the Guardian mostly because we have different models of Users, we fetch different data from server than the default and we
 * register them in different ways.
 *
 * Use the `useAppGuardian()` function instead of `useGuardian()`
 */
import { GuardianSmart, useSmart, } from "@bluelibs/x-ui";
import { gql } from "@apollo/client";
export class AppGuardian extends GuardianSmart {
    retrieveUser() {
        return this.apolloClient
            .query({
            query: gql `
          query AdminMe {
            AdminMe {
              _id
              email
              fullName
              profile {
                firstName
                lastName
              }
              roles
              avatarId
              avatar {
                file {
                  downloadUrl
                }
              }
            }
          }
        `,
            fetchPolicy: "network-only",
        })
            .then((response) => {
            return response.data.AdminMe;
        });
    }
    async login(username, password) {
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation AdminLogin($input: AdminLoginInput!) {
            AdminLogin(input: $input)
          }
        `,
            variables: {
                input: {
                    email: username,
                    password,
                },
            },
        })
            .then(async (response) => {
            const token = response.data.AdminLogin;
            await this.storeToken(token);
            await this.load();
            return token;
        });
    }
}
/**
 * Use this instead `useGuardian()`
 * @returns
 */
export function useAppGuardian() {
    return useSmart(AppGuardian);
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, useTranslate } from "@bluelibs/x-ui";
import { Alert, Layout } from "antd";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { CustomPageHeader, Table } from "@bundles/UIAppBundle/components";
import { CreateReturn } from "@bundles/UIAppBundle/components/CreateReturn";
import { ReturnsListFilters } from "./ReturnsListFilters";
import { useReturnsList } from "./hooks";
import styles from "./ReturnsList.module.scss";
import { ordersAccessRoles } from "@bundles/UIAppBundle/pages/OrdersManagement/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ReturnsList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const { api, Provider, filtersOpened, setFiltersOpened, createModalOpen, setCreateModalOpen, onFiltersUpdate, } = useReturnsList();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: [_jsx(Seo, { data: seoData.return.list }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(CustomPageHeader, { title: t("management.returns.list.header"), searchKey: "number", searchPlaceholder: t("management.returns.list.search"), createNewLabel: t("management.returns.list.create_btn"), handleCreateButtonClick: () => setCreateModalOpen(true), onFiltersUpdate: onFiltersUpdate, filtersOpened: filtersOpened, setFiltersOpened: setFiltersOpened }, void 0), _jsx(Provider, { children: _jsx(CreateReturn, { modalOpen: createModalOpen, setModalOpen: setCreateModalOpen, onSuccess: () => api.init() }, void 0) }, void 0), api.state.isError && (_jsx(Alert, { type: "error", message: t("generics.error_message") }, void 0)), _jsx(Layout.Content, { children: _jsxs(Provider, { children: [filtersOpened && _jsx(ReturnsListFilters, { onUpdate: onFiltersUpdate }, void 0), _jsx(Table, Object.assign({ className: styles["table"] }, api.getTableProps(), { 
                                    // NOTE: x: auto sets inline styles {table-layout: auto, min-width: auto}
                                    // -> this forces each col to have minimum possible width to accomodate content (not possible with css otherwise)
                                    scroll: { x: "auto" } }), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0));
}

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { EndUserProfileListFiltersForm } from "../../config/EndUserProfileListFiltersForm";
import { CustomTableFilters } from "@bundles/UIAppBundle/components/CustomTableFilters";
import { use } from "@bluelibs/x-ui";
export const EndUserProfilesListFilters = React.memo(EndUserProfilesListFiltersBase);
export function EndUserProfilesListFiltersBase(props) {
    const filterForm = use(EndUserProfileListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsx(CustomTableFilters, { onUpdate: props.onUpdate, formInstance: filterForm }, void 0));
}

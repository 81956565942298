var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UnitsOfMeasurementCollection, } from "@bundles/UIAppBundle/collections";
let ProductVariantPriceListListFiltersForm = class ProductVariantPriceListListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "priceListId",
                label: t("management.product_variant_price_lists.fields.priceListId"),
                name: ["priceListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "priceListCode",
                label: t("management.product_variant_price_lists.fields.priceListCode"),
                name: ["priceListCode"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "sku",
                label: t("management.product_variant_price_lists.fields.sku"),
                name: ["sku"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "price",
                label: t("management.product_variant_price_lists.fields.price"),
                name: ["price"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "currency",
                label: t("management.product_variant_price_lists.fields.currency"),
                name: ["currency"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "priceListInfo",
                label: t("management.product_variant_price_lists.fields.priceListInfo"),
                name: ["priceListInfo"],
                columns: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.id"),
                        name: ["priceListInfo", "id"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "code",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.code"),
                        name: ["priceListInfo", "code"],
                        component: Ant.Input,
                    },
                    {
                        id: "status",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.status"),
                        name: ["priceListInfo", "status"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "formula",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.formula"),
                        name: ["priceListInfo", "formula"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "name",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.name"),
                        name: ["priceListInfo", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "tp",
                        label: t("management.product_variant_price_lists.fields.priceListInfo.tp"),
                        name: ["priceListInfo", "tp"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.product_variant_price_lists.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.product_variant_price_lists.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.product_variant_price_lists.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.product_variant_price_lists.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "unitOfMeasurementId",
                label: t("management.product_variant_price_lists.fields.unitOfMeasurement"),
                name: ["unitOfMeasurementId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UnitsOfMeasurementCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ProductVariantPriceListListFiltersForm = __decorate([
    Service({ transient: true })
], ProductVariantPriceListListFiltersForm);
export { ProductVariantPriceListListFiltersForm };

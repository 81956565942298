import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { BigCardLayout, Button, Progress, } from "@bundles/UIAppBundle/components";
import styles from "./ImportProducts.module.scss";
export const Importing = ({ imported, total, onClickStopImporting, }) => {
    const t = useTranslate();
    return (_jsx(BigCardLayout, Object.assign({ title: t("management.import.title"), subtitle: t("management.import.headers.importing") }, { children: _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles["label"] }, { children: t("management.import.descriptions.importing.importingInProgress") }), void 0), _jsx("div", { children: t("management.import.descriptions.importing.benefit") }, void 0), _jsx(Progress, { className: styles["progress"], value: imported / total }, void 0), _jsx("div", Object.assign({ className: styles["progress-text"] }, { children: t("management.import.descriptions.importing.progress", {
                        imported,
                        total,
                    }) }), void 0), _jsx(Button, Object.assign({ width: "fit-content", btnStyle: "primary-outlined", className: styles["submit"], type: "button", onClick: onClickStopImporting }, { children: t("management.import.buttons.stopImporting") }), void 0)] }, void 0) }), void 0));
};

import { PostsCollection as Base } from "./Posts.collection";
import { ADMIN_POSTS_GET } from "../../pages/PostsManagement/config/queries";
export class CustomPostsCollection extends Base {
    async find(query) {
        var _a;
        return this.apolloClient
            .query({
            query: ADMIN_POSTS_GET,
            variables: {
                input: {
                    options: (_a = query.options) !== null && _a !== void 0 ? _a : {},
                },
            },
            fetchPolicy: this.getFetchPolicy(),
        })
            .then((result) => {
            return result.data.AdminPostsList;
        })
            .catch((err) => {
            console.error(err);
        });
    }
}

import { DIGITS_AFTER_DECIMAL_POINT } from "../consts/numbers";
import { CURRENCY } from "@bundles/UIAppBundle/enums/Currency";
export const removeWhiteSpacesAndSplitWordsByComma = (keyword) => {
    return keyword.replace(/\s/g, "").split(",");
};
export const addRequiredFieldSpecialMark = (fieldName) => {
    return fieldName + "*";
};
export const scrollToFirstFormFieldError = {
    behavior: (actions) => {
        const action = actions[0];
        action === null || action === void 0 ? void 0 : action.el.scrollTo({
            left: action.left,
            top: action.top,
        });
    },
    block: "start",
    scrollMode: "always",
};
export const transformArrayToStringSeperatedByComma = (keywords) => {
    return keywords.join(",");
};
export const toFixed = (value, digits = DIGITS_AFTER_DECIMAL_POINT) => {
    return (+(value || 0)).toLocaleString(undefined, {
        maximumFractionDigits: digits,
    });
};
export const formatCurrencyAndPrice = (currency, price) => {
    if (!price) {
        price = 0;
    }
    const fixedPrice = toFixed(price);
    if (!currency) {
        return fixedPrice;
    }
    if (currency === "usd") {
        return fixedPrice + CURRENCY[currency];
    }
    return CURRENCY[currency] + fixedPrice;
};

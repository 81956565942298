import { UserRole } from "@root/api.types";
export const marketingAccessRoles = [
    UserRole.SUPER_ADMIN,
    UserRole.MARKETING_ADMIN,
];
export var IntegrationOption;
(function (IntegrationOption) {
    IntegrationOption["GOOGLE_ANALYTICS"] = "google-analytics";
    IntegrationOption["FACEBOOK_PIXEL"] = "facebook-pixel";
})(IntegrationOption || (IntegrationOption = {}));

import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { use } from "@bluelibs/x-ui";
import { OrderListFiltersForm } from "../../config/OrderListFiltersForm";
import { CustomTableFilters } from "@bundles/UIAppBundle/components/CustomTableFilters";
export const OrdersListFilters = React.memo(OrdersListFiltersBase);
export function OrdersListFiltersBase(props) {
    const filterForm = use(OrderListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsx(CustomTableFilters, { onUpdate: props.onUpdate, formInstance: filterForm }, void 0));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { EndUserProfilesCollection, ProductVariantsCollection, ReviewsCollection, } from "@bundles/UIAppBundle/collections";
let ReviewEditForm = class ReviewEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "comment",
                label: t("management.reviews.fields.comment"),
                name: ["comment"],
                component: Ant.Input,
            },
            {
                id: "stars",
                label: t("management.reviews.fields.stars"),
                name: ["stars"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "status",
                label: t("management.reviews.fields.status"),
                name: ["status"],
                initialValue: "PENDING",
                render: (props) => {
                    const options = [
                        { value: "PENDING", label: "Pending" },
                        { value: "APPROVED", label: "Approved" },
                        { value: "UNAPPROVED", label: "Unapproved" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.reviews.fields.status") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.reviews.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.reviews.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "isAnonymous",
                label: t("management.reviews.fields.isAnonymous"),
                name: ["isAnonymous"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "editHistory",
                label: t("management.reviews.fields.editHistory"),
                name: ["editHistory"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "comment",
                        label: t("management.reviews.fields.editHistory.comment"),
                        name: ["editHistory", "comment"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "stars",
                        label: t("management.reviews.fields.editHistory.stars"),
                        name: ["editHistory", "stars"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "createdAt",
                        label: t("management.reviews.fields.editHistory.createdAt"),
                        name: ["editHistory", "createdAt"],
                        required: true,
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
                    },
                ],
                initialValue: [],
            },
            {
                id: "reviewerId",
                label: t("management.reviews.fields.reviewer"),
                name: ["reviewerId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
            {
                id: "productVariantId",
                label: t("management.reviews.fields.productVariant"),
                name: ["productVariantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductVariantsCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "likesIds",
                label: t("management.reviews.fields.likes"),
                name: ["likesIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "disLikesIds",
                label: t("management.reviews.fields.disLikes"),
                name: ["disLikesIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "reportsIds",
                label: t("management.reviews.fields.reports"),
                name: ["reportsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            comment: 1,
            stars: 1,
            status: 1,
            isAnonymous: 1,
            editHistory: {
                comment: 1,
                stars: 1,
                createdAt: 1,
            },
            reviewer: {
                _id: 1,
                fullName: 1,
            },
            reviewerId: 1,
            productVariant: {
                _id: 1,
                name: 1,
            },
            productVariantId: 1,
            likes: {
                _id: 1,
                fullName: 1,
            },
            likesIds: 1,
            disLikes: {
                _id: 1,
                fullName: 1,
            },
            disLikesIds: 1,
            reports: {
                _id: 1,
                fullName: 1,
            },
            reportsIds: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.reviews.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ReviewsCollection),
    __metadata("design:type", typeof (_a = typeof ReviewsCollection !== "undefined" && ReviewsCollection) === "function" ? _a : Object)
], ReviewEditForm.prototype, "collection", void 0);
ReviewEditForm = __decorate([
    Service({ transient: true })
], ReviewEditForm);
export { ReviewEditForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let NotificationListFiltersForm = class NotificationListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.notifications.fields.title"),
                name: ["title"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.notifications.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.notifications.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "ORDER_PLACED" }, { children: "Order Placed" }), "ORDER_PLACED"), _jsx(Ant.Select.Option, Object.assign({ value: "ORDER_STATUS_CHANGED" }, { children: "Order Status Changed" }), "ORDER_STATUS_CHANGED"), _jsx(Ant.Select.Option, Object.assign({ value: "RETURN_CREATED" }, { children: "Return Created" }), "RETURN_CREATED"), _jsx(Ant.Select.Option, Object.assign({ value: "RETURN_STATUS_CHANGED" }, { children: "Return Status Changed" }), "RETURN_STATUS_CHANGED"), _jsx(Ant.Select.Option, Object.assign({ value: "CLIENT_CREATED" }, { children: "Client Created" }), "CLIENT_CREATED"), _jsx(Ant.Select.Option, Object.assign({ value: "INVOICE_CREATED" }, { children: "Invoice Created" }), "INVOICE_CREATED"), _jsx(Ant.Select.Option, Object.assign({ value: "PRODUCT_OUT_OF_STOCK" }, { children: "Product Out Of Stock" }), "PRODUCT_OUT_OF_STOCK"), _jsx(Ant.Select.Option, Object.assign({ value: "PRODUCT_PROMOTION_EXPIRE" }, { children: "Product Promotion Expire" }), "PRODUCT_PROMOTION_EXPIRE")] }), void 0) }), void 0)),
            },
            {
                id: "redirectLink",
                label: t("management.notifications.fields.redirectLink"),
                name: ["redirectLink"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "metadata",
                label: t("management.notifications.fields.metadata"),
                name: ["metadata"],
                columns: true,
                nest: [
                    {
                        id: "productVariantId",
                        label: t("management.notifications.fields.metadata.productVariantId"),
                        name: ["metadata", "productVariantId"],
                        component: Ant.Input,
                    },
                    {
                        id: "clientId",
                        label: t("management.notifications.fields.metadata.clientId"),
                        name: ["metadata", "clientId"],
                        component: Ant.Input,
                    },
                    {
                        id: "orderId",
                        label: t("management.notifications.fields.metadata.orderId"),
                        name: ["metadata", "orderId"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.notifications.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.notifications.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.notifications.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.notifications.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
        ]);
    }
};
NotificationListFiltersForm = __decorate([
    Service({ transient: true })
], NotificationListFiltersForm);
export { NotificationListFiltersForm };

import { useTranslate } from "@bluelibs/x-ui";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
import { notification } from "antd";
import { useEffect, useState } from "react";
export const useLogoDetails = () => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState({});
    const [filesPreview, setFilesPreview] = useState({});
    const [filesToRemove, setFilesToRemove] = useState({
        logo: false,
        favicon: false,
    });
    const t = useTranslate();
    const tl = useTranslate("management.marketing");
    const shopSettingsApi = useShopSettings();
    const { state: { logo, favicon }, } = shopSettingsApi;
    useEffect(() => {
        setFilesPreview({ logo: logo === null || logo === void 0 ? void 0 : logo.downloadUrl, favicon: favicon === null || favicon === void 0 ? void 0 : favicon.downloadUrl });
    }, [logo, favicon]);
    const onChange = (key, file) => {
        if (file) {
            setFiles({ ...files, [key]: file.originFileObj });
            setFilesPreview({
                ...filesPreview,
                [key]: URL.createObjectURL(file.originFileObj),
            });
            setFilesToRemove({ ...filesToRemove, [key]: false });
        }
    };
    const onRemove = (key) => {
        setFiles({ ...files, [key]: null });
        setFilesPreview({ ...filesPreview, [key]: null });
        setFilesToRemove({ ...filesToRemove, [key]: true });
    };
    const customRequest = (options) => {
        options.onSuccess("ok", null);
    };
    const onSubmit = async () => {
        try {
            setLoading(true);
            if (files.logo)
                await shopSettingsApi.uploadLogo(files.logo);
            if (files.favicon)
                await shopSettingsApi.uploadFavicon(files.favicon);
            if (filesToRemove.logo)
                await shopSettingsApi.uploadLogo(null);
            if (filesToRemove.favicon)
                await shopSettingsApi.uploadFavicon(null);
            notification.success({
                message: t("generics.success"),
                description: tl("edit_confirmation.logo_details"),
            });
            shopSettingsApi.init();
        }
        catch (error) {
            notification.error({
                message: t("generics.error"),
                description: error.message,
            });
        }
        finally {
            setLoading(false);
        }
    };
    return {
        filesPreview,
        loading,
        onChange,
        onRemove,
        customRequest,
        onSubmit,
    };
};

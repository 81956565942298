import "./i18n";
import { MAILING_LISTS_LIST as BASE_MAILING_LISTS_LIST, MAILING_LISTS_CREATE as BASE_MAILING_LISTS_CREATE, MAILING_LISTS_EDIT as BASE_MAILING_LISTS_EDIT, MAILING_LISTS_VIEW as BASE_MAILING_LISTS_VIEW, } from "./config/routes";
export const MAILING_LISTS_LIST = {
    ...BASE_MAILING_LISTS_LIST,
};
export const MAILING_LISTS_CREATE = {
    ...BASE_MAILING_LISTS_CREATE,
};
export const MAILING_LISTS_EDIT = {
    ...BASE_MAILING_LISTS_EDIT,
};
export const MAILING_LISTS_VIEW = {
    ...BASE_MAILING_LISTS_VIEW,
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { use, useTranslate } from "@bluelibs/x-ui";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { ShopShippingAddressEditForm } from "../../config/ShopShippingAddressEditForm";
import { useShopShippingAddressEdit } from "./hooks";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
export function ShopShippingAddressEdit(props) {
    const t = useTranslate();
    const form = use(ShopShippingAddressEditForm, { transient: true });
    const { onSubmit, document, isLoading, error } = useShopShippingAddressEdit(props.id);
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shippingAddress.edit }, void 0), _jsxs(ShopConfigurationLayout, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsx(Card, Object.assign({ title: t("management.shipping_address.edit").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => onSubmit(document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["absolute-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0) }), void 0))] }, void 0)] }), void 0));
}

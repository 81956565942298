import { Collection, } from "@bluelibs/x-ui";
import { Coupon } from "@root/api.types";
import { EndUserProfilesCollection, ProductVariantsCollection, ProductCategoriesCollection, } from "@bundles/UIAppBundle/collections";
export class CouponsCollection extends Collection {
    getName() {
        return "Coupons";
    }
    getInputs() {
        return {
            insert: "CouponInsertInput!",
            update: "CouponUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "allowedUsers",
                many: true,
                field: "allowedUsersIds",
            },
            {
                collection: () => EndUserProfilesCollection,
                name: "usedBy",
                many: true,
                field: "usedByIds",
            },
            {
                collection: () => ProductVariantsCollection,
                name: "productVariants",
                many: true,
                field: "productVariantsIds",
            },
            {
                collection: () => ProductCategoriesCollection,
                name: "productCategory",
                many: true,
                field: "productCategoryIds",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            isActiveUntil: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

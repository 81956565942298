var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { useTranslate } from "@bluelibs/x-ui";
import { Form } from "antd";
import { RichTextEditor } from "@bundles/UIAppBundle/components";
import { ArticleEditForm as BaseArticleEditForm } from "./ArticleEditForm.base";
let ArticleEditForm = class ArticleEditForm extends BaseArticleEditForm {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("slug");
        this.remove("title");
        this.update("body", {
            required: true,
            render: (props) => (_jsx(Form.Item, Object.assign({}, props, { label: "", wrapperCol: { span: 24 } }, { children: _jsx(RichTextEditor, { placeholder: t("management.articles.placeholders.body") }, void 0) }), void 0)),
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ArticleEditForm = __decorate([
    Service({ transient: true })
], ArticleEditForm);
export { ArticleEditForm };

import { use, useTranslate } from "@bluelibs/x-ui";
import { useMutation, useQuery } from "@apollo/client";
import * as Ant from "antd";
import { ProductEditForm } from "../../config/ProductEditForm";
import { UpdateProductMutation } from "../Create/mutations";
import { updateFields } from "./helpers/extraFieldsUpdates";
import { getProduct } from "@bundles/UIAppBundle/pages/ProductVariantsManagement/components/queries";
import moment from "moment";
import { removeWhiteSpacesAndSplitWordsByComma, transformArrayToStringSeperatedByComma, } from "@bundles/UIAppBundle/utils/helpers";
export const useProductEdit = (id) => {
    const t = useTranslate();
    const form = use(ProductEditForm, { transient: true });
    const [formInstance] = Ant.Form.useForm();
    const [updateProduct, { loading: mutationLoading }] = useMutation(UpdateProductMutation);
    form.build();
    updateFields(formInstance, form);
    const { data: document, loading: queryLoading, error: queryError, } = useQuery(getProduct, {
        fetchPolicy: "network-only",
        variables: { input: { _id: id } },
    });
    const product = document === null || document === void 0 ? void 0 : document.AdminProductsGet;
    const onSubmit = async (input) => {
        const allImages = input.images || [];
        const newImagesIdsMap = allImages.reduce((prev, current) => ({ ...prev, [current._id]: true }), {});
        const deletedImagesIds = product.images.files
            .map((file) => file._id)
            .filter((id) => !newImagesIdsMap[id]);
        const images = allImages
            .map((image) => image.originFileObj)
            .filter(Boolean);
        const seoFields = packSeoFieldsAndRemoveThem(input);
        try {
            await updateProduct({
                variables: {
                    input: {
                        productId: id,
                        ...input,
                        images,
                        deletedImagesIds,
                        seoFields,
                    },
                },
            });
            Ant.notification.info({
                message: t("generics.success"),
                description: t("management.products.edit_confirmation"),
            });
        }
        catch (err) {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
            console.error(err.message || err.toString());
        }
    };
    const getInitialValues = () => {
        var _a, _b, _c, _d;
        return {
            ...product,
            ...unPackSeoFields(product),
            images: ((_a = product === null || product === void 0 ? void 0 : product.images) === null || _a === void 0 ? void 0 : _a.files) || [],
            vatRateId: (_b = product === null || product === void 0 ? void 0 : product.vatRate) === null || _b === void 0 ? void 0 : _b._id,
            defaultImageIndex: (_d = (_c = product === null || product === void 0 ? void 0 : product.images) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d.findIndex((image) => { var _a; return image._id === ((_a = product === null || product === void 0 ? void 0 : product.defaultImage) === null || _a === void 0 ? void 0 : _a._id); }),
            discountActiveUntil: (product === null || product === void 0 ? void 0 : product.discountActiveUntil)
                ? moment(product === null || product === void 0 ? void 0 : product.discountActiveUntil)
                : null,
        };
    };
    const onFormValuesChange = (value, values) => {
        if (value.pageTitle) {
            formInstance.setFieldValue("slug", encodeURIComponent(values.pageTitle.toLowerCase().replaceAll(" ", "-")));
        }
    };
    const packSeoFieldsAndRemoveThem = (input) => {
        const { pageTitle, metaDescription, keywords, slug } = input;
        const seoFields = {
            pageTitle,
            metaDescription,
            keywords: keywords ? removeWhiteSpacesAndSplitWordsByComma(keywords) : [],
            slug,
        };
        delete input.pageTitle;
        delete input.metaDescription;
        delete input.keywords;
        delete input.slug;
        return seoFields;
    };
    // TODO: this method can be deleted if there's a way to access keys inside the object in the form
    const unPackSeoFields = (product) => {
        if (!product)
            return {};
        const { seoFields } = product;
        const { pageTitle, metaDescription, keywords, slug } = seoFields || {};
        return {
            pageTitle,
            metaDescription,
            keywords: keywords
                ? transformArrayToStringSeperatedByComma(keywords)
                : keywords,
            slug,
        };
    };
    return {
        t,
        form,
        formInstance,
        onSubmit,
        queryLoading,
        queryError,
        mutationLoading,
        getInitialValues,
        document: getInitialValues(),
        onFormValuesChange,
    };
};

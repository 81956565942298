import { useState } from "react";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ValidatorService } from "@bluelibs/validator-bundle";
import { useGuardian } from "@bluelibs/x-ui-guardian-bundle";
import { useTranslate } from "@bluelibs/x-ui";
import { notification } from "antd";
import { ForgotPasswordFormSchema, DefaultValues } from "./consts";
export const useForgotPassword = () => {
    const [input, setInput] = useState(DefaultValues);
    const [error, setError] = useState();
    const validatorService = use(ValidatorService);
    const tl = useTranslate("authentication.forgotPassword");
    const guardian = useGuardian();
    const onSubmit = async (event) => {
        event.preventDefault();
        const { email } = input;
        try {
            await validatorService.validate(input, {
                model: ForgotPasswordFormSchema,
            });
            guardian
                .forgotPassword(email)
                .then(() => {
                setError(null);
                notification.success({
                    message: tl("success"),
                });
            })
                .catch((err) => {
                notification.error({
                    message: err.message || err.toString(),
                });
                setError(err.message || err.toString());
            });
        }
        catch (err) {
            setError(err);
            notification.error({
                message: err.message || err.toString(),
            });
        }
    };
    const fieldError = (inputKey) => {
        if (inputKey === (error === null || error === void 0 ? void 0 : error.path))
            return error === null || error === void 0 ? void 0 : error.errors[0];
        return null;
    };
    const onChangeValue = (key, value) => {
        setInput({
            ...input,
            [key]: value,
        });
    };
    return {
        fieldError,
        onChangeValue,
        onSubmit,
    };
};

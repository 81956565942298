import "./i18n";
import { AVATARS_LIST as BASE_AVATARS_LIST, AVATARS_CREATE as BASE_AVATARS_CREATE, AVATARS_EDIT as BASE_AVATARS_EDIT, AVATARS_VIEW as BASE_AVATARS_VIEW, } from "./config/routes";
export const AVATARS_LIST = {
    ...BASE_AVATARS_LIST,
};
export const AVATARS_CREATE = {
    ...BASE_AVATARS_CREATE,
};
export const AVATARS_EDIT = {
    ...BASE_AVATARS_EDIT,
};
export const AVATARS_VIEW = {
    ...BASE_AVATARS_VIEW,
};

import "./i18n";
import { SHIPPING_ADDRESS_LIST as BASE_SHIPPING_ADDRESS_LIST, SHIPPING_ADDRESS_CREATE as BASE_SHIPPING_ADDRESS_CREATE, SHIPPING_ADDRESS_EDIT as BASE_SHIPPING_ADDRESS_EDIT, SHIPPING_ADDRESS_VIEW as BASE_SHIPPING_ADDRESS_VIEW, } from "./config/routes";
export const SHIPPING_ADDRESS_LIST = {
    ...BASE_SHIPPING_ADDRESS_LIST,
};
export const SHIPPING_ADDRESS_CREATE = {
    ...BASE_SHIPPING_ADDRESS_CREATE,
};
export const SHIPPING_ADDRESS_EDIT = {
    ...BASE_SHIPPING_ADDRESS_EDIT,
};
export const SHIPPING_ADDRESS_VIEW = {
    ...BASE_SHIPPING_ADDRESS_VIEW,
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useGuardian } from "@bluelibs/x-ui-guardian-bundle";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { Routes } from "@bundles/UIAppBundle";
import React, { useEffect } from "react";
const ProtectedRoute = ({ children, roles }) => {
    var _a;
    const { state } = useGuardian();
    const router = useRouter();
    useEffect(() => {
        if ((state.initialised && state.hasInvalidToken) ||
            (state.initialised && !state.isLoggedIn)) {
            router.go(Routes.LOGIN, {});
        }
    }, [state]);
    if (!state.user) {
        return _jsx("div", { children: "Loading" }, void 0);
    }
    if (state.initialised && !state.hasInvalidToken && state.user) {
        const userRoles = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.roles) || [];
        if (roles.length > 0 && !roles.some((r) => userRoles.includes(r))) {
            return _jsx("div", { children: "You dont have permission to access this page" }, void 0);
        }
    }
    return _jsx("div", { children: children }, void 0);
};
export default ProtectedRoute;

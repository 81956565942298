import { jsx as _jsx } from "react/jsx-runtime";
import { useTexture, Decal } from "@react-three/drei";
const ImageDecal = ({ d }) => {
    const texture = useTexture(d.href);
    return (_jsx(Decal, { debug: d.debug, position: [d.x, d.y, d.z], rotation: [
            (d.rotationZ * Math.PI) / 180,
            (d.rotationY * Math.PI) / 180,
            (d.rotationX * Math.PI) / 180,
        ], scale: [d.scale, d.scale, d.scale], map: texture }, void 0));
};
export default ImageDecal;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ShopSettingEditForm as BaseShopSettingEditForm } from "../../../pages/ShopSettingsManagement/config/ShopSettingEditForm.base";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import styles from "./GeneralDetailsForm.module.scss";
import { addRequiredFieldSpecialMark } from "@bundles/UIAppBundle/utils/helpers";
let GeneralDetailsConstructor = class GeneralDetailsConstructor extends BaseShopSettingEditForm {
    build() {
        super.build();
        const t = useTranslate();
        this.remove("attributeOptions");
        this.remove("invoiceGenerationPrefix");
        this.remove("analyticsURLs");
        this.remove("freeShippingAfterAmountLimit");
        this.remove("firstInvoiceNumber");
        this.remove("defaultCurrency");
        this.remove("vatRatesIds");
        this.remove("paymentOptionsIds");
        this.remove("shippingAddress");
        this.remove("analyticsURLs");
        this.remove("reviewsEnabled");
        this.remove("faviconId");
        this.remove("logoId");
        this.update("shopName", {
            order: 1,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.shopName"), placeholder: t("management.shop_settings.placeholders.shopName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("companyName", {
            order: 2,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.companyName"), placeholder: t("management.shop_settings.placeholders.companyName") }, void 0) }), void 0) }), void 0)),
        });
        this.update("companyIdentificationNumber", {
            order: 4,
            required: false,
            render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.companyIdentificationNumber"), placeholder: t("management.shop_settings.placeholders.companyIdentificationNumber") }, void 0) }), void 0) }), void 0)),
        });
        this.update("billingAddress", {
            order: 6,
            required: true,
            nest: [
                {
                    id: "streetAddressLine1",
                    name: ["billingAddress", "streetAddressLine1"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.shop_settings.fields.billingAddress.streetAddressLine1")), placeholder: t("management.shop_settings.placeholders.billingAddress.streetAddressLine1") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "country",
                    name: ["billingAddress", "country"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.shop_settings.fields.billingAddress.country")), placeholder: t("management.shop_settings.placeholders.billingAddress.country") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "city",
                    name: ["billingAddress", "city"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.shop_settings.fields.billingAddress.city")), placeholder: t("management.shop_settings.placeholders.billingAddress.city") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "zipcode",
                    name: ["billingAddress", "zipcode"],
                    required: true,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: addRequiredFieldSpecialMark(t("management.shop_settings.fields.billingAddress.zipcode")), placeholder: t("management.shop_settings.placeholders.billingAddress.zipcode") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "phoneNumber",
                    name: ["billingAddress", "phoneNumber"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.billingAddress.phoneNumber"), placeholder: t("management.shop_settings.placeholders.billingAddress.phoneNumber"), type: "tel" }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "email",
                    name: ["billingAddress", "email"],
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.billingAddress.email"), placeholder: t("management.shop_settings.placeholders.billingAddress.email"), type: "email" }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        // Perthis additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
GeneralDetailsConstructor = __decorate([
    Service({ transient: true })
], GeneralDetailsConstructor);
export { GeneralDetailsConstructor };

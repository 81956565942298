import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ProductOrder } from "../../ProductOrder";
import { Address } from "../components/Address";
import { AssignTeamMember } from "../components/AssignTeamMember";
import { ShippingMethod } from "../components/ShippingMethod";
import { PaymentInformation } from "../components/PaymentInformation";
import { Notes } from "../components/Notes";
import { Returns } from "../components/Returns";
import { ClientInfo } from "../../ClientInfo";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { useReturnDetail } from "./hooks";
import styles from "./ReturnDetail.module.scss";
const ReturnDetail = ({ id }) => {
    var _a, _b, _c;
    const { returnDetail, loading } = useReturnDetail(id);
    if (loading) {
        return _jsx(_Fragment, { children: "Loading..." }, void 0);
    }
    if (!returnDetail)
        return _jsx(NotFound, {}, void 0);
    const { _id, items, status, shippingAddress, billingAddress, adminId, paymentOption, notes, shippingOption, endUser, price, priceVat, vatCharge, } = returnDetail;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(ProductOrder, { subTotal: price, shippingCharge: (shippingOption === null || shippingOption === void 0 ? void 0 : shippingOption.price) || 0, vatCharge: vatCharge, total: priceVat, items: items, status: status }, void 0), _jsxs("div", Object.assign({ className: styles["shipping-info-container"] }, { children: [endUser && (_jsx(ClientInfo, { clientData: endUser, avatarUrl: (_c = (_b = (_a = endUser.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl }, void 0)), shippingAddress && (_jsx(Address, { title: "Shipping", address: shippingAddress, showImage: false }, void 0)), _jsx(Returns, { id: id, status: status }, void 0), _jsx(AssignTeamMember, { orderId: _id, salesAgentId: adminId }, void 0), shippingOption && _jsx(ShippingMethod, { shippingOption: shippingOption }, void 0), billingAddress && (_jsx(Address, { title: "Billing", showImage: false, address: billingAddress }, void 0)), paymentOption && _jsx(PaymentInformation, { paymentInfo: paymentOption }, void 0), _jsx(Notes, { notes: notes }, void 0)] }), void 0)] }), void 0));
};
export default ReturnDetail;

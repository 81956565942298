import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { ProductsCollection } from "@bundles/UIAppBundle/collections";
import { ProductViewer } from "../../config/ProductViewer";
import { features } from "../../config/features";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { productsAccessRoles } from "../../consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function ProductsView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const collection = use(ProductsCollection);
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(ProductsCollection, new ObjectId(props.id), ProductViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(ProductsViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: productsAccessRoles }, { children: [_jsx(Seo, { data: seoData.product.view }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.products.view.header"), onBack: () => window.history.back(), extra: getHeaderActions(collection, router, props.id) }, void 0), _jsx(Ant.Card, { children: content }, void 0)] }, void 0)] }), void 0));
}
export function ProductsViewComponent(props) {
    const document = props.document;
    const viewer = use(ProductViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return (_jsx(Ant.Descriptions, { children: viewer.rest().map((item) => {
            return (_jsx(Ant.Descriptions.Item, Object.assign({ label: item.label }, { children: viewer.render(item) }), item.id));
        }) }, void 0));
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    if (features.edit) {
        actions.push(_jsx(Link, Object.assign({ to: router.path(Routes.PRODUCTS_EDIT, {
                params: { id },
            }) }, { children: _jsx(Ant.Button, { children: t("generics.edit") }, void 0) }), "edit"));
    }
    if (features.delete) {
        actions.push(_jsx(Ant.Popconfirm, Object.assign({ title: "Are you sure you want to delete this Product?", onConfirm: () => {
                collection.deleteOne(id).then(() => {
                    router.go(Routes.PRODUCTS_LIST);
                    Ant.notification.success({
                        message: "Success",
                        description: "You have deleted the Product",
                    });
                });
            } }, { children: _jsx(Ant.Button, Object.assign({ danger: true }, { children: t("generics.delete") }), void 0) }), "delete"));
    }
    return actions;
}

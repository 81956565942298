import { Collection, } from "@bluelibs/x-ui";
import { PaymentOption } from "@root/api.types";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
export class PaymentOptionsCollection extends Collection {
    getName() {
        return "PaymentOptions";
    }
    getInputs() {
        return {
            insert: "PaymentOptionInsertInput!",
            update: "PaymentOptionUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { Button, Card } from "@bundles/UIAppBundle/components";
import { VatRateCreateForm } from "../../config/VatRateCreateForm";
import styles from "./VatRatesCreate.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function VatRatesCreate() {
    const t = useTranslate();
    const form = use(VatRateCreateForm, { transient: true });
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.vatRate.create }, void 0), _jsx(ShopConfigurationLayout, { children: _jsx(Card, Object.assign({ title: t("management.shop_settings.submenus.vat_details").toUpperCase() }, { children: _jsxs(Ant.Form, Object.assign({ requiredMark: "optional", onFinish: (document) => form.submitForm(document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0));
}

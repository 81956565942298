var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let GetInTouchListFiltersForm = class GetInTouchListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.get_in_touchs.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "address",
                label: t("management.get_in_touchs.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.get_in_touchs.fields.address.salesRayId"),
                        name: ["address", "salesRayId"],
                        component: Ant.Input,
                    },
                    {
                        id: "firstName",
                        label: t("management.get_in_touchs.fields.address.firstName"),
                        name: ["address", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.get_in_touchs.fields.address.lastName"),
                        name: ["address", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.get_in_touchs.fields.address.phoneNumber"),
                        name: ["address", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.get_in_touchs.fields.address.streetAddressLine1"),
                        name: ["address", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.get_in_touchs.fields.address.streetAddressLine2"),
                        name: ["address", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.get_in_touchs.fields.address.email"),
                        name: ["address", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.get_in_touchs.fields.address.city"),
                        name: ["address", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.get_in_touchs.fields.address.town"),
                        name: ["address", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.get_in_touchs.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.get_in_touchs.fields.address.zipcode"),
                        name: ["address", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.get_in_touchs.fields.address.companyName"),
                        name: ["address", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.get_in_touchs.fields.address.vatNumber"),
                        name: ["address", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "phone",
                label: t("management.get_in_touchs.fields.phone"),
                name: ["phone"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.get_in_touchs.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.get_in_touchs.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "protectData",
                label: t("management.get_in_touchs.fields.protectData"),
                name: ["protectData"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.get_in_touchs.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.get_in_touchs.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.get_in_touchs.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.get_in_touchs.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
        ]);
    }
};
GetInTouchListFiltersForm = __decorate([
    Service({ transient: true })
], GetInTouchListFiltersForm);
export { GetInTouchListFiltersForm };

import { gql } from "@apollo/client";
export const AdminShippingOptionUpdate = gql `
  mutation AdminShippingOptionUpdate($input: AdminShippingOptionUpdateInput!) {
    AdminShippingOptionUpdate(input: $input) {
      _id
      description
      name
      price
      type
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import classNames from "classnames";
import styles from "./ControlledTextArea.module.scss";
// eslint-disable-next-line react/display-name
const ControlledTextArea = forwardRef(({ containerClassName, label = "", description, error, EndComponent, id, ...rest }, ref) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, containerClassName), id: id }, { children: [_jsx("label", { children: label }, void 0), description && _jsx("p", Object.assign({ className: styles.description }, { children: description }), void 0), _jsxs("div", Object.assign({ className: styles["input-container"] }, { children: [_jsx("textarea", Object.assign({ ref: ref }, rest), void 0), _jsx("span", Object.assign({ className: styles["end-component"] }, { children: EndComponent }), void 0)] }), void 0), error && (_jsx("div", Object.assign({ className: classNames(styles.error, {
                    [styles["error--visible"]]: error,
                }) }, { children: error }), void 0))] }), void 0));
});
export default ControlledTextArea;

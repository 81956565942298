import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteFilled, CheckCircleFilled, CloseCircleFilled, } from "@ant-design/icons";
import { ReviewsCollection } from "@bundles/UIAppBundle/collections";
import { KebabMenu } from "@bundles/UIAppBundle/icons";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import styles from "../List/ProductVariantsList.module.scss";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { CustomTag } from "@bundles/UIAppBundle/components";
import classNames from "classnames";
import ReactStars from "react-stars";
import { ReviewStatus } from "@root/api.types";
import { reviewStatusTagColors } from "@bundles/UIAppBundle/consts/colors";
export class ProductVariantReviewsTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = ReviewsCollection;
    }
    getBody() {
        return {
            _id: 1,
            createdAt: 1,
            updatedAt: 1,
            status: 1,
            stars: 1,
            comment: 1,
            reviewer: {
                _id: 1,
                fullName: 1,
                user: {
                    avatar: {
                        file: {
                            downloadUrl: 1,
                        },
                    },
                },
            },
        };
    }
    getColumns() {
        const { t } = this.i18n;
        return [
            {
                dataIndex: ["reviewer", "fullName"],
                title: t("management.reviews.fields.reviewer_name"),
                sorter: true,
                render: (value, model) => {
                    var _a, _b, _c;
                    return (_jsxs("div", Object.assign({ className: styles["name-column"] }, { children: [_jsx("img", { alt: model.reviewer.fullName, src: generateProfileImageLink(model.reviewer.fullName, (_c = (_b = (_a = model.reviewer.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("div", Object.assign({ className: styles["name-and-desc"] }, { children: _jsx("h6", Object.assign({ className: styles["name"] }, { children: value }), void 0) }), void 0)] }), void 0));
                },
            },
            {
                title: t("management.reviews.fields.review_date"),
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value) => {
                    const date = new Date(value);
                    const dateString = date.toLocaleString(undefined, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                    });
                    return _jsx("span", Object.assign({ title: date.toLocaleTimeString() }, { children: dateString }), void 0);
                },
            },
            {
                title: t("management.reviews.fields.rating"),
                dataIndex: ["stars"],
                sorter: true,
                render: (value) => {
                    return _jsx(ReactStars, { edit: false, value: value }, void 0);
                },
            },
            {
                title: t("management.reviews.fields.status"),
                dataIndex: ["status"],
                sorter: true,
                render: (value) => {
                    return (_jsx("div", Object.assign({ className: classNames(styles["status"]) }, { children: _jsx(CustomTag, { value: value, variant: "filled", color: reviewStatusTagColors[value] }, void 0) }), void 0));
                },
            },
            {
                title: t("management.reviews.fields.comment"),
                dataIndex: ["comment"],
                sorter: false,
                render: (value) => {
                    return _jsx("span", { children: value }, void 0);
                },
            },
            this.getActionsColumn(),
        ];
    }
    getActionsColumn() {
        return {
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    icon: _jsx(KebabMenu, {}, void 0),
                    label: "",
                    items: this.getActionItems(),
                });
            },
        };
    }
    getActionItems() {
        return [
            {
                label: this.i18n.t("generics.delete"),
                icon: _jsx(DeleteFilled, {}, void 0),
                confirm: this.i18n.t("generics.ask_confirmation"),
                action: (model) => {
                    this.collection.deleteOne(model._id).then(() => {
                        this.load();
                    });
                },
            },
            {
                label: this.i18n.t("management.reviews.actions.approve"),
                icon: _jsx(CheckCircleFilled, {}, void 0),
                action: (model) => {
                    this.collection
                        .updateOne(model._id, { status: ReviewStatus.APPROVED })
                        .then(() => {
                        this.load();
                    });
                },
            },
            {
                label: this.i18n.t("management.reviews.actions.unapprove"),
                icon: _jsx(CloseCircleFilled, {}, void 0),
                action: (model) => {
                    this.collection
                        .updateOne(model._id, { status: ReviewStatus.UNAPPROVED })
                        .then(() => {
                        this.load();
                    });
                },
            },
        ];
    }
    async load() {
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        try {
            const response = await this.collection.find({
                filters: this.config.initialFilters,
                options: this.state.options,
            }, this.getBody());
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response,
                totalCount: response.length,
            });
        }
        catch (e) {
            this.updateState({
                isLoading: false,
                isError: true,
            });
        }
    }
}

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import DOMPurify from "dompurify";
import { Card } from "@bundles/UIAppBundle/components/Card";
import styles from "./Notes.module.scss";
const Notes = ({ notes }) => {
    return (_jsx(Card, Object.assign({ title: "Notes", className: styles["custom-card"] }, { children: _jsx("div", Object.assign({ className: styles["container"] }, { children: notes && (_jsx("p", { dangerouslySetInnerHTML: {
                    __html: DOMPurify.sanitize(notes),
                } }, void 0)) }), void 0) }), void 0));
};
export default Notes;

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Decal } from "@react-three/drei";
import { CanvasTexture } from "three";
const TxtDecal = ({ d }) => {
    const [texture, setTexture] = useState();
    useEffect(() => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const fontSize = 96;
        ctx.font = `${fontSize}px sans`;
        ctx.canvas.width = ctx.measureText(d.text || " ").width;
        ctx.canvas.height = ctx.canvas.width;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = `${fontSize}px sans`;
        ctx.fillStyle = d.fontColor || "black";
        ctx.fillText(d.text || "", canvas.width / 2, canvas.height / 2);
        const texture = new CanvasTexture(canvas);
        setTexture(texture);
    }, [d.text, d.fontSize, d.fontColor]);
    if (!texture)
        return null;
    return (_jsx(Decal, { debug: d.debug, position: [d.x, d.y, d.z], rotation: [
            (d.rotationZ * Math.PI) / 180,
            (d.rotationY * Math.PI) / 180,
            (d.rotationX * Math.PI) / 180,
        ], scale: [d.scale, d.scale, d.scale], map: texture }, void 0));
};
export default TxtDecal;

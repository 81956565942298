import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import * as Ant from "antd";
import { ProductVariant } from "@bundles/UIAppBundle/icons";
import { Button } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { MainButtonsEnum } from "./types";
import styles from "./ProductsManagementLayout.module.scss";
const VariantsDropdown = ({ openKeys, onOpenChange, mainBtnClasses, variantBtnClasses, navigateTo, data, productId, }) => (_jsx(Ant.Menu, { openKeys: openKeys, mode: "inline", inlineIndent: 0, className: styles.menu, onOpenChange: () => onOpenChange(), items: [
        {
            label: (_jsx(Button, Object.assign({ className: mainBtnClasses(MainButtonsEnum.PRODUCT_VARIANTS), btnStyle: "white", icon: ProductVariant }, { children: "Product Variants" }), void 0)),
            key: "variants",
            children: [
                ...data.AdminProductsGet.variants.map(({ name, _id }, idx) => ({
                    label: (_jsx(Button, Object.assign({ btnStyle: "white", onClick: () => navigateTo(Routes.PRODUCT_VARIANTS_EDIT, {
                            productId,
                            variantId: _id,
                        }), className: variantBtnClasses(_id) }, { children: _jsx("span", { children: name }, void 0) }), void 0)),
                    key: idx,
                })),
                data.AdminProductsGet.hasVariants && {
                    label: (_jsx(Button, Object.assign({ btnStyle: "white", onClick: () => navigateTo(Routes.PRODUCT_VARIANTS_CREATE, {
                            id: productId,
                        }), className: variantBtnClasses("create") }, { children: _jsx("span", { children: "Add New Variant" }, void 0) }), void 0)),
                    key: "idx",
                },
            ],
        },
    ] }, void 0));
export default VariantsDropdown;

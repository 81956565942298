import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { use, useDataOne, useTranslate } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import * as Ant from "antd";
import { PaymentOptionsCollection, } from "@bundles/UIAppBundle/collections";
import { ShopConfigurationLayout } from "@bundles/UIAppBundle/components/ShopConfiguration/Layout";
import { PaymentOptionEditForm } from "../../config/PaymentOptionEditForm";
import { Button, Card } from "@bundles/UIAppBundle/components";
import styles from "@bundles/UIAppBundle/components/ShopConfiguration/TableStyles.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function PaymentOptionsEdit(props) {
    const t = useTranslate();
    const form = use(PaymentOptionEditForm, { transient: true });
    const { data: document, isLoading, error, } = useDataOne(PaymentOptionsCollection, new ObjectId(props.id), PaymentOptionEditForm.getRequestBody());
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.paymentOption.edit }, void 0), _jsx(ShopConfigurationLayout, { children: _jsxs(Card, Object.assign({ title: t("management.shop_settings.submenus.payment_details").toUpperCase() }, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsxs(Ant.Form, Object.assign({ requiredMark: "optional", initialValues: document, onFinish: (document) => form.submitForm(props.id, document) }, { children: [_jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["absolute-btn"] }, { children: t("generics.submit") }), void 0)] }), void 0))] }), void 0) }, void 0)] }), void 0));
}

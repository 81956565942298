import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { ProductsManagementLayout } from "../../../../components/ProductsManagementLayout";
import { productsAccessRoles } from "../../consts";
import { ProductsEditForm } from "./ProductsEditForm";
export function ProductsEdit(props) {
    return (_jsxs(ProtectedRoute, Object.assign({ roles: productsAccessRoles }, { children: [_jsx(Seo, { data: seoData.product.edit }, void 0), _jsx(ProductsManagementLayout, Object.assign({ productId: props.id }, { children: _jsx(ProductsEditForm, { id: props.id }, void 0) }), void 0)] }), void 0));
}

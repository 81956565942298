import { Collection, } from "@bluelibs/x-ui";
import { Payment } from "@root/api.types";
import { EndUserProfilesCollection, InvoicesCollection, } from "@bundles/UIAppBundle/collections";
export class PaymentsCollection extends Collection {
    getName() {
        return "Payments";
    }
    getInputs() {
        return {
            insert: "PaymentInsertInput!",
            update: "PaymentUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "buyer",
                field: "buyerId",
            },
            {
                collection: () => InvoicesCollection,
                name: "invoice",
                field: "invoiceId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            paidAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ReviewCreateForm } from "../../config/ReviewCreateForm";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function ReviewsCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(ReviewCreateForm, { transient: true });
    form.build();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.SUPER_ADMIN, UserRole.PRODUCTS_ADMIN] }, { children: [_jsx(Seo, { data: seoData.review.create }, void 0), _jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.reviews.create.header"), onBack: () => window.history.back() }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { requiredMark: "optional", onFinish: (document) => form.onSubmit(document) }, { children: [form.render(), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Ant from "antd";
import { Checkmark, Trashcan, UploadPhoto } from "@bundles/UIAppBundle/icons";
import { Card } from "../../Card";
import styles from "./LogoDetails.module.scss";
import { Button } from "../../Button";
import { useTranslate } from "@bluelibs/x-ui";
import { useLogoDetails } from "./hooks";
import { Input } from "./consts";
const UploadCard = ({ title, subTitle, description, sizeRecommedation, filePreview, onChange, onRemove, customRequest, }) => {
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles["card-title"] }, { children: title }), void 0), _jsxs(Card, Object.assign({ className: styles["card"] }, { children: [_jsx("div", Object.assign({ className: styles["card-name"] }, { children: subTitle }), void 0), _jsx("div", Object.assign({ className: styles["card-text"] }, { children: description }), void 0), _jsx("div", Object.assign({ className: styles["upload-container"] }, { children: _jsx(Ant.Upload, Object.assign({ onChange: onChange, listType: "picture-card", className: styles.dragger, showUploadList: false, customRequest: customRequest }, { children: filePreview ? (_jsxs("div", Object.assign({ className: styles["preview-image-container"] }, { children: [_jsx("img", { className: styles["preview-image"], src: filePreview }, void 0), _jsxs("div", Object.assign({ className: styles["remove-image-btn"], onClick: (e) => {
                                            e.stopPropagation();
                                            onRemove();
                                        } }, { children: [_jsx("div", Object.assign({ className: styles["icon"] }, { children: _jsx(Trashcan, {}, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles["btn-text"] }, { children: ["Remove ", title] }), void 0)] }), void 0)] }), void 0)) : (_jsxs(_Fragment, { children: [_jsx(UploadPhoto, { className: styles["upload-icon"] }, void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "Upload your Media Here" }), void 0), _jsx("div", Object.assign({ className: styles["upload-description"] }, { children: "(Click or Drag)" }), void 0)] }, void 0)) }), void 0) }), void 0), _jsx("div", Object.assign({ className: styles["card-text"] }, { children: sizeRecommedation }), void 0)] }), void 0)] }, void 0));
};
const LogoDetails = () => {
    const t = useTranslate();
    const tl = useTranslate("management.marketing");
    const { filesPreview, loading, onChange, onRemove, customRequest, onSubmit } = useLogoDetails();
    return (_jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsx(UploadCard, { title: tl("fields.logo"), subTitle: tl("subTitles.logo"), description: tl("descriptions.logo"), sizeRecommedation: tl("descriptions.logo.size"), filePreview: filesPreview.logo, onChange: ({ file }) => onChange(Input.LOGO, file), onRemove: () => onRemove(Input.LOGO), customRequest: customRequest }, void 0), _jsx(UploadCard, { title: tl("fields.favicon"), subTitle: tl("subTitles.favicon"), description: tl("descriptions.favicon"), sizeRecommedation: tl("descriptions.favicon.size"), filePreview: filesPreview[Input.FAVICON], onChange: ({ file }) => onChange(Input.FAVICON, file), onRemove: () => onRemove(Input.FAVICON), customRequest: customRequest }, void 0), _jsx(Button, Object.assign({ width: "auto", className: styles["submit-btn"], icon: Checkmark, onClick: onSubmit, loading: loading }, { children: t("generics.save_changes") }), void 0)] }), void 0));
};
export default LogoDetails;

export var AttributeOptionsType;
(function (AttributeOptionsType) {
    AttributeOptionsType["string"] = "string";
    AttributeOptionsType["number"] = "number";
})(AttributeOptionsType || (AttributeOptionsType = {}));
export var EndUserProfileLanguage;
(function (EndUserProfileLanguage) {
    EndUserProfileLanguage["en"] = "en";
    EndUserProfileLanguage["ro"] = "ro";
    EndUserProfileLanguage["de"] = "de";
})(EndUserProfileLanguage || (EndUserProfileLanguage = {}));
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["DRAFT"] = "DRAFT";
    InvoiceStatus["OPEN"] = "OPEN";
    InvoiceStatus["PAID"] = "PAID";
    InvoiceStatus["CANCELED"] = "CANCELED";
    InvoiceStatus["AWAITING_PAYMENT"] = "AWAITING_PAYMENT";
})(InvoiceStatus || (InvoiceStatus = {}));
export var NotificationSubscriberStatus;
(function (NotificationSubscriberStatus) {
    NotificationSubscriberStatus["SENT"] = "SENT";
    NotificationSubscriberStatus["ERROR"] = "ERROR";
})(NotificationSubscriberStatus || (NotificationSubscriberStatus = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["ORDER_PLACED"] = "ORDER_PLACED";
    NotificationType["ORDER_STATUS_CHANGED"] = "ORDER_STATUS_CHANGED";
    NotificationType["RETURN_CREATED"] = "RETURN_CREATED";
    NotificationType["RETURN_STATUS_CHANGED"] = "RETURN_STATUS_CHANGED";
    NotificationType["CLIENT_CREATED"] = "CLIENT_CREATED";
    NotificationType["INVOICE_CREATED"] = "INVOICE_CREATED";
    NotificationType["PRODUCT_OUT_OF_STOCK"] = "PRODUCT_OUT_OF_STOCK";
    NotificationType["PRODUCT_PROMOTION_EXPIRE"] = "PRODUCT_PROMOTION_EXPIRE";
})(NotificationType || (NotificationType = {}));
export var OrderItemAppliedDiscountDiscountSource;
(function (OrderItemAppliedDiscountDiscountSource) {
    OrderItemAppliedDiscountDiscountSource["PRODUCT"] = "PRODUCT";
    OrderItemAppliedDiscountDiscountSource["CATEGORY"] = "CATEGORY";
    OrderItemAppliedDiscountDiscountSource["ORDER_ITEM_COUNT"] = "ORDER_ITEM_COUNT";
})(OrderItemAppliedDiscountDiscountSource || (OrderItemAppliedDiscountDiscountSource = {}));
export var OrderItemDiscountDiscountSource;
(function (OrderItemDiscountDiscountSource) {
    OrderItemDiscountDiscountSource["PRODUCT"] = "PRODUCT";
    OrderItemDiscountDiscountSource["CATEGORY"] = "CATEGORY";
    OrderItemDiscountDiscountSource["ORDER_ITEM_COUNT"] = "ORDER_ITEM_COUNT";
})(OrderItemDiscountDiscountSource || (OrderItemDiscountDiscountSource = {}));
export var OrderItemStatus;
(function (OrderItemStatus) {
    OrderItemStatus["OPEN"] = "OPEN";
    OrderItemStatus["WAITING_EXTERNAL_STOCK"] = "WAITING_EXTERNAL_STOCK";
    OrderItemStatus["SHIPPED"] = "SHIPPED";
    OrderItemStatus["DELIVERED"] = "DELIVERED";
    OrderItemStatus["CANCELED"] = "CANCELED";
})(OrderItemStatus || (OrderItemStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["DRAFT"] = "DRAFT";
    OrderStatus["PLACED"] = "PLACED";
    OrderStatus["SHIPPING"] = "SHIPPING";
    OrderStatus["DELIVERED"] = "DELIVERED";
    OrderStatus["CANCELED"] = "CANCELED";
})(OrderStatus || (OrderStatus = {}));
export var PaymentOptionType;
(function (PaymentOptionType) {
    PaymentOptionType["CARD_POS"] = "CARD_POS";
    PaymentOptionType["CASH"] = "CASH";
    PaymentOptionType["BANK_TRANSFER"] = "BANK_TRANSFER";
    PaymentOptionType["STRIPE"] = "STRIPE";
    PaymentOptionType["PAYU"] = "PAYU";
    PaymentOptionType["LATER"] = "LATER";
})(PaymentOptionType || (PaymentOptionType = {}));
export var PostVisibility;
(function (PostVisibility) {
    PostVisibility["VISIBLE"] = "VISIBLE";
    PostVisibility["HIDDEN"] = "HIDDEN";
})(PostVisibility || (PostVisibility = {}));
export var ProductTypes;
(function (ProductTypes) {
    ProductTypes["DIGITAL"] = "DIGITAL";
    ProductTypes["PHYSICAL"] = "PHYSICAL";
    ProductTypes["SERVICE"] = "SERVICE";
})(ProductTypes || (ProductTypes = {}));
export var ProductVariantAvailability;
(function (ProductVariantAvailability) {
    ProductVariantAvailability["OUT_OF_STOCK"] = "OUT_OF_STOCK";
    ProductVariantAvailability["IN_STOCK"] = "IN_STOCK";
    ProductVariantAvailability["IN_STOCK_EXTERNAL"] = "IN_STOCK_EXTERNAL";
})(ProductVariantAvailability || (ProductVariantAvailability = {}));
export var ReturnStatus;
(function (ReturnStatus) {
    ReturnStatus["PENDING"] = "PENDING";
    ReturnStatus["COMPLETED"] = "COMPLETED";
    ReturnStatus["CANCELED"] = "CANCELED";
})(ReturnStatus || (ReturnStatus = {}));
export var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["PENDING"] = "PENDING";
    ReviewStatus["APPROVED"] = "APPROVED";
    ReviewStatus["UNAPPROVED"] = "UNAPPROVED";
})(ReviewStatus || (ReviewStatus = {}));
export var ShippingOptionType;
(function (ShippingOptionType) {
    ShippingOptionType["NATIONAL"] = "NATIONAL";
    ShippingOptionType["INTERNATIONAL"] = "INTERNATIONAL";
})(ShippingOptionType || (ShippingOptionType = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["added"] = "added";
    SubscriptionEventType["changed"] = "changed";
    SubscriptionEventType["removed"] = "removed";
    SubscriptionEventType["ready"] = "ready";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["SUPER_ADMIN"] = "SUPER_ADMIN";
    UserRole["TEAM_ADMIN"] = "TEAM_ADMIN";
    UserRole["PRODUCTS_ADMIN"] = "PRODUCTS_ADMIN";
    UserRole["ORDERS_ADMIN"] = "ORDERS_ADMIN";
    UserRole["CLIENTS_ADMIN"] = "CLIENTS_ADMIN";
    UserRole["INVOICE_ADMIN"] = "INVOICE_ADMIN";
    UserRole["MARKETING_ADMIN"] = "MARKETING_ADMIN";
    UserRole["INSIGHTS"] = "INSIGHTS";
    UserRole["SHOP_CONFIGURATOR"] = "SHOP_CONFIGURATOR";
    UserRole["ENDUSER"] = "ENDUSER";
})(UserRole || (UserRole = {}));

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "@bundles/UIAppBundle/components";
import { useImportProducts } from "./hooks";
import { Upload } from "./Upload";
import { Importing } from "./Importing";
import { Success } from "./Success";
import { Unsuccess } from "./Unsuccess";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
// TODO
const errors = [
    { row: 1, column: 3, message: "Error on Product VAT Rate" },
    { row: 2, column: 3, message: "Error on Product VAT Rate" },
    { row: 3, column: 3, message: "Error on Product VAT Rate" },
];
export const ImportProducts = () => {
    const { step, fileName, 
    // called,
    // data,
    // loading,
    // error,
    imported, total, created, updated, onChangeFile, onDropFile, onSubmit, onReset, onClickStopImporting, onClickStartANewImport, onClickViewProducts, onClickTryAgain, } = useImportProducts();
    return (_jsxs(Layout, { children: [_jsx(Seo, { data: seoData.importProduct }, void 0), step === 0 && (
            // TODO (!called && !loading) &&
            _jsx(Upload, Object.assign({}, {
                fileName,
                onChangeFile,
                onDropFile,
                onSubmit,
                onReset,
            }), void 0)), step === 1 && (
            // TODO loading &&
            _jsx(Importing, Object.assign({}, { imported, total, onClickStopImporting }), void 0)), step === 2 && (
            // TODO data &&
            _jsx(Success, Object.assign({}, {
                total,
                created,
                updated,
                onClickStartANewImport,
                onClickViewProducts,
            }), void 0)), step === 3 && (
            // TODO error &&
            _jsx(Unsuccess, Object.assign({}, {
                errors,
                onClickStartANewImport,
                onClickTryAgain,
            }), void 0))] }, void 0));
};

import { useQuery } from "@apollo/client";
import { getSimulatedOrder } from "./queries";
export const useOrderTotalCard = (stepForm) => {
    const formData = stepForm.getFieldsValue(true);
    const items = formData.items;
    const { data: simulatedOrderData, error, loading, } = useQuery(getSimulatedOrder, {
        variables: {
            input: {
                items: items.map((item) => ({
                    productVariantId: item._id,
                    count: item.quantity,
                })),
            },
        },
    });
    const shippingCharge = formData.shippingOption.price;
    const simulatedOrder = simulatedOrderData === null || simulatedOrderData === void 0 ? void 0 : simulatedOrderData.SimulatedOrderGet;
    const subtotal = simulatedOrder === null || simulatedOrder === void 0 ? void 0 : simulatedOrder.price;
    const taxCharge = (simulatedOrder === null || simulatedOrder === void 0 ? void 0 : simulatedOrder.priceTotal) - (simulatedOrder === null || simulatedOrder === void 0 ? void 0 : simulatedOrder.price);
    return {
        data: simulatedOrder === null || simulatedOrder === void 0 ? void 0 : simulatedOrder.items,
        price: {
            subtotal: +subtotal,
            shippingCharge: +shippingCharge,
            taxCharge: +taxCharge,
        },
        loading,
        error,
    };
};

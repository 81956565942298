import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { use } from "@bluelibs/x-ui";
import { CustomTableFilters } from "@bundles/UIAppBundle/components/CustomTableFilters";
import { InvoiceListFiltersForm } from "../../config/InvoiceListFiltersForm";
export const InvoicesListFilters = React.memo(InvoicesListFiltersBase);
export function InvoicesListFiltersBase(props) {
    const filterForm = use(InvoiceListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsx(CustomTableFilters, { onUpdate: props.onUpdate, formInstance: filterForm }, void 0));
}

import { gql } from "@apollo/client";
export const UPDATE_ACTIVE_NOTIFICATIONS = gql `
  mutation AdminUpdateActiveNotifications($input: NotificationType!) {
    AdminUpdateActiveNotifications(input: $input) {
      activeNotifications {
        clientCreated
        invoiceCreated
        orderPlaced
        orderStatusChanged
        returnCreated
        productOutOfStock
        productPromotionExpire
        returnStatusChanged
      }
    }
  }
`;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AttributeOptionsType, ProductTypes } from "@root/api.types";
export const productTypeOptions = [
    { label: "Digital", value: ProductTypes.DIGITAL },
    { label: "Physical", value: ProductTypes.PHYSICAL },
    { label: "Service", value: ProductTypes.SERVICE },
];
export const yesOrNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];
export const attributeTypeOptions = [
    { label: "Text", value: AttributeOptionsType.string },
    { label: "Number", value: AttributeOptionsType.number },
];
export const availabilityOptions = [
    { value: "OUT_OF_STOCK", label: "Out Of Stock" },
    { value: "IN_STOCK", label: "In Stock" },
    { value: "IN_STOCK_EXTERNAL", label: "In Stock External" },
];
export const suffixOptions = [
    { label: "No Sufix Required", value: null },
    { label: "m (meters)", value: "meters" },
    {
        label: (_jsxs(_Fragment, { children: ["m", _jsx("sup", { children: "2" }, void 0), "\u00A0(square meters)"] }, void 0)),
        value: "square meters",
    },
    {
        label: (_jsxs(_Fragment, { children: ["m", _jsx("sup", { children: "3" }, void 0), "\u00A0(cube meters)"] }, void 0)),
        value: "cubic meters",
    },
    { label: "l (liters)", value: "liters" },
];
export const categoriesQueryBody = {
    _id: 1,
    name: 1,
    vatRateId: 1,
    vatRate: {
        name: 1,
    },
    attributeOptions: {
        name: 1,
        type: 1,
        isRequired: 1,
        isEnum: 1,
        enumValues: 1,
        defaultValue: 1,
        defaultSuffix: 1,
        isRange: 1,
        rangeMin: 1,
        rangeMax: 1,
    },
};

import { Collection, } from "@bluelibs/x-ui";
import { ProductFavorite } from "@root/api.types";
import { EndUserProfilesCollection, ProductVariantsCollection, } from "@bundles/UIAppBundle/collections";
export class ProductFavoritesCollection extends Collection {
    getName() {
        return "ProductFavorites";
    }
    getInputs() {
        return {
            insert: "ProductFavoriteInsertInput!",
            update: "ProductFavoriteUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUserProfilesCollection,
                name: "endUserProfile",
                field: "endUserProfileId",
            },
            {
                collection: () => ProductVariantsCollection,
                name: "productVariant",
                field: "productVariantId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ShopSettingCreateForm } from "../../config/ShopSettingCreateForm";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import styles from "./ShopSettingsCreate.module.scss";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { shopConfigurationAccessRoles } from "@bundles/UIAppBundle/components/ShopConfiguration/consts";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function ShopSettingsCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(ShopSettingCreateForm, { transient: true });
    form.build();
    form.remove("attributeOptions");
    form.remove("invoiceGenerationPrefix");
    form.remove("freeShippingAfterAmountLimit");
    form.remove("firstInvoiceNumber");
    form.remove("defaultCurrency");
    form.remove("vatRatesIds");
    form.remove("paymentOptionsIds");
    form.update("shopName", {
        order: 1,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.shopName"), placeholder: t("management.shop_settings.placeholders.shopName") }, void 0) }), void 0) }), void 0)),
    });
    form.update("companyName", {
        order: 2,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.companyName"), placeholder: t("management.shop_settings.placeholders.companyName") }, void 0) }), void 0) }), void 0)),
    });
    form.update("country", {
        order: 3,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 13 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.country"), placeholder: t("management.shop_settings.placeholders.country") }, void 0) }), void 0) }), void 0)),
    });
    form.update("companyIdentificationNumber", {
        order: 4,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 11 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.companyIdentificationNumber"), placeholder: t("management.shop_settings.placeholders.companyIdentificationNumber") }, void 0) }), void 0) }), void 0)),
    });
    form.update("shopAdministratorEmail", {
        order: 5,
        render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.shopAdministratorEmail"), placeholder: t("management.shop_settings.placeholders.shopAdministratorEmail"), type: "email" }, void 0) }), void 0) }), void 0)),
    });
    form.update("billingAddress", {
        order: 6,
        nest: [
            {
                id: "streetAddressLine1",
                name: ["billingAddress", "streetAddressLine1"],
                required: true,
                render: (props) => (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], label: t("management.shop_settings.fields.billingAddress"), placeholder: t("management.shop_settings.placeholders.streetAddress") }, void 0) }), void 0) }), void 0)),
            },
            {
                id: "city",
                name: ["billingAddress", "city"],
                required: true,
                render: (props) => (_jsx(Ant.Col, Object.assign({ span: 13 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], placeholder: t("management.shop_settings.placeholders.city") }, void 0) }), void 0) }), void 0)),
            },
            {
                id: "zipcode",
                name: ["billingAddress", "zipcode"],
                required: true,
                render: (props) => (_jsx(Ant.Col, Object.assign({ span: 11 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { containerClassName: styles["input-container"], placeholder: t("management.shop_settings.placeholders.postalCode") }, void 0) }), void 0) }), void 0)),
            },
        ],
    });
    form.update("shippingAddress", {
        order: 7,
    });
    return (_jsxs(ProtectedRoute, Object.assign({ roles: shopConfigurationAccessRoles }, { children: [_jsx(Seo, { data: seoData.shopSettings.create }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles["page-wrapper"] }, { children: [_jsx("h1", Object.assign({ className: styles.header }, { children: "Shop Configuration" }), void 0), _jsx(Card, Object.assign({ className: styles.container }, { children: _jsxs(Ant.Form, Object.assign({ labelCol: { span: 0 }, requiredMark: "optional", onFinish: (document) => form.onSubmit(document) }, { children: [_jsx("h2", { children: "General Details" }, void 0), _jsx(Ant.Row, Object.assign({ gutter: 24 }, { children: form.render() }), void 0), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }, void 0)] }), void 0));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MarketingLayout } from "@bundles/UIAppBundle/components";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { PostsListTable } from "@bundles/UIAppBundle/pages/PostsManagement/components/List/PostsListTable";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
export function PostsList() {
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.MARKETING_ADMIN, UserRole.SUPER_ADMIN] }, { children: [_jsx(Seo, { data: seoData.posts.list }, void 0), _jsx(MarketingLayout, { children: _jsx(PostsListTable, {}, void 0) }, void 0)] }), void 0));
}

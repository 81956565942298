import "./i18n";
import { EndUserProfileOrdersList } from "./components/View/EndUserProfileOrdersList";
import { END_USER_PROFILES_LIST as BASE_END_USER_PROFILES_LIST, END_USER_PROFILES_CREATE as BASE_END_USER_PROFILES_CREATE, END_USER_PROFILES_EDIT as BASE_END_USER_PROFILES_EDIT, END_USER_PROFILES_VIEW as BASE_END_USER_PROFILES_VIEW, } from "./config/routes";
export const END_USER_PROFILES_LIST = {
    ...BASE_END_USER_PROFILES_LIST,
    path: "/clients",
};
export const END_USER_PROFILES_CREATE = {
    ...BASE_END_USER_PROFILES_CREATE,
    path: "/clients/create",
};
export const END_USER_PROFILES_EDIT = {
    ...BASE_END_USER_PROFILES_EDIT,
    path: "/clients/:id/edit",
};
export const END_USER_PROFILES_VIEW = {
    ...BASE_END_USER_PROFILES_VIEW,
    path: "/clients/:id/view",
};
export const END_USER_PROFILES_ORDER_LIST = {
    path: "/clients/:id/order-list",
    component: EndUserProfileOrdersList,
};

import { Product3DModelList } from "../components/List/Product3DModelList";
import { Product3DModelCreate } from "../components/Create/Product3DModelCreate";
import { Product3DModelEdit } from "../components/Edit/Product3DModelEdit";
import { Product3DModelView } from "../components/View/Product3DModelView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_3_D_MODEL_LIST = {
    path: "/admin/product-3-d-model",
    component: Product3DModelList,
    menu: {
        key: "PRODUCT_3_D_MODEL_LIST",
        label: "management.product_3_d_model.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_3_D_MODEL_CREATE = {
    path: "/admin/product-3-d-model/create",
    component: Product3DModelCreate,
};
export const PRODUCT_3_D_MODEL_EDIT = {
    path: "/admin/product-3-d-model/:id/edit",
    component: Product3DModelEdit,
};
export const PRODUCT_3_D_MODEL_VIEW = {
    path: "/admin/product-3-d-model/:id/view",
    component: Product3DModelView,
};

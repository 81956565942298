import { gql } from "@apollo/client";
export const AdminShippingAddressCreate = gql `
  mutation AdminShippingAddressCreate($input: ShopShippingAddressInsertInput) {
    AdminShippingAddressCreate(input: $input) {
      _id
    }
  }
`;
export const AdminShippingAddressEdit = gql `
  mutation AdminShippingAddressUpdate(
    $id: ObjectId
    $input: ShopShippingAddressUpdateInput
  ) {
    AdminShippingAddressUpdate(_id: $id, input: $input) {
      _id
      zipcode
      city
      streetAddressLine1
      streetAddressLine2
    }
  }
`;

import { useState, useCallback, useEffect } from "react";
export const useProductInfoVariants = (props) => {
    const { product, value, name, productVariant } = props;
    const [productFoundId, setProductFoundId] = useState();
    const checkIfVariantExist = useCallback(() => {
        const currentAttributeValues = [...productVariant.attributesValues];
        const attributeExistIndex = currentAttributeValues.findIndex((a) => (a === null || a === void 0 ? void 0 : a.key) === name);
        if (attributeExistIndex > -1) {
            currentAttributeValues.splice(attributeExistIndex, 1);
        }
        currentAttributeValues.push({
            key: name,
            value,
        });
        product.variants.forEach((variant) => {
            let isVariantValid = true;
            variant === null || variant === void 0 ? void 0 : variant.attributesValues.forEach((attribute) => {
                if (currentAttributeValues.findIndex((a) => (a === null || a === void 0 ? void 0 : a.key) === (attribute === null || attribute === void 0 ? void 0 : attribute.key) && (a === null || a === void 0 ? void 0 : a.value) === (attribute === null || attribute === void 0 ? void 0 : attribute.value)) === -1) {
                    isVariantValid = false;
                }
            });
            if (isVariantValid &&
                currentAttributeValues.length === (variant === null || variant === void 0 ? void 0 : variant.attributesValues.length)) {
                setProductFoundId(variant._id);
            }
        });
    }, []);
    useEffect(() => {
        checkIfVariantExist();
    }, [checkIfVariantExist]);
    const isFromCurrentProductVariant = (value) => {
        return (productVariant.attributesValues.findIndex((i) => (i === null || i === void 0 ? void 0 : i.value) === value) > -1);
    };
    return {
        isFromCurrentProductVariant,
        productFoundId,
    };
};

import { i18n } from "@bundles/UIAppBundle/i18n";
import { Profile } from "./Profile";
import ProfileDetailsMessages from "./ProfileDetails.i18n.json";
i18n.push(ProfileDetailsMessages);
export const PROFILE = {
    path: "/profile",
    component: Profile,
    menu: {
        key: "Profile",
        label: "Profile",
        order: 0,
        // icon: DashboardOutlined,
    },
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import moment from "moment";
import { Card } from "@bundles/UIAppBundle/components";
import { DatePicker } from "@bundles/UIAppBundle/components";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import styles from "./revenuereportcard.module.scss";
import RevenueReportCardLoader from "@bundles/UIAppBundle/pages/Dashboard/RevenueReportCard/RevenueReportCardLoader";
const monthTextToNumber = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
};
export const RevenueReportCard = ({ className, displayMode = "day", revenueValues, dateChangeHandler, loading, }) => {
    var _a, _b, _c, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const dailyLabels = (_c = (_b = (_a = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _a === void 0 ? void 0 : _a.dailyRevenues) === null || _b === void 0 ? void 0 : _b.timestamp) === null || _c === void 0 ? void 0 : _c.map((timeStamp) => timeStamp.toString().slice(5, 7) +
        "/" +
        monthTextToNumber[timeStamp.toString().slice(8, 11)]).slice(-30);
    const monthlyLabels = (_g = (_f = (_e = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _e === void 0 ? void 0 : _e.monthlyRevenues) === null || _f === void 0 ? void 0 : _f.timestamp) === null || _g === void 0 ? void 0 : _g.map((timeStamp, index, arr) => {
        const currentDDMM = timeStamp.toString().slice(5, 7) +
            "/" +
            monthTextToNumber[timeStamp.toString().slice(8, 11)];
        if (index === 0) {
            return currentDDMM;
        }
        else {
            return (arr[index - 1].toString().slice(5, 7) +
                "/" +
                monthTextToNumber[arr[index - 1].toString().slice(8, 11)] +
                "-" +
                currentDDMM);
        }
    }).slice(-30);
    const yearlyLabels = (_k = (_j = (_h = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _h === void 0 ? void 0 : _h.yearlyRevenues) === null || _j === void 0 ? void 0 : _j.timestamp) === null || _k === void 0 ? void 0 : _k.map((timeStamp) => timeStamp.toString().slice(5, 11)).slice(-30);
    const dailyData = (_o = (_m = (_l = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _l === void 0 ? void 0 : _l.dailyRevenues) === null || _m === void 0 ? void 0 : _m.totalOrders) === null || _o === void 0 ? void 0 : _o.map((order) => [0, order]).slice(-30);
    const monthlyData = (_r = (_q = (_p = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _p === void 0 ? void 0 : _p.monthlyRevenues) === null || _q === void 0 ? void 0 : _q.totalOrders) === null || _r === void 0 ? void 0 : _r.map((order) => [0, order]).slice(-30);
    const yearlyData = (_u = (_t = (_s = revenueValues === null || revenueValues === void 0 ? void 0 : revenueValues.AdminDashboardReportsGet) === null || _s === void 0 ? void 0 : _s.yearlyRevenues) === null || _t === void 0 ? void 0 : _t.totalOrders) === null || _u === void 0 ? void 0 : _u.map((order) => [0, order]).slice(-30);
    const [viewMode, setViewMode] = useState(displayMode);
    const [userData, setUserData] = useState({
        labels: dailyLabels,
        datasets: [
            {
                label: "",
                data: dailyData,
                backgroundColor: ["#6f42c1"],
                barThickness: 12.5,
                borderRadius: 20,
                borderSkipped: false,
            },
        ],
    });
    ChartJS.register(...registerables);
    return (_jsx(Card, Object.assign({ className: `${styles.card} ${className}` }, { children: loading ? (_jsx(RevenueReportCardLoader, {}, void 0)) : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.indexrow }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Revenue Report" }), void 0), _jsxs("div", Object.assign({ className: styles.btns }, { children: [_jsx("button", Object.assign({ type: "button", className: viewMode === "day" ? styles["btn-active"] : "", onClick: () => {
                                        setViewMode("day");
                                        const newUserData = {
                                            labels: dailyLabels,
                                            datasets: [
                                                {
                                                    label: "",
                                                    data: dailyData,
                                                    backgroundColor: ["#6f42c1"],
                                                    barThickness: 12.5,
                                                    borderRadius: 20,
                                                    borderSkipped: false,
                                                },
                                            ],
                                        };
                                        setUserData(newUserData);
                                    } }, { children: "Day" }), void 0), _jsx("button", Object.assign({ type: "button", className: viewMode === "month" ? styles["btn-active"] : "", onClick: () => {
                                        setViewMode("month");
                                        const newUserData = {
                                            labels: monthlyLabels,
                                            datasets: [
                                                {
                                                    label: "",
                                                    data: monthlyData,
                                                    backgroundColor: ["#6f42c1"],
                                                    barThickness: 12.5,
                                                    borderRadius: 20,
                                                    borderSkipped: false,
                                                },
                                            ],
                                        };
                                        setUserData(newUserData);
                                    } }, { children: "Month" }), void 0), _jsx("button", Object.assign({ type: "button", className: viewMode === "year" ? styles["btn-active"] : "", onClick: () => {
                                        setViewMode("year");
                                        const newUserData = {
                                            labels: yearlyLabels,
                                            datasets: [
                                                {
                                                    label: "",
                                                    data: yearlyData,
                                                    backgroundColor: ["#6f42c1"],
                                                    barThickness: 12.5,
                                                    borderRadius: 20,
                                                    borderSkipped: false,
                                                },
                                            ],
                                        };
                                        setUserData(newUserData);
                                    } }, { children: "Year" }), void 0), _jsx("div", Object.assign({ className: styles.daypicker }, { children: _jsx(DatePicker, { disabledDate: (currentDate) => currentDate.isSameOrAfter(moment()), range: true, transient: true, allowClear: false, onChange: (date) => {
                                            const startDate = new Date(date[0]._d);
                                            const endDate = new Date(date[1]._d);
                                            const newStartDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10
                                                ? "0" + (startDate.getMonth() + 1)
                                                : startDate.getMonth() + 1}-${startDate.getDate() < 10
                                                ? "0" + startDate.getDate()
                                                : startDate.getDate()}`;
                                            const newEndDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10
                                                ? "0" + (endDate.getMonth() + 1)
                                                : endDate.getMonth() + 1}-${endDate.getDate() < 10
                                                ? "0" + endDate.getDate()
                                                : endDate.getDate()}`;
                                            dateChangeHandler(newStartDateString, newEndDateString);
                                        } }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx(Bar, { data: userData, options: {
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                enabled: true,
                                backgroundColor: "#FFFFFF",
                                titleColor: "#1E1E1E",
                                bodyColor: "#656565",
                                displayColors: false,
                            },
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    autoSkip: false,
                                },
                            },
                            y: {
                                grid: {
                                    display: true,
                                    borderDash: [8, 4],
                                    drawBorder: false,
                                },
                                ticks: {
                                    autoSkip: false,
                                    stepSize: 1,
                                },
                            },
                        },
                    } }, void 0)] }, void 0)) }), void 0));
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let Product3DImageList = class Product3DImageList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "size",
                title: t("management.product_3_d_image.fields.size"),
                key: "management.product_3_d_image.fields.size",
                dataIndex: ["size"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "position.x",
                title: t("management.product_3_d_image.fields.position.x"),
                key: "management.product_3_d_image.fields.position.x",
                dataIndex: ["position", "x"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "position.y",
                title: t("management.product_3_d_image.fields.position.y"),
                key: "management.product_3_d_image.fields.position.y",
                dataIndex: ["position", "y"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "position.z",
                title: t("management.product_3_d_image.fields.position.z"),
                key: "management.product_3_d_image.fields.position.z",
                dataIndex: ["position", "z"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "rotation.x",
                title: t("management.product_3_d_image.fields.rotation.x"),
                key: "management.product_3_d_image.fields.rotation.x",
                dataIndex: ["rotation", "x"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "rotation.y",
                title: t("management.product_3_d_image.fields.rotation.y"),
                key: "management.product_3_d_image.fields.rotation.y",
                dataIndex: ["rotation", "y"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "rotation.z",
                title: t("management.product_3_d_image.fields.rotation.z"),
                key: "management.product_3_d_image.fields.rotation.z",
                dataIndex: ["rotation", "z"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "nodeName",
                title: t("management.product_3_d_image.fields.nodeName"),
                key: "management.product_3_d_image.fields.nodeName",
                dataIndex: ["nodeName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.product_3_d_image.fields.createdAt"),
                key: "management.product_3_d_image.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.product_3_d_image.fields.updatedAt"),
                key: "management.product_3_d_image.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "image",
                title: t("management.product_3_d_image.fields.image"),
                key: "management.product_3_d_image.fields.image",
                dataIndex: ["image"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "productModel",
                title: t("management.product_3_d_image.fields.productModel"),
                key: "management.product_3_d_image.fields.productModel",
                dataIndex: ["productModel"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_3_D_MODEL_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                title: t("management.product_3_d_image.fields.createdBy"),
                key: "management.product_3_d_image.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                title: t("management.product_3_d_image.fields.updatedBy"),
                key: "management.product_3_d_image.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            image: "image._id",
            productModel: "productModel.name",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            size: 1,
            position: {
                x: 1,
                y: 1,
                z: 1,
            },
            rotation: {
                x: 1,
                y: 1,
                z: 1,
            },
            nodeName: 1,
            createdAt: 1,
            updatedAt: 1,
            image: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            imageId: 1,
            productModel: {
                _id: 1,
                name: 1,
            },
            productModelId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
Product3DImageList = __decorate([
    Service({ transient: true })
], Product3DImageList);
export { Product3DImageList };

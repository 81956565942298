import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslate } from "@bluelibs/x-ui";
import { GetGeneralDetails } from "../queries";
export const useGeneralDetailsView = () => {
    const t = useTranslate();
    const [sortedData, setSortedData] = useState([]);
    const { data, loading, error } = useQuery(GetGeneralDetails, {
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (!data)
            return;
        const sortedKeys = [
            "shopName",
            "companyName",
            "companyIdentificationNumber",
        ];
        setSortedData(sortedKeys.map((key) => ({
            label: t(`management.shop_settings.fields.${key}`),
            value: data.AdminGetShopSettings[key],
        })));
    }, [data]);
    return {
        data: sortedData,
        billingAddress: data === null || data === void 0 ? void 0 : data.AdminGetShopSettings.billingAddress,
        loading,
        error,
        t,
    };
};

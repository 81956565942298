var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject } from "@bluelibs/core";
import { StopOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { InternalUserProfilesCollection } from "@bundles/UIAppBundle/collections";
import { Edit, View, KebabMenu } from "@bundles/UIAppBundle/icons";
import { features } from "../../config/features";
import { InternalUserProfileList } from "../../config/InternalUserProfileList";
import { CustomTableSmart } from "@bundles/UIAppBundle/overrides/CustomTableSmart";
import { CustomInternalUserProfilesCollection } from "@bundles/UIAppBundle/collections/InternalUserProfiles/InternalUserProfiles.custom.collection";
export class InternalUserProfilesAntTableSmart extends CustomTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = CustomInternalUserProfilesCollection;
    }
    getBody() {
        return InternalUserProfileList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(InternalUserProfileList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            title: "",
            key: "actions",
            fixed: "right",
            render: (_, model) => {
                return this.generateActions(model, {
                    label: "",
                    icon: _jsx(KebabMenu, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return InternalUserProfileList.getSortMap();
    }
    getActionItems() {
        var _a, _b;
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(View, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.INTERNAL_USER_PROFILES_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(Edit, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.INTERNAL_USER_PROFILES_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        const isSuspended = (_b = (_a = this.state.filters) === null || _a === void 0 ? void 0 : _a["$and"]) === null || _b === void 0 ? void 0 : _b.some((filter) => { var _a; return (_a = filter.isSuspended) === null || _a === void 0 ? void 0 : _a["$in"][0]; });
        if (isSuspended !== undefined) {
            actions.push({
                label: isSuspended ? "Unsuspend" : "Suspend",
                icon: _jsx(StopOutlined, {}, void 0),
                confirm: this.i18n.t("generics.ask_confirmation"),
                action: (model) => {
                    var _a;
                    if (((_a = model.user) === null || _a === void 0 ? void 0 : _a.isSuspended) === false) {
                        this.collection
                            .suspendUser(model === null || model === void 0 ? void 0 : model._id)
                            .then(() => {
                            this.load();
                            notification.success({ message: "User Suspended" });
                        })
                            .catch(() => notification.error({ message: "User Suspend Failed" }));
                    }
                    else {
                        this.collection
                            .unSuspendUser(model === null || model === void 0 ? void 0 : model._id)
                            .then(() => {
                            this.load();
                            notification.success({ message: "User Unsuspended" });
                        })
                            .catch(() => notification.error({ message: "User Unsuspend Failed" }));
                    }
                },
            });
        }
        return actions;
    }
    load() {
        var _a;
        if (this.state.isLoading === false) {
            this.updateState({ isLoading: true });
        }
        // ----- MODIFYING FILTERS
        let modifiedFiltersObj = {};
        (_a = this.state.filters["$and"]) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            modifiedFiltersObj = { ...modifiedFiltersObj, ...filter };
            return filter;
        });
        if (modifiedFiltersObj["fullName"]) {
            modifiedFiltersObj["fullName"] = modifiedFiltersObj["fullName"].source;
        }
        if (modifiedFiltersObj["isSuspended"]) {
            modifiedFiltersObj["isSuspended"] =
                modifiedFiltersObj["isSuspended"]["$in"][0];
        }
        // ---- FILTERS MODIFICATION COMPLETE
        this.collection
            .find({
            filters: {
                ...modifiedFiltersObj,
            },
            options: {
                ...this.getSortOptions(),
                ...this.state.options,
                ...this.getPaginationOptions(),
            },
        })
            .then((response) => {
            this.updateState({
                isLoading: false,
                isError: false,
                documents: response.data,
                totalCount: response.total,
            }, {
                silent: true,
            });
            this.inform();
        })
            .catch((err) => {
            this.updateState({
                isLoading: false,
                isError: true,
                errorMessage: err.message || err.toString(),
            }, {
                silent: true,
            });
            this.inform();
        });
    }
}
__decorate([
    Inject(() => InternalUserProfilesCollection),
    __metadata("design:type", typeof (_a = typeof CustomInternalUserProfilesCollection !== "undefined" && CustomInternalUserProfilesCollection) === "function" ? _a : Object)
], InternalUserProfilesAntTableSmart.prototype, "collection", void 0);

import { gql } from "@apollo/client";
// TODO
export const getVariant = gql `
  query AdminProductVariantGetOne($input: AdminProductVariantGetOneInput!) {
    AdminProductVariantGetOne(input: $input) {
      attributesValues {
        key
        value
        suffix
        isNumber
      }
      defaultImage {
        downloadUrl
      }
      unitOfMeasurementId
      salesRayImageUrl
      model3D {
        downloadUrl
      }
      product {
        availableStockCount
        params
        categoriesBreadcrumbs {
          _id
          name
          attributeOptions {
            name
            type
            isRequired
            isEnum
            enumValues
            isRange
            defaultValue
            defaultSuffix
            rangeMin
            rangeMax
          }
        }
        variants {
          _id
          attributesValues {
            key
            value
            suffix
            isNumber
          }
          unitOfMeasurement {
            _id
            salesRayId
            name
            code
            hasWeight
            label
          }
          height
          length_
          width
          netWeight
          grossWeight
        }
        attributeOptions {
          name
          type
          isRequired
          isEnum
          enumValues
          isRange
          defaultValue
          defaultSuffix
        }

        category {
          _id
          name
          attributeOptions {
            name
            type
            isRequired
            isEnum
            enumValues
            isRange
            defaultValue
            defaultSuffix
            rangeMin
            rangeMax
          }
          fullAttributeOptions {
            name
            type
            isRequired
            isEnum
            enumValues
            isRange
            defaultValue
            defaultSuffix
            rangeMin
            rangeMax
          }
        }
      }

      availability
      brand
      country
      daysBeforeAllowingReview
      description
      discountActiveUntil
      discountPercentage
      images {
        files {
          _id
          downloadUrl
        }
      }
      isPublished
      name
      netPrice
      totalDiscountPercentage
      sku
      tags
      type
      vatRateId
      seoFields {
        pageTitle
        metaDescription
        keywords
        slug
      }
    }
  }
`;
export const getProduct = gql `
  query AdminProductsGet($input: AdminProductsGetInput!) {
    AdminProductsGet(input: $input) {
      attributeOptions {
        name
        type
        isRequired
        isEnum
        enumValues
        isRange
        defaultValue
        defaultSuffix
      }

      category {
        name
        attributeOptions {
          name
          type
          isRequired
          isEnum
          enumValues
          isRange
          defaultValue
          defaultSuffix
          rangeMin
          rangeMax
        }
      }

      brand
      country
      defaultDiscountPercentage
      images {
        files {
          _id
          downloadUrl
          name
          mimeType
        }
      }
      defaultNetPrice
      description
      height
      hasVariants
      length
      name
      stockAvailability
      type
      variants {
        name
        _id
      }
      vatRate {
        _id
        description
        name
        percentage
      }
      # weight
      width
      discountActiveUntil

      seoFields {
        pageTitle
        metaDescription
        keywords
        slug
      }
    }
  }
`;

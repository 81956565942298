import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Button } from "@bundles/UIAppBundle/components";
import EditProduct3DMenu from "./components/EditProduct3DMenu";
import ModelMesh from "./components/ModelMesh";
import styles from "./Product3DPreview.module.scss";
import { use3DProduct } from "./hooks";
const Product3DPreview = ({ id }) => {
    const { model, isLoading, selectedDecalIndex, groupRef, onClickDownload } = use3DProduct(id);
    return (_jsx(Suspense, Object.assign({ fallback: "loading..." }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(EditProduct3DMenu, { model: model, selectedDecalIndex: selectedDecalIndex, isLoading: isLoading }, void 0), _jsx(Button, Object.assign({ onClick: onClickDownload, className: styles["fab"], width: "fit-content" }, { children: "Download" }), void 0), _jsxs(Canvas, Object.assign({ className: styles.canvas, shadows: true, orthographic: true, camera: { position: [-50, 50, 100], zoom: 140 } }, { children: [_jsx("ambientLight", { intensity: 0.5 }, void 0), _jsx("directionalLight", { intensity: 0.5, position: [10, 10, 10] }, void 0), model.file && _jsx(ModelMesh, { model: model, ref: groupRef }, void 0), _jsx(OrbitControls, {}, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default Product3DPreview;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { notification, Spin } from "antd";
import { Card } from "@bundles/UIAppBundle/components/Card";
import { useOrderTotalCard } from "./hooks";
import styles from "./OrderTotalCard.module.scss";
import { useTranslate } from "@bluelibs/x-ui";
import { useShopSettings } from "@bundles/UIAppBundle/services/ShopSettingsService/ShopSettingsService";
const OrderTotalCard = ({ stepForm }) => {
    const t = useTranslate();
    const { data, price, loading, error } = useOrderTotalCard(stepForm);
    const shopSettings = useShopSettings();
    if (error) {
        notification.error({
            message: t("generics.error"),
            description: error.message,
        });
        return null;
    }
    if (loading)
        return _jsx(Spin, {}, void 0);
    const { subtotal, shippingCharge, taxCharge } = price;
    return (_jsx(Card, Object.assign({ className: styles.card }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles["header"] }, { children: [_jsx("div", { children: "PRODUCT" }, void 0), _jsx("div", { children: "PRICE" }, void 0), _jsx("div", { children: "Pcs" }, void 0), _jsx("div", { children: "Total" }, void 0)] }), void 0), _jsx("div", { className: styles["hr"] }, void 0), data.map(({ count, productVariant: { netPrice, name, totalDiscountPercentage }, }, index) => {
                    const discountPrice = netPrice - (netPrice * totalDiscountPercentage) / 100;
                    return (_jsxs("div", Object.assign({ className: styles["item"] }, { children: [_jsx("div", { children: name }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(discountPrice) }, void 0), _jsx("div", { children: count }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(discountPrice * count) }, void 0)] }), index));
                }), _jsxs("div", Object.assign({ className: styles["subtotal-section"] }, { children: [_jsx("div", {}, void 0), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles["subtotal-section__item"] }, { children: [_jsx("div", { children: "Subtotal:" }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(subtotal) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["subtotal-section__item"] }, { children: [_jsx("div", { children: "Shipping Charge:" }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(shippingCharge) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["subtotal-section__item"] }, { children: [_jsx("div", { children: "VAT TAX:" }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(taxCharge) }, void 0)] }), void 0), _jsx("div", { className: styles["hr"] }, void 0), _jsxs("div", Object.assign({ className: styles["subtotal-section__item"] }, { children: [_jsx("div", { children: "Total:" }, void 0), _jsx("div", { children: shopSettings.formatPriceWithCurrency(subtotal + taxCharge + shippingCharge) }, void 0)] }), void 0)] }, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default OrderTotalCard;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ObjectId } from "@bluelibs/ejson";
import { CheckOutlined } from "@ant-design/icons";
import { useUIComponents, use, useDataOne } from "@bluelibs/x-ui";
import { OrderEditForm } from "../../config/OrderEditForm";
import { OrdersCollection } from "@bundles/UIAppBundle/collections";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { useUpdateOrder } from "./hooks";
import { ordersAccessRoles } from "../../consts";
import { Card, ControlledSelect, RichTextEditor, Topbar, } from "@bundles/UIAppBundle/components";
import { AddProductsCard } from "./components/AddProductsCard/AddProductsCard";
import { AssignTeamMember } from "@bundles/UIAppBundle/components/OrderDetail/components/AssignTeamMember";
import styles from "./OrdersEdit.module.scss";
import { Spin } from "antd";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
export function OrdersEdit(props) {
    var _a;
    const UIComponents = useUIComponents();
    const form = use(OrderEditForm, { transient: true });
    const { data: document, isLoading, error, } = useDataOne(OrdersCollection, new ObjectId(props.id), OrderEditForm.getRequestBody());
    form.build();
    const { onSubmit, orderStatusOptions, paymentOptions, teamMemberProfilesLoading, paymentOptionLoading, companyDetailsLoading, orderDetailLoading, newOrderDetail, companyDetails, orderDetail, onOrderStatusSelectHandler, onPaymentOptionSelectHandler, onNoteEditHandler, } = useUpdateOrder(props.id);
    if (teamMemberProfilesLoading ||
        orderDetailLoading ||
        companyDetailsLoading ||
        paymentOptionLoading ||
        isLoading) {
        return (_jsx(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: _jsx(UIComponents.AdminLayout, { children: _jsx(Spin, { size: "large" }, void 0) }, void 0) }), void 0));
    }
    return (_jsxs(ProtectedRoute, Object.assign({ roles: ordersAccessRoles }, { children: [_jsx(Seo, { data: seoData.order.edit }, void 0), _jsx(UIComponents.AdminLayout, { children: _jsxs("div", Object.assign({ className: styles["container"] }, { children: [_jsx(Topbar, { title: "EDIT ORDER", btnText: "Save Order", btnIcon: CheckOutlined, btnOnClick: () => {
                                if (newOrderDetail && (newOrderDetail === null || newOrderDetail === void 0 ? void 0 : newOrderDetail._id)) {
                                    onSubmit(newOrderDetail === null || newOrderDetail === void 0 ? void 0 : newOrderDetail._id, newOrderDetail);
                                }
                            } }, void 0), _jsx(Card, Object.assign({ title: "ORDER STATUS" }, { children: _jsx(ControlledSelect, { label: "SELECT STATUS", value: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.status, options: orderStatusOptions, onChange: (v) => {
                                    onOrderStatusSelectHandler(v);
                                } }, void 0) }), void 0), _jsx(Card, Object.assign({ title: "PAYMENT OPTION" }, { children: _jsx(ControlledSelect, { label: "SELECT PAYMENT OPTION", value: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.paymentOptionId, options: paymentOptions, onChange: (v) => {
                                    onPaymentOptionSelectHandler(v);
                                } }, void 0) }), void 0), _jsx(AssignTeamMember, { orderId: orderDetail._id, salesAgentId: orderDetail.salesAgentId, cardDefault: true }, void 0), _jsxs("div", { children: [_jsx("h2", Object.assign({ className: styles["subtitle"] }, { children: "PRODUCTS" }), void 0), (newOrderDetail === null || newOrderDetail === void 0 ? void 0 : newOrderDetail.items) && (_jsx(AddProductsCard, { items: newOrderDetail === null || newOrderDetail === void 0 ? void 0 : newOrderDetail.items.map((item) => {
                                        var _a;
                                        return {
                                            id: item._id,
                                            picture: (_a = item.productVariant.defaultImage) === null || _a === void 0 ? void 0 : _a.downloadUrl,
                                            name: item.productSnapshot.name,
                                            quantity: item.count,
                                            piecePrice: item.price.net / item.count,
                                            attributes: item.productVariant.attributesValues,
                                            currency: item.price.currency,
                                        };
                                    }), subTotal: orderDetail.price, shippingCharge: (_a = orderDetail.shippingOption) === null || _a === void 0 ? void 0 : _a.price, vatCharge: orderDetail.priceVat - orderDetail.price, total: orderDetail.priceTotal }, void 0))] }, void 0), _jsx(Card, Object.assign({ title: "ADD NOTES" }, { children: _jsx(RichTextEditor, { label: "DESCRIPTION", value: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.privateNotes, onChange: (value) => {
                                    onNoteEditHandler(value);
                                } }, void 0) }), void 0)] }), void 0) }, void 0)] }), void 0));
}

import { ArticlesList } from "../components/List/ArticlesList";
import { ArticlesCreate } from "../components/Create/ArticlesCreate";
import { ArticlesEdit } from "../components/Edit/ArticlesEdit";
import { ArticlesView } from "../components/View/ArticlesView";
import { SettingFilled } from "@ant-design/icons";
export const ARTICLES_LIST = {
    path: "/admin/articles",
    component: ArticlesList,
    menu: {
        key: "ARTICLES_LIST",
        label: "management.articles.menu.title",
        icon: SettingFilled,
    },
};
export const ARTICLES_CREATE = {
    path: "/admin/articles/create",
    component: ArticlesCreate,
};
export const ARTICLES_EDIT = {
    path: "/admin/articles/:id/edit",
    component: ArticlesEdit,
};
export const ARTICLES_VIEW = {
    path: "/admin/articles/:id/view",
    component: ArticlesView,
};

import { gql } from "@apollo/client";
export const GET_ENDUSER_PROFILES_LIST = gql `
  query AdminEndUserProfileListGet($input: AdminEndUserProfileListGetInput) {
    AdminEndUserProfileListGet(input: $input) {
      total
      data {
        profile {
          _id
          fullName
          businessInfo {
            companyName
            companyRegistrationNumber
          }
          email
          phoneNumber
          billingAddress {
            country
            city
            streetAddressLine1
            zipcode
          }
          savedAddresses {
            name
          }
          user {
            avatar {
              file {
                downloadUrl
              }
            }
          }
        }
      }
    }
  }
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { AddCircle, Trashcan } from "@bundles/UIAppBundle/icons";
import { ControlledInput } from "@bundles/UIAppBundle/components";
import { Button } from "@bundles/UIAppBundle/components/Button";
import { ShopSettingCreateForm as BaseShopSettingCreateForm } from "./ShopSettingCreateForm.base";
import styles from "../components/Create/ShopSettingsCreate.module.scss";
let ShopSettingCreateForm = class ShopSettingCreateForm extends BaseShopSettingCreateForm {
    build() {
        super.build();
        const t = useTranslate();
        this.update("shippingAddress", {
            columns: [24, 13, 11],
            listRenderer: () => 
            // eslint-disable-next-line react/display-name
            (fields, { add, remove }) => {
                const onAdd = (e) => {
                    e.preventDefault();
                    add();
                };
                const onRemove = (name) => (e) => {
                    e.preventDefault();
                    remove(name);
                };
                return (_jsxs(Ant.Col, Object.assign({ span: 24 }, { children: [fields.map((field, index) => {
                            return (_jsxs("div", Object.assign({ className: styles["shipment-wrapper"] }, { children: [this.renderFormItem({
                                        ...this.findElement("shippingAddress"),
                                    }, { props: field, isFromList: true }), _jsx(Button, Object.assign({ width: "fit-content", className: styles["remove-btn"], onClick: onRemove(field.name) }, { children: _jsxs(_Fragment, { children: [_jsx(Trashcan, {}, void 0), "Remove Shipping Address"] }, void 0) }), void 0)] }), index));
                        }), _jsx(Button, Object.assign({ width: "fit-content", className: styles["add-btn"], onClick: onAdd }, { children: _jsxs(_Fragment, { children: [_jsx(AddCircle, {}, void 0), "Add New Shipping Address"] }, void 0) }), void 0)] }), void 0));
            },
            nest: [
                {
                    id: "streetAddressLine1",
                    name: ["shippingAddress", "streetAddressLine1"],
                    required: true,
                    initialValue: [],
                    render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { label: t("management.shop_settings.fields.shippingAddress"), placeholder: t("management.shop_settings.placeholders.streetAddress") }, void 0) }), void 0)),
                },
                {
                    id: "city",
                    name: ["shippingAddress", "city"],
                    required: true,
                    initialValue: [],
                    render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { placeholder: t("management.shop_settings.placeholders.city") }, void 0) }), void 0)),
                },
                {
                    id: "zipcode",
                    name: ["shippingAddress", "zipcode"],
                    required: true,
                    initialValue: [],
                    render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { label: "" }, { children: _jsx(ControlledInput, { placeholder: t("management.shop_settings.placeholders.postalCode") }, void 0) }), void 0)),
                },
            ],
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
ShopSettingCreateForm = __decorate([
    Service({ transient: true })
], ShopSettingCreateForm);
export { ShopSettingCreateForm };

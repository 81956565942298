import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Spin } from "antd";
import { generateProfileImageLink } from "@bundles/UIAppBundle/enums/Images";
import { Searchbar } from "@bundles/UIAppBundle/components/Searchbar";
import { Button, Table } from "@bundles/UIAppBundle/components";
import { useAddClient } from "./hooks";
import styles from "./AddClient.module.scss";
const AddClient = ({ stepForm }) => {
    var _a;
    const { onSearch, clientsData, currentPage, setCurrentPage, tableTotalData, clientsListLoading, pageSize, } = useAddClient();
    const columns = [
        {
            title: "Name",
            dataIndex: "fullName",
            render: (name, model) => {
                var _a, _b, _c;
                return (_jsxs("div", Object.assign({ className: styles["client-name-pic"] }, { children: [_jsx("img", { src: generateProfileImageLink(name, (_c = (_b = (_a = model.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl) }, void 0), _jsx("div", { children: name }, void 0)] }), void 0));
            },
        },
        {
            title: "Company Name",
            dataIndex: ["businessInfo", "companyName"],
        },
        {
            title: "email",
            dataIndex: "email",
        },
        {
            title: "phone",
            dataIndex: "phoneNumber",
        },
    ];
    const formData = stepForm.getFieldsValue(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([
        (_a = formData.buyerInfo) === null || _a === void 0 ? void 0 : _a.key,
    ]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            stepForm.setFieldsValue({ buyerInfo: selectedRows[0] });
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(Searchbar, { onChange: (searchTerm) => {
                    onSearch(searchTerm);
                } }, void 0), clientsListLoading ? (_jsx(Spin, { size: "large" }, void 0)) : (_jsx(Table, { className: styles["table"], rowSelection: {
                    type: "radio",
                    ...rowSelection,
                }, rowKey: (record) => record._id, showHeader: false, columns: columns, dataSource: clientsData, pagination: {
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: (page) => {
                        setCurrentPage(page);
                    },
                    total: tableTotalData,
                    showSizeChanger: false,
                    showLessItems: true,
                    showPrevNextJumpers: true,
                    hideOnSinglePage: true,
                    prefixCls: "cc-custom-pagination",
                    position: ["bottomCenter"],
                    itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                            return null;
                        }
                        if (type === "next") {
                            return null;
                        }
                        if (type === "page") {
                            return _jsx(Button, { children: page }, void 0);
                        }
                        if (type === "jump-next" || type === "jump-prev") {
                            return _jsx(Button, Object.assign({ className: "jump" }, { children: "..." }), void 0);
                        }
                        return originalElement;
                    },
                } }, void 0))] }), void 0));
};
export default AddClient;

import "./i18n";
import { GeneralDetailsView, GeneralDetailsForm, InvoicingDetailsView, InvoicingDetailsForm, ShopAttributesView, ShopAttributesForm, } from "@bundles/UIAppBundle/components/ShopConfiguration";
import { SHOP_SETTINGS_LIST as BASE_SHOP_SETTINGS_LIST, SHOP_SETTINGS_CREATE as BASE_SHOP_SETTINGS_CREATE, SHOP_SETTINGS_EDIT as BASE_SHOP_SETTINGS_EDIT, SHOP_SETTINGS_VIEW as BASE_SHOP_SETTINGS_VIEW, } from "./config/routes";
export const SHOP_SETTINGS_LIST = {
    ...BASE_SHOP_SETTINGS_LIST,
    component: null,
};
export const SHOP_SETTINGS_CREATE = {
    ...BASE_SHOP_SETTINGS_CREATE,
    component: null,
};
export const SHOP_SETTINGS_EDIT = {
    ...BASE_SHOP_SETTINGS_EDIT,
    component: null,
};
export const SHOP_SETTINGS_VIEW = {
    ...BASE_SHOP_SETTINGS_VIEW,
    component: null,
};
export const SHOP_SETTINGS_GENERAL_DETAILS_VIEW = {
    path: "/shop-settings/general",
    component: GeneralDetailsView,
};
export const SHOP_SETTINGS_GENERAL_DETAILS_EDIT = {
    path: "/shop-settings/general/edit",
    component: GeneralDetailsForm,
};
export const SHOP_SETTINGS_INVOICING_DETAILS_VIEW = {
    path: "/shop-settings/invoicing",
    component: InvoicingDetailsView,
};
export const SHOP_SETTINGS_INVOICING_DETAILS_EDIT = {
    path: "/shop-settings/invoicing/edit",
    component: InvoicingDetailsForm,
};
export const SHOP_SETTINGS_ATTRIBUTES_VIEW = {
    path: "/shop-settings/attributes",
    component: ShopAttributesView,
};
export const SHOP_SETTINGS_ATTRIBUTES_EDIT = {
    path: "/shop-settings/attributes/edit",
    component: ShopAttributesForm,
};

import { ShippingOptionsList } from "../components/List/ShippingOptionsList";
import { ShippingOptionsCreate } from "../components/Create/ShippingOptionsCreate";
import { ShippingOptionsEdit } from "../components/Edit/ShippingOptionsEdit";
import { ShippingOptionsView } from "../components/View/ShippingOptionsView";
import { SettingFilled } from "@ant-design/icons";
export const SHIPPING_OPTIONS_LIST = {
    path: "/admin/shipping-options",
    component: ShippingOptionsList,
    menu: {
        key: "SHIPPING_OPTIONS_LIST",
        label: "management.shipping_options.menu.title",
        icon: SettingFilled,
    },
};
export const SHIPPING_OPTIONS_CREATE = {
    path: "/admin/shipping-options/create",
    component: ShippingOptionsCreate,
};
export const SHIPPING_OPTIONS_EDIT = {
    path: "/admin/shipping-options/:id/edit",
    component: ShippingOptionsEdit,
};
export const SHIPPING_OPTIONS_VIEW = {
    path: "/admin/shipping-options/:id/view",
    component: ShippingOptionsView,
};

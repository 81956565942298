var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { useData } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ControlledInput, ControlledSelect, } from "@bundles/UIAppBundle/components";
import { CustomProductCategoriesCollection } from "@bundles/UIAppBundle/collections";
import { ProductVariantListFiltersForm as BaseProductVariantListFiltersForm } from "./ProductVariantListFiltersForm.base";
let ProductVariantListFiltersForm = class ProductVariantListFiltersForm extends BaseProductVariantListFiltersForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("name");
        this.remove("description");
        this.remove("sku");
        this.remove("availability");
        this.remove("isPublished");
        this.remove("publishedAt");
        this.remove("tags");
        this.remove("daysBeforeAllowingReview");
        this.remove("brand");
        this.remove("type");
        this.remove("netWeight");
        this.remove("grossWeight");
        this.remove("length_");
        this.remove("width");
        this.remove("height");
        this.remove("country");
        this.remove("updatedAt");
        this.remove("productId");
        this.remove("publishedById");
        this.remove("vatRateId");
        this.remove("createdById");
        this.remove("updatedById");
        this.remove("rating");
        this.remove("seoFields.pageTitle");
        this.remove("seoFields.metaDescription");
        this.remove("seoFields.keywords");
        this.remove("seoFields.seoTitle");
        this.remove("seoFields.seoDescription");
        this.remove("seoFields.slug");
        this.remove("seoFields");
        this.remove("salesRayId");
        this.remove("salesRayImageUrl");
        this.remove("attributesValues");
        this.remove("unitOfMeasurementId");
        this.remove("models");
        this.remove("coefficient");
        this.remove("discountPercentage");
        this.remove("discountActiveUntil");
        this.add({
            id: "netPrice",
            name: ["netPrice"],
            label: "",
            order: 0,
            nest: [
                {
                    id: "min",
                    name: ["netPrice", 0],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Starting At", label: t("management.product_variants.fields.netPrice") }, void 0) }), void 0) }), void 0)),
                },
                {
                    id: "max",
                    name: ["netPrice", 1],
                    label: "",
                    required: false,
                    render: (props) => (_jsx(Ant.Col, Object.assign({ span: 12 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledInput, { placeholder: "Ending At", label: "\u00A0" }, void 0) }), void 0) }), void 0)),
                },
            ],
        });
        // this.update("discountPercentage", {
        //   label: "Discount Percentage",
        //   order: 2,
        //   nest: [
        //     {
        //       id: "min",
        //       name: ["discountPercentage", 0],
        //       label: "",
        //       required: false,
        //       render: (props) => (
        //         <Ant.Col span={12}>
        //           <Ant.Form.Item {...props}>
        //             <ControlledInput
        //               placeholder={"Starting At"}
        //               label={t(
        //                 "management.product_variants.fields.discountPercentage",
        //               )}
        //             />
        //           </Ant.Form.Item>
        //         </Ant.Col>
        //       ),
        //     },
        //     {
        //       id: "max",
        //       name: ["discountPercentage", 1],
        //       label: "",
        //       required: false,
        //       render: (props) => (
        //         <Ant.Col span={12}>
        //           <Ant.Form.Item {...props}>
        //             <ControlledInput placeholder={"Ending At"} label="&nbsp;" />
        //           </Ant.Form.Item>
        //         </Ant.Col>
        //       ),
        //     },
        //   ],
        // });
        //
        // this.update("discountActiveUntil", {
        //   order: 4,
        //   label: "",
        //   render: (props) => (
        //     <Ant.Col span={24}>
        //       <Ant.Form.Item {...props}>
        //         <DatePicker
        //           label={t(
        //             "management.product_variants.fields.discountActiveUntil",
        //           )}
        //         />
        //       </Ant.Form.Item>
        //     </Ant.Col>
        //   ),
        // });
        this.update("categoryId", {
            label: "",
            name: ["categoryId"],
            order: 1,
            render: (props) => {
                var _a;
                const { data: categories, isLoading, error, } = useData(CustomProductCategoriesCollection, {
                    filters: {},
                    options: {},
                }, {});
                const categoriesList = !isLoading &&
                    ((_a = categories) === null || _a === void 0 ? void 0 : _a.data.map((category) => ({
                        value: category._id.toString(),
                        label: category.name,
                    })));
                return (_jsx(Ant.Col, Object.assign({ span: 24 }, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(ControlledSelect, { options: categoriesList || [], placeholder: "Please select an option", label: t("management.product_variants.fields.category") }, void 0) }), void 0) }), void 0));
            },
        });
        // TODO: add them back when we have them (there is a chance that we won't use them)
        // this.update("attributesValues", {
        //   label: "",
        //   order: 5,
        //   render: (props) => {
        //     const { data } = useQuery(AdminProductVariantListGet, {
        //       variables: {
        //         input: {
        //           filters: {},
        //           options: {
        //             limit: 8,
        //           },
        //         },
        //       },
        //     });
        //     const attributeKeys = new Set();
        //     data?.AdminProductVariantListGet.data.forEach((option) => {
        //       return option.attributesValues.map((value) => {
        //         attributeKeys.add(value.key);
        //       });
        //     });
        //     const attributeOptions: IOption[] = [];
        //     attributeKeys.forEach((attribute: string) => {
        //       attributeOptions.push({
        //         value: attribute,
        //         label: attribute,
        //       });
        //     });
        //     return (
        //       <Ant.Col span={24}>
        //         <Ant.Form.Item {...props} initialValue={[]}>
        //           <ControlledSelect
        //             label={t(
        //               t("management.product_variants.fields.attributesValues"),
        //             )}
        //             placeholder="Select Attributes"
        //             options={attributeOptions}
        //             multiple={true}
        //           />
        //         </Ant.Form.Item>
        //       </Ant.Col>
        //     );
        //   },
        // });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
};
ProductVariantListFiltersForm = __decorate([
    Service({ transient: true })
], ProductVariantListFiltersForm);
export { ProductVariantListFiltersForm };

import { jsx as _jsx } from "react/jsx-runtime";
import { use } from "@bluelibs/x-ui-react-bundle";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation, useQuery } from "@apollo/client";
import * as Ant from "antd";
import { SmileOutlined } from "@ant-design/icons";
import slugify from "slugify";
import { LongImageUpload } from "@bundles/UIAppBundle/components";
import { Routes } from "@bundles/UIAppBundle";
import { features } from "../../config/features";
import { PostEditForm } from "../../config/PostEditForm";
import { ADMIN_POST_UPDATE } from "../../config/mutations";
import { ADMIN_POST_GET } from "../../config/queries";
export const usePostsEdit = ({ id }) => {
    const form = use(PostEditForm, { transient: true });
    form.build();
    const [formInstance] = Ant.Form.useForm();
    const { data, loading: isLoading, error: loadingError, } = useQuery(ADMIN_POST_GET, { variables: { input: { _id: id } } });
    const [updatePost, { loading: isSubmitting }] = useMutation(ADMIN_POST_UPDATE);
    const t = useTranslate();
    const router = useRouter();
    form.add({
        id: "heroImage",
        label: t("management.posts.fields.heroImage"),
        name: ["heroImage"],
        componentProps: { field: "_id" },
        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { label: "", noStyle: true }, { children: _jsx(LongImageUpload, { formInstance: formInstance, fieldName: "heroImage" }, void 0) }), void 0)),
    });
    const onFormValuesChange = (value, values) => {
        if (typeof value.title === "string") {
            formInstance.setFieldValue("slug", slugify(values.title));
        }
        if (value.slug) {
            formInstance.setFieldValue("slug", slugify(value.slug));
        }
    };
    const onFinish = (values) => {
        var _a;
        updatePost({
            variables: {
                input: {
                    ...values,
                    postId: id,
                    heroImage: (_a = values.heroImage) === null || _a === void 0 ? void 0 : _a.originFileObj,
                },
            },
        })
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.posts.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.list) {
                return router.go(Routes.POSTS_LIST);
            }
        })
            .catch(() => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    };
    return {
        form,
        formInstance,
        document: data === null || data === void 0 ? void 0 : data.AdminPostsGet,
        isLoading,
        loadingError,
        isSubmitting,
        onFormValuesChange,
        onFinish,
    };
};

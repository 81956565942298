var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { Routes } from "@bundles/UIAppBundle";
import { Button, CustomTag } from "@bundles/UIAppBundle/components";
import SvgEdit from "@bundles/UIAppBundle/icons/Edit";
import styles from "../components/List/ArticlesList.module.scss";
import { ArticleList as BaseArticleList } from "./ArticleList.base";
import { STATUS, statusColors } from "./const";
let ArticleList = class ArticleList extends BaseArticleList {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("createdAt");
        this.remove("body");
        this.remove("slug");
        this.update("title", {
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.articles.table.title") }), void 0)),
            render: (value) => {
                return _jsx("span", Object.assign({ className: styles.row }, { children: value }), void 0);
            },
        });
        this.update("updatedAt", {
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.articles.table.updatedAt") }), void 0)),
            render: (value) => {
                return (_jsx("span", Object.assign({ title: value.toLocaleTimeString(), className: styles.row }, { children: value.toLocaleString(undefined, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                    }) }), void 0));
            },
        });
        this.add({
            id: "status",
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.articles.table.status") }), void 0)),
            render: (value, article) => {
                const status = article.createdAt.valueOf() === article.updatedAt.valueOf()
                    ? STATUS.UNCHANGED
                    : STATUS.MODIFIED;
                const color = statusColors[status];
                return (_jsx("span", Object.assign({ className: styles.row }, { children: _jsx(CustomTag, { value: status, variant: "filled", color: color }, void 0) }), void 0));
            },
        });
        this.add({
            id: "edit",
            title: (_jsx("span", Object.assign({ className: styles.header }, { children: t("management.articles.table.edit") }), void 0)),
            render: (value, article) => {
                return (_jsx(Button, Object.assign({ btnStyle: "primary", icon: SvgEdit, className: styles["edit-button"], onClick: () => {
                        this.router.go(Routes.ARTICLES_EDIT, {
                            params: { id: article._id.toString() },
                        });
                    } }, { children: t("management.articles.table.edit") }), void 0));
            },
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ArticleList = __decorate([
    Service({ transient: true })
], ArticleList);
export { ArticleList };

import { gql } from "@apollo/client";
export const getShopSettings = gql `
  query AdminGetShopSettings {
    AdminGetShopSettings {
      attributeOptions {
        name
        type
        isRequired
        isEnum
        enumValues
        defaultValue
        defaultSuffix
      }
      billingAddress {
        streetAddressLine1
        streetAddressLine2
        city
        county
        country
        zipcode
        phoneNumber
        email
      }
      defaultCurrency
      analyticsURLs {
        google
        facebookPixel
      }
      logo {
        downloadUrl
      }
      favicon {
        downloadUrl
      }
      shopName
    }
  }
`;

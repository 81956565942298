import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { Table } from "@bundles/UIAppBundle/components";
import ExpandRowIcon from "../ExpandRowIcon";
import { useExpandedInfoTable } from "./hooks";
import styles from "./ExpandedInfoTable.module.scss";
const ExpandedInfoTable = ({ record, level = 2 }) => {
    var _a;
    const { data, loading, error, getNestedTableColumns } = useExpandedInfoTable(record);
    return (_jsxs(_Fragment, { children: [loading && _jsx(Ant.Spin, { size: "large" }, void 0), !loading && !error && (_jsx(Table, { defaultExpandedRowKeys: [], rowKey: (record) => record._id, rowClassName: () => styles["nested-table-row"], className: styles["nested-table"], columns: getNestedTableColumns(level), dataSource: ((_a = data === null || data === void 0 ? void 0 : data.AdminCategoriesGetAll) === null || _a === void 0 ? void 0 : _a.data) || [], pagination: false, expandable: {
                    expandIcon: ExpandRowIcon,
                    expandedRowClassName: () => styles["expanded-row"],
                    expandedRowRender: (record) => (_jsx(ExpandedInfoTable, { record: record, level: level + 1 }, void 0)),
                } }, void 0))] }, void 0));
};
export default ExpandedInfoTable;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { Product3DModelCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let Product3DImageListFiltersForm = class Product3DImageListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "size",
                label: t("management.product_3_d_image.fields.size"),
                name: ["size"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "position",
                label: t("management.product_3_d_image.fields.position"),
                name: ["position"],
                columns: true,
                nest: [
                    {
                        id: "x",
                        label: t("management.product_3_d_image.fields.position.x"),
                        name: ["position", "x"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "y",
                        label: t("management.product_3_d_image.fields.position.y"),
                        name: ["position", "y"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "z",
                        label: t("management.product_3_d_image.fields.position.z"),
                        name: ["position", "z"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "rotation",
                label: t("management.product_3_d_image.fields.rotation"),
                name: ["rotation"],
                columns: true,
                nest: [
                    {
                        id: "x",
                        label: t("management.product_3_d_image.fields.rotation.x"),
                        name: ["rotation", "x"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "y",
                        label: t("management.product_3_d_image.fields.rotation.y"),
                        name: ["rotation", "y"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "z",
                        label: t("management.product_3_d_image.fields.rotation.z"),
                        name: ["rotation", "z"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                ],
            },
            {
                id: "nodeName",
                label: t("management.product_3_d_image.fields.nodeName"),
                name: ["nodeName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.product_3_d_image.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.product_3_d_image.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.product_3_d_image.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.product_3_d_image.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "productModelId",
                label: t("management.product_3_d_image.fields.productModel"),
                name: ["productModelId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: Product3DModelCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.product_3_d_image.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.product_3_d_image.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.product_3_d_image.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.product_3_d_image.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
Product3DImageListFiltersForm = __decorate([
    Service({ transient: true })
], Product3DImageListFiltersForm);
export { Product3DImageListFiltersForm };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { Button, Card, MarketingLayout } from "@bundles/UIAppBundle/components";
import ProtectedRoute from "@bundles/UIAppBundle/hoc/ProtectedRoute/ProtectedRoute";
import { UserRole } from "@root/api.types";
import { Checkmark } from "@bundles/UIAppBundle/icons";
import { Seo } from "@bundles/UIAppBundle/components/Seo";
import seoData from "@bundles/UIAppBundle/components/Seo/SeoData";
import { usePostsCreate } from "./hooks";
import styles from "./PostsCreate.module.scss";
import { scrollToFirstFormFieldError } from "@bundles/UIAppBundle/utils/helpers";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export function PostsCreate() {
    const t = useTranslate();
    const { form, formInstance, isSubmitting, onFinish, onFormValuesChange } = usePostsCreate();
    return (_jsxs(ProtectedRoute, Object.assign({ roles: [UserRole.MARKETING_ADMIN, UserRole.SUPER_ADMIN] }, { children: [_jsx(Seo, { data: seoData.posts.create }, void 0), _jsx(MarketingLayout, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { form: formInstance, requiredMark: "optional", onFinish: onFinish, onValuesChange: onFormValuesChange, className: styles["form"], scrollToFirstError: scrollToFirstFormFieldError }, { children: [_jsxs("div", Object.assign({ className: styles["form__header"] }, { children: [_jsxs("label", Object.assign({ className: styles["visibility"] }, { children: [_jsx("span", Object.assign({ className: styles["visibility__label"] }, { children: t("management.posts.fields.visibility") }), void 0), form.render("visibility")] }), void 0), _jsx(Button, Object.assign({ className: styles["form__header__submit"], icon: Checkmark, loading: isSubmitting }, { children: t("generics.save_changes") }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["form__sections"] }, { children: [_jsxs(Card, { children: [_jsx("h3", Object.assign({ className: styles["form__sections__section__title"] }, { children: t("management.posts.create.sections.titles") }), void 0), form.render("title"), form.render("subtitle"), form.render("slug")] }, void 0), _jsxs(Card, { children: [_jsx("h3", Object.assign({ className: styles["form__sections__section__title"] }, { children: t("management.posts.create.sections.image") }), void 0), form.render("heroImage")] }, void 0), _jsxs(Card, { children: [_jsx("h3", Object.assign({ className: styles["form__sections__section__title"] }, { children: t("management.posts.create.sections.body") }), void 0), form.render("body")] }, void 0)] }), void 0)] }), void 0) }, void 0)] }), void 0));
}

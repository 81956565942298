var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { VatRatesCollection, } from "@bundles/UIAppBundle/collections";
let VatRateCreateForm = class VatRateCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.vat_rates.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "percentage",
                label: t("management.vat_rates.fields.percentage"),
                name: ["percentage"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "description",
                label: t("management.vat_rates.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.vat_rates.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.VAT_RATES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.VAT_RATES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.VAT_RATES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => VatRatesCollection),
    __metadata("design:type", typeof (_a = typeof VatRatesCollection !== "undefined" && VatRatesCollection) === "function" ? _a : Object)
], VatRateCreateForm.prototype, "collection", void 0);
VatRateCreateForm = __decorate([
    Service({ transient: true })
], VatRateCreateForm);
export { VatRateCreateForm };
